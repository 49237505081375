<template>
     <v-container fluid >
   
        <view-mode  :framework="framework"></view-mode>
    </v-container>

</template>

<script>

import ViewMode from '../newComponents/FrameworkComponents/ViewMode.vue'
export default {
    name: "FrameworkHome",
    components:{ViewMode},
     data(){
        return{
            tab: null,
            saving: false,
            changes: 0,
            tableView: true,
            editableFramework: null,
            resetCount: 0,
        }
    },
    created(){
        if(this.$route.hash != null ){
            var t = this.$route.hash.toLowerCase().replace('#','');
            this.tab = this.framework && this.framework.units && this.framework.units.length ? this.framework.units.map(x=>x.id).includes(t) ? t : this.framework.units[0].id : null;
        }
        else{
            this.tab = this.framework && this.framework.units && this.framework.units.length ? this.framework.units[0].id : null;
        }
        this.reset();
    },
    methods:{
        reset(){
            if(this.editableFramework != null)
            {
                this.editableFramework = null;
            }
            this.editableFramework = {};
            var settings = {
                id: this.framework.id,
                units: this.framework.units
            };
          Object.assign(this.editableFramework, settings);
           //this.editableFramework = settings;
            this.resetCount ++;
        },
      
        saveUnitSchema(e){
            this.saving = true;
            console.log(e)
            this.saving = false;
        },
        addUnit(){
            this.tab = null;
            var newUnit = null; 
            newUnit = {
                title: '',
                tags: [],
                description: '',
                maxMarks: 0,
                gradeBoundaries: [
                    { key: `Level 1`, floor: 0 }
                ], 
            };
            this.editableFramework.units.push(newUnit);
            this.changes ++;
        },
        updateUnit(e){
              this.saving = 'secondary';
           /* var boundaries = [];
            this.editableSettings.gradeBoundaries.forEach(g=>{
                var boundary = {
                    key: g.key,
                    floor: g.floor/100
                }
                boundaries.push(boundary)
            });
            this.editableSettings.gradeBoundaries = [];
            this.editableSettings.gradeBoundaries = boundaries;
            */
            this.$post(`frameworks/${this.framework.id}/unit`, e).then((response)=>{
                debugger;
                if(response.error){
                    
                     this.$emit('error', this.errorText(response.text, 'save',this.$getTerm('framework')))
                }
                else{
                    this.$store.dispatch('saved/get_frameworks');
                     var settings = {
                            id: response.frameworkId,
                            units: response.units
                        };
                    Object.assign(this.editableFramework, settings);
                     this.tab = response.updatedUnit;
                    this.$emit('update', 'Changes Saved')
                }
                this.saving = false;
            });
        }
    },
    computed:{
        emptyUnit(){
             return this.framework.units.find(x=>!x.id);
        },
        units(){
            return this.framework.units.map(x=>{
                return {
                    value: x.id,
                    text: x.title
                }
            })
        },
        unitCount(){
            return this.framework.units.length
        },
        filledUnits(){
             return this.editableFramework.units.filter(x=>x.id).length

        },
        unit(){
            return this.emptyUnit ? this.emptyUnit : this.framework.units.find(x=>x.id == this.tab)
           
        }
    },
    props:{
        framework: {
            type: Object
        }
    }
}
</script>