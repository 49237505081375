var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ( (_vm.availableIds.length || _vm.unavailableIds.length))?_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-title',[_vm._v("Create "+_vm._s(_vm._f("pluralTerm")('Subgroup')))]):_vm._e(),(_vm.groupTypeOptions.length)?_c('v-card-text',[_c('sr-available-ids',{attrs:{"entity":"Group","label":"Subgroup","availableIds":_vm.availableIds,"unavailableIds":_vm.unavailableIds}})],1):_vm._e(),_c('v-card-text',[(_vm.availableIds.length && _vm.groupTypeOptions.length)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.groupTypeOptions,"item-text":"name","item-value":"id","label":"Group Type","rules":[function (v) { return !!v || 'Group Type is required'; }]},on:{"update":_vm.change},model:{value:(_vm.groupType),callback:function ($$v) {_vm.groupType=$$v},expression:"groupType"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Name","rules":[
                                 function (v) { return !!v || 'Name is required'; },
                                    function (v) { return v.length <= 50 || 'Name must be less than 50 characters'; } ]},on:{"input":_vm.change},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","multiple":"","append-icon":""},on:{"input":_vm.change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                var attrs = ref.attrs;
                                var item = ref.item;
                                var select = ref.select;
                                var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","dark":""},on:{"click":select,"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2029797868),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),_c('v-col',{attrs:{"cols":"6","md":"4","lg":"3"}},[_c('v-color-picker',{attrs:{"dot-size":"25","hide-inputs":"","swatches-max-height":"200","swatches":_vm.swatches,"show-swatches":"","hide-canvas":"","hide-sliders":""},on:{"input":_vm.change},model:{value:(_vm.primaryColor),callback:function ($$v) {_vm.primaryColor=$$v},expression:"primaryColor"}})],1)],1),_c('v-row')],1):_c('sr-not-found',{attrs:{"icon":"fad fa-exclamation","color":"info"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v("No subgroup options for this selection of groups.")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" Please ensure that you have selected groups that are all of the same type, and that the type has possible child types. ")]},proxy:true}],null,false,1289912105)})],1),(_vm.availableIds.length && _vm.groupTypeOptions.length)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0,"color":"warning"},on:{"click":_vm.reset}},[_vm._v("reset")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || !_vm.valid,"color":"success"},on:{"click":_vm.update}},[_vm._v("Create Subgroups")])],1):_vm._e(),(_vm.result)?_c('v-card',[_vm._v(" "+_vm._s(_vm.result)+" ")]):_vm._e()],1):_c('v-card-text',{staticClass:"text--center d-flex align-content-stretch"},[_c('em',[_vm._v("Please select some groups first")])])}
var staticRenderFns = []

export { render, staticRenderFns }