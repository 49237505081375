var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.gt && _vm.keys)?_c('div',[(_vm.showAdmin && (_vm.adminRead || _vm.adminWrite || !_vm.inherited))?_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',{staticClass:"light"},[_c('th',{attrs:{"colspan":"4","disabled":""}},[_c('span',[_vm._v(_vm._s(_vm._f("pluralize")(_vm.adminName))+" in "+_vm._s(!_vm.parent ? _vm.getIndirectArticle(this.gt.name) : "")+" "+_vm._s(_vm.name)+" can:")])])]),_c('tr',[_c('th',[_vm._v("Access")]),_c('th',[_vm._v("Read")]),_c('th',[_vm._v("Write")]),_c('th',[_vm._v("Information")])]),_vm._l((_vm.keys.admin),function(p){return _c('tr',{key:'admin' + p.name},[_c('th',[_vm._v(_vm._s(_vm._f("unCamel")(p.name)))]),_c('td',[_c('i',{staticClass:"fad fa-fw",class:{
              'fa-check success--text': p.read,
              'fa-times error--text': !p.read,
            }})]),_c('td',[_c('i',{staticClass:"fad fa-fw",class:{
              'fa-check success--text': p.write,
              'fa-times error--text': !p.write,
            }})]),_c('td',[_c('small',{domProps:{"innerHTML":_vm._s(p.info)}})])])})],2)]):(_vm.showAdmin)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.parent)+" "+_vm._s(_vm._f("pluralize")(_vm.adminName))+" have no access. ")]):_vm._e(),(_vm.showMember && (_vm.memberRead || _vm.memberWrite || !_vm.inherited))?_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('th',{staticClass:"light",attrs:{"disabled":"","colspan":"4"}},[_c('span',[_vm._v(_vm._s(_vm._f("pluralize")(_vm.memberName))+" in "+_vm._s(_vm.getIndirectArticle(this.gt.name))+" "+_vm._s(_vm.name)+" can:")])])]),_c('tr',[_c('th',[_vm._v("Access")]),_c('th',[_vm._v("Read")]),_c('th',[_vm._v("Write")]),_c('th',[_vm._v("Information")])]),_vm._l((_vm.keys.member),function(p){return _c('tr',{key:'member' + p.name},[_c('th',[_vm._v(_vm._s(_vm._f("unCamel")(p.name)))]),_c('td',[_c('i',{staticClass:"fad fa-fw",class:{
              'fa-check success--text': p.read,
              'fa-times error--text': !p.read,
            }})]),_c('td',[_c('i',{staticClass:"fad fa-fw",class:{
              'fa-check success--text': p.write,
              'fa-times error--text': !p.write,
            }})]),_c('td',[_c('small',{domProps:{"innerHTML":_vm._s(p.info)}})])])})],2)]):(_vm.showMember)?_c('v-alert',{attrs:{"type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.parent)+" "+_vm._s(_vm._f("pluralize")(_vm.memberName))+" have no access. ")]):_vm._e()],1):_c('div',[_c('sr-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }