<template>
  <v-card>
    <v-row>
      <v-col cols="4" md="3" class="border-right">
        <v-treeview
          activatable
          item-children="subgroups"
          selection-type="independent"
          :items="groups"
          :active.sync="selectedGroup"
          @update:active="getSelectedInfo"
        >
          <template v-slot:label="{ item }">
            <sr-group-chip selectOption :path="false" :id="item.id"></sr-group-chip>
          </template>
        </v-treeview>
      </v-col>
      <v-col class="pa-5" v-if="selectedInfo" :key="updateKey">
        <span class="overline"
          >Direct {{ selectedInfo.group.adminName.toLowerCase() | pluralize }} of
          {{ selectedInfo.group.displayText }} can:</span
        >
        <v-list v-if="permissions">
          <v-list-item
            v-for="(p, i) in Object.keys(
              rubric.rubricPermissions[selectedInfo.isNode ? 'nodeAdmin' : 'childAdmin']
            )"
            :key="i"
          >
            <v-list-item-title>
              {{ p | unCamel }}
            </v-list-item-title>
            <v-list-item-action>
              <v-icon
                class="success--text"
                v-show="
                  rubric.rubricPermissions[
                    selectedInfo.isNode ? 'nodeAdmin' : 'childAdmin'
                  ][p]
                "
                >fal fa-check</v-icon
              >
              <v-icon
                class="error--text"
                v-show="
                  !rubric.rubricPermissions[
                    selectedInfo.isNode ? 'nodeAdmin' : 'childAdmin'
                  ][p]
                "
                >fal fa-times</v-icon
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "RubricAccess",
  data() {
    return {
      selectedGroup: [],
      selectedInfo: null,
      updateKey: 0,
    };
  },
  mounted() {
    this.selectedGroup = this.groups ? [this.groups[0].id] : [];
    this.getSelectedInfo();
  },

  methods: {
    getSelectedInfo() {
      console.log(this.selectedGroup[0]);
      if (this.selectedGroup && this.selectedGroup[0]) {
        var isNode = this.rubric.groups.includes(this.selectedGroup[0]);
        var group = this.$store.getters["saved/get"](["groups", this.selectedGroup[0]]);
        var gt = group
          ? this.$store.getters["saved/get"](["groupTypes", group.groupType])
          : null;
        if (group) {
          group.adminName = gt ? gt.adminName : null;
          this.selectedInfo = {
            group: group,
            isNode: isNode,
          };
        }
      }
      this.updateKey++;
    },
  },
  computed: {
    groups() {
      var t = this;
      var nodes = t.rubric.groups;
      var result = [];
      nodes.forEach((n) => {
        result = result.concat(t.$store.getters["saved/node"](n));
      });
      return result;
    },
  },
  props: {
    rubric: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
