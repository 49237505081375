<template>
  <v-row>
    <v-col cols="4" md="3">
      <sr-manage-members
        :group="group"
        :readonly="true"
        :people="people"
        :permissions="permissions"
        class="mb-2"
        nav
      >
      </sr-manage-members>
    </v-col>
    <v-col v-if="group">
      <sr-open-assessments
        :title="`Open Assessment`"
        :groupId="group.id"
        :hideHeaders="true"
        :headers="[
          {
            text: 'Date',
            value: 'created',
            sortable: true,
          },
          {
            text: 'Title',
            value: 'assessmentTitle',
            sortable: true,
          },
          {
            text: 'Rubric',
            value: 'rubricTitle',
            sortable: true,
          },
          {
            text: 'Status',
            value: 'complete',
            sortable: false,
          },
          { text: 'Actions', value: 'actions' },
        ]"
      ></sr-open-assessments>

      <v-row
        v-if="
          groupType &&
          group &&
          permissions &&
          permissions.subgroupList.read &&
          group.subgroups.length
        "
        class="my-2"
        :key="group.id"
      >
        <v-col>
          <sr-group-widget
            :allowFilter="false"
            :asTable="true"
            :showOpaque="true"
            :hideFilter="false"
            :title="`Groups in ${group.metadata.name}`"
            :showAll="true"
            :filterValues="{
              selectedGroupTypes: [],
              selectedParents: [group.id],
              showOnlyDirect: false,
              showOnlyAdmin: false,
              showOnlyNonAdmin: false,
            }"
          >
          </sr-group-widget>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GroupHome",
  computed: {
    groupType() {
      return this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupTypeId])
        : null;
    },
  },
  methods: {
    getGroupTypeName(id) {
      var gt = this.$store.getters["saved/get"](["groupTypes", id]);
      return gt ? this.pluralize(gt.name) : "Groups";
    },
  },
  props: {
    group: {
      type: Object,
    },
    people: {
      type: Array,
    },
    permissions: {
      type: Object,
    },
    rubrics: {
      type: Array,
    },
  },
};
</script>
