<template>
  <div>
    <v-toolbar v-if="miniRubric" dense flat :key="loading" class="d-print-none">
      <v-toolbar-items v-if="rubric && showBreadCrumbs">
        <v-btn icon :to="{ name: 'Home' }" :color="color">
          <i class="fal fa-home"></i>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-title>
        <v-btn class="px-1" color="primary" text exact :to="{ name: 'AllRubrics' }"
          >Rubrics</v-btn
        >
        <span class="text-button ml-2 mt-1">/ {{ miniRubric.title }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && !showBreadCrumbs">
        <sr-rubric-tabs
          :optional="true"
          right
          :grow="false"
          :rubricid="miniRubric.id"
          :canWrite="author"
          :permissions="permissions"
        ></sr-rubric-tabs>
      </v-toolbar-items>

      <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown || showBreadCrumbs">
        <sr-rubric-tabs
          :optional="true"
          right
          :grow="false"
          :rubricid="miniRubric.id"
          :canWrite="author"
          :permissions="permissions"
        ></sr-rubric-tabs>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid v-if="rubric">
      <router-view
        @update="init"
        @stealthUpdate="stealthInit"
        @error="showError"
        v-bind="{ rubric: rubric, permissions: permissions, updateKey: resetCount }"
      >
      </router-view>
    </v-container>
    <v-container v-show="!rubric">
      <sr-loading v-if="loading"></sr-loading>
      <sr-not-found v-else></sr-not-found>
    </v-container>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="snackbarColor"
      :timeout="app_info.snackbarTimeout"
    >
      {{ snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import srRubricTabs from "../newComponents/RubricComponents/TabList.vue";
const editProps = [
  {
    type: "editSchema",
    props: [
      "addRubricUnits",
      "removeRubricUnits",
      "changeRubricUnitMetadata",
      "addRubricObjectives",
      "removeRubricObjectives",
      "addRubricBands",
      "removeRubricBands",
      "changeRubricLevelText",
    ],
  },
  {
    type: "editUnits",
    props: ["addRubricUnits", "removeRubricUnits", "changeRubricUnitMetadata"],
  },
  {
    type: "editObjectives",
    props: ["addRubricObjectives", "removeRubricObjectives"],
  },
  {
    type: "editBands",
    props: ["addRubricBands", "removeRubricBands"],
  },
  {
    type: "editLevelText",
    props: ["changeRubricLevelText"],
  },
  {
    type: "editRubricMetadata",
    props: ["changeRubricMetadata"],
  },
  {
    type: "createRubrics",
    props: ["createRubrics"],
  },
];

export default {
  name: "RubricWrapper",
  components: {
    srRubricTabs,
  },
  data() {
    return {
      rubric: null,
      loading: false,
      error: null,
      snackbar: null,
      showSnackbar: false,
      showBreadCrumbs: false,
      snackbarColor: null,
      resetCount: 0,
      editProps: [],
      //  people: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    getPermission(prop, permissions) {
      debugger;
      var arr = prop.props.map((p) => {
        return permissions[p] == true;
      });
      return arr.length != 0;
    },
    getPermissionObject(permissions) {
      debugger;
      var result = {};
      this.editProps.forEach((prop) => {
        console.log(prop);
        result[prop.type] = this.getPermission(prop, permissions);
      });
      return result;
    },
    showError(snackbar) {
      this.snackbar = snackbar;
      this.snackbarColor = "error";
      this.showSnackbar = true;
    },
    stealthInit(snackbar) {
      this.init(snackbar, true);
    },
    init(snackbar, stealthy) {
      this.editProps = editProps;
      console.log("getting Rubric");
      console.log("snackbar: " + snackbar);
      this.loading = true;
      var miniRubric = this.$store.getters["saved/get"](["rubrics", this.rubricid]);
      if (!miniRubric || !miniRubric.secret) {
        this.$get(`rubrics/${this.rubricid}`).then((response) => {
          debugger;

          if (response.error) {
            this.error = response.text;
          } else {
            this.rubric = response.data;
            if (snackbar) {
              this.snackbar = snackbar;
              this.showSnackbar = true;
              this.snackbarColor = null;
            }
          }
          this.loading = false;
          if (!stealthy) {
            this.resetCount++;
          }
        });
      } else {
        var data = {
          id: this.rubricid,
          secret: miniRubric.secret,
        };
        this.$post(`read/rubric`, data).then((response) => {
          if (response.error) {
            this.error = response.text;
          } else {
            this.rubric = response;

            if (snackbar) {
              this.snackbar = snackbar;
              this.showSnackbar = true;
              this.snackbarColor = null;
            }
          }
          this.loading = false;
          if (!stealthy) {
            this.resetCount++;
          }
        });
      }
    },
  },
  computed: {
    miniRubric() {
      return this.$store.getters["saved/get"](["rubrics", this.rubricid]);
    },
    permissions() {
      var p = {
        write: this.miniRubric ? this.miniRubric.write : false,
        read: true,
        author: this.author,
      }; // this.groupType ? this.admin ? this.groupType.objectPermissions.admin : this.groupType.objectPermissions.member : null
      if (this.rubric) {
        p.access = this.access;
        p.nodeAdmin = false;
        p.editPermissions = null;

        p.childAdmin = false;
        //get is node admin
        var personGroups = this.$store.getters["saved/memberships"](this.$auth.userId);
        if (this.access) {
          if (this.intersect(p.access.nodes, personGroups.adminships).length) {
            p.nodeAdmin = true;
            p.editPermissions = this.rubric.rubricPermissions.nodeAdmin;
          }
          if (this.intersect(p.access.children, personGroups.adminships).length) {
            p.childAdmin = true;
            p.editPermissions = this.rubric.rubricPermissions.nodeAdmin;
          }
        }
        if (this.author) {
          p.editPermissions = this.rubric.rubricPermissions.nodeAdmin;
          Object.keys(p.editPermissions).forEach((k) => {
            p.editPermissions[k] = true;
          });
        }

        p.permissionObject = p.editPermissions
          ? this.getPermissionObject(p.editPermissions)
          : null;
      }
      return p;
    },

    access() {
      if (this.rubric) {
        var t = this;
        var allGroups = t.rubric.groups;
        var nodes = [];
        var source = t.$store.getters["saved/get"]([
          "frameworks",
          t.rubric.source.frameworkId,
        ]);
        var children = [];
        if (source) {
          nodes = source.nodes.map((x) => x.groupId);
          children = allGroups.filter((x) => !nodes.includes(x));
        } else {
          children = allGroups;
        }
        return {
          nodes: nodes,
          children: children,
        };
      }

      return null;
    },
    author() {
      return this.miniRubric && this.miniRubric.authors && this.miniRubric.authors.length
        ? this.miniRubric.authors.includes(this.$auth.userId)
        : false;
    },
  },
  props: {
    rubricid: {
      type: String,
    },
  },
};
</script>
