<template>
  <div>
    <v-row v-if="!isCreate">
      <v-col>
        <sr-group-widget
          :stack="true"
          v-if="group && permissions.subgroupList.read && group.subgroups.length"
          class="mb-2"
          :allowFilter="false"
          :asTable="false"
          :showOpaque="true"
          :hideToolbar="false"
          :hideFilter="false"
          :admin="false"
          title="Subgroups"
          :showOnlyDirect="false"
          :showAll="true"
          :permissions="permissions"
          :filterValues="{
            selectedGroupTypes: [],
            selectedParents: [group.id],
            showOnlyDirect: true,
            showOnlyAdmin: false,
            showOnlyNonAdmin: false,
          }"
        >
        </sr-group-widget>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :loading="saving" v-if="permissions.subgroupList.write">
          <v-toolbar dense flat>
            <v-toolbar-title
              >Add <span v-if="!isCreate">Sub{{ label.toLowerCase() }}</span>
              <span v-else>{{ label }}</span></v-toolbar-title
            >
          </v-toolbar>
          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col v-if="!hideType">
                <v-select
                  :label="`Create a new ${
                    !isCreate ? label.toLowerCase() : label
                  } of type...`"
                  :items="groupTypeItems"
                  clearable
                  deletable-chips
                  v-model="type"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          dense
                          v-model="typeSearch"
                          append-icon="mdi-magnify"
                          label="Search Types"
                          clearable
                          single-line
                          hide-details
                          :disabled="saving"
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="type">
                <v-text-field
                  :label="`New ${selectedType.name.toLowerCase()} name...`"
                  v-model="subgroupName"
                  :disabled="saving"
                >
                </v-text-field>
                <v-checkbox
                  :label="`Add me as ${getIndirectArticle(selectedType.adminName)} ${
                    selectedType.adminName
                  }`"
                  v-if="selectedType"
                  v-model="addSelfAdmin"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="type">
            <v-spacer></v-spacer>
            <v-btn text :disabled="saving" color="warning" @click="reset">Reset</v-btn>
            <v-btn
              text
              color="success"
              @click="createSubGroup"
              :disabled="!subgroupName || subgroupName.length == 0 || saving"
              >Create new {{ selectedType.name }}
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-row v-if="type">
              <v-col>
                <v-card>
                  <v-toolbar dense>
                    <v-toolbar-title class="grey--text">
                      <i class="mr-2 fad fa-lock"></i>
                      New {{ selectedType.name }} Permission Information</v-toolbar-title
                    >
                    <template v-slot:extension>
                      <v-tabs v-model="tab">
                        <v-tab :key="0">
                          {{
                            subgroupName && subgroupName.length
                              ? subgroupName
                              : ` New ${selectedType.name}`
                          }}
                        </v-tab>
                        <v-tab :key="1">
                          {{ group.metadata.name }}
                        </v-tab>
                        <v-tab :key="2">
                          Sibling {{ selectedType.name | pluralize }}
                        </v-tab>
                      </v-tabs>
                    </template>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-tabs-items v-model="tab">
                    <v-tab-item :key="0">
                      <sr-permissions
                        v-if="selectedType"
                        :gt="selectedType"
                      ></sr-permissions>
                    </v-tab-item>
                    <v-tab-item :key="1">
                      <sr-permissions
                        :parent="`${group.metadata.name}`"
                        :adminTitle="groupType.adminName"
                        :memberTitle="groupType.membername"
                        :inherited="true"
                        :gt="groupType"
                        v-if="groupType"
                        :adminRead="
                          groupType.objectPermissions.admin.subgroupPermissions.read
                        "
                        :adminWrite="
                          groupType.objectPermissions.admin.subgroupPermissions.write
                        "
                        :memberRead="
                          groupType.objectPermissions.member.subgroupPermissions.read
                        "
                        :memberWrite="
                          groupType.objectPermissions.member.subgroupPermissions.write
                        "
                        :groupType="type"
                      ></sr-permissions>
                    </v-tab-item>
                    <v-tab-item :key="2">
                      <sr-permissions
                        :parent="`Sibling ${pluralize(selectedType.name)}`"
                        :adminTitle="selectedType.adminName"
                        :memberTitle="selectedType.membername"
                        v-if="selectedType"
                        :gt="selectedType"
                        :inherited="true"
                        :adminRead="
                          selectedType.objectPermissions.admin.accessSiblings.read &&
                          selectedType.objectPermissions.admin.subgroupPermissions.read
                        "
                        :adminWrite="
                          selectedType.objectPermissions.admin.accessSiblings.write &&
                          selectedType.objectPermissions.admin.subgroupPermissions.write
                        "
                        :memberRead="
                          selectedType.objectPermissions.member.accessSiblings.read &&
                          selectedType.objectPermissions.member.subgroupPermissions.read
                        "
                        :memberWrite="
                          selectedType.objectPermissions.member.accessSiblings.write &&
                          selectedType.objectPermissions.member.subgroupPermissions.write
                        "
                        :groupType="type"
                      ></sr-permissions>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "GroupSubgroups",
  data() {
    return {
      typeSearch: null,
      type: null,
      subgroupName: null,
      addSelfAdmin: true,
      tab: 0,
      saving: false,
    };
  },
  computed: {
    hideType() {
      return (
        this.$route.query.type &&
        this.$store.getters["saved/get"](["groupTypes", this.$route.query.type])
      );
    },
    isCreate() {
      return this.$route.name == "CreateGroup";
    },
    permissionCascade() {
      return [];
    },
    selectedType() {
      return this.type
        ? this.$store.getters["saved/get"](["groupTypes", this.type])
        : null;
    },
    groupType() {
      return this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupTypeId])
        : null;
    },

    groupTypeItems() {
      return this.groupType
        ? this.groupType.subgroups
            .map((g) => {
              var gt = this.$store.getters["saved/get"](["groupTypes", g]);
              return {
                text: gt ? gt.name : "",
                value: gt ? gt.id : "",
              };
            })
            .filter((x) => {
              if (this.typeSearch && this.typeSearch.length != 0) {
                return x.text.toLowerCase().includes(this.typeSearch.toLowerCase());
              } else {
                return x;
              }
            })
        : [];
    },
  },
  created() {
    this.reset();
  },
  methods: {
    reset() {
      this.type = this.$route.query.type;
      this.subgroupName = null;
    },
    createSubGroup() {
      this.saving = this.group.metadata.primaryColor ?? "secondary";
      var colorIndex =
        this.app_info.colorArr.indexOf(this.group.metadata.primaryColor) ?? 0;
      var color = this.app_info.colorArr[colorIndex + 1];
      var group = {
        groupTypeId: this.type,
        parentId: this.group.id,
        isAdministrator: this.addSelfAdmin,
        metadata: {
          name: this.subgroupName,
          primaryColor: color,
        },
      };

      this.$post("entities/groups/add", group).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.$get(`saved/sessiondata/delete`).then(() => {
            this.$store.dispatch("saved/get_groups");
            this.$emit("update", "Changes Saved");
            this.$emit("newGroup", response.id);
            this.reset();
          });
        }
        this.saving = false;
      });
    },
  },
  props: {
    group: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
    label: {
      type: String,
      default: "Group",
    },
  },
};
</script>
