<template>
  <v-app id="app" ref="app">
    <v-system-bar
      app
      dark
      class="py-3 d-print-none"
      v-if="branch != 'Production' || $auth.isTester"
    >
      <span v-if="netlify == 'true'" class="white--text">Running on Netlify</span>
      <span v-else>Local</span>
      <span class="pl-5"
        ><v-btn small text href="https://guide.smartrubric.com/#/core/changelog">{{
          version
        }}</v-btn></span
      >
      <v-spacer></v-spacer>

      <v-chip label tile class="mx-1 pr-4 no-padder" small color="green ">
        <v-chip
          style="font-size: 1em"
          color="green darken-1"
          label
          class="pr-3 pl-6 mr-3"
        >
          <v-icon small left color="white"> fal fa-browser </v-icon>
          Front End
        </v-chip>
        <span style="font-size: 1em">{{ branch }}</span>
      </v-chip>

      <v-chip label v-if="backend" class="mx-1 pr-4 no-padder" small color="blue ">
        <v-chip style="font-size: 1em" color="blue darken-1" label class="pr-3 pl-6 mr-3">
          <v-icon small left color="white"> fal fa-server </v-icon>
          API
        </v-chip>
        <span style="font-size: 1em"> {{ backend }}</span>
      </v-chip>

      <v-chip
        label
        v-if="database"
        class="mx-1 pr-4 no-padder"
        small
        color="yellow darken-3"
      >
        <v-chip
          style="font-size: 1em"
          color="yellow darken-4"
          label
          class="pr-3 pl-6 mr-3"
        >
          <v-icon small left color="white"> fal fa-database </v-icon>
          Database
        </v-chip>
        <span style="font-size: 1em"> {{ database }}</span>
      </v-chip>
    </v-system-bar>
    <v-system-bar
      app
      color="error"
      dark
      v-if="!connected && $auth.isAuthenticated && showIfOffline"
    >
      Application Offline: View Only
    </v-system-bar>
    <router-view @login="login"></router-view>
  </v-app>
</template>

<script>
import Template from "./configs/layout";
const app = process.env.VUE_APP_ORG;
const branch = process.env.VUE_APP_BRANCH;
const help = process.env.VUE_APP_HELP_URI;
const netlify = process.env.VUE_APP_NETLIFY;
const apiEndpoint = process.env.VUE_APP_API_URL;
const version = process.env.VUE_APP_VERSION;
export default {
  name: "App",
  data() {
    return {
      templateDoc: Template,
      showIfOffline: true,
      branch: branch,
      help: `${help}/changelog`,
      environment: null,
      backend: null,
      netlify: netlify,
      version: version,
    };
  },
  provide() {
    return {
      templateDoc: this.template,
      isAdministrator: this.$auth.isAdmin,
      connected: this.connected,
    };
  },

  created() {
    //       var v = this;
    //   setTimeout(function () {
    //     v.showIfOffline = true;
    //   },17000);
    var t = this;
    t.$publicGet("public/environment").then((r) => {
      var data = r.data ? r.data : "error";
      this.environment = data.database;
      this.backend = data.branch;
    });
  },
  methods: {
    login() {
      if (!this.$auth.isAuthenticated) {
        this.$auth.loginWithRedirect();
      } else {
        // this.lock = true;
        console.log("Logging Out");
        this.$store.dispatch("saved/reset");
        this.$store.dispatch("management/reset");
        localStorage.clear();
        this.$auth.logout({ returnTo: window.location.origin });
      }

      // this.$auth.logout({
      //  returnTo: window.location.origin,
      // });
    },
  },
  computed: {
    template() {
      return this.templateDoc[app] ?? this.templateDoc.default;
    },
    database() {
      if (this.netlify == "true" || this.netlify == true) {
        var isSandbox = apiEndpoint ? apiEndpoint.includes("sandbox") : false;
        return isSandbox ? "Sandbox" : "Production";
      } else {
        return this.environment;
      }
    },
    connected() {
      return this.$auth.isAuthenticated ? this.$store.getters["saved/connected"] : true;
    },
  },
};
</script>
