<style>
.slash::after {
  content: "/";
  display: block;
  color: var(--v-light-darken2) !important;
  padding-left: 10px;
}
.slash:last-of-type::after {
  content: "";
}
</style>
<template>
  <v-breadcrumbs-item
    v-if="formattedValue && value"
    class="slash"
    append
    :disabled="disabled"
    exact
    :to="toRoute"
    >{{ value | titleCase }}</v-breadcrumbs-item
  >
</template>

<script>
export default {
  name: "breadcrumb",
  props: ["route", "disabled", "to"],
  methods: {
    groupName(id) {
      var g = this.$store.getters["entities/group"](id);
      if (g) {
        return g.metadata.name ?? "";
      }
    },
    isFunction(functionToCheck) {
      return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === "[object Function]"
      );
    },
  },
  computed: {
    toRoute() {
      if (this.route.mode) {
        return null;
      } else {
        var to = {
          name:
            this.route.name ??
            (this.route.meta ? this.route.meta.toRoute : null),
        };
        return to;
      }
    },
    value() {
      if (this.formattedValue.length) {
        return this.formattedValue;
      }
      if (this.formattedValue.mode) {
        return this.formattedValue.mode;
      }
      if (this.isFunction(this.formattedValue)) {
        return this.formattedValue();
      }
      return null;
    },
    formattedValue() {
      if (this.route.meta) {
        var getter = this.route.meta.bcGetter;
        if (getter) {
          var id;
          if (this.route.meta.bcIsChild) {
            switch (getter) {
              case "entities/gRoute":
                id = this.$route.params.groupid;
                break;
              case "people/pRoute":
                id = this.$route.params.individualid;
                break;
              case "frameworks/framework":
                id = this.$route.params.frameworkid;
                break;
              case "rubrics/rubric":
                id = this.$route.params.rubricid;
                break;
              case "assessments/assessment":
                id = this.$route.params.assessmentid;
                break;
            }
          } else {
            id = this.$route.params.grouptypeid;
          }
          if (id) {
            var bc = this.$store.getters[this.route.meta.bcGetter](id);
            var name = this.route.meta.bcLinkText(bc);
            return name ?? name();
          }
        }
        return this.route.meta.bcLinkText;
      }
      return this.route;
    },
    loadingText() {
      const loadingText = this.route.meta.bcLoadingText ?? null;
      return loadingText ? loadingText : "Loading...";
    },
  },
};
</script>
