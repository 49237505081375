<template>
  <div ref="ruler" style="white-space: nowrap">
    <span class="pink" v-for="(item, i) in items" :key="i">{{ item }}</span>
  </div>
</template>

<script>
export default {
  name: "OverflowChips",
  computed: {},
  mounted() {
    console.log(this.visualLength());
  },
  methods: {
    visualLength() {
      var ruler = this.$refs.ruler;
      ruler.innerHTML = this;
      return ruler.offsetWidth;
    },
  },
  props: {
    width: {
      type: Number,
      default: 200,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
