var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{key:_vm.resetKey,attrs:{"loading":_vm.saving}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Manage "+_vm._s(_vm._f("pluralTerm")("Assessee")))])],1),(_vm.editableSettings)?_c('v-form',{key:_vm.resetCount,model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-divider'),_c('v-data-table',{staticClass:"row-keys",attrs:{"tile":"","items-per-page":-1,"hide-default-footer":"","hide-default-header":"","items":_vm.editableSettings.individualAssessments,"item-class":_vm.status,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.individualId",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'hover-parent': !_vm.saving }},[(!_vm.saving)?_c('v-btn',{staticClass:"hover-item",attrs:{"color":"error","small":"","text":"","icon":""},on:{"click":function($event){return _vm.removeIa(item.individualId, item.inProgress)}}},[_c('v-icon',[_vm._v("fal fa-times")])],1):_vm._e(),_c('sr-person-chip',{attrs:{"id":item.individualId}})],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.complete)?_c('v-chip',{attrs:{"label":"","outlined":"","small":"","color":"success"}},[_vm._v("Completed")]):_c('v-chip',{attrs:{"label":"","outlined":"","small":""}},[_vm._v("Incomplete")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.canInvite && !item.complete && !item.invited)?_c('v-btn',{attrs:{"disabled":_vm.changes > 0 || _vm.saving,"small":"","text":"","color":"success"},on:{"click":function($event){return _vm.sendEmail([item.individualId], _vm.assessment.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-envelope")]),_vm._v(_vm._s(_vm._f("term")("Invite"))+" ")],1):_vm._e(),(item.canInvite && !item.complete && item.invited)?_c('v-btn',{attrs:{"disabled":_vm.changes > 0 || _vm.saving,"small":"","text":"","color":"warning"},on:{"click":function($event){return _vm.sendEmail([item.individualId], _vm.assessment.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-envelope")]),_vm._v(_vm._s(_vm._f("term")("Remind"))+" ")],1):_vm._e(),(!item.canInvite)?_c('v-btn',{attrs:{"small":"","text":"","disabled":""}},[_vm._v("Not a user")]):_vm._e()]}},{key:"footer",fn:function(){return [_c('v-divider'),_c('small',{staticClass:"ml-4 mb-2"},[_vm._v("Hover over the "+_vm._s(_vm._f("term")("assessee"))+"'s name to delete")]),_c('div',{staticClass:"d-flex ml-4 align-center"},[_c('v-select',{attrs:{"loading":_vm.loading,"disabled":_vm.unassignedMembers.length == 0,"label":_vm.unassignedMembers.length == 0
                    ? ("No unassigned " + (_vm.pluralize(_vm.memberName.toLowerCase())))
                    : ("Add " + (_vm.$getTerm('Assessee'))),"items":_vm.unassignedMembers},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('sr-person-chip',{staticClass:"mb-2",attrs:{"small":"","id":item,"selectOption":""}})]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('sr-person-chip',{attrs:{"small":"","id":item,"selectOption":""}})]}}],null,false,4203435180),model:{value:(_vm.selectedMember),callback:function ($$v) {_vm.selectedMember=$$v},expression:"selectedMember"}}),_c('v-btn',{attrs:{"disabled":!_vm.selectedMember || _vm.saving,"color":"primary","text":""},on:{"click":function($event){return _vm.addIa()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add And Save ")],1)],1)]},proxy:true}],null,false,564592244)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }