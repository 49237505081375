<template>
    <v-container>
         <sr-breadcrumbs :items="breadcrumbs"></sr-breadcrumbs>

        <v-row v-if="!groupId">
            <v-col>
                <sr-group-widget 
                    :headers="[{text: 'Name',align: 'start',sortable: true,value: 'displayName' },{text: 'Type', align: 'center',value: 'groupType' },]"
                    :title="'Select Parent Group'"
                    @input="updateGroupId"
                    asTable 
                    :selectable="true"
                    showAll
                    :multiSelect="false">
                </sr-group-widget>
            </v-col>
        </v-row>
       
            <router-view v-if="group"
                        @newGroup="newGroup" 
                        v-bind="{group: group, people: people, rubrics:rubrics, permissions: permissions, groupType: type, label: label}">
            </router-view>
        <sr-loading v-if="loading"></sr-loading>
      
    </v-container>
</template>

<script>
export default {
    name: 'CreateGroupWrapper',
    data(){
        return {
            group: null,
            loading: false,
            error: null,
            snackbar: null,
            showSnackbar: false,
            showBreadCrumbs: false,
            people: [],
            rubrics: []
        }
    },
    watch:{
        groupId: function(){
            this.init(); 
        }
    },
    created(){
       this.init();
    },
    methods:{
        newGroup(i){
            var route = {
                name: 'GroupSettings',
                params: {
                    groupid: i
                }
            }
            this.$router.push(route);
        },
        updateGroupId(i){
           this.$router.push({ query: Object.assign({}, this.$route.query, { group: i }) }).catch(()=>{});

           
        },
         init(snackbar){
            console.log("getting Group")
            console.log("snackbar: "+ snackbar)
            if(this.$route.query.group){
               this.loading = true;

                 this.$get(`entities/groups/${this.$route.query.group}`).then((response)=>{
                debugger;
                if(response.error){
                    this.error = response.text
                }
                else{
                    this.group = response.data;
                   
                }
                this.loading = false;
            });
            }
        }
    },
    computed:{
        admin(){
            return this.group != null ? this.group.isAdministrator : false;
        },
          permissions(){
           
            return this.groupType ? this.admin ? this.groupType.objectPermissions.admin : this.groupType.objectPermissions.member : null
        },
         groupType(){
            return this.group ? this.$store.getters['saved/get'](['groupTypes', this.group.groupTypeId]) : null
        },
        groupId(){
            return this.$route.query.group; 
        },
        label(){
            return this.$route.query.label ?? 'Group';
        },
        type(){
            return this.$route.query.type;
        },
        breadcrumbs(){
                var breadcrumbs = [{text: 'Home',to:{name: 'Home'} }, {text: 'Groups', to:{name: 'AllGroups'}}];
                    var title = this.$route.meta.bcLinkText; 
                    if(title != null){
                        breadcrumbs[1].exact= true; 
                        breadcrumbs[1].text="All Groups"; 

                        breadcrumbs.push({
                                text: this.toTitleCase(title), 
                                to:{name: 'CreateGroup', query: {type: this.$route.query.type, title: title}},
                                disabled: true
                            });
                    }
                    else {
                            breadcrumbs[1].disabled=true;
                    }
                    if(this.group){
                        breadcrumbs[breadcrumbs.length-1].disabled = false;
                         breadcrumbs.push({
                                text: 'Subgroup in ' + this.group.metadata.name, 
                                disabled: true
                            });
                    }
                
                return breadcrumbs; 
            },
    },
   
    props:{
        title:{
            type: String
        },
     
    }
}
</script>