<template>
  <div v-if="gt && keys">
    <v-simple-table dense v-if="showAdmin && (adminRead || adminWrite || !inherited)">
      <tbody>
        <tr class="light">
          <th colspan="4" disabled>
            <span
              >{{ adminName | pluralize }} in
              {{ !parent ? getIndirectArticle(this.gt.name) : "" }} {{ name }} can:</span
            >
          </th>
        </tr>
        <tr>
          <th>Access</th>
          <th>Read</th>
          <th>Write</th>
          <th>Information</th>
        </tr>
        <tr v-for="p in keys.admin" :key="'admin' + p.name">
          <th>{{ p.name | unCamel }}</th>

          <td>
            <i
              class="fad fa-fw"
              :class="{
                'fa-check success--text': p.read,
                'fa-times error--text': !p.read,
              }"
            ></i>
          </td>
          <td>
            <i
              class="fad fa-fw"
              :class="{
                'fa-check success--text': p.write,
                'fa-times error--text': !p.write,
              }"
            ></i>
          </td>
          <td><small v-html="p.info"></small></td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-alert type="error" dense v-else-if="showAdmin">
      {{ parent }} {{ adminName | pluralize }} have no access.
    </v-alert>
    <v-simple-table dense v-if="showMember && (memberRead || memberWrite || !inherited)">
      <tbody>
        <tr>
          <th disabled colspan="4" class="light">
            <span
              >{{ memberName | pluralize }} in {{ getIndirectArticle(this.gt.name) }}
              {{ name }} can:</span
            >
          </th>
        </tr>
        <tr>
          <th>Access</th>
          <th>Read</th>
          <th>Write</th>
          <th>Information</th>
        </tr>
        <tr v-for="p in keys.member" :key="'member' + p.name">
          <th>{{ p.name | unCamel }}</th>

          <td>
            <i
              class="fad fa-fw"
              :class="{
                'fa-check success--text': p.read,
                'fa-times error--text': !p.read,
              }"
            ></i>
          </td>
          <td>
            <i
              class="fad fa-fw"
              :class="{
                'fa-check success--text': p.write,
                'fa-times error--text': !p.write,
              }"
            ></i>
          </td>
          <td><small v-html="p.info"></small></td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-alert v-else-if="showMember" type="error" dense>
      {{ parent }} {{ memberName | pluralize }} have no access.
    </v-alert>
  </div>
  <div v-else>
    <sr-loading></sr-loading>
  </div>
</template>

<script>
export default {
  name: "Permissions",

  methods: {
    getKeys(admin) {
      var inheritRead = admin ? this.adminRead : this.memberRead;
      var inheritWrite = admin ? this.adminWrite : this.memberWrite;
      var filtered = this.permissions.map((x) => {
        var r = admin
          ? this.gt.objectPermissions.admin[x.value]
          : this.gt.objectPermissions.member[x.value];
        var read =
          (r.read && !this.inherited) || (r.read && this.inherited && inheritRead);
        var write =
          (r.write && !this.inherited) || (r.write && this.inherited && inheritWrite);
        return {
          name: x.name,
          info: x.info,
          read: read,
          write: write,
        };
      });
      return this.inherited
        ? filtered.filter(
            (f) =>
              !["subgroupPermissions", "accessSiblings", "self", "selfDocs"].includes(
                f.name
              )
          )
        : filtered;
    },
  },
  computed: {
    keys() {
      if (this.gt) {
        return {
          admin: this.getKeys(true),
          member: this.getKeys(false),
        };
      }
      return null;
    },
    permissions() {
      return this.gt
        ? [
            {
              name: `${this.parent ? "New" : ""} ${this.gt.name} Information`,
              value: "metadata",
              info: `General information about the ${this.gt.name.toLowerCase()} such as name, description, tags, colour etc. `,
            },
            {
              name: `List of  ${this.pluralize(this.gt.memberName)} in the ${
                this.parent ? "New" : ""
              } ${this.gt.name}`,
              value: "memberList",
              info: `Access allows the user to see names only, no other personal data. Write access means the ability to add and remove ${this.pluralize(
                this.gt.memberName
              ).toLowerCase()} from this ${this.gt.name.toLowerCase()}.<br/> <em>Note: write access alone does not allow the user to create new user accounts -- this permission must be configured seperately.</em> `,
            },
            {
              name: `List of ${this.possibleChildren} Inside the ${
                this.parent ? "New" : ""
              } ${this.gt.name}`,
              value: "subgroupList",
              info: `Write access means the ability to add and remove ${this.possibleChildren.toLowerCase()} from this ${this.gt.name.toLowerCase()}.`,
            },
            {
              name: "Documents",
              value: "docs",
              info: `Documents (assessments, rubrics and report data) pertaining to ${this.pluralize(
                this.gt.memberName
              ).toLowerCase()} in this ${this.gt.name}`,
            },
            {
              name: "Own Documents",
              value: "selfDocs",
              info: `Documents (assessments and report data) pertaining to themselves in the context of this ${this.gt.name.toLowerCase()}.`,
            },

            {
              name: `Personal information about ${this.gt.name} ${this.pluralize(
                this.gt.adminName
              )}`,
              value: "administrators",
              info: `All ${this.gt.name.toLowerCase()} ${this.pluralize(
                this.gt.memberName
              ).toLowerCase()} and ${this.pluralize(
                this.gt.adminName
              ).toLowerCase()} can see the names of ${this.pluralize(
                this.gt.adminName
              )}.`,
            },
            {
              name: `Personal information about ${this.gt.name} ${this.pluralize(
                this.gt.memberName
              )}`,
              value: "members",
              info: `Access detailed personal information about ${this.pluralize(
                this.gt.memberName
              ).toLowerCase()} in this ${this.gt.name}.`,
            },
            {
              name: `Access Sibling ${this.pluralize(this.gt.name)}`,
              value: "accessSiblings",
              info: `Access direct sibling ${this.pluralize(
                this.gt.name
              ).toLowerCase()} (must share a parent ${this.parentGroupType.toLowerCase()}) as if the user belongs to that ${this.pluralize(
                this.gt.name
              ).toLowerCase()}`,
            },
            {
              name: `Access Child ${this.pluralize(this.gt.name)}`,
              value: "subgroupPermissions",
              info: `Access ${this.possibleChildren.toLowerCase()} inside this ${this.gt.name.toLowerCase()} as if the user belonged to that group.`,
            },
            {
              name: `Personal information about ${this.pluralize(
                this.gt.adminName
              )} in direct child groups`,
              value: "childAdmins",
              info: `All parent group ${this.pluralize(
                this.gt.adminName
              ).toLowerCase()} can see the names of ${this.pluralize(
                this.gt.adminName
              )} if read access is enabled for 'Access Child Groups' on the direct parent group.`,
            },
          ]
        : [];
    },
    adminName() {
      return this.adminTitle || this.gt.adminName;
    },
    parentGroupType() {
      var parents = this.groupType
        ? this.$store.getters["saved/gtParents"](this.groupType)
        : [];
      return parents.length != 0 ? parents[0].name : "group";
    },
    possibleChildren() {
      var gts = this.gt
        ? this.gt.subgroups
            .map((s) => {
              var type = this.$store.getters["saved/get"](["groupTypes", s]);
              return type ? type.name : null;
            })
            .filter((x) => x)
        : [];
      var result = "";
      gts.forEach((gt, i) => {
        if (i == 1) {
          result = this.pluralize(gt);
        } else {
          if (i == gts.length - 1) {
            result = `${result} and ${this.pluralize(gt)}`;
          } else {
            result = `${result}, ${this.pluralize(gt)}`;
          }
        }
      });
      return gts.length != 0 ? result : "child subgroups";
    },
    memberName() {
      return this.memberTitle || this.gt.memberName;
    },
    name() {
      return this.parent || this.gt.name;
    },
  },
  props: {
    groupType: {
      type: String,
    },
    adminRead: {
      type: Boolean,
      default: false,
    },
    adminWrite: {
      type: Boolean,
      default: false,
    },
    memberRead: {
      type: Boolean,
      default: false,
    },
    memberWrite: {
      type: Boolean,
      default: false,
    },
    adminTitle: {
      type: String,
    },
    parent: {
      type: String,
    },
    memberTitle: {
      type: String,
    },
    inherited: {
      type: Boolean,
      default: false,
    },
    showAdmin: {
      type: Boolean,
      default: true,
    },
    showMember: {
      type: Boolean,
      default: true,
    },
    gt: {
      type: Object,
    },
  },
};
</script>
