
const terminology ={
    default:{
        assessment: 'Assessment',
        assess: 'Assess',
        rubric: 'Rubric',
        groupicon: 'fa-users-class',
        assessmenticon: 'fa-edit',
        invite: 'Invite',        
        remind: 'Remind',
        assessee: 'Person',
        afl: 'Report',
        skillprofile: 'Skill Breakdown',
        unit: 'Unit',
        reportview: 'Report',
        markingview: 'Edit',
        point: 'Mark',
        comment: 'Comment'
      },
    srCore: {
        assessment: 'Assessment',
        assess: 'Assess',
        assessee: 'Person',
        invite: 'Invite',
        remind: 'Remind',
        skillprofile: 'Objective Breakdown',
        unit: 'Unit',
        reportview: 'AFL',
        markingview: 'Mark',
        point: 'Mark',
        comment: 'Comment'


    },
    isp:{
        assessment: 'Evaluation',
        assess: 'Evaluate',
        groupicon: 'fa-circle',
        assessee: 'Subject',
        invite: 'Invite',
        remind: 'Remind',
        skillprofile: 'Criteria Breakdown',
        unit: 'Criterion',
        reportview: 'Report',
        markingview: 'Edit',
        point: 'Point',
        comment: 'Evidence'


    },
    designCEO:{
        assessment: 'Evaluation',
        assess: 'Evaluate',
        groupicon: 'fa-users',
        assessee: 'Participant',
        invite: 'Invite to complete',
        remind: 'Send Reminder',
        skillprofile: 'Gift Profile',
        unit: 'Unit',
        reportview: 'Report',
        markingview: 'Evaluate',
        point: 'Point',
        comment: 'Comment'

    },
    plurals: {
        person: 'people',
        company: 'companies',
        criterion: 'criteria'
    }
}
export default terminology;