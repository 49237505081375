<template>
  <v-card flat>
    <v-row>
      <v-col sm="5" md="4" lg="3">
        <v-treeview
          :active.sync="active"
          :items="trees"
          item-text="name"
          item-key="id"
          :open.sync="open"
          activatable
          color="warning"
          item-children="subgroups"
          :open-on-click="false"
          transition
          @update:active="resetGT"
        >
        </v-treeview>
      </v-col>
      <v-col class="mx-2">
        <v-card v-for="a in activeGroupTypes" :key="a.id" :loading="saving">
          <v-tabs color="secondary" v-model="tab" right>
            <v-tab v-for="item in tabItems(a)" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="pluralize(a.name)">
              <v-divider></v-divider>
              <sr-group-widget
                :bordered="false"
                hideToolbar
                class="mb-3"
                asTable
                :allowFilter="false"
                showAll
                :filterValues="{ selectedParents: [], selectedGroupTypes: [a.id] }"
              ></sr-group-widget>
            </v-tab-item>
            <v-tab-item key="Permissions">
              <v-divider></v-divider>

              <sr-permissions :gt="a"></sr-permissions>
            </v-tab-item>
            <v-tab-item key="Baseline">
              <v-divider></v-divider>
              <sr-view-baseline :gt="a"></sr-view-baseline>
            </v-tab-item>
            <v-tab-item v-if="$auth.isAdmin" key="Settings">
              <v-divider></v-divider>
              <v-toolbar dense color="primary" dark flat>
                <v-toolbar-title>Add a new Group Type to {{ a.name }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  dense
                  v-model="newGTName"
                  label="New type name"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="success" :disabled="saving" @click="createGT(a)"
                  >Update</v-btn
                >
              </v-card-actions>

              <v-alert tile text v-if="error" type="error">
                {{ error }}
              </v-alert>
              <v-divider></v-divider>
              <v-toolbar dense color="secondary" dark flat>
                <v-toolbar-title>Edit {{ a.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items
                  ><v-btn @click="prepareEdit(a)" icon
                    ><v-icon>fal fa-pencil</v-icon></v-btn
                  ></v-toolbar-items
                >
              </v-toolbar>
              <v-card-text v-if="editGroup">
                <v-text-field dense v-model="editGroup.name" label="Name"></v-text-field>
                <v-text-field
                  dense
                  v-model="editGroup.adminName"
                  label="Admin Name"
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="editGroup.memberName"
                  label="Member Name"
                ></v-text-field>
                <v-subheader><strong>Permissions</strong></v-subheader>
                <v-divider></v-divider>
                <v-list>
                  <v-subheader class="overline">{{ editGroup.adminName }}</v-subheader>
                  <v-list-item
                    class="white darken-2"
                    v-for="(p, i) in Object.keys(editGroup.objectPermissions.admin)"
                    :key="i"
                  >
                    <v-list-item-content>{{ p | unCamel }} </v-list-item-content>
                    <v-list-item-action>
                      <span>
                        <v-checkbox
                          v-model="editGroup.objectPermissions.admin[p].read"
                          label="read"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="editGroup.objectPermissions.admin[p].write"
                          label="write"
                        ></v-checkbox> </span
                    ></v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list>
                  <v-subheader class="overline">{{ editGroup.memberName }}</v-subheader>
                  <v-list-item
                    class="white darken-2"
                    v-for="(p, i) in Object.keys(editGroup.objectPermissions.member)"
                    :key="i"
                  >
                    <v-list-item-content>{{ p | unCamel }} </v-list-item-content>
                    <v-list-item-action>
                      <span>
                        <v-checkbox
                          v-model="editGroup.objectPermissions.member[p].read"
                          label="read"
                        ></v-checkbox>
                        <v-checkbox
                          v-model="editGroup.objectPermissions.member[p].write"
                          label="write"
                        ></v-checkbox> </span
                    ></v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions v-if="editGroup">
                <v-spacer></v-spacer>
                <v-btn text color="warning" :disabled="saving" @click="resetGT"
                  >Cancel</v-btn
                >
                <v-btn text color="success" :disabled="saving" @click="updateGT(a)"
                  >Update</v-btn
                >
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TreeHandler from "../assets/scripts/treeHandler.js";
const tree = TreeHandler;

export default {
  name: "GroupTreeWidget",
  data() {
    return {
      active: [],
      open: [],
      tab: null,
      newGTName: null,
      saving: false,
      error: null,
      editGroup: null,
    };
  },
  methods: {
    getSubGroups(item) {
      debugger;
      console.log(item);
      // var gt = this.groupTypes.find(x=>x.id == id);
      return this.$r.clone(this.groupTypes).filter((x) => item.subgroups.includes(x.id));
    },
    tabItems(a) {
      var result = [this.pluralize(a.name), "Permissions", "Baseline"];
      if (this.$auth.isAdmin) {
        result.push("Settings");
      }
      return result;
    },
    resetGT() {
      this.newGTName = null;
      this.error = null;
      this.editGroup = null;
    },
    prepareEdit(a) {
      this.editGroup = null;
      this.editGroup = this.$r.clone(a);
    },
    createGT(a) {
      var data = {
        parentId: a.id,
        name: this.newGTName,
        orgId: this.org_id,
        memberName: "member",
        adminName: "admin",
      };
      this.saving = true;
      this.$post("entities/grouptypes/upsert", data).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.$store.dispatch("saved/get_groupTypes");
          this.resetGT();
          // this.$emit('update', 'Changes Saved')
        }
        this.saving = false;
      });
    },
    updateGT() {
      this.saving = true;
      this.$post("entities/grouptypes/upsert", this.editGroup).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.$store.dispatch("saved/get_groupTypes");
          this.resetGT();
          // this.$emit('update', 'Changes Saved')
        }
        this.saving = false;
      });
    },
  },
  computed: {
    selected() {
      if (!this.active.length) return undefined;

      const id = this.active[0];
      return this.groupTypes.find((gt) => gt.id === id);
    },
    groupTypes() {
      return this.$r.clone(this.$store.getters["saved/getAll"]("groupTypes"));
    },
    allSubGroups() {
      return this.$r
        .clone(this.groupTypes)
        .map((x) => x.subgroups)
        .flat();
    },
    topNodes() {
      var topNodes = this.$r
        .clone(this.groupTypes)
        .filter((x) => !this.allSubGroups.includes(x.id));
      return topNodes;
    },
    activeGroupTypes() {
      return this.active.map((x) => {
        return this.groupTypes.find((m) => m.id == x);
      });
    },
    trees() {
      var result = [];
      this.topNodes.forEach((node) => {
        var hydratedTree = tree.hydrateTree(node, this.$r.clone(this.groupTypes));
        var r = tree.mapTree(hydratedTree);
        result.push(r);
      });
      return result;
    },
  },
  props: {
    title: {
      type: String,
      default: "Group Types",
    },
  },
};
</script>
