<template>
    <div>
      <v-container v-if="!isDetailView">
             <sr-breadcrumbs :items="[{text: 'Home',to:{name: 'Home'} }, {text: 'Frameworks', to:{name: 'AllFrameworks'}, disabled: true}]"></sr-breadcrumbs>
            <v-row v-if="isAuthor">
                <v-col>
                    <create-framework></create-framework>
                </v-col>
            </v-row>
          <v-row >
            
              <v-col>
                 <framework-widget ></framework-widget>

              </v-col>
       
          </v-row>
      </v-container>
      <router-view v-else></router-view>

    </div>
    

</template>

<script>
import FrameworkWidget from '../newComponents/FrameworkWidget.vue';
import CreateFramework from '../newComponents/FrameworkComponents/CreateFramework.vue'
export default {
    name: "Frameworks",
    components:{
        FrameworkWidget,
        CreateFramework
    },
    computed:{
        isAuthor(){
            return this.$auth.isAuthor; 
        },
        isDetailView(){
            if(this.$route.params.frameworkid){
                return true;
            }
            return false;
        },

    }
}
</script>