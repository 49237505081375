<template>
  <v-container fluid>
    <view-mode :permissions="permissions" :rubric="rubric"></view-mode>
  </v-container>
</template>

<script>
import ViewMode from "../newComponents/RubricComponents/ViewMode.vue";
export default {
  name: "RubricHome",
  components: { ViewMode },
  data() {
    return {
      tab: null,
      saving: false,
      changes: 0,
      tableView: true,
      editableRubric: null,
      resetCount: 0,
    };
  },
  created() {
    if (this.$route.hash != null) {
      var t = this.$route.hash.toLowerCase().replace("#", "");
      this.tab =
        this.rubric && this.rubric.units && this.rubric.units.length
          ? this.rubric.units.map((x) => x.id).includes(t)
            ? t
            : this.rubric.units[0].id
          : null;
    } else {
      this.tab =
        this.rubric && this.rubric.units && this.rubric.units.length
          ? this.rubric.units[0].id
          : null;
    }
    this.reset();
  },
  methods: {
    reset() {
      if (this.editableRubric != null) {
        this.editableRubric = null;
      }
      this.editableRubric = {};
      var settings = {
        id: this.rubric.id,
        units: this.rubric.units,
      };
      Object.assign(this.editableRubric, settings);
      //this.editableRubric = settings;
      this.resetCount++;
    },

    saveUnitSchema(e) {
      this.saving = true;
      console.log(e);
      this.saving = false;
    },
    addUnit() {
      this.tab = null;
      var newUnit = null;
      newUnit = {
        title: "",
        tags: [],
        description: "",
        maxMarks: 0,
        gradeBoundaries: [{ key: `Level 1`, floor: 0 }],
      };
      this.editableRubric.units.push(newUnit);
      this.changes++;
    },
    updateUnit(e) {
      this.saving = "secondary";
      /* var boundaries = [];
           this.editableSettings.gradeBoundaries.forEach(g=>{
               var boundary = {
                   key: g.key,
                   floor: g.floor/100
               }
               boundaries.push(boundary)
           });
           this.editableSettings.gradeBoundaries = [];
           this.editableSettings.gradeBoundaries = boundaries;
           */
      this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$store.dispatch("saved/get_rubrics");
          var settings = {
            id: response.rubricId,
            units: response.units,
          };
          Object.assign(this.editableRubric, settings);
          this.tab = response.updatedUnit;
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
      });
    },
  },
  computed: {
    emptyUnit() {
      return this.rubric.units.find((x) => !x.id);
    },
    units() {
      return this.rubric.units.map((x) => {
        return {
          value: x.id,
          text: x.title,
        };
      });
    },
    unitCount() {
      return this.rubric.units.length;
    },
    filledUnits() {
      return this.editableRubric.units.filter((x) => x.id).length;
    },
    unit() {
      return this.emptyUnit
        ? this.emptyUnit
        : this.rubric.units.find((x) => x.id == this.tab);
    },
  },
  props: {
    rubric: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
