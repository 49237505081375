<template>
     <v-tabs   
        v-if="frameworkid && tabList.length > 1"
            :grow="grow"
            :vertical="vertical"
            :color="sliderColor"
            :right="right"
            :optional="optional"
        >
          <v-tabs-slider ></v-tabs-slider>
            <v-tab 
                v-for="(t,i) in tabList" 
                :key="i"
                :exact="t.exact"
                :to="{name: t.name, props: {frameworkid: frameworkid}, query: $route.query}"
                >
                <i :class="t.icon" class="fad mr-2"></i>
               <span v-if="t.filter == 'term'">{{t.text | term}}</span>
               <span v-else>{{t.text | pluralTerm}}</span>
            </v-tab>


        </v-tabs>
</template>

<script>
export default {
    name: 'tabList',
    data(){
        return{
            tabList: []
        }

    },
    created(){
        var result = this.tabs;
                result.push(     {
                        text: 'Assessment',
                        filter: 'pluralTerm',
                        exact: true,
                        icon: 'fa-edit',
                        name: 'FrameworkAssessments',
                    },)
               if(this.canWrite){
             
            
                  /*    result.push({
                        text: 'Report',
                        filter: 'pluralTerm',
                        exact: true,
                        icon: 'fa-chart-bar',
                        name: 'FrameworkReport', 

                    });
                      */
                   result.push(     {
                        text: 'Edit',
                        filter: 'term',
                        exact: false,
                        icon: 'fa-edit',
                        name: 'FrameworkEditSchema',
                    },)
                  
            
                result.push({
                    text: 'Settings',
                    filter: 'term',
                    exact: false,
                    icon: 'fa-cog',
                    name: 'FrameworkSettings'
                     });
                    
            }
         
             
               
      
            this.tabList = result.filter(x=>!this.exclude.includes(x.name));

    },
    computed:{
  
    },
    props:{
        exclude:{
            type: Array,
            default: ()=>[]
        },
        optional:{
            type: Boolean,
            default: false,
        },
        tabs:{
            type: Array,
            default: ()=>{
                return [
                   {
                        text: 'Schema',
                        filter: 'term',
                        exact: true,
                        icon: 'fa-th',
                        name: 'FrameworkHome',
                         
                    },
               
                    
                ]
            }
        },
        right:{
            type: Boolean,
            default: false,
        },
        iconWeight: {
            type: String,
            default: 'fad'
        },
        grow: {
            type: Boolean,
            default: true
        },
        vertical: {
            type: Boolean,
            default: false
        },
        frameworkid: {
            type: String
        },
        canWrite:{
            type: Boolean,
            default: false
        },
        sliderColor:{
            type: String,
            default: "secondary"
        }
    }
}
</script>