<template>
  <span v-if="loading">
    <v-skeleton-loader type="chip"></v-skeleton-loader>
  </span>
  <span v-else>
    <v-chip
      v-if="displayText && !selectOption && !isError"
      @click="goToPerson({ id: id })"
      :disabled="opaque"
      :small="small"
      :class="{ 'primary lighten-1': highlight }"
    >
      <slot name="avatar">
        <v-avatar v-if="showAvatar" left class="primary">
          <v-img v-if="avatar != null" :src="avatar"></v-img>
          <span class="button-text white--text" v-else>{{ initials }}</span>
        </v-avatar>
      </slot>

      <v-badge dot inline right :value="isSelf">
        {{ displayText }}
        <slot></slot>
      </v-badge>
    </v-chip>
    <v-chip
      v-else-if="displayText && !isError"
      :small="small"
      tile
      color="transparent"
      style="color: inherit; border-radius: 0px !important"
      :class="{ 'px-1 mb-1': !showAvatar, 'no-hover': noHover }"
    >
      <slot name="avatar">
        <v-avatar v-if="showAvatar" left class="primary">
          <v-img v-if="avatar != null" :src="avatar"></v-img>
          <span class="button-text white--text" v-else>{{ initials }}</span>
        </v-avatar>
      </slot>

      <v-badge dot inline right :value="isSelf">
        {{ displayText }}
      </v-badge>
      <slot></slot>
    </v-chip>
    <v-chip v-else-if="!isError" :small="small">
      <slot name="avatar">
        <v-avatar v-if="showAvatar" left class="error">
          <v-icon class="white--text">fal fa-ban</v-icon>
        </v-avatar>
      </slot>

      No access
      <slot></slot>
    </v-chip>
    <v-chip v-else :small="small">
      <slot name="avatar">
        <v-avatar v-if="showAvatar" left class="error">
          <v-icon class="white--text">fal fa-user-slash</v-icon>
        </v-avatar>
      </slot>
      <span class="mr-2" v-if="definite">
        {{ "Individual" | term }} no longer with organisation.</span
      >
      <span v-else>{{ "Individual" | term }} not found</span>
      <slot></slot>
    </v-chip>
  </span>
</template>

<script>
export default {
  name: "PersonChip",
  data() {
    return {
      displayText: null,
      initials: null,
      avatar: null,
      opaque: true,
      loading: true,
      isSelf: false,
      isError: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.avatar = null;
      this.opaque = true;
      this.initials = null;
      this.displayText = null;
      this.isSelf = false;
      this.loading = true;
      this.isError = false;
      var person = this.id ? this.$store.getters["saved/get"](["people", this.id]) : null;
      if (!person && this.id) {
        debugger;

        this.$get(`read/person/${this.id}`).then((response) => {
          if (response.error) {
            this.isError = true;
            this.displayText = response.text;
          } else {
            this.displayText = response.data;
            this.initials = this.getInitials(response.data);
          }
          this.loading = false;
        });
      } else if (person) {
        this.displayText = person.displayText;
        this.opaque = person.opaque;
        this.isSelf = person.isSelf;
        this.initials = this.getInitials(person.displayText);
        this.avatar = person.avatar;
        this.loading = false;
      }
    },
    getInitials(text) {
      var words = text.split(" ");
      var initials = words ? words.map((x) => x[0]) : [];
      return initials.slice(0, 2).join("");
    },
  },
  /* computed: {
    person() {
      return this.id ? this.$store.getters["saved/get"](["people", this.id]) : null;
    },
   
  },
  */
  props: {
    id: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    selectOption: {
      type: Boolean,
      default: false,
    },
    definite: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    avatarOnly: {
      type: Boolean,
      default: false,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      defaul: false,
    },
  },
};
</script>
