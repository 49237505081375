
const msg ={
    email:{
        from:{
            email: '',
            name: ''
        },
        templateId: '',
        dynamicTemplateData:{
            subject: '',
            name: '',
            primaryCTA: {
             url: null,
             text: null,
             helpText: null
            },
            secondaryCTA: {
                url: null,
                text: null,
                helpText: null
            },
            primaryMessage: null,
            secondaryMessage: null,
            finePrint: null,
            signature: {
                salutation: 'Dear',
                closing: 'All the best,',
                name: null,
                title: null,
                organisation: null,
            }
        },
        personalizations: []
    },
    personalisation: {
        individualId: null,
        dynamicTemplateData:{
            subject: '',
            name: '',
            individualId: '',
            primaryCTA: {
             url: null,
             text: null,
             helpText: null
            },
            secondaryCTA: {
                url: null,
                text: null,
                helpText: null
            },
            primaryMessage: null,
            secondaryMessage: null,
            finePrint: null,
            signature: null
        }
    },

};

  export default msg;