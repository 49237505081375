<template>
  <v-card :loading="loading" flat v-if="availableIds.length || unavailableIds.length">
    <v-card-title v-if="$vuetify.breakpoint.mdAndUp"
      >Add Tags to {{ "Assessment" | pluralTerm }}</v-card-title
    >
    <v-card-text>
      <sr-available-ids
        entity="Assessment"
        label="Tag"
        :availableIds="availableIds"
        :unavailableIds="unavailableIds"
        :items="assessments"
      ></sr-available-ids>
    </v-card-text>
    <v-card-text>
      <v-form v-model="valid" v-if="availableIds.length" :disabled="loading">
        <v-row align-content="start">
          <v-col>
            <v-combobox
              outlined
              dense
              hide-no-data
              single-line
              small-chips
              v-model="tags"
              chips
              clearable
              label="Tags"
              @input="change"
              multiple
              append-icon=""
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  dark
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-form>
      <sr-not-found icon="fad fa-exclamation" color="info" v-else>
        <template v-slot:description
          >No duplicate options for this selection of
          {{ "assessment" | pluralTerm }}.</template
        >
        <template v-slot:body>
          Please ensure that you have selected {{ "assessment" | pluralTerm }} belonging
          to {{ "group" | pluralTerm }} that you have
          <strong>write:documents</strong>access to.
        </template>
      </sr-not-found>
    </v-card-text>
    <v-card-actions v-if="availableIds.length">
      <v-spacer></v-spacer>
      <v-btn text @click="reset" :disabled="changes == 0 || loading" color="warning"
        >reset</v-btn
      >
      <v-btn
        text
        @click="update"
        :disabled="changes == 0 || !valid || loading"
        color="success"
        >Add tags</v-btn
      >
    </v-card-actions>
    <v-card v-if="result">
      {{ result }}
    </v-card>
  </v-card>
  <v-card-text v-else class="text--center d-flex align-content-stretch">
    <em>Please select some {{ "assessment" | pluralTerm }} first</em>
  </v-card-text>
</template>

<script>
import srAvailableIds from "./AvailableIds.vue";

export default {
  name: "BatchTagAssessment",
  components: {
    srAvailableIds,
  },
  data() {
    return {
      tags: [],
      valid: false,
      changes: 0,
      saving: false,
      showSelected: false,
      error: null,
      loading: false,
      result: null,
      locked: false,
    };
  },
  methods: {
    change() {
      this.changes = this.changes + 1;
    },
    reset() {
      this.changes = 0;
      this.tags = null;
      this.tags = [];
    },
    removeTag(item) {
      debugger;
      var i = this.tags.indexOf(item);
      this.tags.splice(i, 1);
      this.tags = [...this.tags];
    },
    update() {
      var valid = window.confirm(
        `Are you sure you wish to add tags to ${this.availableIds.length} ${this.getTerm(
          "assessment",
          this.availableIds.length == 1 ? false : true
        )}?`
      );
      if (valid) {
        this.loading = true;
        var data = {
          assessments: this.availableAssessments.map((x) => {
            return {
              id: x.id,
              secret: x.secret,
            };
          }),
          tags: this.tags,
        };
        debugger;
        this.$post("update/assessment/batch/tag", data).then((response) => {
          debugger;
          if (response.error) {
            this.error = response.text;
          } else {
            this.result = response;
            //   this.$store.dispatch("saved/get_assessments");
            // this.reset();
          }
          this.loading = false;
        });
      }
    },
  },
  computed: {
    availableAssessments() {
      return this.assessments.filter((x) => this.availableIds.includes(x.id));
    },
  },
  props: {
    unavailableIds: {
      type: Array,
      default: () => [],
    },
    availableIds: {
      type: Array,
      default: () => [],
    },
    assessments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
