<template>
  <v-card :loading="saving">
    <v-toolbar dense flat>
      <v-toolbar-title>Edit Metadata</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="valid" v-if="editableSettings" :key="resetCount">
        <v-row>
          <v-col>
            <v-text-field
              outlined
              dense
              :label="$getTerm('Title')"
              :disabled="saving != false"
              v-model="editableSettings.title"
              @input="change"
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => v.length <= 150 || 'Name must be less than 150 characters',
              ]"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              label="Date"
              :disabled="saving != false"
              type="date"
              v-model="editableSettings.date"
              @input="change"
              :rules="[(v) => !!v || 'Date is required']"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <wysiwyg
          class="rounded mb-5"
          :label="$getTerm('Title')"
          v-model="editableSettings.description"
          :disabled="saving != false"
          @change="change"
        />

        <v-combobox
          outlined
          dense
          hide-no-data
          single-line
          small-chips
          v-model="editableSettings.tags"
          chips
          clearable
          label="Tags"
          :disabled="saving != false"
          @input="change"
          multiple
          append-icon=""
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              class="my-1"
              dark
              @click="select"
              @click:close="removeTag(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
        <v-switch
          v-model="editableSettings.selfAssessment"
          @change="change"
          :disabled="saving != false"
          :label="`Self ${$getTerm('Assessment')}?`"
        >
        </v-switch>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="$auth.isAdmin"
        @click="deleteAssessment"
        :disabled="saving != false"
        color="error"
        >delete</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="reset"
        :disabled="changes == 0 || saving != false"
        color="warning"
        >reset</v-btn
      >
      <v-btn
        text
        @click="update"
        :disabled="changes == 0 || !valid || saving != false"
        color="success"
        >Save Changes</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
const Moment = moment;
export default {
  name: "AssessmentSettings",
  data() {
    return {
      editableSettings: null,
      changes: 0,
      saving: false,
      valid: false,
      resetCount: 0,
    };
  },
  created() {
    this.reset();
  },
  methods: {
    change() {
      this.changes++;
    },
    reset() {
      if (this.editableSettings != null) {
        this.editableSettings = null;
      }
      this.editableSettings = {};
      var settings = {
        id: this.assessment.id,
        title: this.assessment.title,
        secret: this.assessment.secret,
        tags: this.assessment.tags,
        description: this.assessment.description,
        selfAssessment: this.assessment.selfAssessment,
        date: Moment(new Date(this.assessment.date)).format("YYYY-MM-DD"),
      };
      Object.assign(this.editableSettings, settings);
      this.resetCount++;
    },
    removeTag(item) {
      debugger;
      var i = this.editableSettings.tags.indexOf(item);
      this.editableSettings.tags.splice(i, 1);
      this.editableSettings.tags = [...this.editableSettings.tags];
      this.change();
    },
    deleteAssessment() {
      var confirm = window.confirm(
        `Are you sure you want to permanently delete this ${this.getTerm(
          "assessment"
        )}? This cannot be undone and all  ${this.getTerm(
          "assessment"
        )} data will be lost.`
      );
      if (confirm) {
        this.saving = "secondary";
        this.$get(`assessments/${this.assessment.id}/delete`).then((response) => {
          if (response.error) {
            this.$emit(
              "error",
              this.errorText(response.text, "delete", this.$getTerm("assessment"))
            );
          } else {
            //  this.$store.dispatch('saved/get_assessments')
            this.$router.push({ name: "Assessments" });
          }
        });
      }
    },
    update() {
      this.saving = "secondary";
      this.$post(
        `update/assessment/${this.assessment.id}/settings`,
        this.editableSettings
      ).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("assessment"))
          );
        } else {
          //  this.$store.dispatch('saved/get_assessments')
          this.changes = 0;
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
      });
    },
  },
  props: {
    assessment: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
