
import axios from "axios";
import config from '../../configs/template.js';
import { clone, mergeDeepRight, mergeRight } from "ramda";

const query = `?orgId=${config.orgId}`;
const root = process.env.VUE_APP_API_URL;


const objArr = function(id){
    var obj = {
        status: 'empty',
        updated: null,
        pagination: {
            pageSize: 500,
            pageNumber: 1
        },
        data: [],
    }
    if(id != null){
        obj.idField = id
    }
    return obj;
}
const objObj = function(id){
    var obj = {
        status: 'empty',
        updated: null,
        data: null,
    }
    if(id != null){
        obj.idField = id
    }
    return obj;
}
const readyArr = ['complete', 'error'];

const baseActions = function(actions, stateProperties){
  var result = { 
      ...actions, 
     async init(context){
         //debugger;
       return context.dispatch('saved/testConnection', null, {root: true}).then((response)=>{
           if(response.error){
            context.commit('updateConnectionStatus', 'offline')
           }
           else{
            if(context.rootGetters['saved/connected']){
                stateProperties.forEach(x=>{
                    if(x.includeOnInit){
                        context.commit('updateStatus',x.key);
                        console.log('initialising ' + x.key)
                        context.dispatch(`get_${x.key}`);
                    }
                    else{
                        console.log('NOT initialising ' + x.key)

                    }

                });
                return true;
            }
            else{
                return false
            }
           }
           
        })
     
    },
    reset({commit}){
        commit('reset');
        return true;
    },
  
    partialReset(context){
        return context.dispatch('saved/testConnection', null, {root: true}).then((response)=>{
            if(response.error){
             context.commit('updateConnectionStatus', 'offline')
            }
            else{
               
                if(context.rootGetters['saved/connected']){
                    stateProperties.forEach(x=>{
                        if(x.includeOnPartialInit){
                            context.commit('updateStatus',x.key);
                            console.log('initialising ' + x.key)
                            context.dispatch(`get_${x.key}`);
                        }
                        else{
                            console.log('NOT initialising ' + x.key)
    
                        }
    
                    })
                    return true;
                }
                else{
                    return false
                }
            }
            
         })
    }
  }
  //get
  stateProperties.forEach(p=>{
      result[`get_${p.key}`] = async function(context, pagination){
          //debugger;
        return context.dispatch('saved/testConnection', null, {root: true}).then(()=>{
            if(context.rootGetters['saved/connected']){
                var url = `${root}${p.route}${query}`;
                return this.$app.$auth.getTokenSilently().then((t) => {
                    const config = {
                      headers: {
                        authorization: "Bearer " + t,
                      },
                    };
                    if(p.method == 'post'){
                        var data = context.state[p.key].pagination;
                        if(pagination != null){
                            data = pagination;
                        }
                        axios.post(url, data, config).then((response) => {
                            //debugger;
                            if(p.key == 'rubrics'){
                                    context.dispatch('stealthRubricUpdate');
                            }
                            context.commit("set", {key: p.key, value: response.data.items, pagination: pagination ? response.data.pageParams : null});
                            return response;
                          });
                    }
                    else{
                        axios.get(url, config).then((response) => {
                            context.commit("set", {key: p.key, value: response.data});
                            return response;
                          });
                    }
                  
                });
            }
            else{
                context.commit("set", {key: p.key, value: null, error: true});
            }
        });
    }
  });

  //update
  stateProperties.forEach(p=>{
    if(p.updateRoute != null){
        result[`update_${p.key}`] = async function(context){
            //debugger;
          return context.dispatch('saved/testConnection', null, {root: true}).then(()=>{
              if(context.rootGetters['saved/connected']){
                  var url = `${root}${p.updateRoute}${query}`;
                  return this.$app.$auth.getTokenSilently().then((t) => {
                      const config = {
                        headers: {
                          authorization: "Bearer " + t,
                        },
                      };

                      var data= context.state[p.key].data.map(x=>{
                        var d = {
                            id: x.id,
                            secret: x.secret
                        }
                        return d; 
                      });
                    axios.post(url, data,config).then((response) => {
                        //debugger;
                              context.commit("set", {key: p.key, value: response.data});
                              return response;
                            });
                      
                    
                  });
              }
              else{
                  context.commit("set", {key: p.key, value: null, error: true});
              }
          });
      }
    }
  
})

  return result;
}

const baseModule = function(stateProperties,extraState,mutations, actions, getters,){ return {
    namespaced: true,
    state: initialState(stateProperties, extraState),
    mutations:{
        ...mutations,
        set(state, data){
            //debugger;
            if(data.key != null){
                const item = state[data.key];
                if(item != null){
                    if(data.error == true){
                        item.status = 'error'
                    }
                    else if(data.value != null){
                        item.status = 'setting';
                


                        try{
                            if(data.pagination != null){
                                data.pagination.pageNumber ++;
                            }
                        setMutation(item, {data: data.value, pagination: data.pagination});
                            item.status = 'complete';
                        } 
                        catch{
                        item.status = 'error'
                        }
                    }
                    item.updated = new Date();
                }
            }
        },
        updateStatus(state, key){
            if(key != null){
                const item = state[key];
                if(item){
                    item.status = 'waiting'
                }
            }
        },
        reset(state){
            var connected = state.connectionStatus && state.connectionStatus == 'connected'; 
            
            Object.assign(state, initialState(stateProperties, extraState));
            
            if(connected){
                state.connectionStatus = 'connected'
            }
        },
    },
    actions: baseActions(actions, stateProperties),
    getters:{
        ...getters,
        ready(state){
            return (key)=>{
                var item = state[key];
                if(item){
                    return {
                        ready: readyArr.includes(item.status),
                        error: item.status == 'error',
                        updated: item.updated
                    };
                }
                return null;
                
            }
        },
        getAll(state){
            return (data) => {
                try{
                    //debugger;
                    return state[data].data;
                }
                catch{
                    return [];
                }
            } 
        },
        getArr(state){
            return (data)=>{
                //debugger;
                const d = state[data[0]];
                const idField = d ? d.idField : null;
                var isArray = idField != null &&  state[data[0]].data ? Array.isArray(state[data[0]].data) : false;
                var result = []; 
                if(isArray && data.length == 2){
                    result = clone(state[data[0]].data.filter(x=>data[1].includes(x[idField])))
                }
               
                return result;
            }
        },
        search(state){
            return (data)=>{
                //debugger;
            
                var isArray = state[data[0]].data ? Array.isArray(state[data[0]].data) : false;

                return isArray && data.length == 2 ? state[data[0]].data.filter(x=> x[data[1].key] == data[1].value): [];
            }
        },
        get(state){
            return (data)=>{
               // debugger;
                const idField = state[data[0]].idField;
                var isArray = state[data[0]].data ? Array.isArray(state[data[0]].data) : false;

                return isArray && data.length == 2 ? state[data[0]].data.find(x=>x[idField] == data[1]): state[data[0]].data;
            }
        },
    }
};}


const setMutation = function(item, data){
   //debugger;
    var updateObj = item != null && !Array.isArray(item.data);
    var updateArr = item != null && Array.isArray(item.data) && Array.isArray(data.data) && data.data.length != 0;
    var updateValArr = item != null && Array.isArray(item.data) && !Array.isArray(data.data);
    if(item.pagination != null && data.pagination != null){
        item.data = null; 
        item.data = clone(data.data);
        item.pagination = clone(data.pagination);
    }
    else if(updateValArr){
       // data.value = [data];
       data.data = data.data.items;
        updateArr = true;
    }
    else if(updateObj){
        item.data = mergeDeepRight(item.data, data);
    }
    else{
        item.data = null;
        item.data = clone(data.data)
    }
    if(updateArr){
        data.data.forEach(element => {
            if(item.idField == null){
                //debugger;
               const i = item.data.find(element); 
               if(i){
                   item.data = [...item.data.map(x=>{
                       if(x == element){
                           x = mergeRight(x, element)
                       }
                       return x;
                   })]
               }
               else{
                   item.data.push(element);
               }
            }
            else{
                const i = item.data.find((x) => x[item.idField] === element.id);
                if (i) {
                  item.data = [...item.data.map(x => {
                    if(x.id == i.id){
                      x = mergeRight(x, element);
                    }
                    return x;
                  })]
                } else {
                  item.data.push(element);
                }
            
            }
        }); 
        //debugger;
        var dataIds = data.data.map(x=>x[item.idField]);
        item.data = item.data.filter(x=>dataIds.includes(x[item.idField]));
    }
   
}
const stateObj = function(props, extraState){
    var state = extraState;
    props.forEach(p=>{
        state[p.key] = p.type == 'arr' ? objArr(p.idField) : objObj(p.idField);
        state[p.key].status = 'empty';
    })
    return state;
};
const initialState = function(props, state){
    return stateObj(props, state);
}

const methods = {
    baseModule
};


export default methods;