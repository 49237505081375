var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.availableIds.length || _vm.unavailableIds.length)?_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-title',[_vm._v("Create "+_vm._s(_vm._f("pluralTerm")("assessment")))]):_vm._e(),_c('v-card-text',[_c('sr-available-ids',{attrs:{"label":"Assessment","entity":"Group","availableIds":_vm.availableIds,"unavailableIds":_vm.unavailableIds}})],1),_c('v-card-text',[(_vm.availableIds.length)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[(_vm.rubrics.length)?_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'A rubric is required'; }],"items":_vm.rubrics,"label":"Rubric","item-text":"text","item-value":"val"},model:{value:(_vm.rubric),callback:function ($$v) {_vm.rubric=$$v},expression:"rubric"}}):_c('em',[_vm._v("No frameworks found")])],1)],1),_c('v-text-field',{attrs:{"label":((_vm.$getTerm('Assessment')) + " title"),"hint":"Available placeholders: [group.name], [group.internalId], [group.tag],[group.parent.name], [group.parent.internalId], [group.tag],","rules":[
          function (v) { return !!v || 'Title is required'; },
          function (v) { return v.length <= 150 || 'Title must be less than 150 characters'; } ]},on:{"input":_vm.change},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),(
          _vm.availableIds.length && _vm.title && _vm.title.includes('[') && _vm.title.includes(']')
        )?_c('p',[_c('em',[_vm._v("Sample title: "+_vm._s(_vm.getTitle(_vm.groups[0])))])]):_vm._e(),_c('v-textarea',{attrs:{"label":"Description"},on:{"input":_vm.change},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-text-field',{attrs:{"label":"Date","type":"date","rules":[function (v) { return !!v || 'A date is required'; }]},on:{"input":_vm.change},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","multiple":"","append-icon":""},on:{"input":_vm.change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var attrs = ref.attrs;
        var item = ref.item;
        var select = ref.select;
        var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","dark":""},on:{"click":select,"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2029797868),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1):_vm._e()],1),(_vm.availableIds.length)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0,"color":"warning"},on:{"click":_vm.reset}},[_vm._v("reset")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || !_vm.valid,"color":"success"},on:{"click":_vm.create}},[_vm._v("Create Assessments")])],1):_vm._e(),(_vm.response)?_c('v-alert',[_vm._v(" "+_vm._s(_vm.response)+" ")]):_vm._e()],1):_c('v-card-text',{staticClass:"text--center d-flex align-content-stretch"},[_c('em',[_vm._v("Please select some groups first")])])}
var staticRenderFns = []

export { render, staticRenderFns }