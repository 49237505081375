const entities = {
  individual: {
    userId: null,
    orgs: [],
    groupId: null,
    opaqueData: ["firstName", "lastName"],
    title: null,
    nickname: null,
    preferredPronouns: {
      nominative: null,
      accusative: null,
      reflexive: null,
      possessivePronominal: null,
      possessivePredicative: null,
    },
    metadata: null,
    firstName: null,
    lastName: null,
    dob: null,
    emails: [],
    addresses: [],
    contacts: [],
    phoneNumbers: [],
    demographics: {
      ethnicities: [],
      sens: [],
      tags: [],
    },
    gender: null,
    tags: [],
  },
  group: {
    orgId: null,
    groupTypeId: null,
    creator_Id: null,
    members: [],
    active: true,
    activePeriods: [],
    tags: [],
    subGroups: [],
    metadata: {
      org: false,
      name: null,
      primaryColor: null,
      internalIdentifier: null,
    },
  },
  groupType: {
    parentId: null,
    adminPermissions: null,
    memberPermissions: null,
    name: null,
    adminName: null,
    memberName: null,
    hasSubgroups: false,
    isOrg: false,
    enableSupportAccess: false,
    cutOff: false,
    subgroups: [],
    endOfNode: false,
    objectPermissions: {
      admin: {
        administrators: { read: false, write: false },
        members: { read: false, write: false },
        self: { read: false, write: false },
        memberList: { read: false, write: false },
        docs: { read: false, write: false },
        selfDocs: { read: false, write: false },
        metadata: { read: false, write: false },
        subgroupList: { read: false, write: false },
        subgroupMetadata: { read: false, write: false },
        subgroupMemberList: { read: false, write: false },
        subgroupIndividuals: { read: false, write: false },
        subgroupDocuments: { read: false, write: false },
        subgroupPermissions: { read: false, write: false },
      },
      member: {
        administrators: { read: false, write: false },
        members: { read: false, write: false },
        self: { read: false, write: false },
        memberList: { read: false, write: false },
        docs: { read: false, write: false },
        selfDocs: { read: false, write: false },
        metadata: { read: false, write: false },
        subgroupList: { read: false, write: false },
        subgroupMetadata: { read: false, write: false },
        subgroupMemberList: { read: false, write: false },
        subgroupIndividuals: { read: false, write: false },
        subgroupDocuments: { read: false, write: false },
        subgroupPermissions: { read: false, write: false },
      },
    },
  },
  organisation: {
    id: null,
    groupType: null,
    administrators: [],
    metadata: {
      address: {
        streetAddress: null,
        city: null,
        postcode: null,
        country: null,
        primary: false,
      },
      primaryContact: {
        addresses: [],
        phoneNumbers: [],
        name: null,
        emails: [],
      },
      billingContact: {
        addresses: [],
        phoneNumbers: [],
        name: null,
        emails: [],
      },
      legal: {
        address: {
          streetAddress: null,
          city: null,
          postcode: null,
          country: null,
          primary: false,
        },
        registrationNumber: null,
        termsOfUse: null,
        privacyPolicy: null,
      },
      links: [],
      logo: null,
      org: false,
      name: null,
      primaryColor: null,
      internalIdentifier: null,
    },
    appTemplate: {
      title: null,
      subdomain: null,
      description: null,
      theme: {
        dark: false,
        themes: {
          light: {
            primary: null,
            accent: null,
            secondary: null,
            success: null,
            info: null,
            warning: null,
            error: null,
            light: null,
          },
          dark: {
            primary: null,
            accent: null,
            secondary: null,
            success: null,
            info: null,
            warning: null,
            error: null,
            light: null,
          },
        },
      },
      favicon: null,
      lightLogo: null,
      darkLogo: null,
    },
  },
  objectPermission: {
    administrators: { read: false, write: false },
    members: { read: false, write: false },
    self: { read: false, write: false },
    memberList: { read: false, write: false },
    docs: { read: false, write: false },
    selfDocs: { read: false, write: false },
    metadata: { read: false, write: false },
    subgroupList: { read: false, write: false },
    subgroupMetadata: { read: false, write: false },
    subgroupMemberList: { read: false, write: false },
    subgroupIndividuals: { read: false, write: false },
    subgroupDocuments: { read: false, write: false },
    subgroupPermissions: { read: false, write: false },
  },
  member: {
    individualId: null,
    admin: false,
    active: true,
    metadata: { internalIdentifier: null },
    membership: [],
  },
  contact: { addresses: [], phoneNumbers: [], name: null, emails: [] },
  phoneNumber: {
    number: null,
    countryCode: null,
    identifier: null,
    primary: true,
  },
  address: {
    streetAddress: null,
    city: null,
    postcode: null,
    country: null,
    primary: true,
  },
  emergencyContact: {
    safe: false,
    relationship: null,
    addresses: [],
    phoneNumbers: [],
    name: null,
    emails: [],
  },
  orderedLink: {
    primary: false,
    index: 0,
    url: null,
    text: null,
    description: null,
    helpText: null,
    newWindow: false,
    icon: null,
    disabled: false,
  },
  link: {
    url: null,
    text: null,
    description: null,
    helpText: null,
    newWindow: false,
    icon: null,
    disabled: false,
  },
  email: { address: null, primary: true },
  subGroup: { groupId: null, groupTypeId: null },
  comment:{
    metadata: {createdBy: null},
    replies:[],
    text: null,
    private: false,
    file: [],
    tags: []
  }
};
export default entities;
