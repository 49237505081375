<template>
  <div :style="`${app_info.loginBG}; height: 100VH;`" id="background">
    <v-alert
      prominent
      style="position: absolute; top: 50px; left: 50px; right: 50px"
      type="error"
      v-if="errorTitle"
    >
      <strong>{{ errorTitle.replaceAll("_", " ") | titleCase }}</strong> <br />
      {{ errorDescription }}
    </v-alert>

    <v-row class="align-content-center justify-space-around" style="height: 100%">
      <v-col class="text-center" md="8" lg="6" cols="10">
        <v-card
          :tile="app_info.tile"
          :color="app_info.colorfulHeadings ? '' : 'transparent'"
          :dark="app_info.loginBgDark"
          flat
          class="ma-5"
        >
          <div class="my-5">
            <v-img style="margin: 0 auto" width="100" :src="getImgUrl('icon')"></v-img>
          </div>
          <h2 class="text-h5 overline">Welcome</h2>
          <div
            :class="{ 'secondary--text': app_info.colorfulHeadings }"
            class="text-h4 text-md-h3 text-uppercase mt-2"
            v-html="app_info.htmlName ? app_info.htmlName : app_info.name"
          ></div>
          <div
            class="text-h5 mb-5 overline dark--text text--lighten-5"
            v-html="app_info.description"
          ></div>
          <v-card-text
            class="mb-5 pa-5"
            v-if="app_info.alert"
            v-html="app_info.alert"
          ></v-card-text>
          <v-card-actions class="justify-center pb-10">
            <v-btn
              :tile="app_info.tile"
              v-if="this.$auth.isAuthenticated"
              text
              :color="app_info.colorfulHeadings ? '' : 'light'"
              outlined
              @click="logout"
            >
              <span>Log Out</span>
            </v-btn>
            <v-btn :tile="app_info.tile" color="secondary" @click="login">
              <span v-if="this.$auth.isAuthenticated">Go to Dashboard</span>
              <span v-else>Log In</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PublicHome",

  methods: {
    logout() {
      this.$logout();
    },
    login() {
      if (window.location.origin.includes("localhost")) {
        var route = { name: "Home" };
        this.$router.push(route);
      } else {
        var uri = `${window.location}app`;
        console.log(uri);
        this.$login(uri);
      }
    },
  },
  computed: {
    errorTitle() {
      return this.$route.query.error;
    },
    errorDescription() {
      return this.$route.query.error_description;
    },
  },
};
</script>
