<template>
  <v-chip
    v-if="framework && !selectOption && !groupRow"
    @click="goToFramework(framework)"
    :small="small"
  >
    <v-avatar v-if="showAvatar" left class="primary">
      <span class="button-text white--text">{{ initials }}</span>
    </v-avatar>
    {{ framework.title }}
  </v-chip>
  <v-chip v-else-if="framework && !groupRow" :small="small" color="transparent">
    <v-avatar v-if="showAvatar" left class="primary">
      <span class="button-text white--text">{{ initials }}</span>
    </v-avatar>

    {{ framework.title }}
  </v-chip>
  <v-system-bar class="pr-0" v-else-if="framework && groupRow">
    <v-card-subtitle class="text-overline"
      ><span v-if="prependText">{{ prependText }}: </span
      >{{ framework.title }}</v-card-subtitle
    >
    <v-toolbar-items class="align-center">
      <sr-person-chip
        class="mx-2"
        v-for="(a, i) in framework.authors"
        :key="i"
        small
        :showAvatar="false"
        :id="a"
      ></sr-person-chip>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-btn class="px-3 pl-5" icon @click="goToFramework(framework, true)">
      <v-icon>fal fa-angle-right</v-icon>
    </v-btn>
  </v-system-bar>
</template>

<script>
export default {
  name: "FrameworkChip",
  computed: {
    framework() {
      return this.id ? this.$store.getters["saved/get"](["frameworks", this.id]) : null;
    },
    initials() {
      var words = this.framework.title.split(" ");
      var initials = words ? words.map((x) => x[0]) : [];
      return initials.slice(0, 2).join("");
    },
  },
  props: {
    id: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    selectOption: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    groupRow: {
      type: Boolean,
      default: false,
    },
    prependText: {
      type: String,
      default: null,
    },
  },
};
</script>
