var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.groupType)?_c('v-system-bar',{style:({ backgroundColor: _vm.color }),attrs:{"dark":""}},[_c('span',{staticClass:"overline ml-5 pl-5"},[_vm._v(" "+_vm._s(_vm.groupType.name))]),_c('v-spacer')],1):_vm._e(),(_vm.miniGroup)?_c('v-toolbar',{key:_vm.loading,attrs:{"dense":"","flat":""},scopedSlots:_vm._u([(_vm.group && (_vm.$vuetify.breakpoint.mdAndDown || _vm.showBreadCrumbs))?{key:"extension",fn:function(){return [(_vm.group)?_c('sr-group-tabs',{staticClass:"border-top",attrs:{"groupid":_vm.group.id,"groupType":_vm.groupType,"sliderColor":_vm.color,"admin":_vm.admin}}):_vm._e()]},proxy:true}:null],null,true)},[_c('v-toolbar-items',{staticClass:"no-padder"},[(_vm.paths.length != 0)?_c('v-btn',{staticClass:"px-0",attrs:{"text":"","icon":""},on:{"click":function($event){_vm.showBreadCrumbs = !_vm.showBreadCrumbs}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBreadCrumbs),expression:"showBreadCrumbs"}]},[_vm._v("mdi-chevron-right")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showBreadCrumbs),expression:"!showBreadCrumbs"}]},[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),(_vm.group && (_vm.showBreadCrumbs || _vm.paths.length == 0))?_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","to":{ name: 'Home' },"color":_vm.color}},[_c('i',{staticClass:"fal fa-home"})]),(_vm.groupType)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.color}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fal fa-users"})])]}}],null,false,1615494781)},[(_vm.groupType)?_c('v-list',[_c('v-subheader',{staticClass:"overline",attrs:{"hidden":""}},[_vm._v("Back To...")]),_c('v-list-item',{attrs:{"dense":"","exact":"","link":"","to":{ name: 'AllGroups' }}},[_c('v-list-item-title',[_c('i',{staticClass:"fad fa-users mr-3",attrs:{"color":"primary"}}),_vm._v(" All groups ")])],1),_c('v-list-item',{attrs:{"dense":"","exact":"","link":"","to":{ name: 'AllGroups', query: { type: _vm.groupType.id } }}},[_c('v-list-item-title',[_c('i',{staticClass:"fad fa-users mr-3",attrs:{"color":"primary"}}),_vm._v(" All "+_vm._s(_vm._f("pluralize")(_vm.groupType.name))+" ")])],1),(_vm.paths.length)?_c('v-list-item',{attrs:{"dense":"","exact":"","link":"","to":{
              name: 'AllGroups',
              query: { type: _vm.groupType.id, parent: _vm.paths[0].groups[0].id },
            }}},[_c('v-list-item-title',[_c('i',{staticClass:"fad fa-users mr-3",attrs:{"color":"primary"}}),_vm._v(" Sibling "+_vm._s(_vm._f("pluralize")(_vm.groupType.name))+" ("+_vm._s(_vm.paths[0].groups[0].name)+") ")])],1):_vm._e()],1):_vm._e()],1):_vm._e(),_vm._l((_vm.paths),function(item,i){return _c('v-toolbar-items',{key:i},[_c('v-btn',{staticClass:"px-1",attrs:{"text":"","large":"","color":_vm.color,"to":{ name: 'GroupHome', params: { groupid: item.groups[0].id } }}},[_vm._v(" "+_vm._s(item.groups[0].name)+" ")]),(i < _vm.paths.length)?_c('v-icon',{staticClass:"text-button mt-1"},[_vm._v("mdi-chevron-right")]):_vm._e()],1)})],2):_vm._e(),_c('v-toolbar-title',{staticClass:"text-button ml-2 mt-1",style:({ color: _vm.color })},[_vm._v(" "+_vm._s(_vm.miniGroup.displayText)+" ")]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.admin)?_c('v-btn',{staticClass:"mx-3 hidden",attrs:{"text":"","color":_vm.color}},[_c('i',{staticClass:"fal fa-plus mr-3"}),_vm._v(" New "+_vm._s(_vm._f("term")("Assessment"))+" ")]):_vm._e(),(_vm.group && _vm.$vuetify.breakpoint.lgAndUp && !_vm.showBreadCrumbs)?_c('sr-group-tabs',{attrs:{"optional":true,"right":"","sliderColor":_vm.color,"grow":false,"groupid":_vm.group.id,"groupType":_vm.groupType,"admin":_vm.admin}}):_vm._e()],1)],1):_vm._e(),_c('v-divider'),(_vm.group)?_c('v-container',{attrs:{"fluid":""}},[_c('router-view',_vm._b({attrs:{"name":"header"}},'router-view',{
        group: _vm.group,
        people: _vm.people,
        rubrics: _vm.rubrics,
        permissions: _vm.permissions,
        groupType: _vm.groupType,
      },false)),_c('router-view',_vm._b({on:{"update":_vm.init}},'router-view',{
        group: _vm.group,
        people: _vm.people,
        rubrics: _vm.rubrics,
        permissions: _vm.permissions,
        groupType: _vm.groupType,
      },false))],1):_vm._e(),(!_vm.group)?_c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('sr-loading'):_vm._e(),(!_vm.loading && _vm.error && _vm.error.toLowerCase().includes('network'))?_c('sr-not-found',{attrs:{"color":"error","icon":"fad fa-wifi-slash"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Network Error ")]},proxy:true},{key:"description",fn:function(){return [_vm._v("This resource is temporarily unavailable.")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" Please try again later. If the problem persists, contact support. ")]},proxy:true}],null,false,946838849)}):_vm._e(),(!_vm.loading && _vm.error && !_vm.error.toLowerCase().includes('network'))?_c('sr-not-found',{attrs:{"unauthorized":""}}):_vm._e()],1):_vm._e(),_c('v-snackbar',{attrs:{"top":"","timeout":_vm.app_info.snackbarTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }