<template>
    <i :style="{color: color}" :class="c"></i>
</template>

<script>
export default {
    name: 'CustomIcon',
    computed:{
        c(){
            var icon = this.$getTerm(`${this.icon}Icon`);
  
            return `${this.classes} ${icon}`;
        }
    },
    props:{
        icon:{
            type: String,
            default: 'group'
        },
        classes:{
            type: String,
            default: 'fal'
        },
        color:{
            type: String
        }
    }
}
</script>