import colors from "vuetify/lib/util/colors";
import smartrubric from "@/assets/palette.js";
import ReportHeaders from './reportHeaders';



import ispColors from '@/assets/ispColors.js';

const colorArr = function(c){
  debugger;
  var keys = Object.keys(c).filter(x=> !['white','dark', 'shades', 'grey'].includes(x));
  var result = [];
  keys.forEach(x=>{
    var colorBase = c[x];
    var colors = Object.keys(c[x]).filter(y=>{
      return !Array.isArray(colorBase[y]) && (!y.includes('accent') && !y.includes('3') && !y.includes('4') && !y.includes('5')) || (y.includes('accent')&& !y.includes('1') && !y.includes('2')) 
    });
    colors.forEach((y)=>{
    //  colors.forEach((y, i)=>{  
     // if(i % 2 == 0){
        result.push(c[x][y])
     // }
    })
  })
  return result;
}

const org = process.env.VUE_APP_ORG;
const reportHeaders = ReportHeaders;
const configs = {
  srCore: {
    stitch: {
      appClient: "isp_management_portal",
    },
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: {
          primary: smartrubric.gray.base,
          secondary: smartrubric.teal.base,
          accent: smartrubric.teal.darken2,
          success: smartrubric.green.base,
          info: colors.blue.base,
          warning: smartrubric.yellow.base,
          error: smartrubric.red.base,
          light: smartrubric.white.base,
          dark: smartrubric.gray.base,
          anchor: smartrubric.teal.darken3,
          highlight: '#efefef',
        },
        dark: {
          primary: smartrubric.gray.base,
          secondary: smartrubric.teal.base,
          accent: smartrubric.teal.darken2,
          success: smartrubric.green.base,
          info: colors.blue.base,
          warning: smartrubric.yellow.base,
          error: smartrubric.red.base,
          light: smartrubric.white.base,
          dark: smartrubric.gray.base,
          anchor: smartrubric.indigo.lighten2,
          highlight: '#efefef',
        },
      },
    },
    orgId: "5edf597d21ffbf2b13e1c236",
    appInfo: {
      branch: "Production",
      noMarksText: "-",
      noMarksIcon: "mdi-close",
      noMarksClass: "error--text",
      filename: "srCore",
      reportHeaders: reportHeaders.default,
      supportUrl: 'support@smartrubric.com',
      avatar:"https%3A%2F%2F1.bp.blogspot.com%2F-zkai6ws07uA%2FX0epz5X4G7I%2FAAAAAAAABdQ%2FLAolvMruyekoXYuTwASBeWysW6tkwd4FwCLcBGAsYHQ%2Fs0%2FSRCore%252BAvatar.png",
      icon: "fad fa-adjust",
      name: "SmartRubric Core",
      globalCommentTags:[],
      gradient: [smartrubric.gray.base,smartrubric.teal.base, smartrubric.green.base],
      colorArr: colorArr(colors),
      snackbarTimeout: 2000,
      heatmapArray: [smartrubric.red.base,smartrubric.yellow.base, smartrubric.green.base ],
      baselineInputs: [smartrubric.red.base,smartrubric.yellow.base, smartrubric.green.base ],
      shortDate: 'DD/MM/YYYY',
      connectionNames: ["Username-Password-Authentication", "google-oauth2"],
      loginBG: "background: #98b0b2",
      footerBG: "#98b0b2",
      loginBgDark: false,
      emailContact: "info@smartrubric.com",
      emailTemplateId: 'd-6bc02a62b03a48ec8891dc9b6c2a03c4',
      saEmail(assessmentTitle, rubricTitle, signature, groupName, individual, assessmentTerm, origin, appName){
        return {
          individualId: individual,
          subject: `Please take the ${assessmentTitle} Self ${assessmentTerm}}`,

          primaryCTA: {
            url: `${origin}/invitation`,
            text: `Complete ${assessmentTerm}`,
            helpText: `If this is your first time accessing the ${appName} platform, you will need to set a new password. Please click the 'Don't remember your password?' link and follow the instructions to set a password.`,
          },
          salutation: "Dear ",
          primaryMessage: `I'd like you to complete the ${rubricTitle} self ${assessmentTerm} for ${groupName}.`,

          secondaryMessage: `When you have finished your self-${assessmentTerm}, please click the 'Complete' button to be taken to your preliminary results.`,
          signature: signature,
        };
      },
      short_name: "SmartRubric",
      description: "Formative Assessment Platform",
      print_copyright:"<a href='www.pinemarteneducation.com'>Pinemarten Education</a>",
      copyright:
        "<a href='www.pinemarteneducation.com'>Pinemarten Education</a>. All rights reserved.",
      social_links: [
        {
          icon: "fab fa-facebook",
          url: "https://www.facebook.com/smartrubric",
        },
        {
          icon: "fab fa-twitter",
          url: "https://www.twitter.com/smartrubric",
        },
        {
          icon: "fab fa-instagram",
          url: "https://www.instagram.com/smartrubric",
        },
      ],
    },
  },
  isp: {
    stitch: {
      appClient: "isp_management_portal",
    },
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: {
          secondary: ispColors.green.base,
          primary: "#62ba89",
          accent: ispColors.blue.base,
          success: ispColors.green.lighten3,
          info: ispColors.purple.lighten2,
          warning: "#f9d200",
          error: ispColors.peach.base,
          light: ispColors.shades.white,
          dark: ispColors.grey.darken2,
          anchor: ispColors.blue.base,
          highlight: ispColors.grey.lighten2,
        },
        dark: {
          primary: smartrubric.gray.base,
          secondary: smartrubric.teal.base,
          accent: smartrubric.red.accent2,
          success: smartrubric.green.base,
          info: colors.blue.base,
          warning: smartrubric.yellow.base,
          error: smartrubric.red.base,
          light: smartrubric.white.base,
          dark: smartrubric.gray.base,
          anchor: smartrubric.indigo.lighten2,
          highlight: '#efefef',
        },
      },
    },
    orgId: "5f4e48fed4298b4e9858e8de",
    appInfo: {
      branch: "Production",
      colorfulHeadings: true,
      noMarksText: "-",
      noMarksIcon: "mdi-close",
      noMarksClass: "error--text",
      filename: "isp",
      reportHeaders: reportHeaders.default,
      supportUrl: 'helpme@ispschools.com',
      icon: "fad fa-adjust",
      toolbarIcon: 'icon-white',
      snackbarTimeout: 1500,
      globalCommentTags:['Moderator Comment', 'Action'],
      gradient: ['primary','secondary'],
      colorArr: colorArr(ispColors),
      heatmapArray: ['error','warning','success' ],
      baselineInputs: ['primary','secondary', 'accent' ],
      shortDate: 'DD/MM/YYYY',
      emailContact: "helpme@ispschools.com",
      connectionNames: ["ISPSelfEvalSSO"],
      loginBG: `background: #62ba89`,
      footerBG: `#62ba89`,
      emailTemplateId: 'd-7fb975e93c8a465199c79906c3899889',
      saEmail(assessmentTitle, rubricTitle, signature, groupName, individual, assessmentTerm, origin, appName){
        return {
          individualId: individual,
          subject: `Please take the ${assessmentTitle} Self ${assessmentTerm}}`,

          primaryCTA: {
            url: `${origin}/invitation`,
            text: `Complete ${assessmentTerm}`,
            helpText: `If this is your first time accessing the ${appName} platform, you will need to set a new password. Please click the 'Don't remember your password?' link and follow the instructions to set a password.`,
          },
          salutation: "Dear ",
          primaryMessage: `I'd like you to complete the ${rubricTitle} self ${assessmentTerm} for ${groupName}.`,

          secondaryMessage: `When you have finished your self-${assessmentTerm}, please click the 'Complete' button to be taken to your preliminary results.`,
          signature: signature,
        };
      },
      loginBgDark: false,
      name: "Functional Evaluation",

      short_name: "ISP",
      description: '',
      alert: `<p style="text-transform: none;"> Please ensure you completed the <strong>One-Time activation</strong> sent by email</p><small style="text-transform: none">Contact your Regional Systems Support / Regional Head of Technology if you have issues logging in.</small>
     <p style="text-transform: none; margin-top: 10px; padding-top: 10px;" class='border-top'> Por favor, asegúrese de haber completado la activación única enviada por correo electrónico.</p><small style="text-transform: none">Comuníquese con su Soporte Regional de Sistemas / Jefe Regional de Tecnología si tiene problemas para iniciar sesión.
      </small> `,
      avatar:"https%3A%2F%2F1.bp.blogspot.com%2F-cmTKnfLGv1g%2FX0erym6EYPI%2FAAAAAAAABdc%2FvCDwfcp0wmc4fiHa7Pc5jIp-z6sg14P9ACLcBGAsYHQ%2Fs306%2FISP_Avatar.png",
      
      print_copyright:"International Schools Partnership",
      copyright:
        "<a href='www.pinemarteneducation.com'>Pinemarten Education</a>  <br/> Content belongs to <a href='https://www.internationalschoolspartnership.com/ '>International Schools Partnership</a>.<br/>All rights reserved.",
      social_links: [
        {
          icon: "fas fa-globe",
          url: "https://www.internationalschoolspartnership.com/",
        },
      ],
    },
  },
  designCEO: {
    stitch: {
      appClient: "isp_management_portal",
    },
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: {
          primary: "#292829",
          accent: "#ba2c73",
          secondary: "#fbb516",
          success: "#9cd08f",
          info: "#b8d4e3",
          warning: "#fcc136",
          error: "#e55934",
          light: "#f3eff5",
          dark: "#292829",
          anchor: `#f1aa04`,
          highlight: '#efefef',
        },
        dark: {
          primary: "#292829",
          accent: "#ba2c73",
          secondary: "#fbb516",
          success: "#9cd08f",
          info: "#b8d4e3",
          warning: "#fcc136",
          error: "#e55934",
          light: "#f3eff5",
          dark: "#292829",
          anchor: `#f1aa04`,
          highlight: '#efefef',
        },
      },
    },
    orgId: "5f577333a63ecf6e443a1d37",
    appInfo: {
      branch: "Production",
      noMarksText: "-",
      noMarksIcon: "mdi-close",
      noMarksClass: "error--text",
      filename: "designCEO",
      supportUrl: 'support@smartrubric.com',
      placeholder:``,
      gradient: ['warning','secondary'],
      colorArr: colorArr(colors),
      connectionNames: ["Username-Password-Authentication"],
      globalCommentTags:[],
      snackbarTimeout: 2000,
      reportHeaders: reportHeaders.default,
      heatmapArray: ['error','warning','success' ],
      baselineInputs: ['pink','blue', 'grey' ],
      shortDate: 'DD/MM/YYYY',
      headerFontFamily: "'Oswald', sans-serif;",
      headerTextTransform: 'uppercase',
      tile: true,
      loading: ` <div
        style="
          margin: 0 auto;
          height: 200px;
          width: 200px;
          border-radius: 50%;
          overflow: hidden;
        "
        class="fa-4x pa-5 mt-4"
      >
        <span class="fa-layers fa-fw ma-5">
          <i class="fad fa-spin fa-spinner-third secondary--text" data-fa-transform="grow-30 down-12"></i>
          <i class="fad fa-spin fa-spinner-third info--text" data-fa-transform="grow-15 rotate-60 down-12"></i>
          <i class="fad fa-spin fa-spinner-third success--text" data-fa-transform="grow-3 rotate-90 down-12"></i>
        </span> 
        </div>
        `,
      notFound:`<div
        style="
        margin: 0 auto;
        height: 200px;
        width: 200px;
        border-radius: 50%;
        overflow: hidden;
          "
          class="pa-5 mt-4 fa-4x"
        >
        <i class="fad fa-times secondary--text" data-fa-transform="grow-30 down-12"></i>

        </div>`,
      icon: "fad fa-adjust",
      loginBG: `background: #000000;`,
      footerBG: '#000000',
      loginBgDark: true,
      emailContact: "naturalgifts@smartrubric.com",
      emailTemplateId: 'd-e54af93a214e41698ce1668e9d5ed3d5',
      saEmail(assessmentTitle, rubricTitle, signature, groupName, individual, assessmentTerm, origin, appName){
        return {
          individualId: individual,
          subject: `${assessmentTitle}`,
          primaryCTA: {
            url: `${origin}/invitation`,
            text: `Complete ${assessmentTerm}`,
            helpText: `If this is your first time accessing the ${appName} platform, you will need to set a new password. Please click the 'Don't remember your password?' link and follow the prompts.`,
          },
          salutation: "Hi ",
          primaryMessage: `We would like to invite you to complete the ${rubricTitle} self-${assessmentTerm}.`,

          secondaryMessage: `Once you have completed your self-${assessmentTerm}, please click the 'Complete' button to be taken to the preliminary results. </br> If you have any queries at all don't hesitate to reach out to me via email or 0409 556 083.`,
          signature: signature,
        };
      },
      name: "NATURAL GIFTS",
      short_name: "Natural Gifts",
      description: "Self Assessment",
      avatar:"https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiIY-GWAUaWtlUshTJZ_GMKrotEWgQd1FLmeziNSP__QK6MqvgH3fMTg5GwhqsTQQGHQl03Q-yVasmo-4FaPUQli1OsuGasJu5XuH9jhtnb4qoL2bbCcgnB0hb8WxRZpUWAqjwXZCiPAvbKYECINYCGwRzf-L9YDapzxYGVdU_IebcEPK827JdYKxhz/s320/logo@0,25x.png",
      print_copyright:"LAMBEAU PTY LTD (trading as designCEO).",
      copyright:
        "<a href='www.pinemarteneducation.com'>Pinemarten Education</a>  <br/> Content belongs to <a href='https://www.designceo.com.au/'>LAMBEAU PTY LTD</a>  (trading as designCEO).<br/>All rights reserved.",
      social_links: [
        {
          icon: "fas fa-globe",
          url: "https://www.designceo.com.au/",
        },
      ],
    },
  },
  fluid: {
    stitch: {
      appClient: "isp_management_portal",
    },
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: {
          primary: '#0C3C60',
          secondary: '#59C0CE',
          accent: '#EF767A',
          success: smartrubric.green.base,
          info: colors.blue.base,
          warning: smartrubric.yellow.base,
          error: smartrubric.red.base,
          light: smartrubric.white.base,
          dark: smartrubric.gray.base,
          anchor: '#59C0CE',
          highlight: '#efefef',
        },
        dark: {
          primary: '#0C3C60',
          secondary: '#59C0CE',
          accent: '#EF767A',
          success: smartrubric.green.base,
          info: colors.blue.base,
          warning: smartrubric.yellow.base,
          error: smartrubric.red.base,
          light: smartrubric.white.base,
          dark: smartrubric.gray.base,
          anchor: '#59C0CE',
          highlight: '#efefef',
        },
      },
    },
    orgId: "6319efa7d04c2e6a700ba03c",
    appInfo: {
      branch: "Production",
      noMarksText: "-",
      noMarksIcon: "mdi-close",
      noMarksClass: "error--text",
      filename: "fluid",
      loading: ` <div
      style="
        margin: 0 auto;
        height: 200px;
        width: 200px;
        border-radius: 50%;
        overflow: hidden;
      "
      class="fa-4x pa-5 mt-4"
    >
      <span class="fa-layers fa-fw ma-5">
        <i class="fad fa-spin fa-spinner-third secondary--text" data-fa-transform="grow-30 down-12"></i>
        <i class="fad fa-spin fa-spinner-third info--text" data-fa-transform="grow-15 rotate-60 down-12"></i>
        <i class="fad fa-spin fa-spinner-third success--text" data-fa-transform="grow-3 rotate-90 down-12"></i>
      </span> 
      </div>
      `,
      notFound:`<div
      style="
        margin: 0 auto;
        height: 200px;
        width: 200px;
        border-radius: 50%;
        overflow: hidden;
      "
      class="pa-5 mt-4 fa-4x"
    >
    <i class="fad fa-times secondary--text" data-fa-transform="grow-30 down-12"></i>

    </div>`,
      reportHeaders: reportHeaders.default,
      supportUrl: 'support@smartrubric.com',
      avatar:"https%3A%2F%2F1.bp.blogspot.com%2F-zkai6ws07uA%2FX0epz5X4G7I%2FAAAAAAAABdQ%2FLAolvMruyekoXYuTwASBeWysW6tkwd4FwCLcBGAsYHQ%2Fs0%2FSRCore%252BAvatar.png",
      icon: "fad fa-kiwi-fruit",
      name: "Fluid.EDUCATION",
      globalCommentTags:[],
      htmlName: "<div style='padding-bottom:3px;'>Fluid.<span class='secondary--text'>EDUCATION</span></div>",
      gradient: ['primary','secondary'],
      colorArr: colorArr(colors),
      heatmapArray: ['error','warning','success' ],
      snackbarTimeout: 2000,
      shortDate: 'DD/MM/YYYY',
      connectionNames: ["Username-Password-Authentication", "google-oauth2" ],
      loginBG: "background: #0C3C60",
      footerBG: "#98b0b2",
      loginBgDark: true,
      emailContact: "info@smartrubric.com",
      emailTemplateId: 'd-ef2de802e3c9470f91962738d953f396',
      short_name: "Fluid.EDUCATION",
      description: "Assessment",
      print_copyright:"<a href='https://oliclo.wixsite.com/fluid-education'>Fluid.Education</a>",

      copyright:
        "<a href='https://oliclo.wixsite.com/fluid-education'>Fluid.Education</a>. All rights reserved.",
      social_links: [
        {
          icon: "fad fa-globe",
          url: "https://oliclo.wixsite.com/fluid-education",
        },
        {
          icon: "fab fa-linkedin",
          url: "https://www.linkedin.com/in/olivercloke/?originalSubdomain=uk",
        },
        
      ],
    },
  },
};

const template = {
  theme: configs[org].theme,
  appInfo: configs[org].appInfo,
  orgId: configs[org].orgId,
};

export default template;
