<template>
<v-card flat class="mb-5">
         <v-toolbar  dense flat>
            <v-toolbar-title>New {{'Framework' | term}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showCreate = !showCreate">
                <v-icon v-show="!showCreate">mdi-plus</v-icon>
                <v-icon v-show="showCreate">mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
<v-form
    v-model="valid"
    ref="newFramework"
    v-if="showCreate"
>
    <v-card-text>
              <v-form v-model="valid"  v-if="framework" :key="resetCount">
         
                        <v-text-field 
                            outlined dense
                            :label="$getTerm('Title')"
                            :disabled="saving"
                            v-model="framework.title"
                            :rules="nameRules"
                            >
                        </v-text-field>
                            <wysiwyg 
                            class="rounded mb-5"
                            :label="$getTerm('Title')"
                            v-model="framework.description"
                            :disabled="saving"
                             
                            />
                                   
                       <v-combobox
                         outlined
                          dense
                        hide-no-data
                        single-line
                        small-chips
                        v-model="framework.tags"
                        chips
                        clearable
                        label="Tags"
                        :disabled="saving"
                        multiple
                        append-icon=""
                        >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                class="my-1"
                                dark
                                @click="select"
                                @click:close="removeTag(item)"
                            >
                                {{item}}
                            </v-chip>
                            </template>
                        </v-combobox>
            </v-form>
    </v-card-text>
          
   
    <v-card-actions>
    <v-spacer></v-spacer>
                <v-btn text color="error" :disabled="saving"  @click="reset">
                    <span >Cancel</span>
                </v-btn>
     
            <v-btn :disabled="!valid || saving" text color="success"  @click="create">
                    <span >Create</span>
            </v-btn>
    </v-card-actions>
    
</v-form>
 <v-snackbar
      v-model="error"
      top
      :color="error"
      :timeout="app_info.snackbarTimeout"

    >
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="error = null"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
   
</v-card>

</template>

<script>

export default {
    name: 'CreateFramework',
    data(){
        return {
            valid: false,
            showCreate: false,
            framework: null,
            nameRules: [
                 v => !!v || 'Name is required',
            ],
            saving: false,
            resetCount: 0,
            error: null,
        }
    },
    created(){
        this.reset(); 
    },
   
    methods:{
        reset(){
            this.showCreate = false;
            if(this.framework != null)
            {
                this.framework = null;
            }
            this.framework = {};
            var settings = {
                title: null,
                tags: [],
                description: null,               
            };
            Object.assign(this.framework, settings);
            this.resetCount ++;
        },
        removeTag (item) {
            debugger;
            var i = this.framework.tags.indexOf(item)
            this.framework.tags.splice(i , 1)
            this.framework.tags = [... this.framework.tags];
        },
  
        create(){
            this.saving = true;
            var data = this.framework; 
            this.$post("frameworks/upsert", data).then((response)=>{
                debugger;
                if(response.error){
                    this.error = response.text;
                }
                else{
                    debugger;
                    this.$store.dispatch('saved/get_frameworks')
                    var id = response.id;
                    var route = {
                        name: 'FrameworkSettings',
                        params:{
                            frameworkid: id
                        }
                    };
                    this.$router.push(route);
                }
                this.saving = false;
            });
       
        }
   
    },
    
    props: {
      
     
    }
}
</script>