var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{key:_vm.groupId},[(_vm.group != null)?_c('v-col',{key:_vm.loading},[_c('v-card',{attrs:{"flat":"","loading":_vm.loading &&
        _vm.groupType &&
        _vm.groupType.baseline &&
        _vm.group.isMember &&
        !_vm.group.isAdministrator}},[(
          _vm.group &&
          _vm.person &&
          _vm.readDocs &&
          _vm.groupType.baseline &&
          _vm.group.isMember &&
          !_vm.group.isAdministrator
        )?_c('baseline-report',{attrs:{"showPrint":false,"person":_vm.person,"group":_vm.group}}):_vm._e(),(_vm.miniGroup && !_vm.miniGroup.readonly)?_c('group-info',{attrs:{"person":_vm.person,"group":_vm.miniGroup}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }