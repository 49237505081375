var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.tableView && _vm.$vuetify.breakpoint.mdAndUp && _vm.unit.objectives.length)?_c('v-form',{ref:"bands",attrs:{"value":_vm.bandValid}},[_c('v-simple-table',{staticClass:"matrix",staticStyle:{"overflow-x":"auto"},attrs:{"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"border-right text-center",staticStyle:{"width":"150px"}}),_vm._l((_vm.editableUnit.objectives[0].bands),function(b,gbi){return _c('td',{key:b.id,staticClass:"px-0",staticStyle:{"min-width":"200px"}},[_c('div',{staticClass:"px-0",attrs:{"tile":"","flat":""}},[(b != null)?_c('div',{staticClass:"px-0"},[_c('v-system-bar',{staticClass:"mb-4",attrs:{"color":"highlight"}},[(b.value > 0 && b.floor < _vm.unit.maxMarks)?_c('v-btn',{attrs:{"x-small":"","icon":"","color":"error","disabled":_vm.saving,"text":""},on:{"click":function($event){return _vm.removeBand(gbi)}}},[_c('v-icon',[_vm._v("fal fa-times")])],1):_vm._e(),_c('v-spacer'),(b.floor < _vm.unit.maxMarks)?_c('v-btn',{attrs:{"icon":"","x-small":"","color":"success","disabled":_vm.saving},on:{"click":function($event){return _vm.addBand(gbi)}}},[_c('v-icon',[_vm._v("fal fa-plus")])],1):_vm._e()],1),_c('v-text-field',{staticClass:"pt-4",attrs:{"dense":"","label":"Label","disabled":_vm.saving,"rules":[
                      function (v) { return !!v || 'Label is required'; },
                      function (v) { return v.length >= 1 || 'Label is required'; } ]},on:{"input":_vm.updateBand},model:{value:(b.key),callback:function ($$v) {_vm.$set(b, "key", $$v)},expression:"b.key"}}),_c('v-text-field',{attrs:{"dense":"","disabled":_vm.saving || ['ratingScale'].includes(_vm.unit.format.mode),"label":"Min","rules":[
                      function (v) { return !isNaN(v) || 'Floor is required'; },
                      function (v) { return v >= 0 || 'Value must be positive.'; },
                      function (v) { return v.toString().length > 0 || 'Floor is required'; } ]},on:{"input":_vm.updateBand},model:{value:(b.floor),callback:function ($$v) {_vm.$set(b, "floor", _vm._n($$v))},expression:"b.floor"}}),(!['ratingScale'].includes(_vm.unit.format.mode))?_c('v-text-field',{attrs:{"dense":"","readonly":"","label":"Max","value":_vm.getCeiling(gbi),"hint":("Actual " + (_vm.getTerm('point', true)) + ": " + (b.value)),"persistent-hint":"","disabled":_vm.saving}}):_vm._e(),_c('v-checkbox',{attrs:{"label":"Hidden","dense":"","readonly":_vm.saving},on:{"change":_vm.updateBand},model:{value:(b.hidden),callback:function ($$v) {_vm.$set(b, "hidden", $$v)},expression:"b.hidden"}})],1):_vm._e()])])})],2)]),_c('tbody',_vm._l((_vm.editableUnit.objectives),function(o,oi){return _c('tr',{key:oi,attrs:{"valign":"top"}},[_c('td',{staticClass:"pa-0",staticStyle:{"min-width":"200px"}},[_c('v-system-bar',{staticClass:"mb-4",attrs:{"color":"highlight"}},[_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","text":"","color":"error","disabled":_vm.saving},on:{"click":function($event){return _vm.removeObjective(o.id)}}},[_vm._v(" Delete "+_vm._s(o.title)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-times")])],1)],1),(o)?_c('div',{key:_vm.resetCount,staticClass:"px-0"},[_c('div',{staticClass:"px-2"},[_c('v-text-field',{staticClass:"pt-4",attrs:{"dense":"","label":_vm.$getTerm('Title'),"disabled":_vm.saving,"rules":[
                      function (v) { return !!v || 'Name is required'; },
                      function (v) { return v.length >= 1 || 'Name is required'; },
                      function (v) { return v.length <= 50 || 'Name must be less than 50 characters'; } ]},on:{"input":function($event){return _vm.updateObjective(o.id)}},model:{value:(o.title),callback:function ($$v) {_vm.$set(o, "title", $$v)},expression:"o.title"}}),_c('wysiwyg',{staticClass:"rounded mb-5 mini-wys",attrs:{"label":_vm.$getTerm('Title'),"disabled":_vm.saving,"placeholder":((_vm.$getTerm('Objective')) + " description..."),"hint":"Hint"},on:{"change":function($event){return _vm.updateObjective(o.id)}},model:{value:(o.description),callback:function ($$v) {_vm.$set(o, "description", $$v)},expression:"o.description"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","disabled":_vm.saving,"multiple":"","append-icon":""},on:{"input":function($event){return _vm.updateObjective(o.id)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var attrs = ref.attrs;
                    var item = ref.item;
                    var select = ref.select;
                    var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":selected,"close":"","dark":""},on:{"click":select,"click:close":function($event){return _vm.removeTag(o.id, item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true),model:{value:(o.tags),callback:function ($$v) {_vm.$set(o, "tags", $$v)},expression:"o.tags"}})],1)]):_vm._e()],1),_vm._l((o.bands),function(b,bi){return _c('td',{key:("b" + bi),staticClass:"pa-0"},[_c('v-system-bar',{staticClass:"mb-4",attrs:{"color":"highlight"}},[_c('v-spacer'),_vm._v(" "+_vm._s(b.key)+" "),_c('v-spacer')],1),_c('div',{staticClass:"px-3",staticStyle:{"height":"90%"}},[_c('wysiwyg',{staticClass:"rounded my-5 mini-wys",class:{ 'hidden-wys': b.hidden },staticStyle:{"height":"100%"},attrs:{"placeholder":b.hidden
                      ? ((_vm.$getTerm('Hidden level Text')) + "...")
                      : ((_vm.$getTerm('Level Text')) + "..."),"label":_vm.$getTerm('Title'),"disabled":_vm.saving},on:{"change":function($event){return _vm.updateLevelText(o.id, b.id)}},model:{value:(b.levelText),callback:function ($$v) {_vm.$set(b, "levelText", $$v)},expression:"b.levelText"}})],1)],1)})],2)}),0)]},proxy:true}],null,false,3369985426)})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown || !_vm.tableView)?_c('v-expansion-panels',{key:_vm.resetCount,staticClass:"border-bottom",attrs:{"flat":"","accordion":"","value":0}},[(!['checkList'].includes(_vm.unit.format.mode) && _vm.editableUnit.objectives.length)?_c('v-expansion-panel',{key:0,staticClass:"border-bottom"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{staticClass:"overline"},[_vm._v(" Configure "+_vm._s(_vm._f("pluralTerm")("Band")))])]},proxy:true}],null,false,1375845101)}),_c('v-expansion-panel-content',[_c('v-form',{ref:"bands",attrs:{"value":_vm.bandValid}},[_c('div',{staticClass:"mx-2 d-flex flex-wrap align-start"},_vm._l((_vm.editableUnit.objectives[0].bands),function(b,gbi){return _c('div',{key:b.id,staticClass:"d-flex align-center"},[_c('v-card',{staticClass:"ma-4",attrs:{"outlined":"","tile":_vm.app_info.tile,"max-width":"175"}},[_c('div',{staticClass:"d-flex border-bottom"},[_c('div',[(b != null)?_c('v-card-text',[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Label","disabled":_vm.saving,"rules":[
                          function (v) { return !!v || 'Label is required'; },
                          function (v) { return v.length >= 1 || 'Label is required'; } ]},on:{"change":_vm.updateBand},model:{value:(b.key),callback:function ($$v) {_vm.$set(b, "key", $$v)},expression:"b.key"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","disabled":_vm.saving,"label":"Min","rules":[
                          function (v) { return !isNaN(v) || 'Floor is required'; },
                          function (v) { return v >= 0 || 'Value must be positive.'; },
                          function (v) { return v.toString().length > 0 || 'Floor is required'; } ]},on:{"change":_vm.updateBand},model:{value:(b.floor),callback:function ($$v) {_vm.$set(b, "floor", _vm._n($$v))},expression:"b.floor"}}),(!['ratingScale'].includes(_vm.unit.format.mode))?_c('v-text-field',{attrs:{"dense":"","outlined":"","readonly":"","label":"Max","value":_vm.getCeiling(gbi),"hint":("Actual " + (_vm.getTerm('point', true)) + ": " + (b.value)),"persistent-hint":""}}):_vm._e(),_c('v-checkbox',{attrs:{"label":"Hidden"},on:{"change":_vm.updateBand},model:{value:(b.hidden),callback:function ($$v) {_vm.$set(b, "hidden", $$v)},expression:"b.hidden"}})],1):_vm._e()],1)]),_c('v-card-actions',{staticClass:"white dark--text"},[_vm._v(" "+_vm._s(Math.round((b.floor / 100) * _vm.unit.maxMarks))+" marks "),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.saving,"color":"error","text":""},on:{"click":function($event){return _vm.removeBand(gbi)}}},[_vm._v(" Remove ")])],1)],1),(b.floor < _vm.unit.maxMarks)?_c('v-btn',{staticClass:"elevation-1",attrs:{"color":"success","disabled":_vm.saving,"icon":""},on:{"click":function($event){return _vm.addBand(gbi)}}},[_c('v-icon',[_vm._v("fal fa-plus")])],1):_vm._e()],1)}),0)])],1)],1):_vm._e(),_vm._l((_vm.editableUnit.objectives),function(o,oi){return _c('v-expansion-panel',{key:oi + 1},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(oi + 1)+". "+_vm._s(o.title))])]},proxy:true}],null,true)}),_c('v-expansion-panel-content',[(o)?_c('v-form',{key:_vm.resetCount,model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$getTerm('Title'),"disabled":_vm.saving,"rules":[
                function (v) { return !!v || 'Name is required'; },
                function (v) { return v.length >= 1 || 'Name is required'; },
                function (v) { return v.length <= 50 || 'Name must be less than 50 characters'; } ]},on:{"input":_vm.change},model:{value:(o.title),callback:function ($$v) {_vm.$set(o, "title", $$v)},expression:"o.title"}}),_c('wysiwyg',{staticClass:"rounded mb-5",attrs:{"label":_vm.$getTerm('Title'),"disabled":_vm.saving},on:{"change":_vm.change},model:{value:(o.description),callback:function ($$v) {_vm.$set(o, "description", $$v)},expression:"o.description"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","disabled":_vm.saving,"multiple":"","append-icon":""},on:{"input":_vm.change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var attrs = ref.attrs;
              var item = ref.item;
              var select = ref.select;
              var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":selected,"close":"","dark":""},on:{"click":select,"click:close":function($event){return _vm.removeTag(o.id, item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,true),model:{value:(o.tags),callback:function ($$v) {_vm.$set(o, "tags", $$v)},expression:"o.tags"}})],1)],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.saving,"color":"error"},on:{"click":function($event){return _vm.removeObjective(o.id)}}},[_vm._v("Delete "+_vm._s(_vm._f("term")("objective")))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || _vm.saving,"color":"warning"},on:{"click":_vm.reset}},[_vm._v("reset")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || !_vm.valid || _vm.saving,"color":"success"},on:{"click":function($event){return _vm.updateObjective(o.id)}}},[_vm._v("Save Changes")])],1),_c('v-card-text',[(_vm.unit.format.mode == 'matrix')?_c('v-list',_vm._l((o.bands),function(b,bi){return _c('v-list-item',{key:("b" + bi)},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"overline"},[_vm._v(_vm._s(b.key))]),_c('wysiwyg',{staticClass:"rounded mb-5",attrs:{"label":_vm.$getTerm('Title'),"disabled":_vm.saving},on:{"change":function($event){return _vm.updateLevelText(o.id, b.id)}},model:{value:(b.levelText),callback:function ($$v) {_vm.$set(b, "levelText", $$v)},expression:"b.levelText"}})],1)],1)}),1):_vm._e()],1)],1)],1)})],2):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.saving,"block":"","text":""},on:{"click":_vm.addObjective}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus")]),_vm._v(" Add "+_vm._s(_vm._f("term")("Objective"))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }