<template>
  <v-card :tile="app_info.tile" v-if="group">
    <v-toolbar flat>
      <v-toolbar-title class="d-flex align-center">
        <sr-group-chip selectOption :path="false" :id="group.id"></sr-group-chip>
        <span v-if="miniGroup">
          <sr-group-path
            :center="false"
            :self="{ name: miniGroup.displayText, groupType: groupType.name }"
            :path="miniGroup.path"
          ></sr-group-path>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn primary icon @click="goToGroup(group, true)">
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-subtitle v-if="role" class="overline">
      <span> Role: {{ role }}</span>
    </v-card-subtitle>
    <sr-permissions
      v-if="groupType && role"
      :parent="miniGroup.displayText"
      :showMember="role == this.groupType.memberName"
      :showAdmin="role == this.groupType.adminName"
      :gt="groupType"
    ></sr-permissions>
  </v-card>
</template>

<script>
export default {
  name: "GroupInfo",
  computed: {
    role() {
      if (this.group && this.groupType) {
        var isAdmin = false;
        var isMember = false;
        if (this.person) {
          isAdmin = this.group.admins.includes(this.person.id);
          isMember = this.group.members.includes(this.person.id);
        } else {
          isAdmin = this.group.isAdministrator;
          isMember = this.group.isMember;
        }
        if (isAdmin) {
          return this.groupType.adminName;
        }
        if (isMember) {
          return this.groupType.memberName;
        }
      }
      return null;
    },
    groupType() {
      return this.miniGroup
        ? this.$store.getters["saved/get"](["groupTypes", this.miniGroup.groupType])
        : null;
    },
    miniGroup() {
      return this.group
        ? this.$store.getters["saved/get"](["groups", this.group.id])
        : null;
    },
  },
  props: {
    group: {
      type: Object,
    },
    person: {
      type: Object,
    },
  },
};
</script>
