var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.saving}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Edit Metadata")])],1),_c('v-card-text',[(_vm.editableSettings)?_c('v-form',{key:_vm.resetCount,model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$getTerm('Title'),"disabled":_vm.saving != false,"rules":[
              function (v) { return !!v || 'Name is required'; },
              function (v) { return v.length <= 150 || 'Name must be less than 150 characters'; } ]},on:{"input":_vm.change},model:{value:(_vm.editableSettings.title),callback:function ($$v) {_vm.$set(_vm.editableSettings, "title", $$v)},expression:"editableSettings.title"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Date","disabled":_vm.saving != false,"type":"date","rules":[function (v) { return !!v || 'Date is required'; }]},on:{"input":_vm.change},model:{value:(_vm.editableSettings.date),callback:function ($$v) {_vm.$set(_vm.editableSettings, "date", $$v)},expression:"editableSettings.date"}})],1)],1),_c('wysiwyg',{staticClass:"rounded mb-5",attrs:{"label":_vm.$getTerm('Title'),"disabled":_vm.saving != false},on:{"change":_vm.change},model:{value:(_vm.editableSettings.description),callback:function ($$v) {_vm.$set(_vm.editableSettings, "description", $$v)},expression:"editableSettings.description"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","disabled":_vm.saving != false,"multiple":"","append-icon":""},on:{"input":_vm.change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var attrs = ref.attrs;
            var item = ref.item;
            var select = ref.select;
            var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":selected,"close":"","dark":""},on:{"click":select,"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2537883044),model:{value:(_vm.editableSettings.tags),callback:function ($$v) {_vm.$set(_vm.editableSettings, "tags", $$v)},expression:"editableSettings.tags"}}),_c('v-switch',{attrs:{"disabled":_vm.saving != false,"label":("Self " + (_vm.$getTerm('Assessment')) + "?")},on:{"change":_vm.change},model:{value:(_vm.editableSettings.selfAssessment),callback:function ($$v) {_vm.$set(_vm.editableSettings, "selfAssessment", $$v)},expression:"editableSettings.selfAssessment"}})],1):_vm._e()],1),_c('v-card-actions',[(_vm.$auth.isAdmin)?_c('v-btn',{attrs:{"disabled":_vm.saving != false,"color":"error"},on:{"click":_vm.deleteAssessment}},[_vm._v("delete")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || _vm.saving != false,"color":"warning"},on:{"click":_vm.reset}},[_vm._v("reset")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || !_vm.valid || _vm.saving != false,"color":"success"},on:{"click":_vm.update}},[_vm._v("Save Changes")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }