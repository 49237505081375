<template>
  <v-card :flat="flat || editMode" v-if="value">
    <v-snackbar v-model="loading">Saving</v-snackbar>
    <v-card-subtitle class="overline py-3 d-flex justify-space-between" v-if="!editMode">
      <strong> {{ value.title }} </strong>
      <v-chip v-if="format.showUnitScores" small>
        {{ unitScore }}/{{ value.marks }}
      </v-chip>
      <v-chip
        v-if="
          false &&
          (format.showUnitGrades || org_id == '5f4e48fed4298b4e9858e8de') &&
          grade != null &&
          grade.length
        "
        small
      >
        {{ grade[0] }}
      </v-chip>
    </v-card-subtitle>
    <v-card-text v-if="editMode" v-html="value.description"> </v-card-text>
    <v-row v-if="editMode && !disabled">
      <v-col class="text-center">
        <v-btn
          v-show="complete != value.objectives.length && editMode"
          text
          small
          outlined
          @click="hideCompletedObjectives = !hideCompletedObjectives"
        >
          <span v-if="hideCompletedObjectives">Show </span>
          <span v-else>Hide </span>
          &nbsp; Completed
        </v-btn>
        <v-btn
          small
          outlined
          disabled
          v-show="complete == value.objectives.length && editMode && !disabled"
        >
          <v-icon left class="text--success">fal fa-check</v-icon>
          <span> {{ "Unit" | term }} Complete </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear
      color="secondary"
      :value="(complete / value.objectives.length) * 100"
    >
    </v-progress-linear>

    <v-container fluid :class="{ 'px-0': flat }" class="py-0">
      <v-row
        class="light"
        v-if="!editMode && (format.unitLevelComment || format.unitLevelComments)"
      >
        <v-col>
          <v-subheader class="overline py-0">{{
            format.unitLevelCommentLabel
          }}</v-subheader>

          <edit-comment
            :multiline="true"
            :items="value.comments"
            :label="singularize(format.unitLevelCommentLabel)"
            :rich="false"
            valueField="text"
            :showHeaders="false"
            type="comment"
            @change="updateUnitComment"
            :tags="commentTags(format.unitCommentTags)"
            :allowCreateTags="format.unitAllowCreateTags"
            :readonly="true"
            reportView
            :showAuthors="true"
            :preserveAuthorship="true"
            :replies="true"
          >
          </edit-comment>
        </v-col>
      </v-row>

      <v-row v-if="false">
        <v-col class="d-print-none" v-if="pageLength > 1">
          <v-item-group
            class="d-flex align-center justify-space-between flex-nowrap"
            v-model="pagination.page"
            @change="next"
          >
            <v-item :value="pagination.page - 1" v-slot="{ toggle }" class="ma-2">
              <v-btn small outlined @click="toggle" :disabled="pagination.page == 1">
                <v-icon>fal fa-angle-left</v-icon>
                <span class="ml-3">Previous</span>
              </v-btn>
            </v-item>
            <span class="caption"> Page {{ pagination.page }} of {{ pageLength }} </span>
            <v-item :value="pagination.page + 1" v-slot="{ toggle }" class="ma-2">
              <v-btn
                outlined
                @click="toggle"
                :disabled="pagination.page == pageLength"
                small
              >
                <span class="mr-3">Next</span>
                <v-icon>fal fa-angle-right</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row v-if="!editMode">
        <v-col>
          <v-card-text v-if="!editMode" class="overline pt-3 py-0">
            <strong>Responses</strong>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row
        :class="{
          'success-border-key': o.selectedBand && editMode,
          'border-bottom': i != value.objectives ? value.objectives.length - 1 : 0,
        }"
        v-for="(o, i) in value.objectives"
        :key="o.id"
        v-show="
          (o.selectedBand == null && hideCompletedObjectives) ||
          !hideCompletedObjectives ||
          complete == value.objectives.length
        "
      >
        <v-col class="px-5">
          <strand-mark
            :editMode="editMode"
            class="mx-5"
            :reviewedDate="value.metadata.lastUpdated"
            :disabled="loading || disabled"
            v-model="o.selectedBand"
            :id="o.id"
            :index="i + 1"
            :title="o.title"
            :comments="o.comments"
            :enableComment="
              format.objectiveLevelComment && org_id != '5f577333a63ecf6e443a1d37'
            "
            :commentLabel="
              singularize(getCommentLabel(format.objectiveLevelCommentLabel))
            "
            :options="o.bands"
            :description="o.description"
            :commentTags="commentTags(format.evidenceTags)"
            :allowCreateTags="format.createEvidenceTags"
            @mark="update"
            @updateComment="updateComment"
            :mode="format.mode"
            :letterForRatingScale="format.letterForRatingScale"
          >
          </strand-mark>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col class="d-flex justify-center d-print-none">
          <v-item-group
            class="d-flex flex-nowrap"
            v-model="pagination.page"
            @change="next"
          >
            <v-item :value="pagination.page - 1" v-slot="{ active, toggle }" class="ma-2">
              <v-btn
                icon
                :color="active ? 'secondary' : ''"
                elevation="1"
                @click="toggle"
                :disabled="pagination.page == 1"
              >
                <v-icon>fal fa-angle-left</v-icon>
              </v-btn>
            </v-item>
            <v-item
              v-for="n in pageLength"
              :value="n"
              :key="n"
              v-slot="{ active, toggle }"
              class="ma-2"
            >
              <v-btn
                :color="active ? 'secondary' : 'light darken-2'"
                @click="toggle"
                icon
                elevation="1"
              >
                <v-icon v-show="completeProgress(n)"> fal fa-check </v-icon>
                <span v-show="!completeProgress(n)">{{ n }}</span>
              </v-btn>
            </v-item>
            <v-item :value="pagination.page + 1" v-slot="{ active, toggle }" class="ma-2">
              <v-btn
                elevation="1"
                icon
                @click="toggle"
                :disabled="pagination.page == pageLength"
              >
                <v-icon>fal fa-angle-right</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col class="text-center">
          <v-btn small @click="toggleShowAll">
            <span v-if="pagination.perPage == value.objectives.length">Paginate</span>
            <span v-else>Show All</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="comment-border-left border-top secondary"
        v-if="editMode && (format.unitLevelComment || format.unitLevelComments)"
      >
        <v-col>
          <v-subheader class="overline white--text py-0"
            ><strong>{{ format.unitLevelCommentLabel }}</strong></v-subheader
          >
          <v-card>
            <edit-comment
              :multiline="true"
              :items="value.comments"
              :label="singularize(format.unitLevelCommentLabel)"
              :rich="false"
              valueField="text"
              :showHeaders="false"
              type="comment"
              @change="updateUnitComment"
              :tags="commentTags(format.unitCommentTags)"
              :allowCreateTags="format.unitAllowCreateTags"
              v-if="editMode"
              :readonly="disabled || loading"
              :showAuthors="true"
              :preserveAuthorship="true"
              :replies="true"
            >
            </edit-comment>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-fab-transition>
      <v-btn
        href="#app"
        color="primary"
        fab
        dark
        style="position: fixed; bottom: 20px; right: 20px"
        bottom
        v-if="value.objectives.length >= 10"
        left
        class="v-btn--example"
      >
        <v-icon>fas fa-angle-up</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<script>
import StrandMark from "./StrandMark.vue";
import EditComment from "./EditComment.vue";

export default {
  name: "UnitMark",
  components: {
    StrandMark,
    EditComment,
  },
  data() {
    return {
      loading: false,
      resetCount: 0,
      hideCompletedObjectives: false,
      /* pagination: {
                page: 1,
                perPage: 10,
                visible: 7,
                items: []
            }
            */
    };
  },
  watch: {
    unitId: function () {
      this.unitChange++;
    },
  },

  methods: {
    getCommentLabel(label) {
      if (label == "Comment" && this.app_info.short_name == "ISP") {
        return "Evidence";
      }
      return label;
    },
    commentTags(arr) {
      debugger;
      var result = this.app_info.globalCommentTags;
      if (arr && (arr.length || arr == [])) {
        result = result.concat(arr, this.app_info.globalCommentTags);
      }
      return result;
    },
    updateComment(e, objective) {
      var data = {
        id: this.assessmentId,
        iaId: this.iaId,
        unitId: this.value.id,
        objectiveId: objective,
        comments: e,
        secret: this.secret,
      };
      console.log("sending");

      this.$post(`update/mark/${this.assessmentId}/comments`, data).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            `Could not save mark. Please reload the page and try again.`
          );
        } else {
          console.log(response);
          //this.$emit("input", e);
        }
        this.loading = false;
        this.resetCount++;
      });
    },
    updateUnitComment(e) {
      var data = {
        id: this.assessmentId,
        iaId: this.iaId,
        unitId: this.value.id,
        comments: e,
        secret: this.secret,
      };
      console.log("sending");

      this.$post(`update/mark/${this.assessmentId}/comments`, data).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            `Could not save mark. Please reload the page and try again.`
          );
        } else {
          console.log(response);
          //this.$emit("input", e);
        }
        this.loading = false;
        this.resetCount++;
      });
    },
    update(e, objective) {
      console.log("beginning unit mark");

      //  this.loading = true;
      /* debugger;
            console.log(e)
            console.log(objective);
            var o = this.value.objectives.find(x=>x.id == objective);
            var band = o.bands.find(x=>x.id == selectedBand);
            var bandIndex = o.bands.indexOf(band);
            var levelUp = o.bands[bandIndex + 1];
            */

      var data = {
        id: this.assessmentId,
        iaId: this.iaId,
        unitId: this.value.id,
        objectiveId: objective,
        selectedBand: e,
        secret: this.secret,
      };
      console.log("sending");

      this.$post(`update/mark/${this.assessmentId}`, data).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            `Could not save mark. Please reload the page and try again.`
          );
        } else {
          console.log(response);
          this.$emit("updateMark", {
            unitId: data.unitId,
            objectiveId: data.objectiveId,
            selectedBand: data.selectedBand,
          });

          //this.$emit("input", e);
        }
        //this.loading = false;
        // this.resetCount++;
      });
    },
    /* next (page) {
          this.$router.push({ query: Object.assign({}, this.$route.query, { page: page }) });
        },
        updateItems(page){
             debugger;
            console.log(page)
            var offset =  (page - 1) * this.pagination.perPage + 1;
            var clone = this.$r.clone(this.value.objectives);
            var items = clone.splice(offset, this.pagination.perPage);
            this.pagination.items = items; 
        },
        completeProgress(page){
            debugger;
            var offset =  (page - 1) * this.pagination.perPage + 1;
            var clone = this.$r.clone(this.value.objectives);
            var items = clone.splice(offset, this.pagination.perPage);
            var result = items.filter(x=>x.selectedBand != null).length;
            var total = items.length;
            return result == total; 
        },
        toggleShowAll(){
            var page = this.pagination.page; 
            if(this.pagination.perPage != this.value.objectives.length){
                this.pagination.perPage = this.value.objectives.length

            }
            else{
                this.pagination.perPage = 10;
            }
            this.next(page)
        }
        */
  },
  computed: {
    /*currentPage(){
            return this.$route.query.page ? this.$route.query.page : 1 ;
        },
        pageLength(){
            return this.value.objectives.length / this.pagination.perPage; 
        },
        offset(){
            return (this.pagination.page - 1) * this.pagination.perPage + 1
        },
       */
    format() {
      return this.value ? this.value.format : null;
    },
    unitId() {
      return this.$route.query.unit;
    },
    unitScore() {
      return this.format.stackScore ? this.sum : this.avgScore;
    },
    sum() {
      return this.value ? this.$r.sum(this.value.objectives.map((x) => x.value)) : null;
    },
    avgScore() {
      return this.sum
        ? Math.round(
            this.sum / (this.value.objectives.length ? this.value.objectives.length : 1)
          )
        : null;
    },
    complete() {
      return this.value
        ? this.value.objectives.length -
            this.value.objectives.filter((x) => null == x.selectedBand).length
        : 0;
    },
    grade() {
      if (this.value && this.value.gradeBoundaries && this.value.gradeBoundaries.length) {
        debugger;
        var score = this.unitScore ? this.unitScore : 0;
        var pc =
          (score / (this.value && this.value.marks != 0 ? this.value.marks : 1)) * 100.0;
        var result = this.value.gradeBoundaries
          .filter((x) => x.floor <= pc)
          .sort((a, b) => {
            if (a.floor > b.floor) {
              return -1;
            }
            if (b.floor < a.floor) {
              return 1;
            }
            return 0;
          });
        if (result.length) {
          return result[0].key;
        }
      }
      return null;
    },
  },
  props: {
    value: {
      type: Object,
    },
    iaId: {
      type: String,
    },
    individualId: {
      type: String,
    },
    assessmentId: {
      type: String,
    },
    secret: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
