<template>
  <v-card :loading="loading" v-if="groupType">
    <v-toolbar flat>
      <v-toolbar-title
        class="mx-4"
        :key="`${updates}+title`"
        v-html="addPersonTitle"
      ></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="cancelAddPerson" color="error" text>
        <v-icon>fal fa-times</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="showCreatePerson"
          mandatory
          class="mx-4"
          dense
          color="secondary"
        >
          <v-btn :value="false" :disabled="loading"> Existing </v-btn>
          <v-btn :value="true" :disabled="loading"> New </v-btn>
        </v-btn-toggle>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!showCreatePerson" class="mt-4">
      <v-row class="align-content-center">
        <v-col cols="3">
          <span class="text-subtitle-1">Select Existing</span>
        </v-col>
        <v-col class="grow align-self-end">
          <v-select
            dense
            :disabled="loading"
            outlined
            v-model="addExisting"
            item-text="label"
            item-value="value"
            ref="addExisting"
            :items="filteredPersonOptions"
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    class="mx-2"
                    prepend-icon="fal fa-search"
                    v-model="search"
                  >
                  </v-text-field>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else>
      <v-form ref="form">
        <v-row>
          <v-col>
            <v-text-field
              v-model.lazy="newPerson.firstName"
              :rules="nameRules"
              label="First Name*"
              :disabled="loading"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model.lazy="newPerson.lastName"
              label="Last Name*"
              :disabled="loading"
              :rules="nameRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model.lazy="newPerson.title"
              label="Title"
              persistent-hint
              :disabled="loading"
              hint="(optional)"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model.lazy="newPerson.nickname"
              label="Nickname"
              :disabled="loading"
              persistent-hint
              hint="(optional) If added, this will be used for the person's display name in place of their first name in the app, as well as in email communications."
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model.lazy="newPerson.gender"
              label="Gender"
              :disabled="loading"
              persistent-hint
              hint="(optional)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model.lazy="newPerson.emails.address"
              :rules="emailRules"
              :disabled="loading"
              label="Email Address"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" v-if="$auth.isAdmin || $auth.isDataManager">
            <v-row>
              <v-col>
                <v-checkbox
                  :disabled="!validEmail || loading"
                  v-model="newPersonOptions.createAccount"
                  label="Create Account"
                >
                </v-checkbox>
              </v-col>
              <v-col v-if="newPersonOptions.createAccount">
                <v-checkbox
                  v-model="newPersonOptions.sendEmail"
                  label="Send Invitation Email"
                  :disabled="loading"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-checkbox
        :disabled="loading"
        class="mx-5"
        v-model="newPersonOptions.isAdmin"
        :label="groupType.adminName"
      ></v-checkbox>

      <v-spacer></v-spacer>
      <v-btn @click="cancelAddPerson" :disabled="loading" color="error" text
        >Cancel</v-btn
      >
      <v-btn
        outlined
        color="success"
        @click="addExistingPerson"
        v-if="!showCreatePerson"
        :disabled="!addExisting || loading"
        >Save</v-btn
      >
      <v-btn v-else @click="savePerson" outlined :disabled="loading" color="success"
        >Save New</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AddMember",
  data() {
    return {
      loading: false,
      showCreatePerson: false,
      addExisting: null,
      search: null,
      newPerson: {
        firstName: null,
        lastName: null,
        gender: null,
        nickname: null,
        title: null,
        emails: {
          address: null,
          primary: true,
        },
      },
      newPersonOptions: {
        isAdmin: false,
        createAccount: false,
      },
      updates: 0,
    };
  },
  computed: {
    validEmail() {
      return this.newPerson.emails.address && this.newPerson.emails.address.length
        ? this.validateEmail(this.newPerson.emails.address)
        : false;
    },
    nameRules() {
      var rules = [];
      const required = (v) => v != null || "Required";
      rules.push(required);
      const length = (v) =>
        ((v || "").length != 0 && (v || "").trim() != "") ||
        "Minimum 1 character required";
      rules.push(length);
      return rules;
    },
    emailRules() {
      var rules = [];
      const validEmail = (v) => {
        var valid = v && v.length != 0 ? this.validateEmail(v) : true;
        return valid || "Invalid Email Address";
      };
      rules.push(validEmail);
      if (this.newPersonOptions.createAccount == true) {
        const required = (v) => v != null || "Email address required to create account.";
        rules.push(required);
      }
      return rules;
    },
    addPersonTitle() {
      var result = `Add ${this.groupType.memberName} to ${this.group.metadata.name}`;
      if (this.newPersonOptions.isAdmin) {
        result = `Add ${this.groupType.adminName} to ${this.group.metadata.name}`;
      }
      return result;
    },
    filteredPersonOptions() {
      return this.$r.clone(this.personOptions).filter((x) => {
        if (this.search != null && this.search.length > 0) {
          return x.label.toLowerCase().includes(this.search.toLowerCase());
        }
        return true;
      });
    },
    self() {
      return this.$store.getters["saved/self"];
    },
    signature() {
      var self = this.$store.getters["saved/self"];
      var result = {};
      if (self) {
        var n = this.getDisplayName(self);
        if (self.defaultEmailSignature) {
          result = {
            name: n,
            greeting: self.defaultEmailSignature.greeting ?? "Dear ",
            closing: self.defaultEmailSignature.closing ?? "All the best,",
            email: self.defaultEmailSignature.email ?? { address: self.username },
            phone: self.defaultEmailSignature.phone ?? null,
            organisation: this.app_info.name,
          };
        } else {
          result = {
            name: n,
            greeting: "Dear ",
            closing: "All the best,",
            email: { address: self.username },
            organisation: this.app_info.name,
          };
        }
      }
      return result;
    },
    personOptions() {
      var result = [];
      var p = this.$store.getters["saved/getAll"]("people");
      if (p && p.length) {
        var list = p.map((o) => {
          return {
            label: o.displayText,
            value: o.id,
          };
        });
        result = list.filter((y) => {
          var exists = this.group.members.filter((x) => x.individualId == y.value);
          if (!exists || exists.length == 0) {
            return y;
          }
        });
      }
      return result;
    },
  },
  methods: {
    cancelAddPerson() {
      this.$emit("cancel");
      this.clear();
    },
    clear() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (this.$refs.addExisting) {
        this.$refs.addExisting.clearSelection();
      }
    },
    initPerson() {
      console.log(this.newPerson);
      this.updateAddPersonTitle(false);
      this.newPerson = null;
      this.newPerson = {
        firstName: null,
        lastName: null,
        gender: null,
        nickname: null,
        title: null,
      };
      this.newPerson.orgs = [this.group.orgId];
      this.newPerson.groupId = this.group.id;
      this.newPersonOptions = {};
      this.newPersonOptions = Object.assign(this.newPersonOptions, {
        isAdmin: false,
        internalID: null,
        createAccount: false,
        sendEmail: false,
      });
      this.newPerson.emails = { address: null, primary: true };
      console.log(this.newPerson);
    },
    addExistingPerson() {
      this.loading = true;
      this.saveMembership(this.addExisting, this.newPersonOptions.isAdmin);
      this.addExisting == null;
      this.loading = false;
      this.clear();
    },
    savePerson() {
      debugger;
      var valid = this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        console.log("valid");
        var data = {
          firstName: this.newPerson.firstName,
          lastName: this.newPerson.lastName,
          email: this.newPerson.emails.address,
          gender: this.newPerson.gender,
          nickname: this.newPerson.nickname,
          title: this.newPerson.title,
          // password: x.password ?? null,
          groupId: this.group.id,
          admin: this.newPersonOptions.isAdmin,
          createUser: this.newPersonOptions.createAccount,
          roles: ["rol_33OrPJsMkyh5wRCo"],
          inviterName: this.self
            ? `${this.self.firstName} ${this.self.lastName}`
            : `${this.app_info.name} Admin`,
          inviterEmail: this.app_info.emailContact, //this.self ? this.self.username : "support@smartrubric.com",
          sendEmail: this.newPersonOptions.sendEmail,
          clientId: this.app_info.clientId,
          connectionName: this.app_info.connectionNames[0],
          msgTemplateId: this.app_info.emailTemplateId,
          msg: {
            subject: `Invitation to join ${this.app_info.name}`,
            name: `${this.newPerson.firstName} ${this.newPerson.lastName}`,
            primaryCTA: {
              url: `${window.location.origin}/login`,
              text: "Join Now",
            },
            salutation: "Dear ",
            primaryMessage: `I'd like you to join ${this.group.metadata.name} on the ${this.app_info.name} platform.`,
            secondaryMessage: `Your username is ${this.newPerson.emails.address}. If you already have an account, you can log in <a href="${window.location.origin}/app">here</a>.`,
            signature: this.signature,
          },
        };

        this.$post(`entities/groups/${this.group.id}/members/add`, data).then(
          (response) => {
            debugger;
            if (response.error) {
              this.$emit("update", {
                name: `${data.nickname ? data.nickname : data.firstName} ${
                  data.lastName
                }`,
                error: true,
              });
            } else {
              this.$emit("update", {
                name: `${data.nickname ? data.nickname : data.firstName} ${
                  data.lastName
                }`,
              });
              // this.$store.dispatch("saved/get_people");
              this.loading = false;
              this.clear();
            }
          }
        );
      }
    },
    saveMembership(id, admin) {
      var person = this.personOptions.find((x) => x.value == id);
      var name = person != null ? person.label : null;
      var data = {
        membership: {
          groupId: this.group.id,
          admin: admin,
          individualId: id,
        },
        name: name,
      };
      this.$emit("addExisting", data);
      //  var newGroup = {};
      //  newGroup = Object.assign(newGroup, this.group);
      //  newGroup.members.push(member);
      //  this.save(newGroup);
    },
  },
  props: {
    group: {
      type: Object,
    },
    groupType: {
      type: Object,
    },
  },
};
</script>
