<script>
export default {
  name: "Home",
  functional: true,
  props: {
    template: {
      type: Object,
    },
    isAdministrator: {
      type: Boolean,
    },
  },
  render: function (h, context) {
    function renderTemplate(node) {
      var show = node.showFn(context.props);
      if (show) {
        var result = h(
          node.type,
          {
            class: node.class,
            attrs: node.attrs,
            props: node.props,
          },
          []
        );
        if (node.children != null && node.children.length != 0) {
          node.children.forEach((child) => {
            result.children.push(renderTemplate(child));
          });
        }
        if (node.text) {
          result = h(
            node.type,
            {
              class: node.class,
              attrs: node.attrs,
              props: node.props,
            },
            node.text
          );
        }

        return result;
      }
      return h("div", { class: "d-none" }, "hidden: " + node.type);
    }
    debugger;
    var result = renderTemplate(context.props.template.home);
    return result;
  },
};
</script>
