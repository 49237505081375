<template>
  <div ref="pageTitle">
    <v-system-bar class="d-print-none" dark color="primary">
      <span class="overline ml-5 pl-5"> {{ "assessment" | pluralTerm }} </span>

      <v-spacer></v-spacer>
    </v-system-bar>
    <v-toolbar v-if="assessment" dense flat :key="loading" class="d-print-none">
      <v-toolbar-items class="no-padder">
        <v-btn @click="showBreadCrumbs = !showBreadCrumbs" text class="px-0">
          <v-icon v-show="showBreadCrumbs">mdi-chevron-right</v-icon>
          <v-icon v-show="!showBreadCrumbs">mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          v-if="group && !showBreadCrumbs"
          text
          large
          class="px-1"
          :to="{ name: 'GroupHome', params: { groupid: assessment.group } }"
        >
          <span :style="{ color: group.colour }">{{ group.displayText }}</span>
        </v-btn>
        <v-icon v-if="!showBreadCrumbs" class="text-button mt-1"
          >mdi-chevron-right</v-icon
        >
        <v-btn text large class="px-1" disabled>
          <span>{{ assessment.title }}</span>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items v-if="showBreadCrumbs">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="pt-4">
              <i class="fal fa-ellipsis-h"></i>
            </v-btn>
          </template>

          <v-list>
            <v-subheader hidden class="overline">Back To...</v-subheader>
            <v-list-item dense exact link :to="{ name: 'Assessments' }">
              <v-list-item-title>
                <i color="primary" class="fad fa-edit mr-3"></i>
                All {{ "assessment" | pluralTerm }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="group"
          text
          large
          class="px-1"
          :to="{ name: 'GroupHome', params: { groupid: assessment.group } }"
        >
          {{ group.displayText }}
        </v-btn>
        <v-icon v-if="group" class="text-button mt-1">mdi-chevron-right</v-icon>
        <v-btn
          v-if="group"
          text
          large
          class="px-1"
          :to="{ name: 'GroupAssessments', params: { groupid: assessment.groupId } }"
        >
          {{ "assessment" | pluralTerm }}
        </v-btn>
        <v-icon v-if="group" class="text-button mt-1">mdi-chevron-right</v-icon>
      </v-toolbar-items>
      <v-toolbar-title class="text-button ml-2 mt-1">
        {{ assessment.assessmentTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items
        v-if="write && $vuetify.breakpoint.mdAndUp && !showBreadCrumbs && assessmentid"
      >
        <sr-assessment-tabs
          v-if="assessment"
          :assessmentid="assessmentid"
          :canWrite="permissions.write"
          :settings="permissions.settings"
        >
        </sr-assessment-tabs>
      </v-toolbar-items>

      <template
        v-slot:extension
        v-if="write && ($vuetify.breakpoint.smAndDown || showBreadCrumbs)"
      >
        <sr-assessment-tabs
          v-if="assessment"
          :assessmentid="assessmentid"
          :canWrite="permissions.write"
          :settings="permissions.settings"
          grow
        >
        </sr-assessment-tabs>
      </template>
    </v-toolbar>
    <v-divider class="d-print-none"></v-divider>
    <div v-if="assessment">
      <router-view
        @error="showError"
        @reset="init"
        v-bind="{
          assessment: assessment,
          permissions: permissions,
          resetKey: resetCount,
        }"
      >
      </router-view>
    </div>
    <v-container v-show="!assessment">
      <sr-loading v-if="loading">
        <template v-slot:content> Finding {{ "assessment" | term }}... </template>
      </sr-loading>
      <sr-not-found
        color="error"
        icon="fad fa-wifi-slash"
        v-if="!loading && error && error.toLowerCase().includes('network')"
      >
        <template v-slot:title> Network Error </template>
        <template v-slot:description>This resource is temporarily unavailable.</template>
        <template v-slot:body>
          Please try again later. If the problem persists, contact support.
        </template>
      </sr-not-found>
      <sr-not-found
        unauthorized
        v-if="!loading && error && !error.toLowerCase().includes('network')"
      ></sr-not-found>
    </v-container>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="snackbarColor"
      :timeout="app_info.snackbarTimeout"
    >
      {{ snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import srAssessmentTabs from "../newComponents/AssessmentComponents/TabList";

export default {
  name: "AssessmentWrapper",
  components: {
    srAssessmentTabs,
  },
  data() {
    return {
      assessment: null,
      loading: false,
      error: null,
      snackbar: null,
      showSnackbar: false,
      showBreadCrumbs: false,
      snackbarColor: null,
      resetCount: 0,
      //  people: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    showError(snackbar) {
      this.snackbar = snackbar;
      this.snackbarColor = "error";
      this.showSnackbar = true;
    },
    init(snackbar) {
      debugger;
      console.log("getting Assessment");
      console.log("snackbar: " + snackbar);
      this.loading = true;

      this.$get(`assessments/${this.assessmentid}`).then((response) => {
        if (response.error) {
          this.error = response.text;
        } else {
          this.assessment = response.data;

          if (snackbar) {
            this.snackbar = snackbar;
            this.showSnackbar = true;
            this.snackbarColor = null;
          }
        }
        this.loading = false;
        this.resetCount++;
      });

      // this.$store.dispatch("saved/get_assessments");
      this.$store.dispatch("saved/get_people");
      // this.$store.dispatch("saved/update_groups");
      //this.$store.dispatch("saved/get_groups");
    },
  },
  computed: {
    write() {
      return this.assessment ? this.assessment.permissions.write : false;
    },
    miniAssessment() {
      return null;
      //this.$store.getters["saved/get"](["assessments", this.assessmentid]);
    },
    group() {
      return this.assessment
        ? this.$store.getters["saved/get"](["groups", this.assessment.group])
        : null;
    },
    permissions() {
      var isAdmin = this.group ? !this.group.readonly : null;
      return {
        write: this.assessment ? this.assessment.permissions.write : false,
        read: this.assessment ? this.assessment.permissions.read : false,
        selfWrite:
          this.assessment && this.assessment.selfPermissions
            ? this.assessment.selfPermissions.write
            : null,
        selfRead:
          this.assessment && this.assessment.selfPermissions
            ? this.assessment.selfPermissions.write
            : null,
        settings: isAdmin,
      }; // this.groupType ? this.admin ? this.groupType.objectPermissions.admin : this.groupType.objectPermissions.member : null
    },
  },
  props: {
    assessmentid: {
      type: String,
    },
  },
};
</script>
