<template>

   <v-card
    outlined 
    :tile="app_info.tile"
    :loading="!frameworkReady || !rubricReady"
    >
      <v-toolbar flat>
        <v-toolbar-title class="grey--text">
          {{title | pluralTerm}}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :disabled="!frameworkReady"
      ></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
     <v-data-table
     :items="frameworks"
     single-expand
     :expanded.sync="expanded"
     item-key="id"
     show-expand
     :headers="headers"
     :hide-default-header="hideHeaders"
     :search="search"
     >
     <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <v-btn icon text @click.prevent="expand(true)" v-show=" showExpand(item) && !isExpanded"><v-icon>fal fa-angle-down</v-icon></v-btn>
      <v-btn icon text @click.prevent="expand(false)" v-show="showExpand(item) && isExpanded"><v-icon>fal fa-angle-up</v-icon></v-btn>
    </template>


      <template v-slot:expanded-item="{ headers, item }">
          <td  :colspan="headers.length" >
              <v-card flat class="pa-5 my-2" color="transparent"   v-if="getRubrics(item.id).length">
                <v-data-table
                    dense
                    :hide-default-header="true"
                    :hide-default-footer="getRubrics(item.id).length <= 10"
                    disable-pagination
                     tile color="transparent"  :headers="rubricHeaders" :items="getRubrics(item.id)">
                                <template v-slot:item.dateUpdated="{item}">
                                    <div class="d-flex align-center">
                                        {{item.dateUpdated | moment(app_info.shortDate)}}
                                    </div>
                                </template>
                                <template v-slot:item.assessments="{item}">
                                    <v-chip small pill color="primary"
                                        @click="goToAssessmentView(item.frameworkId)"
                                    >
                                        <v-avatar
                                            left
                                        >
                                        <sr-icon icon="assessment" class="fal"></sr-icon>
                                        </v-avatar>
                                       {{'assessment' | pluralTerm}}: <strong class="ml-2">{{getAssessments(item.id).length}}</strong> </v-chip>  
                                </template>
                                <template v-slot:item.nodes="{item}">
                                    <sr-group-chip 
                                        v-for="(g,i) in item.nodes"
                                        :key="g"
                                        :small="true"
                                        :class="{'mr-2': i < item.nodes.length-1}"
                                        :id="g">
                                    </sr-group-chip>
                                </template>
                        </v-data-table>
              </v-card>
                <v-card flat v-else color="transparent" class="text--center">
                  <v-card-subtitle class="overline">No {{'rubric' | pluralTerm}} in use</v-card-subtitle>
                </v-card>
          </td>
         
       
    </template>

     <template v-slot:item.lastUpdated="{item}">
         <div class="d-flex align-center">
            {{item.lastUpdated | moment(app_info.shortDate)}}
         </div>
     </template>
     <template v-slot:item.authors="{item}">
        <sr-person-chip 
            v-for="(g,i) in item.authors"
            newWindow
            :key="g"
            :class="{'mr-2': i < item.authors.length-1}"
            :id="g">
        </sr-person-chip>
    </template>

    <template v-slot:item.actions="{ item }">
    <v-btn icon @click="goToFramework(item, false)">
        <v-icon>fal fa-angle-right</v-icon>
    </v-btn>
    </template>
     </v-data-table>
        </v-card>
    
</template>

<script>
export default {
    name : 'Frameworks',

    data(){
        return {
            search: '',
            expanded: []
        }
    },
    created(){
        this.init();
    },
    computed:{
        frameworkReady(){
            return this.$store.getters['saved/status']("frameworks") == "complete";
        },
        rubricReady(){
            return this.$store.getters['saved/status']("rubrics") == "complete";
        },
        frameworks(){
            return this.$store.getters['saved/getAll']('frameworks').sort((a,b)=>{return a.relevance > b.relevance});
            //.map(x=>{
             //   var g = this.$store.getters['saved/get'](['groups', x.groupId]);
          //      var gt = g ? this.$store.getters['saved/get'](['groupTypes', g.groupType]) : null;
           //     return {
               //     ...x,
                  //  groupName: g ? g.displayText : '',
                  //  color: g ? g.colour : 'secondary',
                  //  groupType: gt ? gt.name : '',
          //      }
           // }).filter(x=>{
              //  if(this.groupId){
             //       return x.groupId == this.groupId
              //  }
           //     return x;
          //  })
        }
    },
    methods:{
        init(){
            this.$store.dispatch('saved/get_frameworks');
            this.$store.dispatch('saved/get_rubrics');
        },
        showExpand(item){
            debugger;
            return this.getRubrics(item.id).length
        },
        getRubrics(id){
            return this.$store.getters['saved/rubricsByFramework'](id);
        },
        getAssessments(rubricId){
            return this.$store.getters['saved/search'](['assessments', {key: 'rubricId', value: rubricId }]);
        },
        goToAssessmentView(frameworkId){
            var route = {
                name: "FrameworkAssessments",
                params: {
                    frameworkid: frameworkId
                }
            };
            this.$router.push(route);
        }
        
    },  
    props:{
        title:{
            type: String,
            default: 'Framework'
        },
        hideHeaders:{
            type: Boolean,
            default: false
        },
        hideFilter:{
            type: Boolean,
            default: false
        },
        headers: {
            type: Array,
            default: ()=>{
                return [
                    { text: 'Rubrics', value: 'data-table-expand' },

                    {
                        text: 'Last Updated',
                        value: 'lastUpdated',
                        sortable: true
                    },
                    {
                        text: 'Title',
                        value: 'title',
                    },
                   {
                        text: 'Authors',
                        value: 'authors',
                    },
                    {
                        text: '',
                        value: 'actions'
                    }
                ]
            }
        },
        rubricHeaders: {
            type: Array,
            default: ()=>{
                return [
              
                    {
                        text: 'Title',
                        value: 'title',
                    },
                    {
                        text: 'Groups',
                        value: 'nodes'
                    },
                    {
                        text: 'Assessments',
                        value: 'assessments'
                    },
                    {
                        text: '',
                        value: 'actions'
                    },
                ]
            }
        }
    }
}
</script>