const sameDay = function(a,b){
  var aDate = new Date(a);
  var bDate = new Date(b);
  var yearMatch = aDate.getFullYear() == bDate.getFullYear();
  var monthMatch = aDate.getMonth() == bDate.getMonth(); 
  var dayMatch = aDate.getDate() == bDate.getDate(); 
  return yearMatch && monthMatch && dayMatch;
};
const reportHeaders = 
   {
       default:  [
        {
          text: 'Date Created',
          value: 'created',
          content: (item, t)=>{
            return t.$moment(new Date(item.created)).format('YYYY-MM-DD')
          },
          date: (item,t)=>{
            return t.$moment(new Date(item.created)).format('DD MMMM YYYY')

          },
          
          selected: true,
          sortable: true,
          sort: (a,b)=>{
            debugger;
            var aDate = a.text;
            var bDate = b.text;
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        }
        },
        {
          text: 'Last Updated',
          value: 'lastUpdated',
          content: (item, t)=>{
            return t.$moment(new Date(item.lastUpdated)).format('YYYY-MM-DD')
          },
          date: (item,t)=>{
            return t.$moment(new Date(item.lastUpdated)).format('DD MMMM YYYY')

          },
          selected: true,
          sortable: true,
          sort: (a,b)=>{
            debugger;
            var aDate = a.text;
            var bDate = b.text;
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        }
        },
        {
          text: 'Group', 
          value: 'group',
          selected: false,
          content: (item, t)=>{
            var name = t.$store.getters['saved/groupName'](item.groupId)
            var path = t.$store.getters['saved/groupParents'](item.groupId);
            if(path && path[0]){
              return `${name}: ${path[0].text}`
            }
            else{
              return `${name}`;
            }
          },
          href: (item, t)=>{
            var route = {
              name: 'GroupHome',
              params: {
                groupid: item.groupId
              },
            }
            var path = t.$router.resolve(route).href;
            return window.location.origin + path;
          },
          sortable: true,
          sort: (a,b)=>{
            debugger;
            var aDate = a.text;
            var bDate = b.text;
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        }
      
        },
        {
          text: 'Region',
          value: 'region',
          selected: false,
          sortable: true,
          content: (item, t)=>{
            var parent = t.$store.getters['saved/getParentOfType']({id: item.groupId, type:'5f4e4c54d4298b4e9858e99f' });
           // var parent = path[1] ? t.$store.getters['saved/get'](['groups',  path[1].id]) :  path[0] ? t.$store.getters['saved/get'](['groups',  path[0].id]):null;
            return parent ? parent.internalId : '-';
          },
          sort: (a,b)=>{
            debugger;
            var aDate = a.text;
            var bDate = b.text;
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        },
         
        },
        {
          text: 'Assessment',
          value: 'assessment',
          selected: true,
          content:(item)=>item.assessmentTitle,
          href: (item, t)=>{
            var route = {
              name: 'AssessmentHome',
              params: {
                assessmentid: item.assessmentId
              },
              query: {
                mode: 'report',
                subject: item.personId, 
                unit: item.unitId,
              }
            }
            var path = t.$router.resolve(route).href;
            return window.location.origin + path;
          },
          sortable: true,
          sort: (a,b)=>{
            debugger;
            var aDate = a.text;
            var bDate = b.text;
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        }
        },
        {
            text: 'Objective',
            value: 'objectiveTitle',
            content: (item)=>{
                return `${item.index} ${item.objectiveTitle ? item.objectiveTitle : '' }`
            },
            selected: true,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text;
              var bDate = b.text;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
            text: 'Person', 
            value:  'person', 
            selected: false,
            content: (item, t)=>{
              return t.$store.getters['saved/personName'](item.personId)
            },
            href: (item, t)=>{
              var route = {
                name: 'PersonGroup',
                params: {
                  individualid: item.personId,
                  groupid: item.groupId
                },
              }
              var path = t.$router.resolve(route).href;
              return window.location.origin + path;
            },
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text;
              var bDate = b.text;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
          },
        
        {
            text: 'Unit',
            value: 'unitTitle',
            content: (item)=>{
                return `${item.unitIndex}. ${item.unitTitle}`
            },
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text;
              var bDate = b.text;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
            text: 'Objective Judgement', 
            value: 'judgement', 
            content: (item)=>item.judgement,
            selected: true,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text;
              var bDate = b.text;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
          },
          {
            text: 'Archived', 
            value: 'archived', 
            content: (item)=>item.archived,
            selected: false,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text;
              var bDate = b.text;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
          },
          {
            text: 'Locked', 
            value: 'locked', 
            content: (item)=>item.locked,
            selected: false,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text;
              var bDate = b.text;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
          },

        
        {
            text: 'Framework', 
            value:  'framework', 
            content: (item, t)=>{
              return t.$store.getters['saved/frameworkName'](item.frameworkId)
            },
            href: (item, t)=>{
              var route = {
                name: 'FrameworkHome',
                params: {
                  frameworkid: item.frameworkId
                },
              }
              var path = t.$router.resolve(route).href;
              return window.location.origin + path;
            },
            selected: true,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text;
              var bDate = b.text;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
          },
        
        {
            text: 'Tags', 
            value: 'tags',
            content: (item)=>{item.tags.join(' | ')},
            tags: (item)=>item.tags,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.tags.length;
              var bDate = b.tags.length;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
           
        },
        {
            text: 'Assessors', 
            value: 'assessors',
            content: (item, t)=>{
                return item.assessors.map(a=>{
                   return t.$store.getters['saved/personName'](a)
                }).join(' | ')
            },
            tags: (item, t)=>{
                return item.assessors.map(a=>{
                   return t.$store.getters['saved/personName'](a)
                })
            },
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.tags.length;
              var bDate = b.tags.length;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
            
        },
        {
            text: 'Objective Score', 
            value: 'value',
            content: (item)=>item.value.toString(),
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = parseInt(a.text);
              var bDate = parseInt(b.text);
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
            text: 'Objective Max Points', 
            value: 'maxValue',
            content: (item)=>item.maxValue.toString(),
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = parseInt(a.text);
              var bDate = parseInt(b.text);
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
          text: 'Assessment Score', 
          value: 'overallValue',
          content: (item)=>item.overallValue.toString(),
          sortable: true,
          sort: (a,b)=>{
            debugger;
            var aDate = parseInt(a.text);
            var bDate = parseInt(b.text);
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        }
      },
      
      {
          text: 'Assessment Max Points', 
          value: 'overallMaxValue',
          content: (item)=>item.overallMaxValue.toString(),
          sortable: true,
          sort: (a,b)=>{
            debugger;
            var aDate = parseInt(a.text);
            var bDate = parseInt(b.text);
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        }
      },
      {
        text: 'Assessment Judgement', 
        value: 'overallJudgement', 
        content: (item)=>item.overallJudgement,
        sortable: true,
        sort: (a,b)=>{
          debugger;
          var aDate = a.text;
          var bDate = b.text;
          return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
      }
      },
      {
        text: 'Unit Score', 
        value: 'unitValue',
        content: (item)=>item.unitValue.toString(),
        sortable: true,
        sort: (a,b)=>{
          debugger;
          var aDate = parseInt(a.text);
          var bDate = parseInt(b.text);
          return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
      }
    },
    {
        text: 'Unit Max Points', 
        value: 'unitMaxValue',
        content: (item)=>item.unitMaxValue.toString(),
        sortable: true,
        sort: (a,b)=>{
          debugger;
          var aDate = parseInt(a.text);
          var bDate = parseInt(b.text);
          return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
      }
    },
    {
      text: 'Unit Judgement', 
      value: 'unitJudgement', 
      content: (item)=>item.unitJudgement,
      selected: true,
      sortable: true,
      sort: (a,b)=>{
        debugger;
        var aDate = a.text;
        var bDate = b.text;
        return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
    }
    },

        {
            text: 'Percentage', 
            value: 'percentage', 
            content: (item)=>{
                var divisor = item.maxValue > 0 ? item.maxValue : 1;
                var pc = item.value / divisor;

                return `${Math.round(pc * 100).toString()}`;
            },
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = parseInt(a.text);
              var bDate = parseInt(b.text);
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
          },
        {
            text: 'Level Text', 
            value: 'levelText',
            content: (item)=>item.levelText,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.items.length;
              var bDate = b.items.length;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
            text: 'Target Text', 
            value: 'targetText',
            content: (item)=>item.targetText,
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.text.length;
              var bDate = b.text.length;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
            text: 'Evidence', 
            value: 'objectiveComments',
            selected: false,
            content: (item, t)=>{
                return item.objectiveComments.map(a=>{
                    var createdBy = t.$store.getters['saved/personName'](a.metadata.createdBy);
                    var dateString = sameDay(a.metadata.created, a.metadata.lastUpdated) ?   t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY') : a.metadata.lastUpdated ? `Created: ${t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY')}, Updated: ${t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY')}`: t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY');

                     return `${a.tags.join(' ')}: ${a.text} (${createdBy} ${dateString})`
                  }).join(' | ')
            },
            items: (item, t)=>{
                return item.objectiveComments.map(a=>{
                    var createdBy = t.$store.getters['saved/personName'](a.metadata.createdBy);
                    var dateString = sameDay(a.metadata.created, a.metadata.lastUpdated) ?   t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY') : a.metadata.lastUpdated ? `Created: ${t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY')}, Updated: ${t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY')}`: t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY');

                     return `<strong>${a.tags.join(',')}</strong> ${a.text} (${createdBy},  ${dateString})`
                  });
            },
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.items.length;
              var bDate = b.items.length;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
            text: 'Unit Comments', 
            value: 'unitComments',
            content: (item, t)=>{
                return item.unitComments.map(a=>{
                    var createdBy = t.$store.getters['saved/personName'](a.metadata.createdBy);
                    var dateString = sameDay(a.metadata.created, a.metadata.lastUpdated) ?   t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY') : a.metadata.lastUpdated ? `Created: ${t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY')}, Updated: ${t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY')}`: t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY');
                     return `${a.tags.join(' ')} ${a.text} (${createdBy} ${dateString })`
                  }).join(' | ')
            },
            items: (item, t)=>{
                return item.unitComments.map(a=>{
                    var createdBy = t.$store.getters['saved/personName'](a.metadata.createdBy)
                    var dateString = sameDay(a.metadata.created, a.metadata.lastUpdated) ?   t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY') : a.metadata.lastUpdated ? `Created: ${t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY')}, Updated: ${t.$moment(new Date(a.metadata.lastUpdated)).format('DD MMMM YYYY')}`: t.$moment(new Date(a.metadata.created)).format('DD MMMM YYYY');

                    return `<strong>${a.tags.join(',')}</strong> ${a.text} (${createdBy}, ${dateString})`
                });
            },
            sortable: true,
            sort: (a,b)=>{
              debugger;
              var aDate = a.items.length;
              var bDate = b.items.length;
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
          }
        },
        {
          text: 'School Internal Id', 
          value: 'internalId',
          content: (item, c)=>{
            var group =  c.$store.getters['saved/get'](['groups', item.groupId]);
            var path = group ? group.path : null; 
            if(path && path.length && path[0].groups){
              var parent = c.$store.getters['saved/get'](['groups', path[0].groups[0]]);
              return parent && parent.internalId ? parent.internalId : '';
            }
            
            return group && group.internalId ? group.internalId : '';
          },
          sortable: true,
          selected: true,
          sort: (a,b)=>{
            debugger;
            var aDate = parseInt(a.text);
            var bDate = parseInt(b.text);
            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0
        }
      },
        
        {
            text: 'ID_Assessment', 
            value: 'assessmentId',
            content: (item)=>item.assessmentId,
            hidden: true
        },
        {
            text: 'ID_Unit', 
            value: 'unitId',
            content: (item)=>item.unitId,
            hidden: true
        },
        {
            text: 'ID_Framework', 
            value: 'frameworkId',
            content: (item)=>item.frameworkId,
        },
        {
            text: 'ID_Objective', 
            value: 'objectiveId',
            content: (item)=>item.objectiveId,
            hidden: true
        },
        {
            text: 'ID_Group', 
            value: 'groupId',
            content: (item)=>item.groupId,
        },
        {
            text: 'ID_Person', 
            value: 'personId',
            content: (item)=>item.personId,
            hidden: true
        },
        
       
       
        
        ]
    }



export default reportHeaders; 