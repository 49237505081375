var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","tile":_vm.app_info.tile,"loading":!_vm.peopleReady}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm._f("pluralTerm")(_vm.title))+" ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","disabled":!_vm.peopleReady,"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.people,"item-key":"id","headers":_vm.updatedHeaders,"hide-default-header":_vm.hideHeaders,"search":_vm.search},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30","color":"primary"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"white--text overline"},[_vm._v(_vm._s(_vm.getInitials(item.displayText)))])],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_vm._v(_vm._s(item.email))]):_vm._e()]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('i',{staticClass:"primary--text fad fa-user-check"}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.opaque)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.routeLink(item)}}},[_c('v-icon',[_vm._v("fal fa-angle-right")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }