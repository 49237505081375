import methods from '../scripts/helpers.js';
const root = process.env.VUE_APP_API_URL;
import axios from "axios";
import config from '../../configs/template.js';

import TreeHandler from '../../assets/scripts/treeHandler';
//import cryptoJs from 'crypto-js';
const R = require('ramda');
const query = `?orgId=${config.orgId}`;

const stateProperties = [
    {
        key: 'people', 
        type: 'arr',
        idField: 'id',
        route: 'saved/people',
        updateRoute:'read/update/people',
        method: "post",
        includeOnInit: true,
        includeOnPartialInit: true,
       /* pagination: {
            PageNumber: 1,
            PageSize: 5000
        }*/
    },
    {
        key: 'groups', 
        type: 'arr',
        idField: 'id',
        route: 'saved/groups',
        updateRoute:'read/update/groups',
        method: "post",
        includeOnInit: true,
        includeOnPartialInit: true,
      /*  pagination: {
            PageNumber: 1,
            PageSize: 5000
        }
        */

    },
    {
        key: 'groupTypes', 
        type: 'arr',
        idField: 'id',
        route: 'entities/grouptypes',
        method: "get",
        includeOnInit: true

    },
    {
        key: 'roles', 
        type: 'arr',
        idField: 'id',
        route: 'permissions/roles',
        method: "get",
        includeOnInit: true

    },
    {
        key: 'invitations', 
        type: 'arr',
        idField: 'id',
        route: 'permissions/invitations',
        method: "get",
        includeOnInit: true

    },
   {
        key: 'rubrics', 
        type: 'arr',
        idField: 'id',
        route: 'saved/rubrics',
        method: "post",
        includeOnInit: true,

        pagination: {
            PageNumber: 1,
            PageSize: 500
        }

    },
    {
        key: 'frameworks', 
        type: 'arr',
        idField: 'id',
        route: 'saved/frameworks',
        method: "post",
        includeOnInit: true,
        updateRoute:'read/update/frameworks',

       /* pagination: {
            PageNumber: 1,
            PageSize: 500
        }
            */

   },
   /* {
        key: 'assessments', 
        type: 'arr',
        idField: 'id',
        route: 'saved/assessments',
        method: "post",
        includeOnInit: true

      /*  pagination: {
            PageNumber: 1,
            PageSize: 500
        }
            */

   // },
    
  /*  {
        key: 'timeline', 
        type: 'arr',
        idField: 'id',
        route: 'rubrics'

    },
 */
 
    {
        key: 'self', 
        type: 'obj',
        idField: 'id',
        route: 'entities/individuals/self',
        method: "get",
        includeOnInit: true

    }
];
const tree = TreeHandler
const getChildGroupIds = function(id, source){
    //debugger;
        const groups = R.clone(source);
        const group = groups.find(x=>x.id == id);
        if(group){
            const hydrated = tree.hydrateTree(group, groups);
            var result = tree.flattenTree(hydrated);
            //debugger;
            return result.map(x=>x.id);
        }
        
    
};

const getNode = function(id, source){
    const groups = R.clone(source);
    const group = groups.find(x=>x.id == id);
    if(group){
        const hydrated = tree.hydrateTree(group, groups);
        return hydrated;
    }

}
/*
const getChildGroupArrIds = function(ids, source){
    //debugger;
    var result = [];
    ids.forEach(i=>{
        const gs = source;
        const group = gs.find(x=>x.id == i);
        const t = tree.hydrateTree(group, gs);
        const flat = tree.flattenTree(t);
        result = result.concat(flat);
        
    });
        return result.map(x=>x.id);
    
};
*/

const state = {
    darkMode: false,
    history: [],
    connectionStatus: 'offline',
    sessionData: null
};

const mutations = {
    addHistory(state, data){
        state.history.push(data);
    },
    clearHistory(state){
        state.history = [];
    },
    toggleMode(state){
        state.darkMode = !state.darkMode;
    },
    updateConnectionStatus(state, data){
        state.connectionStatus = data;
    },
    updateSessionData(state, data){
        state.sessionData = data;
    },
    rubricUpdate(state, data){
        const item = state[data.key];
        var values = data.value; 
        console.log(values);
        values.forEach(r=>{
            debugger; 

            var rubric = item.data.find(i=>i.id == r.id);
            rubric.nodes = r.nodes;
        });
    }
    
}
const actions = {
    toggleMode({commit}){
        commit('toggleMode');
    },
    addHistory({commit}, data){
        commit('addHistory', data)
    },
    clearHistory({commit}){
        commit('clearHistory')
    },
    async getSessionData({commit}){
        var url = `${root}saved/sessiondata${query}`;
        return this.$app.$auth.getTokenSilently().then((t) => {
            const config = {
              headers: {
                authorization: "Bearer " + t,
              },
            };
            //debugger
            return axios.get(url, config).then((response) => {
                //debugger;
                if(!response.error){
                    commit('updateSessionData', response)
                }
                return response.error != null ? false : true;
                 });
            });
    },
    async resetSessionData({commit}){
        commit('updateSessionData', null);
        var url = `${root}sessiondata/delete${query}`;
        return this.$app.$auth.getTokenSilently().then((t) => {
            const config = {
              headers: {
                authorization: "Bearer " + t,
              },
            };
            return axios.get(url, config).then((response) => {
                if(!response.error){
                    commit('updateSessionData', response)
                };
                return response.error != null ? false : true;
            });
            });
    },
    async testConnection({commit}){
        var options = {
            url: `${root}public`,
            method: 'get'
        }
       return axios.request(options).catch(function(error) {
           //debugger;
            if (error.status) {
                commit('updateConnectionStatus', 'offline' );
            }
          }).then((response)=>{
            commit('updateConnectionStatus', response ? 'connected' : 'offline');
            return true;
          });
    },
    async stealthRubricUpdate(context){
       
        var url = `${root}update/rubrics/stealth`;
        return this.$app.$auth.getTokenSilently().then((t) => {
            const config = {
              headers: {
                authorization: "Bearer " + t,
              },
            };
           
            var rubricList = context.getters['getAll']('rubrics');
        
            var dataList = rubricList.map(r=>{
             return {
                 id: r.id,
                 secret: r.secret
             }
            });
            console.log(dataList);
     
                axios.post(url, dataList, config).then((response) => {
                    console.log(response)
                    var rubrics = response.data;
                    var keys= Object.keys(rubrics);
                    var rs = keys.map(k=>{
                        var id = k;
                        return {
                            id: id,
                            nodes: rubrics[id]
                        }
                    });
                    var data = {
                        key: 'rubrics',
                        value: rs
                    };
                    context.commit('rubricUpdate', data);
                    console.log(response)
                    //context.commit("set", {key: p.key, value: response.data.items, pagination: pagination ? response.data.pageParams : null});
                    return response;
                  });
            });

      
    },

}   
const getters  = {
    dark(state){
        return state.darkMode;
    },
    connected(state){
        return state.connectionStatus == 'connected'
    },
    self(state){
        return state.self != null & state.self.data != null ? state.self.data.data : null;
    },
    invitation(state){
        return (username)=>{
            return state.invitations != null && state.invitations.data != null ? state.invitations.data.filter(x=>x.invitee.email == username): null;
        }
    },
    status(state){
        return (key)=>{
            return state[key].status
        }
    },
      updated(state){
        return (key)=>{
            return state[key].updated
        }
    },
    bookmarks(state){
        return state.self != null & state.self.data != null ? state.self.data.data.bookmarks : [];

    },
    initialised(state){
        var ids = R.clone(stateProperties.filter(x=>x.includeOnInit && x.key != 'roles')).map(x=>x.key);
        var initialised = true; 
        var i = 0; 
        do{
            initialised = state[ids[i]].status == 'complete';
            i++
        }while(initialised == true && i <ids.length);
       
       return initialised; 
    },
    hasSessionData(state){
        return state.sessionData != null;
    },
    rubricsByGroup(state){
        return (groupId)=>{
            debugger;

            return state.rubrics.data.filter(x=>{
                
               // var ids = getChildGroupArrIds(x.nodes, state.groups.data);
                //debugger;
                return x.nodes.includes(groupId);
            });
        }
    },
    rubricsByGroups(state){
        return (groupIds)=>{
            debugger;

            return state.rubrics.data.filter(x=>{
                
                //var ids = getChildGroupArrIds(x.nodes, state.groups.data);
                //debugger;
                return x.nodes.filter(x=>groupIds.includes(x));
            });
        }
    },
    rubricsByFramework(state){
        return (frameworkId)=>{
            //debugger;
         //   var rubrics = state.rubrics.data; 

            return state.rubrics.data.filter(x=>x.frameworkId == frameworkId);
        }
    },
    personName(state){
        return(id)=>{
            var p = state.people.data.find(x=>x[state.people.idField] == id);
            return p ? p.displayText : null
        }
    },
    groupName(state){
        return(id)=>{
            var p = state.groups.data.find(x=>x[state.groups.idField] == id);
            return p ? p.displayText : null
        }
    },
    frameworkName(state){
        return(id)=>{
            var p = state.frameworks.data.find(x=>x[state.frameworks.idField] == id);
            return p ? p.title : null
        }
    },
    childIds(state){
        return (id)=>{
            return getChildGroupIds(id, state.groups.data);
        }
    },
    node(state){
        return (id)=>{
            return getNode(id, state.groups.data);
        }
    },
    childAdminPermissions(state){
        return (data)=>{
            //get parent
            debugger;
            var parent = data && data.childId ? state.groups.data.find(x=>x.subgroups.includes(data.childId)) : null;
            if(parent != null){
                var parentGroupType = state.groupTypes.data.find(t=>t.id == parent.groupType);
                if(parentGroupType != null){
                    var permissions = parentGroupType.objectPermissions; 
                    return data && data.admin ? permissions.admin.childAdmins : permissions.member.childAdmins; 
                }
            }
            return {
                read: false,
                write: false
            }
        }
    },
    groupParents(state){
        return (ids) => {
            var result = [];
            result = state.groups.data.filter(x=>ids.includes(x.id)).map(g=>{
                var path = g.path;
                return path.map(p=>p.groups).flat();
            });
            return [... new Set(R.clone(result).flat())].map(x=>{
                var g = state.groups.data.find(g=>g.id == x);
                var gt = g ? state.groupTypes.data.find(t=>t.id == g.groupType) : null;
                return {
                    id: x,
                    text:  g ? g.displayText +(gt ? ` (${gt.name})` : '') : null,
                    type: gt ? gt.name : null,
                    colour: g ? g.colour : null
                }
            });
        }
    },
    getParentOfType(state){
        return (data) => {
            debugger;
            var result = [];
            result = state.groups.data.filter(x=>data.id.includes(x.id)).map(g=>{
                var path = g.path;
                return path.map(p=>p.groups).flat();
            });
            return [... new Set(R.clone(result).flat())].map(x=>{
                var g = state.groups.data.find(g=>g.id == x);
             
                return {
                    id: x,
                    text:  g ? g.displayText : null,
                    type: g ? g.groupType : null,
                    internalId: g ? g.internalId : null,
                    colour: g ? g.colour : null
                }
            }).find(x=>x.type == data.type);
        }
    },
    groupsByPerson(state){
        return (id)=>{
            var result = state.groups.data.filter(x=>x.members.includes(id)|| x.admins.includes(id));
            return result; 
        }
    },
    memberships(state){
        return (id)=>{
            var groupMemberships = state.groups.data.filter(x=>x.members.includes(id));
            var groupAdminships = state.groups.data.filter(x=>x.admins.includes(id));
            return {
                memberships: groupMemberships.map(x=>x.id),
                adminships: groupAdminships.map(x=>x.id)
            }
        }
    },
    gtParents(state){
        return (id) => {
            //debugger;
            var result = [];
            result = state.groupTypes.data.filter(x=>x.subgroups.includes(id));
           return result;
        }
    }
}
const Module = methods.baseModule(stateProperties,state,mutations, actions, getters);




export default Module;