<template>
<v-card :loading="loading" flat v-if=" (availableIds.length || unavailableIds.length)">
    <v-card-title v-if="$vuetify.breakpoint.mdAndUp">Create {{'Subgroup' | pluralTerm}}</v-card-title>
    <v-card-text v-if="groupTypeOptions.length">
        <sr-available-ids 
            entity="Group"
            label="Subgroup"
            :availableIds="availableIds" 
            :unavailableIds="unavailableIds"></sr-available-ids>
    </v-card-text>
<v-card-text >

  
  <v-form v-model="valid" v-if="availableIds.length && groupTypeOptions.length">
        <v-row>
            
                        <v-col>
                            <v-select 
                                :items="groupTypeOptions" 
                                v-model="groupType"
                                item-text="name"
                                @update="change"
                                item-value="id"
                                label="Group Type"
                                 :rules="[v => !!v || 'Group Type is required']"
                                >
                               
                            </v-select>
                            <v-text-field outlined dense
                                :label="`Name`"
                                v-model="name"
                                @input="change"
                                :rules="[
                                 v => !!v || 'Name is required',
                                    v => v.length <= 50 || 'Name must be less than 50 characters',
                                ]"
                                >
                            </v-text-field>
                       <v-combobox
                         outlined
                          dense
                            hide-no-data
                            single-line
                            small-chips
                            v-model="tags"
                            chips
                            clearable
                            label="Tags"
                            @input="change"
                            multiple
                            append-icon=""
                        >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                dark
                                @click="select"
                                @click:close="removeTag(item)"
                            >
                                {{item}}
                            </v-chip>
                            </template>
                        </v-combobox>
                 
                        </v-col>
                        <v-col cols="6" md="4" lg="3">
 
                            <v-color-picker
                            dot-size="25"
                            @input="change"
                            hide-inputs
                            v-model="primaryColor"
                            swatches-max-height="200"
                            :swatches="swatches"
                            show-swatches
                            hide-canvas
                            hide-sliders
                            ></v-color-picker>
                        </v-col>
                    </v-row>
                    <v-row>
             
            </v-row>
   
   </v-form>
    <sr-not-found icon="fad fa-exclamation" color="info" v-else>
        <template v-slot:description>No subgroup options for this selection of groups.</template>
    <template v-slot:body>
        Please ensure that you have selected groups that are all of the same type, and that the type has possible child types. 
    </template>
    </sr-not-found>
</v-card-text>
      <v-card-actions  v-if="availableIds.length && groupTypeOptions.length">
           <v-spacer></v-spacer>
           <v-btn text @click="reset" :disabled="changes == 0" color="warning">reset</v-btn>
           <v-btn text @click="update" :disabled="changes == 0 || !valid" color="success">Create Subgroups</v-btn>
       </v-card-actions>
    <v-card
        v-if="result"
       
        >
        {{result}}

    </v-card>
</v-card>
<v-card-text v-else class="text--center d-flex align-content-stretch">
    
    <em>Please select some groups first</em>


</v-card-text>

  
</template>

<script>
import srAvailableIds from './AvailableIds.vue'

export default {
    name: 'BatchSubgroup',
    components:{
        srAvailableIds
    },
        data(){
        return {
            name: '',
            tags: [],
            primaryColor: '#49c1a2',
            groupType: null,
            valid: false,
            changes: 0,
            saving: false,
            showSelected: false,
            availableGroupTypes:[],
            error: null,
            loading: false,
            result: null,
        }
    },
    methods:{
        change(){
            this.changes = this.changes + 1;
        },
        reset(){
            this.changes = 0;
            this.name = '';
            this.tags = null; 
            this.tags = [];
            this.primaryColor = '#49c1a2';
            this.groupType = null; 
        },
        removeTag (item) {
            debugger;
            var i = this.tags.indexOf(item)
            this.tags.splice(i , 1)
            this.tags = [... this.tags]
        },
        update(){
            if(this.valid){
                this.loading = true; 
                var data = {
                    parents : this.availableIds,
                    groupType: this.groupType,
                    tags: this.tags,
                    name: this.name,
                    primaryColor: this.primaryColor,
                }
                this.$post("groups/subgroups/batch", data).then((response)=>{
                debugger;
                if(response.error){
                    this.error = response.text
                }
                else{
                    this.result = response; 
                    this.$store.dispatch('saved/get_groups');
                    this.reset();
                }
                this.loading = false;
            });
            }
        }
    },
    computed:{
        swatches(){
            var arr =  [...new Set(this.app_info.colorArr)];
            return this.chunkArray(arr, 7, []);
        },
        parentGroupType(){
            var groups = this.$store.getters['saved/getArr'](['groups', this.availableIds]);
            var gts = groups ? groups.map(x=>x.groupType) : [];
            var gtArr = gts ? [...new Set(gts)] : null;
            var gt = gtArr && gtArr.length == 1 ? gtArr[0] :null;
            return gt ? this.$store.getters['saved/get'](['groupTypes', gt]) : null
        },
        groupTypeOptions(){
            if(this.parentGroupType){
                return this.parentGroupType.subgroups.map(x=>{
                    return this.$store.getters['saved/get'](['groupTypes', x]) 
                })
            }
            return []
        }
    },
        props: {
          unavailableIds: {
            type: Array,
            default: ()=>[]
        },
          availableIds: {
            type: Array,
            default: ()=>[]
        }
    }
}
</script>