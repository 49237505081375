<template>
<div>
   <baseline-report 
        v-if="!loadingGroup && !loadingPerson && group && person" 
        :group="group"
        :person="person"
        printView
        ></baseline-report>
    <sr-loading class="d-print-none" v-if="loadingGroup || loadingPerson"></sr-loading>
    <sr-not-found v-if="(!loadingGroup && !loadingPerson) && (!group || !person)">
        
    </sr-not-found>
</div>
 
</template>

<script>
import BaselineReport from '../newComponents/PersonComponents/BaselineReport.vue'
export default {
    name: 'PrintBaseline',
    data(){
        return{
            person: null,
            group: null,
            loadingPerson: false,
            loadingGroup:true,
        }
    },
    components:{BaselineReport},
    created(){
         this.loadingGroup = true; 
         this.loadingPerson = true;
               this.$get(`entities/groups/${this.groupId}`).then((response)=>{
                debugger;
                if(response.error){
                    this.error = response.text
                }
                else{
                    this.group = response.data;
                   
                }
                this.loadingGroup = false;
            });
            this.$get(`entities/individuals/${this.personId}`).then((response)=>{
                debugger;
                if(response.error){
                    this.error = response.text
                }
                else{
                    this.person = response.data;
                    
                }
                this.loadingPerson = false;
            });
         
    },
    props:{
        groupId:{
            type: String
        },
        personId:{
            type: String
        }
    }
}
</script>