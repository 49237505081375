<template>
  <v-container>
    <v-row>
      <v-col>
        <sr-open-assessments
          :rubricId="rubric.id"
          :title="title"
          routeName="AssessmentHome"
          :hideHeaders="true"
          :routeProps="{ query: { rubricId: rubric.id } }"
          :headers="headers"
          :query="query"
        >
        </sr-open-assessments>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
const Moment = moment;
const end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
const start = Moment(new Date()).add(-18, "months").format("YYYY-MM-DD");

export default {
  name: "RubricAssessments",
  data() {
    return {
      query: {
        groups: [],
        rubrics: [],

        groupTypes: [],
        parents: [],
        showOnlyDirect: false,
        showOnlyNonDirect: false,
        showOnlyAdmin: false,
        showOnlyNonAdmin: false,

        tags: [],
        individuals: [],
        assessments: [],
        matchAny: false,
        tagMatchAny: true,
        start: start,
        end: end,
        getNode: false,
        frameworks: [],
        onlyArchived: false,
        onlyLocked: false,
        onlyUnlocked: false,
        onlyUnarchived: true,
      },
    };
  },
  computed: {
    title() {
      return this.rubric
        ? `${this.rubric.title} ${this.$getTerm("Assessment")}`
        : "Assessment";
    },
    headers() {
      return [
        {
          text: "Date",
          value: "created",
          sortable: false,
        },
        {
          text: "Title",
          value: "assessmentTitle",
          sortable: false,
        },
        /* {
                        text: 'Rubric',
                        value: 'rubricTitle'
                    },
                    */
        {
          text: "Group",
          value: "groupId",
        },
        {
          text: "Status",
          value: "complete",
        },
        {
          text: "",
          value: "actions",
        },
      ];
    },
  },
  props: {
    rubric: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
