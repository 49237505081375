var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(!_vm.hideToolbar)?_c('v-toolbar',{attrs:{"flat":"","extension-height":"350"},scopedSlots:_vm._u([(_vm.showExtension && _vm.allowFilter)?{key:"extension",fn:function(){return [_c('v-row',{key:_vm.changes},[_c('v-col',[_c('v-card',{staticClass:"mb-3",attrs:{"fluid":"","flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('span',[_vm._v("Show groups inside:")])]),(!_vm.groupId)?_c('v-col',[_c('v-select',{attrs:{"disabled":!_vm.groupReady,"label":"All groups...","multiple":"","items":_vm.groupItems,"clearable":"","deletable-chips":"","dense":"","item-value":"value","hint":!_vm.groupReady ? 'Loading options...' : '',"persistent-hint":!_vm.groupReady},on:{"input":_vm.updateGroups,"change":function($event){_vm.includeChildGroups = false}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Search Groups","clearable":"","single-line":"","hide-details":"","disabled":!_vm.groupReady},model:{value:(_vm.groupSearch),callback:function ($$v) {_vm.groupSearch=$$v},expression:"groupSearch"}})],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('sr-group-chip',{attrs:{"selectOption":"","id":item.value,"small":""}})],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('sr-group-chip',{attrs:{"selectOption":"","id":item.value,"small":""}})]}}],null,false,3593878949),model:{value:(_vm.localQuery.groups),callback:function ($$v) {_vm.$set(_vm.localQuery, "groups", $$v)},expression:"localQuery.groups"}})],1):_vm._e()],1),(!_vm.groupType)?_c('v-row',[_c('v-col',[(_vm.groupTypeItems)?_c('v-select',{attrs:{"disabled":!_vm.groupReady,"label":"All group types...","multiple":"","items":_vm.groupTypeItems,"clearable":"","deletable-chips":"","dense":"","item-value":"value","hint":!_vm.groupTypeReady ? 'Loading options...' : '',"persistent-hint":!_vm.groupTypeReady},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","label":"Search Group Types","clearable":"","single-line":"","hide-details":"","disabled":!_vm.groupTypeReady},model:{value:(_vm.groupTypeSearch),callback:function ($$v) {_vm.groupTypeSearch=$$v},expression:"groupTypeSearch"}})],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var active = ref.active;
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,611450048),model:{value:(_vm.localQuery.groupTypes),callback:function ($$v) {_vm.$set(_vm.localQuery, "groupTypes", $$v)},expression:"localQuery.groupTypes"}}):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"indeterminate":_vm.showOnlyAdmin.indeterminate,"label":_vm.showOnlyAdmin.name,"persistent-hint":"","hint":_vm.showOnlyAdmin.indeterminate
                        ? ("Both admin and non admin " + (_vm.getTerm(
                            'group',
                            true
                          )) + " will be retrieved")
                        : ("Only " + (!_vm.showOnlyAdmin.checked ? 'non' : '') + " admin " + (_vm.getTerm(
                            'group',
                            true
                          )) + " will be retrieved")},on:{"change":function($event){return _vm.updateIndeterminate(_vm.showOnlyAdmin)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.showOnlyAdmin.indeterminate)?_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.showOnlyAdmin.indeterminate = !_vm.showOnlyAdmin.indeterminate}}},[_vm._v("Show All")]):_vm._e()]},proxy:true}],null,false,2273917401),model:{value:(_vm.showOnlyAdmin.checked),callback:function ($$v) {_vm.$set(_vm.showOnlyAdmin, "checked", $$v)},expression:"showOnlyAdmin.checked"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"indeterminate":_vm.showOnlyDirect.indeterminate,"label":_vm.showOnlyDirect.name,"persistent-hint":"","hint":_vm.showOnlyDirect.indeterminate
                        ? ("Both direct and indirect " + (_vm.getTerm(
                            'group',
                            true
                          )) + " will be retrieved")
                        : ("Only " + (!_vm.showOnlyDirect.checked ? 'non' : '') + " direct " + (_vm.getTerm('group', true)) + " will be retrieved")},on:{"change":function($event){return _vm.updateIndeterminate(_vm.showOnlyDirect)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.showOnlyDirect.indeterminate)?_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.showOnlyDirect.indeterminate = !_vm.showOnlyDirect.indeterminate}}},[_vm._v("Show All")]):_vm._e()]},proxy:true}],null,false,1718805019),model:{value:(_vm.showOnlyDirect.checked),callback:function ($$v) {_vm.$set(_vm.showOnlyDirect, "checked", $$v)},expression:"showOnlyDirect.checked"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.resetFilters}},[_vm._v("Reset Filters")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.getDataFromApi(true)}}},[_vm._v("Search")])],1)],1)],1)],1)]},proxy:true}:null],null,true)},[_c('div',{staticClass:"text-subtitle",class:{
        'grey--text': !_vm.app_info.colorfulHeadings,
        'secondary--text': _vm.app_info.colorfulHeadings,
      }},[_vm._v(" "+_vm._s(_vm._f("pluralTerm")(_vm.title))+" ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"hide-details":true,"label":"Search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.search || !_vm.search.length),expression:"!search || !search.length"}]},[_vm._v("mdi-magnify")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.search && _vm.search.length),expression:"search && search.length"}]},[_vm._v("mdi-keyboard-return")])]},proxy:true}],null,false,2562459374),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.allowFilter && !_vm.hideFilter)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showExtension = !_vm.showExtension}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExtension),expression:"!showExtension"}]},[_vm._v("mdi-chevron-down")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExtension),expression:"showExtension"}]},[_vm._v("mdi-chevron-up")])],1):_vm._e()],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"value":_vm.selectedGroups,"sort-by":"name","sort-desc":true,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50, 100] },"show-select":_vm.selectable && _vm.totalItems <= 100},on:{"update:options":function($event){_vm.options=$event},"input":_vm.update},scopedSlots:_vm._u([{key:"item.groupType",fn:function(ref){
      var item = ref.item;
return [_c('sr-group-path',{attrs:{"excludeType":false,"self":{
          id: item.id,
          name: item.displayText,
          groupType: item.groupTypeName,
        },"path":item.path}})]}},{key:"item.displayText",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-badge',{attrs:{"bordered":"","top":"","color":"secondary","offset-x":"10","content":item.directAdmin ? 'A' : '',"value":item.write,"dot":item.write && !item.directAdmin,"offset-y":"10"}},[_c('v-avatar',{attrs:{"color":item.colour,"size":"30"}},[_c('span',{staticClass:"white--text",domProps:{"innerHTML":_vm._s(item.displayText[0])}})])],1),_c('v-card-subtitle',{staticClass:"ml-3",domProps:{"innerHTML":_vm._s(item.displayText)}})],1)]}},{key:"item.tags",fn:function(ref){
        var item = ref.item;
return _vm._l((item.tags),function(t,i){return _c('v-chip',{key:i,staticClass:"mr-2"},[_vm._v(_vm._s(t))])})}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [(!item.opaque)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goToGroup(item, false)}}},[_c('v-icon',[_vm._v("fal fa-angle-right")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }