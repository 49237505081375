<template>
  <v-card outlined :tile="app_info.tile" :loading="!peopleReady">
    <v-toolbar flat>
      <v-toolbar-title class="grey--text">
        {{ title | pluralTerm }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        :disabled="!peopleReady"
        single-line
        hide-details
      ></v-text-field>
    </v-toolbar>
    <v-divider></v-divider>

    <v-data-table
      :items="people"
      item-key="id"
      :headers="updatedHeaders"
      :hide-default-header="hideHeaders"
      :search="search"
    >
      <template v-slot:item.avatar="{ item }">
        <v-avatar size="30" color="primary">
          <v-img v-if="item.avatar" :src="item.avatar"></v-img>
          <span class="white--text overline" v-else>{{
            getInitials(item.displayText)
          }}</span>
        </v-avatar>
      </template>
      <template v-slot:item.email="{ item }">
        <span v-if="item.user">{{ item.email }}</span>
      </template>
      <template v-slot:item.user="{ item }">
        <i class="primary--text fad fa-user-check" v-if="item.user"></i>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn v-if="!item.opaque" icon @click="routeLink(item)">
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "People",

  data() {
    return {
      search: "",
      expanded: [],
      groupMembers: null,
      error: null,
    };
  },
  watch: {
    peopleReady: function () {
      this.$emit("ready");
    },
  },
  created() {
    if (this.dispatch) {
      this.$store.dispatch("saved/get_people");
    }
    // this.init()
  },
  computed: {
    peopleReady() {
      return this.$store.getters["saved/status"]("people") == "complete";
    },
    people() {
      var result = this.$r.clone(this.$store.getters["saved/getAll"]("people"));

      return result
        .filter((x) => {
          var r = this.showReadonly ? true : !x.readonly;
          var o = this.showOpaque ? true : !x.opaque;
          return r && o;
        })
        .sort((a, b) => {
          if (a.readonly && !b.readonly) {
            return 1;
          }
          if (!a.readonly && b.readonly) {
            return -1;
          }
          return 0;
        });
    },
    updatedHeaders() {
      var headers = this.$r.clone(this.headers);
      if (this.$auth.isDataManager) {
        headers.splice(2, 0, { text: "User", value: "user", width: "60px" });
      }
      if (this.$auth.isAdmin || this.$auth.isLocalAdmin || this.$auth.isDataManager) {
        headers.splice(2, 0, { text: "Username", value: "email" });
      }

      return headers;
    },
  },
  methods: {
    routeLink(e) {
      console.log(e);
      debugger;
      this.goToPerson(e, "PersonHome", {}, true);
    },
  },
  props: {
    dispatch: {
      type: Boolean,
      default: true,
    },
    groupId: {
      type: String,
    },
    title: {
      type: String,
      default: "Individual",
    },
    hideHeaders: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    showReadonly: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: "PersonHome",
    },
    routeProps: {
      type: Object,
      default: () => {},
    },
    showOpaque: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => {
        return [
          { text: "", value: "avatar", width: "50px" },
          { text: "Name", value: "displayText", align: "start" },
          { text: "", value: "actions", width: "50px" },

          /*      {
                        text: 'Last Updated',
                        value: 'dateUpdated',
                        sortable: true
                    },
                    {
                        text: 'Title',
                        value: 'displayName',
                    },
                   {
                        text: 'Authors',
                        value: 'connectedIds',
                    },
                    {
                        text: '',
                        value: 'actions'
                    }
                    */
        ];
      },
    },
  },
};
</script>
