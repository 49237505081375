<template>
  <v-tabs
    :grow="grow"
    :vertical="vertical"
    :color="sliderColor"
    :right="right"
    :optional="optional"
  >
    <v-tabs-slider></v-tabs-slider>
    <v-tab
      v-for="(t, i) in tabList"
      :key="i"
      :exact="t.exact"
      :to="{ name: t.name, props: { personid: personid } }"
    >
      <i :class="t.icon" class="fad mr-2"></i>
      <span v-if="t.filter == 'term'">{{ t.text | term }}</span>
      <span v-else>{{ t.text | pluralTerm }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "tabList",
  data() {
    return {
      tabList: [],
    };
  },
  created() {
    var result = this.tabs;

    if (this.$auth.isDataManager && this.personid != this.$auth.userId && this.canWrite) {
      result.push({
        text: "User Management",
        filter: "term",
        exact: false,
        icon: "fa-user-check",
        name: "PersonSettings",
      });
    }

    this.tabList = result.filter((x) => !this.exclude.includes(x.name));
  },
  computed: {
    tabs() {
      var text = "Person";
      if (this.$route.name.includes("Profile")) {
        text = "Profile";
      }
      return [
        {
          text: "Home",
          filter: "term",
          exact: true,
          icon: "fa-home",
          name: `${text}Home`,
        },
        {
          text: "Groups",
          filter: "term",
          exact: false,
          icon: "fa-users",
          name: `${text}Groups`,
        },
      ];
    },
  },
  props: {
    exclude: {
      type: Array,
      default: () => [],
    },
    optional: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },
    iconWeight: {
      type: String,
      default: "fad",
    },
    grow: {
      type: Boolean,
      default: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    personid: {
      type: String,
    },
    canWrite: {
      type: Boolean,
      default: false,
    },
    sliderColor: {
      type: String,
      default: "secondary",
    },
  },
};
</script>
