<template>
  <span v-if="group">
    <v-chip
      v-if="!selectOption"
      @click="goToGroup(group, newWindow)"
      :disabled="group.opaque"
      :small="small"
      class="ma-2"
      :color="color"
    >
      <slot name="prepend"></slot>
      <v-avatar left v-if="!excludeIcon">
        <sr-icon :color="group.colour" icon="group" classes="fad"></sr-icon>
      </v-avatar>

      <v-badge
        :value="group.directMember"
        content="A"
        dot
        inline
        right
        :color="!group.directAdmin ? group.colour : 'primary'"
      >
        <strong class="mr-2">{{ group.displayText }}</strong>
        <span v-if="path">
          <sr-group-path :self="group" minify :path="group.path"></sr-group-path>
        </span>
      </v-badge>
      <slot name="append"></slot>
    </v-chip>
    <div :class="{ 'd-flex': !twoLine }" v-else>
      <v-badge
        :value="group.directMember"
        content="A"
        dot
        inline
        right
        :color="
          !group.directAdmin
            ? group.colour != null
              ? group.colour
              : 'secondary'
            : 'primary'
        "
      >
        <strong class="mr-2">{{ group.displayText }}</strong>
      </v-badge>
      <span v-if="path">
        <sr-group-path :self="group" minify :path="group.path"></sr-group-path>
      </span>
    </div>
  </span>
  <span v-else>
    <v-chip v-if="!selectOption" disabled :small="small" class="ma-2" :color="color">
      <v-avatar left>
        <sr-icon icon="group" class="fal"></sr-icon>
      </v-avatar>

      <strong class="mr-2">Group Details Unavailable</strong>
    </v-chip>
    <div :class="{ 'd-flex': !twoLine }" v-else>
      <strong class="mr-2">Group Details Unavailable</strong>
    </div>
  </span>
</template>

<script>
export default {
  name: "GroupChip",
  computed: {
    group() {
      var group = this.id
        ? this.$r.clone(this.$store.getters["saved/get"](["groups", this.id]))
        : null;
      var gt = group
        ? this.$store.getters["saved/get"](["groupTypes", group.groupType])
        : null;
      debugger;
      if (gt) {
        group.groupType = gt.name;
      }
      return group ? group : null;
    },
  },
  props: {
    id: {
      type: String,
    },
    small: {
      type: Boolean,
      default: false,
    },
    newWindow: {
      type: Boolean,
      default: false,
    },
    path: {
      type: Boolean,
      default: true,
    },
    selectOption: {
      type: Boolean,
      default: false,
    },
    twoLine: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
    excludeIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
