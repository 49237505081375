<template>
  <v-card :loading="saving" :key="resetKey">
    <v-toolbar dense flat>
      <v-toolbar-title>Manage {{ "Assessee" | pluralTerm }}</v-toolbar-title>
    </v-toolbar>

    <v-form v-model="valid" v-if="editableSettings" :key="resetCount">
      <v-row>
        <v-col>
          <v-divider></v-divider>
          <v-data-table
            tile
            :items-per-page="-1"
            hide-default-footer
            hide-default-header
            :items="editableSettings.individualAssessments"
            :item-class="status"
            class="row-keys"
            :headers="headers"
          >
            <template v-slot:item.individualId="{ item }">
              <div :class="{ 'hover-parent': !saving }">
                <v-btn
                  class="hover-item"
                  color="error"
                  v-if="!saving"
                  small
                  text
                  icon
                  @click="removeIa(item.individualId, item.inProgress)"
                >
                  <v-icon>fal fa-times</v-icon>
                </v-btn>
                <sr-person-chip :id="item.individualId"></sr-person-chip>
              </div>
            </template>

            <template v-slot:item.status="{ item }">
              <div>
                <v-chip label outlined small v-if="item.complete" color="success"
                  >Completed</v-chip
                >
                <v-chip label outlined small v-else>Incomplete</v-chip>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                :disabled="changes > 0 || saving"
                @click="sendEmail([item.individualId], assessment.id)"
                small
                v-if="item.canInvite && !item.complete && !item.invited"
                text
                color="success"
              >
                <v-icon left>fal fa-envelope</v-icon>{{ "Invite" | term }}
              </v-btn>
              <v-btn
                :disabled="changes > 0 || saving"
                @click="sendEmail([item.individualId], assessment.id)"
                small
                v-if="item.canInvite && !item.complete && item.invited"
                text
                color="warning"
              >
                <v-icon left>fal fa-envelope</v-icon>{{ "Remind" | term }}
              </v-btn>
              <v-btn small text disabled v-if="!item.canInvite">Not a user</v-btn>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>

              <small class="ml-4 mb-2"
                >Hover over the {{ "assessee" | term }}'s name to delete</small
              >

              <div class="d-flex ml-4 align-center">
                <v-select
                  :loading="loading"
                  v-model="selectedMember"
                  :disabled="unassignedMembers.length == 0"
                  :label="
                    unassignedMembers.length == 0
                      ? `No unassigned ${pluralize(memberName.toLowerCase())}`
                      : `Add ${$getTerm('Assessee')}`
                  "
                  :items="unassignedMembers"
                >
                  <template v-slot:selection="{ item }">
                    <sr-person-chip
                      class="mb-2"
                      small
                      :id="item"
                      selectOption
                    ></sr-person-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <sr-person-chip small :id="item" selectOption></sr-person-chip>
                  </template>
                </v-select>
                <v-btn
                  :disabled="!selectedMember || saving"
                  color="primary"
                  text
                  @click="addIa()"
                >
                  <v-icon left>fal fa-plus</v-icon> Add And Save
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
//import moment from 'moment';
//const Moment = moment;
import Message from "../../objects/messages";
const msg = Message.email;
import { clone } from "ramda";

export default {
  name: "AssessmentMembers",
  data() {
    return {
      editableSettings: null,
      changes: 0,
      saving: false,
      valid: false,
      selectedMember: null,
      resetCount: 0,
      members: [],
      loading: false,
    };
  },
  created() {
    this.reset();
  },
  watch: {
    resetKey: function () {
      console.log("resetting");
      this.reset();
      // this.saving = false;
    },
  },
  computed: {
    groupType() {
      return this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupType])
        : null;
    },
    memberName() {
      return this.groupType ? this.groupType.memberName : "member";
    },
    unassignedMembers() {
      debugger;
      var ias = this.editableSettings.individualAssessments.map((x) => x.individualId);
      var members = this.members
        .filter((x) => !x.tags.includes("inactive") && !x.tags.includes("admin"))
        .map((x) => x.id);
      return members.filter((x) => !ias.includes(x));
    },
    group() {
      return this.assessment
        ? this.$store.getters["saved/get"](["groups", this.assessment.group])
        : null;
    },
    groupName() {
      return this.group ? this.group.displayText : null;
    },

    headers() {
      var headers = [
        {
          text: "Hover to remove",
          value: "individualId",
          align: "left",
          fixed: true,
        },
        {
          text: "",
          value: "status",
          align: "right",
          width: "75px",
          fixed: true,
        },
      ];
      if (this.assessment.selfAssessment) {
        headers.push({
          text: "",
          value: "actions",
          align: "right",
          width: "100px",
          fixed: true,
        });
      }
      return headers;
    },
    signature() {
      var self = this.$store.getters["saved/self"];
      var result = {};
      if (self) {
        var n = this.getDisplayName(self);
        if (self.defaultEmailSignature) {
          result = {
            name: n,
            greeting: self.defaultEmailSignature.greeting ?? "Dear ",
            closing: self.defaultEmailSignature.closing ?? "All the best,",
            email: self.defaultEmailSignature.email ?? { address: self.username },
            phone: self.defaultEmailSignature.phone ?? null,
            organisation: this.app_info.name,
          };
        } else {
          result = {
            name: n,
            greeting: "Dear ",
            closing: "All the best,",
            email: { address: self.username },
            organisation: this.app_info.name,
          };
        }
      }
      return result;
    },
  },
  methods: {
    sendEmail(persons) {
      debugger;
      this.saving = true;
      var message = null;
      message = {};

      message = Object.assign(message, msg);
      message.personalizations = null;
      message.personalizations = clone([]);
      message.templateId = this.app_info.emailTemplateId;
      message.from = {
        email: this.app_info.emailContact,
        name: this.app_info.name,
      };
      message.to = {
        email: this.app_info.emailContact,
        name: this.app_info.name,
      };
      message.dynamicTemplateData = this.app_info.saEmail(
        this.assessment.title,
        this.assessment.rubric.title,
        this.signature,
        null,
        null,
        this.$getTerm("assessment"),
        window.location.origin,
        this.app_info.name
      );

      /*{
        subject: `${this.assessment.title}`,
        salutation: "Dear ",
        primaryMessage: `I'd like you to complete the ${
          this.assessment.rubric.title
        } self ${this.$getTerm("assessment")}${
          this.groupName ? " for " + this.groupName : ""
        }.`,
        primaryCTA: {
          // url: `${window.location.origin}/assessments/${assessment}`,
          url: `${window.location.origin}/invitation`,

          text: `Complete ${this.$getTerm("Assessment")}`,
          helpText: `If this is your first time accessing the ${this.app_info.name} platform, you will need to set a new password. Please click the 'Don't remember your password?' link and follow the instructions to set a password.`,
        },
        secondaryMessage: `When you have finished your self-${this.$getTerm(
          "assessment"
        )}, please click the 'Complete' button to be taken to your preliminary results.`,
        signature: this.signature,
      };
      */
      const personalizations = persons.map((p) => {
        var email = this.app_info.saEmail(
          this.assessment.title,
          this.assessment.rubric.title,
          this.signature,
          null,
          p,
          this.$getTerm("assessment"),
          window.location.origin,
          this.app_info.name
        );
        return email;

        /*{
          individualId: p,
          subject: `Please take the ${this.assessment.title} Self ${this.$getTerm(
            "Assessment"
          )}`,

          primaryCTA: {
            url: `${window.location.origin}/invitation`,
            text: `Complete ${this.$getTerm("Assessment")}`,
            helpText: `If this is your first time accessing the ${this.app_info.name} platform, you will need to set a new password. Please click the 'Don't remember your password?' link and follow the instructions to set a password.`,
          },
          salutation: "Dear ",
          primaryMessage: `I'd like you to complete the ${
            this.assessment.rubric.title
          } self ${this.$getTerm("assessment")} for ${this.groupName}.`,

          secondaryMessage: `When you have finished your self-${this.$getTerm(
            "assessment"
          )}, please click the 'Complete' button to be taken to your preliminary results.`,
          signature: this.signature,
        };
        */
      });
      message.personalizations = personalizations;
      debugger;
      message.assessmentId = this.assessment.id;
      this.$post(`entities/message`, message).then((response) => {
        //  this.saving = false;
        if (response.error) {
          this.$emit("error", this.errorText(response.text, "send", "message"));
        } else {
          //    this.$store.dispatch('saved/get_assessments')
          this.changes = 0;
          this.$emit("update", "Changes Saved");
        }
        // this.saving = false;
        this.reset();
      });
    },
    status(item) {
      var rowClass = "show-on-hover  row-tile";

      var color = item.complete ? "primary-row-key" : "warning-row-key";

      return `${rowClass} ${color}`;
    },
    change() {
      this.changes++;
    },
    removeIa(id, started) {
      debugger;
      var proceed = true;
      if (started) {
        var person = this.$store.getters["saved/get"](["people", id]);
        var msg = `Are you sure you want to remove ${person.displayText.trim()} as a ${this.$getTerm(
          "assessee"
        )} for this ${this.$getTerm(
          "assessment"
        )}? Marks have already been entered, and you will lose all of them. This action cannot be undone.`;
        proceed = window.confirm(msg);
      }
      if (proceed) {
        this.saving = true;
        this.$post(`assessments/${this.assessment.id}/remove/${id}`, null).then(
          (response) => {
            if (response.error) {
              this.$emit(
                "error",
                this.errorText(response.text, "remove", this.$getTerm("assessee"))
              );
            } else {
              //   this.$store.dispatch('saved/get_assessments')
              this.changes = 0;
              this.$emit("update", "Changes Saved");
            }
          }
        );
      }

      this.changes++;
    },
    addIa() {
      var id = this.selectedMember;
      if (id) {
        this.saving = true;
        this.$post(`assessments/${this.assessment.id}/add/${id}`, null).then(
          (response) => {
            if (response.error) {
              this.$emit(
                "error",
                this.errorText(response.text, "add", this.$getTerm("Assessee"))
              );
            } else {
              debugger;
              //  this.$store.dispatch('saved/get_assessments')
              this.changes = 0;
              this.$emit("update", "Changes Saved");
            }
            // this.saving = false;
          }
        );
      }
    },
    reset() {
      this.$get(`entities/groups/${this.assessment.group}/members`).then((response) => {
        if (response.error) {
          this.$emit("error", this.errorText(response.text, "get", "Group Members"));
        } else {
          this.members = response.data;
        }
      });
      this.errorMsg = null;
      if (this.editableSettings != null) {
        this.editableSettings = null;
      }

      this.editableSettings = {};
      this.members = null;
      this.members = [];
      // var miniAssessment = this.$store.getters['saved/get'](['assessments', this.assessment.id]);
      //  var ias = this.miniAssessment.gra
      var ias = this.assessment.individualAssessments.map((ia) => {
        //var unitScores = ia.units.map(x=>x.score);
        debugger;
        var individual = this.$store.getters["saved/get"](["people", ia.individualId]);
        // var max = Math.max(unitScores);
        /* var incompleteUnits = ia.units.filter(u=>{
                    var incompleteObjectives = u.objectives.filter(x=>!x.selectedBand).length != 0; 
                    return incompleteObjectives; 
                });

                var startedUnits = ia.units.filter(u=>{
                    var completeObjectives = u.objectives.filter(x=>x.selectedBand).length != 0; 
                    return completeObjectives; 
                });

               // var status = !incompleteUnits.length || (incompleteUnits.length && startedUnits.length)  ? 'In progress' : 'Not started';
*/
        debugger;
        return {
          individualId: ia.individualId,
          id: ia.id,
          complete: ia.complete,
          metadata: ia.metadata,
          invited: ia.invited,
          canInvite: individual ? individual.user : false,
        };
      });
      var settings = {
        id: this.assessment.id,
        individualAssessments: ias,
      };
      Object.assign(this.editableSettings, settings);
      //  this.loading = false;
      this.saving = false;
      this.resetCount++;
    },
    removeTag(item) {
      debugger;
      var i = this.editableSettings.tags.indexOf(item);
      this.editableSettings.tags.splice(i, 1);
      this.editableSettings.tags = [...this.editableSettings.tags];
      this.change();
    },

    update() {
      this.saving = "secondary";
      /* this.$post("assessments/update", this.editableSettings).then((response)=>{
                debugger;
                if(response.error){
                    
                     this.$emit('error', this.errorText(response.text, 'save',this.$getTerm('assessment')))
                }
                else{
                    this.$store.dispatch('saved/get_assessments')
                    this.changes = 0;
                    this.$emit('update', 'Changes Saved')
                }
                this.saving = false;
            });
       */
    },
  },
  props: {
    assessment: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
    resetKey: {
      type: Number,
    },
  },
};
</script>
