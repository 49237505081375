<template>
  <v-list>
    <v-list-item v-if="!availableIds.length">
      <v-avatar size="40" color="error" class="mr-4 white--text">
        <i class="fad fa-exclamation"></i>
      </v-avatar>
      <v-list-item-content>
        <v-list-item-title>
          No valid {{ entity | pluralTerm }} selected
        </v-list-item-title>
        <v-list-item-subtitle v-if="!availableIds.length">
          To proceed, please select {{ entity | pluralTerm }} to which you have write
          access for {{ label | pluralTerm }}.
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-group v-else :value="false">
      <template v-slot:activator>
        <v-avatar size="40" color="success" class="mr-4 white--text">
          <strong>{{ availableIds.length }}</strong>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title> {{ entity | pluralTerm }} Selected </v-list-item-title>
          <v-list-item-subtitle>
            {{ label | pluralTerm }} will be created for these {{ entity | pluralTerm }}.
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <v-list dense outlined>
        <v-list-item two-line dense v-for="g in availableIds" :key="g">
          <sr-group-chip
            v-if="entity == 'Group'"
            twoLine
            selectOption
            :id="g"
          ></sr-group-chip>
          <v-list-item-content v-else>
            <v-list-item-title v-html="getItem(g).title"></v-list-item-title>
            <v-list-item-title v-html="getItem(g).description"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list-group>
    <v-list-group :value="false" v-if="unavailableIds.length">
      <template v-slot:prependIcon> </template>
      <template v-slot:activator>
        <v-avatar size="40" class="error mr-4 white--text">
          <strong>{{ unavailableIds.length }}</strong>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Invalid {{ entity | pluralTerm }} (No write access to
            {{ label | pluralTerm }})
          </v-list-item-title>
          <v-list-item-subtitle class="error--text">
            No {{ label | pluralTerm }} will be created for these
            {{ entity | pluralTerm }}.
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <v-list outlined dense>
        <v-list-item two-line v-for="g in unavailableIds" :key="g.id">
          <v-list-item-action>
            <v-icon color="error">fad fa-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <sr-group-chip
              v-if="entity == 'Group'"
              twoLine
              selectOption
              :id="g.id"
            ></sr-group-chip>
            <v-list-item-content v-else>
              <v-list-item-title v-html="getItem(g.id).title"></v-list-item-title>
              <v-list-item-title v-html="getItem(g.id).description"></v-list-item-title>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: "AvailableIds",
  methods: {
    getItem(e) {
      var result = {
        title: "",
        description: "",
      };
      var entity = this.entity.toLowerCase() + "s";
      var item =
        this.items.length == 0
          ? this.$store.getters["saved/get"]([entity, e])
          : this.items.find((i) => i.id == e);
      if (item) {
        result.title = item.title
          ? item.title
          : item.name
          ? item.name
          : item.assessmentTitle
          ? item.assessmentTitle
          : item.id;
        result.description = item.description;
      }

      return result;
    },
  },
  props: {
    availableIds: {
      type: Array,
      default: () => [],
    },
    unavailableIds: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    entity: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
