<template>
  <v-card
    flat
    tile
    :class="{ light: !transparent, transparent: transparent }"
    class="ma-2"
  >
    <v-card-text class="comment-border-left py-0">
      <p class="mb-2" v-html="comment.text"></p>
      <span v-if="comment.tags">
        <v-chip
          outlined
          dense
          small
          class="ma-1"
          v-for="(t, i) in comment.tags"
          :key="i"
          >{{ t }}</v-chip
        >
      </span>
      <div class="darken-1 d-flex mt-2 justify-space-between">
        <sr-person-chip
          small
          selectOption
          :id="comment.metadata.lastUpdatedBy"
          class="mx-1"
        ></sr-person-chip>
        <span class="overline">
          {{ comment.metadata.lastUpdated | moment("DD MMM YYYY") }}
          <v-btn
            x-small
            @click="showHistory = !showHistory"
            icon
            v-if="!sameDay(comment.metadata.created, comment.metadata.lastUpdated)"
          >
            <v-icon>fal fa-history</v-icon></v-btn
          >
        </span>
      </div>
      <div class="d-flex justify-end" v-if="showHistory">
        <small>
          <span>
            created {{ comment.metadata.created | moment("DD MMM YYYY") }}
            <span v-if="comment.metadata.createdBy != comment.metadata.lastUpdatedBy">
              by
              <sr-person-chip
                small
                selectOption
                :showAvatar="false"
                :id="comment.metadata.createdBy"
                class="mx-1"
              ></sr-person-chip>
            </span>
          </span>
        </small>
      </div>
    </v-card-text>
    <comment
      transparent
      full-width
      :reviewed="reviewedDate"
      v-for="(c, i) in comment.replies"
      :key="i"
      :comment="c"
    >
    </comment>
  </v-card>
</template>

<script>
import Comment from "./Comment.vue";

export default {
  name: "Comment",
  components: { Comment },

  data() {
    return {
      showHistory: false,
    };
  },
  computed: {
    reviewedDate() {
      return this.reviewed ? new Date(this.reviewed) : null;
    },
  },
  props: {
    comment: {
      type: Object,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    reviewed: {
      type: String,
    },
  },
};
</script>
