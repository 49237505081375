<template>
  <div>
    <v-toolbar v-if="miniFramework" dense flat :key="loading" class="d-print-none">
      <v-toolbar-items v-if="framework && showBreadCrumbs">
        <v-btn icon :to="{ name: 'Home' }" :color="color">
          <i class="fal fa-home"></i>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-title>
        <v-btn class="px-1" color="primary" text exact :to="{ name: 'AllFrameworks' }"
          >Frameworks</v-btn
        >
        <span class="text-button ml-2 mt-1">/ {{ miniFramework.title }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && !showBreadCrumbs">
        <sr-framework-tabs
          :optional="true"
          right
          :grow="false"
          :frameworkid="miniFramework.id"
          :canWrite="author"
        ></sr-framework-tabs>
      </v-toolbar-items>

      <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown || showBreadCrumbs">
        <sr-framework-tabs
          :optional="true"
          right
          :grow="false"
          :frameworkid="miniFramework.id"
          :canWrite="author"
        ></sr-framework-tabs>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid v-if="framework">
      <router-view
        @update="init"
        @stealthUpdate="stealthInit"
        @error="showError"
        v-bind="{ framework: framework, permissions: permissions, updateKey: resetCount }"
      >
      </router-view>
    </v-container>
    <v-container v-show="!framework">
      <sr-loading v-if="loading"></sr-loading>
      <sr-not-found v-else></sr-not-found>
    </v-container>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="snackbarColor"
      :timeout="app_info.snackbarTimeout"
    >
      {{ snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import srFrameworkTabs from "../newComponents/FrameworkComponents/TabList";

export default {
  name: "FrameworkWrapper",
  components: {
    srFrameworkTabs,
  },
  data() {
    return {
      framework: null,
      loading: false,
      error: null,
      snackbar: null,
      showSnackbar: false,
      showBreadCrumbs: false,
      snackbarColor: null,
      resetCount: 0,
      //  people: []
    };
  },
  mounted() {
    this.$store.dispatch("saved/stealthRubricUpdate");
    this.init();
  },
  methods: {
    showError(snackbar) {
      this.snackbar = snackbar;
      this.snackbarColor = "error";
      this.showSnackbar = true;
    },
    stealthInit(snackbar) {
      this.init(snackbar, true);
    },
    init(snackbar, stealthy) {
      console.log("getting Framework");
      console.log("snackbar: " + snackbar);
      this.loading = true;
      var miniFramework = this.$store.getters["saved/get"]([
        "frameworks",
        this.frameworkid,
      ]);
      if (!miniFramework || !miniFramework.secret) {
        this.$get(`frameworks/${this.frameworkid}`).then((response) => {
          debugger;
          if (response.error) {
            this.error = response.text;
          } else {
            this.framework = response.data;
            if (snackbar) {
              this.snackbar = snackbar;
              this.showSnackbar = true;
              this.snackbarColor = null;
            }
          }
          this.loading = false;
          if (!stealthy) {
            this.resetCount++;
          }
        });
      } else {
        debugger;
        var data = {
          id: this.frameworkid,
          secret: miniFramework.secret,
          personId: this.$auth.userId,
        };
        this.$post(`read/framework`, data).then((response) => {
          if (response.error) {
            this.error = response.text;
          } else {
            this.framework = response;

            if (snackbar) {
              this.snackbar = snackbar;
              this.showSnackbar = true;
              this.snackbarColor = null;
            }
          }
          this.loading = false;
          if (!stealthy) {
            this.resetCount++;
          }
        });
      }
    },
  },
  computed: {
    miniFramework() {
      return this.$store.getters["saved/get"](["frameworks", this.frameworkid]);
    },
    permissions() {
      return {
        write: this.miniFramework.write,
        read: true,
        author: this.author,
      }; // this.groupType ? this.admin ? this.groupType.objectPermissions.admin : this.groupType.objectPermissions.member : null
    },
    author() {
      return this.miniFramework.permissions.write;
    },
  },
  props: {
    frameworkid: {
      type: String,
    },
  },
};
</script>
