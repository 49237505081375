<template>
  <v-card
    v-if="comment"
    class="border-left border-right my-4"
    style="border-width: 5px !important"
    :tile="app_info.tile"
  >
    <v-toolbar height="35" dense color="highlight" flat class="border-bottom">
      <v-toolbar-items>
        <sr-person-chip
          :showAvatar="false"
          selectOption
          definite
          :id="
            comment.metadata.lastUpdatedBy
              ? comment.metadata.lastUpdatedBy
              : comment.metadata.createdBy
              ? comment.metadata.createdBy
              : $auth.userId
          "
        >
          <template v-slot:default>
            <v-divider vertical class="mx-2"></v-divider>

            <span v-if="$vuetify.breakpoint.smAndUp">{{
              comment.metadata.lastUpdated | moment("DD MMM YYYY HH:mm")
            }}</span>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  class="px-2 ml-5 mr-1 d-print-none"
                  v-bind="attrs"
                  v-on="on"
                >
                  History
                </v-btn>
              </template>
              <v-list dense class="pt-0">
                <v-subheader class="highlight">Authors</v-subheader>
                <v-list-item>
                  <v-list-item-content class="pl-2">
                    <sr-person-chip selectOption :id="comment.metadata.createdBy">
                      <template v-slot:avatar>
                        <v-avatar left tile> + </v-avatar>
                      </template>
                      <template v-slot:default>
                        <span>
                          -
                          {{
                            comment.metadata.created | moment("DD MMMM YYYY HH:mm")
                          }}</span
                        >
                      </template>
                    </sr-person-chip>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="
                    comment.metadata.lastUpdatedBy &&
                    comment.metadata.lastUpdatedBy != comment.metadata.createdBy
                  "
                ></v-divider>
                <v-list-item
                  v-if="
                    comment.metadata.lastUpdatedBy &&
                    comment.metadata.lastUpdatedBy != comment.metadata.createdBy
                  "
                >
                  <v-list-item-content class="pl-2">
                    <sr-person-chip selectOption :id="comment.metadata.lastUpdatedBy">
                      <template v-slot:avatar>
                        <v-avatar left tile> E </v-avatar>
                      </template>
                      <template v-slot:default>
                        <span>
                          -
                          {{
                            comment.metadata.lastUpdated | moment("DD MMMM YYYY HH:mm")
                          }}</span
                        >
                      </template>
                    </sr-person-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </sr-person-chip></v-toolbar-items
      >
      <v-spacer></v-spacer>
      <v-toolbar-items class="mr-0 d-print-none">
        <v-btn
          text
          small
          :disabled="disabled"
          @click="locked = false"
          v-show="enableUnlock && locked"
        >
          Unlock
        </v-btn>

        <v-btn
          icon
          :disabled="disabled"
          color="error"
          @click="deleteComment"
          v-show="!locked"
        >
          X
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-container class="py-0" fluid>
      <v-row no-gutter class="">
        <v-col id="comment" class="py-0" :class="{ grow: $vuetify.breakpoint.smAndUp }">
          <v-row>
            <v-col id="text" class="py-0" cols="12" :md="8">
              <v-row>
                <v-col cols="12" id="commentField">
                  <div v-if="!showEditHTML">
                    <div v-if="!locked">
                      <wysiwyg
                        :label="getTerm('Comment')"
                        v-model="comment.text"
                        dense
                        :disabled="disabled"
                        @change="inputText"
                        :options="{
                          link: {
                            url: 'http://',
                          },
                        }"
                      >
                      </wysiwyg>

                      <v-alert
                        outlined
                        text
                        prominent
                        class="mt-2"
                        tile
                        dense
                        v-if="errorMsg"
                        :type="errorSeverity"
                        >{{ errorMsg }}</v-alert
                      >
                    </div>
                    <div v-else>
                      <v-alert dense outlined text v-if="errorMsg" color="info">
                        This {{ "comment" | term }} text contains images. Where possible,
                        please replace them with file attachments or links to externally
                        hosted images.
                      </v-alert>
                      <v-card-text class="py-0" v-html="comment.text"> </v-card-text>
                    </div>
                  </div>
                  <div v-else>
                    <v-textarea
                      outlined
                      :label="getTerm('Comment')"
                      v-bind:value="comment.text"
                      dense
                      :disabled="disabled"
                      v-if="!locked"
                      @change="inputText"
                    >
                    </v-textarea>
                    <v-card-text v-else class="py-0" v-html="comment.text"> </v-card-text>
                  </div>
                  <v-system-bar
                    v-if="!locked"
                    :class="{
                      'system-bar-subtle': !showEditHTML,
                      transparent: showEditHTML,
                    }"
                    class="d-flex justify-end"
                  >
                    <v-btn
                      color="primary"
                      text
                      x-small
                      @click="showEditHTML = !showEditHTML"
                    >
                      <span v-show="showEditHTML"> Return to Rich Text Editor</span>
                      <span v-show="!showEditHTML"> Edit or paste HTML (advanced)</span>
                    </v-btn>
                  </v-system-bar>
                </v-col>
                <v-col cols="12" id="tagAndAttach">
                  <div v-if="!locked">
                    <v-select
                      outlined
                      v-show="!allowCreateTags"
                      dense
                      multiple
                      chips
                      :disabled="disabled"
                      :items="tags"
                      placeholder="Add tags"
                      v-bind:value="comment.tags"
                      @change="inputTag"
                    ></v-select>
                    <v-combobox
                      outlined
                      v-show="allowCreateTags"
                      dense
                      multiple
                      chips
                      :disabled="disabled"
                      :items="tags"
                      label="tags"
                      v-bind:value="comment.tags"
                      @change="inputTag"
                    ></v-combobox>
                  </div>
                  <div v-else class="ml-5">
                    <v-chip
                      small
                      v-for="(t, ti) in comment.tags"
                      class="mx-2"
                      :key="ti"
                      >{{ t }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              id="attachments"
              :md="4"
              class="no-padder"
              :class="{ 'border-left': $vuetify.breakpoint.mdAndUp }"
            >
              <sr-file-input
                @input="addFile"
                @delete="deleteFile"
                stretch
                :disabled="disabled"
                :xs="$vuetify.breakpoint.smAndDown"
                flat
                :readonly="locked"
                :parent="comment"
              ></sr-file-input>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
//const _ = require("lodash");
const expression = /<img([\w\W]+?)[\\/]?>/gi;
const imgRegex = new RegExp(expression);

export default {
  name: "Comment",
  data() {
    return {
      locked: false,
      enableUnlock: false,
      authorsMenu: false,
      errorMsg: null,
      errorSeverity: "",
      showEditHTML: false,
    };
  },
  /*  watch: {
    commentText: function (nv, old) {
      if (nv != old) {
        console.log("debouncing");
        this.debouncedValidateComment();
      }
    },
  },
  */
  created() {
    var author = this.comment.metadata.createdBy;
    var editor = this.comment.metadata.lastUpdatedBy;
    this.locked = ![author, editor].includes(this.$auth.userId);
    this.enableUnlock = true;
    //  this.$auth.isDataManager || this.$auth.isAdmin || this.$auth.isLocalAdmin;
    //  this.debouncedValidateComment = _.debounce(this.validateComment, 800);
    this.validateComment(true);
  },
  computed: {
    commentText() {
      return this.comment.text;
    },
  },
  methods: {
    validateComment(initial) {
      this.errorMsg = null;
      this.errorSeverity = "";
      var text = this.comment.text;
      if (text != null) {
        var imgs = text.match(imgRegex);
        var containsIllegal = imgs
          ? imgs
              .filter((i) => {
                if (i.toLowerCase().includes("base64")) {
                  return 1;
                }
              })
              .filter((i) => i)
          : [];
        if (imgs && containsIllegal.length) {
          console.log("image present");
          //   if (text.length > 30000) {
          //     this.errorMsg = `Unable to save comment due to large images. Please delete images from your comment and use the  Include images using the file attachment field instead in order to proceed.`;
          //     this.errorSeverity = "error";
          //   } else {
          if (initial) {
            this.errorMsg = `This ${this.getTerm(
              "comment"
            )} text contains large image files. Where possible, please use the file attachment field ${
              this.$vuetify.breakpoint.width < 960 ? "below" : ""
            } to add images, or link to an externally hosted image instead.`;
            this.errorSeverity = "info";
          } else {
            this.errorMsg = `Please do not include images in your ${this.getTerm(
              "comment"
            )} text. Include images using the file attachment field ${
              this.$vuetify.breakpoint.width < 960 ? "below" : ""
            } instead.`;
            this.errorSeverity = "error";
            //this.inputText(text);
          }

          // }
        }
      }
    },
    sendingEvent(file) {
      console.log("sending event");
      console.log(file);
    },
    inputTag(e) {
      console.log(e);
      console.log(this.comment);
      var comment = this.comment;
      comment.tags = e;
      this.update(comment);
    },
    deleteComment() {
      console.log("deleteComment");
      this.$emit("delete", this.index);
    },
    inputText(e) {
      // var markdownLinks = e.match(markDownLinkExp);
      console.log(this.comment);
      var comment = this.comment;
      comment.text = e;

      this.update(comment);
      //  this.$emit("input", comment);
    },
    update(c) {
      console.log("emitting update");
      this.$emit("input", { comment: c, index: this.index });
    },
    addFile(e) {
      debugger;
      console.log(e);
      console.log(this.comment);
      var comment = this.comment;
      comment.file.push(e);
      this.update(comment);
    },
    deleteFile(e) {
      debugger;
      console.log(e);
      var comment = this.comment;
      console.log(comment);
      comment.file = comment.file.filter((f) => f.id != e);

      console.log(comment.file);
      this.update(comment);
    },
  },
  props: {
    focus: {
      type: Number,
      default: 0,
    },
    comment: {
      type: Object,
    },
    allowCreateTags: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>
