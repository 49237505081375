var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.comment)?_c('v-card',{staticClass:"border-left border-right my-4",staticStyle:{"border-width":"5px !important"},attrs:{"tile":_vm.app_info.tile}},[_c('v-toolbar',{staticClass:"border-bottom",attrs:{"height":"35","dense":"","color":"highlight","flat":""}},[_c('v-toolbar-items',[_c('sr-person-chip',{attrs:{"showAvatar":false,"selectOption":"","definite":"","id":_vm.comment.metadata.lastUpdatedBy
            ? _vm.comment.metadata.lastUpdatedBy
            : _vm.comment.metadata.createdBy
            ? _vm.comment.metadata.createdBy
            : _vm.$auth.userId},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.comment.metadata.lastUpdated,"DD MMM YYYY HH:mm")))]):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2 ml-5 mr-1 d-print-none",attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" History ")])]}}],null,false,4053238749)},[_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_c('v-subheader',{staticClass:"highlight"},[_vm._v("Authors")]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pl-2"},[_c('sr-person-chip',{attrs:{"selectOption":"","id":_vm.comment.metadata.createdBy},scopedSlots:_vm._u([{key:"avatar",fn:function(){return [_c('v-avatar',{attrs:{"left":"","tile":""}},[_vm._v(" + ")])]},proxy:true},{key:"default",fn:function(){return [_c('span',[_vm._v(" - "+_vm._s(_vm._f("moment")(_vm.comment.metadata.created,"DD MMMM YYYY HH:mm")))])]},proxy:true}],null,false,4186136161)})],1)],1),(
                  _vm.comment.metadata.lastUpdatedBy &&
                  _vm.comment.metadata.lastUpdatedBy != _vm.comment.metadata.createdBy
                )?_c('v-divider'):_vm._e(),(
                  _vm.comment.metadata.lastUpdatedBy &&
                  _vm.comment.metadata.lastUpdatedBy != _vm.comment.metadata.createdBy
                )?_c('v-list-item',[_c('v-list-item-content',{staticClass:"pl-2"},[_c('sr-person-chip',{attrs:{"selectOption":"","id":_vm.comment.metadata.lastUpdatedBy},scopedSlots:_vm._u([{key:"avatar",fn:function(){return [_c('v-avatar',{attrs:{"left":"","tile":""}},[_vm._v(" E ")])]},proxy:true},{key:"default",fn:function(){return [_c('span',[_vm._v(" - "+_vm._s(_vm._f("moment")(_vm.comment.metadata.lastUpdated,"DD MMMM YYYY HH:mm")))])]},proxy:true}],null,false,1389750512)})],1)],1):_vm._e()],1)],1)]},proxy:true}],null,false,2709845668)})],1),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"mr-0 d-print-none"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableUnlock && _vm.locked),expression:"enableUnlock && locked"}],attrs:{"text":"","small":"","disabled":_vm.disabled},on:{"click":function($event){_vm.locked = false}}},[_vm._v(" Unlock ")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.locked),expression:"!locked"}],attrs:{"icon":"","disabled":_vm.disabled,"color":"error"},on:{"click":_vm.deleteComment}},[_vm._v(" X ")])],1)],1),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutter":""}},[_c('v-col',{staticClass:"py-0",class:{ grow: _vm.$vuetify.breakpoint.smAndUp },attrs:{"id":"comment"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"id":"text","cols":"12","md":8}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","id":"commentField"}},[(!_vm.showEditHTML)?_c('div',[(!_vm.locked)?_c('div',[_c('wysiwyg',{attrs:{"label":_vm.getTerm('Comment'),"dense":"","disabled":_vm.disabled,"options":{
                        link: {
                          url: 'http://',
                        },
                      }},on:{"change":_vm.inputText},model:{value:(_vm.comment.text),callback:function ($$v) {_vm.$set(_vm.comment, "text", $$v)},expression:"comment.text"}}),(_vm.errorMsg)?_c('v-alert',{staticClass:"mt-2",attrs:{"outlined":"","text":"","prominent":"","tile":"","dense":"","type":_vm.errorSeverity}},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e()],1):_c('div',[(_vm.errorMsg)?_c('v-alert',{attrs:{"dense":"","outlined":"","text":"","color":"info"}},[_vm._v(" This "+_vm._s(_vm._f("term")("comment"))+" text contains images. Where possible, please replace them with file attachments or links to externally hosted images. ")]):_vm._e(),_c('v-card-text',{staticClass:"py-0",domProps:{"innerHTML":_vm._s(_vm.comment.text)}})],1)]):_c('div',[(!_vm.locked)?_c('v-textarea',{attrs:{"outlined":"","label":_vm.getTerm('Comment'),"value":_vm.comment.text,"dense":"","disabled":_vm.disabled},on:{"change":_vm.inputText}}):_c('v-card-text',{staticClass:"py-0",domProps:{"innerHTML":_vm._s(_vm.comment.text)}})],1),(!_vm.locked)?_c('v-system-bar',{staticClass:"d-flex justify-end",class:{
                    'system-bar-subtle': !_vm.showEditHTML,
                    transparent: _vm.showEditHTML,
                  }},[_c('v-btn',{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){_vm.showEditHTML = !_vm.showEditHTML}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEditHTML),expression:"showEditHTML"}]},[_vm._v(" Return to Rich Text Editor")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showEditHTML),expression:"!showEditHTML"}]},[_vm._v(" Edit or paste HTML (advanced)")])])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","id":"tagAndAttach"}},[(!_vm.locked)?_c('div',[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.allowCreateTags),expression:"!allowCreateTags"}],attrs:{"outlined":"","dense":"","multiple":"","chips":"","disabled":_vm.disabled,"items":_vm.tags,"placeholder":"Add tags","value":_vm.comment.tags},on:{"change":_vm.inputTag}}),_c('v-combobox',{directives:[{name:"show",rawName:"v-show",value:(_vm.allowCreateTags),expression:"allowCreateTags"}],attrs:{"outlined":"","dense":"","multiple":"","chips":"","disabled":_vm.disabled,"items":_vm.tags,"label":"tags","value":_vm.comment.tags},on:{"change":_vm.inputTag}})],1):_c('div',{staticClass:"ml-5"},_vm._l((_vm.comment.tags),function(t,ti){return _c('v-chip',{key:ti,staticClass:"mx-2",attrs:{"small":""}},[_vm._v(_vm._s(t))])}),1)])],1)],1),_c('v-col',{staticClass:"no-padder",class:{ 'border-left': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"id":"attachments","md":4}},[_c('sr-file-input',{attrs:{"stretch":"","disabled":_vm.disabled,"xs":_vm.$vuetify.breakpoint.smAndDown,"flat":"","readonly":_vm.locked,"parent":_vm.comment},on:{"input":_vm.addFile,"delete":_vm.deleteFile}})],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }