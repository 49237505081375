<template>
  <v-card :loading="loading" flat v-if="availableIds.length || unavailableIds.length">
    <v-card-title v-if="$vuetify.breakpoint.mdAndUp"
      >{{ lock ? "Lock" : "Unlock" }} {{ "Assessment" | pluralTerm }}</v-card-title
    >
    <v-card-text>
      <sr-available-ids
        entity="Assessment"
        :label="lock ? 'Lock' : 'Unlock'"
        :availableIds="availableIds"
        :unavailableIds="unavailableIds"
        :items="assessments"
      ></sr-available-ids>
    </v-card-text>

    <v-card-actions v-if="availableIds.length">
      <v-spacer></v-spacer>

      <v-btn text @click="update" color="success"
        >{{ lock ? "Lock" : "Unlock" }} {{ "assessment" | pluralTerm }}</v-btn
      >
    </v-card-actions>
    <v-card v-if="result">
      {{ result }}
    </v-card>
  </v-card>
  <v-card-text v-else class="text--center d-flex align-content-stretch">
    <em>Please select some {{ "assessment" | pluralTerm }} first</em>
  </v-card-text>
</template>

<script>
import srAvailableIds from "./AvailableIds.vue";

export default {
  name: "LockAssessments",
  components: {
    srAvailableIds,
  },
  data() {
    return {
      changes: 0,
      saving: false,
      showSelected: false,
      error: null,
      loading: false,
      result: null,
    };
  },
  methods: {
    change() {
      this.changes = this.changes + 1;
    },
    update() {
      var valid = window.confirm(
        `Are you sure you wish to ${this.lock ? "lock" : "restore"} ${
          this.availableIds.length
        } ${this.getTerm("assessment", this.availableIds.length == 1 ? false : true)}?`
      );
      if (valid) {
        this.loading = true;
        var data = {
          assessments: this.availableAssessments.map((x) => {
            return {
              id: x.id,
              secret: x.secret,
            };
          }),
          locked: this.lock,
        };
        debugger;
        this.$post("update/assessment/batch/lock", data).then((response) => {
          debugger;
          if (response.error) {
            this.error = response.text;
          } else {
            this.result = response;
            this.$store.dispatch("saved/get_assessments");
            // this.reset();
          }
          this.loading = false;
        });
      }
    },
  },
  computed: {
    availableAssessments() {
      return this.assessments.filter((x) => this.availableIds.includes(x.id));
    },
  },
  props: {
    unavailableIds: {
      type: Array,
      default: () => [],
    },
    availableIds: {
      type: Array,
      default: () => [],
    },
    assessments: {
      type: Array,
      default: () => [],
    },
    lock: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
