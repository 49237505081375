<template>
  <v-card color="transparent" :outlined="readonly" :flat="flat">
    <v-simple-table v-if="!readonly" dense class="transparent pb-2" :key="changes">
      <template v-slot:default>
        <thead v-if="showHeaders">
          <tr>
            <th v-if="headers && headers.length" width="70%">
              <slot name="title">
                {{ headers[0] }}
              </slot>
            </th>
            <th v-for="(h, i) in headers" :hidden="i != 0" :key="i">
              {{ h }}
            </th>
          </tr>
        </thead>
        <tbody>
          <div v-for="(item, i) in items" :key="i">
            <tr :class="{ highlight: locked[i] }">
              <td class="pa-4" width="50%">
                <input
                  v-if="!multiline && !rich"
                  @input="update"
                  style="width: 100%"
                  class="emailInput mb-2"
                  :placeholder="`${label}...`"
                  text
                  v-model="item[valueField]"
                  :readonly="readonly || locked[i]"
                />
                <v-textarea
                  v-if="multiline && !rich"
                  @input="update"
                  style="width: 100%"
                  :placeholder="`${label}...`"
                  text
                  outlined
                  dense
                  v-model="item[valueField]"
                  :readonly="readonly || locked[i]"
                >
                </v-textarea>
                <wysiwyg
                  class="mb-2"
                  v-if="rich && !readonly && !locked[i]"
                  v-model="item[valueField]"
                  @change="update"
                  :options="{
                    link: {
                      url: 'http://',
                    },
                  }"
                >
                </wysiwyg>
                <v-card-text style="width: 100%" v-if="rich && (readonly || locked[i])">
                  <div v-html="item[valueField]"></div>
                </v-card-text>

                <div v-if="showAuthors && false" class="mb-5">
                  <sr-person-chip
                    small
                    class="mx-2"
                    :showAvatar="false"
                    :id="item.metadata.createdBy"
                    :highlight="item.metadata.createdBy == item.metadata.lastUpdatedBy"
                  >
                    (
                    <span
                      v-if="
                        (item.metadata.createdBy == item.metadata.lastUpdatedBy &&
                          !sameDate(item.metadata.created, item.metadata.lastUpdated)) ||
                        item.metadata.createdBy != item.metadata.lastUpdatedBy
                      "
                    >
                      Created: {{ item.metadata.created | moment("DD MMMM YYYY") }}
                    </span>

                    <span v-else>
                      Last Updated:
                      {{ item.metadata.lastUpdated | moment("DD MMMM YYYY") }}
                    </span>
                    )
                  </sr-person-chip>

                  <sr-person-chip
                    small
                    class="mx-2"
                    highlight
                    :showAvatar="false"
                    v-if="
                      item.metadata.lastUpdatedBy &&
                      item.metadata.createdBy != item.metadata.lastUpdatedBy
                    "
                    :id="item.metadata.lastUpdatedBy"
                  >
                    (Last Updated:
                    {{ item.metadata.lastUpdated | moment("DD MMMM YYYY") }})
                  </sr-person-chip>
                </div>
                <div v-if="showAuthors">
                  <small>
                    <sr-person-chip small :id="item.metadata.lastUpdatedBy">
                      <span class="ml-2">
                        - {{ item.metadata.lastUpdated | moment("DD MMM YYYY") }}
                      </span>
                    </sr-person-chip>
                  </small>
                </div>
              </td>
              <td v-if="hasPrimary" width="10%" class="text-center">
                <v-checkbox
                  dense
                  v-if="!readonly && !locked[i]"
                  v-model="item.primary"
                  @change="update"
                  :readonly="readonly"
                ></v-checkbox>
                <i
                  class="fas fa-check green--text"
                  v-if="item.primary && (readonly || locked[i])"
                ></i>
              </td>
              <td v-if="allowFileUpload">
                <sr-file-input :parent="item" @input="addFile" @delete="deleteFile">
                </sr-file-input>
              </td>
              <td v-if="tags.length != 0" width="30%">
                <v-select
                  multiple
                  chips
                  @blur="update"
                  :items="tags"
                  label="tags"
                  v-model="item.tags"
                  :readonly="readonly || locked[i]"
                ></v-select>
              </td>
              <td width="10%" class="text-center">
                <v-btn
                  v-show="!readonly && !locked[i]"
                  size="sm"
                  icon
                  @click="removeItem(i)"
                >
                  <v-icon>fal fa-times</v-icon>
                </v-btn>
                <v-btn v-show="locked[i]" icon size="sm" @click="unlock(i)">
                  <v-icon>fal fa-lock</v-icon>
                </v-btn>
                <br />
              </td>
            </tr>
          </div>
        </tbody>
      </template>
    </v-simple-table>
    <div v-else class="d-print-block w-100">
      <v-card class="ma-2" v-for="(item, i) in items" :key="i">
        <v-card-text v-html="item[valueField]"></v-card-text>
        <v-card-text class="d-flex align-center text--secondary" v-if="showAuthors">
          <small
            >Created: {{ item.metadata.created | moment("DD MMM YYYY") }} by
            <sr-person-chip
              small
              selectOption
              :showAvatar="false"
              :id="item.metadata.createdBy"
            ></sr-person-chip>
            <span
              v-if="
                item.metadata.createdBy != item.metadata.lastUpdatedBy ||
                item.metadata.created != item.metadata.lastUpdatedBy
              "
              class="mr-3"
              >|
            </span>
          </small>
          <small
            v-if="
              item.metadata.createdBy != item.metadata.lastUpdatedBy ||
              item.metadata.created != item.metadata.lastUpdatedBy
            "
            >Last Updated:
            {{ item.metadata.lastUpdated | moment("DD MMM YYYY") }}
            <span v-if="item.metadata.createdBy != item.metadata.lastUpdatedBy">
              by
              <sr-person-chip
                small
                selectOption
                :showAvatar="false"
                :id="item.metadata.lastUpdatedBy"
                class="mx-1"
              ></sr-person-chip>
            </span>
          </small>
        </v-card-text>
      </v-card>
    </div>

    <v-card-text v-if="readonly && items.length == 0"
      >No {{ label | pluralize }} listed</v-card-text
    >
    <v-card-actions v-if="!readonly">
      <v-btn block small text @click="addItem"
        ><v-icon left>fal fa-plus</v-icon> {{ label }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import entities from "../../objects/entities.js";
import SrFileInput from "./FileInput.vue";

export default {
  name: "TextFieldArray",
  components: { SrFileInput },
  data() {
    return { e: entities, locked: [], changes: 0 };
  },
  created() {
    if (this.preserveAuthorship) {
      var userId = this.$auth.userId;
      this.locked = this.items.map((i) => {
        var metadataExists =
          i.metadata != null && (i.metadata.createdBy || i.metadata.lastUpdatedBy);
        var locked = false;
        if (metadataExists) {
          locked = ![i.metadata.createdBy, i.metadata.lastUpdatedBy].includes(userId);
        }
        return locked;
      });
    }
  },
  methods: {
    unlock(i) {
      console.log("unlocking");
      this.locked[i] = !this.locked[i];
      this.changes++;
    },
    person(i) {
      return i.metadata ? i.metadata.createdBy : null;
    },
    setValue(e) {
      this.items[e.index][this.valueField] = e.html;
      this.update();
    },
    addFile(e) {
      console.log("adding file");
      console.log(e);
      var index = this.items.indexOf(e.parent);
      this.items[index].file.push(e.file);
      this.update();
    },
    deleteFile(e) {
      console.log("deleting File");
      console.log(e);
      const index = this.items.indexOf(e.parent);
      const file = e.parent.file.find((f) => f.id == e.fileId);
      const fileIndex = this.items[index].file.indexOf(file);
      console.log(file);

      debugger;
      this.items[index].file.splice(fileIndex, 1);
      this.update();
    },

    addItem() {
      if (this.type) {
        var i = {};
        i = this.$r.clone(this.e[this.type]);
        if (i.metadata != null) {
          i.metadata.createdBy = this.$auth.userId;
          i.metadata.created = new Date();
        }
        this.items.push(i);
        if (this.preserveAuthorship) {
          this.locked.push(false);
        }
      } else {
        this.items.push(null);
      }
      this.$emit("change", this.items);
    },
    removeItem(i) {
      debugger;
      var hasValue = this.items[i][this.valueField];
      var confirm = hasValue
        ? window.confirm(
            `Are you sure you want to remove this ${this.label.toLowerCase()}? This action cannot be undone.`
          )
        : true;
      if (confirm) {
        this.items.splice(i, 1);
        if (this.locked.length && this.preserveAuthorship) {
          this.locked.splice(i, 1);
        }
        this.$emit("change", this.items);
      }
    },
    update() {
      this.$emit("change", this.items);
    },
  },
  computed: {
    headers() {
      var result = [this.label];
      if (this.hasPrimary) {
        if (this.readonly) {
          result.push("Primary");
        } else {
          result.push("Primary?");
        }
      }
      if (!this.readonly) {
        result.push("Remove");
      }
      return result;
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    rich: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: null,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    valueField: {
      type: String,
    },
    hasPrimary: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Item",
    },
    showHeaders: {
      type: Boolean,
      default: true,
    },
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
    allowCreateTags: {
      type: Boolean,
    },
    showAuthors: {
      type: Boolean,
    },
    replies: {
      type: Boolean,
    },
    preserveAuthorship: {
      type: Boolean,
    },
    allowFileUpload: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
