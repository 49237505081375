<template>
  <div>
    <v-card :loading="saving">
      <v-toolbar dense flat>
        <v-toolbar-title>Edit Appearance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text v-show="preview" @click="preview = false">Hide Preview</v-btn>
        <v-btn text v-show="!preview" @click="preview = true">Show Preview</v-btn>
      </v-toolbar>
      <v-card class="ma-5" v-if="preview">
        <div class="d-flex justify-end border-bottom">
          <h4 class="overline pa-2 px-5">Preview</h4>
          <v-spacer></v-spacer>
          <div class="pa-2 px-5 overline hoverable" style="border-bottom: 5px solid gray">
            Unit 1
          </div>
          <div class="pa-2 px-5 overline hoverable">Unit 2</div>
        </div>
        <v-card-text>
          <div v-for="n in [1, 2, 3]" :key="n">
            <div
              class="pa-4 d-flex"
              v-show="editableSettings.format.mode != 'checkList'"
              :class="{
                'justify-space-between': editableSettings.format.mode == 'matrix',
              }"
            >
              <h5 class="overline pr-5">Objective {{ n }}</h5>
              <div v-for="(a, i) in ['A', 'B', 'C', 'D']" :key="i" class="hoverable">
                <v-avatar
                  class="mx-2"
                  color="highlight"
                  size="40"
                  v-if="
                    editableSettings.format.mode == 'ratingScale' ||
                    (editableSettings.format.mode == 'checkList' && i == 1)
                  "
                >
                  <span
                    v-if="
                      editableSettings.format.letterForRatingScale &&
                      editableSettings.format.mode != 'checkList'
                    "
                    >{{ a }}</span
                  >
                  <v-icon v-show="editableSettings.format.mode == 'checkList'"
                    >fal fa-check</v-icon
                  >
                  <span
                    v-if="
                      !editableSettings.format.letterForRatingScale &&
                      editableSettings.format.mode != 'checkList'
                    "
                    >{{ i }}</span
                  >
                </v-avatar>
                <div
                  v-if="editableSettings.format.mode == 'matrix'"
                  :class="{
                    rounded: !app_info.tile && editableSettings.format.mode == 'matrix',
                    'rounded-circle': editableSettings.format.mode != 'matrix',
                  }"
                  style="border: 1px solid gray; height: 50px"
                  class="pa-2 px-4"
                >
                  Level Descriptor {{ n }} {{ a }}
                </div>
              </div>
            </div>
            <div
              class="d-flex align-center ml-5 pa-3"
              v-if="editableSettings.format.objectiveLevelComment"
            >
              <div
                class="rounded grow mx-3 w-100 mb-3 pt-2 pl-2 pr-5 pb-5 d-flex flex-column"
              >
                <div class="d-flex highlight align-center mb-2" v-if="n == 2">
                  <div>
                    <em class="d-block pa-4">Lorem ipsum dolor sit amet...</em>
                    <v-chip class="ml-4 mb-4" small>tag</v-chip>
                  </div>

                  <v-spacer></v-spacer>
                  <div class="shrink">
                    <v-avatar class="mx-2 elevation-1 hoverable" color="white" size="25">
                      <i class="fal fa-minus"></i>
                    </v-avatar>
                  </div>
                </div>

                <v-card
                  outlined
                  class="d-flex hoverable align-center justify-center pa-3"
                >
                  <i class="fal fa-plus-circle mr-3"></i>
                  {{ editableSettings.format.objectiveLevelCommentLabel }}
                </v-card>
              </div>
            </div>
          </div>

          <div
            class="pa-3 overline border-top"
            v-if="editableSettings.format.unitLevelComment"
          >
            {{ editableSettings.format.unitLevelCommentLabel | pluralize }}
          </div>
          <div
            class="d-flex align-center pa-3"
            v-if="editableSettings.format.unitLevelComment"
          >
            <div class="rounded grow w-100 mb-3 pt-2 pl-2 pr-5 pb-5 d-flex flex-column">
              <div class="d-flex highlight align-center mb-2">
                <em class="pa-4">Lorem ipsum dolor sit amet...</em>

                <v-spacer></v-spacer>
                <div class="shrink">
                  <v-avatar class="mx-2 elevation-1" color="white" size="25">
                    <i class="fal fa-minus"></i>
                  </v-avatar>
                </div>
              </div>

              <v-card outlined class="d-flex align-center justify-center pa-3">
                <i class="fal fa-plus-circle mr-3"></i>
                {{ editableSettings.format.unitLevelCommentLabel }}
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-form v-model="valid" v-if="editableSettings" :key="resetCount">
        <div
          class="border-top info-border-key"
          :class="{
            'border-bottom':
              editableSettings.format.mode != 'individual' &&
              editableSettings.format.objectiveLevelComment &&
              editableSettings.format.unitLevelComment,
          }"
        >
          <v-alert dense text tile type="info"
            >These values will apply to all units</v-alert
          >

          <div class="mx-5">
            <v-row align="center">
              <v-col>
                <div class="d-block border-bottom overline">Mode</div>
                <v-row>
                  <v-col>
                    <v-radio-group
                      dense
                      @change="changeTop('mode')"
                      v-model="editableSettings.format.mode"
                    >
                      <v-radio label="Rating Scale" value="ratingScale"> </v-radio>
                      <v-radio label="Matrix" value="matrix"></v-radio>
                      <v-radio label="Checklist" value="checkList"></v-radio>
                      <v-radio
                        value="individual"
                        label="Set individually for each unit"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col v-if="editableSettings.format.mode == 'ratingScale'">
                    <v-checkbox
                      v-model="editableSettings.format.letterForRatingScale"
                      @change="changeTop('letterForRatingScale')"
                      :disabled="saving"
                      label="Use Letter for Rating Scale"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="d-block border-bottom overline">{{ "Scores" | term }}</div>
            <small
              v-if="
                !editableSettings.format.showUnitScores ||
                !editableSettings.format.showUnitGrades ||
                !editableSettings.format.showOverallGrade ||
                !editableSettings.format.showOverallScore
              "
            >
              You will be able to configure some score and grade settings for individual
              {{ "unit" | pluralTerm }} below.
            </small>
            <v-row>
              <v-col>
                <v-switch
                  dense
                  v-model="editableSettings.format.showUnitScores"
                  @change="changeTop('showUnitScores')"
                  :disabled="saving"
                  label="Display Unit level scores"
                >
                </v-switch>
                <v-switch
                  dense
                  v-model="editableSettings.format.showUnitGrades"
                  @change="changeTop('showUnitGrades')"
                  :disabled="saving"
                  label="Display Unit level grades"
                >
                </v-switch>
              </v-col>
              <v-col>
                <v-switch
                  dense
                  v-model="editableSettings.format.showOverallScore"
                  @change="change"
                  :disabled="saving"
                  label="Display overall score"
                >
                </v-switch>
                <v-switch
                  dense
                  v-model="editableSettings.format.showOverallGrade"
                  @change="change"
                  :disabled="saving"
                  label="Display overall grade"
                >
                </v-switch>
              </v-col>
            </v-row>

            <div class="d-block border-bottom overline">{{ "Comments" | term }}</div>
            <small
              v-if="
                !editableSettings.format.unitLevelComment ||
                !editableSettings.format.objectiveLevelComment
              "
            >
              You will be able to configure
              <span
                v-if="
                  !editableSettings.format.unitLevelComment &&
                  !editableSettings.format.objectiveLevelComment
                "
                >all</span
              >
              <span v-else>some</span>
              {{ "comment" | term }} settings for individual
              {{ "unit" | pluralTerm }} below.
            </small>

            <v-switch
              dense
              v-model="editableSettings.format.unitLevelComment"
              @change="changeTop('unitLevelComment')"
              :disabled="saving"
              label="Unit Level Comments"
            >
            </v-switch>

            <v-text-field
              outlined
              dense
              v-if="editableSettings.format.unitLevelComment"
              label="Unit Comment Label"
              :disabled="saving"
              v-model="editableSettings.format.unitLevelCommentLabel"
              @input="changeTop('unitLevelCommentLabel')"
              :rules="[
                (v) => !!v || 'Label is required',
                (v) => v.length <= 50 || 'Label must be less than 50 characters',
              ]"
            >
            </v-text-field>

            <v-switch
              dense
              v-model="editableSettings.format.objectiveLevelComment"
              @change="changeTop('objectiveLevelComment')"
              :disabled="saving"
              label="Enable Objective Level Comments?"
            >
            </v-switch>

            <v-row v-if="editableSettings.format.objectiveLevelComment">
              <v-col>
                <v-text-field
                  outlined
                  dense
                  label="Objective Comment Label"
                  :disabled="saving"
                  v-model="editableSettings.format.objectiveLevelCommentLabel"
                  @input="changeTop('objectiveLevelCommentLabel')"
                  :rules="[
                    (v) => !!v || 'Label is required',
                    (v) => v.length <= 50 || 'Label must be less than 50 characters',
                  ]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="start" v-if="editableSettings.format.objectiveLevelComment">
              <v-col cols="12" md="8">
                <v-combobox
                  outlined
                  dense
                  class="mt-2"
                  hide-no-data
                  single-line
                  small-chips
                  v-model="editableSettings.format.evidenceTags"
                  chips
                  clearable
                  label="Objective Tags"
                  :disabled="saving"
                  @input="changeTop('evidenceTags')"
                  multiple
                  append-icon=""
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      class="my-1"
                      dark
                      @click="select"
                      @click:close="removeTag(item)"
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="editableSettings.format.createEvidenceTags"
                  @change="changeTop('createEvidenceTags')"
                  :disabled="saving"
                  label="Users can create tags"
                >
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-expansion-panels
          multiple
          tile
          flat
          v-if="
            editableSettings.format.mode == 'individual' ||
            !editableSettings.format.objectiveLevelComment ||
            !editableSettings.format.unitLevelComment
          "
        >
          <v-expansion-panel
            v-for="(u, i) in editableSettings.units"
            :key="i + 1"
            class="border-top"
          >
            <v-expansion-panel-header>
              {{ u.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :key="changes + i">
              <div class="mx-5">
                <v-row align="center">
                  <v-col>
                    <div class="d-block border-bottom overline">Mode</div>
                    <small v-if="editableSettings.format.mode != 'individual'">
                      Change mode to 'Set Individually' in the global settings above to
                      edit.
                    </small>
                    <v-row>
                      <v-col>
                        <v-radio-group
                          dense
                          @change="change"
                          :disabled="
                            saving || editableSettings.format.mode != 'individual'
                          "
                          v-model="u.format.mode"
                        >
                          <v-radio label="Rating Scale" value="ratingScale"> </v-radio>
                          <v-radio label="Matrix" value="matrix"></v-radio>
                          <v-radio label="Checklist" value="checkList"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col v-if="u.format.mode == 'ratingScale'">
                        <v-checkbox
                          v-model="u.format.letterForRatingScale"
                          @change="change"
                          :disabled="
                            saving || editableSettings.format.mode != 'individual'
                          "
                          label="Use Letter for Rating Scale"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="d-block border-bottom overline">{{ "Scores" | term }}</div>
                <small
                  v-if="
                    editableSettings.format.showUnitScores &&
                    editableSettings.format.showUnitGrades &&
                    editableSettings.format.showOverallGrade &&
                    editableSettings.format.showOverallScore
                  "
                >
                  To change unit score/grade displays individually, please deselect
                  switches in general settings above.
                </small>
                <v-row>
                  <v-col>
                    <v-switch
                      dense
                      v-model="u.format.showUnitScores"
                      @change="change"
                      :disabled="saving || editableSettings.format.showUnitScores"
                      label="Display Unit level scores"
                    >
                    </v-switch>
                    <v-switch
                      dense
                      v-model="u.format.showUnitGrades"
                      @change="change"
                      :disabled="saving || editableSettings.format.showUnitGrades"
                      label="Display Unit level grades"
                    >
                    </v-switch>
                  </v-col>
                </v-row>

                <div class="d-block border-bottom overline">{{ "Comments" | term }}</div>
                <small
                  v-if="
                    editableSettings.format.objectiveLevelComment ||
                    editableSettings.format.unitLevelComment
                  "
                >
                  Deselect
                  <span v-if="editableSettings.format.unitLevelComment">
                    'Enable Unit Level Comments'
                  </span>
                  <span
                    v-if="
                      editableSettings.format.unitLevelComment &&
                      editableSettings.format.objectiveLevelComment
                    "
                    >or</span
                  >
                  <span v-if="editableSettings.format.objectiveLevelComment">
                    'Enable Objective Level Comments'
                  </span>
                  in the global settings above to edit.
                </small>
                <v-switch
                  dense
                  v-model="u.format.unitLevelComment"
                  @change="change"
                  :disabled="saving || editableSettings.format.unitLevelComment"
                  label="Unit Level Comments"
                >
                </v-switch>

                <v-text-field
                  outlined
                  dense
                  v-if="u.format.unitLevelComment"
                  label="Unit Comment Label"
                  :disabled="saving || editableSettings.format.unitLevelComment"
                  v-model="u.format.unitLevelCommentLabel"
                  :rules="[
                    (v) => !!v || 'Label is required',
                    (v) => v.length <= 50 || 'Label must be less than 50 characters',
                  ]"
                >
                </v-text-field>

                <v-switch
                  dense
                  v-model="u.format.objectiveLevelComment"
                  @change="change"
                  :disabled="saving || editableSettings.format.objectiveLevelComment"
                  label="Enable Objective Level Comments?"
                >
                </v-switch>

                <v-row v-if="u.format.objectiveLevelComment">
                  <v-col>
                    <v-text-field
                      outlined
                      dense
                      label="Objective Comment Label"
                      :disabled="saving || editableSettings.format.objectiveLevelComment"
                      v-model="u.format.objectiveLevelCommentLabel"
                      :rules="[
                        (v) => !!v || 'Label is required',
                        (v) => v.length <= 50 || 'Label must be less than 50 characters',
                      ]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="start" v-if="u.format.objectiveLevelComment">
                  <v-col cols="12" md="8">
                    <v-combobox
                      outlined
                      dense
                      class="mt-2"
                      hide-no-data
                      single-line
                      small-chips
                      v-model="u.format.evidenceTags"
                      chips
                      clearable
                      label="Objective Tags"
                      :disabled="saving || editableSettings.format.objectiveLevelComment"
                      @input="change"
                      multiple
                      append-icon=""
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          class="my-1"
                          dark
                          @click="select"
                          @click:close="removeTag(item)"
                        >
                          {{ item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="u.format.createEvidenceTags"
                      @change="change"
                      :disabled="saving || editableSettings.format.objectiveLevelComment"
                      label="Users can create tags"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reset" :disabled="changes == 0 || saving" color="warning"
          >reset</v-btn
        >
        <v-btn
          text
          @click="update"
          :disabled="changes == 0 || !valid || saving"
          color="success"
          >Save Changes</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FrameworkAppearance",
  data() {
    return {
      editableSettings: null,
      changes: 0,
      saving: false,
      valid: false,
      resetCount: 0,
      preview: false,
    };
  },
  created() {
    this.reset();
  },

  methods: {
    change() {
      this.changes++;
    },
    changeTop(e) {
      var setting = this.editableSettings.format[e];
      console.log(setting);
      if (this.editableSettings.units && this.editableSettings.units.length != 0) {
        this.editableSettings.units.forEach((u) => {
          debugger;
          if (setting != "individual") {
            u.format[e] = setting;
          }
        });
      }

      this.changes++;
    },
    reset() {
      if (this.editableSettings != null) {
        this.editableSettings = null;
      }
      this.editableSettings = {};
      var aF = {
        letterForRatingScale: this.framework.format.letterForRatingScale,
        evidenceTags: this.framework.format.evidenceTags
          ? this.framework.format.evidenceTags
          : [],
        createEvidenceTags: this.framework.format.createEvidenceTags,
        mode: this.framework.format.ratingScale
          ? "ratingScale"
          : this.framework.mode
          ? this.framework.mode
          : this.framework.format.mode
          ? this.framework.format.mode
          : "individual",
        unitLevelComment:
          this.framework.format.unitLevelComments ||
          this.framework.format.unitLevelComment,
        unitLevelCommentLabel: this.framework.format.unitLevelCommentLabel
          ? this.framework.format.unitLevelCommentLabel
          : "Comment",
        objectiveLevelComment:
          this.framework.format.objectiveLevelComment ??
          this.framework.format.objectiveLevelComment ??
          this.framework.format.enableEvidence,
        objectiveLevelCommentLabel: this.framework.format.objectiveLevelCommentLabel
          ? this.framework.format.objectiveLevelCommentLabel
          : "Comment",
        showUnitGrades: this.framework.format.showUnitGrades,
        showOverallGrade: this.framework.format.showOverallGrade,
        showUnitScores: this.framework.format.showUnitScores,
        showOverallScore: this.framework.format.showOverallScore,
      };
      var settings = {
        id: this.framework.id,
        format: aF,
        units: this.framework.units.map((x) => {
          var uF = x.format;
          if (aF.mode != "individual") {
            uF.mode = aF.mode;
            uF.letterForRatingScale = aF.letterForRatingScale;
          }
          if (aF.unitLevelComment) {
            uF.unitLevelComment = aF.unitLevelComment;
            uF.unitLevelCommentLabel = aF.unitLevelCommentLabel;
          }
          if (aF.objectiveLevelComment) {
            uF.objectiveLevelComment = aF.objectiveLevelComment;
            uF.objectiveLevelCommentLabel = aF.objectiveLevelCommentLabel;
            uF.evidenceTags = aF.evidenceTags;
            uF.createEvidenceTags = aF.createEvidenceTags;
          }
          if (aF.showUnitScores) {
            uF.showUnitScores = aF.showUnitScores;
          }
          if (aF.showOverallScore) {
            uF.showOverallScore = aF.showOverallScore;
          }
          if (aF.showUnitGrades) {
            uF.showUnitGrades = aF.showUnitGrades;
          }
          if (aF.showOverallGrade) {
            uF.showOverallGrade = aF.showOverallGrade;
          }
          return {
            id: x.id,
            title: x.title,
            format: uF,
          };
        }),
      };
      Object.assign(this.editableSettings, settings);
      this.resetCount++;
    },
    removeTag(item) {
      debugger;
      var i = this.editableSettings.format.evidenceTags.indexOf(item);
      this.editableSettings.format.evidenceTags.splice(i, 1);
      this.editableSettings.format.evidenceTags = [
        ...this.editableSettings.format.evidenceTags,
      ];
      this.change();
    },

    update() {
      this.saving = true;
      var data = this.editableSettings.units.map((u) => {
        return {
          id: this.framework.id,
          secret: this.framework.secret,
          unitId: u.id,
          format: u.format,
        };
      });
      data.push({
        id: this.framework.id,
        secret: this.framework.secret,
        format: this.editableSettings.format,
      });
      console.log(data);
      this.$post("update/framework/appearance", data).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("framework"))
          );
        } else {
          //  this.$store.dispatch('saved/get_frameworks')
          this.changes = 0;
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
      });
    },
  },
  props: {
    framework: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
