import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./sass/main.scss";
//https://github.com/chmln/vue-wysiwyg
import wysiwyg from "vue-wysiwyg-lite";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
//update version
import AES from 'crypto-js/aes';
const Moment = require('moment');

Vue.use(wysiwyg, {
  // { [module]: boolean (set true to hide) }
  hideModules: { 
    "image": true,
    "table": true, 
    "justifyLeft": true,
    "justifyRight": true,
    "justifyCenter": true,
    "code": true,
    "separator": true,
  },
  forcePlainTextOnPaste: false,
});

const R = require('ramda'); 

import globalMixins from "./assets/scripts/mixins.js";
import { Auth0Plugin } from "./auth";
import template from "./configs/template";
import layout from './configs/layout';
import Breadcrumb from "./components/Breadcrumb.vue";
import NavBar from "./components/Navbar";
import GroupWidget from './newComponents/GroupWidget';
import Bookmarks from './newComponents/Settings/Bookmarks.vue';
//import MyGroupReports from './newComponents/MyGroupReports';
import Notifications from './newComponents/Notifications';
import OpenAssessments from './newComponents/AssessmentList.vue';
import GroupList from './newComponents/GroupList.vue';
import Timeline from './newComponents/Timeline';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "./assets/app.css";
import "./assets/print.css";
import getters from './assets/scripts/getters';
import axios from "axios";
import config from './configs/template.js';
import Terminology from './configs/terminology';
import GroupTypeWidget from './newComponents/GroupTypeWidget';
import Loading from './newComponents/Loading.vue'
import Permissions from './newComponents/Permissions.vue';
import ManageSubgroups from './newComponents/GroupComponents/GroupSubgroups.vue';
import EditGroup from './newComponents/GroupComponents/GroupEdit.vue';
import ManageMembers from './newComponents/GroupComponents/GroupMembers.vue';
import ViewBaseline from './newComponents/GroupComponents/ViewBaseline.vue'
//FrameworkStuff
import EditFramework from './newComponents/FrameworkComponents/FrameworkEdit.vue';
import FrameworkAppearance from './newComponents/FrameworkComponents/FrameworkAppearance.vue';
import FrameworkScoring from './newComponents/FrameworkComponents/FrameworkScoring.vue';
import FrameworkAccess from './newComponents/FrameworkComponents/FrameworkAccess.vue';


//Rubric Stuff
import EditRubric from './newComponents/RubricComponents/RubricEdit.vue';
import RubricAccess from './newComponents/RubricComponents/RubricAccess.vue';

//Assessment Stuff
import EditAssessment from './newComponents/AssessmentComponents/AssessmentEdit.vue';
import AssessmentAccess from './newComponents/AssessmentComponents/AssessmentAccess.vue';
import Comment from './newComponents/AssessmentComponents/Comment.vue';
import ReportWidget from './newComponents/ReportWidget.vue';
import Breadcrumbs from './newComponents/Breadcrumbs.vue';
import NotFound from './newComponents/NotFound';
//Person Stuff
import PersonWidget from './newComponents/PeopleWidget.vue';


//minis
import CustomIcon from './newComponents/MiniComponents/CustomIcon';
import GroupChip from './newComponents/MiniComponents/GroupChip';
import PersonChip from './newComponents/MiniComponents/PersonChip';
import FrameworkChip from './newComponents/MiniComponents/FrameworkChip.vue';
import GroupPath from './newComponents/GroupPath.vue';
import TextFieldArray from './newComponents/Inputs/TextFieldArray.vue';
import GroupPicker from './newComponents/Inputs/GroupPicker.vue';
import OverflowChips from './newComponents/MiniComponents/OverflowChips.vue'
import FileInput from './newComponents/Inputs/FileInput.vue';
//actions
import srBatchAssess from './newComponents/Actions/BatchAssess.vue'
import srBatchSubgroup from './newComponents/Actions/BatchSubgroup.vue'
import srDuplicateAssessments from './newComponents/Actions/DuplicateAssessments.vue'
import srArchiveAssessments from './newComponents/Actions/ArchiveAssessments.vue';
import srLockAssessments from './newComponents/Actions/LockAssessments.vue';
import srAddAssessmentTags from './newComponents/Actions/BatchTag.vue';

Vue.component("better-select", vSelect);
Vue.component("sr-icon", CustomIcon);
Vue.component("sr-file-input", FileInput);
Vue.component("sr-group-chip", GroupChip);
Vue.component("sr-person-chip", PersonChip);
Vue.component("sr-framework-chip", FrameworkChip);
Vue.component("sr-overflow-chips", OverflowChips);
Vue.component("sr-loading", Loading);
Vue.component("sr-group-picker", GroupPicker);
Vue.component("sr-group-widget", GroupWidget);
Vue.component("sr-group-type-widget", GroupTypeWidget);
Vue.component("sr-notifications", Notifications);
Vue.component("sr-open-assessments", OpenAssessments);
Vue.component("sr-group-list", GroupList);
Vue.component("sr-timeline", Timeline);
Vue.component("sr-group-path", GroupPath);
Vue.component("sr-edit-rubric", EditRubric);
Vue.component("sr-rubric-access", RubricAccess);
Vue.component('sr-breadcrumbs', Breadcrumbs);
Vue.component('sr-permissions', Permissions);
Vue.component('sr-manage-subgroups', ManageSubgroups);
Vue.component('sr-manage-members', ManageMembers);
Vue.component('sr-edit-group', EditGroup);
Vue.component('sr-edit-framework', EditFramework);
Vue.component('sr-framework-appearance', FrameworkAppearance);
Vue.component('sr-framework-access', FrameworkAccess);
Vue.component('sr-framework-scoring', FrameworkScoring);
Vue.component('sr-text-field-array', TextFieldArray);
Vue.component('sr-edit-assessment', EditAssessment);
Vue.component('sr-assessment-members', AssessmentAccess);
Vue.component('sr-comment', Comment);
Vue.component('sr-people-widget', PersonWidget);
Vue.component('sr-report', ReportWidget);
Vue.component('sr-not-found', NotFound);
Vue.component('sr-view-baseline', ViewBaseline);
Vue.component('sr-bookmarks', Bookmarks);
Vue.component('sr-batch-assess', srBatchAssess)
Vue.component('sr-batch-subgroups', srBatchSubgroup)
Vue.component('sr-duplicate-assessments', srDuplicateAssessments)
Vue.component('sr-archive-assessments', srArchiveAssessments);
Vue.component('sr-lock-assessments', srLockAssessments);
Vue.component('sr-batch-tag-assessment', srAddAssessmentTags);
const terminology = Terminology;
const domain = process.env.VUE_APP_DOMAIN; //auth.domain;
const clientId = process.env.VUE_APP_CLIENT_ID; //auth.clientId;
const audience = process.env.VUE_APP_AUDIENCE; //auth.audience;
const authOrg = process.env.VUE_APP_AUTH_ORG; //auth.audience;
const org = process.env.VUE_APP_ORG;
const root = process.env.VUE_APP_API_URL;
const api_key = process.env.VUE_APP_API_KEY
const query = `?orgId=${config.orgId}`;

const encrypt = function(string){
    return AES.encrypt(string, api_key);
}

const login = function(uri){
    var t = this;
    t.$store.dispatch("saved/reset");
  this.$auth.loginWithRedirect({
    redirect_uri: uri
  });
};

const passwordReset = function(email){
  //debugger;
  const config = {
   /* headers: {
      contentType: "application/json ",
    },
    */
  };
  const data = {
      client_id: clientId,
      email: email, 
      connection: "Username-Password-Authentication"
  }
    axios.post(`https://${domain}/dbconnections/change_password`, data, config).then(response=>{
      console.log(response);
      return response; 
    });
}

const logout = function(){
  console.log('Logging Out');
  var t = this;
  //debugger;
    t.$store.dispatch("saved/reset");
    localStorage.clear();
    httpGet("saved/sessiondata/delete", t.$auth).then(()=>{
      //debugger;

      t.$auth.logout({returnTo: window.location.origin,});
    });
};

const httpGet = async function(url, auth){
  auth = auth ?? this.$auth;
  return  auth.getTokenSilently().then((t) => {
    const config = {
      headers: {
        authorization: "Bearer " + t,
      },
    };
    return  axios.get(`${root}${url}${query}`, config).then((response) => {
      return response;
    })
    .catch((error, response)=>{
      return {error: true, text: `${error}: ${response}`};
    });
  });
};

const publicGet = async function(url){
  //debugger;
    return  axios.get(`${root}${url}`).then((response) => {
      return response;
    })
    .catch((error, response)=>{
      return {error: true, text: `${error}: ${response}`};
    });
  }
 

const httpPost = function(url, data){
  return this.$auth.getTokenSilently().then((t) => {
    const config = {
      headers: {
        authorization: "Bearer " + t,
      },
    };
    return axios.post(`${root}${url}${query}`, data, config).then((response) => {
      return response.data;
    })
    .catch((error, response)=>{
      return {error: true, text: `${error}: ${response}`};
    });
  });
};

const httpPostFile = function(file){
  let formData = new FormData();
  formData.append("file", file);
  return this.$auth.getTokenSilently().then((t) => {
    const config = {
      
      headers: {
        authorization: "Bearer " + t,
        "Content-Type": "multipart/form-data",
      },
    };
    return axios.post(`${root}file/upload${query}`, formData, config).then((response) => {
      return response.data;
    })
    .catch((error, response)=>{
      return {error: true, text: `${error}: ${response}`};
    });
  });
};
const pluralize = function (value) {
  if (!value) return "";
      value = value.toString();
      var arr = [];
      if(value.includes(' of ')){
        arr = value.split(' ');
      }
      else{
        arr = [value];
      }
      //debugger;
      if(terminology.plurals[arr[0].toLowerCase()]){
        var v = arr[0];
        var upperCase = v[0] == v[0].toUpperCase(); 
        var lcValue = v.toLowerCase();
        var term = ! upperCase ? terminology.plurals[lcValue] : terminology.plurals[lcValue][0].toUpperCase()  + terminology.plurals[lcValue].substring(1); ;
        return term; 
      }
      else{
        var suffix = "s";
        if (arr[0].charAt(arr[0].length - 1) == "s") {
          suffix = "es";
        }
        if (arr[0].charAt(arr[0].length - 1) == "y") {
          arr[0] = arr[0].substring(0, arr[0].length - 1);
          suffix = "ies";
        }
        if (arr[0].charAt(arr[0].length - 1) == "x") {
          suffix = "es";
        }
        if (arr[0].charAt(arr[0].length - 1) == ")") {
          suffix = "";
        }
        arr[0] = arr[0] + suffix;
      }
      
      return arr.join(' ');
};

const getTerm = function (value, plural) {
  //debugger
  if (!value) return "";
  
  var arr = value.split(' ');
  var result  = '';
  arr.forEach(v=>{
    //debugger;
    var upperCase = v[0] == v[0].toUpperCase(); 
    var lcValue = v.toLowerCase();
    var term = terminology[org] && terminology[org][lcValue] ?  terminology[org][lcValue] : (terminology.default[lcValue] != null ? terminology.default[lcValue] : v) ;
    if(upperCase){
      term = term[0].toUpperCase() + term.substring(1);
    }
    else{
      if(v.toUpperCase() != v){
        term = term.toLowerCase(); 

      }
    }
    result = `${result} ${term}`;
    })
  return plural ? pluralize(result.trim()) : result.trim();
};
const getPluralTerm = function(value){
  return getTerm(value, true);
}
const vowels = ['a','e','i','o','u'];

const article = function(value){
  var initial = value && value.length ? value [0] : null; 
  if(initial && vowels.includes(initial)){
    return 'an'
  }
  else if(initial){
    return 'a'
  }
  return null; 
}

const getOrdinal = function(n) {
    let ord = 'th';
  
    if (n % 10 == 1 && n % 100 != 11)
    {
      ord = 'st';
    }
    else if (n % 10 == 2 && n % 100 != 12)
    {
      ord = 'nd';
    }
    else if (n % 10 == 3 && n % 100 != 13)
    {
      ord = 'rd';
    }
  
    return `${n}${ord}`;
  }

Vue.prototype.$login = login;

Vue.prototype.$passwordReset = passwordReset;
Vue.prototype.$logout = logout;
Vue.prototype.$get = httpGet;
Vue.prototype.$post = httpPost;
Vue.prototype.$upload = httpPostFile;
Vue.prototype.$getTerm = getTerm;
Vue.prototype.$article = article; 
Vue.prototype.$encrypt = encrypt; 
Vue.prototype.$r= R;
Vue.prototype.$moment=Moment;
Vue.prototype.$publicGet = publicGet; 
Vue.component("crumb", Breadcrumb);
Vue.component("nav-bar", NavBar);
Vue.use(require("vue-moment"));
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
});
Vue.config.productionTip = false;
Vue.mixin(globalMixins);
Vue.mixin(getters)
Vue.mixin({
  data: function () {
    return {
      get app_template(){
        return Object.keys(layout).includes(org) ? layout[org] : layout.default;
      },
      get app_info() {
        template.appInfo.branch = process.env.VUE_APP_BRANCH;
        template.appInfo.mode = process.env.NODE_ENV;
        template.appInfo.version = "Version " + process.env.VUE_APP_VERSION;
        template.appInfo.clientId = process.env.VUE_APP_CLIENT_ID;
        return template.appInfo;
      },
      
      get org_id() {
       
        return template.orgId;
      },
    };
  },
});

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  authOrg,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});


Vue.filter("titleCase", function (str) {
  if (str && str.length) {
    return str
      .split(" ")
      .map(function (word) {
        if (!word.match(/[A-Z]{2,}/g)) {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }
        return word;
      })
      .join(" ");
  }
  return null;
});
Vue.filter("ordinal", getOrdinal)
Vue.filter("term", getTerm);
Vue.filter("pluralTerm", getPluralTerm);

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("pluralize", pluralize);
Vue.filter("singularize", function (value) {
  if (
    value &&
    value.charAt(value.length - 1) == "s" &&
    value.charAt(value.length - 2) == "e" &&
    value.charAt(value.length - 3) == "i"
  ) {
    return value.substring(0, value.length - 1);
  }
  if (value && value.charAt(value.length - 1) == "s") {
    return value.substring(0, value.length - 1);
  }
  return value ?? "";
});
Vue.filter("allcaps", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});
Vue.filter("unCamel", function (value) {
  if (!value) return "";
  value = value.toString();
  var result = value.replace( /([A-Z])/g, " $1" );
 return result.charAt(0).toUpperCase() + result.slice(1);
});

function titleCase(str) {
  return str
    .split(" ")
    .map(function (word) {
      if (!word.match(/[A-Z]{2,}/g)) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word;
    })
    .join(" ");
}


router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const nearestWithMetaTwo = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && !r.meta.bcHide);

  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  if (nearestWithMetaTwo && nearestWithMetaTwo.meta.bcDynamic) {
    var titleArray = document.title.split(" | ");
    var currentTitle = titleArray[titleArray.length - 1];
    var getter = nearestWithMetaTwo.meta.bcGetter;
    var id;
    if (nearestWithMetaTwo.meta.bcIsChild) {
      switch (getter) {
        case "entities/gRoute":
          id = to.params.groupid;
          break;
        case "people/pRoute":
          id = to.params.individualid;
          break;
        case "frameworks/framework":
          id = to.params.frameworkid;
          break;
        case "assessments/assessment":
          id = to.params.assessmentid;
          break;
      }
    } else {
      id = to.params.grouptypeid;
    }
    var title = currentTitle;
    var bc = store.getters[getter](id);

    var name = nearestWithMetaTwo.meta.title(bc);

    //var obj = store.getters[getter](id);
    if (name) {
      title = titleCase(name);
    }

    document.title = title;
    // }
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),

  created() {
    console.log("created");
    //  store.dispatch('stitch/anonymousLogin');
  },
});
store.$app = app;
app.$mount("#app");
