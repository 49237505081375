var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.person.opaque || _vm.$auth.isAdmin)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$auth.isDataManager)?_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Add to Groups")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"d-flex flex-align-center pb-0 px-5"},[_c('sr-group-picker',{staticClass:"flex-grow-1 mr-5",attrs:{"dense":"","width":"50%","outlined":"","multiple":"","label":"Add to Group","disabled":_vm.loading,"exclude":_vm.groupIds},model:{value:(_vm.newGroups),callback:function ($$v) {_vm.newGroups=$$v},expression:"newGroups"}}),_c('v-checkbox',{staticClass:"my-1",class:{ 'mx-5': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"disabled":_vm.newGroups.length == 0 || _vm.loading,"label":"Admin"},model:{value:(_vm.newGroupAdmin),callback:function ($$v) {_vm.newGroupAdmin=$$v},expression:"newGroupAdmin"}}),_c('v-btn',{staticClass:"my-0 ml-5",class:{ 'mx-5': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"icon":_vm.$vuetify.breakpoint.smAndDown,"text":"","color":"primary","disabled":_vm.newGroups.length == 0 || _vm.loading},on:{"click":_vm.addToGroup}},[_c('v-icon',{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("fal fa-plus")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Add to Group")]):_vm._e()],1)],1)],1):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"md":_vm.availableGroups ? '3' : '12'}},[_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',{attrs:{"dense":""}},_vm._l((_vm.groups),function(t){return _c('v-list-item',{key:t.id,staticClass:"d-flex justify-space-between",class:{ 'elevation-1 my-3': !t.canReadDocs },attrs:{"dense":"","color":"secondary","to":t.canReadDocs
                ? { name: _vm.destination, params: { groupid: t.id } }
                : { name: 'GroupHome', params: { groupid: t.id } },"exact":""}},[_c('v-badge',{attrs:{"value":_vm.isAdmin(t),"prepend":"","content":"A","bordered":"","left":"","inline":"","transition":"scale-rotate-transition"}},[_c('sr-group-chip',{attrs:{"id":t.id,"twoLine":true,"selectOption":"","small":""}})],1)],1)}),1)],1)],1):_vm._e(),_c('v-col',[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"d-flex flex-wrap justify-end"},[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"40"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Select Group "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fas fa-caret-down")])],1)]}}],null,false,59016391)},[_c('v-list',{staticStyle:{"max-height":"50vh","overflow-y":"auto"}},_vm._l((_vm.groups),function(t){return _c('v-list-item',{key:t.id,staticClass:"ma-2 flex-grow",attrs:{"color":"secondary","to":t.canReadDocs
                  ? { name: _vm.destination, params: { groupid: t.id } }
                  : { name: 'GroupHome', params: { groupid: t.id } },"exact":""}},[_c('v-badge',{attrs:{"value":_vm.isAdmin(t),"prepend":"","content":"A","bordered":"","left":"","inline":"","transition":"scale-rotate-transition"}},[_c('sr-group-chip',{attrs:{"id":t.id,"twoLine":true,"selectOption":"","small":""}})],1)],1)}),1)],1)],1):_vm._e(),(_vm.group)?_c('router-view',_vm._b({},'router-view',{ person: _vm.person, permissions: _vm.permissions, groupId: _vm.group },false)):_vm._e(),(!_vm.group && _vm.availableGroups)?_c('sr-not-found',{attrs:{"color":"secondary","icon":"fad fa-list"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Choose a "+_vm._s(_vm._f("term")("Group")))]},proxy:true},{key:"body",fn:function(){return [_vm._v("Select a group from the options "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("to the left")]):_c('span',[_vm._v("above")]),_vm._v(".")]},proxy:true}],null,false,490776914)}):_vm._e()],1)],1):_c('sr-not-found',{attrs:{"color":"error","icon":"fad fa-lock","unauthorized":"","thing":("the groups page for " + (_vm.getDisplayName(_vm.person)))}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }