<template>
  <v-card :loading="saving">
    <v-toolbar dense flat>
      <v-toolbar-title>Edit Access</v-toolbar-title>
    </v-toolbar>
    <v-form v-model="valid" v-if="editableSettings" :key="resetCount">
      <v-card-text>
        <v-row>
          <v-col>
            <v-card outlined :tile="app_info.tile">
              <v-card-subtitle class="overline">Library</v-card-subtitle>
              <div>
                <v-data-table
                  show-expand
                  :key="groupChange"
                  :single-expand="true"
                  :items-per-page="-1"
                  hide-default-footer
                  dense
                  :itemClass="itemClass"
                  item-key="groupId"
                  hide-default-header
                  :items="library"
                  :headers="libraryHeaders"
                >
                  <template v-slot:item.groupId="{ item }">
                    <div class="hover-parent">
                      <v-btn
                        v-if="!item.opaque"
                        class="hover-item"
                        color="error"
                        small
                        icon
                        text
                        @click="deleteGroup(item.groupId)"
                      >
                        <v-icon>fal fa-times</v-icon>
                      </v-btn>
                      <sr-group-chip :id="item.groupId"></sr-group-chip>
                    </div>
                  </template>
                  <template v-slot:item.access="{ item }">
                    <v-switch
                      :disabled="item.opaque"
                      inset
                      dense
                      class="visibility-toggle"
                      v-model="item.access"
                      @change="change()"
                    >
                    </v-switch>
                  </template>

                  <template v-slot:footer>
                    <v-divider></v-divider>
                    <div class="d-flex ma-3 align-center">
                      <v-select
                        v-model="selectedGroup"
                        item-value="id"
                        @change="nodeSearch = null"
                        :items="groups"
                      >
                        <template v-slot:prepend-item>
                          <v-text-field
                            class="mx-3"
                            append-icon="fal fa-search"
                            v-model="nodeSearch"
                          ></v-text-field>
                        </template>

                        <template v-slot:selection="{ item }">
                          <div class="d-flex">
                            <v-chip small class="mr-3" v-if="item.internalId"
                              >{{ item.internalId }}
                            </v-chip>
                            <sr-group-chip :id="item.id" selectOption></sr-group-chip>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <div class="d-flex">
                            <v-chip small class="mr-3" v-if="item.internalId"
                              >{{ item.internalId }}
                            </v-chip>
                            <sr-group-chip :id="item.id" selectOption></sr-group-chip>
                          </div>
                        </template>
                      </v-select>
                      <v-btn :disabled="!selectedGroup" text @click="addGroup">
                        <v-icon left>fal fa-plus</v-icon> Add Node
                      </v-btn>
                    </div>
                    <div v-if="selectedGroup">
                      <sr-group-widget
                        :bordered="false"
                        asTable
                        showAll
                        :key="selectedGroup"
                        title="If added, the framework will be available to the groups listed below"
                        :allowFilter="false"
                        :headers="[
                          {
                            text: 'Name',
                            align: 'start',
                            sortable: true,
                            value: 'displayName',
                          },
                          {
                            text: 'Type',
                            align: 'center',
                            value: 'groupType',
                          },
                        ]"
                        :filterValues="{
                          selectedGroupTypes: [],
                          selectedParents: [selectedGroup],
                        }"
                      >
                      </sr-group-widget>
                    </div>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card class="ma-4" flat background-color="transparent">
                        <v-card-text v-if="item.access">
                          <v-btn
                            :disabled="saving"
                            block
                            outlined
                            @click="createRubric(item)"
                            >Create new {{ "Rubric" | term }} for
                            <sr-group-chip
                              class="mx-3"
                              select-option
                              small
                              :id="item.groupId"
                            ></sr-group-chip>
                            and children</v-btn
                          >
                        </v-card-text>
                        <v-tabs v-model="tab" background-color="transparent">
                          <v-tab key="permissions"> Permissions </v-tab>

                          <v-tab key="groups">
                            {{ "Group" | pluralTerm }}
                          </v-tab>
                          <v-tab key="history"> Rubrics </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab">
                          <v-tab-item key="permissions">
                            <v-card flat class="ma-4">
                              <v-card-text>
                                <div class="text-overline">Rubric Permissions</div>
                                <v-row>
                                  <v-col>
                                    Node Admins can:
                                    <v-switch
                                      dense
                                      v-for="(p, i) in rubricPermissions"
                                      :key="i"
                                      v-model="item.rubricPermissions.nodeAdmin[p]"
                                      @change="change"
                                      :disabled="saving"
                                    >
                                      <template v-slot:label>
                                        {{ p | unCamel }}
                                      </template>
                                    </v-switch>
                                  </v-col>
                                  <v-col>
                                    Child group Admins can:
                                    <v-switch
                                      dense
                                      v-for="(p, i) in rubricPermissions"
                                      :key="i"
                                      v-model="item.rubricPermissions.childAdmin[p]"
                                      @change="change"
                                      :disabled="saving"
                                    >
                                      <template v-slot:label>
                                        {{ p | unCamel }}
                                      </template>
                                    </v-switch>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  @click="reset"
                                  :disabled="changes == 0 || saving"
                                  color="warning"
                                  >reset</v-btn
                                >
                                <v-btn
                                  text
                                  @click="update"
                                  :disabled="changes == 0 || !valid || saving"
                                  color="success"
                                  >Save Changes</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-tab-item>

                          <v-tab-item key="groups">
                            <v-card flat class="ma-4">
                              <sr-group-widget
                                class="ma-4"
                                :bordered="false"
                                asTable
                                showAll
                                :key="selectedGroup"
                                title="If added, the framework will be available to these groups:"
                                :hideToolbar="false"
                                :allowFilter="false"
                                :frameworkId="framework.id"
                                :headers="[
                                  {
                                    text: 'Name',
                                    align: 'start',
                                    sortable: true,
                                    value: 'displayName',
                                  },
                                  {
                                    text: 'Type',
                                    align: 'center',
                                    value: 'groupType',
                                  },
                                ]"
                                :filterValues="{
                                  selectedGroupTypes: [],
                                  selectedParents: [item.groupId],
                                }"
                              >
                              </sr-group-widget>
                            </v-card>
                          </v-tab-item>
                          <v-tab-item key="history">
                            <v-card flat class="ma-4">
                              <v-data-table
                                :headers="historyHeaders"
                                :items="getHistory(item.history)"
                              >
                                <template v-slot:item.delete="{ item }">
                                  <v-btn
                                    v-if="item.permissions.write"
                                    color="error"
                                    small
                                    @click="deleteRubric(item.id)"
                                  >
                                    Delete
                                  </v-btn>
                                </template>
                                <template v-slot:item.created="{ item }">
                                  {{ item.created | moment("DD MMMM YYYY") }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                  <v-btn
                                    v-if="item.permissions.write"
                                    small
                                    @click="updateRubric(item.id)"
                                  >
                                    Update
                                  </v-btn>
                                </template>
                              </v-data-table>
                            </v-card>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-card>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reset" :disabled="changes == 0 || saving" color="warning"
          >reset</v-btn
        >
        <v-btn
          text
          @click="update"
          :disabled="changes == 0 || !valid || saving"
          color="success"
          >Save Changes</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "FrameworkAccess",
  data() {
    return {
      nodeSearch: null,
      groupChange: 0,
      editableSettings: null,
      changes: 0,
      saving: false,
      tab: "permissions",
      valid: false,
      resetCount: 0,
      selectedGroup: null,
      rubricPermissions: [
        "createRubrics",
        "removeRubricObjectives",
        "removeRubricBands",
        "removeRubricUnits",
        "addRubricObjectives",
        "addRubricBands",
        "addRubricUnits",
        "changeRubricLevelText",
        "changeRubricMetadata",
        "changeRubricUnitMetadata",
      ],
      libraryHeaders: [
        { text: "Node", value: "groupId" },
        { text: "Visible", value: "access", align: "right", width: "50px" },
        { text: "", value: "data-table-expand" },
      ],
      historyHeaders: [
        { text: "delete", value: "delete", width: "50px" },
        { text: "Title", value: "title" },
        { text: "Created", value: "created" },
        { text: "", value: "actions" },
      ],
    };
  },
  created() {
    this.reset();
  },
  computed: {
    groups() {
      return this.$store.getters["saved/getAll"]("groups")
        .filter((g) => {
          return !this.nodeSearch || this.nodeSearch.length == 0
            ? true
            : g.displayText.toLowerCase().includes(this.nodeSearch.toLowerCase()) ||
                (g.internalId &&
                  g.internalId.toLowerCase().includes(this.nodeSearch.toLowerCase()));
        })
        .map((g) => {
          return {
            id: g.id,
            internalId: g.internalId,
          };
        });
    },
    library() {
      return this.editableSettings.library;
    },
    allRubrics() {
      return this.$store.getters["saved/rubricsByFramework"](this.framework.id);
    },
    libraryKey() {
      return this.editableSettings.library.map((x) => x.id).join("");
    },
  },
  methods: {
    createRubric(item) {
      var data = {
        rubricPermissions: item.rubricPermissions,
        groupId: item.groupId,
        acces: item.access,
        framework: {
          id: this.framework.id,
        },
      };
      this.saving = true;
      this.$post("rubrics/copy", data).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("framework"))
          );
        } else {
          //here we want to get the specific saved rubric and add it to the store first.
          this.$store.dispatch("saved/get_rubrics");
          this.changes = 0;
          this.$emit("update", "Changes Saved");
          var route = {
            name: "RubricHome",
            params: {
              rubricid: response.id,
            },
          };
          console.log(route);

          //  this.$store.dispatch('assessments/init');
          this.$router.push(route);
          //this.$router.push({ path: `/rubrics/${response.id}` });
        }
        // this.saving = false;
      });
    },
    getHistory(hist) {
      return hist
        .map((h) => {
          var rubric = this.$store.getters["saved/get"](["rubrics", h.rubricId]);
          return rubric ? rubric : null;
        })
        .filter((x) => x);
    },
    getRubricNodes(id) {
      var rubric = this.$store.getters["saved/get"](["rubrics", id]);
      return rubric ? rubric : null;
    },
    itemClass(item) {
      return item.opaque ? "no-hover-row" : "";
    },
    showExpand(item) {
      return !item.opaque;
    },
    change() {
      this.changes++;
    },
    groupRubrics(groupId) {
      return this.allRubrics.length
        ? this.allRubrics.filter((x) => x.nodes.includes(groupId)).map((x) => x.id)
        : [];
    },
    reset() {
      if (this.editableSettings != null) {
        this.editableSettings = null;
      }
      this.editableSettings = {};
      var settings = {
        id: this.framework.id,
        bannedUsers: this.framework.bannedUsers,
        bannedGroups: this.framework.bannedGroups,
        library: this.framework.library.map((l) => {
          l.opaque = !this.$store.getters["saved/get"](["groups", l.groupId]);

          return l;
        }),
      };
      Object.assign(this.editableSettings, settings);
      this.resetCount++;
      if (this.framework.isDraft) {
        this.libraryHeaders.splice(2, 0, {
          text: "Rubric",
          value: "rubric",
          align: "right",
          width: "50px",
        });
      }
    },
    /* toggleAccess(groupId) {
          /* var g = this.editableSettings.library.find(x=>x.groupId == groupId);
           console.log(g.access);
           debugger;
           var val = false; 
           if(!g.access){
               val = true; 
           }
           g.access = val;
           console.log(g.access)
          
           this.change();
        },
        */
    deleteGroup(groupId) {
      this.editableSettings.library = this.editableSettings.filter(
        (x) => x.groupId != groupId
      );
      this.change();
    },
    addGroup() {
      var group = {
        groupId: this.selectedGroup,
        access: true,
        history: [],
        rubricPermissions: {
          nodeAdmin: {
            createRubrics: true,
            removeRubricObjectives: true,
            removeRubricBands: true,
            removeRubricUnits: true,
            addRubricObjectives: false,
            addRubricBands: false,
            addRubricUnits: false,
            changeRubricLevelText: true,
            changeRubricMetadata: true,
          },
          childAdmin: {
            createRubrics: true,
            removeRubricObjectives: false,
            removeRubricBands: false,
            removeRubricUnits: false,
            addRubricObjectives: false,
            addRubricBands: false,
            addRubricUnits: false,
            changeRubricLevelText: false,
            changeRubricMetadata: false,
          },
        },
      };
      this.editableSettings.library.push(group);
      this.selectedGroup = null;
      this.groupChange++;
      this.change();
    },

    update() {
      this.saving = true;
      var data = this.editableSettings;
      data.secret = this.framework.secret;
      this.$post("update/framework/access", data).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("framework"))
          );
        } else {
          //  this.$store.dispatch('saved/get_frameworks')
          this.changes = 0;
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
      });
    },
  },
  props: {
    framework: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
