<template>
  <v-container>
    <v-row>
      <v-col>
        <sr-open-assessments
          :frameworkId="framework.id"
          :title="title"
          hideFilter
          routeName="AssessmentHome"
          :routeProps="{ query: { frameworkId: framework.id } }"
          :headers="headers"
        >
        </sr-open-assessments>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FrameworkAssessments",
  computed: {
    title() {
      return this.framework
        ? `${this.framework.title} ${this.$getTerm("Assessment")}`
        : "Assessment";
    },
    headers() {
      return [
        {
          text: "Date",
          value: "created",
          sortable: true,
        },
        {
          text: "Title",
          value: "assessmentTitle",
          sortable: true,
        },
        /* {
                        text: 'Rubric',
                        value: 'rubricTitle'
                    },
                    */
        {
          text: "Group",
          value: "groupId",
        },
        {
          text: "",
          value: "actions",
        },
      ];
    },
  },
  props: {
    framework: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
