<template>
  <v-card>
    <v-toolbar flat extension-height="350" v-if="!hideToolbar">
      <div
        :class="{
          'grey--text': !app_info.colorfulHeadings,
          'secondary--text': app_info.colorfulHeadings,
        }"
        class="text-subtitle"
      >
        {{ title | pluralTerm }}
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        :hide-details="true"
        label="Search"
        @keyup.enter="getDataFromApi()"
      >
        <template v-slot:append>
          <v-icon v-show="!search || !search.length">mdi-magnify</v-icon>
          <v-icon v-show="search && search.length">mdi-keyboard-return</v-icon>
        </template>
      </v-text-field>

      <v-btn
        v-if="allowFilter && !hideFilter"
        icon
        @click="showExtension = !showExtension"
      >
        <v-icon v-show="!showExtension">mdi-chevron-down</v-icon>
        <v-icon v-show="showExtension">mdi-chevron-up</v-icon>
      </v-btn>

      <template v-slot:extension v-if="showExtension && allowFilter">
        <v-row :key="changes">
          <v-col>
            <v-card fluid flat class="mb-3">
              <v-card-text>
                <v-row>
                  <v-col cols="3" class="text-right">
                    <span>Show groups inside:</span>
                  </v-col>
                  <v-col v-if="!groupId">
                    <v-select
                      :disabled="!groupReady"
                      label="All groups..."
                      multiple
                      :items="groupItems"
                      clearable
                      deletable-chips
                      dense
                      item-value="value"
                      @input="updateGroups"
                      @change="includeChildGroups = false"
                      v-model="localQuery.groups"
                      :hint="!groupReady ? 'Loading options...' : ''"
                      :persistent-hint="!groupReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model="groupSearch"
                              append-icon="mdi-magnify"
                              label="Search Groups"
                              clearable
                              single-line
                              hide-details
                              :disabled="!groupReady"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <sr-group-chip
                              selectOption
                              :id="item.value"
                              small
                            ></sr-group-chip>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <sr-group-chip
                          selectOption
                          :id="item.value"
                          small
                        ></sr-group-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row v-if="!groupType">
                  <v-col>
                    <v-select
                      v-if="groupTypeItems"
                      :disabled="!groupReady"
                      label="All group types..."
                      multiple
                      :items="groupTypeItems"
                      clearable
                      deletable-chips
                      dense
                      item-value="value"
                      v-model="localQuery.groupTypes"
                      :hint="!groupTypeReady ? 'Loading options...' : ''"
                      :persistent-hint="!groupTypeReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model="groupTypeSearch"
                              append-icon="mdi-magnify"
                              label="Search Group Types"
                              clearable
                              single-line
                              hide-details
                              :disabled="!groupTypeReady"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.name }}
                      </template>

                      <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            {{ item.name }}
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="showOnlyAdmin.checked"
                      @change="updateIndeterminate(showOnlyAdmin)"
                      :indeterminate="showOnlyAdmin.indeterminate"
                      :label="showOnlyAdmin.name"
                      persistent-hint
                      :hint="
                        showOnlyAdmin.indeterminate
                          ? `Both admin and non admin ${getTerm(
                              'group',
                              true
                            )} will be retrieved`
                          : `Only ${!showOnlyAdmin.checked ? 'non' : ''} admin ${getTerm(
                              'group',
                              true
                            )} will be retrieved`
                      "
                    >
                      <template v-slot:append>
                        <v-btn
                          v-if="!showOnlyAdmin.indeterminate"
                          small
                          text
                          color="primary"
                          @click="
                            showOnlyAdmin.indeterminate = !showOnlyAdmin.indeterminate
                          "
                          >Show All</v-btn
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="showOnlyDirect.checked"
                      @change="updateIndeterminate(showOnlyDirect)"
                      :indeterminate="showOnlyDirect.indeterminate"
                      :label="showOnlyDirect.name"
                      persistent-hint
                      :hint="
                        showOnlyDirect.indeterminate
                          ? `Both direct and indirect ${getTerm(
                              'group',
                              true
                            )} will be retrieved`
                          : `Only ${
                              !showOnlyDirect.checked ? 'non' : ''
                            } direct ${getTerm('group', true)} will be retrieved`
                      "
                    >
                      <template v-slot:append>
                        <v-btn
                          v-if="!showOnlyDirect.indeterminate"
                          small
                          text
                          color="primary"
                          @click="
                            showOnlyDirect.indeterminate = !showOnlyDirect.indeterminate
                          "
                          >Show All</v-btn
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="resetFilters" color="error">Reset Filters</v-btn>
                <v-btn text @click="getDataFromApi(true)" color="primary">Search</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :value="selectedGroups"
      @input="update"
      sort-by="name"
      :sort-desc="true"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
      class="elevation-1"
      :show-select="selectable && totalItems <= 100"
    >
      <template v-slot:item.groupType="{ item }">
        <sr-group-path
          :excludeType="false"
          :self="{
            id: item.id,
            name: item.displayText,
            groupType: item.groupTypeName,
          }"
          :path="item.path"
        ></sr-group-path>
      </template>

      <template v-slot:item.displayText="{ item }">
        <div class="d-flex align-center">
          <v-badge
            bordered
            top
            color="secondary"
            offset-x="10"
            :content="item.directAdmin ? 'A' : ''"
            :value="item.write"
            :dot="item.write && !item.directAdmin"
            offset-y="10"
          >
            <v-avatar :color="item.colour" size="30">
              <span class="white--text" v-html="item.displayText[0]"></span>
            </v-avatar>
          </v-badge>
          <v-card-subtitle class="ml-3" v-html="item.displayText"></v-card-subtitle>
        </div>
      </template>
      <template v-slot:item.tags="{ item }">
        <v-chip class="mr-2" v-for="(t, i) in item.tags" :key="i">{{ t }}</v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn v-if="!item.opaque" icon @click="goToGroup(item, false)">
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      search: "",
      groupTypeSearch: null,
      changes: 0,
      showExtension: false,
      groupSearch: null,
      localQuery: {},
      includeChildGroups: false,
      showOnlyDirect: {
        name: "Show Direct Groups?",
        checked: true,
        indeterminate: false,
      },
      showOnlyAdmin: {
        name: "Show Admin Groups?",
        checked: false,
        indeterminate: true,
      },
    };
  },
  watch: {
    options: {
      handler(nv, ov) {
        if (ov.page != nv.page || ov.itemsPerPage != nv.itemsPerPage) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    /* changes() {
    //  this.getFrameworkItems();
    // this.getRubricItems();
    },

    frameworkReady() {
   //   this.getFrameworkItems();
    },
    rubricReady() {
      this.getRubricItems();
    },
    */
  },
  created() {
    this.init();
  },
  methods: {
    getGroupType(id) {
      return this.groupTypeItems.find((gt) => (gt.value = id));
    },
    getItem(id) {
      return this.$store.getters["saved/get"](["groups", id]);
    },
    resetFilters() {
      this.init();
    },
    init() {
      this.localQuery = {};
      Object.assign(this.localQuery, this.query);
      if (this.groupId) {
        this.localQuery.parents = [this.groupId];
        this.includeChildGroups = true;
      }
      if (this.groupType) {
        this.localQuery.groupTypes = [this.groupType];
      }
      this.showOnlyDirect.checked =
        this.localQuery.showOnlyDirect && !this.localQuery.showOnlyNonDirect;
      this.showOnlyDirect.indeterminate =
        (!this.localQuery.showOnlyDirect && !this.localQuery.showOnlyNonDirect) ||
        (this.localQuery.showOnlyDirect && this.localQuery.showOnlyNonDirect);

      this.showOnlyAdmin.checked =
        this.localQuery.showOnlyAdmin && !this.localQuery.showOnlyNonAdmin;
      this.showOnlyAdmin.indeterminate =
        (!this.localQuery.showOnlyAdmin && !this.localQuery.showOnlyNonAdmin) ||
        (this.localQuery.showOnlyAdmin && this.localQuery.showOnlyNonAdmin);
    },
    updateIndeterminate(e) {
      e.indeterminate = false;
    },
    update(e) {
      this.$emit("input", e);
    },
    updateGroups() {
      console.log("blur");
      this.includeChildGroups = false;
      this.changes++;

      console.log(this.localQuery.groups);
    },
    updateParents(includeChildGroups) {
      console.log(includeChildGroups);
    },
    getRubricItems(id) {
      var item = this.$store.getters["saved/get"](["groups", id]);
      return item ? item : null;
    },
    getDataFromApi(updatedQuery) {
      debugger;
      this.loading = true;
      var query = {};
      if (this.includeChildGroups == true) {
        this.localQuery.parents = this.localQuery.groups;
      } else if (!this.includeChildGroups) {
        this.localQuery.parents = null;
        this.localQuery.parents = [];
      }
      Object.assign(query, this.localQuery);

      query.showOnlyAdmin =
        this.showOnlyAdmin.checked && !this.showOnlyAdmin.indeterminate;
      query.showOnlyNonAdmin =
        !this.showOnlyAdmin.checked && !this.showOnlyAdmin.indeterminate;
      query.showOnlyDirect =
        this.showOnlyDirect.checked && !this.showOnlyDirect.indeterminate;
      query.showOnlyNonDirect =
        !this.showOnlyDirect.checked && !this.showOnlyDirect.indeterminate;
      if (updatedQuery) {
        this.options.page = 1;
      }
      console.log(query);
      debugger;
      var options = {
        pageNumber: this.options.page - 1,
        pageSize: this.options.itemsPerPage,
        search: this.search,
        query: query,
        sortField: this.options.sortBy[0] ? this.options.sortBy[0] : "displayText",
        sortDirection: this.options.sortDesc[0] ? "descending" : "ascending",
      };
      console.log(options);
      this.$post(this.url, options).then((data) => {
        debugger;

        if (!data.error) {
          this.items = data.items.map((d) => {
            var gt = this.$store.getters["saved/get"](["groupTypes", d.groupType]);
            var item = this.$store.getters["saved/get"](["groups", d.id]);
            return {
              ...d,
              groupTypeName: gt ? gt.name : "",
              path: item ? item.path : [],
            };
          });
          this.totalItems = data.totalItems > 0 ? data.totalItems : data.items.length;
        }
        this.loading = false;
      });
    },
  },
  computed: {
    groupTypeReady() {
      return this.$store.getters["saved/status"]("groupTypes") == "complete";
    },
    groupTypeItems() {
      var groupTypes = this.$store.getters["saved/getAll"]("groupTypes");
      return groupTypes.map((gt) => {
        return {
          name: gt.name,
          value: gt.id,
        };
      });
    },
    groupReady() {
      return this.$store.getters["saved/status"]("groups") == "complete";
    },
    groupItems() {
      var groups = this.$store.getters["saved/getAll"]("groups");
      return groups
        .map((group) => {
          debugger;
          var paths = group
            ? group.path.map((g) => {
                var parents = g.groups
                  ? g.groups.map((gp) => {
                      var parent = this.$store.getters["saved/get"](["groups", gp]);
                      return parent ? `${parent.displayText} ${parent.internalId}` : "";
                    })
                  : [];
                return parents;
              })
            : [];
          // var count = this.rawAssessments.filter(x=>x.groupId == group.id);
          return {
            text: group.displayText,
            value: group.id,
            path: paths.join(" "),
            member: group.directMember,
            admin: group.directAdmin,
            //    hasCount: count.length != 0
          };
        })
        .filter((x) => {
          if (this.groupSearch && this.groupSearch.length != 0) {
            return (x.text.toLowerCase() + x.path.toLowerCase()).includes(
              this.groupSearch.toLowerCase()
            );
          } else {
            return x;
          }
        });
    },
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: "GroupHome",
    },
    routeProps: {
      type: Object,
    },
    title: {
      type: String,
      default: "Group",
    },
    groupId: {
      type: String,
    },
    groupType: {
      type: String,
    },
    url: {
      type: String,
      default: "saved/groups/search",
    },
    selectedGroups: {
      type: Array,
      default: () => [],
    },
    archived: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    query: {
      type: Object,
      default: () => {
        return {
          groups: [],
          groupTypes: [],
          parents: [],
          showOnlyDirect: false,
          showOnlyNonDirect: false,
          showOnlyAdmin: false,
          showOnlyNonAdmin: false,
          tags: [],
          individuals: [],
          matchAny: false,
          tagMatchAny: true,
          getNode: false,
        };
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "displayText",
          },
          {
            text: "Type",
            align: "center",
            value: "groupType",
          },
          {
            text: "",
            align: "center",
            value: "action",
          },
        ];
      },
    },
  },
};
</script>
