<template>
  <v-card :loading="loading" flat v-if="availableIds.length || unavailableIds.length">
    <v-card-title v-if="$vuetify.breakpoint.mdAndUp"
      >Duplicate {{ "Assessment" | pluralTerm }}</v-card-title
    >
    <v-card-text>
      <sr-available-ids
        entity="Assessment"
        label="Duplicate"
        :availableIds="availableIds"
        :unavailableIds="unavailableIds"
        :assessments="assessments"
      ></sr-available-ids>
    </v-card-text>
    <v-card-text>
      {{ timeLabel }}

      <v-form v-model="valid" v-if="availableIds.length" :disabled="loading">
        <v-row>
          <v-col class="shrink mt-3">
            <span>Title: </span>
          </v-col>
          <v-col>
            <v-text-field outlined dense :label="`Find`" v-model="find" @input="change">
            </v-text-field>
          </v-col>
          <v-col class="shrink mt-3">
            <span>with </span>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              :label="`Replace`"
              v-model="replace"
              @input="change"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <small>Sample Title: {{ sampleTitle }}</small>
          </v-col>
        </v-row>
        <v-row align-content="start">
          <v-col>
            <v-combobox
              outlined
              dense
              hide-no-data
              single-line
              small-chips
              v-model="tags"
              chips
              clearable
              label="Tags"
              @input="change"
              multiple
              append-icon=""
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  dark
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="copyMarks"
              :label="`Copy marks, comments and evidence`"
            ></v-checkbox>
            <v-checkbox v-model="locked" :label="`Lock new assessments`"></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
      <sr-not-found icon="fad fa-exclamation" color="info" v-else>
        <template v-slot:description
          >No duplicate options for this selection of
          {{ "assessment" | pluralTerm }}.</template
        >
        <template v-slot:body>
          Please ensure that you have selected {{ "assessment" | pluralTerm }} belonging
          to {{ "group" | pluralTerm }} that you have
          <strong>write:documents</strong>access to.
        </template>
      </sr-not-found>
    </v-card-text>
    <v-card-actions v-if="availableIds.length">
      <v-spacer></v-spacer>
      <v-btn text @click="reset" :disabled="changes == 0 || loading" color="warning"
        >reset</v-btn
      >
      <v-btn
        text
        @click="update"
        :disabled="changes == 0 || !valid || loading"
        color="success"
        >Duplicate {{ "assessment" | pluralTerm }}</v-btn
      >
    </v-card-actions>
    <v-card v-if="result">
      {{ result }}
    </v-card>
  </v-card>
  <v-card-text v-else class="text--center d-flex align-content-stretch">
    <em>Please select some {{ "assessment" | pluralTerm }} first</em>
  </v-card-text>
</template>

<script>
import srAvailableIds from "./AvailableIds.vue";

export default {
  name: "DuplicateAssessments",
  components: {
    srAvailableIds,
  },
  data() {
    return {
      find: "",
      replace: "",
      tags: [],
      copyMarks: true,
      valid: false,
      changes: 0,
      saving: false,
      showSelected: false,
      error: null,
      loading: false,
      result: null,
      locked: false,
    };
  },
  methods: {
    change() {
      this.changes = this.changes + 1;
    },
    reset() {
      this.changes = 0;
      this.find = "";
      this.tags = null;
      this.tags = [];
      this.replace = "";
      this.copyMarks = true;
      this.locked = false;
    },
    removeTag(item) {
      debugger;
      var i = this.tags.indexOf(item);
      this.tags.splice(i, 1);
      this.tags = [...this.tags];
    },
    update() {
      if (this.valid) {
        this.loading = true;
        var data = {
          assessments: this.availableIds,
          tags: this.tags,
          find: this.find,
          replace: this.replace,
          copyMarks: this.copyMarks,
          locked: this.locked,
          archived: false,
        };
        this.$post("assessments/duplicate", data).then((response) => {
          debugger;
          if (response.error) {
            this.error = response.text;
          } else {
            this.result = response;
            // this.$store.dispatch("saved/get_assessments");
            this.reset();
          }
          this.loading = false;
        });
      }
    },
  },
  computed: {
    timeLabel() {
      var result = this.availableIds.length / 10;
      var label = result < 1 ? "two" : Math.round(result);
      return `This process may take up to ${label} minutes`;
    },
    sampleTitle() {
      if (this.availableIds.length) {
        var assessment = this.assessments.find((x) => x.id == this.availableIds[0]);
        return assessment && assessment.assessmentTitle
          ? assessment.assessmentTitle.replace(this.find, this.replace)
          : "No assessments selected";
      }
      return "No assessments selected";
    },
  },
  props: {
    unavailableIds: {
      type: Array,
      default: () => [],
    },
    availableIds: {
      type: Array,
      default: () => [],
    },
    assessments: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
