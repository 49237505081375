/* eslint-disable */

import { curry } from "ramda";

function hasChildren(node) {
  //  var isObject = typeof node === "object";
    var hasChildren = typeof node === "object" && node.subgroups && node.subgroups.length != 0;
  return (
        hasChildren
  );
}
var sourceItems =[];
const Tree = {
  reduce: curry(function reduce(reducerFn, init , node) {
    // Calculate the reduced value for this node.
    
    const acc = reducerFn(init, node);

    if (!hasChildren(node)) {
      return acc;
    }
    // if children
    return node.subgroups.reduce(Tree.reduce(reducerFn), acc);
  }),

  map: curry(function map(mapFn, node) {
   
    const newNode = mapFn(node);

    if (newNode ) {
      //  var children = [];
        if(hasChildren(newNode)){
            var g = newNode.subgroups.map(Tree.map(mapFn));
            newNode.subgroups = g.filter(x=>{
              return typeof x =="object"
            })
       }
        return newNode;
    }
}),
};

function flattenToArray(arr, { subgroups, ...data }) {
  //  console.log(arr);

  return arr.concat([{ ...data }]);
}
function getItem(id){
    var item = sourceItems.find((x) => x.id == id);
    if(item){
      if(!item.subgroups){
        item.subgroups = [];
      }
      return item;
    }
    return null;
}
function slim(item){
  if(item){
    var gName = item.metadata ? item.metadata.name : item.name;
    var gColor = item.metadata ? item.metadata.primaryColor : null;
  
    var mappedItem = {
      id: item.id,
      name: gName,
      color: gColor,
      subgroups: item.subgroups
    };
  return mappedItem;
  }
  return {
    id: null,
    name: null,
    color: null,
    subgroups: []
  };
}


const getProps = function(path, context){
  
  var context = context || this;
      path = path.split('.');
  
  
  for ( var i = 0; i < path.length; i++ ){
              context = context[path[i]];
      };
  
      return context;
  };
 // var mappedItem = {
 //   id: item.id,
 //   name: gName,
 //   color: gColor,
 //   subgroups: item.subgroups
 // };
//return mappedItem;
//}

function hydrateNode(node) {
  var item = sourceItems.find((x) => x.id == node);
  return {
      ...node,
      item
  };
 // return {
  //   ...node,
    // text: node.text + countstr,
  //  item,
 // };
}

//const hydrateTree = Tree.map(hydrateNode, startingGroup, allItems);

//const flattenedTree = Tree.map(flattenToArray, [], hydratedTree);

const treeHandler = {
    hydrateTree : function (startingItem, allItems) {
        sourceItems = [];
        sourceItems = allItems;
        var hydratedTree = Tree.map(getItem,startingItem.id);
        return hydratedTree;
      },
    flattenTree: function (tree) {
    var flattenedTree = Tree.reduce(flattenToArray,[],tree);
    return flattenedTree;
  },
  mapTree : function(tree){
    
    var slimtree = Tree.map(slim, tree);
    return slimtree ?? null;
  },
}
export default treeHandler;
