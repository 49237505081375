<template>
  <v-card :tile="linky" :loading="loading && !linky" :flat="linky" :key="changes">
    <template slot="progress">
      <v-progress-linear
        style="position: absolute; top: 45px; z-index: 1000"
        color="secondary"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-toolbar color="primary" dark dense flat v-if="!linky">
      <v-toolbar-title>Bookmarks</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn x-small text @click="editMode = true" v-show="!editMode">
          <v-icon>fad fa-pencil</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-btn
      class="mx-2"
      :loading="loading"
      fab
      dark
      style="position: fixed; bottom: 20px; right: 0px; z-index: 1000"
      small
      @click="addBookmark"
      color="primary"
      v-if="linky && !mini"
    >
      <v-icon dark> fad fa-bookmark </v-icon>
    </v-btn>
    <v-list v-if="bookmarks">
      <v-list-item
        dense
        :link="linky"
        exact
        active-class="secondary--text text--darken-2"
        v-for="(item, i) in bookmarks"
        :key="`${item.index}:${editMode[i]}`"
        :to="linky ? item : null"
      >
        <v-list-item-icon v-if="linky && !mini"
          ><v-icon v-show="!isBookmarked(item)">fal fa-bookmark</v-icon>
          <v-icon @click="removeBookmark(item)" v-show="isBookmarked(item)"
            >fad fa-bookmark</v-icon
          ></v-list-item-icon
        >
        <v-list-item-icon v-if="mini">
          <span class="fa-layers fa-fw">
            <v-icon> fad fa-bookmark</v-icon>
            <span class="fa-layers-text white--text" data-fa-transform="shrink-5 up-4">{{
              item.display.trim().charAt(0)
            }}</span>
          </span>
        </v-list-item-icon>
        <v-list-item-action v-else-if="editMode">
          <v-btn @click="popBookmark(item)" icon x-small color="error">
            <v-icon>fas fa-minus-circle</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-title
          ><span v-if="!editMode">{{
            item.displayName ? item.displayName : item.display
          }}</span>
          <v-text-field v-else dense v-model="item.display"></v-text-field>
        </v-list-item-title>
        <v-list-item-action v-show="!linky && !editMode">
          <v-btn icon :to="item">
            <v-icon>fal fa-angle-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider v-if="bookmarks.length"></v-divider>
    <v-card-actions v-if="!linky && editMode">
      <v-spacer></v-spacer>
      <v-btn text @click="editMode = false">Cancel</v-btn>
      <v-btn @click="updateBookmarks" text color="success">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Bookmarks",
  data() {
    return {
      loading: false,
      editMode: false,
      changes: 0,
      bookmarks: [],
    };
  },
  created() {
    this.getBookmarks();
  },

  computed: {
    bookmarked() {
      if (!this.loading) {
        var r = this.$route;
        var exists = this.bookmarks.filter((b) => {
          var nameMatch = b.name == r.name;
          var paramMatch =
            b.params != null
              ? JSON.stringify(b.params) === JSON.stringify(r.params)
              : JSON.stringify(r.params) == {};
          var queryMatch =
            b.query != null ? JSON.stringify(b.query) === JSON.stringify(r.query) : true;
          return nameMatch && paramMatch && queryMatch;
        });
        return exists.length ? true : false;
      }
      return false;
    },
    bookmark() {
      var r = this.$route;

      if (this.bookmarked) {
        var result = this.bookmarks.filter((b) => {
          var nameMatch = b.name == r.name;
          var paramMatch =
            b.params != null
              ? JSON.stringify(b.params) === JSON.stringify(r.params)
              : JSON.stringify(r.params) == {};
          var queryMatch =
            b.query != null ? JSON.stringify(b.query) === JSON.stringify(r.query) : true;
          return nameMatch && paramMatch && queryMatch;
        });
        return result[0];
      } else {
        var route = {
          index: this.bookmarks.length,
          org: this.org_id,
          queries: this.$route.queries,
          params: this.$route.params,
          name: this.$route.name,
          display: this.pageTitle,
        };
        return route;
      }
    },
  },
  methods: {
    isBookmarked(b) {
      if (!this.loading) {
        var r = this.$route;

        var nameMatch = b.name == r.name;
        var paramMatch =
          b.params != null
            ? JSON.stringify(b.params) === JSON.stringify(r.params)
            : JSON.stringify(r.params) == {};
        var queryMatch =
          b.query != null ? JSON.stringify(b.query) === JSON.stringify(r.query) : true;
        return nameMatch && paramMatch && queryMatch;
      }
      return false;
    },
    popBookmark(item) {
      this.bookmarks = this.bookmarks.filter((x) => x != item);
    },
    updateBookmarks() {
      this.loading = true;
      this.$post(
        `entities/individuals/${this.personId}/bookmarks/update`,
        this.bookmarks
      ).then((data) => {
        debugger;
        if (!data.error) {
          this.bookmarks = data;
        }
        this.loading = false;
        this.editMode = false;
        this.changes++;
      });
    },
    addBookmark() {
      this.loading = true;

      this.$post(
        `entities/individuals/${this.personId}/bookmarks/add`,
        this.bookmark
      ).then((response) => {
        debugger;
        if (!response.error) {
          this.bookmarks = response;
        }
        this.loading = false;
        this.changes++;
      });
    },
    removeBookmark(b) {
      this.loading = true;
      this.$post(`entities/individuals/${this.personId}/bookmarks/remove`, b).then(
        (data) => {
          debugger;
          if (!data.error) {
            this.bookmarks = data;
          }
          this.loading = false;
          this.changes++;
        }
      );
    },
    getBookmarks() {
      this.loading = true;
      this.$get(`entities/individuals/${this.personId}/bookmarks`).then((data) => {
        debugger;
        if (!data.error) {
          this.bookmarks = data.data;
        }
        this.loading = false;
        this.changes++;
      });
    },
  },
  props: {
    personId: {
      type: String,
    },
    pageTitle: {
      type: String,
    },
    linky: {
      type: Boolean,
      default: true,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
