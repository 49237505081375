<template>
  <div>
    <v-container v-if="!isDetailView">
      <sr-breadcrumbs :items="breadcrumbs"></sr-breadcrumbs>

      <v-row :key="key">
        <v-col>
          <v-alert dense text type="info" v-if="isAdmin">
            Select {{ title }} to see which batch actions are available ({{
              app_info.short_name
            }}
            {{ "Administrator" | pluralTerm }} only)
          </v-alert>
          <v-card outlined :tile="app_info.tile">
            <sr-group-list
              :hideHeaders="!isAdmin"
              :headers="headers"
              :query="query"
              :groupType="groupType"
              :selectable="isAdmin"
              v-model="selectedGroups"
              @input="updateGroups"
              :key="$route.name"
            >
            </sr-group-list>

            <v-divider v-if="isAdmin && selectedGroups.length"></v-divider>
            <multiple-actions
              v-if="isAdmin && selectedGroups.length"
              :items="selectedGroups"
              :actions="actions"
              url="groups/arr"
            ></multiple-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import MultipleActions from "./../newComponents/MultipleActions.vue";

export default {
  name: "Groups",
  components: {
    MultipleActions,
  },
  data() {
    return {
      selectedGroups: [],
      key: 0,
    };
  },
  methods: {
    updateGroups(e) {
      this.selectedGroups = e;
    },
  },
  watch: {
    route: function () {
      this.key++;
    },
    groupType: function () {
      this.key++;
    },
  },
  computed: {
    breadcrumbs() {
      var breadcrumbs = [
        { text: "Home", to: { name: "Home" } },
        { text: "Groups", to: { name: "AllGroups" } },
      ];
      var title = this.title;
      if (title != null) {
        breadcrumbs[1].exact = true;
        breadcrumbs[1].text = "All Groups";

        breadcrumbs.push({
          text: this.toTitleCase(title),
          to: {
            name: "AllGroups",
            query: { type: this.$route.query.type, title: title },
          },
          disabled: true,
        });
      }
      return breadcrumbs;
    },
    route() {
      return this.$route.path;
    },
    actions() {
      var groups =
        this.selectedGroups.length && this.selectedGroups[0].id
          ? this.selectedGroups.map((x) => x.id)
          : this.selectedGroups
          ? this.selectedGroups
          : [];
      return [
        {
          name: "CreateAssessment",
          title: `Create ${this.$getTerm("Assessment", true)}`,
          component: "sr-batch-assess",
          props: {
            availableIds: [],
            unavailableIds: [],
          },
          data: {
            key: "Docs",
            write: true,
            ids: groups,
          },
        },

        {
          name: "CreateSubGroups",
          title: "Create SubGroups",
          component: "sr-batch-subgroups",
          props: {
            availableIds: [],
            unavailableIds: [],
          },
          data: {
            key: "SubgroupList",
            write: true,
            ids: groups,
          },
        },
      ];
    },
    groupType() {
      return this.$route.query.type;
    },
    title() {
      var title = "groups";
      if (this.$route.query.type) {
        var gt = this.$store.getters["saved/get"](["groupTypes", this.$route.query.type]);
        title =
          this.$route.query.title != null
            ? this.$route.query.title
            : gt != null
            ? this.pluralize(gt.name)
            : null;
      }
      return title.toLowerCase();
    },
    isDetailView() {
      if (this.$route.params.groupid) {
        return true;
      }
      return false;
    },
    filterOptions() {
      var filteredOptions = {
        selectedGroupTypes: [],
        selectedParents: [],
      };
      if (this.$route.query.type) {
        filteredOptions.selectedGroupTypes.push(this.$route.query.type);
      }
      if (this.$route.query.parent) {
        filteredOptions.selectedParents.push(this.$route.query.parent);
      }
      return filteredOptions;
    },
    hideFilter() {
      return this.$route.query.type != null || this.$route.query.parent != null;
    },
    isAdmin() {
      return this.$auth.isAdmin;
    },
    /*  headers(){
            var headers = [ {
                            text: 'Name',
                            align: 'start',
                            sortable: true,
                            value: 'displayName'
                        },
                        {
                            text: '',
                            align: 'center',
                            value: 'action'
                        },
                        ];
                         if(this.$vuetify.breakpoint.lgAndUp){
                             headers.splice(1, 0,    {
                            text: 'Tags',
                            align: 'center',
                            value: 'tags'
                        })
                        }
                        if(!this.$route.query.hidepath){
                            headers.splice(1, 0,  {
                            text: 'Type',
                            align: 'left',
                            value: 'groupType'
                        })
                        };

            return headers;
        }*/
  },
  props: {
    query: {
      type: Object,
      default: () => {
        return {
          groups: [],
          groupTypes: [],
          parents: [],
          showOnlyDirect: false,
          showOnlyNonDirect: false,
          showOnlyAdmin: false,
          showOnlyNonAdmin: false,
          tags: [],
          individuals: [],
          assessments: [],
          matchAny: false,
          tagMatchAny: true,
          getNode: false,
        };
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "displayText",
          },
          {
            text: "Path",
            align: "left",
            value: "groupType",
          },
          {
            text: "Tags",
            align: "center",
            value: "tags",
          },
          {
            text: "R",
            align: "center",
            value: "relevance",
          },
          {
            text: "",
            align: "center",
            value: "action",
          },
        ];
      },
    },
  },
};
</script>
