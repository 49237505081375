<template>
  <v-container fluid>
    <v-row v-if="!person.opaque || $auth.isAdmin">
      <v-col cols="12">
        <v-card :loading="loading" v-if="$auth.isDataManager">
          <v-toolbar dense flat color="primary" dark>
            <v-toolbar-title>Add to Groups</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-align-center pb-0 px-5">
            <sr-group-picker
              class="flex-grow-1 mr-5"
              dense
              width="50%"
              outlined
              multiple
              label="Add to Group"
              :disabled="loading"
              v-model="newGroups"
              :exclude="groupIds"
            >
            </sr-group-picker>
            <v-checkbox
              class="my-1"
              :class="{ 'mx-5': $vuetify.breakpoint.mdAndUp }"
              v-model="newGroupAdmin"
              :disabled="newGroups.length == 0 || loading"
              label="Admin"
            ></v-checkbox>
            <v-btn
              class="my-0 ml-5"
              :icon="$vuetify.breakpoint.smAndDown"
              :class="{ 'mx-5': $vuetify.breakpoint.mdAndUp }"
              text
              color="primary"
              @click="addToGroup"
              :disabled="newGroups.length == 0 || loading"
            >
              <v-icon :left="$vuetify.breakpoint.mdAndUp">fal fa-plus</v-icon>
              <span v-if="$vuetify.breakpoint.mdAndUp">Add to Group</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :md="availableGroups ? '3' : '12'" v-if="$vuetify.breakpoint.mdAndUp">
        <v-list nav>
          <v-list-item-group dense>
            <v-list-item
              dense
              color="secondary"
              v-for="t in groups"
              :key="t.id"
              :class="{ 'elevation-1 my-3': !t.canReadDocs }"
              class="d-flex justify-space-between"
              :to="
                t.canReadDocs
                  ? { name: destination, params: { groupid: t.id } }
                  : { name: 'GroupHome', params: { groupid: t.id } }
              "
              exact
            >
              <v-badge
                :value="isAdmin(t)"
                prepend
                content="A"
                bordered
                left
                inline
                transition="scale-rotate-transition"
              >
                <sr-group-chip
                  :id="t.id"
                  :twoLine="true"
                  selectOption
                  small
                ></sr-group-chip>
              </v-badge>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <div v-if="$vuetify.breakpoint.smAndDown" class="d-flex flex-wrap justify-end">
          <v-menu bottom left offset-y="40">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark color="primary" v-bind="attrs" v-on="on">
                Select Group <v-icon right>fas fa-caret-down</v-icon>
              </v-btn>
            </template>
            <v-list style="max-height: 50vh; overflow-y: auto">
              <v-list-item
                class="ma-2 flex-grow"
                color="secondary"
                v-for="t in groups"
                :key="t.id"
                :to="
                  t.canReadDocs
                    ? { name: destination, params: { groupid: t.id } }
                    : { name: 'GroupHome', params: { groupid: t.id } }
                "
                exact
              >
                <v-badge
                  :value="isAdmin(t)"
                  prepend
                  content="A"
                  bordered
                  left
                  inline
                  transition="scale-rotate-transition"
                >
                  <sr-group-chip
                    :id="t.id"
                    :twoLine="true"
                    selectOption
                    small
                  ></sr-group-chip>
                </v-badge>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <router-view
          v-if="group"
          v-bind="{ person: person, permissions: permissions, groupId: group }"
        ></router-view>
        <sr-not-found
          v-if="!group && availableGroups"
          color="secondary"
          icon="fad fa-list"
        >
          <template v-slot:title>Choose a {{ "Group" | term }}</template>
          <template v-slot:body
            >Select a group from the options
            <span v-if="$vuetify.breakpoint.mdAndUp">to the left</span
            ><span v-else>above</span>.</template
          >
        </sr-not-found>
      </v-col>
    </v-row>
    <sr-not-found
      v-else
      color="error"
      icon="fad fa-lock"
      unauthorized
      :thing="`the groups page for ${getDisplayName(person)}`"
    >
    </sr-not-found>
  </v-container>
</template>

<script>
export default {
  name: "PersonGroups",
  data() {
    return {
      newGroups: [],
      newGroupAdmin: false,
      loading: false,
      groups: [],
    };
  },
  watch: {
    updatedGroupStamp() {
      console.log("groups updated");
      this.init();
    },
  },
  computed: {
    destination() {
      return this.$route.name == "ProfileGroups" ? "ProfileGroup" : "PersonGroup";
    },
    groupIds() {
      return this.groups.map((x) => x.id);
    },
    updatedGroupStamp() {
      return this.$store.getters["saved/updated"]("groups");
    },
    group() {
      return this.$route.params.groupid;
    },
    availableGroups() {
      return this.groups.length
        ? this.$r.clone(this.groups).filter((x) => x.canReadDocs).length > 0
        : false;
    },
    linkText() {
      var text = "Person";
      if (this.$route.name == "ProfileGroups") {
        text = "Profile";
      }
      return text;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.person) {
        this.loading = true;
        this.groups = this.$store.getters["saved/groupsByPerson"](this.person.id).map(
          (x) => {
            return {
              ...x,
              canReadDocs: this.canReadDocs(x),
            };
          }
        );
      } else {
        this.groups = [];
      }
      this.loading = false;
    },
    addToGroup() {
      this.loading = true;

      var memberRequests = this.newGroups.map((g) => {
        return {
          groupId: g,
          admin: this.newGroupAdmin,
          individualId: this.person.id,
        };
      });
      console.log(memberRequests);

      this.$post(`entities/groups/addmembermultiple`, memberRequests).then((response) => {
        debugger;
        if (response.error) {
          console.log(response.error);
          this.$emit("update", response.error);
        } else {
          this.$store.dispatch("saved/get_groups").then(() => {
            //   this.init();
            this.$emit("update", "User added to group");
          });
        }
      });
    },
    isAdmin(group) {
      return this.person ? group.admins.includes(this.person.id) : false;
    },
    canReadDocs(group) {
      var admin = group && !group.readonly;
      var gt = group
        ? this.$store.getters["saved/get"](["groupTypes", group.groupType])
        : null;
      var permission = gt
        ? admin
          ? gt.objectPermissions.admin
          : gt.objectPermissions.member
        : null;
      return permission && this.person
        ? this.person.isSelf
          ? permission.selfDocs.read
          : permission.docs.read
        : false;
    },
  },
  props: {
    person: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
