var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.group && _vm.people && _vm.groupType)?_c('div',[_c('v-card',{attrs:{"flat":_vm.readonly,"outlined":_vm.readonly && !_vm.nav,"loading":_vm.loading}},[(!_vm.readonly)?_c('v-toolbar',{attrs:{"flat":""},scopedSlots:_vm._u([(!_vm.readonly)?{key:"extension",fn:function(){return [_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}:null],null,true)},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm._f("pluralTerm")("Member"))+" ")]),_c('v-spacer'),(!_vm.readonly)?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.showAddNew = !_vm.showAddNew}}},[_c('v-icon',[_vm._v("fad fa-user-plus")])],1):_vm._e(),(!_vm.readonly)?_c('v-btn',{attrs:{"color":"secondary","icon":""},on:{"click":function($event){_vm.showImport = !_vm.showImport}}},[_c('v-icon',[_vm._v("fad fa-file-import")])],1):_vm._e()],1):_vm._e(),(!_vm.readonly)?_c('v-divider'):_vm._e(),(_vm.people.length)?_c('div',[_c('v-list',{attrs:{"nav":_vm.nav}},[_c('v-subheader',{staticClass:"overline"},[_vm._v(_vm._s(_vm._f("pluralize")(_vm.groupType.adminName))+" ")]),_vm._l((_vm.admins),function(m,i){return _c('v-list-item',{key:'admin' + i,attrs:{"dense":_vm.nav}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[(m.member && m.member.avatar)?_c('v-img',{attrs:{"src":m.member.avatar}}):_c('v-icon',[_vm._v("fad fa-user")])],1),_c('v-list-item-content',[(m.member)?_c('v-list-item-title',[_vm._v(_vm._s(m.member.displayText))]):_vm._e()],1),(_vm.group.manageMembers && !_vm.readonly)?_c('span',[(!m.self)?_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-cog")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(m.member)?_c('v-subheader',{staticClass:"overline text-center"},[_vm._v(_vm._s(m.member.displayText))]):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.toggleSuspend(m.individualId)}}},[_c('i',{staticClass:"fal fa-ban error--text mr-3"}),_c('v-list-item-title',[_vm._v("Suspend from "+_vm._s(_vm.group.metadata.name))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.toggleAdmin(m.individualId)}}},[_c('i',{staticClass:"fal fa-arrow-down warning--text mr-3"}),_c('v-list-item-title',[_vm._v("Demote to "+_vm._s(_vm.groupType.memberName))])],1)],1)],1):_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v("fad fa-user ")])],1)],1):_vm._e(),(_vm.permissions.administrators.read || m.self)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","to":{
                name: 'PersonGroup',
                params: { groupid: _vm.group.id, individualid: m.individualId },
              }}},[_c('v-icon',[_vm._v("fal fa-angle-right")])],1)],1):_vm._e()],1)}),(!_vm.admins.length)?_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"font-italic"},[_vm._v("No "+_vm._s(_vm._f("pluralize")(_vm.groupType.adminName))+" "),(_vm.search && _vm.search.length)?_c('span',[_vm._v("found")]):_c('span',[_vm._v("yet")])])],1):_vm._e()],2),(_vm.permissions && _vm.permissions.memberList.read)?_c('v-list',[_c('v-divider'),_c('v-subheader',{staticClass:"overline"},[_vm._v(_vm._s(_vm._f("pluralize")(_vm.groupType.memberName ? _vm.groupType.memberName : "member"))+" ")]),_vm._l((_vm.members),function(m,i){return _c('v-list-item',{key:'member' + i,attrs:{"dense":_vm.nav}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[(m.member && m.member.avatar)?_c('v-img',{attrs:{"src":m.member.avatar}}):_c('v-icon',[_vm._v("fad fa-user")])],1),_c('v-list-item-content',[(m.member)?_c('v-list-item-title',[(m.self)?_c('i',{staticClass:"fad fa-user mr-3"}):_vm._e(),_vm._v(_vm._s(m.member.displayText))]):_vm._e()],1),(_vm.group.manageMembers && !_vm.readonly)?_c('span',[(!m.self)?_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-cog")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(m.member)?_c('v-subheader',{staticClass:"overline text-center"},[_vm._v(_vm._s(m.member.displayText))]):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.toggleSuspend(m.individualId)}}},[_c('i',{staticClass:"fal fa-ban error--text mr-3"}),_c('v-list-item-title',[_vm._v("Suspend from "+_vm._s(_vm.group.metadata.name))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.toggleAdmin(m.individualId)}}},[_c('i',{staticClass:"fal fa-arrow-up warning--text mr-3"}),_c('v-list-item-title',[_vm._v("Promote to "+_vm._s(_vm.groupType.adminName))])],1)],1)],1):_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v("fad fa-user ")])],1)],1):_vm._e(),(_vm.permissions.members.read || m.self)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","to":{
                name: 'PersonGroup',
                params: { groupid: _vm.group.id, individualid: m.individualId },
              }}},[_c('v-icon',[_vm._v("fal fa-angle-right")])],1)],1):_vm._e()],1)}),(!_vm.members.length)?_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"font-italic"},[_vm._v("No "+_vm._s(_vm._f("pluralize")(_vm.groupType.memberName ? _vm.groupType.memberName : "member"))+" "),(_vm.search && _vm.search.length)?_c('span',[_vm._v("found")]):_c('span',[_vm._v("yet")])])],1):_vm._e()],2):_vm._e(),(_vm.permissions && _vm.permissions.memberList.write)?_c('v-btn',{attrs:{"block":"","small":"","outlined":"","color":"light darken-2"},on:{"click":function($event){_vm.showInactive = !_vm.showInactive}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInactive),expression:"showInactive"}]},[_vm._v("Hide Suspended")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showInactive),expression:"!showInactive"}]},[_vm._v("Show Suspended")])]):_vm._e(),(_vm.permissions && _vm.permissions.memberList.write && _vm.showInactive)?_c('v-list',[_c('v-subheader',{staticClass:"overline"},[_vm._v("Suspended ")]),_vm._l((_vm.inactive),function(m,i){return _c('v-list-item',{key:'inactive' + i,attrs:{"dense":_vm.nav}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[(m.member && m.member.avatar)?_c('v-img',{attrs:{"src":m.member.avatar}}):_c('v-icon',[_vm._v("fad fa-user")])],1),_c('v-list-item-content',[(m.member)?_c('v-list-item-title',{staticClass:"text--disabled"},[(m.self)?_c('i',{staticClass:"fad fa-user mr-3"}):_vm._e(),_vm._v(_vm._s(m.member.displayText))]):_vm._e(),(m.admin)?_c('v-list-item-subtitle',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.groupType.adminName))]):_c('v-list-item-subtitle',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.groupType.memberName))])],1),(_vm.group.manageMembers && !_vm.readonly)?_c('span',[(!m.self)?_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fal fa-cog")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(m.member)?_c('v-subheader',{staticClass:"overline text-center"},[_vm._v(_vm._s(m.member.displayText)+" ")]):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.toggleSuspend(m.individualId)}}},[_c('i',{staticClass:"fal fa-plus success--text mr-3"}),_c('v-list-item-title',[_vm._v("Reinstate to "+_vm._s(_vm.group.metadata.name))])],1)],1)],1):_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v("fad fa-user ")])],1)],1):_vm._e(),(m.self)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","to":{
                name: 'PersonGroup',
                params: { groupid: _vm.group.id, individualid: m.individualId },
              }}},[_c('v-icon',[_vm._v("fal fa-angle-right")])],1)],1):_vm._e()],1)}),(!_vm.inactive.length)?_c('v-list-item',[_c('v-list-item-subtitle',{staticClass:"font-italic"},[_vm._v("No suspended members "),(_vm.search && _vm.search.length)?_c('span',[_vm._v("found")]):_vm._e()])],1):_vm._e()],2):_vm._e()],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"75vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"hide-overlay":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showAddNew),callback:function ($$v) {_vm.showAddNew=$$v},expression:"showAddNew"}},[_c('v-card',{attrs:{"tile":_vm.$vuetify.breakpoint.smOnly}},[_c('sr-add-member',{attrs:{"group":_vm.group,"loading":_vm.loading,"groupType":_vm.groupType},on:{"update":_vm.update,"addExisting":_vm.addExistingMember,"cancel":_vm.toggleShowAddNew}})],1)],1),_c('v-dialog',{attrs:{"max-width":"75vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"hide-overlay":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showImport),callback:function ($$v) {_vm.showImport=$$v},expression:"showImport"}},[_c('v-card',[_c('sr-csv-import',{attrs:{"loading":_vm.loading,"group":_vm.group.id,"groupType":_vm.groupType},on:{"import":_vm.importMembers}})],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarStatus,"top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }