<template>
  <v-row
    v-if="$auth.isDataManager && !person.isSelf && !person.readonly && !person.opaque"
  >
    <v-col>
      <v-card class="mb-5" :tile="app_info.tile" :loading="saving" v-if="isUser">
        <v-toolbar flat dense>Manage Roles</v-toolbar>
        <v-divider v-if="roles && roles.length != 0"></v-divider>
        <v-list v-if="!roleError">
          <v-list-item v-for="r in roles" :key="r.id">
            <v-list-item-content>
              <v-list-item-title>{{ r.name }}</v-list-item-title>
              <small>{{ r.description }}</small>
            </v-list-item-content>
            <v-list-item-action
              ><v-btn :disabled="saving" color="error" @click="removeRole(r.id)" icon
                ><i class="fal fa-times"></i></v-btn
            ></v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-select
                  :key="newRole != null"
                  dense
                  outlined
                  label="Add Role"
                  :items="filteredRoleOptions"
                  item-text="name"
                  item-value="id"
                  :hint="newRole ? newRole.description : null"
                  return-object
                  v-model="newRole"
                  :disabled="saving"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      @click="addRole"
                      icon
                      color="success"
                      :disabled="!newRole || saving"
                    >
                      <v-icon>fal fa-plus</v-icon>
                    </v-btn>
                  </template>
                </v-select>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text class="error--text" v-if="roleError">
          Unable to retrieve roles for {{ getDisplayName(person) }} because
          {{ getPronoun(person.preferredPronouns, "nominative") }} are not connected to
          the {{ app_info.name }} organization on Auth0.
          <br />
          You should delete
          {{ getPronoun(person.preferredPronouns, "possessivePronominal") }} User Account
          and re-invite {{ getPronoun(person.preferredPronouns, "accusative") }}.
        </v-card-text>
      </v-card>

      <div flat v-else>
        <sr-not-found
          :outlined="false"
          flat
          :icon="invitation.length != 0 ? 'fad fa-user-clock' : 'fad fa-user-slash'"
        >
          <template v-slot:title>
            <span v-if="invitation.length != 0"> Roles pending </span>
            <span v-else>No User Account </span>
          </template>
          <template v-slot:body>
            <span v-if="invitation.length != 0">
              {{ getDisplayName(person) }} has not yet accepted
              {{ getPronoun(person.preferredPronouns, "possessivePronominal") }}
              invitation to join {{ app_info.name }}. Once
              {{ getPronoun(person.preferredPronouns, "nominative") }} do, you will be
              able to edit roles.
            </span>
            <span v-else>
              {{ getDisplayName(person) }} does not have a User Account. You can invite
              {{ getPronoun(person.preferredPronouns, "accusative") }} to join
              {{ app_info.name }} using the form below.
            </span>
          </template>
        </sr-not-found>
      </div>

      <div v-if="invitation.length != 0">
        <v-card-title v-if="invitation">Open Invitations</v-card-title>

        <v-card
          :loading="savingInvite"
          :tile="app_info.tile"
          :color="savingInvite ? 'grey' : expired(i.expiresAt) ? 'error' : 'secondary'"
          class="pl-5"
          v-for="(i, ii) in invitation"
          :key="ii"
        >
          <v-simple-table
            style="
              border-top-left-radius: 0px;
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
              padding-top: 10px;
            "
            dense
          >
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Created At</th>
                  <td>{{ i.createdAt | moment("DD MMM YYYY hh:mm") }}</td>
                </tr>
                <tr>
                  <th>
                    Expire<span v-if="expired(i.expiresAt)">d</span><span v-else>s</span>
                  </th>
                  <td :class="{ 'error--text': expired(i.expiresAt) }">
                    {{ i.expiresAt | moment("DD MMM YYYY hh:mm") }}
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ i.invitee.email }}</td>
                </tr>
                <tr>
                  <th>Invited By</th>
                  <th>{{ i.inviter.name }}</th>
                </tr>
                <tr>
                  <th>Link</th>
                  <td>
                    <a :href="i.invitationUrl" target="_blank">{{ i.invitationUrl }}</a>
                  </td>
                </tr>
                <tr>
                  <th>Roles</th>
                  <td>
                    <v-list color="transparent" dense tile style="max-width: 100%">
                      <v-list-item
                        class="no-padder"
                        v-for="role in roleList(i.roles)"
                        :key="role.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ role.name }}</v-list-item-title>
                          <small>{{ role.description }}</small>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions style="border-bottom-left-radius: 0px" class="white">
            <v-spacer></v-spacer>
            <v-btn text color="error" :disabled="savingInvite" @click="cancelInvite(i)"
              ><span v-if="expired(i.expiresAt)">Delete</span
              ><span v-else>Revoke</span></v-btn
            >

            <v-btn
              text
              v-if="!expired(i.expiresAt)"
              :disabled="savingInvite"
              color="success"
              @click="invite()"
              >Send New</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
      <div v-if="invitation.length == 0 && !isUser">
        <v-card :key="changes">
          <v-card-subtitle class="overline"
            >Invite {{ getDisplayName(person) }} to {{ app_info.name }}</v-card-subtitle
          >
          <v-card-text>
            <p>
              When {{ getDisplayName(person) }} <b>clicks the invite link</b> (this will
              be in the email if you have elected to email it to
              {{ getPronoun(person.preferredPronouns, "accusative") }}, otherwise you can
              copy it from the table below),
              {{ getPronoun(person.preferredPronouns, "possessiveProniminal") }} user
              account will be connected.
            </p>
            <v-form v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required, rules.email]"
                    :disabled="savingInvite"
                    v-model="request.email"
                    dense
                    outlined
                    type="email"
                    :label="`${$getTerm('Email', false)}*`"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    item-text="name"
                    item-value="id"
                    :disabled="savingInvite"
                    hint="In order to interact with this application, the user must have 'User' role."
                    dense
                    outlined
                    multiple
                    label="Roles"
                    :items="roleOptions"
                    v-model="request.roles"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :disabled="savingInvite"
                    :rules="[rules.required]"
                    v-model="request.inviterName"
                    dense
                    outlined
                    label="Invited By*"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-switch
                    dense
                    class="mt-1"
                    label="Send Invitation by Email"
                    hint=""
                    :disabled="savingInvite"
                    v-model="request.sendEmail"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="app_info.connectionNames.length > 1">
                <v-select
                  :disabled="savingInvite"
                  dense
                  outlined
                  label="Roles"
                  :items="app_info.connectionNames"
                  v-model="request.connectionName"
                >
                </v-select>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="invite" :disabled="!valid || savingInvite" color="primary"
              >Invite to {{ app_info.name }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </v-col>
    <v-col cols="12" sm="6" md="5" lg="4">
      <v-card :tile="app_info.tile">
        <v-toolbar color="" flat dense>Advanced Settings</v-toolbar>
        <v-divider></v-divider>
        <div v-if="isUser">
          <v-card-subtitle class="overline">Delete User Account</v-card-subtitle>
          <v-card-text>
            <p>
              Deleting the Auth0 entity <strong>does not</strong> delete this person from
              {{ app_info.name }}. It deletes
              {{ getPronoun(person.preferredPronouns, "possessivePronominal") }}
              <strong>User Account</strong> from Auth0, the authentication provider for
              this application.
            </p>

            <p>
              You will be able to re-invite
              {{ getPronoun(person.preferredPronouns, "accusative") }} to join the
              application from this screen.
            </p>
            <p>
              Use this task if
              {{ getPronoun(person.preferredPronouns, "possessivePronominal") }} User
              Account is not working correctly, or has been created in error.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="saving" text @click="deleteAuth0" color="error"
              >Delete Auth0 Entity</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>
        </div>

        <v-card-subtitle class="overline"
          >Delete {{ getDisplayName(person) }}</v-card-subtitle
        >
        <v-card-text>
          <p>
            Deleting {{ getDisplayName(person) }} will
            <strong>permanently delete</strong>
            {{ getPronoun(person.preferredPronouns, "accusative") }} from
            {{ app_info.name }}.
          </p>
          <p v-if="isUser">
            It will <strong>also delete</strong>
            {{ getPronoun(person.preferredPronouns, "accusative") }} from Auth0, the
            authentication provider for this application.
          </p>
          <p>
            It will <strong>also remove</strong>
            {{ getPronoun(person.preferredPronouns, "accusative") }} from all groups.
          </p>

          <p>
            It <strong>will not remove</strong> any {{ getTerm("evaluation", true) }} that
            {{ getPronoun(person.preferredPronouns, "nominative") }} have already
            completed. To remove these, go to the settings page of the relevant
            {{ getTerm("evaluation", false) }}.
          </p>

          <v-alert type="error" dense
            >Please ensure you have read the above information <strong>carefully</strong>.
            This action cannot be undone.</v-alert
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteUser" :disabled="saving" color="error"
            >Delete {{ getDisplayName(person) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

  <sr-not-found v-else> </sr-not-found>
</template>

<script>
export default {
  name: "PersonSettings",
  data() {
    return {
      saving: false,
      savingInvite: false,
      roles: [],
      dirtyRoles: null,
      orgs: null,
      roleError: null,
      orgError: null,
      request: null,
      valid: false,
      newRole: null,
      changes: 0,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.resetInvite();
  },
  computed: {
    isUser() {
      if (this.person) {
        return this.person.username != null || this.person.userId != null;
      }
      return false;
    },
    email() {
      if (this.person) {
        return this.person.username
          ? this.person.username
          : this.person.emails && this.person.emails.find((x) => x.primary)
          ? this.person.emails.find((x) => x.primary).address
          : null;
      }
      return null;
    },
    roleOptions() {
      return this.$store.getters["saved/getAll"]("roles");
    },
    filteredRoleOptions() {
      return this.roles ? this.roleOptions.filter((x) => !this.roles.includes(x)) : [];
    },
    invitation() {
      return this.person
        ? this.$store.getters["saved/invitation"](this.person.username)
        : [];
    },
    self() {
      return this.$store.getters["saved/self"];
    },
  },
  methods: {
    deleteAuth0() {
      var ok = window.confirm(
        `Are you sure you wish to delete ${this.getDisplayName(
          this.person
        )}'s User Account? You will need to re-invite ${this.getPronoun(
          this.person.preferredPronouns,
          "accusative"
        )} to ${this.app_info.name} in order to restore access.`
      );
      if (ok) {
        this.saving = true;
        this.$get(`permissions/user/${this.person.id}/deleteAuth`).then((response) => {
          debugger;
          if (response.error) {
            this.$emit("update", response.error);
          } else {
            var acknowledge = window.confirm(`${response.data}`);
            if (acknowledge) {
              this.$router.go();
            }
          }
          this.saving = false;
        });
      }
    },
    deleteUser() {
      this.saving = true;
      var ok = window.confirm(
        `Are you sure you wish to permanently delete ${this.getDisplayName(
          this.person
        )} from ${this.app_info.name}? This action cannot be undone.`
      );
      if (ok) {
        this.$get(`permissions/user/${this.person.id}/delete`).then((response) => {
          debugger;
          if (response.error) {
            this.$emit("update", response.error);
          } else {
            var acknowledge = window.confirm(`${response.data}`);
            if (acknowledge) {
              this.$router.push({
                name: "People",
              });
            }
          }
          this.saving = false;
        });
      }
    },
    invite() {
      if (this.valid) {
        this.savingInvite = true;
        this.$post("permissions/users/invite", this.request).then((response) => {
          if (response.error) {
            this.$emit("update", response.error);
          } else {
            this.$store.dispatch("saved/get_invitations").then(() => {
              this.init();
              this.savingInvite = false;
              this.$emit("update", "Success");
            });
          }
        });
      }
    },
    roleList(roles) {
      return roles.map((r) => {
        return this.roleOptions.find((x) => x.id == r);
      });
    },
    resetInvite() {
      var request = {
        email: this.email,
        firstName: this.person.firstName,
        lastName: this.person.lastName,
        groupId: null,
        admin: false,
        createUser: true,
        roles: ["rol_33OrPJsMkyh5wRCo"],
        inviterName: this.self
          ? `${this.self.firstName} ${this.self.lastName}`
          : `${this.app_info.name} Admin`,
        sendEmail: true,
        clientId: this.app_info.clientId,
        connectionName: this.app_info.connectionNames[0],
      };
      this.request = null;
      this.request = request;
      this.changes++;
    },
    init() {
      this.getRoles();
      this.getOrgs();
    },
    addRole() {
      this.saving = true;
      this.$post(`permissions/user/roles/add`, {
        id: this.person.id,
        role: this.newRole.id,
      }).then(() => {
        this.newRole = null;
        this.getRoles();
      });
    },
    removeRole(r) {
      this.saving = true;
      this.$post(`permissions/user/roles/delete`, {
        id: this.person.id,
        role: r,
      }).then(() => {
        this.getRoles();
      });
    },

    getRoles() {
      this.saving = true;
      this.$get(`permissions/user/${this.person.id}/roles`).then((response) => {
        debugger;
        if (response.error) {
          debugger;
          this.roleError = response.text;
        } else {
          this.roles = response.data;
          this.dirtyRoles = response.data;
          //  this.$emit('update', 'Changes Saved')
        }
        this.saving = false;
      });
    },
    getOrgs() {
      this.$get(`permissions/user/${this.person.id}/orgs`).then((response) => {
        debugger;
        if (response.error) {
          this.orgError = response.text;
        } else {
          this.orgs = response.data;

          //  this.$emit('update', 'Changes Saved')
        }
      });
    },
    cancelInvite(i) {
      console.log("cancelling");
      console.log(i);
      this.savingInvite = true;
      this.$post(`permissions/invites/revoke/${i.id}`).then((response) => {
        if (response.error) {
          this.$emit("update", response.error);
        } else {
          this.$store.dispatch("saved/get_invitations").then(() => {
            this.init();
            this.reset();
            this.savingInvite = false;
            this.$emit("update", "Success");
          });
        }
      });
    },
  },
  props: {
    person: {
      type: Object,
    },
  },
};
</script>
