const smartrubric = {
  /* src/core/components/mdTheme/palette.js */
  teal: {
    lighten5: "#e9f8f4",
    lighten4: "#c8ece3",
    lighten3: "#a4e0d1",
    lighten2: "#80d4be",
    lighten1: "#64cab0",
    base: "#49c1a2",
    darken1: "#42bb9a",
    darken2: "#39b390",
    darken3: "#31ab86",
    darken4: "#219e75",
    accent1: "#dafff2",
    accent2: "#a7ffe0",
    accent3: "#74ffce",
    accent4: "#5affc5",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "darken1",
      "darken2",
      "darken3",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  gray: {
    lighten5: "#f1f4f5",
    lighten4: "#dde5e6",
    lighten3: "#c6d3d5",
    lighten2: "#afc1c4",
    lighten1: "#9eb4b7",
    base: "#8da7aa",
    darken1: "#859fa3",
    darken2: "#7a9699",
    darken3: "#708c90",
    darken4: "#5d7c7f",
    accent1: "#eefdff",
    accent2: "#bbf7ff",
    accent3: "#88f2ff",
    accent4: "#6eefff",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "darken1",
      "darken2",
      "darken3",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  red: {
    lighten5: "#fcece8",
    lighten4: "#f8d0c7",
    lighten3: "#f4b0a1",
    lighten2: "#ef907b",
    lighten1: "#eb795f",
    base: "#e86143",
    darken1: "#e5593d",
    darken2: "#e24f34",
    darken3: "#de452c",
    darken4: "#d8331e",
    accent1: "#ffffff",
    accent2: "#ffdfdb",
    accent3: "#ffb1a8",
    accent4: "#ff9a8f",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  yellow: {
    lighten5: "#fff9e9",
    lighten4: "#fef0c8",
    lighten3: "#fde7a3",
    lighten2: "#fcdd7e",
    lighten1: "#fcd563",
    base: "#fbce47",
    darken1: "#fac940",
    darken2: "#fac237",
    darken3: "#f9bc2f",
    darken4: "#f8b020",
    accent1: "#ffffff",
    accent2: "#fffefc",
    accent3: "#ffecc9",
    accent4: "#ffe3af",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "darken1",
      "darken2",
      "darken3",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  dark: {
    lighten5: "#e8e9eb",
    lighten4: "#c6c8cc",
    lighten3: "#a0a4ab",
    lighten2: "#798089",
    lighten1: "#5d646f",
    base: "#404956",
    darken1: "#3a424f",
    darken2: "#323945",
    darken3: "#2a313c",
    darken4: "#1c212b",
    accent1: "#739eff",
    accent2: "#407bff",
    accent3: "#0d57ff",
    accent4: "#004bf2",
    darkText: ["lighten5", "lighten4", "lighten3", "accent1"],
  },
  white: {
    lighten5: "#fdfdfd",
    lighten4: "#fafafa",
    lighten3: "#f6f6f6",
    lighten2: "#f2f2f2",
    lighten1: "#f0f0f0",
    base: "#ededed",
    darken1: "#ebebeb",
    darken2: "#e8e8e8",
    darken3: "#e5e5e5",
    darken4: "#e0e0e0",
    accent1: "#ffffff",
    accent2: "#ffffff",
    accent3: "#ffffff",
    accent4: "#ffffff",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "darken1",
      "darken2",
      "darken3",
      "darken4",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  citron: {
    lighten5: "#f4f7ea",
    lighten4: "#e5ecca",
    lighten3: "#d3dfa7",
    lighten2: "#c1d283",
    lighten1: "#b4c969",
    base: "#a7bf4e",
    darken1: "#9fb947",
    darken2: "#96b13d",
    darken3: "#8ca935",
    darken4: "#7c9b25",
    accent1: "#f4ffda",
    accent2: "#e6ffa7",
    accent3: "#d7ff74",
    accent4: "#d0ff5a",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "darken1",
      "darken2",
      "darken3",
      "darken4",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  bluegreen: {
    lighten5: "#e9f4f6",
    lighten4: "#c8e4e8",
    lighten3: "#a4d3d9",
    lighten2: "#80c1c9",
    lighten1: "#64b3be",
    base: "#49a6b2",
    darken1: "#429eab",
    darken2: "#3995a2",
    darken3: "#318b99",
    darken4: "#217b8a",
    accent1: "#c7f6ff",
    accent2: "#94efff",
    accent3: "#61e7ff",
    accent4: "#47e3ff",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "darken1",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  blue: {
    lighten5: "#e9f1f8",
    lighten4: "#c8dcec",
    lighten3: "#a4c5e0",
    lighten2: "#80add4",
    lighten1: "#649cca",
    base: "#498ac1",
    darken1: "#4282bb",
    darken2: "#3977b3",
    darken3: "#316dab",
    darken4: "#215a9e",
    accent1: "#daeaff",
    accent2: "#a7ccff",
    accent3: "#74afff",
    accent4: "#5aa1ff",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
  indigo: {
    lighten5: "#eae9f6",
    lighten4: "#ccc8e8",
    lighten3: "#aaa4d9",
    lighten2: "#8780c9",
    lighten1: "#6e64be",
    base: "#5449b2",
    darken1: "#4d42ab",
    darken2: "#4339a2",
    darken3: "#3a3199",
    darken4: "#29218a",
    accent1: "#cbc7ff",
    accent2: "#9b94ff",
    accent3: "#6b61ff",
    accent4: "#5447ff",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "accent1",
      "accent2",
    ],
  },
  green: {
    lighten5: "#eaf6e9",
    lighten4: "#cbe8c9",
    lighten3: "#a9d9a5",
    lighten2: "#86ca80",
    lighten1: "#6cbe65",
    base: "#52b34a",
    darken1: "#4bac43",
    darken2: "#41a33a",
    darken3: "#389a32",
    darken4: "#288b22",
    accent1: "#cbffc9",
    accent2: "#9bff96",
    accent3: "#6aff63",
    accent4: "#52ff49",
    darkText: [
      "lighten5",
      "lighten4",
      "lighten3",
      "lighten2",
      "lighten1",
      "base",
      "darken1",
      "accent1",
      "accent2",
      "accent3",
      "accent4",
    ],
  },
};
export default smartrubric;
