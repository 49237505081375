<template>
  <div class="d-flex" :class="{ 'align-center': center }">
    <span v-if="paths && paths.length != 0">
      <span v-if="!minify && !excludeType">
        <span class="mr-2 font-weight-bold">{{ self.groupType }}</span> in
      </span>

      <span v-for="(g, gi) in paths[0].groups" :key="gi">
        <span> {{ g.name }}</span>
      </span>
      <span v-if="paths.length > 1">
        <span v-for="(g, gi) in paths[1].groups" :key="gi">
          <span v-if="!minify"> ({{ g.name }})</span>
          <span v-else>, {{ g.name }}</span>
        </span>
      </span>
    </span>
    <v-spacer></v-spacer>
    <v-tooltip
      v-if="paths.length > 1 && !minify && !excludeType"
      left
      color="primary darken-2"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn :small="!center" color="dark" icon dark v-bind="attrs" v-on="on">
          <i class="fal fa-sitemap"> </i>
        </v-btn>
      </template>
      <div class="d-flex flex-column">
        <div v-for="(p, pi) in reversePaths" :key="pi">
          <i class="fal fa-angle-right mr-2" v-if="pi != 0"></i>
          <span v-for="(g, gi) in p.groups" :key="gi">
            <span :class="{ 'font-weight-black': pi == 0 }"> {{ g.name }}</span>
            <span>({{ g.groupType }})</span>
          </span>
        </div>
        <div>
          <i class="fas fa-caret-right mr-2"></i>
          <span class="font-weight-bold"> {{ self.name }}</span>
          <span>({{ self.groupType }}) </span>
        </div>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "GroupPath",
  methods: {},
  computed: {
    reversePaths() {
      return this.paths.slice().reverse();
    },
    internalIds() {
      if (this.paths) {
        var groups = this.paths
          .map((x) => x.groups)
          .flat()
          .filter((m) => m.internalId)
          .map((i) => i.internalId);
        return groups;
      }
      return [];
    },
    paths() {
      var t = this;
      return this.path
        .map((x) => {
          return x
            ? {
                level: x.level,
                groups: x.groups
                  ? x.groups.map((g) => {
                      var group = t.$store.getters["saved/get"](["groups", g]);
                      var gt = t.$store.getters["saved/get"]([
                        "groupTypes",
                        group.groupType,
                      ]);
                      return {
                        id: g,
                        name: group != null ? group.displayText : null,
                        internalId: group != null ? group.internalId : null,
                        groupType: gt != null ? gt.name : null,
                        colour: group ? group.colour : "",
                      };
                    })
                  : [],
              }
            : null;
        })
        .sort(function (a, b) {
          if (a.level < b.level) {
            return -1;
          }
          if (a.level > b.level) {
            return 1;
          }
          if (a.level === b.level) {
            return 0;
          }
        })
        .filter((y) => y);
    },
  },

  props: {
    path: {
      type: Array,
    },
    self: {
      type: Object,
    },
    minify: {
      type: Boolean,
      default: false,
    },
    excludeType: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
