<template>
  <v-card
    :tile="app_info.tile || flat"
    :flat="flat"
    :loading="uploading"
    style="min-height: 100%"
    :style="xs ? `min-height: auto !important` : ''"
    class=""
  >
    <v-card-subtitle v-if="readonly && !parent.file.length">
      No attachments
    </v-card-subtitle>
    <div v-else>
      <v-toolbar
        v-if="!xs"
        flat
        :color="flat ? '' : 'highlight'"
        dense
        class="border-bottom"
      >
        <v-toolbar-title>Attachments</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <div :style="!xs ? `min-height: 150px` : ''">
        <v-list dense :class="{ 'pt-0': xs }">
          <v-subheader v-if="xs" class="overline">Attachments</v-subheader>
          <v-divider v-if="xs && parent.file.length"></v-divider>
          <div style="overflow-y: auto">
            <v-list-item v-for="(file, fi) in parent.file" :key="fi">
              <v-list-item-content>
                <v-list-item-title class="d-print-inline">{{
                  file.title.replace(`${file.title.split("_")[0]}_`, "")
                }}</v-list-item-title>
                <v-list-item-subtitle class="d-print-none">
                  {{
                    file.size * 0.000001 >= 1000
                      ? `${((file.size * 0.000001) / 1000).toFixed(2)}GB`
                      : `${(file.size * 0.000001).toFixed(2)} MB`
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-btn text class="d-print-none" @click="downloadFile(file.id)">
                <i class="fal fa-download"></i>
              </v-btn>
              <v-btn
                v-if="!readonly"
                class="d-print-none"
                icon
                color="error"
                @click="deleteFile(file.id)"
              >
                <span class="text--error">X</span>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="error" dark class="error">
              <v-list-item-avatar tile dark color="error"> ! </v-list-item-avatar>
              <v-list-item-content>{{ error }}</v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </div>
    </div>
    <v-card-actions v-if="!readonly" class="border-top d-flex flex-column">
      <v-file-input
        dense
        :disabled="uploading || disabled"
        v-model="file"
        :rules="rules"
        style="width: 100%"
        label="Add attachment"
        @change="error = null"
        truncate-length="50"
        prepend-icon=""
      >
      </v-file-input>
      <v-btn
        block
        text
        color="success"
        :disabled="uploading || disabled"
        v-if="file"
        @click="uploadFile"
        >Upload</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "FileInput",
  data() {
    return {
      uploading: false,
      imageId: null,
      error: null,
      file: null,
      rules: [
        (value) => !value || value.size < 3000000 || "File size must be less than 3 MB",
      ],
    };
  },
  computed: {
    fileStatus() {
      if (this.error) {
        return "error-border-key";
      }
      if (this.showUploadButton) {
        return "warning-border-key";
      }
      return "primary-border-key";
    },
  },
  methods: {
    getIcon(f) {
      if (f) {
        if (f.includes("image")) {
          return "IMG";
        }
        if (f.includes("pdf")) {
          return "PDF";
        }
        if (f.includes("word")) {
          return "W";
        }
        if (f.includes("audio")) {
          return "A";
        }
        if (f.includes("video")) {
          return "V";
        }
        if (f.includes("csv")) {
          return "CSV";
        }
        if (f.includes("excel")) {
          return "EXCEL";
        }
      }

      return "";
    },
    downloadFile(id) {
      if (id != null) {
        this.$get(`file/blob/${id}/view`).then((response) => {
          console.log(response);
          if (response.error) {
            this.error = response.text;
          } else {
            window.open(response.data);
          }
        });
      }
    },
    deleteFile(fileId) {
      if (fileId) {
        this.uploading = true;
        console.log("deleting File");
        console.log(fileId);
        this.$get(`file/${fileId}/delete`).then((response) => {
          console.log(response);
          if (response.error) {
            this.error = "File not found";
          } else {
            this.$emit("delete", fileId);
          }
          this.uploading = false;
        });
      }
    },

    uploadFile() {
      var e = this.file;
      if (e) {
        console.log(e);

        this.uploading = true;
        //upload
        this.$upload(e).then((response) => {
          console.log(response);

          if (response.error) {
            this.error = response.text;
          } else {
            var file = response;

            this.error = null;
            this.$emit("input", file);
            this.file = null;
          }
          this.uploading = false;
        });
      }
    },
  },
  props: {
    parent: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
