var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("New "+_vm._s(_vm._f("term")('Framework')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showCreate = !_vm.showCreate}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showCreate),expression:"!showCreate"}]},[_vm._v("mdi-plus")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCreate),expression:"showCreate"}]},[_vm._v("mdi-close")])],1)],1),(_vm.showCreate)?_c('v-form',{ref:"newFramework",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[(_vm.framework)?_c('v-form',{key:_vm.resetCount,model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$getTerm('Title'),"disabled":_vm.saving,"rules":_vm.nameRules},model:{value:(_vm.framework.title),callback:function ($$v) {_vm.$set(_vm.framework, "title", $$v)},expression:"framework.title"}}),_c('wysiwyg',{staticClass:"rounded mb-5",attrs:{"label":_vm.$getTerm('Title'),"disabled":_vm.saving},model:{value:(_vm.framework.description),callback:function ($$v) {_vm.$set(_vm.framework, "description", $$v)},expression:"framework.description"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","disabled":_vm.saving,"multiple":"","append-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":selected,"close":"","dark":""},on:{"click":select,"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2537883044),model:{value:(_vm.framework.tags),callback:function ($$v) {_vm.$set(_vm.framework, "tags", $$v)},expression:"framework.tags"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error","disabled":_vm.saving},on:{"click":_vm.reset}},[_c('span',[_vm._v("Cancel")])]),_c('v-btn',{attrs:{"disabled":!_vm.valid || _vm.saving,"text":"","color":"success"},on:{"click":_vm.create}},[_c('span',[_vm._v("Create")])])],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"top":"","color":_vm.error,"timeout":_vm.app_info.snackbarTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.error = null}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }