<template>
  <v-row :key="groupId">
    <v-col :key="loading" v-if="group != null">
      <v-card
        flat
        :loading="
          loading &&
          groupType &&
          groupType.baseline &&
          group.isMember &&
          !group.isAdministrator
        "
      >
        <baseline-report
          v-if="
            group &&
            person &&
            readDocs &&
            groupType.baseline &&
            group.isMember &&
            !group.isAdministrator
          "
          :showPrint="false"
          :person="person"
          :group="group"
        >
        </baseline-report>
        <group-info
          v-if="miniGroup && !miniGroup.readonly"
          :person="person"
          :group="miniGroup"
        >
        </group-info>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BaselineReport from "./BaselineReport.vue";
import GroupInfo from "../GroupComponents/GroupInfo.vue";
export default {
  name: "GroupPerson",
  components: { BaselineReport, GroupInfo },
  data() {
    return {
      skills: [],
      group: null,
      error: null,
      loading: false,
    };
  },
  watch: {
    groupId() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  computed: {
    groupType() {
      return this.miniGroup
        ? this.$store.getters["saved/get"](["groupTypes", this.miniGroup.groupType])
        : null;
    },
    permissions() {
      var group = this.group;
      var admin = group && !group.readonly;
      var gt = this.groupType;
      var permission = gt
        ? admin
          ? gt.objectPermissions.admin
          : gt.objectPermissions.member
        : null;
      return permission;
    },
    readDocs() {
      return this.permissions && this.person
        ? this.person.isSelf
          ? this.permissions.selfDocs
          : this.permissions.docs.read
        : false;
    },
    miniGroup() {
      return this.groupId
        ? this.$store.getters["saved/get"](["groups", this.groupId])
        : null;
    },
  },
  methods: {
    init() {
      this.loading = true;
      this.$get(`entities/groups/${this.groupId}`).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.group = response.data;
        }
        this.loading = false;
      });
    },
  },
  props: {
    person: {
      type: Object,
    },
    groupId: {
      type: String,
    },
  },
};
</script>
