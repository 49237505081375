<template>
  <v-card flat>
    <v-form
      :value="bandValid"
      v-if="tableView && $vuetify.breakpoint.mdAndUp && unit.objectives.length"
      ref="bands"
    >
      <v-simple-table fixed-header dense class="matrix" style="overflow-x: auto">
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 150px" class="border-right text-center"></th>

              <td
                class="px-0"
                style="min-width: 200px"
                v-for="(b, gbi) in editableUnit.objectives[0].bands"
                :key="b.id"
              >
                <div class="px-0" tile flat>
                  <div class="px-0" v-if="b != null">
                    <v-system-bar color="highlight" class="mb-4">
                      <v-btn
                        x-small
                        icon
                        color="error"
                        v-if="b.value > 0 && b.floor < unit.maxMarks"
                        :disabled="saving"
                        text
                        @click="removeBand(gbi)"
                      >
                        <v-icon>fal fa-times</v-icon>
                      </v-btn>

                      <v-spacer></v-spacer>

                      <v-btn
                        icon
                        x-small
                        color="success"
                        v-if="b.floor < unit.maxMarks"
                        :disabled="saving"
                        @click="addBand(gbi)"
                      >
                        <v-icon>fal fa-plus</v-icon>
                      </v-btn>
                    </v-system-bar>

                    <v-text-field
                      dense
                      class="pt-4"
                      label="Label"
                      :disabled="saving"
                      :rules="[
                        (v) => !!v || 'Label is required',
                        (v) => v.length >= 1 || 'Label is required',
                      ]"
                      @input="updateBand"
                      v-model="b.key"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :disabled="saving || ['ratingScale'].includes(unit.format.mode)"
                      label="Min"
                      :rules="[
                        (v) => !isNaN(v) || 'Floor is required',
                        (v) => v >= 0 || 'Value must be positive.',
                        (v) => v.toString().length > 0 || 'Floor is required',
                      ]"
                      @input="updateBand"
                      v-model.number="b.floor"
                    >
                    </v-text-field>
                    <v-text-field
                      dense
                      v-if="!['ratingScale'].includes(unit.format.mode)"
                      readonly
                      label="Max"
                      :value="getCeiling(gbi)"
                      :hint="`Actual ${getTerm('point', true)}: ${b.value}`"
                      persistent-hint
                      :disabled="saving"
                    >
                    </v-text-field>
                    <v-checkbox
                      v-model="b.hidden"
                      label="Hidden"
                      dense
                      :readonly="saving"
                      @change="updateBand"
                    >
                    </v-checkbox>
                  </div>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr valign="top" v-for="(o, oi) in editableUnit.objectives" :key="oi">
              <td class="pa-0" style="min-width: 200px">
                <v-system-bar color="highlight" class="mb-4">
                  <v-spacer></v-spacer>

                  <v-btn
                    x-small
                    text
                    color="error"
                    :disabled="saving"
                    @click="removeObjective(o.id)"
                  >
                    Delete {{ o.title }} <v-icon right>fal fa-times</v-icon>
                  </v-btn>
                </v-system-bar>
                <div v-if="o" class="px-0" :key="resetCount">
                  <div class="px-2">
                    <v-text-field
                      class="pt-4"
                      dense
                      :label="$getTerm('Title')"
                      :disabled="saving"
                      v-model="o.title"
                      @input="updateObjective(o.id)"
                      :rules="[
                        (v) => !!v || 'Name is required',
                        (v) => v.length >= 1 || 'Name is required',
                        (v) => v.length <= 50 || 'Name must be less than 50 characters',
                      ]"
                    >
                    </v-text-field>

                    <wysiwyg
                      class="rounded mb-5 mini-wys"
                      :label="$getTerm('Title')"
                      v-model="o.description"
                      :disabled="saving"
                      :placeholder="`${$getTerm('Objective')} description...`"
                      hint="Hint"
                      @change="updateObjective(o.id)"
                    />

                    <v-combobox
                      outlined
                      dense
                      hide-no-data
                      single-line
                      small-chips
                      v-model="o.tags"
                      chips
                      clearable
                      label="Tags"
                      :disabled="saving"
                      @input="updateObjective(o.id)"
                      multiple
                      append-icon=""
                    >
                      <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          class="my-1"
                          dark
                          @click="select"
                          @click:close="removeTag(o.id, item)"
                        >
                          {{ item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                </div>
              </td>
              <td class="pa-0" v-for="(b, bi) in o.bands" :key="`b${bi}`">
                <v-system-bar color="highlight" class="mb-4">
                  <v-spacer></v-spacer>

                  {{ b.key }}

                  <v-spacer></v-spacer>
                </v-system-bar>
                <div class="px-3" style="height: 90%">
                  <wysiwyg
                    :class="{ 'hidden-wys': b.hidden }"
                    style="height: 100%"
                    :placeholder="
                      b.hidden
                        ? `${$getTerm('Hidden level Text')}...`
                        : `${$getTerm('Level Text')}...`
                    "
                    class="rounded my-5 mini-wys"
                    :label="$getTerm('Title')"
                    v-model="b.levelText"
                    :disabled="saving"
                    @change="updateLevelText(o.id, b.id)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-form>
    <v-expansion-panels
      class="border-bottom"
      flat
      accordion
      :value="0"
      :key="resetCount"
      v-if="$vuetify.breakpoint.smAndDown || !tableView"
    >
      <v-expansion-panel
        class="border-bottom"
        v-if="!['checkList'].includes(unit.format.mode) && editableUnit.objectives.length"
        :key="0"
      >
        <v-expansion-panel-header>
          <template v-slot:default>
            <span class="overline"> Configure {{ "Band" | pluralTerm }}</span>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form :value="bandValid" ref="bands">
            <div class="mx-2 d-flex flex-wrap align-start">
              <div
                v-for="(b, gbi) in editableUnit.objectives[0].bands"
                :key="b.id"
                class="d-flex align-center"
              >
                <v-card outlined :tile="app_info.tile" max-width="175" class="ma-4">
                  <div class="d-flex border-bottom">
                    <div>
                      <v-card-text v-if="b != null">
                        <v-text-field
                          dense
                          outlined
                          label="Label"
                          :disabled="saving"
                          :rules="[
                            (v) => !!v || 'Label is required',
                            (v) => v.length >= 1 || 'Label is required',
                          ]"
                          @change="updateBand"
                          v-model="b.key"
                        ></v-text-field>
                        <v-text-field
                          dense
                          outlined
                          :disabled="saving"
                          label="Min"
                          :rules="[
                            (v) => !isNaN(v) || 'Floor is required',
                            (v) => v >= 0 || 'Value must be positive.',
                            (v) => v.toString().length > 0 || 'Floor is required',
                          ]"
                          @change="updateBand"
                          v-model.number="b.floor"
                        >
                        </v-text-field>
                        <v-text-field
                          dense
                          v-if="!['ratingScale'].includes(unit.format.mode)"
                          outlined
                          readonly
                          label="Max"
                          :value="getCeiling(gbi)"
                          :hint="`Actual ${getTerm('point', true)}: ${b.value}`"
                          persistent-hint
                        >
                        </v-text-field>
                        <v-checkbox
                          v-model="b.hidden"
                          label="Hidden"
                          @change="updateBand"
                        >
                        </v-checkbox>
                      </v-card-text>
                    </div>
                  </div>

                  <v-card-actions class="white dark--text">
                    {{ Math.round((b.floor / 100) * unit.maxMarks) }} marks
                    <v-spacer></v-spacer>
                    <v-btn :disabled="saving" color="error" text @click="removeBand(gbi)">
                      Remove
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-btn
                  class="elevation-1"
                  v-if="b.floor < unit.maxMarks"
                  color="success"
                  :disabled="saving"
                  icon
                  @click="addBand(gbi)"
                >
                  <v-icon>fal fa-plus</v-icon>
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="(o, oi) in editableUnit.objectives" :key="oi + 1">
        <v-expansion-panel-header>
          <template v-slot:default>
            <span> {{ oi + 1 }}. {{ o.title }}</span>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form v-model="valid" v-if="o" :key="resetCount">
            <v-card-text>
              <v-text-field
                outlined
                dense
                :label="$getTerm('Title')"
                :disabled="saving"
                v-model="o.title"
                @input="change"
                :rules="[
                  (v) => !!v || 'Name is required',
                  (v) => v.length >= 1 || 'Name is required',
                  (v) => v.length <= 50 || 'Name must be less than 50 characters',
                ]"
              >
              </v-text-field>

              <wysiwyg
                class="rounded mb-5"
                :label="$getTerm('Title')"
                v-model="o.description"
                :disabled="saving"
                @change="change"
              />

              <v-combobox
                outlined
                dense
                hide-no-data
                single-line
                small-chips
                v-model="o.tags"
                chips
                clearable
                label="Tags"
                :disabled="saving"
                @input="change"
                multiple
                append-icon=""
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    class="my-1"
                    dark
                    @click="select"
                    @click:close="removeTag(o.id, item)"
                  >
                    {{ item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn :disabled="saving" @click="removeObjective(o.id)" color="error"
              >Delete {{ "objective" | term }}</v-btn
            >

            <v-spacer></v-spacer>
            <v-btn text @click="reset" :disabled="changes == 0 || saving" color="warning"
              >reset</v-btn
            >
            <v-btn
              text
              @click="updateObjective(o.id)"
              :disabled="changes == 0 || !valid || saving"
              color="success"
              >Save Changes</v-btn
            >
          </v-card-actions>
          <v-card-text>
            <v-list v-if="unit.format.mode == 'matrix'">
              <v-list-item v-for="(b, bi) in o.bands" :key="`b${bi}`">
                <v-list-item-content>
                  <v-list-item-title class="overline">{{ b.key }}</v-list-item-title>

                  <wysiwyg
                    class="rounded mb-5"
                    :label="$getTerm('Title')"
                    v-model="b.levelText"
                    :disabled="saving"
                    @change="updateLevelText(o.id, b.id)"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions>
      <v-btn :disabled="saving" block text @click="addObjective">
        <v-icon left>fal fa-plus</v-icon> Add {{ "Objective" | term }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "UnitSchema",
  data() {
    return {
      editableUnit: null,
      changes: 0,
      valid: false,
      resetCount: 0,
      panel: "bandConfig",
      bandValid: false,
    };
  },
  created() {
    this.reset();
  },
  methods: {
    getCeiling(i) {
      debugger;
      const b = this.editableUnit.objectives[0].bands[i];
      var nextBands = this.editableUnit.objectives[0].bands
        .filter((x) => x.floor > b.floor)
        .sort((a, b) => {
          return a.floor - b.floor;
        });
      const levelUp = nextBands.length ? nextBands[0] : null;
      // const levelUp = this.editableUnit.objectives[0].bands[i + 1];
      var result = 0;
      if (levelUp) {
        result = levelUp.floor % 1 != 0 ? levelUp.floor - 0.1 : levelUp.floor - 1;
      } else {
        result =
          b.floor == this.unit.maxMarks
            ? this.unit.maxMarks
            : this.unit.maxMarks % 1 != 0
            ? this.unit.maxMarks - 0.1
            : this.unit.maxMarks - 1;
      }
      return result >= 0 ? result : 0;
    },
    change() {
      this.changes++;
    },
    reset() {
      if (this.editableUnit != null) {
        this.editableUnit = null;
      }

      this.editableUnit = {};
      var unit = {
        id: this.unit && this.unit.id ? this.unit.id : null,
        objectives: this.unit.objectives.map((o) => {
          var bands = o.bands.sort((a, b) => {
            return a.floor - b.floor;
          });
          o.bands = bands;
          return o;
        }),
      };
      Object.assign(this.editableUnit, unit);
      this.changes = 0;
      this.resetCount++;
    },
    getValue(index, floor) {
      const ceiling = this.getCeiling(index);
      return (ceiling - floor) / 2 + floor;
    },
    addBand(i) {
      const previousBand = this.editableUnit.objectives[0].bands[i];
      var ceiling = this.getCeiling(i);
      var floor =
        ceiling % 1 != 0
          ? ceiling - 0.1 > previousBand.floor
            ? ceiling - 0.1
            : ceiling - 0.05
          : ceiling - 1 > previousBand.floor
          ? ceiling - 1
          : ceiling - 0.5;
      var value = (ceiling - floor) / 2 + floor;
      var newBand = {
        floor: floor,
        value: value,
        key: `${this.$getTerm("Level")} ${i}`,
      };
      this.editableUnit.objectives.forEach((objective) => {
        var data = {
          objectiveId: objective.id,
          unitId: this.unit.id,
          band: newBand,
        };
        debugger;
        this.$emit("addBand", data);
      });
    },
    removeBand(index) {
      this.editableUnit.objectives.forEach((objective) => {
        const band = objective.bands[index];
        var data = {
          objectiveId: objective.id,
          unitId: this.unit.id,
          bandId: band.id,
        };
        debugger;
        this.$emit("removeBand", data);
      });
    },

    updateBand() {
      var valid = this.$refs.bands.validate();
      if (valid) {
        debugger;
        /*   var newBands = this.editableUnit.objectives[0]
          ? this.editableUnit.objectives[0].bands
          : [];
*/
        var bandArrSource = this.editableUnit.objectives.map((o) => o.bands);
        var newBands = [];
        let bi = 0;
        do {
          var standard = bandArrSource[0][bi];
          var biArr = [];
          let objIndex = 0;
          do {
            biArr.push(bandArrSource[objIndex][bi].id);
            objIndex++;
          } while (objIndex < bandArrSource.length);
          standard.idArr = biArr;
          newBands.push(standard);
          bi++;
        } while (bi < bandArrSource[0].length);

        let i = 0;
        do {
          var thisBand = newBands[i];
          var ceiling = this.getCeiling(i);
          debugger;
          if (ceiling < thisBand.floor) {
            if (thisBand.floor < this.unit.maxMarks) {
              newBands[i + 1].floor =
                thisBand.floor % 1 != 0 ? thisBand.floor + 0.5 : thisBand.floor + 1;
            }
          }
          thisBand.value = (ceiling - thisBand.floor) / 2 + thisBand.floor;
          i++;
        } while (i < newBands.length);

        this.editableUnit.objectives.forEach((objective) => {
          objective.bands.forEach((band) => {
            debugger;
            var standard = newBands.find((x) => x.idArr.includes(band.id));
            band.hidden = standard.hidden;
            band.floor = standard.floor;
            band.key = standard.key;
            band.value = standard.value;

            var data = {
              objectiveId: objective.id,
              unitId: this.unit.id,
              band: band,
              bandId: band.id,
            };
            //console.log(data)
            this.$emit("updateBand", data);
          });
        });
      } else {
        console.log("invalid");
      }
    },
    addObjective() {
      this.changes = 0;

      var newObjective = null;
      var bands = [];
      var e = this.unit;
      if (e.objectives.length) {
        bands = e.objectives[0].bands.map((b) => {
          var band = {
            floor: b.floor,
            hidden: b.hidden,
            key: b.key,
            value: b.value,
          };
          return band;
        });
      } else {
        switch (e.format.mode) {
          case "checkList":
            bands = [
              {
                floor: 0,
                key: "N",
                value: 0,
              },
              {
                floor: 0,
                key: "Mid",
                value: e.maxMarks / 2,
                hidden: true,
              },
              {
                floor: e.maxMarks != 0 ? e.maxMarks : 1,
                key: "Y",
                value: e.maxMarks != 0 ? e.maxMarks : 1,
              },
            ];
            break;
          case "matrix":
            var interval = e.maxMarks > 3 ? Math.floor(e.maxMarks / 3) : e.maxMarks / 3;
            bands = [
              {
                floor: 0,
                key: `No ${this.$getTerm("point", true)}`,
                value: 0,
              },
              {
                floor: 0,
                key: `${this.$getTerm("Level", false)} 1`,
              },
              {
                floor: interval,
                key: `${this.$getTerm("Level", false)} 2`,
              },
              {
                floor: 2 * interval,
                key: `${this.$getTerm("Level", false)} 3`,
              },

              {
                floor: e.maxMarks != 0 ? e.maxMarks : 1,
                key: `Max ${this.$getTerm("point", true)}`,
              },
            ];
            bands.forEach((b, i) => {
              const levelUp = bands[i + 1];
              const floor = b.floor;
              var ceiling =
                floor == this.unit.maxMarks
                  ? this.unit.maxMarks
                  : this.unit.maxMarks % 1 != 0
                  ? this.unit.maxMarks - 0.1
                  : this.unit.maxMarks - 1;
              if (levelUp) {
                ceiling =
                  levelUp.floor % 1 != 0 ? levelUp.floor - 0.1 : levelUp.floor - 1;
              }
              b.value = (ceiling - floor) / 2 + floor;
              if (b.value < 0) {
                b.value = 0;
              }
            });
            break;
          case "ratingScale":
            var ints = [];
            var i = 0;
            var max = e.maxMarks != 0 ? e.maxMarks : 1;
            do {
              ints.push(i);
              i++;
            } while (i <= max);
            bands = ints.map((i) => {
              return {
                floor: i,
                key: i.toString(),
                value: i,
              };
            });

            break;
          default:
          // code block
        }
      }
      newObjective = {
        description: null,
        bands: bands,
        tags: [],
        title: `${this.$getTerm("Objective")} ${e.objectives.length + 1}`,
      };
      var data = {
        unitId: e.id,
        objective: newObjective,
      };
      this.$emit("addObjective", data);
    },
    updateLevelText(objectiveId, bandId) {
      var objective = this.editableUnit.objectives.find((o) => o.id == objectiveId);
      var band = objective.bands.find((b) => b.id == bandId);
      var data = {
        bandId: bandId,
        unitId: this.editableUnit.id,
        objectiveId: objectiveId,
        levelText: band.levelText,
      };
      this.changes = 0;

      this.$emit("updateLevelText", data);
    },
    updateObjective(id) {
      var valid = this.tableView ? this.$refs.bands.validate() : this.valid;
      if (valid) {
        const objective = this.editableUnit.objectives.find((o) => o.id == id);
        var data = {
          objective: objective,
          unitId: this.editableUnit.id,
          objectiveId: id,
        };
        this.changes = 0;

        this.$emit("updateObjective", data);
      }
    },
    removeObjective(id) {
      debugger;
      this.changes = 0;

      var data = {
        unitId: this.editableUnit.id,
        objectiveId: id,
      };
      this.$emit("removeObjective", data);
    },
    removeTag(objectiveId, item) {
      debugger;
      var objective = this.editableUnit.objectives.find((o) => o.id == objectiveId);
      var i = objective.tags.indexOf(item);
      objective.tags.splice(i, 1);
      objective.tags = [...objective.tags];
      this.updateObjective(objectiveId);
    },
  },
  props: {
    unit: {
      type: Object,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    tableView: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
