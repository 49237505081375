var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4 break",attrs:{"tile":_vm.app_info.tile}},[_c('v-system-bar',{staticClass:"d-print-none d-flex justify-space-between",attrs:{"height":"30","color":"secondary"}},[_c('span',{staticClass:"white--text"},[(_vm.mode == 'edit')?_c('span',[_vm._v(_vm._s(_vm._f("term")("MarkingView"))+" ")]):_c('span',[_vm._v(_vm._s(_vm._f("term")("ReportView"))+" ")]),_vm._v(" View ")]),(
        (!_vm.selfSelected &&
          ((_vm.mode == 'report' && !_vm.complete && !_vm.locked) || _vm.mode == 'edit')) ||
        _vm.$auth.isAdmin
      )?_c('v-btn',{staticClass:"white--text",attrs:{"tile":"","color":"white","small":"","text":""},on:{"click":_vm.toggleMode}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode == 'edit'),expression:"mode == 'edit'"}]},[_c('v-icon',{staticStyle:{"color":"white !important"},attrs:{"left":""}},[_vm._v("fad fa-file-chart-line")]),_vm._v(" Show "+_vm._s(_vm._f("term")("ReportView"))+" View ")],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.mode == 'report'),expression:"mode == 'report'"}]},[_c('v-icon',{staticStyle:{"color":"white !important"},attrs:{"left":""}},[_vm._v("fad fa-th")]),_vm._v(" Show "+_vm._s(_vm._f("term")("MarkingView"))+" View")],1)]):_vm._e()],1),_c('v-toolbar',{staticClass:"d-none d-print-inline"},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.assessment.title)+" ("+_vm._s(_vm._f("moment")(_vm.assessment.metadata.lastUpdated,"DD MMM YYYY"))+") ")]),_c('v-spacer'),(!_vm.individuals.length || _vm.individuals.length == 1)?_c('v-toolbar-title',[_c('sr-person-chip',{attrs:{"showAvatar":false,"id":_vm.selectedIndividual}})],1):_vm._e()],1),(_vm.individuals.length > 1)?_c('v-toolbar',{staticClass:"border-bottom d-print-none",attrs:{"color":"primary","dark":"","fixed":"","dense":"","flat":""}},[(!_vm.individuals.length || _vm.individuals.length == 1)?_c('v-toolbar-title',[_c('sr-person-chip',{attrs:{"selectOption":"","id":_vm.selectedIndividual}})],1):_c('v-toolbar-items',[_c('div',{key:_vm.selectedIndividual,staticClass:"mt-2 d-print-none"},[_c('v-select',{attrs:{"outlined":"","hide-details":"","prepend-inner-icon":"mdi-account","dense":"","items":_vm.individuals},on:{"change":_vm.goToRoute},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('sr-person-chip',{attrs:{"id":item,"selectOption":""}})]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('sr-person-chip',{attrs:{"id":item,"small":"","selectOption":""}})]}}],null,false,2911174901),model:{value:(_vm.selectedIndividual),callback:function ($$v) {_vm.selectedIndividual=$$v},expression:"selectedIndividual"}})],1)]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"d-none d-print-inline"},[_c('sr-person-chip',{attrs:{"id":_vm.selectedIndividual,"selectOption":""}})],1),_c('v-btn',{attrs:{"small":"","to":{
        name: 'PersonHome',
        params: { individualid: _vm.selectedIndividual, groupid: _vm.assessment.group },
      }}},[_vm._v(" "+_vm._s(_vm._f("term")("Assessee"))+" Profile "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-arrow-right")])],1)],1):_vm._e(),_c('div',[(_vm.individualAssessment)?_c('individual-assessment',{key:_vm.selectedIndividual,ref:"ia",attrs:{"individualId":_vm.selectedIndividual,"assessmentId":_vm.assessment.id,"rubric":_vm.assessment.rubric,"secret":_vm.assessment.secret,"updateKey":_vm.updateKey,"self":_vm.selfSelected,"editMode":_vm.editMode,"locked":_vm.locked},on:{"updateLock":_vm.updateLock,"error":_vm.error,"input":_vm.update,"complete":_vm.updateComplete,"toggleMode":_vm.toggleMode}}):_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('sr-not-found',{attrs:{"icon":"fad fa-users","color":"warning"},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.individuals.length)?_c('span',[_vm._v(" Please select "+_vm._s(_vm.$article(_vm.$getTerm("assessee")))+" "+_vm._s(_vm._f("term")("assessee")))]):_c('span',[_vm._v(" There are no "+_vm._s(_vm._f("pluralTerm")("assessee"))+" configured for this "+_vm._s(_vm._f("term")("assessment"))+" ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.individuals.length)?_c('span'):_c('span',[_vm._v("Please configure "+_vm._s(_vm._f("pluralTerm")("assessee"))+" using the "+_vm._s(_vm._f("term")("settings"))+" tab.")])]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }