<template>
  <v-card :loading="loading" flat v-if="availableIds.length || unavailableIds.length">
    <v-card-title v-if="$vuetify.breakpoint.mdAndUp"
      >Create {{ "assessment" | pluralTerm }}</v-card-title
    >
    <v-card-text>
      <sr-available-ids
        label="Assessment"
        entity="Group"
        :availableIds="availableIds"
        :unavailableIds="unavailableIds"
      ></sr-available-ids>
    </v-card-text>
    <v-card-text>
      <v-form v-model="valid" v-if="availableIds.length">
        <v-row>
          <v-col>
            <v-select
              :rules="[(v) => !!v || 'A rubric is required']"
              :items="rubrics"
              label="Rubric"
              item-text="text"
              item-value="val"
              v-model="rubric"
              v-if="rubrics.length"
            >
            </v-select>
            <em v-else>No frameworks found</em>
          </v-col>
        </v-row>
        <v-text-field
          :label="`${$getTerm('Assessment')} title`"
          v-model="title"
          @input="change"
          hint="Available placeholders: [group.name], [group.internalId], [group.tag],[group.parent.name], [group.parent.internalId], [group.tag],"
          :rules="[
            (v) => !!v || 'Title is required',
            (v) => v.length <= 150 || 'Title must be less than 150 characters',
          ]"
        ></v-text-field>
        <p
          v-if="
            availableIds.length && title && title.includes('[') && title.includes(']')
          "
        >
          <em>Sample title: {{ getTitle(groups[0]) }}</em>
        </p>
        <v-textarea
          :label="`Description`"
          v-model="description"
          @input="change"
        ></v-textarea>
        <v-text-field
          :label="`Date`"
          v-model="date"
          @input="change"
          type="date"
          :rules="[(v) => !!v || 'A date is required']"
        ></v-text-field>
        <v-combobox
          outlined
          dense
          hide-no-data
          single-line
          small-chips
          v-model="tags"
          chips
          clearable
          label="Tags"
          @input="change"
          multiple
          append-icon=""
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              dark
              @click="select"
              @click:close="removeTag(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="availableIds.length">
      <v-spacer></v-spacer>
      <v-btn text @click="reset" :disabled="changes == 0" color="warning">reset</v-btn>
      <v-btn text @click="create" :disabled="changes == 0 || !valid" color="success"
        >Create Assessments</v-btn
      >
    </v-card-actions>
    <v-alert v-if="response">
      {{ response }}
    </v-alert>
  </v-card>
  <v-card-text v-else class="text--center d-flex align-content-stretch">
    <em>Please select some groups first</em>
  </v-card-text>
</template>

<script>
import srAvailableIds from "./AvailableIds.vue";
//const regex = /({[^\s]*})/g;
//const regexReplace = /({|})/g;
export default {
  name: "BatchAssess",
  components: {
    srAvailableIds,
  },
  data() {
    return {
      title: "",
      tags: [],
      date: new Date().toISOString().split("T")[0],
      valid: false,
      changes: 0,
      saving: false,
      showSelected: false,
      error: null,
      rubric: null,
      description: "",
      selfAssessment: false,
      response: null,
    };
  },
  computed: {
    showGroups() {
      return this.showSelected ? this.availableIds : this.availableIds.slice(0, 2);
    },
    availableLength() {
      return this.availableIds.length;
    },
    rubrics() {
      return this.$store.getters["saved/rubricsByGroups"]([this.availableIds])
        .map((x) => {
          return {
            text: x.title,
            val: x.id,
          };
        })
        .filter((x) => !x.draft);
    },
    groups() {
      return this.availableIds
        ? this.$store.getters["saved/getArr"](["groups", this.availableIds]).map((x) => {
            var parent =
              x.path && x.path.length ? x.path.find((x) => x.level == 1) : null;
            if (parent) {
              var p = this.$store.getters["saved/get"](["groups", parent.groups[0]]);
              if (p) {
                x.parent = {
                  name: p.displayText,
                  tag: p.tagString,
                  internalId: p.internalId,
                };
              }
              x.name = x.displayText;
              x.tag = x.tagString;
              return x;
            }
          })
        : [];
    },
  },
  watch: {
    availableLength: function (newVal, oldVal) {
      if (newVal > oldVal) {
        this.frameworksChecked = false;
        this.availableFrameworks = [];
        this.framework = null;
      }
    },
  },
  methods: {
    reset() {
      this.title = "";
      this.date = null;
      this.tags = [];
      this.description = null;
      this.rubric = null;
    },
    change() {
      this.changes++;
    },
    removeTag(item) {
      debugger;
      var i = this.editableGroup.Tags.indexOf(item);
      this.editableGroup.Tags.splice(i, 1);
      this.editableGroup.Tags = [...this.editableGroup.Tags];
    },
    update() {
      this.saving = "secondary";
      var data = {
        groups: this.availableIds,
        tags: this.tags,
        title: this.title,
        rubric: this.rubric,
        date: this.date,
        selfAssessment: this.selfAssessment,
        description: this.description,
      };
      this.$post("assessments/batch", data).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.response = response;
          // this.$store.dispatch("saved/update_groups");
          this.$store.dispatch("saved/get_groups");
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
        this.reset();
      });
    },
    create() {
      if (this.valid) {
        this.saving = "secondary";

        debugger;
        //var titleArr = var result = str.match(regex);

        var assessments = this.groups.map((g) => {
          var name = this.getTitle(g);
          return {
            name: name,
            date: this.date,
            rubricId: this.rubric,
            groupId: g.id,
            selfAssessment: false,
            description: this.description,
            tags: this.tags,
          };
        });
        this.$post("assessments/batch", assessments).then((response) => {
          debugger;
          if (response.error) {
            this.error = response.text;
          } else {
            //  this.$store.dispatch("saved/get_assessments");
            this.$emit("update", "Changes Saved");
            this.reset();
          }
          this.response = response;

          this.saving = false;
        });
      }
    },
    getTitle(g) {
      var name = `${this.title}`;
      name = name.replaceAll("[group.name]", g.name);
      name = name.replaceAll("[group.parent.name]", g.parent.name);
      name = name.replaceAll("[group.internalId]", g.internalId);
      name = name.replaceAll("[group.parent.internalId]", g.parent.internalId);
      name = name.replaceAll("[group.tag]", g.tag);
      name = name.replaceAll("[group.parent.tag]", g.parent.tag);
      return name;
    },
  },
  props: {
    loading: {
      type: Boolean,
    },
    unavailableIds: {
      type: Array,
      default: () => [],
    },
    availableIds: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
