var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('sr-manage-members',{staticClass:"mb-2",attrs:{"group":_vm.group,"readonly":true,"people":_vm.people,"permissions":_vm.permissions,"nav":""}})],1),(_vm.group)?_c('v-col',[_c('sr-open-assessments',{attrs:{"title":"Open Assessment","groupId":_vm.group.id,"hideHeaders":true,"headers":[
        {
          text: 'Date',
          value: 'created',
          sortable: true,
        },
        {
          text: 'Title',
          value: 'assessmentTitle',
          sortable: true,
        },
        {
          text: 'Rubric',
          value: 'rubricTitle',
          sortable: true,
        },
        {
          text: 'Status',
          value: 'complete',
          sortable: false,
        },
        { text: 'Actions', value: 'actions' } ]}}),(
        _vm.groupType &&
        _vm.group &&
        _vm.permissions &&
        _vm.permissions.subgroupList.read &&
        _vm.group.subgroups.length
      )?_c('v-row',{key:_vm.group.id,staticClass:"my-2"},[_c('v-col',[_c('sr-group-widget',{attrs:{"allowFilter":false,"asTable":true,"showOpaque":true,"hideFilter":false,"title":("Groups in " + (_vm.group.metadata.name)),"showAll":true,"filterValues":{
            selectedGroupTypes: [],
            selectedParents: [_vm.group.id],
            showOnlyDirect: false,
            showOnlyAdmin: false,
            showOnlyNonAdmin: false,
          }}})],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }