<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" md="3" v-if="$vuetify.breakpoint.mdAndUp">
        <v-list nav>
          <v-list-item-group v-model="tab" vertical dense>
            <v-list-item
              dense
              color="secondary"
              v-for="m in members"
              :key="m.value"
              :value="m.value"
              :href="`#${m.value}`"
            >
              <v-list-item-avatar size="20">
                <v-img v-if="m && m.avatar" :src="m.avatar"></v-img>
                <v-icon v-else>fad fa-user</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ m.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <v-select
          solo
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="tab"
          :label="`Select ${groupType.memberName}`"
          :items="members"
        >
        </v-select>
        <keep-alive>
          <v-card v-if="selectedPerson" :loading="loading" :tile="app_info.tile" outlined>
            <v-toolbar flat dark color="primary" dense>
              <v-toolbar-title>
                {{ selectedPerson.text }}'s {{ groupType.baseline.title }}
                {{ "Profile" | term }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  icon
                  :to="{
                    name: 'PersonGroups',
                    params: { individualid: tab, groupid: group.id },
                  }"
                >
                  <v-icon>fal fa-user</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col md="5" v-if="tab && groupType.baseline && skills.length">
                  <assign-baseline
                    :personId="tab"
                    :baseline="groupType.baseline"
                    :membership="selectedPerson"
                    :items="skills"
                    :groupId="group.id"
                    @loading="toggleLoading"
                    @update="update"
                  >
                  </assign-baseline>
                </v-col>
                <v-col v-if="tab">
                  <baseline-skills
                    :skillProfile="skillProfile"
                    :skills="skills"
                    spliceProfile
                    :personName="selectedPerson.text"
                    :loading="reportLoading"
                  >
                  </baseline-skills>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-toolbar color="highlight" flat>
              <v-toolbar-title> Preview </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  :to="{
                    name: 'BaselineReport',
                    params: { individualid: tab, groupid: group.id },
                  }"
                >
                  <v-icon left>fad fa-print</v-icon> Go to Print View
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <baseline-text
              :key="changes + 'text'"
              embedded
              :skillProfile="skillProfile"
              :membership="selectedPerson"
              :skills="skills"
              :loading="reportLoading"
            >
            </baseline-text>
          </v-card>

          <div v-else>
            <sr-not-found :actions="false">
              <template v-slot:title
                >Select {{ getIndirectArticle(groupType.memberName) }}
                {{ groupType.memberName }} to view
                <span class="mx-2" v-if="group.writeMemberDocs"
                  >&nbsp;and assign &nbsp;</span
                >&nbsp; a {{ "profile" | term }}</template
              >
            </sr-not-found>
          </div>
        </keep-alive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AssignBaseline from "./AssignBaseline.vue";
import BaselineSkills from "../PersonComponents/BaselineReport_Skills.vue";
import BaselineText from "../PersonComponents/BaselineReport_Text.vue";
export default {
  name: "GroupBaseline",
  components: {
    AssignBaseline,
    BaselineSkills,
    BaselineText,
  },
  data() {
    return {
      tab: null,
      baselineData: [],
      dataReady: false,
      skills: [],
      error: null,
      loading: false,
      previewReport: true,
      reportData: [],
      reportLoading: false,
      changes: 0,
    };
  },
  watch: {
    tab: function () {
      this.init();
    },
  },
  methods: {
    update() {
      this.$emit("update");
    },
    toggleLoading(e) {
      this.loading = e;
      this.changes++;
    },
    init() {
      console.log("Initialising Report Data");
      this.reportData = [];
      if (this.tab != null) {
        this.reportLoading = true;
        this.$get(`reports/group/${this.group.id}/person/${this.tab}`).then(
          (response) => {
            if (response.error) {
              this.error = response.text;
            } else {
              this.reportData = response.data;
            }
            this.reportLoading = false;
          }
        );
      }
    },
  },
  created() {
    this.$get(`frameworks/tags`).then((response) => {
      debugger;
      if (response.error) {
        this.error = response.text;
      } else {
        this.skills = response.data ? response.data[0].skills : [];
      }
    });
  },
  mounted() {
    this.tab = this.members && this.members.length ? this.members[0].value : null;

    //this.init();
  },
  computed: {
    showPreview() {
      if (this.selectedPerson) {
        return (
          this.selectedPerson.baseline.inputs.filter((x) => x.value && x.value.length)
            .length != 0
        );
      }
      return false;
    },
    selectedPerson() {
      return this.tab ? this.members.find((x) => x.value == this.tab) : null;
    },
    skillProfile() {
      return this.reportData.length
        ? this.getSkillProfile(this.reportData, this.skills)
        : [];
    },
    members() {
      return this.group.members
        .filter((x) => !x.admin && x.active)
        .map((x) => {
          var person = this.people.find((p) => p.id == x.individualId);
          if (person) {
            return {
              text: person.displayText,
              value: person.id,
              baseline: x.baseline,
              avatar: person.avatar,
            };
          }
        })
        .filter((x) => x);
    },
  },
  props: {
    group: {
      type: Object,
    },
    groupType: {
      type: Object,
    },
    people: {
      type: Array,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
