<template>
    <router-view v-if="id" :groupid="groupid" :individualid="id"></router-view>
</template>

<script>

export default {
    name: "SelfWrapper",
    computed:{
        id(){
            return this.$auth.userId; 
        },
        groupid(){
            return this.$route.query.groupid;
        }
    }
}
</script>