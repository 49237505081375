<template>
  <v-card :loading="loadingReport || loadingGroup">
    <baseline-text
      v-if="showText"
      :skillProfile="skillProfile"
      :membership="membership"
      :skills="skills"
      :loading="loadingReport"
      :printView="printView"
      :showPrint="showPrint"
    >
    </baseline-text>
    <baseline-skills
      v-else
      :skillProfile="skillProfile"
      :skills="skills"
      spliceProfile
      :personName="membership.text"
      :loading="loadingReport"
      :printView="printView"
    >
    </baseline-skills>
  </v-card>
</template>

<script>
import BaselineText from "./BaselineReport_Text.vue";
import BaselineSkills from "./BaselineReport_Skills.vue";

export default {
  name: "BaselineReport",
  components: { BaselineText, BaselineSkills },
  data() {
    return {
      reportData: [],
      loadingFrameworkTags: false,
      error: null,
      frameworkTags: [],
      loadingReport: false,
      loadingGroup: false,
    };
  },
  watch: {
    personId: function () {
      this.init();
    },
    groupId: function () {
      this.init();
    },
  },
  created() {
    this.loadingReport = "secondary";
    if (!this.frameworkTags.length) {
      this.loadingFrameworkTags = "secondary";
    }
    this.init();
  },
  methods: {
    init() {
      this.$get(`reports/group/${this.group.id}/person/${this.person.id}`).then(
        (response) => {
          if (response.error) {
            this.error = response.text;
          } else {
            this.reportData = response.data;
          }
          this.loadingReport = false;
        }
      );
      this.$get(`frameworks/tags`).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.frameworkTags = response.data ? response.data[0].skills : [];
        }
        this.loadingFrameworkTags = false;
      });
    },
  },
  computed: {
    showText() {
      if (this.membership && this.membership.baseline) {
        var inputs = this.$r.clone(this.membership.baseline.inputs);
        return inputs.filter((x) => x.value && x.value.length).length != 0;
      }
      return false;
    },
    membership() {
      var result = this.group
        ? this.group.members.find((x) => x.individualId == this.person.id && x.active)
        : null;
      if (result != null) {
        result.text = this.getDisplayName(this.person);
        return result;
      }
      return null;
    },

    skills() {
      if (!this.loadingFrameworkTags && this.frameworkTags.length > 0) {
        return this.frameworkTags;
      } else {
        var arr = this.$r
          .clone(this.reportData)
          .map((x) => x.rubricObjectiveTags)
          .flat();
        var unique = [...new Set(arr)];
        var result = unique.map((x) => {
          return {
            name: x,
            description: "",
            icon: null,
          };
        });
        return result;
      }
    },
    groupTypeName() {
      return this.group != null
        ? this.$store.getters["saved/get"]([
            "groupTypes",
            this.group.groupTypeId,
          ]).name.toLowerCase()
        : null;
    },
    key() {
      return this.skills.length;
    },
    skillProfile() {
      return this.getSkillProfile(this.reportData, this.skills);
    },
  },
  props: {
    group: {
      type: Object,
    },

    person: {
      type: Object,
    },
    printView: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showPrint: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
