<template>
  <v-card tile flat>
    <v-row no-gutters>
      <v-col cols="12" md="4" lg="3">
        <v-tabs v-model="tab" :vertical="$vuetify.breakpoint.mdAndUp">
          <v-tabs-slider color="secondary"></v-tabs-slider>

          <v-tab v-for="item in actions" :key="item.name" v-model="tab">
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in actions" :key="item.name">
            <v-card flat class="border-left">
              <component
                :loading="loading"
                v-if="actionProps.find((x) => x.name == item.name)"
                v-bind="actionProps.find((x) => x.name == item.name).props"
                :is="item.component"
              >
              </component>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "MultipleActions",
  data() {
    return {
      tab: null,
      components: [],
      loading: false,
      actionProps: [],
    };
  },
  watch: {
    count: function () {
      console.log("count changed");
      this.init();
    },
  },
  mounted() {
    console.log("mounted");
    this.init();
  },
  computed: {
    count() {
      return this.items.length;
    },
  },
  methods: {
    init() {
      debugger;
      this.loading = true;
      this.actions.forEach((element) => {
        /*  var props = {
          availableIds: [],
          unavailableIds: [],
          assessments: element.data.ids,
        };
        */
        this.$post(this.url, element.data).then((response) => {
          debugger;
          if (response.error) {
            this.error = response.text;
          } else {
            element.props.availableIds = Object.keys(response.success);
            element.props.unavailableIds = Object.keys(response.failure).map((x) => {
              return {
                id: x,
                msg: response.failure[x],
              };
            });
            this.actionProps = this.actionProps.filter((x) => x.name != element.name);
            this.actionProps.push({
              name: element.name,
              props: [element.props],
            });
          }
        });
      });
      this.loading = false;
    },
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
    },
  },
};
</script>
