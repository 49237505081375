<template>
  <v-card
    v-if="comment"
    class="border-left mt-4 mb-8 border-secondary"
    style="border-width: 5px !important"
    flat
    :tile="app_info.tile"
  >
    <v-toolbar height="35" dense flat>
      <v-toolbar-items>
        <span class="d-print-inline d-none text-overline">Last updated by </span>
        <sr-person-chip
          :showAvatar="false"
          selectOption
          definite
          :id="
            comment.metadata.lastUpdatedBy
              ? comment.metadata.lastUpdatedBy
              : comment.metadata.createdBy
              ? comment.metadata.createdBy
              : $auth.userId
          "
        >
          <template v-slot:default>
            <span v-if="$vuetify.breakpoint.smAndUp">{{
              comment.metadata.lastUpdated | moment("DD MMM YYYY HH:mm")
            }}</span>
            <v-menu offset-y open-on-hover class="d-print-none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  class="px-2 ml-5 mr-1 d-print-none"
                  v-bind="attrs"
                  v-on="on"
                >
                  History
                </v-btn>
              </template>
              <v-list dense class="pt-0">
                <v-subheader class="highlight">Authors</v-subheader>
                <v-list-item>
                  <v-list-item-content class="pl-2">
                    <sr-person-chip selectOption :id="comment.metadata.createdBy">
                      <template v-slot:avatar>
                        <v-avatar left tile> + </v-avatar>
                      </template>
                      <template v-slot:default>
                        <span>
                          -
                          {{
                            comment.metadata.created | moment("DD MMMM YYYY HH:mm")
                          }}</span
                        >
                      </template>
                    </sr-person-chip>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="
                    comment.metadata.lastUpdatedBy &&
                    comment.metadata.lastUpdatedBy != comment.metadata.createdBy
                  "
                ></v-divider>
                <v-list-item
                  v-if="
                    comment.metadata.lastUpdatedBy &&
                    comment.metadata.lastUpdatedBy != comment.metadata.createdBy
                  "
                >
                  <v-list-item-content class="pl-2">
                    <sr-person-chip selectOption :id="comment.metadata.lastUpdatedBy">
                      <template v-slot:avatar>
                        <v-avatar left tile> E </v-avatar>
                      </template>
                      <template v-slot:default>
                        <span>
                          -
                          {{
                            comment.metadata.lastUpdated | moment("DD MMMM YYYY HH:mm")
                          }}</span
                        >
                      </template>
                    </sr-person-chip>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </sr-person-chip>
      </v-toolbar-items>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container class="py-0" fluid>
      <v-row no-gutter class="">
        <v-col id="comment" class="py-0" :class="{ grow: $vuetify.breakpoint.smAndUp }">
          <v-row>
            <v-col id="text" class="py-0" cols="12" :md="comment.file.length ? 8 : 12">
              <v-row>
                <v-col cols="12" id="commentField">
                  <div>
                    <div>
                      <v-alert dense outlined text v-if="errorMsg" color="info">
                        This {{ "comment" | term }} text contains images. Where possible,
                        please replace them with file attachments or links to externally
                        hosted images.
                      </v-alert>
                      <v-card-text class="py-0" v-html="comment.text"> </v-card-text>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" id="tagAndAttach">
                  <div class="ml-5">
                    <v-chip
                      small
                      v-for="(t, ti) in comment.tags"
                      class="mx-2"
                      :key="ti"
                      >{{ t }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              id="attachments"
              v-if="comment.file.length"
              :md="comment.file.length ? 4 : 2"
              class="no-padder"
            >
              <sr-file-input
                stretch
                :xs="$vuetify.breakpoint.smAndDown"
                flat
                readonly
                :parent="comment"
              ></sr-file-input>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
//const _ = require("lodash");
const expression = /<img([\w\W]+?)[\\/]?>/gi;
const imgRegex = new RegExp(expression);

export default {
  name: "Comment",
  data() {
    return {
      authorsMenu: false,
      errorMsg: null,
      errorSeverity: "",
    };
  },
  /*  watch: {
    commentText: function (nv, old) {
      if (nv != old) {
        console.log("debouncing");
        this.debouncedValidateComment();
      }
    },
  },
  */
  created() {
    this.validateComment(true);
  },

  methods: {
    validateComment(initial) {
      this.errorMsg = null;
      this.errorSeverity = "";
      var text = this.comment.text;
      if (text != null) {
        var imgs = text.match(imgRegex);
        var containsIllegal = imgs
          ? imgs
              .filter((i) => {
                if (i.toLowerCase().includes("base64")) {
                  return 1;
                }
              })
              .filter((i) => i)
          : [];
        if (imgs && containsIllegal.length) {
          console.log("image present");
          //   if (text.length > 30000) {
          //     this.errorMsg = `Unable to save comment due to large images. Please delete images from your comment and use the  Include images using the file attachment field instead in order to proceed.`;
          //     this.errorSeverity = "error";
          //   } else {
          if (initial) {
            this.errorMsg = `This ${this.getTerm(
              "comment"
            )} text contains large image files. Where possible, please use the file attachment field ${
              this.$vuetify.breakpoint.width < 960 ? "below" : ""
            } to add images, or link to an externally hosted image instead.`;
            this.errorSeverity = "info";
          } else {
            this.errorMsg = `Please do not include images in your ${this.getTerm(
              "comment"
            )} text. Include images using the file attachment field ${
              this.$vuetify.breakpoint.width < 960 ? "below" : ""
            } instead.`;
            this.errorSeverity = "error";
            //this.inputText(text);
          }

          // }
        }
      }
    },
  },
  props: {
    comment: {
      type: Object,
    },

    index: {
      type: Number,
    },
  },
};
</script>
