<template>
  <v-card :loading="saving" v-if="framework.write">
        <v-toolbar   dense flat>
            <v-toolbar-title>Edit Metadata</v-toolbar-title>

        </v-toolbar>
        <v-card-text>
            <v-form v-model="valid"  v-if="editableSettings" :key="resetCount">
         
                        <v-text-field 
                            outlined dense
                            :label="$getTerm('Title')"
                            :disabled="saving"

                            v-model="editableSettings.title"
                                @input="change"
                                :rules="[
                                 v => !!v || 'Name is required',
                                    v => v.length <= 50 || 'Name must be less than 50 characters',
                                ]"
                            >
                        </v-text-field>
                            <wysiwyg 
                            class="rounded mb-5"
                            :label="$getTerm('Title')"
                            v-model="editableSettings.description"
                            :disabled="saving"
                            @change="change"
                             
                            />
                                   
                       <v-combobox
                         outlined
                          dense
                        hide-no-data
                        single-line
                        small-chips
                        v-model="editableSettings.tags"
                        chips
                        clearable
                        label="Tags"
                        :disabled="saving"

                        @input="change"
                        multiple
                        append-icon=""
                        >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                            <v-chip
                                v-bind="attrs"
                                :input-value="selected"
                                close
                                class="my-1"
                                dark
                                @click="select"
                                @click:close="removeTag(item)"
                            >
                                {{item}}
                            </v-chip>
                            </template>
                        </v-combobox>
                       <v-row>
                           <v-col cols='12' md="4">
                            <v-switch 
                                v-model="editableSettings.public" 
                                @change="change"
                                :disabled="saving"
                                persistent-hint
                                hint="'Public' means it is available to anyone on this platform."
                                label="Public?">
                            </v-switch>
                           </v-col>
                           <v-col cols='12' md="4">
                                <v-switch 
                                    v-model="editableSettings.draft" 
                                    @change="change"
                                    :disabled="saving"
                                    hint="'Draft' means it is unavailable to group admins."
                                    persistent-hint
                                    label="Draft?">
                                </v-switch>
                           </v-col>
                           <v-col cols='12' md="4">
                            <v-switch 
                                v-model="editableSettings.static" 
                                @change="change"
                                :disabled="saving"
                                persistent-hint
                                hint="'Static' means rubrics based on this framework can't be modified."
                                label="Static?">
                            </v-switch>
                           </v-col>
                       </v-row>
               
               
                   
            </v-form>
        </v-card-text>
              <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn text @click="reset" :disabled="changes == 0 || saving" color="warning">reset</v-btn>
           <v-btn text @click="update" :disabled="changes == 0 || !valid || saving" color="success">Save Changes</v-btn>
       </v-card-actions>
  </v-card>
    <sr-not-found v-else>

    </sr-not-found>
</template>

<script>
export default {
    name: 'FrameworkEdit',
    data(){
        return{
            editableSettings: null,
            changes: 0,
            saving: false,
            valid: false,
            resetCount: 0,
        }
    },
    created(){
        this.reset();
    },
    methods:{
        change(){
            this.changes ++;
        },
        reset(){
            if(this.editableSettings != null)
            {
                this.editableSettings = null;
            }
            this.editableSettings = {};
            var settings = {
                id: this.framework.id,
                title: this.framework.title,
                tags: this.framework.tags,
                description: this.framework.description,
                draft: this.framework.isDraft,
                public: this.framework.isPublic,
                static: this.framework.isStatic,
                authors: this.framework.authors,
            };
            Object.assign(this.editableSettings, settings);
            this.resetCount ++;
        },
        removeTag (item) {
            debugger;
            var i = this.editableSettings.tags.indexOf(item)
            this.editableSettings.tags.splice(i , 1)
            this.editableSettings.tags = [... this.editableSettings.tags];
            this.change();
        },
  
        update(){
            this.saving = true;
            var data = this.editableSettings; 
             data.secret = this.framework.secret;
            this.$post("update/framework/settings", data).then((response)=>{
                debugger;
                if(response.error){
                    
                     this.$emit('error', this.errorText(response.text, 'save',this.$getTerm('framework')))
                }
                else{
                    //this.$store.dispatch('saved/get_frameworks')
                    this.changes = 0;
                    this.$emit('update', 'Changes Saved')
                }
                this.saving = false;
            });
       
        }
    },
    props:{
        framework: {
            type:Object
        },
        permissions: {
            type: Object,
        },
    
    }
}
</script>