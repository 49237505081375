<style scoped>
.v-list-item__action span.fa-layers {
  color: var(--v-dark-lighten2);
}
.v-list-item--active .v-list-item__action span.fa-layers {
  color: inherit !important;
}
.v-list-item--active .v-avatar {
  box-shadow: 0 0 4px 2px var(--v-primary-base);
}
</style>

<template>
  <div>
    <div style="height: 70vh; overflow: auto; padding-bottom: 70px">
      <v-list class="no-padder">
        <v-list-item
          link
          :class="{ 'd-none': $vuetify.breakpoint.lgAndUp }"
          exact
          @click="logout"
          active-class="secondary--text text--darken-2"
        >
          <v-list-item-icon>
            <v-icon>fad fa-fw fa-sign-out</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item>
        <v-divider :class="{ 'd-none': $vuetify.breakpoint.lgAndUp }"></v-divider>
        <sr-bookmarks
          ref="bookmarks"
          :pageTitle="pageTitle"
          :personId="self.id"
          v-if="!mini"
        >
        </sr-bookmarks>
        <div v-for="(s, i) in sideBarItems" :key="i">
          <v-list-item
            v-if="!s.subheader && (!s.showFn || s.showFn(props))"
            link
            :class="{ 'd-none': (s.miniOnly && !mini) || (s.maxOnly && mini) }"
            exact
            :to="s.to"
            :href="s.href"
            active-class="secondary--text text--darken-2"
          >
            <v-list-item-icon v-if="s.gravatar">
              <v-avatar size="30px">
                <img alt="Avatar" :src="getGravatar($auth.user.email, 30)" />
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-icon v-else-if="s.icon">
              <v-icon>fad fa-fw {{ s.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ s.title(props) | term }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
      <v-list class="hidden">
        <v-divider></v-divider>
        <v-subheader v-if="!mini" class="overline">Reports</v-subheader>

        <v-list-item
          dense
          v-for="(g, i) in groupItems"
          :key="i"
          link
          :class="{ 'd-none': (g.miniOnly && !mini) || (g.maxOnly && mini) }"
          exact
          :to="g.to"
          active-class="secondary--text text--darken-2"
        >
          <v-badge inline left dot>
            <v-list-item-title class="pl-2">{{ g.text }}</v-list-item-title>
          </v-badge>
        </v-list-item>
      </v-list>
    </div>

    <v-list
      color="light"
      dense
      v-if="props.isAuthAdmin || props.isDataManager"
      flat
      class="no-padder"
      style="width: 100%; position: fixed; bottom: 0px; margin: 0px !important"
    >
      <v-divider></v-divider>
      <v-list-item link exact :to="{ name: 'Settings' }">
        <v-list-item-icon>
          <v-icon class="fad fa-cog fa-fw"></v-icon>
        </v-list-item-icon>
        <v-list-item-title>Settings</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  methods: {
    logout() {
      this.$logout();
    },
  },
  computed: {
    help() {
      return process.env.VUE_APP_HELP_URI;
    },
    bookmarks() {
      // var org_Id = this.org_id;
      debugger;
      return this.self != null ? this.self.bookmarks : [];
    },
    groupItems() {
      return this.$store.getters["entities/sidebarItems"];
    },
    self() {
      return this.$store.getters["saved/self"];
    },
    isAdministrator() {
      return this.$store.getters["saved/getAll"]("groups").filter(
        (x) => !x.readonly || x.directAdmin
      );
    },
    props() {
      return {
        isAuthor: this.$auth.isAuthor,
        isAdministrator: this.isAdministrator.length,
        help: this.help,
        isAuthAdmin: this.$auth.isAdmin,
        isDataManager: this.$auth.isDataManager,
      };
    },
  },
  props: {
    sideBarItems: {
      type: Array,
      default: () => [],
    },

    mini: {
      type: Boolean,
      default: false,
    },
    pageTitle: {
      type: String,
      default: "Home",
    },
  },
};
</script>
