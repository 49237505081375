<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :value="selectedAssessments"
      @input="update"
      disable-sort
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
      class="elevation-1"
      :show-select="selectable && totalItems <= 100"
    >
      <template v-slot:item.picture="{ item }">
        <v-avatar size="36">
          <v-img :src="item.picture"></v-img>
        </v-avatar>
      </template>
      <template v-slot:item.created="{ item }">
        <div class="d-flex align-center">
          {{ item.created | moment(app_info.shortDate) }}
        </div>
      </template>
      <template v-slot:item.groupId="{ item }">
        <sr-group-chip flat newWindow path :id="item.groupId"> </sr-group-chip>
      </template>
      <template v-slot:item.frameworkId="{ item }">
        <sr-framework-chip flat newWindow path :id="item.frameworkId">
        </sr-framework-chip>
      </template>
      <template v-slot:item.nodes="{ item }">
        <sr-group-chip flat newWindow path v-for="n in item.nodes" :key="n" :id="n">
        </sr-group-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="goToAssessment(item, linkTarget, null, true)">
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    update(e) {
      this.$emit("input", e);
    },
    getDataFromApi() {
      this.loading = true;
      debugger;
      var options = {
        pageNumber: this.options.page - 1,
        pageSize: this.options.itemsPerPage,
        search: this.search,
      };
      this.$post(this.url, options).then((data) => {
        debugger;

        if (!data.error) {
          this.items = data.items;
          this.totalItems = data.totalItems > 0 ? data.totalItems : data.items.length;
        }
        this.loading = false;
      });
    },
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "permissions/users",
    },
    selectedAssessments: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    linkTarget: {
      type: String,
      default: "AssessmentHome",
    },
    headers: {
      type: Array,
      default: () => [
        { text: "", value: "picture" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
      ],
    },
  },
};
</script>
