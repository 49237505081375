<template>
  <v-card :loading="loading || validating">
    <v-form v-model="valid">
      <v-row>
        <v-col class="grow">
          <v-card-text>
            <v-row v-if="$auth.isAdmin || $auth.isDataManager">
              <v-col>
                <v-checkbox
                  label="Create User Accounts"
                  :disabled="loading"
                  v-model="createAccounts"
                >
                </v-checkbox>
                <v-checkbox
                  :disabled="loading"
                  label="Send Email Invitations"
                  v-model="sendEmailInvitations"
                >
                </v-checkbox>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col class="grow">
                <v-file-input
                  v-model="file"
                  :rules="[(v) => !!v || 'You must upload a file']"
                  @change="loadCSV($event)"
                  accept=".csv"
                  :disabled="loading"
                  label="CSV File"
                  placeholder="Click here to select a file"
                >
                </v-file-input>
              </v-col>
              <v-col class="shrink" v-show="file">
                <v-chip outlined v-show="csvValid && !validating" color="success"
                  ><v-icon left>fal fa-check</v-icon> CSV Valid</v-chip
                >
                <v-chip outlined v-show="!csvValid && !validating" color="error"
                  ><v-icon left>fal fa-times</v-icon> Invalid file</v-chip
                >
                <v-chip outlined v-show="validating" color="warning"
                  ><v-icon left>fal fa-spinner fa-spin</v-icon> Validating</v-chip
                >
              </v-col>
              <v-col class="shrink" v-show="!file">
                <v-chip outlined><i class="fal fa-upload mr-3"></i> Upload File</v-chip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col md="5" cols="12" class="highlight no-padder" v-if="!file || !csvValid">
          <v-alert tile prominent type="info" color="secondary">
            Include the following headers <strong>ONLY </strong> in your CSV file:
          </v-alert>
          <v-card-text>
            <div class="overline">Required</div>
            <div class="mb-2">
              <v-chip
                color="secondary"
                class="ma-1"
                v-for="(r, i) in requiredHeaders"
                :key="i"
                >{{ r }}</v-chip
              >
            </div>
            <div class="overline">Optional</div>
            <div class="my-2">
              <v-chip
                color="primary"
                class="ma-1"
                v-for="(r, i) in optionalHeaders"
                :key="i"
                >{{ r }}</v-chip
              >
            </div>
          </v-card-text>
        </v-col>
        <v-col v-else cols="12">
          <v-alert
            dense
            tile
            type="error"
            v-if="
              createAccounts && individualsToImport.filter((x) => !x.email).length != 0
            "
          >
            You selected 'Create Accounts', but some of the people you are trying to
            import have missing or invalid email addresses.
          </v-alert>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="importIndividuals"
              :disabled="!valid || loading"
              large
              color="secondary"
              >Import {{ groupType.memberName | pluralize }}</v-btn
            >
          </v-card-actions>
          <v-data-table :items="individualsToImport" :headers="headers">
            <template v-slot:item.email="{ item }">
              <span v-if="item.email">{{ item.email }}</span>
            </template>
            <template v-slot:item.createUser="{ item }">
              <v-icon v-show="item.email">fal fa-check</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
export default {
  name: "CsvImport",
  data() {
    return {
      file: null,
      createAccounts: true,
      sendEmailInvitations: true,
      createAssessment: true,
      rubricId: null,
      assessmentName: null,
      isSelfAssessment: true,
      users: [],
      validating: false,
      parse_csv: [],
      parse_header: [],
      sortOrders: {},
      sortKey: "",
      valid: false,
    };
  },
  methods: {
    sortBy: function (key) {
      var vm = this;
      vm.sortKey = key;
      vm.sortOrders[key] = vm.sortOrders[key] * -1;
    },
    csvJSON(csv) {
      var vm = this;
      var lines = csv.split("\n");
      var result = [];
      var neededHeaders = vm.requiredHeaders.map((x) => {
        return x && x.trim().length != 0 ? vm.toCamelCaseString(x) : null;
      });
      var headers = lines[0]
        .split(",")
        .filter((x) => x.trim().length > 0)
        .map((x) => {
          return x && x.trim().length != 0 ? vm.toCamelCaseString(x) : null;
        });

      var intersect = neededHeaders.filter((x) => {
        return headers.includes(x);
      });
      var validHeaders = intersect.length >= neededHeaders.length;
      if (validHeaders) {
        vm.parse_header = lines[0].split(",").filter((x) => x.trim().length > 0);
        lines[0].split(",").forEach(function (key) {
          vm.sortOrders[key] = 1;
        });
        lines.map(function (line, indexLine) {
          if (indexLine < 1) return; // Jump header line

          var obj = {};
          var currentline = line.split(",");

          headers.map(function (header, indexHeader) {
            if (header && header.trim().length != 0) {
              var string = currentline[indexHeader];
              if (
                (header.toLowerCase().includes("name") ||
                  header.toLowerCase().includes("title")) &&
                string &&
                string.length != 0
              ) {
                string = vm.toTitleCase(string);
              }
              obj[header] =
                string && string.length != 0 && string.trim().length != 0
                  ? string.trim()
                  : null;
            }
          });
          var length = Object.values(obj).filter((x) => x).length;

          if (length != 0) {
            result.push(obj);
          }
        });

        // result.pop() // remove the last item because undefined values

        return result; // JavaScript object
      }
      return null;
    },
    loadCSV() {
      if (event.target && event.target.files && event.target.files.length != 0) {
        this.validating = true;
        var vm = this;
        if (window.FileReader) {
          var reader = new FileReader();
          reader.readAsText(event.target.files[0]);
          // Handle errors load
          reader.onload = function (event) {
            var csv = event.target.result;
            vm.parse_csv = vm.csvJSON(csv) ?? [];
          };
          reader.onerror = function (evt) {
            this.validating = false;
            if (evt.target.error.name == "NotReadableError") {
              alert("The file cannot be read");
            }
          };
        } else {
          alert("FileReader are not supported in this browser.");
        }
        this.validating = false;
      } else {
        this.parse_csv = [];
      }
    },
    importIndividuals() {
      if (this.valid) {
        var data = {
          sendEmail: this.sendEmailInvitations,
          inviterName: this.self
            ? `${this.self.firstName} ${this.self.lastName}`
            : `${this.app_info.name} Admin`,
          inviterEmail: this.app_info.emailContact, //this.self ? this.self.username : "support@smartrubric.com",
          clientId: this.app_info.clientId,
          connectionName: this.app_info.connectionNames[0],
          msgTemplateId: this.app_info.emailTemplateId,
          people: this.individualsToImport,
          groupId: this.group,
        };
        this.$emit("import", data);
      }
    },
  },
  computed: {
    headers() {
      var result = [];
      if (this.requiredHeaders && this.optionalHeaders) {
        result = this.requiredHeaders
          .concat(this.optionalHeaders)
          .map((x) => {
            var val = x && x.trim().length != 0 ? this.toCamelCaseString(x) : null;

            if (val) {
              return {
                text: x.trim(),
                value: val,
              };
            }
            return null;
          })
          .filter((y) => y);
        if (this.createAccounts) {
          result.push({ text: "Can Create Account", value: "createUser" });
        }
      }

      return result;
    },
    csvValid() {
      return this.individualsToImport.length != 0;
    },
    self() {
      return this.$store.getters["saved/self"];
    },
    individualsToImport() {
      return this.parse_csv.map((x) => {
        var validEmail = x.email ? this.validateEmail(x.email) : false;
        var result = {
          firstName: x.firstName,
          lastName: x.lastName,
          email: validEmail ? x.email : null,
          gender: x.gender ?? null,
          nickname: x.nickname ?? null,
          title: x.title ?? null,
          // password: x.password ?? null,
          groupId: null,
          admin: false,
          createUser: this.createAccounts,
          roles: ["rol_33OrPJsMkyh5wRCo"],
          inviterName: this.self
            ? `${this.self.firstName} ${this.self.lastName}`
            : `${this.app_info.name} Admin`,
          inviterEmail: this.app_info.emailContact, //this.self ? this.self.username : "support@smartrubric.com",
          sendEmail: this.sendEmailInvitations,
          clientId: this.app_info.clientId,
          connectionName: this.app_info.connectionNames[0],
          msgTemplateId: this.app_info.emailTemplateId,
          msg: {
            subject: `Invitation to join ${this.app_info.name}`,
            name: `${x.firstName} ${x.lastName}`,
            primaryCTA: {
              url: `${window.location.origin}/invitation`,
              text: "Join Now",
            },
            salutation: "Dear ",
            primaryMessage: `I'd like you to join ${this.groupName} on the ${this.app_info.name} platform.`,
            secondaryMessage: `Your username is ${x.email}. If you already have an account, you can log in <a href="${window.location.origin}/app">here</a>.`,
            signature: this.signature,
          },
        };

        return result;
      });
    },
    signature() {
      var self = this.$store.getters["saved/self"];
      var result = {};
      if (self) {
        var n = this.getDisplayName(self);
        if (self.defaultEmailSignature) {
          result = {
            name: n,
            greeting: self.defaultEmailSignature.greeting ?? "Dear ",
            closing: self.defaultEmailSignature.closing ?? "All the best,",
            email: self.defaultEmailSignature.email ?? { address: self.username },
            phone: self.defaultEmailSignature.phone ?? null,
          };
        } else {
          result = {
            name: n,
            greeting: "Dear ",
            closing: "All the best,",
            email: { address: self.username },
          };
        }
        result.title = `${this.adminName} (${this.groupName})`;
      }
      return result;
    },
    requiredHeaders() {
      var h = ["First Name", "Last Name"];
      if (this.createAccounts) {
        h.push("Email");
      }
      return h;
    },
    optionalHeaders() {
      var h = ["Nickname", "Title", "Gender"];
      if (!this.createAccounts) {
        h.push("Email");
      } else {
        //  h.push("Password")
      }
      return h;
    },
    groupName() {
      var result = this.group
        ? this.$store.getters["saved/get"](["groups", this.group])
        : null;
      return result != null ? result.displayText : "group";
    },
  },
  props: {
    loading: {
      type: String,
    },
    group: {
      type: String,
    },
    groupType: {
      type: Object,
    },
  },
};
</script>
