<template>
  <div>
    <div class="pa-2" v-if="items && reportView">
      <sr-report-comment
        v-for="(comment, i) in items"
        :tags="tags"
        :key="i"
        :index="i"
        @input="updateComment"
        :comment="comment"
        @delete="removeItem"
      >
      </sr-report-comment>
    </div>
    <div class="pa-2" v-if="items && !reportView">
      <sr-comment
        v-for="(comment, i) in items"
        :tags="tags"
        :key="`${i}Edit`"
        :index="i"
        @input="updateComment"
        :comment="comment"
        @delete="removeItem"
        :disabled="readonly"
      >
      </sr-comment>

      <v-card-actions v-if="!readonly && !reportView">
        <v-btn block small text @click="addItem"
          ><v-icon left>fal fa-plus</v-icon> {{ label }}</v-btn
        >
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import entities from "../../objects/entities.js";
import srComment from "../Inputs/Comment.vue";
import srReportComment from "../Inputs/ReadonlyComment.vue";

export default {
  name: "EditComments",
  components: { srComment, srReportComment },
  data() {
    return {
      e: entities,
      changes: 0,
      focusCount: 0,
    };
  },
  created() {
    if (this.preserveAuthorship) {
      var userId = this.$auth.userId;
      this.locked = this.items.map((i) => {
        var metadataExists =
          i.metadata != null && (i.metadata.createdBy || i.metadata.lastUpdatedBy);
        var locked = false;
        if (metadataExists) {
          locked = ![i.metadata.createdBy, i.metadata.lastUpdatedBy].includes(userId);
        }
        return locked;
      });
    }
  },
  methods: {
    person(i) {
      return i.metadata ? i.metadata.createdBy : null;
    },
    updateComment(e) {
      debugger;
      if (e.comment && e.index != null) {
        if (!e.comment.metadata) {
          e.comment.metadata = {};
        }
        e.comment.metadata.lastUpdatedBy = this.$auth.userId;
        e.comment.metadata.lastUpdated = new Date();
        Object.assign(this.items[e.index], e.comment);
        console.log(this.items);
        this.update();
      }
    },
    addItem() {
      if (this.type) {
        var i = {};
        i = this.$r.clone(this.e[this.type]);
        if (i.metadata != null) {
          i.metadata.createdBy = this.$auth.userId;
          i.metadata.created = new Date();
        }
        this.items.push(i);
        if (this.preserveAuthorship) {
          this.locked.push(false);
        }
      } else {
        this.items.push(null);
      }
      this.$emit("change", this.items);
    },
    removeItem(i) {
      debugger;
      var hasValue = this.items[i][this.valueField];
      var confirm = hasValue
        ? window.confirm(
            `Are you sure you want to remove this ${this.label.toLowerCase()}? This action cannot be undone.`
          )
        : true;
      if (confirm) {
        this.items.splice(i, 1);
        if (this.locked.length && this.preserveAuthorship) {
          this.locked.splice(i, 1);
        }
        this.$emit("change", this.items);
      }
    },
    update() {
      console.log("emitting change");
      this.$emit("change", this.items);
    },
  },
  computed: {
    headers() {
      var result = [this.label];
      if (this.hasPrimary) {
        if (this.readonly) {
          result.push("Primary");
        } else {
          result.push("Primary?");
        }
      }
      if (!this.readonly) {
        result.push("Remove");
      }
      return result;
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    rich: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: null,
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    valueField: {
      type: String,
    },
    hasPrimary: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Item",
    },
    showHeaders: {
      type: Boolean,
      default: true,
    },
    tags: {
      type: Array,
      default: function () {
        return [];
      },
    },
    allowCreateTags: {
      type: Boolean,
    },
    showAuthors: {
      type: Boolean,
    },
    replies: {
      type: Boolean,
    },
    preserveAuthorship: {
      type: Boolean,
    },
    allowFileUpload: {
      type: Boolean,
      default: true,
    },
    reportView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
