import axios from "axios";
const root = process.env.VUE_APP_API_URL + "entities";
const query = `?orgId=${config.orgId}`;
import config from '../../configs/template.js';
import helpers from '../../assets/scripts/helpers.js';
const help = helpers;

const getters =  {
    methods:{
        getGroup: function(id){
            if(!id){
                return 'Invalid Id';
            }
            const i = id;
            var valid = help.validateId(i);
            if(!valid){
                return 'Invalid Id';
            }
            var url = root + "/groups/"+id+ query;
            return  this.$app.$auth.getTokenSilently().then((t) => {
                const config = {
                headers: {
                    authorization: "Bearer " + t,
                },
                };
            return axios.get(url, config).then((response) => {
            //  commit('setGroup', response.data);
                return response.data;
            })
            .catch((error, response)=>{
                // console.log(error)
                return {error: true, text: `${error}: ${response}`};
            });
            });
        }
    }
    
        
}

export default getters;