<template>
   <v-card outlined>
        <sr-not-found thing="timeline">
            <template v-slot:title>Timeline</template>
            <template v-slot:content>Coming Soon</template>
            
        </sr-not-found>
   </v-card>
</template>

<script>
export default {
    name: 'Timeline'
}
</script>