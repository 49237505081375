<template>
  <v-tabs
    v-if="rubricid && tabList.length > 1"
    :grow="grow"
    :vertical="vertical"
    :color="sliderColor"
    :right="right"
    :optional="optional"
  >
    <v-tabs-slider></v-tabs-slider>
    <v-tab
      v-for="(t, i) in tabList"
      :key="i"
      :exact="t.exact"
      :to="{ name: t.name, props: { rubricid: rubricid }, query: $route.query }"
    >
      <i :class="t.icon" class="fad mr-2"></i>
      <span v-if="t.filter == 'term'">{{ t.text | term }}</span>
      <span v-else>{{ t.text | pluralTerm }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "tabList",
  data() {
    return {
      tabList: [],
    };
  },
  created() {
    var result = this.tabs;
    result.push({
      text: "Assessment",
      filter: "pluralTerm",
      exact: true,
      icon: "fa-edit",
      name: "RubricAssessments",
    });
    if (
      this.permissions &&
      this.permissions.permissionObject &&
      this.permissions.permissionObject.editSchema
    ) {
      /*    result.push({
                        text: 'Report',
                        filter: 'pluralTerm',
                        exact: true,
                        icon: 'fa-chart-bar',
                        name: 'FrameworkReport', 

                    });
       */
      result.push({
        text: "Edit",
        filter: "term",
        exact: true,
        icon: "fa-edit",
        name: "RubricEditSchema",
      });
    }
    if (
      this.permissions &&
      this.permissions.permissionObject &&
      this.permissions.permissionObject.editRubricMetadata
    ) {
      result.push({
        text: "Settings",
        filter: "term",
        exact: false,
        icon: "fa-cog",
        name: "RubricSettings",
      });
    }

    this.tabList = result.filter((x) => !this.exclude.includes(x.name));
  },
  computed: {},
  props: {
    exclude: {
      type: Array,
      default: () => [],
    },
    optional: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => {
        return [
          {
            text: "Schema",
            filter: "term",
            exact: true,
            icon: "fa-th",
            name: "RubricHome",
          },
        ];
      },
    },
    permissions: {
      type: Object,
    },
    right: {
      type: Boolean,
      default: false,
    },
    iconWeight: {
      type: String,
      default: "fad",
    },
    grow: {
      type: Boolean,
      default: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    rubricid: {
      type: String,
    },
    canWrite: {
      type: Boolean,
      default: false,
    },
    sliderColor: {
      type: String,
      default: "secondary",
    },
  },
};
</script>
