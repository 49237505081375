<template>
  <div v-if="allowAccess">
    <v-navigation-drawer
      v-if="self"
      class="d-print-none"
      v-model="drawer"
      app
      clipped
      :mini-variant.sync="mini"
      :permanent="$vuetify.breakpoint.lgAndUp"
      style="padding-top: 10px"
    >
      <div v-if="self.id && !mini" class="hover text-center pa-5 mt-5">
        <v-btn
          :to="{ name: 'PersonHome', params: { individualid: $auth.userId } }"
          icon
          style="width: 100px; height: 100px"
        >
          <v-avatar size="100px">
            <img alt="Avatar" :src="getGravatar($auth.user.email, 150)" />
          </v-avatar>
        </v-btn>

        <h3
          class="mt-3 text--secondary text--lighten-2"
          :key="displayName"
          v-html="displayName"
        ></h3>
      </div>

      <side-bar
        ref="sidebar"
        :class="{ 'border-top': !mini }"
        :mini="mini"
        :sideBarItems="sideBarItems"
        :pageTitle="pageTitle"
      >
      </side-bar>
    </v-navigation-drawer>
    <nav-bar
      class="d-print-height-zero"
      style=" 20px"
      v-if="!loading && ready"
      :self="self"
      :hideDrawerToggle="!self"
      @toggleDrawer="toggleDrawer"
      @toggleMini="mini = !mini"
      :mdAndUp="$vuetify.breakpoint.lgAndUp"
      :iconUrl="getImgUrl(app_info.toolbarIcon ? app_info.toolbarIcon : 'icon')"
    >
    </nav-bar>
    <v-main class="mb-5">
      <v-container v-if="!hasSessionData && postLoad">
        <not-found icon="fad fa-wrench" color="primary">
          <template v-slot:title> Welcome to {{ app_info.name }}. </template>
          <template v-slot:description> We are getting things ready for you. </template>
          <template v-slot:body> Your administrator will contact you. </template>
        </not-found>
      </v-container>

      <v-container v-else-if="!hasSessionData">
        <loading></loading>
      </v-container>
      <router-view
        v-if="hasSessionData"
        :template="app_template"
        style="padding-bottom: 200px"
      >
      </router-view>
    </v-main>
    <v-footer app inset absolute>
      <v-container>
        <v-row justify="center">
          <v-col md="6" lg="4" cols="8" class="d-print-none">
            <v-row class="text-center">
              <v-col v-for="(social, i) in app_info.social_links" :key="i">
                <a :href="social.url">
                  <v-avatar color="light">
                    <i class="fa-2x" :class="social.icon"></i>
                  </v-avatar>
                </a>
              </v-col>
            </v-row>
            <div class="overline text-center">
              &copy; {{ new Date() | moment("YYYY") }}
              <span v-html="app_info.copyright"></span>
            </div>
          </v-col>
          <v-col class="d-print-block d-none">
            <small>
              &copy; {{ new Date() | moment("YYYY") }}
              <span v-html="app_info.print_copyright"></span>
            </small>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
  <v-container v-else class="mt-5 pt-5">
    <v-row class="mt-5">
      <v-col class="mt-5">
        <sr-not-found unauthorized actions icon="fad fa-user-slash" color="error">
          <template v-slot:title>
            This is a testing version of {{ app_info.name }}
          </template>
          <template v-slot:body>
            You do not have permission to access this site with your current log in.
            Please contact support if you believe this is an error.
          </template>
          <template v-slot:secondaryAction>
            <v-btn color="secondary" @click="$logout()">Log Out</v-btn>
          </template>
          <template v-slot:primaryAction>
            <v-btn color="primary" href="https://ispselfeval.smartrubric.com"
              >Go to production site</v-btn
            >
          </template>
        </sr-not-found>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const branch = process.env.VUE_APP_BRANCH;
import SideBar from "../newComponents/SideBar.vue";
import Loading from "../newComponents/Loading.vue";
import NotFound from "../newComponents/NotFound.vue";
export default {
  name: "AppWrapper",
  components: {
    SideBar,
    Loading,
    NotFound,
  },
  watch: {
    connected: function () {
      if (this.connected && (!this.self || !this.groupTypes || !this.grouptypes.length)) {
        this.loading == true;
      } else {
        this.loading == false;
      }
    },
  },
  data() {
    return {
      drawer: null,
      mini: true,
      lock: false,
      postLoad: false,
      loadingData: false,
      branch: branch,
    };
  },
  created() {
    this.init();
  },
  computed: {
    allowAccess() {
      var isDevelopment = branch.toLowerCase() != "production";
      var isTester = this.$auth.isTester;

      if (isDevelopment == false) {
        return true;
      }
      if (isDevelopment && isTester) {
        return true;
      }
      return false;
    },
    pageTitle() {
      var entities = [];
      this.nameInfo.forEach((item) => {
        debugger;
        if (item.getter && item.id) {
          var entity = this.$store.getters["saved/get"]([item.getter, item.id]);
          if (entity) {
            entities.push({
              index: item.index,
              name: entity.displayText,
            });
          }
        }
      });
      if (!this.nameInfo.length) {
        entities.push({
          index: 0,
          name: this.$route.meta.bcLinkText,
        });
      }
      var title = "";
      entities.forEach((e) => {
        title = `${title}${e.index > 0 ? " | " : ""} ${e.name} `;
      });
      return title;
    },
    hasSessionData() {
      return this.$store.getters["saved/hasSessionData"];
    },
    connected() {
      return !this.loadingData ? this.$store.getters["saved/connected"] : true;
    },
    template() {
      return this.app_template.app;
    },
    displayName() {
      if (this.self) {
        return this.getDisplayName(this.self);
      }
      return null;
    },
    self() {
      return this.$store.getters["saved/self"];
    },
    ready() {
      return this.$store.getters["saved/ready"]("self");
    },
    isError() {
      return this.ready ? this.ready.error : false;
    },
    lastUpdated() {
      return this.ready ? this.ready.updated : null;
    },
    loading() {
      return this.$auth.loading;
    },
    loggedIn() {
      return this.$auth.isAuthenticated;
    },
    sideBarItems() {
      return this.app_template.app.sidebarMenu.menuItems;
    },
  },
  methods: {
    toggleLoading() {
      this.loading = !this.loading;
    },
    logout() {
      this.$logout();
    },
    toggleDrawer() {
      var lgAndUp = this.$vuetify.breakpoint.lgAndUp;
      if (!lgAndUp && !this.drawer) {
        this.drawer = true;
        this.mini = false;
      } else if (!lgAndUp && this.drawer) {
        this.drawer = false;
        this.mini = true;
      }
      if (lgAndUp) {
        this.mini = !this.mini;
      }
    },
    init() {
      //if user has roles

      var roles = this.$auth.roles;
      if (roles.length) {
        var alreadyInit = this.$store.getters["saved/initialised"];
        if (!alreadyInit) {
          this.loadingData = true;

          this.$store.dispatch("saved/getSessionData").then((success) => {
            console.log("success " + success);
            if (success) {
              this.$store.dispatch("saved/init");
              this.loadingData = false;
            } else {
              console.log("no session data available");
              this.$store.dispatch("saved/resetSessionData").then((success) => {
                console.log("success " + success);
                if (success) {
                  this.$store.dispatch("saved/init");
                }
                this.loadingData = false;
              });
            }
          });
        }
      } else {
        this.postLoad = true;
      }
    },
  },
  props: {
    nameInfo: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
