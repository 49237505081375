<template>
<v-container>

        <sr-breadcrumbs :items="[{text: 'Home',to:{name: 'Home'} }, {text: 'Organization', to:{name: 'GroupType'}, disabled: true}]"></sr-breadcrumbs>

    <v-row>
        <v-col>    
            <sr-group-type-widget></sr-group-type-widget>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    name: 'GroupTypeView'
}
</script>