<template>
  <v-card :loading="saving">
    <v-toolbar dense flat>
      <v-toolbar-title>Edit {{ groupType.name }} Metadata</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form v-model="valid" v-if="editableGroup" :key="resetCount">
        <v-row>
          <v-col>
            <v-text-field
              outlined
              dense
              :label="`${groupType.name} Name`"
              v-model="editableGroup.metadata.name"
              @input="change"
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => v.length <= 50 || 'Name must be less than 50 characters',
              ]"
            >
            </v-text-field>
            <v-text-field
              outlined
              dense
              label="Internal Unique Id"
              v-model="editableGroup.internalId"
              @input="change"
            >
            </v-text-field>
            <v-combobox
              outlined
              dense
              hide-no-data
              single-line
              small-chips
              v-model="editableGroup.tags"
              chips
              clearable
              label="Tags"
              @input="change"
              multiple
              append-icon=""
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  dark
                  :color="editableGroup.metadata.primaryColor"
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  {{ item }}
                </v-chip>
              </template>
            </v-combobox>
            <v-switch
              v-model="editableGroup.active"
              @change="change"
              :color="editableGroup.metadata.primaryColor"
              label="Active?"
            >
            </v-switch>
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <v-color-picker
              dot-size="25"
              @input="change"
              hide-inputs
              v-model="editableGroup.metadata.primaryColor"
              swatches-max-height="200"
              :swatches="swatches"
              show-swatches
              hide-canvas
              hide-sliders
            ></v-color-picker>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="$auth.isAdmin"
        @click="deleteGroup"
        :disabled="saving != false"
        color="error"
        >delete</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn text @click="reset" :disabled="changes == 0" color="warning">reset</v-btn>

      <v-btn text @click="update" :disabled="changes == 0 || !valid" color="success"
        >Save Changes</v-btn
      >
    </v-card-actions>
    <v-alert type="error" v-if="error">{{ error }}</v-alert>
  </v-card>
</template>

<script>
export default {
  name: "GroupEdit",
  data() {
    return {
      editableGroup: null,
      changes: 0,
      saving: false,
      valid: false,
      resetCount: 0,
      error: null,
    };
  },
  created() {
    this.reset();
  },
  methods: {
    change() {
      this.changes++;
    },
    reset() {
      if (this.editableGroup != null) {
        this.editableGroup = null;
      }
      this.editableGroup = {};
      Object.assign(this.editableGroup, this.group);
      this.resetCount++;
    },
    removeTag(item) {
      debugger;
      var i = this.editableGroup.Tags.indexOf(item);
      this.editableGroup.Tags.splice(i, 1);
      this.editableGroup.Tags = [...this.editableGroup.Tags];
    },

    update() {
      this.saving = this.editableGroup.metadata.primaryColor ?? "secondary";
      this.$post("entities/groups/update", this.editableGroup).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.$store.dispatch("saved/get_groups");
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
      });
    },
    deleteGroup() {
      var confirm = window.confirm(
        `Are you sure you want to permanently delete this ${this.getTerm(
          "group"
        )}? This cannot be undone. Note: This will NOT delete associated ${this.getTerm(
          "assessment"
        )} data.`
      );
      if (confirm) {
        this.saving = "secondary";
        this.$get(`entities/groups/delete/${this.group.id}`).then((response) => {
          if (response.error) {
            this.error = "You do not have permission to delete this group.";
            this.saving = false;
          } else {
            //  this.$store.dispatch('saved/get_assessments')
            this.$store.dispatch("saved/get_groups");
            this.$router.push({ name: "AllGroups" });
          }
        });
      }
    },
  },
  computed: {
    swatches() {
      var arr = [...new Set(this.app_info.colorArr)];
      return this.chunkArray(arr, 7, []);
    },
    groupType() {
      return this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupTypeId])
        : null;
    },
  },
  props: {
    group: {
      type: Object,
    },
  },
};
</script>
