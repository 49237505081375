<template>
  <v-container fluid>
    <v-row v-if="!group.opaque">
      <v-col cols="3" md="2" v-if="$vuetify.breakpoint.mdAndUp">
        <v-list nav>
          <v-list-item-group v-model="tab" vertical dense>
            <v-list-item
              dense
              color="secondary"
              v-for="t in tabOptions"
              :key="t.value"
              :value="t.value"
              :href="`#${t.value}`"
            >
              <span class="text-body-2">
                {{ t.text }}
              </span>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <v-select
          solo
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="tab"
          :items="tabOptions"
        >
        </v-select>
        <keep-alive>
          <component
            @update="update"
            :is="component.component"
            v-bind="component.props"
          ></component>
        </keep-alive>
      </v-col>
    </v-row>
    <sr-not-found
      v-else
      color="error"
      icon="fad fa-lock"
      unauthorized
      :thing="`the settings page for ${group.metadata.name}`"
    >
    </sr-not-found>
  </v-container>
</template>

<script>
export default {
  name: "GroupSettings",
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.hash != null) {
      var t = this.$route.hash.toLowerCase().replace("#", "");
      this.tab = this.tabOptions.map((x) => x.value).includes(t) ? t : "edit";
    } else {
      this.tab = "edit";
    }
  },
  methods: {
    update(snackbar) {
      console.log("settings emit");
      console.log(snackbar);
      this.$emit("update", snackbar);
    },
  },
  computed: {
    tabOptions() {
      var tabs = [
        {
          text: "Edit Group",
          icon: "fa-pencil",
          value: "edit",
        },
        {
          text: "Manage Members",
          icon: "fa-users",
          value: "members",
        },
        {
          text: "Manage Subgroups",
          icon: "fa-sitemap",
          value: "subgroups",
        },
        {
          text: "View Permissions",
          icon: "fa-shield-alt",
          value: "permissions",
        },
        {
          text: "Configure Baseline",
          icon: "fa-chart-pie",
          value: "baseline",
        },
      ];

      return tabs;
    },
    showBaseline() {
      var gt = this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupTypeId])
        : null;
      if (gt) {
        return gt.baseline != null;
      }
      return false;
    },
    component() {
      var component = "sr-manage-members";
      var props = { group: this.group, permissions: this.permissions };
      switch (this.tab) {
        case "members":
          props.people = this.people;
          props.readonly = false;

          break;
        case "permissions":
          component = "sr-permissions";
          props.groupType = this.group.groupTypeId;
          props.gt = this.groupType;
          break;
        case "subgroups":
          component = "sr-manage-subgroups";

          break;
        case "edit":
          component = "sr-edit-group";

          break;
        case "baseline":
          component = "sr-view-baseline";
      }
      return { component: component, props: props };
    },
  },
  props: {
    group: {
      type: Object,
    },
    people: {
      type: Array,
    },
    permissions: {
      type: Object,
    },
    groupType: {
      type: Object,
    },
  },
};
</script>
