<template>
  <div>
    <v-toolbar v-if="miniPerson || person" dense flat :key="loading">
      <v-btn @click="showBreadCrumbs = !showBreadCrumbs" text icon class="px-0">
        <v-icon v-show="showBreadCrumbs">mdi-chevron-right</v-icon>
        <v-icon v-show="!showBreadCrumbs">mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-items v-if="miniGroup && !showBreadCrumbs">
        <v-btn
          text
          large
          class="px-1"
          :to="{ name: 'GroupHome', params: { groupid: miniGroup.id } }"
          exact
        >
          <v-icon left>fad fa-users</v-icon> {{ miniGroup.displayText }}
        </v-btn>
        <v-icon class="text-button mt-1">mdi-chevron-right</v-icon>
      </v-toolbar-items>

      <v-toolbar-items v-show="person && showBreadCrumbs">
        <v-btn icon :to="{ name: 'Home' }">
          <i class="fal fa-home"></i>
        </v-btn>
        <v-icon class="text-button mt-1">mdi-chevron-right</v-icon>

        <v-btn text large class="px-1" :to="{ name: 'People' }" exact> All People </v-btn>
        <v-icon class="text-button mt-1">mdi-chevron-right</v-icon>
      </v-toolbar-items>

      <v-toolbar-title class="text-button ml-2 mt-1">
        {{ miniPerson ? miniPerson.displayText : getDisplayName(person) }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp && !showBreadCrumbs">
        <sr-person-tabs
          :optional="true"
          right
          :grow="false"
          :personid="miniPerson ? miniPerson.id : person.id"
          :canWrite="permissions ? permissions.write : false"
        ></sr-person-tabs>
      </v-toolbar-items>

      <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown || showBreadCrumbs">
        <sr-person-tabs
          :optional="true"
          right
          :grow="false"
          :canWrite="permissions ? permissions.write : false"
          :personid="miniPerson ? miniPerson.id : person.id"
        ></sr-person-tabs>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid v-if="person">
      <router-view
        @update="init"
        @error="showError"
        v-bind="{ person: person, permissions: permissions }"
      >
      </router-view>
    </v-container>
    <v-container v-show="!person">
      <sr-loading v-if="loading"></sr-loading>
      <sr-not-found v-else></sr-not-found>
    </v-container>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="snackbarColor"
      :timeout="app_info.snackbarTimeout"
    >
      {{ snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import srPersonTabs from "../newComponents/PersonComponents/TabList";

export default {
  name: "PersonWrapper",
  components: {
    srPersonTabs,
  },
  data() {
    return {
      person: null,
      loading: false,
      error: null,
      snackbar: null,
      showSnackbar: false,
      showBreadCrumbs: false,
      snackbarColor: null,
      //  people: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    showError(snackbar) {
      this.snackbar = snackbar;
      this.snackbarColor = "error";
      this.showSnackbar = true;
    },
    init(snackbar) {
      console.log("getting Person");
      console.log("snackbar: " + snackbar);
      this.loading = true;
      this.$get(`entities/individuals/${this.individualid}`).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.person = response.data;
          if (snackbar) {
            this.snackbar = snackbar;
            this.showSnackbar = true;
            this.snackbarColor = null;
          }
          /* if(this.$auth.isDataManager){
                        this.$get(`permissions/user/${this.individualid}/linked`).then((response)=>{
                            this.person.linkedAccounts = response.data;
                        })
                    }
                    */
        }
        this.loading = false;
      });
    },
  },
  computed: {
    miniPerson() {
      return this.$store.getters["saved/get"](["people", this.individualid]);
    },
    miniGroup() {
      return this.groupid
        ? this.$store.getters["saved/get"](["groups", this.groupid])
        : null;
    },
    permissions() {
      return this.person
        ? {
            write: !this.person.readonly,
            read: true,
            author: this.$auth.isDataManager,
          }
        : null; // this.groupType ? this.admin ? this.groupType.objectPermissions.admin : this.groupType.objectPermissions.member : null
    },
  },
  props: {
    individualid: {
      type: String,
    },
    groupid: {
      type: String,
    },
  },
};
</script>
