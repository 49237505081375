<template>
  <v-card class="break" flat v-if="rubric">
    <v-card-title>{{ rubric.title }}</v-card-title>
    <v-card-text v-html="rubric.description"></v-card-text>
    <v-card outlined class="mx-5 mb-5" v-if="rubric.gradeBoundaries.length">
      <v-subheader class="overline">Grade Boundaries</v-subheader>
      <v-divider></v-divider>
      <v-simple-table dense>
        <tr>
          <th class="text-left pl-3"><small>Key</small></th>
          <th v-for="(b, i) in rubric.gradeBoundaries" :key="i">
            <small> {{ b.key }}</small>
          </th>
        </tr>
        <tr>
          <th class="text-left pl-3"><small>Minimum score</small></th>
          <th v-for="(b, i) in rubric.gradeBoundaries" :key="i">
            <small>{{ b.floor }}</small>
          </th>
        </tr>
      </v-simple-table>
    </v-card>

    <v-toolbar dark color="primary" class="no-print">
      <v-toolbar-title>{{ "Unit" | pluralTerm }}</v-toolbar-title>
    </v-toolbar>
    <v-expansion-panels tile class="d-print-none">
      <v-expansion-panel v-for="(item, i) in rubric.units" :key="i">
        <v-expansion-panel-header>
          {{ item.title }} <v-spacer></v-spacer> {{ item.maxMarks }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="item.description"></div>
          <v-chip small outlined class="mr-2 my-2" v-for="t in item.tags" :key="t">{{
            t
          }}</v-chip>

          <v-simple-table
            dense
            fixed-header
            table-layout="fixed"
            v-if="item.objectives.length"
          >
            <thead>
              <tr>
                <th></th>
                <th v-for="b in getStandards(item, 0)" :key="b.id">
                  <small>
                    {{ b.key }}
                    <br />
                    {{ b.points }}
                  </small>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-left" v-for="(o, oi) in item.objectives" :key="o.id">
                <th class="text-left border-right px-2">
                  <div class="">
                    <div class="overline" v-html="o.title"></div>
                    <small><p v-html="o.description"></p></small>
                    <v-chip
                      x-small
                      outlined
                      class="mr-2 my-2"
                      v-for="t in o.tags"
                      :key="t"
                      >{{ t }}</v-chip
                    >
                  </div>
                </th>
                <td class="border-right" v-for="b in getBands(item, oi)" :key="b.id">
                  <p v-html="b.levelText"></p>
                  <b>{{ b.value }}</b>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <sr-not-found v-else :action="perm" flat :outlined="false">
            <template v-slot:title>No {{ "Objective" | pluralTerm }}</template>
            <template v-slot:body>
              <span
                v-if="
                  permissions.permissionObject &&
                  permissions.permissionObject.editPermissions &&
                  permissions.permissionObject.editPermissions.addRubricObjectives
                "
              >
                You can add {{ "objective" | pluralTerm }} on the 'Edit' tab.
              </span>
              <span v-else
                >When the author adds {{ "objective" | pluralTerm }} to this
                {{ "unit" | term }} they will appear here.
              </span>
            </template>
          </sr-not-found>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card
      class="d-none border-top d-print-block"
      v-for="item in rubric.units"
      :key="item.id"
    >
      <v-card-title>
        {{ item.title }} <v-spacer></v-spacer> {{ item.maxMarks }}
      </v-card-title>
      <v-card-text v-html="item.description"></v-card-text>
      <v-chip small outlined class="mr-2 my-2" v-for="t in item.tags" :key="t">{{
        t
      }}</v-chip>
      <v-card-text>
        <v-simple-table dense fixed-header table-layout="fixed">
          <thead>
            <tr>
              <th></th>
              <th v-for="b in getStandards(item, 0)" :key="b.id">
                <small>
                  {{ b.key }}
                  <br />
                  {{ b.points }}
                </small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-left" v-for="o in item.objectives" :key="o.id">
              <th class="text-left border-right px-2">
                <div class="">
                  <div class="overline" v-html="o.title"></div>
                  <small><p v-html="o.description"></p></small>
                  <v-chip
                    x-small
                    outlined
                    class="mr-2 my-2"
                    v-for="t in o.tags"
                    :key="t"
                    >{{ t }}</v-chip
                  >
                </div>
              </th>
              <td class="border-right" v-for="b in getVisibleBands(o.bands)" :key="b.id">
                <p v-html="b.levelText"></p>
                <b>{{ b.value }}</b>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: "PreviewRubric",
  data() {
    return {};
  },
  methods: {
    getVisibleBands(bands) {
      var result = [];
      result = bands.filter((b) => !b.hidden);
      return result;
    },
    getBands(unit, i) {
      return unit && unit.objectives.length
        ? this.$r
            .clone(unit.objectives[i].bands)
            .filter((x) => !x.hidden && x.key && !x.key.toLowerCase().includes("spare"))
            .sort((a, b) => {
              return a.floor - b.floor;
            })
        : [];
    },
    getStandards(unit, index) {
      var result = [];

      if (unit.objectives[index]) {
        var bands = unit.objectives[index].bands;

        bands.forEach((band) => {
          if (!band.hidden && band.key && !band.key.toLowerCase().includes("spare")) {
            var b = {
              key: band.key,
              points: "",
              value: band.value,
              floor: band.floor,
            };
            //var nextBand = bands[i + 1] ? bands[i + 1] : null;
            var nextBands = bands
              .filter((x) => x.floor > band.floor)
              .sort((a, b) => {
                return a.floor - b.floor;
              });
            var nextBand = nextBands.length ? nextBands[0] : null;
            var min = band ? band.floor : 0;
            debugger;

            var max = nextBand ? nextBand.floor - 1 : unit.maxMarks;
            if (min === max) {
              b.points = `${min} ${this.$getTerm("point", max > 1 || max == 0)}`;
            } else if (band && nextBand && band.floor == 0 && nextBand.floor == 0) {
              b.points = `${min} ${this.$getTerm("point", true)}`;
            } else {
              b.points = `${min} - ${max} ${this.$getTerm("point", max > 1)}`;
            }
            result.push(b);
          }
        });
        result.sort((a, b) => {
          return a.floor - b.floor;
        });
        return result;
      }
    },
  },
  props: {
    rubric: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
