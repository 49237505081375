<template>
  <v-container fluid>
    <v-row v-if="editPermissions" :key="updateKey">
      <v-col :key="changes">
        <div class="d-flex mb-5" v-if="hasUnit">
          <div class="grow">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" block v-if="unit" color="white">
                  {{ unit.title }}
                  <v-spacer></v-spacer>
                  <v-icon right>fas fa-caret-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="t in units"
                  :key="t.value"
                  :value="t.value"
                  :href="`#${t.value}`"
                >
                  <v-list-item-title>{{ t.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-btn
            v-if="editPermissions.addRubricUnits"
            icon
            @click="addUnit"
            class="mx-2"
            color="success"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </div>

        <div :key="tab">
          <v-card :tile="app_info.tile" loading v-if="hasUnit && tab != 1 && tab">
            <template v-slot:progress>
              <v-progress-linear
                color="warning"
                v-if="saving"
                indeterminate
              ></v-progress-linear>
              <v-progress-linear
                v-if="changes != 0"
                color="warning"
                value="100"
              ></v-progress-linear>
              <v-progress-linear
                v-if="!saving && changes == 0"
                color="transparent"
                value="0"
              ></v-progress-linear>
            </template>
            <unit-meta
              :isEdit="false"
              v-if="unit && unit.id"
              :unit="unit"
              @update="updateUnit"
              @remove="removeUnit"
              :saving="saving"
              :permissions="editPermissions"
            >
            </unit-meta>
            <v-divider v-if="unit && unit.id"></v-divider>
            <v-subheader
              v-if="unit && unit.id"
              class="overline d-flex justify-space-between"
              >{{ "Objective" | pluralTerm }}
              <v-btn-toggle
                v-model="tableView"
                v-if="$vuetify.breakpoint.lgAndUp && unit.format.mode == 'matrix'"
              >
                <v-btn small text :value="false">
                  <v-icon>mdi-format-align-left</v-icon>
                </v-btn>
                <v-btn small text :value="true">
                  <v-icon>mdi-table</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-subheader>
            <v-divider v-if="unit && unit.id"></v-divider>
            <unit-schema
              :key="`schema${updateKey}`"
              v-if="unit && unit.id"
              :tableView="tableView && unit.format.mode == 'matrix'"
              :saving="saving"
              @addObjective="addObjective"
              @addBand="addBand"
              @updateBand="updateBand"
              @removeBand="removeBand"
              @removeObjective="removeObjective"
              @updateObjective="updateObjective"
              @updateLevelText="updateLevelText"
              @update="saveUnitSchema"
              :unit="unit"
              :permissions="permissions"
            >
            </unit-schema>
            <v-divider></v-divider>
          </v-card>
          <v-card v-else-if="saving">
            <sr-loading></sr-loading>
          </v-card>
          <v-card v-else-if="false && editPermissions.addUnit">
            <sr-not-found icon="fad fa-file-edit" actions>
              <template v-slot:title> Empty Rubric </template>
              <template v-slot:description>
                The first step in creating a rubric is to add a unit.
              </template>
              <template v-slot:body>
                A unit is a group of similar objectives that are all broken down into the
                same levels and scored the same way.
              </template>
              <template v-slot:primaryAction>
                <v-btn @click="addUnit" text color="primary">
                  <v-icon left>fal fa-plus</v-icon> Add Unit
                </v-btn>
              </template>
            </sr-not-found>
          </v-card>
          <v-card v-else>
            <sr-not-found>
              <template v-slot:title> No Access </template>
              <template v-slot:description>
                You do not have permission to edit this schema.
              </template>
            </sr-not-found>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <sr-not-found
      v-else
      color="error"
      icon="fad fa-lock"
      :key="updateKey"
      unauthorized
      :thing="`the settings page for ${rubric.title}`"
    >
    </sr-not-found>
  </v-container>
</template>

<script>
import UnitMeta from "./RubricUnits/UnitMeta.vue";
import UnitSchema from "./RubricUnits/UnitSchema.vue";

export default {
  name: "RubricEdit",
  components: { UnitMeta, UnitSchema },
  data() {
    return {
      tab: null,
      saving: false,
      changes: 0,
      tableView: true,
      editableRubric: null,
      resetCount: 0,
    };
  },
  created() {
    this.init();
  },
  watch: {
    hash: function () {
      this.init();
    },
  },
  methods: {
    init() {
      if (this.$route.hash != null) {
        var t = this.$route.hash.toLowerCase().replace("#", "");
        this.tab =
          this.rubric && this.rubric.units && this.rubric.units.length
            ? this.rubric.units.map((x) => x.id).includes(t)
              ? t
              : this.rubric.units[0].id
            : null;
      } else {
        this.tab =
          this.rubric && this.rubric.units && this.rubric.units.length
            ? this.rubric.units[0].id
            : null;
      }
      this.reset();
    },
    reset() {
      if (this.editableRubric != null) {
        this.editableRubric = null;
      }
      this.editableRubric = {};
      var settings = {
        id: this.rubric.id,
        units: this.rubric.units,
      };
      Object.assign(this.editableRubric, settings);
      //this.editableRubric = settings;
      this.resetCount++;
    },
    removeUnit(e) {
      debugger;
      this.saving = true;

      var data = {
        id: this.rubric.id,
        secret: this.rubric.secret,
        unitId: e,
      };
      this.$post(`update/rubric/unit/remove`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.init();
          this.$store.dispatch("saved/get_rubrics");
          this.$emit("update", "Changes Saved");
          this.saving = false;
        }
      });
    },
    saveUnitSchema(e) {
      this.saving = true;
      console.log(e);
      this.saving = false;
    },
    addObjective(data) {
      debugger;
      this.saving = true;
      data.id = this.rubric.id;
      data.secret = this.rubric.secret;
      this.$post(`update/rubric/objective/add`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
      });
    },
    addBand(data) {
      debugger;
      this.saving = true;
      data.id = this.rubric.id;
      data.secret = this.rubric.secret;
      this.$post(`update/rubric/band/add`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$emit("update", null);
        }
        this.saving = false;
      });
    },
    updateBand(data) {
      debugger;
      this.saving = true;
      data.id = this.rubric.id;
      data.secret = this.rubric.secret;
      this.$post(`update/rubric/band/update`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$emit("update", null);
        }
        setTimeout(() => {
          this.saving = false;
        }, 200);
      });
    },
    removeBand(data) {
      debugger;
      this.saving = true;
      data.id = this.rubric.id;
      data.secret = this.rubric.secret;
      this.$post(`update/rubric/band/remove`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$emit("update", null);
        }
        this.saving = false;
      });
    },
    removeObjective(data) {
      debugger;
      this.saving = true;
      data.id = this.rubric.id;
      data.secret = this.rubric.secret;

      this.$post(`update/rubric/objective/remove`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.init();
          this.$emit("update", "Changes Saved");
          this.saving = false;
        }
      });
    },
    updateObjective(data) {
      debugger;
      this.saving = true;
      data.id = this.rubric.id;
      data.secret = this.rubric.secret;
      this.$post(`update/rubric/objective/update`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$emit("stealthUpdate", null);
        }
      });
      this.saving = false;
    },
    updateLevelText(data) {
      debugger;
      this.saving = true;
      data.id = this.rubric.id;
      data.secret = this.rubric.secret;
      this.$post(`update/rubric/leveltext/update`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$emit("stealthUpdate", null);
        }
      });
      this.saving = false;
    },
    addUnit() {
      this.saving = true;
      this.tab = null;
      var newUnit = null;
      var format = this.rubric.format;
      debugger;
      format.mode =
        this.rubric.format.mode && this.rubric.format.mode != "individual"
          ? this.rubric.format.mode
          : "matrix";

      newUnit = {
        title: `${this.$getTerm("Unit")} ${this.rubric.units.length + 1}`,
        tags: [],
        description: "",
        maxMarks: 10,
        gradeBoundaries: this.rubric.gradeBoundaries,
        format: format,
      };
      var data = {
        id: this.rubric.id,
        unit: newUnit,
        secret: this.rubric.secret,
      };
      this.$post(`update/rubric/unit/add`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.tab = response;
          this.$store.dispatch("saved/get_rubrics");
          this.$emit("update", "Changes Saved");
        }
        this.saving = false;
      });
    },
    updateUnit(e) {
      debugger;
      this.saving = true;

      var data = {
        id: this.rubric.id,
        unit: e,
        secret: this.rubric.secret,
        unitId: e.id,
      };

      this.$post(`update/rubric/unit/update`, data).then((response) => {
        // this.$post(`rubrics/${this.rubric.id}/unit`, e).then((response)=>{
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            this.errorText(response.text, "save", this.$getTerm("rubric"))
          );
        } else {
          this.$store.dispatch("saved/get_rubrics");
          this.$emit("stealthUpdate", "Changes Saved");
        }
        this.saving = false;
      });
    },
  },
  computed: {
    editPermissions() {
      return this.permissions ? this.permissions.editPermissions : null;
    },
    objectPermissions() {
      return this.permissions ? this.permissions.objectPermissions : null;
    },
    hasUnit() {
      return (
        this.rubric.units.length != 0 && this.rubric.units.filter((x) => x.id).length != 0
      );
    },
    emptyUnit() {
      return this.rubric.units.find((x) => !x.id);
    },
    units() {
      return this.rubric.units.map((x) => {
        return {
          value: x.id,
          text: x.title,
        };
      });
    },
    hash() {
      return this.$route.hash;
    },
    unitCount() {
      return this.rubric.units.length;
    },
    filledUnits() {
      return this.editableRubric.units.filter((x) => x.id).length;
    },
    unit() {
      return this.emptyUnit
        ? this.emptyUnit
        : this.rubric.units.find((x) => x.id == this.tab);
    },
  },
  props: {
    rubric: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
    updateKey: {
      type: Number,
    },
  },
};
</script>
