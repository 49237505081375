<template>
  <v-card outlined color="transparent" class="text-center">
    <div v-html="spinner"></div>
    <v-card-text class="text-italic">
      <slot name="content"> </slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Loading",
  computed: {
    spinner() {
      return (
        this.app_info.loading ??
        `<div
      style="
        margin: 0 auto;
        height: 200px;
        width: 200px;
        border-radius: 50%;
        overflow: hidden;
      "
      class="pa-5 mt-4 fa-4x blue-grey darken-3 white--text"
    ><span class="fa-layers fa-fw ma-5"> <i
          class="fad fa-circle info--text text--lighten-2"
          data-fa-transform="down-57 grow-50"
        ></i>
        <i
          class="fas fa-circle"
          data-fa-transform="shrink-18 left-5 down-1"
        ></i>

        <i
          class="fas fa-circle warning--text text--lighten-5"
          data-fa-transform="shrink-18 right-10 down-15"
        ></i>
        <i
          class="fad fa-stars warning--text text--lighten-3"
          data-fa-transform="shrink-10 flip-h up-8"
        ></i>
        <i
          class="fad fa-stars warning--text text--lighten-2"
          data-fa-transform="shrink-12 left-18"
        ></i>
        <i class="fas fa-star" data-fa-transform="shrink-12 down-15"></i>
        <i
          class="fad fa-planet-ringed warning--text "
          data-fa-transform="shrink-11 right-19 down-10"
        ></i>

        <i
          class="fad fa-spinner-third fa-spin primary--text" 
          data-fa-transform="grow-10 down-12"
        ></i></span></div>`
      );
    },
  },
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>
