<template>
  <v-card flat v-if="editableSettings">
    <div class="d-flex justify-space-between">
      <v-card-subtitle class="overline">
        <span v-if="editableSettings.id">
          {{ editableSettings.title }}

          <em v-if="!editMode">({{ editableSettings.maxMarks }} Marks)</em>
        </span>
        <span v-else> New Unit </span>
        ({{ unCamel(unit.format.mode) }})
      </v-card-subtitle>

      <v-btn icon class="ma-3 mt-5" @click="editMode = !editMode">
        <v-icon v-show="editMode">mdi-chevron-up</v-icon>
        <v-icon v-show="!editMode">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <v-form v-model="valid" v-if="editableSettings" :key="resetCount">
      <v-card-text v-if="editMode || !editableSettings.id">
        <v-row>
          <v-col sm="8">
            <v-text-field
              outlined
              dense
              :label="$getTerm('Title')"
              :disabled="saving"
              v-model="editableSettings.title"
              @input="change"
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => v.length >= 1 || 'Name is required',
                (v) => v.length <= 150 || 'Name must be less than 50 characters',
              ]"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              :label="$getTerm('Available Marks')"
              :disabled="saving"
              @input="change"
              :rules="[
                (v) => !!v || 'Max marks is required',
                (v) => v > 0 || 'Marks must be greater than 0',
              ]"
              v-model.number="editableSettings.maxMarks"
            ></v-text-field>
          </v-col>
        </v-row>

        <wysiwyg
          class="rounded mb-5"
          :label="$getTerm('Title')"
          v-model="editableSettings.description"
          :disabled="saving"
          @change="change"
        />

        <v-combobox
          outlined
          dense
          hide-no-data
          single-line
          small-chips
          v-model="editableSettings.tags"
          chips
          clearable
          label="Tags"
          :disabled="saving"
          @input="change"
          multiple
          append-icon=""
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              class="my-1"
              dark
              @click="select"
              @click:close="removeTag(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
        <v-card outlined :tile="app_info.tile">
          <div class="d-flex justify-space-between">
            <v-card-subtitle
              v-if="showBoundaries && editableSettings.id"
              class="overline"
            >
              {{ "Grade" | term }} {{ "Boundary" | pluralTerm }}
            </v-card-subtitle>
            <v-simple-table
              class="flex-grow-1"
              v-if="!showBoundaries && editableSettings.id"
            >
              <thead>
                <tr>
                  <th>{{ "Grade" | term }} {{ "Boundary" | term }}</th>
                  <th v-for="(b, i) in editableSettings.gradeBoundaries" :key="i">
                    {{ b.key }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Min {{ "point" | pluralTerm }}</th>
                  <td v-for="(b, i) in editableSettings.gradeBoundaries" :key="i">
                    {{ Math.round((b.floor / 100) * editableSettings.maxMarks) }}
                    {{ "point" | pluralTerm }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn
              icon
              :disabled="saving"
              class="ma-3 mt-5"
              @click="showBoundaries = !showBoundaries"
            >
              <v-icon v-show="showBoundaries">mdi-chevron-up</v-icon>
              <v-icon v-show="!showBoundaries">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
          <div
            class="mx-2 d-flex flex-wrap align-center"
            v-if="showBoundaries || !editableSettings.id"
          >
            <v-card
              color="primary"
              dark
              outlined
              :tile="app_info.tile"
              v-for="(g, i) in editableSettings.gradeBoundaries"
              :key="i"
              max-width="170px"
              class="ma-2"
            >
              <v-card-text>
                <v-text-field
                  dense
                  outlined
                  label="label"
                  :disabled="saving"
                  @input="changeBoundary"
                  v-model="g.key"
                ></v-text-field>
                <v-text-field
                  dense
                  outlined
                  :disabled="saving"
                  label="floor"
                  append-icon="fal fa-percent"
                  @input="changeBoundary"
                  v-model.number="g.floor"
                >
                </v-text-field>
              </v-card-text>
              <v-card-actions class="white dark--text">
                {{ Math.round((g.floor / 100) * editableSettings.maxMarks) }} marks
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="saving"
                  color="error"
                  text
                  @click="removeGradeBoundary(i)"
                >
                  Remove
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <v-btn
            v-if="showBoundaries || !editableSettings.id"
            :disabled="saving"
            block
            text
            @click="addGradeBoundary()"
          >
            <v-icon left>fal fa-plus</v-icon> Add {{ "Boundary" | term }}
          </v-btn>
        </v-card>
      </v-card-text>

      <v-card-actions v-if="editMode || !editableSettings.id || changes != 0">
        <v-btn
          :disabled="saving"
          v-if="editableSettings.id"
          @click="removeUnit"
          color="error"
          >Delete {{ "unit" | term }}</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn
          text
          v-if="editableSettings.id"
          @click="reset"
          :disabled="changes == 0 || saving"
          color="warning"
          >reset</v-btn
        >
        <v-btn
          text
          @click="update"
          :disabled="changes == 0 || !valid || saving"
          color="success"
          >Save Changes</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
export default {
  name: "UnitMeta",
  data() {
    return {
      editableSettings: null,
      changes: 0,
      valid: false,
      resetCount: 0,
      boundaryKey: 0,
      showBoundaries: false,
      editMode: false,
    };
  },
  created() {
    this.reset();
  },
  methods: {
    change() {
      this.changes++;
    },
    changeBoundary() {
      this.editableSettings.boundaryEdited = true;
      this.change();
    },
    reset() {
      if (this.editableSettings != null) {
        this.editableSettings = null;
      }
      this.editMode = this.isEdit;

      this.editableSettings = {};
      var settings = {
        id: this.unit && this.unit.id ? this.unit.id : null,
        title: this.unit.title,
        tags: this.unit.tags,
        description: this.unit.description,
        maxMarks: this.unit.maxMarks,
        gradeBoundaries: this.unit.gradeBoundaries,
        boundaryEdited: this.unit.boundaryEdited,
      };
      Object.assign(this.editableSettings, settings);
      this.changes = 0;
      this.resetCount++;
    },
    removeTag(item) {
      debugger;
      var i = this.editableSettings.tags.indexOf(item);
      this.editableSettings.tags.splice(i, 1);
      this.editableSettings.tags = [...this.editableSettings.tags];
      this.change();
    },
    removeGradeBoundary(index) {
      this.editableSettings.gradeBoundaries.splice(index, 1);
      this.boundaryKey++;
      this.changeBoundary();
    },
    addGradeBoundary() {
      var index = this.editableSettings.gradeBoundaries.length;
      this.editableSettings.gradeBoundaries.push({
        key: `Level ${(index + 1).toString()}`,
        floor: 0,
      });
      this.boundaryKey++;
      this.changeBoundary();
    },
    removeUnit() {
      var ok = window.confirm(
        `Are you sure you want to remove this ${this.$getTerm(
          "unit"
        )}? This action cannot be undone.`
      );
      debugger;
      if (ok) {
        this.$emit("remove", this.editableSettings.id);
      }
    },
    update() {
      this.$emit("update", this.editableSettings);
      /*
            this.$post("frameworks/update", this.editableSettings).then((response)=>{
                debugger;
                if(response.error){
                    
                     this.$emit('error', this.errorText(response.text, 'save',this.$getTerm('framework')))
                }
                else{
                    this.$store.dispatch('saved/get_frameworks')
                    this.changes = 0;
                    this.$emit('update', 'Changes Saved')
                }
                this.saving = false;
            });
            */
    },
  },
  props: {
    unit: {
      type: Object,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
