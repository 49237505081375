var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pageTitle"},[_c('v-system-bar',{staticClass:"d-print-none",attrs:{"dark":"","color":"primary"}},[_c('span',{staticClass:"overline ml-5 pl-5"},[_vm._v(" "+_vm._s(_vm._f("pluralTerm")("assessment"))+" ")]),_c('v-spacer')],1),(_vm.assessment)?_c('v-toolbar',{key:_vm.loading,staticClass:"d-print-none",attrs:{"dense":"","flat":""},scopedSlots:_vm._u([(_vm.write && (_vm.$vuetify.breakpoint.smAndDown || _vm.showBreadCrumbs))?{key:"extension",fn:function(){return [(_vm.assessment)?_c('sr-assessment-tabs',{attrs:{"assessmentid":_vm.assessmentid,"canWrite":_vm.permissions.write,"settings":_vm.permissions.settings,"grow":""}}):_vm._e()]},proxy:true}:null],null,true)},[_c('v-toolbar-items',{staticClass:"no-padder"},[_c('v-btn',{staticClass:"px-0",attrs:{"text":""},on:{"click":function($event){_vm.showBreadCrumbs = !_vm.showBreadCrumbs}}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBreadCrumbs),expression:"showBreadCrumbs"}]},[_vm._v("mdi-chevron-right")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showBreadCrumbs),expression:"!showBreadCrumbs"}]},[_vm._v("mdi-chevron-left")])],1),(_vm.group && !_vm.showBreadCrumbs)?_c('v-btn',{staticClass:"px-1",attrs:{"text":"","large":"","to":{ name: 'GroupHome', params: { groupid: _vm.assessment.group } }}},[_c('span',{style:({ color: _vm.group.colour })},[_vm._v(_vm._s(_vm.group.displayText))])]):_vm._e(),(!_vm.showBreadCrumbs)?_c('v-icon',{staticClass:"text-button mt-1"},[_vm._v("mdi-chevron-right")]):_vm._e(),_c('v-btn',{staticClass:"px-1",attrs:{"text":"","large":"","disabled":""}},[_c('span',[_vm._v(_vm._s(_vm.assessment.title))])])],1),(_vm.showBreadCrumbs)?_c('v-toolbar-items',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pt-4",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fal fa-ellipsis-h"})])]}}],null,false,2935765796)},[_c('v-list',[_c('v-subheader',{staticClass:"overline",attrs:{"hidden":""}},[_vm._v("Back To...")]),_c('v-list-item',{attrs:{"dense":"","exact":"","link":"","to":{ name: 'Assessments' }}},[_c('v-list-item-title',[_c('i',{staticClass:"fad fa-edit mr-3",attrs:{"color":"primary"}}),_vm._v(" All "+_vm._s(_vm._f("pluralTerm")("assessment"))+" ")])],1)],1)],1),(_vm.group)?_c('v-btn',{staticClass:"px-1",attrs:{"text":"","large":"","to":{ name: 'GroupHome', params: { groupid: _vm.assessment.group } }}},[_vm._v(" "+_vm._s(_vm.group.displayText)+" ")]):_vm._e(),(_vm.group)?_c('v-icon',{staticClass:"text-button mt-1"},[_vm._v("mdi-chevron-right")]):_vm._e(),(_vm.group)?_c('v-btn',{staticClass:"px-1",attrs:{"text":"","large":"","to":{ name: 'GroupAssessments', params: { groupid: _vm.assessment.groupId } }}},[_vm._v(" "+_vm._s(_vm._f("pluralTerm")("assessment"))+" ")]):_vm._e(),(_vm.group)?_c('v-icon',{staticClass:"text-button mt-1"},[_vm._v("mdi-chevron-right")]):_vm._e()],1):_vm._e(),_c('v-toolbar-title',{staticClass:"text-button ml-2 mt-1"},[_vm._v(" "+_vm._s(_vm.assessment.assessmentTitle)+" ")]),_c('v-spacer'),(_vm.write && _vm.$vuetify.breakpoint.mdAndUp && !_vm.showBreadCrumbs && _vm.assessmentid)?_c('v-toolbar-items',[(_vm.assessment)?_c('sr-assessment-tabs',{attrs:{"assessmentid":_vm.assessmentid,"canWrite":_vm.permissions.write,"settings":_vm.permissions.settings}}):_vm._e()],1):_vm._e()],1):_vm._e(),_c('v-divider',{staticClass:"d-print-none"}),(_vm.assessment)?_c('div',[_c('router-view',_vm._b({on:{"error":_vm.showError,"reset":_vm.init}},'router-view',{
        assessment: _vm.assessment,
        permissions: _vm.permissions,
        resetKey: _vm.resetCount,
      },false))],1):_vm._e(),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.assessment),expression:"!assessment"}]},[(_vm.loading)?_c('sr-loading',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Finding "+_vm._s(_vm._f("term")("assessment"))+"... ")]},proxy:true}],null,false,3060169267)}):_vm._e(),(!_vm.loading && _vm.error && _vm.error.toLowerCase().includes('network'))?_c('sr-not-found',{attrs:{"color":"error","icon":"fad fa-wifi-slash"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Network Error ")]},proxy:true},{key:"description",fn:function(){return [_vm._v("This resource is temporarily unavailable.")]},proxy:true},{key:"body",fn:function(){return [_vm._v(" Please try again later. If the problem persists, contact support. ")]},proxy:true}],null,false,946838849)}):_vm._e(),(!_vm.loading && _vm.error && !_vm.error.toLowerCase().includes('network'))?_c('sr-not-found',{attrs:{"unauthorized":""}}):_vm._e()],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbarColor,"timeout":_vm.app_info.snackbarTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }