<template>
  <v-card class="ma-4 break" :tile="app_info.tile">
    <v-system-bar
      height="30"
      color="secondary"
      class="d-print-none d-flex justify-space-between"
    >
      <span class="white--text">
        <span v-if="mode == 'edit'">{{ "MarkingView" | term }} </span>
        <span v-else>{{ "ReportView" | term }} </span>
        View
      </span>
      <v-btn
        tile
        color="white"
        small
        class="white--text"
        text
        @click="toggleMode"
        v-if="
          (!selfSelected &&
            ((mode == 'report' && !complete && !locked) || mode == 'edit')) ||
          $auth.isAdmin
        "
      >
        <span v-show="mode == 'edit'">
          <v-icon style="color: white !important" left>fad fa-file-chart-line</v-icon>
          Show &nbsp;{{ "ReportView" | term }}
          &nbsp; View
        </span>
        <span v-show="mode == 'report'">
          <v-icon style="color: white !important" left>fad fa-th</v-icon>
          Show &nbsp; {{ "MarkingView" | term }} &nbsp; View</span
        >
      </v-btn>
    </v-system-bar>
    <v-toolbar class="d-none d-print-inline">
      <v-toolbar-title>
        {{ assessment.title }} ({{
          assessment.metadata.lastUpdated | moment("DD MMM YYYY")
        }})
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title v-if="!individuals.length || individuals.length == 1">
        <sr-person-chip :showAvatar="false" :id="selectedIndividual"></sr-person-chip>
      </v-toolbar-title>
    </v-toolbar>
    <v-toolbar
      color="primary"
      dark
      fixed
      dense
      v-if="individuals.length > 1"
      flat
      class="border-bottom d-print-none"
    >
      <v-toolbar-title v-if="!individuals.length || individuals.length == 1">
        <sr-person-chip selectOption :id="selectedIndividual"></sr-person-chip>
      </v-toolbar-title>
      <v-toolbar-items v-else>
        <div class="mt-2 d-print-none" :key="selectedIndividual">
          <v-select
            outlined
            v-model="selectedIndividual"
            hide-details
            @change="goToRoute"
            prepend-inner-icon="mdi-account"
            dense
            :items="individuals"
          >
            <template v-slot:item="{ item }">
              <sr-person-chip :id="item" selectOption></sr-person-chip>
            </template>
            <template v-slot:selection="{ item }">
              <sr-person-chip :id="item" small selectOption></sr-person-chip>
            </template>
          </v-select>
        </div>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-title class="d-none d-print-inline">
        <sr-person-chip :id="selectedIndividual" selectOption></sr-person-chip>
      </v-toolbar-title>
      <v-btn
        small
        :to="{
          name: 'PersonHome',
          params: { individualid: selectedIndividual, groupid: assessment.group },
        }"
      >
        {{ "Assessee" | term }} Profile <v-icon right>fal fa-arrow-right</v-icon>
      </v-btn>
    </v-toolbar>

    <div>
      <individual-assessment
        v-if="individualAssessment"
        :key="selectedIndividual"
        :individualId="selectedIndividual"
        :assessmentId="assessment.id"
        :rubric="assessment.rubric"
        :secret="assessment.secret"
        :updateKey="updateKey"
        @updateLock="updateLock"
        @error="error"
        @input="update"
        @complete="updateComplete"
        @toggleMode="toggleMode"
        :self="selfSelected"
        :editMode="editMode"
        ref="ia"
        :locked="locked"
      >
      </individual-assessment>

      <v-container fluid v-else>
        <v-row>
          <v-col>
            <sr-not-found icon="fad fa-users" color="warning">
              <template v-slot:title>
                <span v-if="individuals.length">
                  Please select {{ $article($getTerm("assessee")) }}
                  {{ "assessee" | term }}</span
                >
                <span v-else>
                  There are no {{ "assessee" | pluralTerm }} configured for this
                  {{ "assessment" | term }}
                </span>
              </template>
              <template v-slot:body>
                <span v-if="individuals.length"> </span>
                <span v-else
                  >Please configure {{ "assessee" | pluralTerm }} using the
                  {{ "settings" | term }} tab.</span
                >
              </template>
            </sr-not-found>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import IndividualAssessment from "../newComponents/AssessmentComponents/IndividualAssessment.vue";
export default {
  name: "AssessmentHome",
  components: {
    IndividualAssessment,
  },
  data() {
    return {
      selectedIndividual: null,
      locked: false,
      complete: false,
      mode: "report",
    };
  },
  watch: {
    paramIndividual: function () {
      this.init();
    },
    paramAssessment: function () {
      this.init();
    },
  },
  computed: {
    /*complete() {
      return this.individualAssessment ? this.individualAssessment.complete : true;
    },
    */
    selfSelected() {
      return this.paramIndividual == this.$auth.userId && this.assessment.selfAssessment;
    },
    individuals() {
      return this.assessment
        ? this.assessment.individualAssessments.map((x) => x.individualId)
        : [];
    },
    paramIndividual() {
      return this.$route.query.subject;
    },
    paramAssessment() {
      return this.$route.params.assessmentid;
    },
    individualAssessment() {
      return this.selectedIndividual
        ? this.assessment.individualAssessments.find(
            (x) => x.individualId == this.selectedIndividual
          )
        : null;
    },
    editMode() {
      return this.mode == "edit" && this.canWrite;
    },
    canWrite() {
      return this.assessment
        ? this.assessment.permissions.write ||
            (this.assessment.selfSelected && this.assessment.selfPermissions.write)
        : false;
    },
    /* mode() {
      return this.$route.query.mode == "edit" || !this.$route.query.mode
        ? "edit"
        : "report";
    },
    */
  },
  created() {
    this.init();
  },
  methods: {
    error(e) {
      this.$emit("error", e);
    },
    update() {
      this.$emit("update");
    },
    updateLock(val) {
      console.log(`Assessment home update Lock: ${val}`);
      this.locked = val;
      if (val) {
        this.changeMode("report");
      }
    },
    updateComplete(val) {
      console.log(`Assessment home update complet: ${val}`);
      this.complete = val;
    },
    init() {
      console.log("initialising Assessment Home");
      if (this.$route.query && this.$route.query.mode) {
        this.mode = this.$route.query.mode.toLowerCase();
      }
      var allComplete = this.assessment
        ? this.assessment.individualAssessments.filter((x) => x.complete).length ==
          this.assessment.individualAssessments.length
        : false;
      if (allComplete && this.mode != "report") {
        this.changeMode("report");
        this.complete = true;
      }
      this.locked = this.assessment ? this.assessment.locked : true;
      if (this.locked && this.mode != "report") {
        this.changeMode("report");
      }

      if (this.$route.query.subject) {
        console.log("has Subject");
        this.selectedIndividual = this.$route.query.subject;
      } else {
        console.log("No Subject");

        var i =
          this.assessment &&
          this.assessment.individualAssessments &&
          this.assessment.individualAssessments.length
            ? this.assessment.individualAssessments[0].individualId
            : null;
        if (i != null) {
          this.selectedIndividual = i;
          this.$router
            .push({ query: Object.assign({}, this.$route.query, { subject: i }) })
            .catch(() => {});
        }
      }
      var individualComplete = this.individualAssessment
        ? this.individualAssessment.complete
        : false;
      if (individualComplete && this.mode != "report") {
        this.changeMode("report");
      } else {
        if (!this.editMode && this.mode != "edit") {
          this.changeMode("edit");
        }
      }
    },
    goToRoute(e) {
      console.log(e);
      this.$router.push({ query: Object.assign({}, this.$route.query, { subject: e }) });
    },
    toggleMode() {
      debugger;
      var mode = "edit";
      if (this.editMode) {
        mode = "report";
      }
      /*this.$router
        .push({ query: Object.assign({}, this.$route.query, { mode: mode }) })
        .catch(() => {});
        */
      this.mode = mode;
    },
    changeMode(e) {
      console.log(`Change Mode: ${e}`);

      debugger;
      if (e != "edit" || (e == "edit" && !this.locked)) {
        /*this.$router
          .push({ query: Object.assign({}, this.$route.query, { mode: e }) })
          .catch(() => {});
          */
        this.mode = e;
      }
    },
  },
  props: {
    assessment: {
      type: Object,
    },
    updateKey: {
      type: Number,
    },
  },
};
</script>
