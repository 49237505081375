import Vue from "vue";
import Vuetify from "vuetify/lib";
import template from "@/configs/template.js";

Vue.use(Vuetify);

const opts = {
  theme: template.theme,
};
export default new Vuetify(opts);
