<template>
  <v-container fluid>
    <v-row class="ma-5">
      <v-col>
        <v-btn
          class="my-4"
          v-if="!loadingData"
          @click="resetSessionData"
          :disabled="refreshingPermissions"
          block
          color="error"
        >
          <v-icon left v-show="!loadingData">fal fa-sync</v-icon>
          <v-icon left v-show="loadingData">fal fa-sync fa-spin</v-icon>

          <span v-if="!loadingData">Refresh</span>
          <span v-else>Refreshing</span> Data
        </v-btn>
        <v-alert dense v-else text color="success">
          <v-list dense light>
            <v-list-item :key="groupsReady + 'groups'">
              <v-list-item-content>Groups </v-list-item-content>
              <v-list-item-action>
                <v-icon v-show="!groupsReady">fal fa-sync fa-spin</v-icon>
                <v-icon v-show="groupsReady">fal fa-check</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item :key="peopleReady + 'people'">
              <v-list-item-content>{{ "Person" | pluralTerm }}</v-list-item-content>
              <v-list-item-action>
                <v-icon v-show="!peopleReady">fal fa-sync fa-spin</v-icon>
                <v-icon v-show="peopleReady">fal fa-check</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item :key="groupTypesReady + 'grouptypes'">
              <v-list-item-content>{{ "GroupType" | pluralTerm }}</v-list-item-content>
              <v-list-item-action>
                <v-icon v-show="!groupTypesReady">fal fa-sync fa-spin</v-icon>
                <v-icon v-show="groupTypesReady">fal fa-check</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item :key="frameworksReady + 'frameworks'">
              <v-list-item-content>{{ "Framework" | pluralTerm }}</v-list-item-content>
              <v-list-item-action>
                <v-icon v-show="!frameworksReady">fal fa-sync fa-spin</v-icon>
                <v-icon v-show="frameworksReady">fal fa-check</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item :key="rubricsReady + 'rubrics'">
              <v-list-item-content>{{ "Rubric" | pluralTerm }}</v-list-item-content>
              <v-list-item-action>
                <v-icon v-show="!rubricsReady">fal fa-sync fa-spin</v-icon>
                <v-icon v-show="rubricsReady">fal fa-check</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item :key="selfReady + 'self'">
              <v-list-item-content>{{ "User" | term }}</v-list-item-content>
              <v-list-item-action>
                <v-icon v-show="!selfReady">fal fa-sync fa-spin</v-icon>
                <v-icon v-show="selfReady">fal fa-check</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-alert>

        <v-card outlined class="mb-5">
          <v-subheader>Manage Bookmarks</v-subheader>
          <v-list dense v-if="bookmarks">
            <v-list-item
              v-for="(b, i) in bookmarks.bookmarks.filter((x) => x.org == org_id)"
              :key="i"
              class="align-center"
            >
              <v-btn color="error" icon text @click="removeBookmark(i)"
                ><i class="fal fa-times"></i
              ></v-btn>
              <v-list-item-content class="mx-3">
                <v-text-field
                  dense
                  @change="updateBookmarks"
                  label="Name"
                  v-model="b.displayName"
                ></v-text-field>
              </v-list-item-content>
              <v-btn icon text color="secondary" :to="b" target="_blank"
                ><i class="fal fa-link"></i
              ></v-btn>
            </v-list-item>
          </v-list>
          <v-card-text
            ><small
              >To add a new bookmark, visit the page and click the
              <i class="mx-2 fal fa-bookmark"></i> bookmark icon</small
            ></v-card-text
          >
        </v-card>

        <v-card outlined v-if="$auth.isDataManager">
          <v-subheader>Manage Session Data</v-subheader>
          <v-card-text
            >Sometimes it's necessary to reset session data. Clicking 'Refresh Data' above
            will reset your own session data.</v-card-text
          >
          <v-list v-if="sessionDatas">
            <v-list-item v-for="(s, i) in sessionDatas" :key="i">
              <sr-person-chip :id="s.userId"></sr-person-chip>
              <v-list-item-action>
                <v-btn icon @click="resetSessionDataById(s.id)">
                  <i class="fal fa-times"></i>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-card-actions v-if="sessionDatas">
            <v-btn text @click="clearSessionData" type="danger">Reset all</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="orgAdmin">
        <v-card outlined class="my-2" :tile="app_info.tile" :loading="!ready">
          <v-toolbar flat extension-height="300">
            <v-toolbar-title class="grey--text">
              {{ "Invitation" | pluralTerm }}
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn icon text @click="showInvites = !showInvites">
              <v-icon v-show="showInvites">close</v-icon>
              <v-icon v-show="!showInvites">mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card
            :loading="!ready && !peopleReady"
            style="background-color: whitesmoke"
            class="border-top"
            tile
            flat
            :key="changes"
            v-if="showInvites"
          >
            <v-card-subtitle class="overline"
              >Invite external users to {{ app_info.name }}</v-card-subtitle
            >
            <v-card-text v-if="request">
              <p>
                When you invite an external user, we will create an individual entity in
                the application straight away, so you can begin assigning them to groups.
              </p>
              <p>
                When the Invitee <b>clicks the invite link</b> (this will be in the email
                if you have elected to email it to them, otherwise you can copy it from
                the table below), they will be connected with their Individual entity
                within the application.
              </p>
              <p>
                If the Invitee logs into the account without using the invite link, they
                will appear in the 'Users' table below, where they can be connected.
              </p>
              <v-form v-model="valid">
                <v-row>
                  <v-col>
                    <v-text-field
                      :rules="[rules.required]"
                      :disabled="saving"
                      v-model="request.firstName"
                      dense
                      outlined
                      :label="`${$getTerm('First Name', false)}*`"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :rules="[rules.required]"
                      :disabled="saving"
                      v-model="request.lastName"
                      dense
                      outlined
                      :label="`${$getTerm('Last Name', false)}*`"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :rules="[rules.required, rules.email]"
                      :disabled="saving"
                      v-model="request.email"
                      dense
                      outlined
                      type="email"
                      :label="`${$getTerm('Email', false)}*`"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-select
                      item-text="name"
                      item-value="id"
                      :disabled="saving"
                      hint="In order to interact with this application, the user must have 'User' role."
                      dense
                      outlined
                      multiple
                      label="Roles"
                      :items="roleOptions"
                      v-model="request.roles"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="mt-3" cols="3">Add to Group (optional): </v-col>
                  <v-col>
                    <v-select
                      :disabled="saving"
                      v-model="request.groupId"
                      dense
                      outlined
                      :items="groupOptions"
                      hint="You should assign this person to the top of the node."
                      persistent-hint
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              v-model="groupSearch"
                              dense
                              append-icon="search"
                            >
                            </v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <group-chip small selectOption :id="item.value"></group-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="shrink">
                    <v-checkbox
                      dense
                      class="mt-1"
                      label="Admin?"
                      hint=""
                      :disabled="saving"
                      v-model="request.admin"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      :disabled="saving"
                      :rules="[rules.required]"
                      v-model="request.inviterName"
                      dense
                      outlined
                      label="Invited By*"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-switch
                      dense
                      class="mt-1"
                      label="Send Invitation by Email"
                      hint=""
                      :disabled="saving"
                      v-model="request.sendEmail"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="app_info.connectionNames.length > 1">
                  <v-select
                    :disabled="saving"
                    dense
                    outlined
                    label="Roles"
                    :items="app_info.connectionNames"
                    v-model="request.connectionName"
                  >
                  </v-select>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="invite" :disabled="!valid || saving" color="primary"
                >Invite to {{ app_info.name }}</v-btn
              >
            </v-card-actions>
          </v-card>

          <v-data-table
            v-if="peopleReady"
            :items="invites"
            :headers="inviteHeaders"
            :search="inviteSearch"
          >
            <template v-slot:item.individualId="{ item }">
              <sr-person-chip :id="item.individualId"></sr-person-chip>
            </template>
            <template v-slot:item.expiresAt="{ item }">
              <span :class="{ 'error--text': expired(item.expiresAt) }">
                <span v-if="expired(item.expiresAt)">Expired</span>
                {{ item.expiresAt | moment("DD MMM YYYY hh:mm") }}
              </span>
            </template>
            <template v-slot:item.inviter="{ item }">
              {{ item.inviter.name }}
            </template>
            <template v-slot:item.invitee="{ item }">
              {{ item.invitee.email }}
            </template>
            <template v-slot:item.invitationUrl="{ item }">
              <a :href="item.invitationUrl">Invite Link</a>
            </template>
          </v-data-table>
        </v-card>
        <v-card outlined class="my-2" :tile="app_info.tile">
          <v-toolbar flat dense extension-height="300">
            <v-toolbar-title class="grey--text">
              {{ "Organization" | term }} {{ "Member" | pluralTerm }}
            </v-toolbar-title>

            <v-spacer></v-spacer>
          </v-toolbar>
          <organization-list :headers="orgHeaders" :url="'permissions/users'">
          </organization-list>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackbar" top>
      {{ snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { clone } from "ramda";
import GroupChip from "../newComponents/MiniComponents/GroupChip.vue";
import OrganizationList from "../newComponents/Settings/OrganizationList.vue";
export default {
  components: { GroupChip, OrganizationList },
  name: "Settings",
  data() {
    return {
      bookmarks: null,
      inviteSearch: null,
      showInvites: false,
      userSearch: null,
      sessionDatas: null,
      users: [],
      invites: [],
      request: null,
      groupSearch: null,
      showSnackbar: false,
      snackbar: null,
      changes: 0,
      loadingData: false,
      refreshingPermissions: false,
      ready: false,
      saving: false,
      valid: false,
      orgHeaders: [
        { text: "", value: "picture" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
      ],
      inviteHeaders: [
        { text: "", value: "individualId" },
        { text: "Email", value: "invitee" },
        {
          text: "Expires",
          value: "expiresAt",
          sort: (a, b) => {
            var aDate = new Date(a);
            var bDate = new Date(b);
            return aDate - bDate;
          },
        },
        { text: "Invite Link", value: "invitationUrl" },
        // {text: 'Roles', value: 'roles'},
      ],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    allReady: function (val) {
      this.loadingData = !val;
    },
  },
  methods: {
    expired(date) {
      var now = Date.now();
      var expiry = new Date(date);
      return now > expiry;
    },
    invite() {
      if (this.valid) {
        this.saving = true;
        this.$post("permissions/users/invite", this.request).then((response) => {
          if (response.error) {
            this.snackbar =
              "Could not send invite. Please try again later or contact support@smartrubric.com";
            this.showSnackbar = true;
          } else {
            this.snackbar = "Success";
            this.showSnackbar = true;
            this.getInvites();
            this.reset();
            this.$store.dispatch("saved/get_people");
          }
          this.saving = false;
        });
      }
    },
    getSessionData() {
      var t = this;
      t.$get("entities/session").then((response) => {
        t.sessionDatas = response.data
          .map((x) => {
            var data = x;
            data.id = x.userId;
            data.userId = x.userId.replace(`|${x.org}`, "");
            data.access = t.$store.getters["saved/get"](["people", x.userId]) != null;
            return data;
          })
          .filter((x) => x.userId != this.$auth.userId && x.access);
        //this.ready = true;
      });
    },
    init() {
      if (this.self != null) {
        this.bookmarks = {
          id: self.id,
          bookmarks: self.bookmarks,
        };
      }
      this.getInvites();
      this.getSessionData();
      // this.getUsers();
      this.reset();
    },
    getInvites() {
      this.$get("permissions/invitations").then((response) => {
        this.invites = response.data;
        this.ready = true;
      });
    },
    getUsers() {
      this.$get("permissions/users").then((response) => {
        this.users = response.data;
        // this.ready = true;
      });
    },
    reset() {
      var request = {
        email: null,
        firstName: null,
        lastName: null,
        groupId: null,
        admin: false,
        createUser: true,
        roles: ["rol_33OrPJsMkyh5wRCo"],
        inviterName: this.self
          ? `${this.self.firstName} ${this.self.lastName}`
          : `${this.app_info.name} Admin`,
        sendEmail: true,
        clientId: this.app_info.clientId,
        connectionName: this.app_info.connectionNames[0],
      };
      this.request = null;
      this.request = request;
      this.changes++;
    },
    removeBookmark(i) {
      this.bookmarks.bookmarks.splice(i, 1);
      this.updateBookmarks();
    },
    updateBookmarks() {
      var t = this;
      this.bookmarks.bookmarks.forEach((element, i) => {
        element.index = i;
      });
      this.$store.dispatch("people/addBookmark", t.bookmarks).then((r) => {
        if (!r.error) {
          t.bookmarks = { id: r.id, bookmarks: clone(r.bookmarks) ?? [] };
        }
      });
    },
    refreshData() {
      this.loadingData = true;

      //   this.$store.dispatch('saved/reset');
      this.$store.dispatch("saved/init");
    },
    resetSessionData() {
      this.refreshingPermissions = true;
      this.$get("saved/sessiondata/delete").then(() => {
        this.refreshingPermissions = false;
        this.refreshData();
      });
    },
    resetSessionDataById(id) {
      console.log(id);
      this.refreshingPermissions = true;
      this.$get(`entities/session/clear/${id}`).then(() => {
        this.refreshingPermissions = false;
      });
    },
    clearSessionData() {
      this.refreshingPermissions = true;
      this.$get(`entities/session/clear`).then(() => {
        this.refreshingPermissions = false;
        this.refreshData();
      });
    },
  },
  computed: {
    allReady() {
      return (
        this.groupsReady &&
        this.peopleReady &&
        // this.assessmentsReady &&
        this.groupTypesReady &&
        this.frameworksReady &&
        this.rubricsReady &&
        this.selfReady
      );
    },
    roleOptions() {
      return this.$store.getters["saved/getAll"]("roles");
    },
    groupsReady() {
      return this.$store.getters["saved/ready"]("groups").ready;
    },
    peopleReady() {
      return this.$store.getters["saved/ready"]("people").ready;
    },
    /*  assessmentsReady() {
      return this.$store.getters["saved/ready"]("assessments").ready;
    },
    */
    groupTypesReady() {
      return this.$store.getters["saved/ready"]("groupTypes").ready;
    },
    frameworksReady() {
      return this.$store.getters["saved/ready"]("frameworks").ready;
    },
    rubricsReady() {
      return this.$store.getters["saved/ready"]("rubrics").ready;
    },
    selfReady() {
      return this.$store.getters["saved/ready"]("self").ready;
    },
    self() {
      return this.$store.getters["people/self"];
    },
    orgAdmin() {
      return this.$auth.isDataManager; //&& this.$auth.isAdmin;
    },
    groupOptions() {
      var groups = this.$store.getters["saved/getAll"]("groups");
      return groups
        .map((group) => {
          debugger;
          var paths = group
            ? group.path.map((g) => {
                var parents = g.groups
                  ? g.groups.map((gp) => {
                      var parent = this.$store.getters["saved/get"](["groups", gp]);
                      return parent ? parent.displayText : "";
                    })
                  : [];
                return parents;
              })
            : [];
          // var count = this.rawAssessments.filter(x=>x.groupId == group.id);
          return {
            text: group.displayText,
            value: group.id,
            path: paths.join(" "),
            member: group.directMember,
            admin: group.directAdmin,
            //    hasCount: count.length != 0
          };
        })
        .filter((x) => {
          if (this.groupSearch && this.groupSearch.length != 0) {
            return (x.text.toLowerCase() + x.path.toLowerCase()).includes(
              this.groupSearch.toLowerCase()
            );
          } else {
            return x;
          }
        });
    },
    info() {
      var bar = {
        mode: "loading...",
        color: "black",
      };
      if (this.app_info.mode) {
        bar = {
          mode:
            this.app_info.version +
            ": <em>" +
            this.app_info.mode.toUpperCase() +
            " MODE </em>",
          color: "info",
        };
        if (bar.mode == "development") {
          bar.color = "secondary";
        }
      }

      return bar;
    },
  },
};
</script>
