<template>
  <v-row v-if="editablePerson">
    <v-col>
      <v-card flat class="text-left">
        <div class="d-flex justify-start align-center ml-5">
          <v-avatar color="primary" class="elevation-2" v-if="personChip" size="70">
            <v-img v-if="personChip.gravatar" :src="personChip.gravatar"></v-img>
            <span class="white--text text-h4" v-else>{{ personChip.initials }}</span>
          </v-avatar>
          <div class="px-2">
            <v-card-title>{{ getDisplayName(person) }}</v-card-title>
            <v-card-subtitle
              class="slash-delimit"
              v-if="
                person.preferredPronouns &&
                Object.values(person.preferredPronouns).filter((x) => x).length
              "
              ><span
                :key="i"
                v-for="(pronoun, i) in Object.values(person.preferredPronouns).filter(
                  (x) => x
                )"
                >{{ pronoun }}</span
              >
              <div v-if="person.dob">
                <strong>D.O.B.</strong>
                {{ person.dob | moment("DD MMMM YYYY") }}
              </div>
              <v-tooltip
                top
                v-if="primaryEmail && (person.isSelf || person.manageUserAccount)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"
                    ><strong>Username: </strong> {{ primaryEmail.address }}</span
                  >
                  To update username, contact support@smartrubric.com
                </template>
              </v-tooltip>
            </v-card-subtitle>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="editMode = !editMode" v-if="!person.readonly"
            ><v-icon>fas fa-pencil</v-icon></v-btn
          >
        </div>
        <v-card-title v-if="editMode">Personal Details</v-card-title>
        <v-card-text v-if="editMode">
          <v-row>
            <v-col>
              <v-text-field
                dense
                label="Title"
                v-if="editMode"
                v-model="editablePerson.title"
                @input="setUnsaved"
              >
              </v-text-field>
              <v-text-field
                dense
                label="First Name"
                v-model="editablePerson.firstName"
                @input="setUnsaved"
              ></v-text-field>
              <v-text-field
                dense
                label="Last Name"
                @input="setUnsaved"
                v-model="editablePerson.lastName"
              ></v-text-field>
              <v-text-field
                dense
                @input="setUnsaved"
                label="Gender"
                v-model="editablePerson.gender"
              ></v-text-field>

              <v-text-field
                style="width: 100%"
                type="date"
                class="mx-2"
                @input="setUnsaved"
                name="dob"
                v-if="editMode"
                v-model="editablePerson.dob"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters align="center">
                <v-col>
                  <v-select
                    :items="pronounOptions"
                    label="Preferred pronouns"
                    item-value="value"
                    @input="setUnsaved"
                    :reduce="(x) => x.value"
                    v-model="editablePerson.preferredPronouns"
                  >
                    <template v-slot:item="{ item }">
                      <span v-if="item.label != 'Create Custom'">
                        <strong> {{ item.name | capitalize }}</strong>
                        ({{ item.label }})
                      </span>
                      <strong class="primary--text" v-else> Customise... </strong>
                    </template>
                    <template v-slot:selection="{ item }">
                      <span v-if="item.label != 'Create Custom'">
                        <strong> {{ item.name | capitalize }}</strong>
                        ({{ item.label }})
                      </span>
                      <strong class="primary--text" v-else> Customise... </strong>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="5">
                  <v-btn
                    block
                    class="mt-5 ml-2"
                    color="primary"
                    text
                    @click="customisePronouns = !customisePronouns"
                  >
                    <span v-if="!customisePronouns">Customise</span>
                    <span v-else>Cancel</span>
                  </v-btn>
                </v-col>
              </v-row>

              <div v-if="customisePronouns" class="pt-3">
                <v-text-field
                  :key="i"
                  dense
                  outlined
                  @input="setUnsaved"
                  v-for="(item, i) in Object.keys(editablePerson.preferredPronouns)"
                  v-model="editablePerson.preferredPronouns[item]"
                  :label="item"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="editMode">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="init">Cancel</v-btn>

          <v-btn color="success" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col md="5" lg="3" v-if="person.isSelf || !person.readonly">
      <sr-bookmarks :linky="false" :personId="person.id"> </sr-bookmarks>
    </v-col>
  </v-row>
</template>

<script>
import entities from "../objects/entities";

export default {
  name: "PersonHome",
  data() {
    return {
      updatedUser: null,
      editMode: false,
      address: entities.address,
      customisePronouns: false,
      updates: 0,
      editablePerson: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    /* linkAccount(key){
            debugger;
            var id = this.person.linkedAccounts[key];
             this.$post(`permissions/user/${this.person.id}/linked`, {id: id}).then((response)=>{
                  this.updatedUser= response.data;
             })
        }
        */
    init() {
      this.editablePerson = this.$r.clone(this.person);
    },
    setUnsaved() {
      this.updates++;
    },
    save() {
      this.message = { text: "Saving...", color: "primary" };
      this.$post("entities/individuals/upsert", this.editablePerson).then(() => {
        this.$emit("update");
        this.changes = 0;
        this.editMode = false;
      });
    },
    addAddress() {
      var newAddress = {};
      newAddress = this.$r.clone(this.address);
      this.person.addresses.push(newAddress);
    },
    removeAddress(i) {
      this.person.addresses.splice(i, 1);
    },
  },
  computed: {
    personChip() {
      return this.person ? this.getPersonChip(this.person) : null;
    },
    primaryEmail() {
      var result = this.person ? this.$r.clone(this.person.emails) : null;
      return result.find((x) => x.primary);
    },
    pronounOptions() {
      return this.getPronounOptions();
    },
  },
  props: {
    person: {
      type: Object,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
