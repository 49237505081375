import Vue from "vue";
import template from "../configs/template.js";
import { authGuard } from "../auth/authGuard";
import Mixins from "../assets/scripts/mixins.js";
import VueRouter from "vue-router";
import moment from "moment";
//Public
import PublicWrapper from '../wrappers/PublicWrapper.vue';
import PublicHome from '../views/Public/Home.vue';
import Invitation from '../views/Public/AcceptInvite.vue';
//App
import AppWrapper from '../wrappers/AppWrapper.vue';
import Home from '../newViews/Home.vue';

//Settings
import Settings from '../newViews/Settings.vue';

//Groups
import Groups from '../lists/GroupList.vue';
import GroupWrapper from '../newWrappers/GroupWrapper.vue';
import GroupHome from '../dashboards/GroupHome.vue';
import GroupPerson from '../newComponents/PersonComponents/GroupPerson.vue';
import GroupSettings from '../settings/GroupSettings.vue';
import GroupBaseline from '../newComponents/GroupComponents/GroupBaseline.vue';
import CreateAssessment from '../newComponents/CreateAssessment.vue';
import CreateGroupWrapper from '../newWrappers/CreateGroupWrapper.vue';
import GroupSubgroups from '../newComponents/GroupComponents/GroupSubgroups.vue';
//People
import People from '../lists/PeopleList.vue';
import PersonWrapper from '../newWrappers/PersonWrapper.vue';
import PersonHome from '../dashboards/PersonHome.vue';
import Profile from "../newWrappers/SelfWrapper.vue";
import PersonGroups from '../newComponents/PersonComponents/GroupList.vue';
import PersonSettings from '../settings/PersonSettings.vue';
//Library
//import Library from '../lists/LibraryList.vue';
//import LibraryWrapper from '../newWrappers/LibraryWrapper.vue';
//import LibraryHome from '../dashboards/LibraryHome.vue';
import Frameworks from '../lists/FrameworkList.vue';
import FrameworkWrapper from '../newWrappers/FrameworkWrapper.vue';
import FrameworkHome from '../dashboards/FrameworkHome.vue';
import FrameworkAssessments from '../newComponents/FrameworkComponents/AssessmentList'
import FrameworkSettings from '../settings/FrameworkSettings';
import FrameworkEditSchema from '../newComponents/FrameworkComponents/EditSchema.vue';

//Assessments
import Assessments from '../lists/AssessmentList.vue';
import AssessmentWrapper from '../newWrappers/AssessmentWrapper.vue';
import AssessmentHome from '../dashboards/AssessmentHome.vue';
import AssessmentSettings from '../settings/AssessmentSettings.vue';
//import ArchivedAssessments from '../lists/ArchivedAssessmentList.vue';

//Assessments
import OpenAssessments from '../newComponents/AssessmentList.vue';
//import AssessmentWrapper from '../wrappers/AssessmentWrapper.vue';

//import IndividualAssessment from '../details/IndividualAssessment.vue';

//Rubrics

import Rubrics from '../lists/RubricList.vue';

import RubricsWrapper from '../newWrappers/RubricWrapper.vue';
import RubricHome from '../dashboards/RubricHome.vue';
import RubricAssessments from '../newComponents/RubricComponents/AssessmentList';
import RubricSettings from '../settings/RubricSettings';
import RubricEditSchema from '../newComponents/RubricComponents/EditSchema.vue';

//GroupTypes
import GroupTypes from '../newViews/GroupTypes.vue'
//import GroupDetail from '../details/GroupDetail.vue';

//Reports
import Report from '../newViews/Report.vue';
import ReportWidget from '../newComponents/ReportWidget.vue';
import BaselineReport from '../newWrappers/PrintBaseline.vue';

Vue.use(VueRouter);

const mixins = Mixins.methods;
const helpURI = process.env.VUE_APP_HELP_URI;
const appName = template.appInfo.short_name;
const appDescription = template.appInfo.description;

const routes = [
    {path: "",
        component: PublicWrapper,
        meta: {
            requiresAuth: false,
            title: appName,
            bcLinkText: "Public",
            metaTags: [
                {
                    title: appName,
                    name: "description",
                    content: appDescription,
                },
            ],
        },
        children:[
            {path: "",
                name: "Public",
                component: PublicHome
        },
        {
            path: "login",
            meta:{
                requiresAuth: true,

            },
                name: "AcceptInvitation",
                component: PublicHome
            },
            {
                path: "invitation",
                meta:{
                    requiresAuth: false,
    
                },
                    name: "Invitation",
                    component: Invitation
                },
                {
                    path: "/baseline/:individualid/:groupid",
                    name: "BaselineReport",
                    component: BaselineReport,
                    props: route => ({groupId: route.params.groupid, personId: route.params.individualid}),
                    meta: {
                    requiresAuth: true,
                   
                    },
                    }

        ]
    },
    {
        path: "/app",
        component: AppWrapper,
        props: (route) => {
            var data = [];
            if(route.params != null){
                var keys = Object.keys(route.params) ; 
                keys.forEach((key, i) => {
                    var d = Object.getOwnPropertyDescriptor(route.params, key);
                    
                    if(!key.includes('assessment')){
                        var entry = {
                            getter: null,
                            index: i,
                            id: d.value
                        };
                        if(key.includes('group')){
                            entry.getter = 'groups'
                        }
                        if(key.includes('individual')){
                            entry.getter = 'people'
                        }
                        if(key.includes('framework')){
                            entry.getter = 'frameworks'
                        }
                        if(key.includes('rubric')){
                            entry.getter = 'rubrics'
                        }
                        data.push(entry);
                    }
                    data = data.filter(d=>d);
                   
                });
            }
            
          return  { 
                
                nameInfo: data, 
               
            }
        }
            
           ,
        meta: {
            requiresAuth: true,
            title: appName,
            bcLinkText: "Home",
            bcHide: true,

            metaTags: [
            {
                title: appName,
                name: "description",
                content: appDescription,
            },
            ],
        },
        children:[
            {path: "",
            name: "Home",
            component: Home,
            meta: {
                requiresAuth: true,
                title: appName,
                bcLinkText: "Home",
                help: `${helpURI}dashboard`,
                metaTags: [
                    {
                    title: appName,
                    name: "description",
                    content: appDescription,
                    },
                ],
            },
            },
            
            {path: "/settings",
                name: "Settings",
                component: Settings,
                meta: {
                requiresAuth: true,
                title: appName,
                bcLinkText: "Settings",
                help: `${helpURI}settings`,
                metaTags: [
                    {
                    title: appName,
                    name: "description",
                    content: appDescription,
                    },
                ],
                },
            },
           {path: "/profile",
                component: Profile,
                meta:{
                    title: appName,
                    bcLinkText: "Profile",
                    toRoute: "Profile",
                    bcHide: false,
                    help: `${helpURI}profile`,
                },
                children:[
                   
                    {path: "",
                    component: PersonWrapper,
                    meta: {
                        title: appName,
                        bcLinkText: "Home",
                        toRoute: "PersonHome",
                        bcHide: true,
                        help: `${helpURI}people`,
                    },
                    children: [
                        {
                            path: "",
                            component: PersonHome,
                            name: "ProfileHome",
                            meta: {
                                title: appName,
                                bcLinkText: "Profile",
                                toRoute: "ProfileHome",
                                bcHide: true,
                                help: `${helpURI}people`,
                            },
                        },
                        {path: "groups",
                        name: "ProfileGroups",
                        component: PersonGroups,
                        meta: {
                            title: appName,
                            bcLinkText: "Profile Groups",
                            toRoute: "ProfileGroups",
                            bcHide: true,
                            help: `${helpURI}people`,
                        },
                        children:[
                            {path: ":groupid",
                            name: "ProfileGroup",
                            component: GroupPerson,
                            meta: {
                                title: appName,
                                bcLinkText: "Home",
                                toRoute: "ProfileGroup",
                                bcHide: true,
                                help: `${helpURI}people`,
                            },
                            title: function (group) {
                                if (group) {
                                    return `${appName} | ${group.name}`;
                                }
                                return `${appName} | Not Found`;
                                },
        
                        },
                    ]

                },
               
                ]

            },
                ]
            },
          
            {path: "/reports",
            name: "Report",
            component: Report,
            props:true,
            meta:{
                title: appName,
                bcLinkText: "Reports",
                toRoute: "Report",
                bcHide: false,
                help: `${helpURI}reports`,
                }
             },
             { path:'/groups/create',
                component: CreateGroupWrapper,
                meta: {
                requiresAuth: true,
                title: appName,
                bcLinkText: "Create",
                toRoute: "CreateGroup",
                help: `${helpURI}groups`,
                metaTags: [
                    {
                    title: appName,
                    name: "description",
                    content: appDescription,
                    },
                ],
                },
                children:[
                    {
                        path: "",
                        name: 'CreateGroup',
                        component: GroupSubgroups,
                        meta:{
                            bcLinkText: "Create New",
                            toRoute: "CreateGroup",
                            help: `${helpURI}groups`,
                            metaTags: [
                                {
                                title: appName,
                                name: "description",
                                content: appDescription,
                                },
                            ]
                        
                        }
                        
                    }
                ]
            },
            {path: "/groups/",
                name: "AllGroups",
                component: Groups,
                meta: {
                requiresAuth: true,
                title: appName,
                bcLinkText: "Groups",
                toRoute: "AllGroups",
                help: `${helpURI}groups`,
                metaTags: [
                    {
                    title: appName,
                    name: "description",
                    content: appDescription,
                    },
                ],
                },
                children:[
                  
                    {
                        path: ":groupid",
                        component: GroupWrapper,   
                        props: true,

                        meta: {
                            bcDynamic: false,
                            bcIsChild: true,
                            toRoute: "Group",
                            title: function (group) {
                            if (group) {
                                return `${appName} | ${group.name}`;
                            }
                            return `${appName} | Not Found`;
                            },
                            bcGetter: "entities/gRoute", 
                            bcLinkText: function (group) {
                            return group ? group.name : "";
                            }, 
                            bcLoadingText: "Loading Group...",
                        },
                        children:[
                            {
                                path: "",
                                name: "GroupHome",
                                component: GroupHome,
                                meta: {
                                    title: appName,
                                    bcLinkText: "Home",
                                    toRoute: "GroupHome",
                                    bcHide: true,
                                    help: `${helpURI}groups`,
                                    }
                                
        
                            },
                            {
                                path: mixins.getTerm('assessment', true),
                                name: "GroupAssessments",
                                components: {
                                   default:  OpenAssessments,
                                   header: CreateAssessment
                                },
                                props: {
                                    default: route=>({groupId: route.params.groupid, headers: [
                                        {
                                            text: "Date",
                                            value: "created",
                                            sortable: true,
                                          
                                          },
                                          {
                                            text: "Title",
                                            value: "assessmentTitle",
                                            sortable: true,

                                          },
                                          {
                                            text: "Rubric",
                                            value: "rubricTitle",
                                            sortable: true,

                                          },
                                          {
                                            text: "Scores",
                                            value: "scores",
                                            sortable: false,
                                          },
                                          {
                                            text: "Status",
                                            value: "complete",
                                            sortable: false,
                                          },
                                
                                          {
                                            text: "",
                                            value: "actions",
                                            sortable: false,

                                          },
                                    ] }),
                                    header: route=>({groupId: route.params.groupid }),
                                },
                                meta:{
                                    title: appName,
                                    bcLinkText: mixins.getTerm('Assessment', true),
                                    toRoute: "GroupAssessments",
                                    bcHide: false,
                                    help: `${helpURI}assessments`,
                                },
                                children: [
                                    {
                                        path: `/:groupid/${mixins.getTerm('assessment', true)}/:assessmentid`,
                                        name: "GroupAssessmentDetail",
                                        component: AssessmentHome,
                                        props: true,
                                        meta: {
                                            bcDynamic: true,
                                            bcIsChild: true,
                                            title: function (f) {
                                            if (f) {
                                                return `${appName} | ${f.title ?? f.rubricTitle}`;
                                            }
                                            return `${appName} | ${mixins.getTerm('Assessment', false)}`;
                                            },
                                            bcGetter: `${mixins.getTerm('assessment', true)}/${mixins.getTerm('assessment', false)}`,
                                            bcLinkText: function (f) {
                                            if (f) {
                                                return function () {
                                                return `${this.groupName(f.groupId) + ': ' ?? ''} ${
                                                    f.title ?? f.rubricTitle
                                                } ${f.created ? '('+ moment(new Date(f.created)).format("DD/MM/YY")+')' : ''}`;
                                                };
                                            }
                                            return `${mixins.getTerm('Assessment', false)}`;
                                            },
                                            bcLoadingText: `Loading ${mixins.getTerm('Assessment', false)}...`
                                        },
                                    }
                                ]
                            },
                          
                         
                            {path: "rubrics",
                                name: "GroupRubrics",
                                component: Rubrics,
                                meta:{
                                    title: appName,
                                    bcLinkText: "Rubrics",
                                    toRoute: "GroupRubrics",
                                    bcHide: false,
                                    help: `${helpURI}rubrics`,
                                },
                                children: [
                                    {
                                        path: "/:groupid/rubrics/:rubricid",
                                        name: "GroupRubricDetail",
                                        component: RubricHome,
                                        props: true,
                                        meta: {
                                            bcDynamic: true,
                                            bcIsChild: true,
                                            title: function (f) {
                                            if (f) {
                                                return `${appName} | ${f.title}`;
                                            }
                                            return `${appName} | Rubric`;
                                            },
                                            bcGetter: "rubrics/rubric",
                                            bcLinkText: function (f) {
                                            if (f) {
                                                return f.title;
                                            }
                                            return `Rubric`;
                                            },
                                            bcLoadingText: "Loading Rubric...",
                                        },
                                    
                                    }
                                ]
                            },

                           
                            {
                                path: "baseline",
                                name: "GroupBaseline",
                                component: GroupBaseline,
                                meta:{
                                    title: appName,
                                    bcLinkText: "Baseline",
                                    toRoute: "GroupBaseline",
                                    bcHide: false,
                                    help: `${helpURI}group-baseline`,
                                }
                            },
                            {path: "settings",
                                name: "GroupSettings",
                                component: GroupSettings,
                                meta:{
                                    title: appName,
                                    bcLinkText: "Settings",
                                    toRoute: "GroupSettings",
                                    bcHide: false,
                                    help: `${helpURI}group-settings`,
                                }
                            },
                            {path: "report",
                                name: "GroupReport",
                                component: ReportWidget,
                                props: route => ({groupId: route.params.groupid}),
                                meta:{
                                    title: appName,
                                    bcLinkText: "Report",
                                    toRoute: "GroupReport",
                                    bcHide: false,
                                    help: `${helpURI}group-report`,
                                }
                            },
                           
                            
                        ]
                    },
                 
                ]
            },
            {path: `/${mixins.getTerm('assessment', true)}/archived`,
                    component: Assessments,
                    name: 'ArchivedAssessments',
                    props:true,
                    meta : {
                        bcDynamic: false,
                        bcIsChild: true,
                        bcLinkText: `Archived ${mixins.getTerm('assessment', true)}`,
                        title:  `${appName} | Archived ${mixins.getTerm('assessment', true)}`,
                        
                    },

                
            },
            {path: `/${mixins.getTerm('assessment', true)}`,
                component: Assessments,
                props:true,
                name: "Assessments",
                meta: {
                    bcIsChild: false,
                    bcLinkText: mixins.getTerm('Assessment', true),
                    title:  `${appName} | ${mixins.getTerm('assessment', true)}`,
                    bcHide: true,
                },
                children: [
                    {
                    path: ":assessmentid",
                    component: AssessmentWrapper,
                    props: true,
                    redirect: to => {
                        return {
                            name: 'AssessmentHome' ,
                            params: to.params
                        }
                    },
                    meta: {
                        bcDynamic: false,
                        bcIsChild: true,
                    
                        title: function (f) {
                        if (f) {
                            return `${appName} | ${f.title ?? f.rubricTitle}`;
                        }
                        return `${appName} | ${mixins.getTerm('Assessment', false)}`;
                        },
                    /*    bcGetter: "assessments/assessment",
                       bcLinkText: function (f) {
                        if (f) {
                            return function () {
                            return `${this.groupName(f.groupId) + ': ' ?? ''} ${
                                f.title ?? f.rubricTitle
                            } ${f.created ? '('+ moment(new Date(f.created)).format("DD/MM/YY")+')' : ''}`;
                            };
                        }
                        return `Assessment`;
                        },
                        */
                        bcLoadingText: `Loading ${mixins.getTerm('Assessment', false)}...`,
                    },
                    children: [
                        {
                            path: `${mixins.getTerm('assess', false)}`,
                            name: "AssessmentHome",
                            component: AssessmentHome,
                            meta: {
                                title: appName,
                                bcLinkText: mixins.getTerm('Assessment', false),
                                toRoute: "Assessment",
                                bcHide: true,
                                help: `${helpURI}assessment`,
                            },
                        },
                        {
                            path: "settings",
                            name: "AssessmentSettings",
                            component: AssessmentSettings,
                            meta: {
                                title: appName,
                                bcLinkText: "Settings",
                                toRoute: "AssessmentSettings",
                                bcHide: true,
                                help: `${helpURI}assessment`,
                            }
                        },
                    ]
                    },
                ],
                
                
            },
            {path: "/frameworks/",
                name: "AllFrameworks",
                component: Frameworks,
                meta: {
                bcIsChild: false,
                title: appName + " | Frameworks",
                bcHide: true,
                },
                children: [
                {
                    path: ":frameworkid",
                    component: FrameworkWrapper,
                    redirect: to => {
                        return {
                            name: 'FrameworkHome' ,
                            params: to.params
                        }
                    },
                    props: true,
                    meta: {
                    bcDynamic: false,
                    bcIsChild: true,
                    title: function (f) {
                        if (f) {
                        return `${appName} | ${f.name}`;
                        }
                        return `${appName} | Framework`;
                    },
                 /*   bcGetter: "frameworks/framework",
                    bcLinkText: function (f) {
                        if (f) {
                        return f.title;
                        }
                        return `Framework`;
                    },
                    */
                    bcLoadingText: "Loading Framework...",
                    },
                    children:[
                        {
                            path: "home",
                            name: "FrameworkHome",
                            component: FrameworkHome,
                            meta: {
                                title: appName,
                                bcLinkText: "Home",
                                toRoute: "FrameworkHome",
                                bcHide: true,
                                help: `${helpURI}frameworks`,
                            }
                        },
                        {
                            path: "edit",
                            name: "FrameworkEditSchema",
                            component: FrameworkEditSchema,
                            meta: {
                                title: appName,
                                bcLinkText: "Edit",
                                toRoute: "FrameworkEditSchema",
                                bcHide: true,
                                help: `${helpURI}frameworks`,
                            }
                        },
                        {
                            path: "settings",
                            name: "FrameworkSettings",
                            component: FrameworkSettings,
                            meta: {
                                title: appName,
                                bcLinkText: "Settings",
                                toRoute: "FrameworkSettings",
                                bcHide: true,
                                help: `${helpURI}frameworks`,
                            }
                        },
                        {
                            path: mixins.getTerm('assessment', true),
                            name: "FrameworkAssessments",
                            component: FrameworkAssessments,
                            meta: {
                                title: appName,
                                bcLinkText: mixins.getTerm('Assessment', true),
                                toRoute: "FrameworkAssessments",
                                bcHide: true,
                                help: `${helpURI}frameworks`,
                            },
                           
                        },
                        
                    ]
                },
                ],
            },
            {path: "/rubrics/",
            name: "AllRubrics",
            component: Rubrics,
            meta: {
            bcIsChild: false,
            title: appName + " | Rubrics",
            bcHide: true,
            },
            children: [
            {
                path: ":rubricid",
                component: RubricsWrapper,
                redirect: to => {
                    return {
                        name: 'RubricHome' ,
                        params: to.params
                    }
                },
                props: true,
                meta: {
                bcDynamic: false,
                bcIsChild: true,
                title: function (f) {
                    if (f) {
                    return `${appName} | ${f.name}`;
                    }
                    return `${appName} | Rubric`;
                },
             /*   bcGetter: "frameworks/framework",
                bcLinkText: function (f) {
                    if (f) {
                    return f.title;
                    }
                    return `Framework`;
                },
                */
                bcLoadingText: "Loading Rubric...",
                },
                children:[
                    {
                        path: "home",
                        name: "RubricHome",
                        component: RubricHome,
                        meta: {
                            title: appName,
                            bcLinkText: "Home",
                            toRoute: "RubricHome",
                            bcHide: true,
                            help: `${helpURI}rubrics`,
                        }
                    },
                  {
                            path: "edit",
                            name: "RubricEditSchema",
                            component: RubricEditSchema,
                            meta: {
                                title: appName,
                                bcLinkText: "Edit",
                                toRoute: "RubricEditSchema",
                                bcHide: true,
                                help: `${helpURI}rubrics`,
                            }
                        },
                        
                        {
                            path: "settings",
                            name: "RubricSettings",
                            component: RubricSettings,
                            meta: {
                                title: appName,
                                bcLinkText: "Settings",
                                toRoute: "RubricSettings",
                                bcHide: true,
                                help: `${helpURI}rubrics`,
                            }
                        },
                        {
                            path: mixins.getTerm('assessment', true),
                            name: "RubricAssessments",
                            component: RubricAssessments,
                            meta: {
                                title: appName,
                                bcLinkText: mixins.getTerm('Assessment', true),
                                toRoute: "RubricAssessments",
                                bcHide: true,
                                help: `${helpURI}rubrics`,
                            },
                           
                        },
                  
                    
                ]
            },
            ],
        },
            {path: "/people/",
                name: "People",
                component: People,
                meta: {
                requiresAuth: true,
                title: appName,
                bcLinkText: "People",
                toRoute: "People",
                help: `${helpURI}people`,
                metaTags: [
                    {
                    title: appName,
                    name: "description",
                    content: appDescription,
                    },
                ],
                },
                children:[
                    {path: ":individualid",
                        component: PersonWrapper,   
                        props: true,
                        meta: {
                            bcDynamic: false,
                            bcIsChild: true,
                           title: function (person) {
                            if (person) {
                                return `${appName} | ${mixins.getDisplayName(person)}`;
                            }
                            return `${appName} | Not Found`;
                            },
                         /*   bcGetter: "people/pRoute", // <breadcrumb> will use this getter to get the user from vuex
                            bcLinkText: function (person) {
                            if (person) {
                                return mixins.getDisplayName(person);
                            }
                            return "Not Found";
                            }, // once we have the user, we use this function to format the LinkText dnynamically,
                            bcLoadingText: "Loading Person...",
                            */
                        },
                        children:[
                            {path: "",
                                name: "PersonHome",
                                component: PersonHome,
                                meta: {
                                    title: appName,
                                    bcLinkText: "Home",
                                    toRoute: "PersonHome",
                                    bcHide: true,
                                    help: `${helpURI}people`,
                                }
        
                            },
                            {path: "settings",
                            name: "PersonSettings",
                            component: PersonSettings,
                            meta: {
                                title: appName,
                                bcLinkText: "Settings",
                                toRoute: "PersonSettings",
                                bcHide: true,
                                help: `${helpURI}people`,
                            }
    
                        },
                            {path: "groups",
                            name: "PersonGroups",
                            component: PersonGroups,
                            meta: {
                                title: appName,
                                bcLinkText: "Home",
                                toRoute: "PersonHome",
                                bcHide: true,
                                help: `${helpURI}people`,
                            },
                            children:[
                                {path: ":groupid",
                                name: "PersonGroup",
                                component: GroupPerson,
                                meta: {
                                    title: appName,
                                    bcLinkText: "Home",
                                    toRoute: "PersonGroup",
                                    bcHide: true,
                                    help: `${helpURI}people`,
                                },
                                title: function (group) {
                                    if (group) {
                                        return `${appName} | ${group.name}`;
                                    }
                                    return `${appName} | Not Found`;
                                    },
        
                            },
                            ]
    
                        },
                            
             
                        ]
                    }
                ]
            },
            {path:"/types",
                name: "GroupType",
                component: GroupTypes,
                 /*  meta: {
                    bcDynamic: true,
                    help: `${helpURI}grouptypes`,
                    bcType: "group",
                    bcIsChild: false,
                    bcGetter: "entities/gtRoute", // <breadcrumb> will use this getter to get the user from vuex
                    bcLinkText: (group) => group.name,
                    title: function (group) {
                    if (group) {
                        return `${appName} | ${group.name}`;
                    }
                    return `${appName} | Groups`;
                    },
                },
                children: [
                    {
                        path: ":groupid",
                        name: "GroupDetail",
                        component: GroupDetail,
                        props: true,
                        meta: {
                            bcDynamic: true,
                            bcIsChild: true,
                            toRoute: "GroupDetail",
                            title: function (group) {
                            if (group) {
                                return `${appName} | ${group.name}`;
                            }
                            return `${appName} | Not Found`;
                            },
                            bcGetter: "entities/gRoute", // <breadcrumb> will use this getter to get the user from vuex
                            bcLinkText: function (group) {
                            return group ? group.name : "";
                            }, // once we have the user, we use this function to format the LinkText dnynamically,
                            bcLoadingText: "Loading Group...",
                        },
                    }
                ]
                */
            },
           
        ]
    }
    
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });
  
  router.beforeEach((to, from, next) => authGuard(to,from, next));
  export default router;