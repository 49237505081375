<style>
#nav,
#nav .nav-title {
  transition: height 1s, display 1s, justify-content 2s, background-color 2s;
  -webkit-transition: all 1s;
}

#nav.loading .loading-hidden {
  display: none;
}
</style>
<template>
  <div>
    <v-app-bar
      elevate-on-scroll
      dark
      app
      :class="{ noTopPadder: noTopPadder }"
      id="nav"
      clipped-left
      :extended="extendToolbar && $vuetify.breakpoint.smAndDown"
      :color="template.color"
      elevation="1"
      class="border-bottom"
    >
      <v-btn
        class="loading-hidden"
        icon
        v-show="!mdAndUp && self"
        @click.stop="toggleDrawer"
      >
        <i class="fal fa-bars"></i>
      </v-btn>
      <v-btn class="loading-hidden" icon v-show="mdAndUp" @click.stop="toggleMini">
        <i class="fal fa-bars"></i>
      </v-btn>
      <router-link
        :to="{ name: 'Public' }"
        class="d-flex align-content-center"
        style="text-decoration: none; color: white; height: 100%"
      >
        <v-toolbar-title class="d-flex align-center nav-title link">
          <v-avatar
            v-if="!app_info.colorfulHeadings"
            tile
            class="mr-5 loading-hidden"
            size="30"
          >
            <img :src="iconUrl" />
          </v-avatar>
          <img v-else height="25" class="pr-3 mb-3" :src="iconUrl" />
          <span v-html="app_info.htmlName ? app_info.htmlName : app_info.name"></span>
        </v-toolbar-title>
      </router-link>
      <span
        v-if="mdAndUp"
        style="font-size: 0.9em !important"
        class="ml-3 overline pt-2 dark--text text--lighten-5"
        >{{ app_info.description }}</span
      >
      <v-spacer class="loading-hidden"></v-spacer>
      <v-text-field
        v-if="$vuetify.breakpoint.mdAndUp"
        hide-details
        class="hidden"
        prepend-inner-icon="mdi-magnify"
        :append-icon="searchAppend"
        single-line
        v-model="searchTerm"
        placeholder="Search..."
        @keydown.enter="search"
      ></v-text-field>
      <v-btn
        class="hidden"
        @click="toggleExtension"
        v-if="$vuetify.breakpoint.smAndDown"
        icon
      >
        <v-icon v-if="!extendToolbar">mdi-magnify</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </v-btn>
      <v-btn
        v-if="mdAndUp"
        icon
        small
        class="loading-hidden"
        :to="{ name: 'PersonHome', params: { individualid: $auth.userId } }"
      >
        <v-avatar class="border-white" size="35px">
          <img alt="Avatar" :src="getGravatar($auth.user.email, 30)" />
        </v-avatar>
      </v-btn>
      <v-btn
        class="loading-hidden"
        text
        v-if="mdAndUp || hideDrawerToggle"
        @click="loginToggle"
        color="white"
      >
        <span v-show="loggedIn">
          Log Out
          <i class="fad ml-2 fa-fw fa-sign-out-alt"></i>
        </span>
        <span v-show="!loggedIn">
          <i class="mr-2 fa-fw fad fa-sign-in-alt"></i>
          Log In
        </span>
      </v-btn>
      <v-btn :key="loadingData" @click="resetSessionData" class="mx-2" icon>
        <v-icon v-show="loadingData">fad fa-sync fa-spin</v-icon>
        <v-icon v-show="!loadingData">fad fa-sync </v-icon>
      </v-btn>
      <template v-slot:extension v-if="extendToolbar">
        <v-text-field
          hide-details
          :append-icon="searchAppend"
          prepend-icon="mdi-magnify"
          single-line
          class="pb-3 hidden"
          ref="search"
          placeholder="Search..."
          v-model="searchTerm"
          @keydown.enter="search"
        ></v-text-field>
      </template>
    </v-app-bar>
    <v-dialog
      fluid
      :value="showSearch"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="dialog-top-transition"
      v-on:click:outside="clearSearch"
    >
      <v-card>
        <v-row no-gutters>
          <v-col
            id="personSearchResult"
            cols="6"
            :class="{ 'border-right': $vuetify.breakpoint.smAndUp }"
          >
            <v-list>
              <v-subheader><i class="fad fa-user mr-3"></i>People</v-subheader>
              <v-divider></v-divider>

              <v-list-item
                class="text-center"
                v-if="
                  !searchingDbPeople &&
                  personSearchResults &&
                  personSearchResults.length == 0
                "
              >
                <v-list-item-subtitle><em>No people found</em></v-list-item-subtitle>
              </v-list-item>

              <v-list-item
                v-for="p in personSearchResults"
                :key="p.id"
                :to="{ name: 'PersonHome', params: { individualid: p.id } }"
                @click="clearSearch"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ p.displayName }}</v-list-item-title>
                  <v-list-item-subtitle v-if="p.description">{{
                    p.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="searchingDbPeople">
                <v-list-item-icon
                  ><i class="fal fa-spinner fa-spin"></i
                ></v-list-item-icon>
                <v-list-item-subtitle><em>Checking database...</em></v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col id="groupSearchResult">
            <v-list>
              <v-subheader><i class="fad fa-users mr-3"></i>Groups</v-subheader>
              <v-divider></v-divider>
              <v-list-item
                class="text-center"
                v-if="
                  !searchingDbGroups &&
                  groupSearchResults &&
                  groupSearchResults.length == 0
                "
              >
                <v-list-item-subtitle><em>No groups found</em></v-list-item-subtitle>
              </v-list-item>
              <v-list-item
                v-for="g in groupSearchResults"
                :key="g.Id"
                :to="{ name: 'GroupHome', params: { groupid: g.id } }"
                @click="clearSearch"
              >
                <v-list-item-content>
                  <v-badge dot left inline :color="g.primaryColour">
                    <v-list-item-title>{{ g.displayName }}</v-list-item-title>
                  </v-badge>
                  <v-list-item-subtitle v-if="g.description">{{
                    g.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="searchingDbGroups">
                <v-list-item-icon
                  ><i class="fal fa-spinner fa-spin"></i
                ></v-list-item-icon>

                <v-list-item-subtitle><em>Checking database...</em></v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { clone, mergeRight } from "ramda";
export default {
  name: "NavBar",
  inject: ["templateDoc"],
  data() {
    return {
      hover: false,
      extendToolbar: false,
      searchTerm: null,
      searching: false,
      personSearchResults: null,
      searchingDbPeople: false,
      searchingDbGroups: false,
      groupSearchResults: null,
      notifications: [{ title: "Note" }],
      refreshingPermissions: false,
      loadingData: false,
    };
  },
  watch: {
    small: function () {
      if (!this.small) {
        this.extendToolbar = false;
      }
    },
    allReady: function (val) {
      this.loadingData = !val;
    },
  },
  methods: {
    refreshData() {
      //   this.$store.dispatch('saved/reset');
      this.$store.dispatch("saved/init");
    },
    resetSessionData() {
      this.loadingData = true;
      this.refreshingPermissions = true;
      this.$get("saved/sessiondata/delete").then(() => {
        this.refreshingPermissions = false;
        this.refreshData();
      });
    },
    clearSearch() {
      this.searchTerm = null;
      this.extendToolbar = false;
      this.searching = false;
      this.searchingDbPeople = false;
      this.searchingDbGroups = false;
      this.groupSearchResults = null;
      this.personSearchResults = null;
    },
    mergeGroups(results) {
      var t = this;
      if (t.groupSearchResults.length != 0) {
        results.forEach((element) => {
          const i = t.groupSearchResults.find((x) => x.id === element.id);
          if (i) {
            t.groupSearchResults = [
              ...t.groupSearchResults.map((item) => {
                if (item.id == i.id) {
                  item = mergeRight(item, element);
                }
                return item;
              }),
            ];
          } else {
            t.groupSearchResults.push(element);
          }
        });
      } else {
        t.groupSearchResults = clone(results);
      }
    },
    mergePeople(results) {
      var t = this;
      if (t.personSearchResults.length != 0) {
        results.forEach((element) => {
          const i = t.personSearchResults.find((x) => x.id === element.id);
          if (i) {
            t.personSearchResults = [
              ...t.personSearchResults.map((item) => {
                if (item.id == i.id) {
                  item = mergeRight(item, element);
                }
                return item;
              }),
            ];
          } else {
            t.personSearchResults.push(element);
          }
        });
      } else {
        t.personSearchResults = clone(results);
      }
    },
    search() {
      this.searching = true;
      var val = this.searchTerm.toLowerCase();
      var groups = this.$store.getters["saved/getAll"]("groups").filter((x) => {
        return x.displayText ? x.displayText.toLowerCase().includes(val) : false;
      });
      var people = this.$store.getters["saved/getAll"]("people").filter((x) => {
        return x.displayText ? x.displayText.toLowerCase().includes(val) : false;
      });
      this.groupSearchResults = [];
      groups.forEach((r) => {
        var gt = this.$store.getters["saved/get"](["groupTypes", r.groupType]);
        var result = {
          id: r.id,
          displayName: r.displayText,
          description: gt.name,
          colour: r.colour,
        };
        this.groupSearchResults.push(result);
      });
      this.personSearchResults = [];
      people.forEach((r) => {
        var result = {
          id: r.id,
          displayName: r.displayText,
        };
        this.personSearchResults.push(result);
      });
      if (this.personSearchResults.length || this.groupSearchResults.length) {
        this.searching = false;
      } else {
        /*
      if(val && val.length != 0){
         var data = {
        SearchTerm: val,
        Type: "group"
       };
              this.searchingDbGroups = true;

        this.$store.dispatch('entities/search', data).then(result=>{
            if(!result.error){
              this.mergeGroups(result);
              this.searching = false;
              this.searchingDbGroups = false;
            }
        });

         var pData = {
        SearchTerm: this.searchTerm,
        Type: "person"
         };
      this.searchingDbPeople = true;
         this.$store.dispatch('entities/search', pData).then(result=>{
            if(!result.error){
              this.mergePeople(result);
              this.searching = false;
              this.searchingDbPeople = false;
            }
        });

      }
      */
        this.searching = false;
      }
    },
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
    toggleMini() {
      this.$emit("toggleMini");
    },
    loginToggle() {
      if (this.$auth.isAuthenticated) {
        this.$logout();
      } else {
        this.$login;
      }
    },

    toggleExtension() {
      this.extendToolbar = !this.extendToolbar;

      if (!this.extendToolbar) {
        this.clearSearch();
      } else {
        var t = this;
        requestAnimationFrame(() => {
          t.$refs.search.$el.getElementsByTagName("input")[0].focus();
        });
      }
    },
  },
  computed: {
    allReady() {
      return (
        this.groupsReady &&
        this.peopleReady &&
        this.groupTypesReady &&
        this.frameworksReady &&
        this.rubricsReady &&
        this.selfReady
      );
    },
    groupsReady() {
      return this.$store.getters["saved/ready"]("groups").ready;
    },
    peopleReady() {
      return this.$store.getters["saved/ready"]("people").ready;
    },

    groupTypesReady() {
      return this.$store.getters["saved/ready"]("groupTypes").ready;
    },
    frameworksReady() {
      return this.$store.getters["saved/ready"]("frameworks").ready;
    },
    rubricsReady() {
      return this.$store.getters["saved/ready"]("rubrics").ready;
    },
    selfReady() {
      return this.$store.getters["saved/ready"]("self").ready;
    },
    template() {
      return this.templateDoc.app.navBar;
    },
    showSearch() {
      return this.searching || this.personSearchResults || this.groupSearchResults;
    },
    searchAppend() {
      if (this.searchTerm) {
        return "keyboard_return";
      }
      return null;
    },
    loggedIn() {
      return this.$auth.isAuthenticated;
    },

    small() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  props: {
    noTopPadder: {
      type: Boolean,
      default: false,
    },
    self: {
      type: Object,
    },
    hideDrawerToggle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mdAndUp: {
      type: Boolean,
      default: true,
    },
    iconUrl: {
      type: String,
      default: "../assets/images/srCore/icon.png",
    },
  },
};
</script>
