<template>
  <v-card
    :outlined="asTable && bordered"
    :flat="!asTable || !bordered"
    :tile="app_info.tile"
    :loading="(!groupReady || !groupTypeReady) && asTable"
  >
    <v-toolbar extension-height="400" v-if="!hideToolbar" flat>
      <div
        :class="{
          'grey--text': !app_info.colorfulHeadings,
          'secondary--text': app_info.colorfulHeadings,
        }"
        class="text-subtitle"
        :key="filterUpdates"
        v-html="getTitle"
      ></div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model.lazy="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        :disabled="!groupReady"
        v-if="!showExtension && !hideFilter && asTable"
      ></v-text-field>

      <v-btn
        v-if="allowFilter && !hideFilter && groupReady"
        icon
        @click="showExtension = !showExtension"
      >
        <v-icon v-show="!showExtension">mdi-chevron-down</v-icon>
        <v-icon v-show="showExtension">mdi-chevron-up</v-icon>
      </v-btn>

      <template v-slot:extension v-if="showExtension && allowFilter">
        <v-row>
          <v-col>
            <v-card fluid flat class="mb-3">
              <v-card-text>
                <p class="mb-3">Show only:</p>

                <v-row>
                  <v-col>
                    <v-select
                      label="All types..."
                      multiple
                      :items="groupTypeItems"
                      clearable
                      deletable-chips
                      dense
                      v-model="selectedGroupTypes"
                      @change="updateFilter"
                      :disabled="!groupTypeReady"
                      :hint="!groupTypeReady ? 'Loading options...' : ''"
                      :persistent-hint="!groupTypeReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model="typeSearch"
                              append-icon="mdi-magnify"
                              label="Search Types"
                              clearable
                              :disabled="!groupTypeReady"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      label="Inside any groups..."
                      :items="parents"
                      clearable
                      multiple
                      dense
                      item-value="id"
                      deletable-chips
                      v-model="selectedParents"
                      @change="updateFilter"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model="parentSearch"
                              append-icon="mdi-magnify"
                              label="Search Parents"
                              clearable
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      dense
                      v-model="showOnlyActive"
                      label="Active"
                      @mouseup="updateFilter"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <p class="mt-3">Show only if you are a(n):</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      dense
                      v-model="showOnlyDirect"
                      label="Direct Member"
                      @mouseup="updateFilter"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      dense
                      v-model="showOnlyAdmin"
                      label="Administrator"
                      @change="updateFilter"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model.lazy="search"
                      dense
                      label="Search Text"
                      single-line
                      clearable
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="resetFilters" color="error">Reset Filters</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>
    <v-divider v-if="asTable && !hideToolbar"></v-divider>
    <v-data-table
      ref="widget"
      v-if="asTable"
      :items="filteredGroups"
      :headers="headers"
      :items-per-page="itemsPerPage"
      :hide-default-header="hideHeaders"
      :search="search"
      :item-class="getColor"
      :single-select="!multiSelect"
      :show-select="selectable"
      @input="updateSelected"
      :expanded.sync="expanded"
      item-key="id"
      v-model="selectedGroups"
      :custom-filter="filter"
    >
      <template v-slot:header.data-table-select>
        <v-checkbox @change="toggleSelectAll" v-if="multiSelect"> Select All </v-checkbox>
      </template>
      <template v-slot:item.location="{ item }">
        <sr-group-path
          :excludeType="true"
          :self="{ id: item.id, name: item.displayName, groupType: item.groupType }"
          :path="item.path"
        ></sr-group-path>
      </template>

      <template v-slot:item.displayName="{ item }">
        <div class="d-flex align-center">
          <v-badge
            bordered
            top
            color="secondary"
            offset-x="10"
            :content="item.directAdmin ? 'A' : ''"
            :value="item.write"
            :dot="item.write && !item.directAdmin"
            offset-y="10"
          >
            <v-avatar :color="item.colour" size="30">
              <span class="white--text" v-html="item.displayName[0]"></span>
            </v-avatar>
          </v-badge>
          <v-card-subtitle class="ml-3" v-html="item.displayName"></v-card-subtitle>
        </div>
      </template>
      <template v-slot:item.tags="{ item }">
        <v-chip class="mr-2" v-for="(t, i) in item.tags" :key="i">{{ t }}</v-chip>
      </template>
      <template v-slot:item.groupType="{ item }">
        <sr-group-path
          :excludeType="false"
          :self="{ id: item.id, name: item.displayName, groupType: item.groupType }"
          :path="item.path"
        ></sr-group-path>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn v-if="!item.opaque" icon @click="goToGroup(item, false)">
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-item-group v-else>
      <v-container>
        <v-row align="stretch">
          <v-col v-for="(g, i) in visibleGroups" :key="i" cols="12" sm="6" md="4">
            <v-item v-slot="{ active /*toggle*/ }">
              <v-card style="height: 100%">
                <v-card-subtitle class="overline d-flex justify-space-between">
                  <span
                    >Role: <b>{{ g.status }}</b></span
                  >
                  <v-menu offset-y left v-if="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn text icon v-bind="attrs" v-on="on">
                        <i class="fal fa-ellipsis-h"></i>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="bookmark(g)">
                        <v-list-item-title>Bookmark</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="g.write" @click="deactivate(g)">
                        <v-list-item-title>Deactivate</v-list-item-title>
                      </v-list-item>
                      <v-divider v-if="canDelete(g.path)"></v-divider>
                      <v-list-item
                        v-if="canDelete(g.path)"
                        @click="detachFromParentGroup(g)"
                      >
                        <v-list-item-title>Detach from Parent Group</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="canDelete(g.path)" @click="remove(g)">
                        <v-list-item-title class="error--text"
                          >Permanently Delete</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-subtitle>
                <v-card flat class="ma-3"> </v-card>
                <v-list-item three-line>
                  <v-list-item-avatar>
                    <v-avatar :color="g.colour">
                      <span class="white--text">{{ g.displayName[0] }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ g.displayName }}</v-list-item-title>
                    <v-list-item-subtitle> {{ g.groupType }}</v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <strong>
                        <sr-group-path
                          :excludeType="true"
                          :self="{
                            id: g.id,
                            name: g.displayName,
                            groupType: g.groupType,
                          }"
                          :path="g.path"
                        ></sr-group-path>
                      </strong>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text icon @click="goToGroup(g, false)">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider v-if="active"></v-divider>
                <v-card-actions v-if="active">
                  <span>actions</span>
                </v-card-actions>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-center">
        <v-pagination
          v-if="Math.ceil(filteredGroups.length / perPage) > 1"
          :circle="!app_info.tile"
          color="secondary"
          v-model="page"
          :length="Math.ceil(filteredGroups.length / perPage)"
        ></v-pagination>
      </div>
    </v-item-group>
  </v-card>
</template>

<script>
export default {
  name: "GroupWidget",
  data() {
    return {
      search: "",
      page: 1,
      expanded: [],
      showExtension: false,
      selectedGroupTypes: [],
      selectedParents: [],
      showOnlyDirect: false,
      showOnlyAdmin: false,
      showOnlyNonAdmin: false,
      showOnlyActive: true,
      filterUpdates: 0,
      parentSearch: null,
      typeSearch: null,
      selectAll: false,
      selectedGroups: [],
    };
  },
  methods: {
    filter(val, search, item) {
      debugger;
      var searchString = `${item.displayName} ${item.groupType} ${item.status} ${
        item.internalId
      } ${this.paths(item.path)}`;
      searchString = searchString.toLowerCase();
      var searchWordArray = search.toLowerCase().split(" ");
      if (searchWordArray.length > 1) {
        var searchStringArray = searchString.split(" ").filter((x) => x.length);
        searchStringArray = searchStringArray.concat(item.tags);

        var matchAny = searchWordArray.filter((value) =>
          searchStringArray.includes(value)
        );
        var distinctMatches = [...new Set(matchAny)];
        var matchAll = distinctMatches.length == searchWordArray.length;
        if (!matchAll) {
          var lastWord = searchWordArray[searchWordArray.length - 1];
          var unmatched = searchWordArray.filter((x) => !distinctMatches.includes(x));
          if (unmatched.length == 1 && unmatched[0] == lastWord) {
            return searchString.includes(lastWord);
          }
        } else {
          return true;
        }
        // var matchAll = searchWordArray.all(searchStringArray);
      } else {
        return searchString.includes(searchWordArray[0]);
      }
      return false;
    },
    paths(path) {
      var t = this;
      return path
        .map((x) => {
          return x.groups
            ? x.groups.map((g) => {
                var group = t.$store.getters["saved/get"](["groups", g]);
                return group != null ? group.displayText : null;
              })
            : null;
        })
        .filter((y) => y)
        .join(" ");
    },
    toggleSelectAll(e) {
      console.log(e);
      if (e) {
        this.selectedGroups = this.filteredGroups;
      } else {
        this.selectedGroups = [];
      }
      // this.$emit('input', this.selectedGroups)
    },
    updateSelected(e) {
      console.log(e);
      this.$emit(
        "input",
        e.map((x) => x.id)
      );
    },
    bookmark(group) {
      alert("Coming Soon");
      console.log(group);
    },
    deactivate(group) {
      alert("Coming Soon");
      console.log(group);
    },
    detachFromParentGroup(group) {
      alert("Coming Soon");
      console.log(group);
    },
    remove(group) {
      var ok = alert(
        "Deleting this group will delete all data associated with it, including assessments. It will also delete any child groups."
      );
      console.log(group);
      console.log(ok);
    },
    getColor(i) {
      debugger;
      return i.opaque ? "light" : "";
    },
    resetFilters() {
      this.showOnlyDirect = this.filterValues.showOnlyDirect;
      this.showOnlyAdmin = this.filterValues.showOnlyAdmin;
      this.selectedParents = null;
      this.selectedParents = this.filterValues.selectedParents;
      this.selectedGroupTypes = null;
      this.selectedGroupTypes = this.filterValues.selectedGroupTypes;
      this.showOnlyNonAdmin = this.filterValues.showOnlyNonAdmin;
      this.search = "";
    },
    updateFilter() {
      this.filterUpdates++;
    },
    canDelete(path) {
      var admin = this.$auth.isLocalAdmin || this.$auth.isAdmin;
      if (admin) {
        var match =
          this.selectedParents &&
          this.selectedParents.length == 1 &&
          this.permissions &&
          this.permissions.subgroupList.write;
        if (match) {
          return true;
        }
        var p = path != null ? path.find((x) => x.level == 1) : null;
        var id = p && p.groups && p.groups.length ? p.groups[0] : null;
        var g = id ? this.$store.getters["saved/get"](["groups", id]) : false;
        return g ? g.readonly == false : false;
      }
    },
  },
  computed: {
    groupReady() {
      return this.$store.getters["saved/status"]("groups") == "complete";
    },
    groupTypeReady() {
      return this.$store.getters["saved/status"]("groupTypes") == "complete";
    },
    route() {
      return this.$route.path;
    },

    itemsPerPage() {
      // if(this.filteredGroups && this.filteredGroups.length != this.groups.length){
      //     return this.filteredGroups.length + 1;
      // }
      return this.perPage;
    },
    getTitle() {
      if (!this.title) {
        var hasSelectedGroupTypes = this.selectedGroupTypes.length != 0;

        var hasSelectedParents = this.selectedParents.length != 0;
        if (!hasSelectedGroupTypes && !hasSelectedParents) {
          return "<span>Groups</span>";
        }
        var result = this.title;
        if (hasSelectedGroupTypes) {
          result =
            "<span>" +
            this.selectedGroupTypes
              .map((x) => {
                var t = this.groupTypeItems.find((t) => t.value == x);
                return t ? this.pluralize(t.text) : "";
              })
              .join(", ");
        }
        if (hasSelectedParents) {
          var p = this.selectedParents
            .map((x) => {
              var t = this.parents.find((t) => t.id == x);
              return t ? t.text : "";
            })
            .join(", ");
          result = `${result} in ${p}`;
        }

        return result + "</span>";
      }
      return this.title;
    },
    groupTypeItems() {
      return this.groups
        .map((g) => {
          return {
            text: g.groupType,
            value: g.gtId,
          };
        })
        .filter((x) => {
          if (this.typeSearch && this.typeSearch.length != 0) {
            return x.text.toLowerCase().includes(this.typeSearch.toLowerCase());
          } else {
            return x;
          }
        });
    },
    visibleGroups() {
      return this.$r
        .clone(this.filteredGroups)
        .slice((this.page - 1) * this.perPage, this.page * this.perPage);
    },
    filteredGroups() {
      debugger;
      //var t = this;
      //var memberships = t.$store.getters['saved/memberships'](t.$auth.userId);

      return this.$r.clone(this.groups).filter((x) => {
        //  var searchValid = true;
        var typeValid = true;
        var parentValid = true;
        var memberValid = true;
        var adminValid = true;
        var activeValid = true;
        //var memberOnlyValid = true;

        if (this.selectedGroupTypes.length != 0) {
          typeValid = this.selectedGroupTypes.includes(x.gtId);
        }
        if (this.selectedParents.length != 0) {
          var pathArr = x.path.map((x) => x.groups.flat()).flat();
          parentValid =
            this.selectedParents.filter((value) => pathArr.includes(value)).length != 0;
        }
        if (this.showOnlyAdmin) {
          adminValid = x.write;
        }
        if (this.showOnlyActive) {
          activeValid = x.active;
        }

        if (this.showOnlyNonAdmin) {
          adminValid = !x.write;
        }
        if (this.showOnlyDirect) {
          adminValid = this.showOnlyAdmin
            ? x.directAdmin
            : x.directMember || x.directAdmin;
        }
        /*  if(this.showOnlyNonAdmin){
                        memberOnlyValid = !x.write;
                    }   
                    */
        return typeValid && parentValid && memberValid && adminValid && activeValid; //&& memberOnlyValid;
      });
    },
    parents() {
      return this.$store.getters["saved/groupParents"](
        this.groups.map((f) => f.id)
      ).filter((x) => {
        if (this.parentSearch && this.parentSearch.length != 0) {
          return x.text.toLowerCase().includes(this.parentSearch.toLowerCase());
        } else {
          return x;
        }
      });
    },
    groups() {
      var t = this;
      return t.$r
        .clone(t.$store.getters["saved/getAll"]("groups"))
        .filter((x) => {
          debugger;
          if (!t.showAll) {
            return t.admin ? x.directAdmin : x.directMember;
          } else {
            if (!t.showOpaque) {
              return !x.opaque;
            }
            return x;
          }
        })
        .map((x) => {
          var gt = t.$store.getters["saved/get"](["groupTypes", x.groupType]);
          if (gt) {
            var status = x.readonly ? gt.memberName : gt.adminName;
            if (x.directMember) {
              status = x.directAdmin ? gt.adminName : gt.memberName;
            }
          }

          return {
            colour: x.colour,
            displayName: x.displayText,
            groupType: gt ? gt.name : "",
            gtId: x.groupType,
            write: !x.readonly,
            opaque: x.opaque,
            directMember: x.directMember,
            status: status != null ? status : "",
            relevance: x.relevance,
            directAdmin: x.directAdmin,
            active: x.active,
            path: x.path,
            tags: x.tags,
            id: x.id,
            internalId: x.internalId,
          };
        })
        .sort((a, b) => {
          if (a.relevance != b.relevance) {
            return a.relevance - b.relevance;
          } else {
            var gtnA = a.groupType.toUpperCase(); // ignore upper and lowercase
            var gtnB = b.groupType.toUpperCase(); // ignore upper and lowercase
            if (gtnA < gtnB) {
              return -1;
            }
            if (gtnA > gtnB) {
              return 1;
            } else {
              var nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
            }
          }
          // names must be equal
          return 0;
        });
    },
  },
  created() {
    if (this.dispatch) {
      // this.$store.dispatch("saved/update_groups");
      this.$store.dispatch("saved/get_groups");
    }
    this.resetFilters();
  },
  props: {
    dispatch: {
      type: Boolean,
      default: true,
    },
    asTable: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    stack: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Groups",
    },
    showOpaque: {
      type: Boolean,
      default: false,
    },
    showActive: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Name",
            align: "start",
            sortable: true,
            value: "displayName",
          },
          {
            text: "Type",
            align: "center",
            value: "groupType",
          },
          {
            text: "",
            align: "center",
            value: "action",
          },
        ];
      },
    },
    hideHeaders: {
      type: Boolean,
      default: true,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    permissions: {
      type: Object,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    filterValues: {
      type: Object,
      default: () => {
        return {
          selectedGroupTypes: [],
          selectedParents: [],
          showOnlyDirect: false,
          showOnlyAdmin: false,
          showOnlyNonAdmin: false,
        };
      },
    },
  },
};
</script>
