<template>
  <v-card v-if="baseline">
    <v-toolbar dense flat>
      <v-toolbar-title>Baseline Configuration</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        class="hidden"
        v-if="isAdmin"
        text
        :to="{ name: 'GroupType' }"
      >
        Edit
      </v-btn>
    </v-toolbar>
    <v-card-text>
      Get in touch with <a href="MAILTO:support@smartrubric.com">Support</a> to configure
      a baseline survey for {{ groupType.name | pluralize }}.
    </v-card-text>
    <v-card-text><strong>Title: </strong>{{ baseline.title }}</v-card-text>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Description</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-html="baseline.description"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Inputs</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item two-line v-for="(input, i) in baseline.inputs" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{ input.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ input.inputType }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
  <not-found :flat="true" :outlined="false" :icon="'fad fa-poll-h'" v-else>
    <template v-slot:title>No Baseline Configuration Found</template>
    <template v-slot:body
      >Get in touch with <a href="MAILTO:support@smartrubric.com">Support</a> to configure
      a baseline survey for {{ groupType.name | pluralize }}</template
    >
  </not-found>
</template>

<script>
import NotFound from "../../newComponents/NotFound.vue";

export default {
  name: "ViewBaseline",
  components: { NotFound },
  data() {
    return {};
  },
  computed: {
    isAdmin() {
      return this.$auth.isAdmin;
    },
    groupType() {
      return this.gt
        ? this.gt
        : this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupTypeId])
        : null;
    },
    baseline() {
      return this.groupType && this.groupType.baseline ? this.groupType.baseline : null;
    },
  },
  props: {
    group: {
      type: Object,
    },
    gt: {
      type: Object,
    },
  },
};
</script>
