var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.saving}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Edit "+_vm._s(_vm.groupType.name)+" Metadata")])],1),_c('v-card-text',[(_vm.editableGroup)?_c('v-form',{key:_vm.resetCount,model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":((_vm.groupType.name) + " Name"),"rules":[
              function (v) { return !!v || 'Name is required'; },
              function (v) { return v.length <= 50 || 'Name must be less than 50 characters'; } ]},on:{"input":_vm.change},model:{value:(_vm.editableGroup.metadata.name),callback:function ($$v) {_vm.$set(_vm.editableGroup.metadata, "name", $$v)},expression:"editableGroup.metadata.name"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Internal Unique Id"},on:{"input":_vm.change},model:{value:(_vm.editableGroup.internalId),callback:function ($$v) {_vm.$set(_vm.editableGroup, "internalId", $$v)},expression:"editableGroup.internalId"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","multiple":"","append-icon":""},on:{"input":_vm.change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var attrs = ref.attrs;
            var item = ref.item;
            var select = ref.select;
            var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","dark":"","color":_vm.editableGroup.metadata.primaryColor},on:{"click":select,"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,3159929010),model:{value:(_vm.editableGroup.tags),callback:function ($$v) {_vm.$set(_vm.editableGroup, "tags", $$v)},expression:"editableGroup.tags"}}),_c('v-switch',{attrs:{"color":_vm.editableGroup.metadata.primaryColor,"label":"Active?"},on:{"change":_vm.change},model:{value:(_vm.editableGroup.active),callback:function ($$v) {_vm.$set(_vm.editableGroup, "active", $$v)},expression:"editableGroup.active"}})],1),_c('v-col',{attrs:{"cols":"6","md":"4","lg":"3"}},[_c('v-color-picker',{attrs:{"dot-size":"25","hide-inputs":"","swatches-max-height":"200","swatches":_vm.swatches,"show-swatches":"","hide-canvas":"","hide-sliders":""},on:{"input":_vm.change},model:{value:(_vm.editableGroup.metadata.primaryColor),callback:function ($$v) {_vm.$set(_vm.editableGroup.metadata, "primaryColor", $$v)},expression:"editableGroup.metadata.primaryColor"}})],1)],1),_c('v-row')],1):_vm._e()],1),_c('v-card-actions',[(_vm.$auth.isAdmin)?_c('v-btn',{attrs:{"disabled":_vm.saving != false,"color":"error"},on:{"click":_vm.deleteGroup}},[_vm._v("delete")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0,"color":"warning"},on:{"click":_vm.reset}},[_vm._v("reset")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || !_vm.valid,"color":"success"},on:{"click":_vm.update}},[_vm._v("Save Changes")])],1),(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }