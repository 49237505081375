import Vue from "vue";
import Vuex from "vuex";
//import Entities from "./modules/entities.js";
//import People from "./modules/people.js";
//import Frameworks from "./modules/frameworks.js";
//import Assessments from "./modules/assessments.js";
//import Rubrics from "./modules/rubrics.js";
import Persistent from './modules/persistent.js'
//import Management from './modules/management';
//import createPersistedState from "vuex-persistedstate";
import VuexPersistence from 'vuex-persist'

import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });
const vuexLocal = new VuexPersistence({
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
  reducer: (state) => ({ saved: state.saved}), //only save saved module
})


Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
   // entities: Entities,
   // people: People,
   // frameworks: Frameworks,
   // rubrics: Rubrics,
   // assessments: Assessments,
   // management: Management,
    saved: Persistent
  },
  plugins: [ vuexLocal.plugin],
});
