<template>
  <v-card flat :id="`container${id}`">
    <div :class="{ 'd-flex justify-space-between align-center': flex }">
      <div :style="{ minWidth: flex ? '250px' : '' }">
        <v-card flat>
          <div class="mx-4 d-flex justify-space-between">
            <span>
              <span v-if="org_id == '5f577333a63ecf6e443a1d37'">{{ index }}.</span>
              {{ title }}
            </span>

            <v-btn
              class="d-none"
              small
              v-if="!ratingScale && description"
              icon
              text
              @click="expand = !expand"
            >
              <v-icon v-show="!expand">mdi-plus</v-icon>
              <v-icon v-show="expand">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-slide-y-transition>
            <div class="mx-4 text-body-2" v-html="description"></div>
          </v-slide-y-transition>
        </v-card>
      </div>

      <div
        v-if="ratingScale || checklist"
        class="my-5 mx-4"
        :class="{ 'd-flex shrink justify-end': $vuetify.breakpoint.smAndUp }"
      >
        <v-btn-toggle color="primary" :value="value" @change="update" v-if="editMode">
          <v-btn
            :class="{
              'rounded-circle mx-2 checklist ': checklist,
              hasValue: checklist && b.value != 0 && b.id == value,
              noValue:
                (checklist && b.value == 0 && b.id == value) ||
                (checklist && bands.length == 1 && !value),
            }"
            v-for="b in bands"
            :key="b.id"
            :disabled="disabled"
            :value="b.id"
          >
            <span v-if="checklist">
              <v-icon v-show="b.value != 0 || (bands.length == 1 && value)"
                >mdi-check</v-icon
              >
              <v-icon v-show="b.value == 0 || (bands.length == 1 && !value)"
                >mdi-close</v-icon
              >
            </span>
            <span v-else-if="b.manual">
              <span small :class="app_info.noMarksClass">X</span>
            </span>
            <span v-else-if="letterForRatingScale">
              {{ b.key[0] }}
            </span>
            <span v-else>{{ getText(b) }}</span>
          </v-btn>
        </v-btn-toggle>

        <v-avatar v-else>
          <span v-if="checklist && bandVal">
            <v-icon class="error--text" v-show="bandVal.value == 0">mdi-close</v-icon>
            <v-icon class="success--text" v-show="bandVal.value > 0">mdi-check</v-icon>
          </span>
          <span v-else-if="letterForRatingScale && bandVal">
            {{ bandVal.key[0] }}
          </span>
          <span v-else-if="bandVal">{{ bandVal.value }}</span>
          <span v-else-if="!bandVal">N/A</span>
        </v-avatar>
      </div>

      <div v-else>
        <v-item-group
          v-if="editMode"
          :disabled="disabled"
          :value="value"
          @change="update"
        >
          <div
            :class="{ 'd-flex flex-xs-column flex-content-stretch': cardWidth >= 200 }"
          >
            <v-item
              v-slot="{ active, toggle }"
              :value="b.id"
              :disabled="disabled"
              v-for="b in bands"
              :key="b.id"
              :style="{ minWidth: cardWidth, maxWidth: cardWidth, width: cardWidth }"
            >
              <v-card
                v-if="!disabled"
                :style="`--card-width: ${
                  cardWidth >= 200 ? cardWidth - 20 : width - 10
                }px`"
                :color="active ? 'secondary' : ''"
                class="align-center matrix-card"
                :class="{
                  'd-flex flex-xs-column': cardWidth < 200,
                }"
                :outlined="!editMode"
                :dark="active ? true : false"
                @click="toggle"
              >
                <v-card-subtitle class="overline grow"
                  ><small>{{ b.key }}</small></v-card-subtitle
                >
                <v-card-text v-html="b.levelText"></v-card-text>
              </v-card>
              <v-card
                v-else
                :style="`--card-width: ${
                  cardWidth >= 200 ? cardWidth - 20 : width - 10
                }px`"
                :color="active ? 'secondary' : ''"
                class="align-center matrix-card"
                :class="{
                  'd-flex flex-xs-column': cardWidth < 200,
                }"
                :outlined="!editMode"
                :dark="active ? true : false"
              >
                <v-card-subtitle class="overline grow"
                  ><small>{{ b.key }}</small></v-card-subtitle
                >
                <v-card-text v-html="b.levelText"></v-card-text>
              </v-card>
            </v-item>
          </div>
        </v-item-group>
        <div v-else>
          <v-card-subtitle v-if="bandVal" class="overline"
            ><small>{{ bandVal.key }}</small></v-card-subtitle
          >
          <v-card-text v-if="bandVal" v-html="bandVal.levelText"> </v-card-text>
          <v-card-text v-if="target">
            Target: <span v-html="target.levelText"></span>
          </v-card-text>
        </div>
      </div>
    </div>
    <div v-if="enableComment">
      <edit-comment
        :multiline="true"
        :items="comments"
        :label="commentLabel"
        :rich="false"
        valueField="text"
        :showHeaders="false"
        type="comment"
        @change="updateComments"
        :tags="commentTags"
        :allowCreateTags="allowCreateTags"
        :reportView="!editMode"
        :readonly="disabled"
        :showAuthors="true"
        :preserveAuthorship="true"
        :replies="true"
        :focused="focus"
        v-on:unfocus="changeFocus"
      >
      </edit-comment>
    </div>
  </v-card>
</template>

<script>
//import Comment from "./Comment.vue";
import EditComment from "./EditComment.vue";
export default {
  name: "StrandMark",
  components: { EditComment },
  data() {
    return {
      expand: false,
      ready: false,
      width: null,
      bands: [],
      focus: 0,
    };
  },
  created() {
    if (this.mode == "scale") {
      this.expand = true;
    }
    // window.addEventListener("resize", this.getWidth);
    // update March 1
    this.setBands();
  },
  mounted() {
    this.ready = true;
    this.getWidth();
  },
  methods: {
    changeFocus(e) {
      console.log("strandMark change focus");
      console.log(e);
    },
    noMarksNeeded() {
      return this.options
        ? this.options.find((o) => o.value == 0 && !o.hidden) == null
        : true;
    },
    setBands() {
      var bands = [];
      bands = this.$r.clone(this.options);
      if (this.noMarksNeeded()) {
        var noMarks = {
          floor: 0,
          hidden: false,
          id: "NoMarks",
          key: this.app_info.noMarksText,
          levelText: "No Marks",
          selected: false,
          value: 0,
          manual: true,
          icon: this.app_info.noMarksIcon,
        };
        bands.push(noMarks);
      }

      bands = bands.filter((x) => !x.hidden);
      Object.assign(
        this.bands,
        bands.sort((a, b) => a.value - b.value)
      );
    },
    updateComments(e) {
      // console.log(e)
      // this.$emit('input', e);
      this.$emit("updateComment", e, this.id);
    },
    update(e) {
      console.log("emitting input");
      this.$emit("input", e);
      var band = this.bands.find((x) => x.id == e);
      this.$emit("mark", band, this.id);
    },
    getWidth() {
      this.width = document.getElementById(`container${this.id}`)
        ? document.getElementById(`container${this.id}`).offsetWidth
        : null;
    },
    getText(band) {
      debugger;
      var parse = parseInt(band.key);
      if (!isNaN(parse)) {
        return parse;
      } else {
        var index = this.bands.indexOf(band);
        return index;
        //return band.value;
      }
    },
  },
  computed: {
    filteredOptions() {
      return this.$r
        .clone(this.options)
        .filter((x) => !x.hidden)
        .sort((a, b) => {
          return a.floor - b.floor;
        });
    },
    cardWidth() {
      var px =
        this.bands.length && this.width > 0
          ? Math.floor(
              (this.width - this.bands.length - (this.flex ? 250 : 0)) / this.bands.length
            )
          : null;
      // if(px < 150){
      return px;
      // }
      //  return null;
    },
    ratingScale() {
      return this.mode == "ratingScale";
    },
    checklist() {
      return this.mode == "checklist" || this.mode == "checkList";
    },

    flex() {
      var scale = this.ratingScale;
      var len = this.bands.length;
      var result = false;
      // var overrideFlex = this.mode == 'matrix' && !this.cardWidth;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          result = this.checklist;
          break;
        case "sm":
          result = (scale && len < 5) || this.checklist;
          break;
        case "md":
          result = (scale && len < 7) || this.checklist || (!scale && len < 3);
          break;
        case "lg":
          result = (scale && len < 20) || this.checklist || !scale;
          break;
        default:
          result = true;
          break;
      }
      return result;
    },
    bandVal() {
      return this.value ? this.bands.find((x) => x.id == this.value) : null;
    },
    target() {
      if (this.value && this.bandVal) {
        var index = this.bands.indexOf(this.bandVal);
        return this.bands[index + 1] ? this.bands[index + 1] : null;
      }
      return null;
    },
  },
  props: {
    value: {
      type: String,
    },
    index: {},
    options: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
    },
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    mode: {
      type: String,
    },
    letterForRatingScale: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    objectiveId: {
      type: String,
    },
    comments: {
      type: Array,
      default: () => [],
    },
    enableComment: {
      type: Boolean,
    },
    commentLabel: {
      type: String,
    },
    commentTags: {
      type: Array,
      default: () => [],
    },
    allowCreateTags: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    reviewedDate: {
      type: String,
    },
  },
};
</script>
