<template>
  <v-row>
    <v-col>
      <v-breadcrumbs :items="items"></v-breadcrumbs>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    items: {
      type: Array,
      default: () => {
        return [{ text: "Home", to: { name: "Home" } }];
      },
    },
  },
};
</script>
