<template>
  <v-card flat>
    <v-card
      class="my-5"
      :outlined="showQuery"
      :disabled="loading || showConfig"
      v-if="!readonly"
    >
      <v-toolbar
        dense
        flat
        :dark="!showQuery"
        :color="!showQuery ? 'primary lighten-1' : 'transparent'"
      >
        <v-toolbar-title :class="{ 'grey--text': showQuery }"
          >Configure Query</v-toolbar-title
        >
        <v-spacer></v-spacer>

        <v-btn small icon @click="showQuery = !showQuery">
          <v-icon v-show="showQuery">mdi-chevron-up</v-icon>
          <v-icon v-show="!showQuery">mdi-chevron-down</v-icon>
        </v-btn>
        <div class="hidden">
          <v-btn icon v-if="!showSave" color="primary">
            <v-icon>fal fa-share-alt</v-icon>
          </v-btn>
          <v-btn v-show="!showSave" icon @click="showSave = true" color="secondary">
            <v-icon>fal fa-save</v-icon>
          </v-btn>
          <v-btn v-show="showSave" icon @click="cancelSave" color="error">
            <v-icon>fal fa-times</v-icon>
          </v-btn>
          <v-menu bottom left v-if="savedQueries.length != 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-subheader>Load Saved Query</v-subheader>
              <v-list-item v-for="(item, i) in savedQueries" :key="i">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <template v-slot:extension v-if="showSave">
          <v-row>
            <v-col>
              <v-text-field
                class="ml-3"
                dense
                v-model="queryName"
                label="Query Name"
                clearable
                :disabled="loading"
              >
              </v-text-field>
            </v-col>
            <v-col class="shrink">
              <v-btn
                text
                :disabled="!queryName || queryName.length == 0"
                color="success"
                @click="saveQuery"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-toolbar>
      <v-divider v-if="showQuery"></v-divider>
      <v-card-text v-if="showQuery">
        <small>
          {{ $getTerm("Assessment", true) }}
          <strong v-if="localQuery.getByLastUpdated">last updated </strong
          ><strong v-else>created </strong> between
          {{ localQuery.start | moment("DD MMMM YYYY") }} and
          {{ localQuery.end | moment("DD MMM YYYY") }}
          will be retrieved</small
        >
        <v-row>
          <v-col>
            <v-text-field
              :disabled="loading"
              label="Start"
              v-model="localQuery.start"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :disabled="loading"
              label="End"
              v-model="localQuery.end"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
              v-model="localQuery.getByLastUpdated"
              label="Get by Last Updated Date"
            >
            </v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="showAdvanced">
          <v-col v-if="!personId">
            <v-select
              label="Include Individuals..."
              :items="individuals"
              clearable
              multiple
              :disabled="loading"
              dense
              chips
              item-text="text"
              :menu-props="{ bottom: true, offsetY: true }"
              item-value="value"
              deletable-chips
              v-model="localQuery.individuals"
              bottom
            >
              <template v-slot:prepend-item>
                <v-card flat color="light" class="mx-4 pb-3 light px-5">
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        dense
                        v-model="personSearch"
                        append-icon="mdi-magnify"
                        label="Search People"
                        clearable
                        single-line
                        :disabled="loading"
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="!frameworkId">
            <v-select
              label="Include Frameworks..."
              :items="frameworks"
              clearable
              multiple
              :disabled="loading"
              dense
              chips
              item-text="text"
              :menu-props="{ bottom: true, offsetY: true }"
              item-value="value"
              deletable-chips
              v-model="localQuery.frameworks"
              bottom
            >
              <template v-slot:prepend-item>
                <v-card flat color="light" class="mx-4 pb-3 light px-5">
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        dense
                        v-model="frameworkSearch"
                        append-icon="mdi-magnify"
                        label="Search Frameworks"
                        clearable
                        single-line
                        :disabled="loading"
                        hide-details
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="8" v-if="!groupId">
            <v-select
              label="Include Groups..."
              :items="groups"
              clearable
              multiple
              dense
              :disabled="loading"
              chips
              item-text="text"
              :menu-props="{ bottom: true, offsetY: true }"
              item-value="value"
              deletable-chips
              v-model="localQuery.groups"
              bottom
            >
              <template v-slot:item="{ active, item, on, attrs }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  #default="{ active }"
                  v-show="item && item.visible"
                >
                  <v-list-item-action>
                    <v-checkbox :input-value="active"> </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ item.text }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:prepend-item>
                <div>
                  <v-card flat color="light" class="mx-4 light px-5">
                    <v-list-item>
                      <v-list-item-content>
                        <v-text-field
                          dense
                          :disabled="loading"
                          v-model="groupSearch"
                          append-icon="mdi-magnify"
                          label="Search Groups"
                          clearable
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="justify-space-between">
                        <v-checkbox
                          dense
                          v-model="selectedGroupTypes"
                          v-for="gt in groupTypeItems"
                          :key="gt.value"
                          :disabled="loading"
                          :label="gt.text"
                          @change="toggleAnyGroupType"
                          :value="gt.value"
                        >
                        </v-checkbox>
                        <v-checkbox
                          dense
                          v-model="anyGroupType"
                          label="Any"
                          @change="toggleGroupTypes"
                        >
                        </v-checkbox>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                  <v-list-item>
                    <v-list-item-action>
                      <v-checkbox
                        dense
                        :disabled="loading"
                        :indeterminate="selectIndeterminate"
                        v-model="selectAll"
                        label="Any"
                      >
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="loading"
              label="Include Subgroup data?"
              v-model="localQuery.getNode"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="showAdvanced">
          <v-col>
            <v-combobox
              dense
              v-model="localQuery.assessmentTags"
              :label="`Search ${$getTerm('Assessment')} Tags`"
              clearable
              multiple
              chips
              deletable-chips
              :items="[]"
              :disabled="loading"
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="loading"
              label="Match Any"
              v-model="localQuery.assessmentTagMatchAny"
              persistent-hint
              :hint="
                localQuery.assessmentTagMatchAny
                  ? `Records that match at least one of these tags will be retrieved`
                  : `Only records that match all of these tags will be retrieved`
              "
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="showAdvanced">
          <v-col>
            <v-combobox
              dense
              v-model="localQuery.objectiveCommentTags"
              :label="`Search Objective ${$getTerm('Comment')} Tags`"
              clearable
              multiple
              chips
              deletable-chips
              :items="app_info.globalCommentTags"
              :disabled="loading"
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="loading"
              label="Match Any"
              v-model="localQuery.objectiveCommentTagMatchAny"
              persistent-hint
              :hint="
                localQuery.objectiveCommentTagMatchAny
                  ? `Records that match at least one of these tags will be retrieved`
                  : `Only records that match all of these tags will be retrieved`
              "
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="showAdvanced">
          <v-col>
            <v-combobox
              dense
              v-model="localQuery.unitCommentTags"
              :label="`Search ${$getTerm('Unit')} ${$getTerm('Comment')}Tags`"
              clearable
              multiple
              chips
              deletable-chips
              :items="app_info.globalCommentTags"
              :disabled="loading"
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="loading"
              label="Match Any"
              v-model="localQuery.unitCommentTagMatchAny"
              persistent-hint
              :hint="
                localQuery.unitCommentTagMatchAny
                  ? `Records that match at least one of these tags will be retrieved`
                  : `Only records that match all of these tags will be retrieved`
              "
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="showAdvanced">
          <v-col>
            <v-combobox
              dense
              v-model="localQuery.rubricTags"
              :label="`Search ${$getTerm('Rubric')} Tags`"
              clearable
              multiple
              chips
              deletable-chips
              :items="[]"
              :disabled="loading"
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="loading"
              label="Match Any"
              v-model="localQuery.rubricTagMatchAny"
              persistent-hint
              :hint="
                localQuery.rubricTagMatchAny
                  ? `Records that match at least one of these tags will be retrieved`
                  : `Only records that match all of these tags will be retrieved`
              "
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="showAdvanced">
          <v-col>
            <v-combobox
              dense
              v-model="localQuery.rubricUnitTags"
              :label="`Search ${$getTerm('Rubric')} ${$getTerm('Unit')} Tags`"
              clearable
              multiple
              chips
              deletable-chips
              :items="[]"
              :disabled="loading"
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="loading"
              label="Match Any"
              v-model="localQuery.rubricUnitTagMatchAny"
              persistent-hint
              :hint="
                localQuery.rubricUnitTagMatchAny
                  ? `Records that match at least one of these tags will be retrieved`
                  : `Only records that match all of these tags will be retrieved`
              "
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="showAdvanced">
          <v-col>
            <v-combobox
              dense
              v-model="localQuery.rubricObjectiveTags"
              :label="`Search ${$getTerm('Rubric')} ${$getTerm('Objective')} Tags`"
              clearable
              multiple
              chips
              deletable-chips
              :items="[]"
              :disabled="loading"
            >
            </v-combobox>
          </v-col>
          <v-col>
            <v-checkbox
              :disabled="loading"
              label="Match Any"
              v-model="localQuery.rubricObjectiveTagMatchAny"
              persistent-hint
              :hint="
                localQuery.rubricObjectiveTagMatchAny
                  ? `Records that match at least one of these tags will be retrieved`
                  : `Only records that match all of these tags will be retrieved`
              "
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row v-if="showAdvanced">
          <v-col>
            <v-checkbox
              v-model="showLocked.checked"
              @change="updateIndeterminate(showLocked)"
              :indeterminate="showLocked.indeterminate"
              :label="showLocked.name"
              persistent-hint
              :hint="
                showLocked.indeterminate
                  ? `Both locked and unlocked ${getTerm(
                      'assessment',
                      true
                    )} will be retrieved`
                  : `Only ${!showLocked.checked ? 'un' : ''}locked ${getTerm(
                      'assessment',
                      true
                    )} will be retrieved`
              "
            >
              <template v-slot:append>
                <v-btn
                  v-if="!showLocked.indeterminate"
                  small
                  text
                  color="primary"
                  @click="showLocked.indeterminate = !showLocked.indeterminate"
                  >Show All</v-btn
                >
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="showAdvanced">
          <v-col>
            <v-checkbox
              v-model="showArchived.checked"
              @change="updateIndeterminate(showArchived)"
              :indeterminate="showArchived.indeterminate"
              :label="showArchived.name"
              persistent-hint
              :hint="
                showArchived.indeterminate
                  ? `Both archived and unarchived ${getTerm(
                      'assessment',
                      true
                    )} will be retrieved`
                  : `Only ${!showArchived.checked ? 'un' : ''}archived ${getTerm(
                      'assessment',
                      true
                    )} will be retrieved`
              "
            >
              <template v-slot:append>
                <v-btn
                  v-if="!showArchived.indeterminate"
                  small
                  text
                  color="primary"
                  @click="showArchived.indeterminate = !showArchived.indeterminate"
                  >Show All</v-btn
                >
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="showQuery">
        <v-btn color="primary" small outlined @click="showAdvanced = !showAdvanced">
          <span v-show="showAdvanced">Hide </span
          ><span v-show="!showAdvanced">Show </span> Detailed Query Editor
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text :disabled="loading" color="warning">
          <v-icon left>fal fa-times</v-icon>Clear</v-btn
        >
        <v-btn text :disabled="loading" color="success" @click="runQuery">
          <v-icon left>fas fa-play</v-icon>
          Run Query</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-row v-if="!firstTime || (firstTime && loading)">
      <v-col>
        <v-card v-if="!loading">
          <v-toolbar dense flat>
            <v-toolbar-title class="grey--text">Results </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="data.length > 0" icon @click="exportToCSV" color="secondary">
              <v-icon>fad fa-file-download</v-icon>
            </v-btn>
            <v-btn
              v-if="data.length > 0"
              small
              class="mr-1"
              icon
              @click="showConfig = !showConfig"
            >
              <v-icon v-show="showConfig">mdi-window-close</v-icon>
              <v-icon v-show="!showConfig">mdi-table-edit</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card v-if="showConfig" flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-select
                    multiple
                    chips
                    deletable-chips
                    label="Display Columns"
                    :items="headers"
                    v-model="selectedHeaders"
                    return-object
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-divider></v-divider>
          <v-data-table
            :items="data"
            :headers="selectedHeaders"
            :hide-default-header="data.length == 0"
            :hide-default-footer="data.length == 0"
            dense
          >
            <template v-slot:top="{ items }">
              <sr-not-found
                flat
                :outlined="false"
                v-if="items.length == 0 && !loading"
                icon="fal fa-table"
              >
                <template v-slot:title>No matching records found</template>
                <template v-slot:description>
                  <v-btn
                    small
                    color="primary"
                    v-if="!showQuery"
                    @click="showQuery = !showQuery"
                  >
                    Configure a new Query
                  </v-btn>
                  <span v-else>Try running a new query</span>
                </template>
                <template v-slot:body>
                  <span></span>
                </template>
              </sr-not-found>
            </template>

            <template v-slot:body="props">
              <tr class="border-bottom" v-for="(index, i) in props.items" :key="i">
                <td v-for="h in selectedHeaders" :key="h.value" class="px-2">
                  <small
                    v-if="
                      !index[h.value].date &&
                      !index[h.value].link &&
                      !index[h.value].special &&
                      !index[h.value].items
                    "
                    v-html="index[h.value].text"
                  ></small>
                  <span
                    v-else-if="index[h.value].special"
                    v-html="index[h.value].special"
                  >
                  </span>
                  <small
                    v-else-if="
                      index[h.value].date &&
                      !index[h.value].link &&
                      !index[h.value].special &&
                      !index[h.value].items
                    "
                    >{{ index[h.value].date }}</small
                  >
                  <a
                    v-else-if="index[h.value].link && !index[h.value].items"
                    :href="index[h.value].link"
                    ><small>{{ index[h.value].text }}</small></a
                  >

                  <div v-if="index[h.value].tags">
                    <v-chip
                      outlined
                      small
                      v-for="(item, ii) in index[h.value].tags"
                      :key="ii"
                      v-html="item"
                    ></v-chip>
                  </div>
                  <div v-if="index[h.value].items">
                    <div
                      class="comment"
                      v-for="(item, ii) in index[h.value].items"
                      :key="ii"
                    >
                      <small v-html="item"></small>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        <v-card v-else>
          <sr-loading></sr-loading>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
const Moment = moment;
export default {
  name: "ReportWidget",
  data() {
    return {
      data: [],
      loading: false,
      error: null,
      showQuery: true,
      firstTime: true,
      localQuery: null,
      groupSearch: null,
      personSearch: null,
      frameworkSearch: null,
      typeSearch: null,
      selectedGroupTypes: [],
      queryName: null,
      showSave: false,
      showAdvanced: false,
      anyGroupType: true,
      saveQueryGroupType: false,
      showConfig: false,
      selectedHeaders: [],
      showArchived: {
        name: "Show Archived?",
        checked: true,
        indeterminate: false,
      },
      showLocked: {
        name: "Show Locked?",
        checked: false,
        indeterminate: true,
      },
    };
  },

  computed: {
    filteredGroups() {
      return this.groups
        ? this.groups.filter((x) => x && x.visible).map((x) => x.value)
        : [];
    },
    selectIndeterminate() {
      debugger;
      var matchAny = this.filteredGroups.some((x) => this.localQuery.groups.includes(x));
      return !this.selectAll && matchAny;
    },
    selectAll: {
      get: function () {
        return this.filteredGroups.every((x) => this.localQuery.groups.includes(x));
      },
      set: function (newval) {
        debugger;
        if (newval) {
          var all = this.localQuery.groups.concat(this.filteredGroups);
          this.localQuery.groups = [...new Set(all)];
        } else {
          var filtered = this.localQuery.groups.filter(
            (x) => !this.filteredGroups.includes(x)
          );
          this.localQuery.groups = [...new Set(filtered)];
        }
      },
    },
    savedQueries() {
      var self = this.$store.getters["saved/self"];
      return self ? self.savedQueries : [];
    },
    groupTypeItems() {
      debugger;
      var distinct = [
        ...new Set(this.groups.filter((x) => x && x.visible).map((g) => g.gtId)),
      ];
      return distinct.map((g) => {
        var gt = this.groups.find((x) => x.gtId == g);
        return {
          text: gt.groupType,
          value: gt.gtId,
        };
      });
    },
    individuals() {
      return this.$store.getters["saved/getAll"]("people")
        .filter((x) => {
          var matchText = true;
          if (this.personSearch && this.personSearch.length != null) {
            matchText = x.displayText
              .toLowerCase()
              .includes(this.personSearch.toLowerCase());
          }
          var isMember = true;
          if (this.memberList != null) {
            isMember = this.memberList.includes(x.id);
          }
          return matchText && isMember && !x.readonly;
        })
        .map((x) => {
          return {
            text: x.displayText,
            avatar: x.avatar,
            value: x.id,
          };
        });
    },
    frameworks() {
      return this.$store.getters["saved/getAll"]("frameworks")
        .filter((x) => {
          var matchText = true;
          if (this.frameworkSearch && this.frameworkSearch.length != null) {
            matchText = x.title
              .toLowerCase()
              .includes(this.frameworkSearch.toLowerCase());
          }
          return matchText;
        })
        .map((x) => {
          return {
            text: x.title,
            value: x.id,
          };
        });
    },
    memberList() {
      return this.group
        ? this.group.members
            .filter((x) => x.active && !x.admin)
            .map((m) => m.individualId)
        : null;
    },
    groups() {
      return this.$store.getters["saved/getAll"]("groups")
        .map((x) => {
          var gt = this.$store.getters["saved/get"](["groupTypes", x.groupType]);
          var pathArr = x.path.map((p) => {
            var first = p.groups != null && p.groups.length != 0 ? p.groups[0] : null;
            var pa = first ? this.$store.getters["saved/get"](["groups", first]) : null;
            return pa ? pa.displayText : "";
          });
          var paths = pathArr ? pathArr.join(" ").toLowerCase() : "";
          if (gt) {
            var text = `${x.displayText} ${gt.name} ${paths}`;
            var containsTerms = true;
            if (this.groupSearch && this.groupSearch.length != 0) {
              containsTerms = text.toLowerCase().includes(this.groupSearch.toLowerCase());
            }
            var matchAnyGroupType = true;
            if (this.selectedGroupTypes.length != 0) {
              debugger;
              matchAnyGroupType = this.selectedGroupTypes.includes(x.groupType);
            }
            var admin = x.directAdmin || (!x.readonly && !x.opaque);
            var permissions = admin
              ? gt.objectPermissions.admin.docs || gt.objectPermissions.admin.selfDocs
              : x.directMember
              ? gt.objectPermissions.member.docs || gt.objectPermissions.member.selfDocs
              : null;
            var parent = pathArr && pathArr.length ? pathArr[0] : "";
            var newText = `${x.displayText} (${gt.name}`;
            if (parent.length) {
              newText = `${newText} in ${parent}`;
              if (pathArr.length > 1) {
                var ancestor = pathArr[pathArr.length - 1];
                newText = `${newText}: ${ancestor}`;
              }
            }
            newText = `${newText})`;

            if (permissions) {
              return {
                text: newText,
                value: x.id,
                path: parent,
                pathText: paths,
                pathArr: pathArr,
                relevance: x.relevance,
                groupType: gt.name,
                gtId: gt.id,
                visible: matchAnyGroupType && containsTerms,
              };
            }
          }
          return null;
        })
        .sort((a, b) => {
          if ((a != null) & (b != null)) {
            if (a.relevance != b.relevance) {
              return a.relevance - b.relevance;
            } else {
              var gtnA = a.groupType.toUpperCase(); // ignore upper and lowercase
              var gtnB = b.groupType.toUpperCase(); // ignore upper and lowercase
              if (gtnA < gtnB) {
                return -1;
              }
              if (gtnA > gtnB) {
                return 1;
              } else {
                var nameA = a.text.toUpperCase(); // ignore upper and lowercase
                var nameB = b.text.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
              }
            }
          }

          // names must be equal
          return 0;
        });
    },
    headers() {
      return this.app_info.reportHeaders.filter((x) => !x.hidden);
    },
    hiddenHeaders() {
      return this.app_info.reportHeaders.filter((x) => x.hidden);
    },
  },
  created() {
    this.init();
  },
  methods: {
    updateIndeterminate(e) {
      e.indeterminate = false;
    },
    exportToCSV() {
      //  var keys = this.data && this.data.length != 0 ? Object.keys(this.data[0]).sort((a,b)=>{
      var keys = this.selectedHeaders.concat(this.hiddenHeaders);
      var headers = keys.map((x) => x.text);
      var filename = `Report ${this.$moment(this.query.start).format(
        "DD MMM YYYY"
      )}-${this.$moment(this.query.end).format("DD MMM YYYY")}`;
      var t = this;
      var data = t.data.map((x) => {
        var props = keys.map((x) => x.value);
        var d = {};
        //Object.assign(d,x)
        debugger;
        props.forEach((prop) => {
          debugger;
          if (!x[prop].text) {
            console.log(prop);
            d[prop] = " ";
          } else {
            var r = x[prop].text.toString().replaceAll(",", ": ").replaceAll("\n", ". ");
            d[prop] = t.truncateString(r, 32000, true);
          }
          //var isObject = typeof x[prop] === 'object' && x[prop] != null;
        });

        //                    debugger;
        return d;
      });
      this.exportCSVFile(headers, data, filename);
    },
    toggleAnyGroupType(e) {
      if (e) {
        this.anyGroupType = false;
      }
    },
    toggleGroupTypes(e) {
      debugger;
      if (e) {
        this.selectedGroupTypes = [];
      }
    },
    updateGroups() {
      this.localQuery.groups = this.groups.filter((x) =>
        this.selectedGroupTypes.includes(x.gtId)
      );
    },
    saveQuery() {
      console.log("save");
    },
    cancelSave() {
      this.queryName = null;
      this.showSave = false;
    },
    headedData(raw) {
      debugger;
      const headers = this.app_info.reportHeaders;
      var t = this;
      var data = raw
        .filter((x) => {
          return x.value >= 0;
        })
        .map((d) => {
          var item = {};
          headers.forEach((h) => {
            item[h.value] = { text: h.content(d, t) };
            if (h.href) {
              item[h.value].link = h.href(d, t);
            }
            if (h.items) {
              item[h.value].items = h.items(d, t);
            }
            if (h.tags) {
              item[h.value].tags = h.tags(d, t);
            }
            if (h.date) {
              item[h.value].date = h.date(d, t);
            }
          });
          return item;
        });
      return data;
    },
    runQuery() {
      this.loading = true;
      var query = {};
      this.selectedHeaders = this.app_info.reportHeaders.filter((x) => x.selected);
      Object.assign(query, this.localQuery);
      query.start = this.$moment(this.localQuery.start).toISOString();
      query.end = this.$moment(this.localQuery.end).toISOString();
      query.onlyArchived = this.showArchived.checked && !this.showArchived.indeterminate;
      query.onlyUnarchived =
        !this.showArchived.checked && !this.showArchived.indeterminate;
      query.onlyLocked = this.showLocked.checked && !this.showLocked.indeterminate;
      query.onlyUnlocked = !this.showLocked.checked && !this.showLocked.indeterminate;

      console.log(query);

      this.$post(`reports/query`, query).then((response) => {
        if (response.error) {
          this.error = response.text;
        } else {
          debugger;
          this.data = this.headedData(response);
          /*      this.data = response.map(d=>{
                        var group = this.$store.getters['saved/get'](['groups', d.groupId]);
                        var person = this.$store.getters['saved/get'](['people', d.personId])
                        return{
                            ...d,
                            group: group ? group.displayText : "",
                            person: person ? person.displayText : ""
                        }
                    }).filter(x=>x.group != null && x.person != null);
                    }
                    */
        }
        this.loading = false;
        this.firstTime = false;
      });
    },
    init() {
      // this.loading = true;
      this.localQuery = this.query;
      this.showArchived.checked =
        this.localQuery.onlyArchived && !this.localQuery.onlyUnarchived;
      this.showArchived.indeterminate =
        (!this.localQuery.onlyArchived && !this.localQuery.onlyUnarchived) ||
        (this.localQuery.onlyArchived && this.localQuery.onlyUnarchived);

      this.showLocked.checked =
        this.localQuery.onlyLocked && !this.localQuery.onlyUnlocked;
      this.showLocked.indeterminate =
        (!this.localQuery.onlyLocked && !this.localQuery.onlyUnlocked) ||
        (this.localQuery.onlyLocked && this.localQuery.onlyUnlocked);
      var getRequest = this.groupId || this.personId;

      if (getRequest) {
        if (this.groupId) {
          this.localQuery.groups = [this.groupId];
        }
        if (this.personId) {
          this.localQuery.individuals = [this.personId];
        }
        /*var url = !this.personId ? `reports/group/${this.groupId}` : `reports/group/${this.groupId}/person/${this.personId}`;
                this.$get(url).then((response)=>{
                debugger;
                if(response.error){
                    this.error = response.text
                   
                }
                else{
                    this.data = response.data.map(d=>{
                        var group = this.$store.getters['saved/get'](['groups', d.groupId]);
                        var person = this.$store.getters['saved/get'](['people', d.personId])
                        return{
                            ...d,
                            group: group.displayText,
                            person: person.displayText
                        }
                    });
                }
                this.loading = false;
                });
                 */
      }
      //   else{

      //  this.runQuery();

      //  }
    },
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    groupId: {
      type: String,
    },
    frameworkId: {
      type: String,
    },
    personId: {
      type: String,
    },
    people: {
      type: Array,
    },
    group: {
      type: Object,
    },

    query: {
      type: Object,
      default: () => {
        var end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        var start = Moment(new Date()).add(-3, "months").format("YYYY-MM-DD");
        return {
          groups: [],
          rubrics: [],
          getByLastUpdated: true,
          assessmentTags: [],
          assessmentTagMatchAny: true,

          objectiveCommentTags: [],
          objectiveCommentTagMatchAny: true,

          unitCommentTags: [],
          unitCommentTagMatchAny: true,

          rubricTags: [],
          rubricTagMatchAny: true,

          rubricUnitTags: [],
          rubricUnitTagMatchAny: true,

          rubricObjectiveTags: [],
          rubricObjectiveTagMatchAny: true,

          individuals: [],
          assessments: [],
          matchAny: false,

          start: start,
          end: end,
          getNode: false,
          frameworks: [],
          onlyArchived: false,
          onlyLocked: false,
          onlyUnlocked: false,
          onlyUnarchived: true,
        };
      },
    },
  },
};
</script>
