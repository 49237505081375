<template>
  <v-tabs
    :key="settings"
    v-if="assessmentid"
    :grow="grow"
    :vertical="vertical"
    :color="sliderColor"
    :right="right"
    :optional="optional"
  >
    <v-tabs-slider></v-tabs-slider>
    <v-tab
      v-for="(t, i) in tabList"
      :key="i"
      :exact="t.exact"
      :to="{ name: t.name, props: { assessmentid: assessmentid }, query: $route.query }"
    >
      <i :class="t.icon" class="fad mr-2"></i>
      <span v-if="t.filter == 'term'">{{ t.text | term }}</span>
      <span v-else>{{ t.text | pluralTerm }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "tabList",
  data() {
    return {
      // tabList: [],
    };
  },
  created() {},
  computed: {
    tabList() {
      var result = this.tabs;
      if (this.settings) {
        result.push({
          text: "Settings",
          filter: "term",
          exact: false,
          icon: "fa-cog",
          name: "AssessmentSettings",
        });
      }

      return result.filter((x) => !this.exclude.includes(x.name));
    },
  },
  props: {
    exclude: {
      type: Array,
      default: () => [],
    },
    optional: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [
        {
          text: "Assess",
          filter: "term",
          exact: false,
          icon: "fa-home",
          name: "AssessmentHome",
        },
        /*   {
                    text: 'Report',
                    filter: 'pluralTerm',
                    exact: true,
                    icon: 'fa-chart-bar',
                    name: 'AssessmentReports'
                },
                */
      ],
    },
    right: {
      type: Boolean,
      default: false,
    },
    iconWeight: {
      type: String,
      default: "fad",
    },
    grow: {
      type: Boolean,
      default: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    assessmentid: {
      type: String,
    },
    canWrite: {
      type: Boolean,
      default: false,
    },
    settings: {
      type: Boolean,
      default: false,
    },

    sliderColor: {
      type: String,
      default: "secondary",
    },
  },
};
</script>
