<template>
<v-container fluid>
    <sr-breadcrumbs :items="[{text: 'Home',to:{name: 'Home'} }, {text: 'Reports', to:{name: 'Report'}, disabled: true}]"></sr-breadcrumbs>
  <v-row>
            <v-col>
                <sr-report></sr-report>
            </v-col>

        </v-row>

</v-container>
      
</template>

<script>
export default {
    name: "Report",
     
}
</script>