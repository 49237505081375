<template>
  <v-card :loading="saving">
        <v-toolbar   dense flat>
            <v-toolbar-title>Edit Scoring</v-toolbar-title>

        </v-toolbar>
        <v-card-text>
            <v-form v-model="valid"  v-if="editableSettings" :key="resetCount">
                <v-checkbox 
                    v-model="editableSettings.format.stackScore" 
                    @change="change"
                    :disabled="saving"
                    label="Use sum of objectives instead of average.">
                </v-checkbox> 
                <v-checkbox 
                    v-model="editableSettings.format.bestFit" 
                    @change="change"
                    :disabled="saving"
                    label="Use best fit to calculate score.">
                </v-checkbox> 
                <v-checkbox 
                    v-model="editableSettings.format.requireAllObjectives" 
                    @change="change"
                    :disabled="saving"
                    label="Include all objectives in score (even if unmarked).">
                </v-checkbox> 
                <v-card outlined :tile="app_info.tile">
                    <v-card-subtitle class="overline">Grade Boundaries</v-card-subtitle>
                    <v-simple-table  :key="boundaryKey">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Label</th>
                                <th class="text-left">Floor (%)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(g, i) in editableSettings.gradeBoundaries" :key="i">
                                <td>
                                    <v-text-field @input="change" v-model="g.key"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field  append-outer-icon="fal fa-percent"
                                        @input="change" v-model.number="g.floor">
                                    </v-text-field>
                                </td>
                                <td>
                                    <v-btn text @click="removeGradeBoundary(i)">
                                        <v-icon>fal fa-times</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="3">
                                    <v-btn text @click="addGradeBoundary(i)">
                                        <v-icon left >fal fa-plus</v-icon> Add Boundary
                                    </v-btn>
                                </th>
                            </tr>
                        </tfoot>
                        </template>
                </v-simple-table>
                </v-card>
             
            </v-form>
        </v-card-text>
              <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn text @click="reset" :disabled="changes == 0 || saving" color="warning">reset</v-btn>
           <v-btn text @click="update" :disabled="changes == 0 || !valid || saving" color="success">Save Changes</v-btn>
       </v-card-actions>
  </v-card>

</template>

<script>
export default {
    name: 'FrameworkScoring',
    data(){
        return{
            editableSettings: null,
            changes: 0,
            saving: false,
            valid: false,
            resetCount: 0,
            boundaryKey: 0,
        }
    },
    created(){
        this.reset();
    },
    methods:{
        change(){
            this.changes ++;
        },
        reset(){
            if(this.editableSettings != null)
            {
                this.editableSettings = null;
            }
            var boundaries = this.framework.gradeBoundaries.sort((a,b)=>{
                var aF = a.floor;
                var bF = b.floor;
                return aF < bF ? -1 : aF > bF ? 1 : 0;
            });
           /* this.framework.gradeBoundaries.forEach(g=>{
                var boundary = {
                    key: g.key,
                    floor: g.floor //* 100
                }
                boundaries.push(boundary)
            });
            */
            this.editableSettings = {};
            var settings = {
                id: this.framework.id,
                gradeBoundaries: boundaries,
                format:{
                    stackScore: this.framework.format.stackScore,
                    bestFit: this.framework.format.bestFit,
                    requireAllObjectives: this.framework.format.requireAllObjectives,
                }
            };
            Object.assign(this.editableSettings, settings);
            this.resetCount ++;
        },
        removeGradeBoundary (index) {
           this.editableSettings.gradeBoundaries.splice(index, 1 );
           this.boundaryKey ++;
            this.change();
        },
        addGradeBoundary() {
           var index = this.editableSettings.gradeBoundaries.length; 
           this.editableSettings.gradeBoundaries.push({
               key: `Level ${(index + 1).toString()}`,
               floor: 0
           });
           this.boundaryKey ++;
          this.change();

        },
  
        update(){
            this.saving = true;
           /* var boundaries = [];
            this.editableSettings.gradeBoundaries.forEach(g=>{
                var boundary = {
                    key: g.key,
                    floor: g.floor/100
                }
                boundaries.push(boundary)
            });
            this.editableSettings.gradeBoundaries = [];
            this.editableSettings.gradeBoundaries = boundaries;
            */
            var data = this.editableSettings; 
            data.secret = this.framework.secret;
            this.$post("update/framework/scoring", data).then((response)=>{
                debugger;
                if(response.error){
                    
                     this.$emit('error', this.errorText(response.text, 'save',this.$getTerm('framework')))
                }
                else{
                   // this.$store.dispatch('saved/get_frameworks')
                    this.changes = 0;
                    this.$emit('update', 'Changes Saved')
                }
                this.saving = false;
            });
       
        }
    },
    props:{
        framework: {
            type:Object
        },
        permissions: {
            type: Object,
        },
    
    }
}
</script>