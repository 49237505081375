<template>
    <div>
      <v-container v-if="!isDetailView">
             <sr-breadcrumbs :items="[{text: 'Home',to:{name: 'Home'} }, {text: 'People', to:{name: 'People'}, disabled: true}]"></sr-breadcrumbs>

          <v-row >
            
              <v-col>
                <people-widget 
                    :title="title" 
                    :groupId="groupId"
                    :hideHeaders="true"
                >

                </people-widget>
              </v-col>
       
          </v-row>
      </v-container>
      <router-view v-else></router-view>

    </div>
    

</template>

<script>
import PeopleWidget from '../newComponents/PeopleWidget.vue'
export default {
    name: "PeopleList",
    components:{
        PeopleWidget
    },
 
    computed:{
        isDetailView(){
            if(this.$route.params.individualid){
                return true;
            }
            return false;
        },
        groupId(){
            return this.$route.query.group;
        },
        title(){
            if(this.groupId){
                var group = this.$store.getters['saved/get'](['groups', this.groupId]);
                return `${group.displayText} ${this.$getTerm('Member')}`
            }
            return null
        }

    }
}
</script>