<template>
  <v-card>
    <v-toolbar flat extension-height="350" v-if="!hideToolbar">
      <div
        :class="{
          'grey--text': !app_info.colorfulHeadings,
          'secondary--text': app_info.colorfulHeadings,
        }"
        class="text-subtitle"
      >
        {{ title | pluralTerm }}
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        :hide-details="true"
        label="Search"
        @keyup.enter="getDataFromApi()"
      >
        <template v-slot:append>
          <v-icon v-show="!search || !search.length">mdi-magnify</v-icon>
          <v-icon v-show="search && search.length">mdi-keyboard-return</v-icon>
        </template>
      </v-text-field>

      <v-btn
        v-if="allowFilter && !hideFilter"
        icon
        @click="showExtension = !showExtension"
      >
        <v-icon v-show="!showExtension">mdi-chevron-down</v-icon>
        <v-icon v-show="showExtension">mdi-chevron-up</v-icon>
      </v-btn>

      <template v-slot:extension v-if="showExtension && allowFilter">
        <v-row :key="changes">
          <v-col>
            <v-card fluid flat class="mb-3">
              <v-card-text>
                <v-row>
                  <v-col cols="3" class="text-right">
                    <span>Show only:</span>
                  </v-col>
                  <v-col v-if="!groupId">
                    <v-select
                      :disabled="!groupReady"
                      label="All groups..."
                      multiple
                      :items="groupItems"
                      clearable
                      deletable-chips
                      dense
                      item-value="value"
                      @input="updateGroups"
                      @change="includeChildGroups = false"
                      v-model="localQuery.groups"
                      :hint="!groupReady ? 'Loading options...' : ''"
                      :persistent-hint="!groupReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model="groupSearch"
                              append-icon="mdi-magnify"
                              label="Search Groups"
                              clearable
                              single-line
                              hide-details
                              :disabled="!groupReady"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <sr-group-chip
                              selectOption
                              :id="item.value"
                              small
                            ></sr-group-chip>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <sr-group-chip
                          selectOption
                          :id="item.value"
                          small
                        ></sr-group-chip>
                      </template>
                    </v-select>
                    <v-checkbox
                      v-if="localQuery.groups && localQuery.groups.length"
                      label="Include Children"
                      v-model="includeChildGroups"
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col v-if="!frameworkId && !rubricId">
                    <v-select
                      label="All Frameworks..."
                      multiple
                      :items="filteredFrameworkItems"
                      :disabled="!frameworkReady"
                      clearable
                      deletable-chips
                      dense
                      item-text="title"
                      @input="changes++"
                      item-value="id"
                      v-model="localQuery.frameworks"
                      :hint="!frameworkReady ? 'Loading options...' : ''"
                      :persistent-hint="!frameworkReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model.lazy="frameworkSearch"
                              append-icon="mdi-magnify"
                              label="Search Frameworks"
                              clearable
                              :disabled="!frameworkReady"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="!rubricId">
                    <v-select
                      label="All Rubrics..."
                      multiple
                      :items="filteredRubricItems"
                      :disabled="!rubricReady"
                      clearable
                      deletable-chips
                      dense
                      item-text="title"
                      item-value="id"
                      v-model="localQuery.rubrics"
                      :hint="!rubricReady ? 'Loading options...' : ''"
                      :persistent-hint="!rubricReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model.lazy="rubricSearch"
                              append-icon="mdi-magnify"
                              label="Search Rubrics"
                              clearable
                              :disabled="!rubricReady"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3" class="text-right">
                    <span class="mt-3">Between dates</span>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="localQuery.start" type="date"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="localQuery.end" type="date"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="showLocked.checked"
                      @change="updateIndeterminate(showLocked)"
                      :indeterminate="showLocked.indeterminate"
                      :label="showLocked.name"
                      persistent-hint
                      :hint="
                        showLocked.indeterminate
                          ? `Both locked and unlocked ${getTerm(
                              'assessment',
                              true
                            )} will be retrieved`
                          : `Only ${!showLocked.checked ? 'un' : ''}locked ${getTerm(
                              'assessment',
                              true
                            )} will be retrieved`
                      "
                    >
                      <template v-slot:append>
                        <v-btn
                          v-if="!showLocked.indeterminate"
                          small
                          text
                          color="primary"
                          @click="showLocked.indeterminate = !showLocked.indeterminate"
                          >Show All</v-btn
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="showArchived.checked"
                      @change="updateIndeterminate(showArchived)"
                      :indeterminate="showArchived.indeterminate"
                      :label="showArchived.name"
                      persistent-hint
                      :hint="
                        showArchived.indeterminate
                          ? `Both archived and unarchived ${getTerm(
                              'assessment',
                              true
                            )} will be retrieved`
                          : `Only ${!showArchived.checked ? 'un' : ''}archived ${getTerm(
                              'assessment',
                              true
                            )} will be retrieved`
                      "
                    >
                      <template v-slot:append>
                        <v-btn
                          v-if="!showArchived.indeterminate"
                          small
                          text
                          color="primary"
                          @click="
                            showArchived.indeterminate = !showArchived.indeterminate
                          "
                          >Show All</v-btn
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="resetFilters" color="error">Reset Filters</v-btn>
                <v-btn text @click="getDataFromApi(true)" color="primary">Search</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :value="selectedAssessments"
      @input="update"
      sort-by="created"
      :sort-desc="true"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
      class="elevation-1"
      :show-select="selectable && totalItems <= 100"
    >
      <template v-slot:item.picture="{ item }">
        <v-avatar size="36">
          <v-img :src="item.picture"></v-img>
        </v-avatar>
      </template>
      <template v-slot:item.created="{ item }">
        <div class="d-flex align-center">
          {{ item.created | moment(app_info.shortDate) }}
        </div>
      </template>
      <template v-slot:item.groupId="{ item }">
        <sr-group-chip v-if="groupReady" flat newWindow path :id="item.groupId">
        </sr-group-chip>
      </template>
      <template v-slot:item.complete="{ item }">
        <v-chip
          small
          class="mr-4"
          :color="
            item.outstanding == 0 ? 'success' : item.complete > 0 ? 'warning' : 'error'
          "
        >
          <i class="far fa-check" v-if="item.outstanding == 0"></i>
          <span style="width: 1em; text-align: center" v-else>
            {{ item.gradebook.length - item.complete }}
          </span>
          <i v-if="item.locked" class="fas fa-lock-alt ml-2"></i>
        </v-chip>
      </template>
      <template v-slot:item.scores="{ item }">
        <v-sparkline
          type="bar"
          :value="item.scores"
          :gradient="app_info.heatmapArray"
          auto-draw
          gradient-direction="bottom"
          auto-line-width
        >
        </v-sparkline>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="goToAssessment(item, 'AssessmentHome', null, true)">
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
const Moment = moment;
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      search: "",
      changes: 0,
      showExtension: false,
      groupSearch: null,
      frameworkSearch: null,
      rubricSearch: null,
      localQuery: {},
      includeChildGroups: false,
      rubricItems: [],
      frameworkItems: [],
      showArchived: {
        name: "Show Archived?",
        checked: true,
        indeterminate: false,
      },
      showLocked: {
        name: "Show Locked?",
        checked: false,
        indeterminate: true,
      },
    };
  },
  watch: {
    options: {
      handler(nv, ov) {
        if (ov.page != nv.page || ov.itemsPerPage != nv.itemsPerPage) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
    changes() {
      this.getFrameworkItems();
      this.getRubricItems();
    },

    frameworkReady() {
      this.getFrameworkItems();
    },
    rubricReady() {
      this.getRubricItems();
    },
  },
  created() {
    this.init();
  },
  methods: {
    resetFilters() {
      this.init();
    },
    init() {
      this.localQuery = {};
      Object.assign(this.localQuery, this.query);
      if (this.rubricId) {
        this.localQuery.rubrics = [this.rubricId];
      }
      if (this.frameworkId) {
        this.localQuery.frameworks = [this.frameworkId];
      }
      if (this.groupId) {
        this.localQuery.groups = [this.groupId];
      }
      if (!this.localQuery.start) {
        // var end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        this.localQuery.start = Moment(new Date())
          .add(-18, "months")
          .format("YYYY-MM-DD");
      }
      if (!this.localQuery.end) {
        this.localQuery.end = Moment(new Date()).add(0, "days").format("YYYY-MM-DD");
      }
      if (this.frameworkId) {
        this.localQuery.frameworks = [this.frameworkId];
      }
      if (this.groupId) {
        this.localQuery.groups = [this.groupId];
      }
      this.showArchived.checked =
        this.archived ||
        (this.localQuery.onlyArchived && !this.localQuery.onlyUnarchived);
      this.showArchived.indeterminate =
        !this.archived &&
        ((!this.localQuery.onlyArchived && !this.localQuery.onlyUnarchived) ||
          (this.localQuery.onlyArchived && this.localQuery.onlyUnarchived));

      this.showLocked.checked =
        this.localQuery.onlyLocked && !this.localQuery.onlyUnlocked;
      this.showLocked.indeterminate =
        (!this.localQuery.onlyLocked && !this.localQuery.onlyUnlocked) ||
        (this.localQuery.onlyLocked && this.localQuery.onlyUnlocked);
      this.getFrameworkItems();
      this.getRubricItems();
    },
    updateIndeterminate(e) {
      e.indeterminate = false;
    },
    update(e) {
      this.$emit("input", e);
    },
    updateGroups() {
      console.log("blur");
      this.includeChildGroups = false;
      this.changes++;

      console.log(this.localQuery.groups);
    },
    updateParents(includeChildGroups) {
      console.log(includeChildGroups);
    },
    getDataFromApi(updatedQuery) {
      debugger;
      this.loading = true;
      var query = {};
      if (this.includeChildGroups == true) {
        this.localQuery.parents = this.localQuery.groups;
      } else if (!this.includeChildGroups) {
        this.localQuery.parents = null;
        this.localQuery.parents = [];
      }
      Object.assign(query, this.localQuery);

      query.start = this.$moment(this.localQuery.start).toISOString();
      query.end = this.$moment(this.localQuery.end).toISOString();
      query.onlyArchived = this.showArchived.checked && !this.showArchived.indeterminate;
      query.onlyUnarchived =
        !this.showArchived.checked && !this.showArchived.indeterminate;
      query.onlyLocked = this.showLocked.checked && !this.showLocked.indeterminate;
      query.onlyUnlocked = !this.showLocked.checked && !this.showLocked.indeterminate;
      if (updatedQuery) {
        this.options.page = 1;
      }
      console.log(query);
      debugger;
      var options = {
        pageNumber: this.options.page - 1,
        pageSize: this.options.itemsPerPage,
        search: this.search,
        query: query,
        sortField: this.options.sortBy[0] ? this.options.sortBy[0] : "created",
        sortDirection: this.options.sortDesc[0] ? "descending" : "ascending",
      };
      console.log(options);
      this.$post(this.url, options).then((data) => {
        debugger;

        if (!data.error) {
          this.items = data.items;
          this.totalItems = data.totalItems > 0 ? data.totalItems : data.items.length;
        }
        this.loading = false;
      });
    },

    getFrameworkItems() {
      var allFrameworks = this.$store.getters["saved/getAll"]("frameworks");
      this.frameworkItems = allFrameworks.filter((f) => !f.draft);
    },
    getRubricItems() {
      var rubrics = this.$store.getters["saved/getAll"]("rubrics");
      var result = rubrics;
      if (this.localQuery.frameworks && this.localQuery.frameworks.length) {
        result = rubrics.filter((r) =>
          this.localQuery.frameworks.includes(r.frameworkId)
        );
      }

      this.rubricItems = result.filter((x) => !x.draft);
    },
  },
  computed: {
    groupReady() {
      return this.$store.getters["saved/status"]("groups") == "complete";
    },
    rubricReady() {
      return this.$store.getters["saved/status"]("rubrics") == "complete";
    },
    frameworkReady() {
      return this.$store.getters["saved/status"]("frameworks") == "complete";
    },
    assessmentReady() {
      return this.$store.getters["saved/status"]("assessments") == "complete";
    },
    groupItems() {
      var groups = this.$store.getters["saved/getAll"]("groups");
      return groups
        .map((group) => {
          debugger;
          var paths = group
            ? group.path.map((g) => {
                var parents = g.groups
                  ? g.groups.map((gp) => {
                      var parent = this.$store.getters["saved/get"](["groups", gp]);
                      return parent ? parent.displayText : "";
                    })
                  : [];
                return parents;
              })
            : [];
          // var count = this.rawAssessments.filter(x=>x.groupId == group.id);
          return {
            text: group.displayText,
            value: group.id,
            path: paths.join(" "),
            member: group.directMember,
            admin: group.directAdmin,
            //    hasCount: count.length != 0
          };
        })
        .filter((x) => {
          if (this.groupSearch && this.groupSearch.length != 0) {
            return (x.text.toLowerCase() + x.path.toLowerCase()).includes(
              this.groupSearch.toLowerCase()
            );
          } else {
            return x;
          }
        });
    },
    filteredFrameworkItems() {
      var result = this.frameworkItems;
      if (this.frameworkSearch) {
        var frameworkSearch = this.frameworkSearch.toLowerCase();
        result = this.frameworkItems.filter((f) => {
          var sString = `${f.title.toLowerCase()}: ${f.tags.join(",").toLowerCase()}`;
          var match = sString.toLowerCase().indexOf(frameworkSearch);
          return match > -1;
        });
      }
      return result;
    },
    filteredRubricItems() {
      var result = this.rubricItems;
      if (this.rubricSearch) {
        var rubricSearch = this.rubricSearch.toLowerCase();
        result = this.rubricItems.filter((f) => {
          var match = f.title.toLowerCase().indexOf(rubricSearch);
          return match > -1;
        });
      }
      return result;
    },
    frameworkProp() {
      if (this.routeProps) {
        return this.routeProps.frameworkId
          ? this.routeProps.frameworkId
          : this.frameworkId;
      }
      return this.frameworkId;
    },
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: "AssessmentHome",
    },
    routeProps: {
      type: Object,
    },
    title: {
      type: String,
      default: "Assessment",
    },
    groupId: {
      type: String,
    },
    rubricId: {
      type: String,
    },
    frameworkId: {
      type: String,
    },
    url: {
      type: String,
      default: "saved/assessments/search",
    },
    selectedAssessments: {
      type: Array,
      default: () => [],
    },
    archived: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    query: {
      type: Object,
      default: () => {
        var end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        var start = Moment(new Date()).add(-18, "months").format("YYYY-MM-DD");
        return {
          groups: [],
          rubrics: [],

          groupTypes: [],
          parents: [],
          showOnlyDirect: false,
          showOnlyNonDirect: false,
          showOnlyAdmin: false,
          showOnlyNonAdmin: false,
          tags: [],
          individuals: [],
          assessments: [],
          matchAny: false,
          tagMatchAny: true,
          start: start,
          end: end,
          getNode: false,
          frameworks: [],
          onlyArchived: false,
          onlyLocked: false,
          onlyUnlocked: true,
          onlyUnarchived: true,
        };
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Date",
            value: "created",
            sortable: true,
            /*  sort: (a, b) => {
              debugger;
              var aDate = new Date(a);
              var bDate = new Date(b);
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
            },
            */
          },
          {
            text: "Title",
            value: "assessmentTitle",
            sortable: true,
          },
          {
            text: "Rubric",
            value: "rubricTitle",
            sortable: true,
          },
          {
            text: "Group",
            value: "groupId",
            sortable: false,
          },
          {
            text: "Scores",
            value: "scores",
            sortable: false,
          },
          {
            text: "Status",
            value: "complete",
            sortable: false,
          },

          {
            text: "",
            value: "actions",
            sortable: false,
          },
        ];
      },
    },
  },
};
</script>
