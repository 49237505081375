<template>
  <div>
    <v-select
      :dense="dense"
      :solo="solo"
      :outlined="outlined"
      item-value="id"
      item-text="displayText"
      :label="label"
      :value="value"
      :multiple="multiple"
      :items="filteredGroupOptions"
      persistent-hint
      v-on:change="update($event)"
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field label="Search" clearable v-model="search"> </v-text-field>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <sr-group-chip :selectOption="multiple == false" :id="item.id"></sr-group-chip>
      </template>
      <template v-slot:item="{ item }">
        <sr-group-chip selectOption :id="item.id"></sr-group-chip>
      </template>
    </v-select>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "GroupPicker",
  data() {
    return {
      search: null,
    };
  },
  computed: {
    groupTypeOptions() {
      return this.$store.getters["saved/getAll"]("groupTypes");
    },
    filteredGroupOptions() {
      var t = this;
      return this.groupOptions
        ? this.groupOptions.filter((f) => {
            debugger;
            var searchArr =
              t.search != null && t.search.length
                ? t.search.split(" ").map((s) => s.toLowerCase())
                : [];
            var matchText = true;
            if (searchArr.length) {
              let i = 0;
              do {
                matchText = f.searchString.includes(searchArr[i]);
                i++;
              } while (i < searchArr.length && matchText);
            }

            return matchText;
          })
        : [];
    },
    groupOptions() {
      var t = this;
      var optionList = this.$store.getters["saved/getAll"]("groups");
      var options =
        this.exclude && Array.isArray(this.exclude)
          ? optionList.filter((x) => !this.exclude.includes(x.id))
          : this.optionList;
      var result = options.map((g) => {
        var gt = t.groupTypeOptions
          ? t.groupTypeOptions.find((x) => x.id == g.groupType)
          : null;
        var path = g.path
          .map((x) => {
            return x
              ? {
                  level: x.level,
                  groups: x.groups
                    ? x.groups.map((g) => {
                        var group = t.$store.getters["saved/get"](["groups", g]);
                        return group != null
                          ? `${group.displayText}${
                              group.internalId ? " (" + group.internalId + ")" : ""
                            }${group.tagString != "" ? "(" + group.tagString + ")" : ""}`
                          : null;
                      })
                    : [],
                }
              : null;
          })
          .sort(function (a, b) {
            if (a.level < b.level) {
              return -1;
            }
            if (a.level > b.level) {
              return 1;
            }
            if (a.level === b.level) {
              return 0;
            }
          })
          .filter((y) => y)
          .map((gp) => {
            return `${gp.groups.join(" ")}`;
          });
        var textSearchArr = [
          g.displayText.toLowerCase(),
          path.join(", ").toLowerCase(),
          gt ? gt.name.toLowerCase() : null,
        ].filter((ts) => ts);
        var tagArr = g.tags ? g.tags.map((t) => t.toLowerCase()) : [];
        var search = textSearchArr.concat(tagArr);
        return {
          ...g,
          search: search,
          searchString: search.join(", "),
        };
      });
      return result;
    },
  },
  methods: {
    clearSearch() {
      console.log("clear");
      this.search = null;
    },
    update(e) {
      console.log(e);
      this.$emit("input", e);
    },
  },
  props: ["value", "multiple", "label", "dense", "outlined", "solo", "exclude"],
};
</script>
