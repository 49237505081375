<template>
  <v-card class="break" v-if="!notFound">
    <div v-if="individualAssessment">
      <v-alert
        class="d-print-none mb-0"
        dense
        tile
        dark
        color="primary"
        :type="
          ['complete', 'ready'].includes(status)
            ? 'success'
            : status == 'partially Complete'
            ? 'info'
            : 'warning'
        "
      >
        {{ "Assessment" | term }} {{ status | capitalize }}
        <template v-slot:append>
          <div>
            <v-btn
              small
              color="primary darken-1"
              @click="toggleComplete"
              v-if="
                ['complete', 'ready', 'partially Complete'].includes(status) &&
                (!self || (self && ['ready', 'ready '].includes(status))) &&
                !locked
              "
            >
              <span v-if="['ready', 'partially Complete'].includes(status)"
                >Finish & Submit</span
              >
              <span v-else>Mark as incomplete</span>
            </v-btn>
            <v-btn
              small
              icon
              text
              class="ml-3"
              v-if="locked || $auth.isDataManager"
              :disabled="!$auth.isDataManager || locking"
              @click="toggleLock"
            >
              <v-icon v-show="locked">mdi-lock</v-icon>
              <v-icon v-show="!locked">mdi-lock-open</v-icon>
            </v-btn>
          </div>
        </template>
      </v-alert>
      <v-progress-linear
        :key="updates"
        v-if="ready"
        color="secondary"
        :value="newCompleteUnits"
        :buffer-value="newStartedUnits"
      ></v-progress-linear>
      <v-progress-linear v-if="!editableAssessment" indeterminate></v-progress-linear>
      <div class="mx-0 pt-0" fluid>
        <v-row v-if="unitOptions.length > 1 && !editMode" class="mt-0">
          <v-col>
            <v-list>
              <v-list-item-group v-model="unit">
                <v-list-item
                  :class="{ 'border-bottom': i != unitOptions.length - 1 }"
                  v-for="(item, i) in unitOptions"
                  :key="item.value"
                  :value="item.value"
                  exact
                >
                  <v-list-item-content>{{ item.text }}</v-list-item-content>
                  <v-list-item-action>
                    <v-chip
                      :color="item.grade == 'No Marks' ? 'primary lighten-2' : 'primary'"
                      small
                      v-if="item.grade && item.complete"
                      >{{ item.grade[0] }}</v-chip
                    >
                    <v-tooltip left v-if="item.score && !item.complete">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          :color="item.grade == 'No Marks' ? 'grey lighten-2' : 'grey'"
                          dark
                          small
                          >{{ item.grade[0] }}</v-chip
                        >
                      </template>
                      <span
                        >Preliminary {{ $getTerm("grade") }},
                        {{ $getTerm("unit") }} incomplete</span
                      >
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-row v-if="unitOptions.length > 1 && editMode" class="my-0 px-5">
          <v-col>
            <v-select
              solo
              class="d-print-none ma-0"
              v-if="unitOptions.length > 1"
              v-model="unit"
              :items="unitOptions"
              @change="goToRoute"
              :label="$getTerm('Unit')"
            >
              <template v-slot:prepend-inner>
                <i class="fal fa-bars mr-3"></i>
              </template>
              <template v-slot:selection="{ item }">
                <div
                  class="d-flex align-center justify-space-between"
                  style="width: 100%"
                >
                  {{ item.text }}
                  <span>
                    <h4 class="primary--text text--darken-1">
                      ({{ (unitOptions.indexOf(item) + 1) | ordinal }} of
                      {{ unitOptions.length }} {{ $getTerm("unit", true) }})
                    </h4>

                    <v-chip color="primary" small v-if="item.grade && item.complete">{{
                      item.grade[0]
                    }}</v-chip>
                    <v-tooltip left v-if="item.score && !item.complete">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          :color="item.grade == 'No Marks' ? 'grey lighten-1' : 'grey'"
                          dark
                          small
                          >{{ item.grade[0] }}</v-chip
                        >
                      </template>
                      <span
                        >Preliminary {{ $getTerm("grade") }},
                        {{ $getTerm("unit") }} incomplete</span
                      >
                    </v-tooltip>
                  </span>
                </div>
              </template>
              <template v-slot:item="{ item }">
                <div
                  class="d-flex align-center justify-space-between"
                  style="width: 100%"
                >
                  {{ item.text }}
                  <span>
                    <v-chip
                      :color="item.grade == 'No Marks' ? 'primary lighten-2' : 'primary'"
                      small
                      v-if="item.grade && item.complete"
                      >{{ item.grade[0] }}</v-chip
                    >
                    <v-tooltip left v-if="item.score && !item.complete">
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          :color="item.grade == 'No Marks' ? 'grey lighten-1' : 'grey'"
                          dark
                          small
                          >{{ item.grade[0] }}</v-chip
                        >
                      </template>
                      <span
                        >Preliminary {{ $getTerm("grade") }},
                        {{ $getTerm("unit") }} incomplete</span
                      >
                    </v-tooltip>
                  </span>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <unit-mark
          v-if="editMode && editableAssessment"
          :secret="secret"
          :key="unit"
          :value="editableAssessment.units.find((x) => x.id == unit)"
          @updateMark="update"
          :editMode="editMode"
          :disabled="editableAssessment.complete || locked"
          :iaId="editableAssessment.id"
          :individualId="individualId"
          @error="error"
          :assessmentId="assessmentId"
        ></unit-mark>

        <div v-if="!editMode && editableAssessment">
          <unit-mark
            :flat="editableAssessment.units.length == 1"
            v-for="u in editableAssessment.units"
            :key="u.i"
            :secret="secret"
            :value="u"
            :class="{ 'd-none d-print-block': u.id != unit }"
            @updateMark="update"
            :editMode="false"
            :iaId="editableAssessment.id"
            :individualId="individualId"
            @error="error"
            :assessmentId="assessmentId"
          ></unit-mark>
        </div>
      </div>
      <v-alert
        dense
        tile
        v-if="!locked"
        dark
        color="primary"
        :type="['complete', 'ready'].includes(status) ? 'success' : 'warning'"
      >
        {{ "Assessment" | term }} {{ status | capitalize }}
        <template v-slot:append>
          <v-btn
            small
            color="primary lighten-2"
            @click="toggleComplete"
            v-if="
              ['complete', 'ready'].includes(status) &&
              (!self || (self && status == 'ready'))
            "
          >
            <span v-if="status == 'ready'">Finish & Submit</span>
            <span v-else>Mark as incomplete</span>
          </v-btn>
        </template>
      </v-alert>
    </div>
    <sr-loading v-else>
      <template v-slot:content> Preparing {{ "assessment" | term }}... </template>
    </sr-loading>
  </v-card>
  <sr-not-found v-else unauthorized :thing="$getTerm('assessment')"> </sr-not-found>
</template>

<script>
import UnitMark from "./UnitMark.vue";
//import UnitReport from './UnitReport.vue'
//const R = require('ramda');
export default {
  name: "IndividualAssessment",
  components: {
    UnitMark,
    //    UnitReport
  },
  data() {
    return {
      editableAssessment: null,
      individualAssessment: null,
      unit: null,
      locking: false,
      resetCount: 0,
      notFound: null,
      showSummary: true,
      incomplete: true,
      updates: 0,
      newCompleteUnits: 0,
      newStartedUnits: 0,
      //calculating: false,
    };
  },
  watch: {
    routeUnit: function () {
      this.routeInit();
    },
    /*   updateKey: function () {
      //update scores
      this.init();
    },
    */
  },
  created() {
    this.init();
  },
  methods: {
    toggleLock() {
      debugger;
      this.locking = true;
      console.log(this.assessmentId);
      var data = {
        id: this.assessmentId,
        personId: this.individualId,
        locking: false,
        secret: this.secret,
        locked: !this.locked,
      };

      console.log(data);
      this.$post(`update/assessment/${this.assessmentId}/lock`, data).then((response) => {
        debugger;
        if (response.error) {
          this.$emit(
            "error",
            `Could not ${
              this.locked ? "unlock" : "lock"
            }. Please reload the page and try again.`
          );
          this.locking = false;
        } else {
          console.log(response);
          this.$emit("input");
          this.$emit("updateLock", data.locked);
          this.init();
        }
      });
    },
    update(e) {
      // console.log(e);
      //   console.log("calculating");

      //   this.calculating = true;
      if (this.editableAssessment) {
        console.log(e);
        if (e && e.selectedBand != null && e.unitId != null && e.objectiveId != null) {
          var unit = this.editableAssessment.units.find((x) => x.id == e.unitId);
          if (unit != null) {
            var objective = unit.objectives.find((x) => x.id == e.objectiveId);
            if (objective != null) {
              objective.selectedBand = e.selectedBand.id;
              objective.levelText = e.selectedBand.levelText;
              objective.value = e.selectedBand.value;
              var band = objective.bands.find((x) => x.id == e.selectedBand.id);
              var bandIndex = objective.bands.indexOf(band);
              objective.bandIndex = bandIndex;
              var unselected = objective.bands.filter((x) => x.id != e.selectedBand.id);
              if (band != null) {
                band.selected = true;
              }
              unselected.forEach((band) => {
                band.selected = false;
              });
            }
          }
        }
      }

      //this.$emit("input", e);
      this.getIncomplete();
    },

    error(e) {
      this.$emit("error", e);
    },
    routeInit() {
      console.log("beginning route init");
      var complete = this.editableAssessment ? this.editableAssessment.complete : false;
      if (this.$route.query.unit) {
        this.unit = this.$route.query.unit;
        if (complete && this.$route.query.mode == "edit") {
          this.$router
            .push({ query: Object.assign({}, this.$route.query, { mode: "report" }) })
            .catch(() => {});
        }
      } else {
        var i =
          this.editableAssessment &&
          this.editableAssessment.units &&
          this.editableAssessment.units.length
            ? this.editableAssessment.units[0].id
            : null;
        if (i != null) {
          this.unit = i;
          var data = { unit: i };
          if (complete) {
            data.mode = "report";
          }
          this.$router
            .push({ query: Object.assign({}, this.$route.query, data) })
            .catch(() => {});
        }
      }
      console.log(" route init complete");
    },

    init() {
      console.log("IndividualAssessment Init");
      this.editableAssessment = null;
      var t = this;
      var data = {
        id: this.assessmentId,
        personId: this.individualId,
        secret: this.secret,
      };
      this.$post(`read/assessment/individual`, data).then((response) => {
        console.log("ia received");
        if (response.error) {
          this.notFound = response.text;
        } else {
          this.individualAssessment = response;
          if (
            this.individualAssessment &&
            this.individualAssessment.units &&
            this.rubric
          ) {
            console.log("parsing ia");
            var aF = this.$r.clone(this.rubric.format);
            aF.objectiveLevelComment =
              aF.objectiveLevelComments || aF.objectiveLevelComment || aF.enableEvidence;
            aF.unitLevelComment = aF.unitLevelComments || aF.unitLevelComment;

            //aF.ratingScale = aF.formatRatingScale || (aF.format.mode ? aF.format.mode == 'scale' : false);
            aF.mode = !aF.mode ? (aF.ratingScale ? "ratingScale" : "matrix") : aF.mode;
            if (this.org_id == "5f577333a63ecf6e443a1d37") {
              aF.mode = "ratingScale";
            }
            console.log("mapping units");
            var units = this.individualAssessment.units.map((x) => {
              var rUnit = t.rubric.units.find((u) => u.id == x.id);

              if (rUnit) {
                rUnit.score = x.score;
                rUnit.marks = x.maxMarks ? x.maxMarks : x.marks;
                rUnit.evidence = x.evidence;
                rUnit.comments = x.comments;

                var uF = rUnit.format ? rUnit.format : aF;
                uF.unitLevelComment = aF.unitLevelComment
                  ? aF.unitLevelComment
                  : uF.unitLevelComment;
                uF.objectiveLevelComment = aF.objectiveLevelComment
                  ? aF.objectiveLevelComment
                  : uF.objectiveLevelComment;

                //if unit gradeboundaries are default, replace with assessment gradeboundaries
                var boundariesDefault = !x.boundaryEdited;
                if (boundariesDefault) {
                  rUnit.gradeBoundaries = t.rubric.gradeBoundaries;
                }
                if (aF.mode != "individual") {
                  uF.mode = aF.mode;
                  uF.letterForRatingScale = aF.letterForRatingScale;
                }
                if (aF.unitLevelComment) {
                  uF.unitLevelComment = aF.unitLevelComment;
                  uF.unitLevelCommentLabel = aF.unitLevelCommentLabel;
                }
                if (aF.objectiveLevelComment) {
                  uF.objectiveLevelComment = aF.objectiveLevelComment;
                  uF.objectiveLevelCommentLabel = aF.objectiveLevelCommentLabel;
                  uF.evidenceTags = aF.evidenceTags;
                  uF.createEvidenceTags = aF.createEvidenceTags;
                }
                if (aF.showUnitScores) {
                  uF.showUnitScores = aF.showUnitScores;
                }
                if (aF.showUnitGrades) {
                  uF.showUnitGrades = aF.showUnitGrades;
                }
                rUnit.metadata = x.metadata;
                rUnit.format = this.$r.clone(uF);

                var objectives =
                  x.objectives && x.objectives.length
                    ? this.$r.clone(
                        x.objectives.map((o) => {
                          var rObjective = rUnit.objectives.find((ro) => ro.id == o.id);
                          if (rObjective) {
                            rObjective.bandIndex = o.bandIndex;
                            rObjective.evidence = o.evidence;
                            rObjective.comments = o.comments;
                            rObjective.levelText = o.levelText;
                            rObjective.targetText = o.targetText;
                            rObjective.value = o.value;
                            rObjective.selectedBand = o.selectedBand;
                            rObjective.tags = o.tags;
                            var bands =
                              rObjective.bands && rObjective.bands.length
                                ? rObjective.bands.map((b) => {
                                    return {
                                      id: b.id,
                                      key: b.key,
                                      floor: b.floor,
                                      levelText: b.levelText,
                                      value: b.value,
                                      selected: b.id == o.selectedBand,
                                      hidden: b.hidden,
                                    };
                                  })
                                : [];
                            rObjective.bands = [];
                            rObjective.bands = bands;
                            return rObjective;
                          }
                        })
                      )
                    : [];

                rUnit.objectives = this.$r.clone(objectives);

                var isChecklist =
                  objectives && objectives.length
                    ? objectives[0].bands.length == 1
                    : false;
                if (isChecklist) {
                  isChecklist = objectives[0].bands[0].floor == rUnit.maxMarks;
                }
                debugger;
                rUnit.checklist = isChecklist;
                rUnit.format.mode = isChecklist ? "checklist" : rUnit.format.mode;
                return rUnit;
              }
              return null;
            });
            console.log("done mapping units");
            var result = this.$r.clone(this.individualAssessment);
            result.format = {};
            result.format = this.$r.clone(aF);
            result.gradeBoundaries = this.$r.clone(this.rubric.gradeBoundaries);
            result.units = [];
            result.units = this.$r.clone(units);
            result.title = this.rubric.title;
            this.editableAssessment = {};
            this.editableAssessment = this.$r.clone(result);
            this.locking = false;
          }
          console.log("IA sending get-incomplete");
          this.getIncomplete();
        }
        console.log("ia done with async");
        this.loading = false;
        // this.calculating = false;
        // console.log("done calculating");
        this.resetCount++;
        console.log("updated reset count, sending route init request");
        this.routeInit();
      });
    },
    goToRoute(e) {
      console.log(e);
      this.$router.push({ query: Object.assign({}, this.$route.query, { unit: e }) });
    },
    toggleComplete() {
      var wantToComplete = !this.individualAssessment.complete;
      var msgText = wantToComplete
        ? `Are you sure you want to submit this ${this.getTerm(
            "evaluation"
          )}? You will not be able to edit it again.`
        : `Are you sure you want to mark this ${this.getTerm(
            "evaluation"
          )} as incomplete? It will make it available for editing again.`;
      var confirm = window.confirm(msgText);
      if (confirm) {
        debugger;
        console.log(this.assessmentId);
        var data = {
          id: this.assessmentId,
          iaId: this.individualAssessment.id,
          secret: this.secret,
          complete: !this.individualAssessment.complete,
          personId: this.individualAssessment.individualId,
        };

        console.log(data);
        this.$post(`update/mark/${this.assessmentId}/complete`, data).then((response) => {
          debugger;
          if (response.error) {
            this.$emit(
              "error",
              `Could not save mark. Please reload the page and try again.`
            );
          } else {
            console.log(response);
            this.$emit("input");
            this.$emit("complete", wantToComplete);
            this.init();
          }
        });
      }
    },
    getIncomplete() {
      console.log("checking incomplete");
      if (this.editableAssessment) {
        var unitLength = this.editableAssessment.units.length;
        var incompleteUnits = this.editableAssessment.units.filter((u) => {
          var incompleteObjectives =
            u.objectives.filter((x) => !x.selectedBand).length != 0;
          return incompleteObjectives;
        });
        var startedUnits = this.editableAssessment.units.filter((u) => {
          var incompleteObjectives =
            u.objectives.filter((x) => x.selectedBand).length != 0;
          return incompleteObjectives;
        });
        var completeUnits = this.editableAssessment.units.filter((u) => {
          var incompleteObjectives =
            u.objectives.filter((x) => !x.selectedBand).length == 0;
          return incompleteObjectives;
        });
        debugger;
        this.newCompleteUnits = (completeUnits.length / unitLength) * 100.0;
        this.newStartedUnits = (startedUnits.length / unitLength) * 100.0;
        this.incomplete = incompleteUnits.length != 0;
        //this.completeUnits = this.getCompleteUnits();
        //this.startedUnits = this.getStartedUnits();
        this.ready = true;
        this.updates++;
        console.log("done checking incomplete");
      }
    },
    getCompleteUnits() {
      if (
        this.editableAssessment &&
        this.editableAssessment.units &&
        this.editableAssessment.units.length
      ) {
        var c = this.editableAssessment.units.filter((x) => {
          var completeObjectives = x.objectives.filter((o) => o.selectedBand);
          var allObjectives = x.objectives.length;
          return allObjectives - completeObjectives == 0;
        }).length;
        var pc = c / this.editableAssessment.units.length;
        return pc * 100.0;
      }
      return null;
    },
    getStartedUnits() {
      if (
        this.editableAssessment &&
        this.editableAssessment.units &&
        this.editableAssessment.units.length
      ) {
        var s = this.editableAssessment.units.filter((x) => {
          var completeObjectives = x.objectives.filter((o) => o.selectedBand);
          return completeObjectives > 0;
        }).length;
        var pc = s / this.editableAssessment.units.length;
        return pc * 100.0;
      }
      return null;
    },
  },
  computed: {
    status() {
      /*   if (this.org_id == "5f4e48fed4298b4e9858e8de") {
        return this.individualAssessment.complete ? "complete" : "partially Complete";
      }
      */
      return this.individualAssessment.complete && !this.incomplete
        ? "complete"
        : !this.individualAssessment.complete && !this.incomplete
        ? "ready"
        : "incomplete";
    },

    routeUnit() {
      return this.$route.query.unit;
    },
    completeUnits() {
      if (this.unitOptions && this.unitOptions.length) {
        var complete = this.unitOptions.filter((x) => x.complete).length;
        var pc = complete / this.unitOptions.length;
        return pc * 100.0;
      }
      return null;
    },
    startedUnits() {
      if (this.unitOptions && this.unitOptions.length) {
        var complete = this.unitOptions.filter((x) => x.score > 0).length;
        var pc = complete / this.unitOptions.length;
        return pc * 100.0;
      }
      return null;
    },
    unitOptions() {
      debugger;
      if (this.editableAssessment) {
        return this.ready
          ? this.editableAssessment.units.map((x) => {
              var sum = this.$r.sum(x.objectives.map((v) => v.value));
              var length = x.objectives.length;
              var avg = sum / (length ? length : 1);
              var incomplete = x.objectives.filter((v) => !v.selectedBand);
              var complete = this.status == "complete" ? true : incomplete.length == 0;
              var score = x.format.stackScore ? sum : (avg / x.marks) * 100;
              var grade = x.gradeBoundaries
                ? x.gradeBoundaries.filter((x) => x.floor <= score)
                : null;
              return {
                text: x.title,
                value: x.id,
                score: score,
                complete: complete,
                grade:
                  grade && grade.length
                    ? grade.sort((a, b) => (a.floor > b.floor ? -1 : 1))[0].key
                    : "No Marks",
              };
            })
          : [];
      }
      return [];
    },
  },
  props: {
    individualId: {
      type: String,
    },
    self: {
      type: Boolean,
      default: false,
    },
    /*   individualAssessment: {
            type: Object
        },
        */
    assessmentId: {
      type: String,
    },
    rubric: {
      type: Object,
    },
    secret: {
      type: String,
    },
    updateKey: {
      type: Number,
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
