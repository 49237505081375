<style>
.breakafter {
  page-break-after: always;
}
</style>
<template>
  <div v-if="membership && filteredInputs.length">
    <v-toolbar dense flat v-if="!embedded && !printView">
      <v-toolbar-title>
        {{ membership.text }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <span v-if="membership.baseline"> {{ membership.baseline.title }} Report</span>
        <span class="d-none d-print-inline"
          >: {{ new Date() | moment("DD MMM YYYY") }}</span
        >
      </v-toolbar-title>
      <v-toolbar-items class="d-print-none ml-5">
        <v-btn
          icon
          v-if="showPrint"
          :to="{ name: 'BaselineReport', params: $route.params }"
        >
          <v-icon>fad fa-print</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-expansion-panels
      v-if="!printView"
      v-model="panels"
      flat
      :tile="app_info.tile"
      accordion
      class="outlined d-print-none"
    >
      <v-expansion-panel key="about">
        <v-expansion-panel-header class="d-flex justify-space-between=">
          <span class="overline"> About</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text v-html="membership.baseline.description"> </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-for="(item, i) in filteredInputs" :key="i">
        <v-expansion-panel-header class="d-flex justify-space-between">
          <span>
            <v-avatar
              size="30"
              class="mr-3 white--text"
              :color="app_info.baselineInputs[i]"
            >
              <h3>{{ item.label[0] | capitalize }}</h3>
            </v-avatar>
            {{ item.label }}</span
          >
          <div class="comma-delimit text-right">
            <span v-for="(v, i) in item.value" :key="i">{{ v }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(v, i) in getVals(item.value)" :key="i">
            <v-card-title>
              <v-avatar tile size="40" class="mr-3 secondary--text" color="transparent">
                <v-icon v-if="v.icon">{{ v.icon }}</v-icon>
                <h3 v-else>{{ v.label[0] | capitalize }}</h3>
              </v-avatar>
              {{ v.label }}
            </v-card-title>
            <v-card-text v-html="v.description"></v-card-text>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="breakdown">
        <v-expansion-panel-header class="d-flex justify-space-between">
          <span class="overline"> Your {{ "SkillProfile" | term }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <span class="text-caption font-italic" v-if="splicedProfile.length == 0"
            >Awaiting Results</span
          >

          <v-list
            style="page-break-after: always"
            v-if="skillProfile.length != 0"
            class="d-print-block"
          >
            <v-list-item v-for="(s, i) in splicedProfile" :key="i">
              <v-list-item-content class="d-flex">
                <v-list-item-title class="shrink"
                  ><v-icon class="mr-5" left>{{ s.skill.icon }}</v-icon>
                  <span class="ml-2">{{ s.skill.name }}</span>
                </v-list-item-title>
                <v-progress-linear
                  :color="s.color"
                  height="10"
                  striped
                  :value="s.score"
                ></v-progress-linear>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="d-print-block d-none" v-if="ready">
      <div class="d-flex justify-center py-5" v-if="imgUrl != null">
        <img
          :src="require(`@/assets/images/${app_info.filename}/icon.png`)"
          v-bind:alt="'icon'"
          class="text-center"
          width="100px"
        />
      </div>
      <div class="text-center py-5">
        <div class="text-h5">{{ membership.text }}</div>

        <div class="overline" v-if="membership.baseline">
          {{ membership.baseline.title }} Report
        </div>
        <div>{{ new Date() | moment("DD MMM YYYY") }}</div>
      </div>
      <hr />
      <v-list
        style="page-break-after: always"
        v-if="skillProfile.length != 0"
        class="d-print-block"
      >
        <v-subheader class="overline">Your profile</v-subheader>
        <span class="text-caption font-italic" v-if="splicedProfile.length == 0"
          >Awaiting Results</span
        >
        <v-list-item v-for="(s, i) in splicedProfile" :key="i">
          <v-list-item-content class="d-flex">
            <v-list-item-title class="shrink"
              ><v-icon class="mr-5" left>{{ s.skill.icon }}</v-icon>
              <span class="ml-2">{{ s.skill.name }}</span>
            </v-list-item-title>
            <v-progress-linear
              :color="s.color"
              height="10"
              striped
              :value="s.score"
            ></v-progress-linear>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-subheader class="overline"
        >About your {{ membership.baseline.title }} {{ "Report" | term }}</v-subheader
      >
      <v-card-text
        style="page-break-after: always"
        v-html="membership.baseline.description"
      >
      </v-card-text>

      <v-card tile v-for="(item, i) in filteredInputs" :key="i">
        <v-card
          v-for="(v, i) in getVals(item.value)"
          :key="i"
          class="breakafter"
          :class="{ breakafter: i == skills.length - 1 }"
        >
          <v-toolbar flat>
            <v-toolbar-title>
              <v-avatar
                size="30"
                class="mr-3 white--text"
                :color="app_info.baselineInputs[item.index]"
              >
                <h3>{{ item.label[0] | capitalize }}</h3>
              </v-avatar>
              {{ item.label }}
            </v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-title>
            <v-avatar tile size="40" class="mr-3 secondary--text" color="transparent">
              <v-icon v-if="v.icon">{{ v.icon }}</v-icon>
              <h3 v-else>{{ v.name | capitalize }}</h3>
            </v-avatar>
            {{ v.name }}
          </v-card-title>
          <v-card-text v-html="v.description"></v-card-text>
        </v-card>
      </v-card>
    </div>
    <v-card tile v-if="printView" class="d-print-none">
      <v-system-bar height="30" color="primary" dark tile>
        <v-btn text x-small @click="goBack">
          <v-icon left> fal fa-arrow-left</v-icon> Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mx-5" @click="print" text x-small>
          Print <v-icon right> fad fa-print</v-icon>
        </v-btn>
        <v-divider vertical></v-divider>
        <v-btn class="mx-5" icon @click="showHelp = !showHelp" text x-small>
          <v-icon right> fal fa-question</v-icon>
        </v-btn>
      </v-system-bar>
      <v-card
        v-if="showHelp"
        style="padding: 0px"
        class="print-card"
        height="100px !important"
      >
        <v-alert text color="secondary">
          If you are having trouble printing, please ensure that you have
          <strong>Print Background Graphics</strong> enabled.
        </v-alert>
      </v-card>

      <v-card class="print-card" v-if="ready">
        <div class="d-flex justify-center py-5" v-if="imgUrl != null">
          <v-img
            :src="require(`@/assets/images/${app_info.filename}/icon.png`)"
            v-bind:alt="'icon'"
            class="text-center"
            max-width="100px"
          ></v-img>
        </div>
        <div class="text-center py-5">
          <div class="text-h5">{{ membership.text }}</div>

          <div class="overline" v-if="membership.baseline">
            {{ membership.baseline.title }} Report
          </div>
          <div>{{ new Date() | moment("DD MMM YYYY") }}</div>
        </div>
        <hr />
        <v-list v-if="skillProfile.length != 0">
          <v-subheader class="overline">Your profile</v-subheader>
          <span class="text-caption font-italic" v-if="splicedProfile.length == 0"
            >Awaiting Results</span
          >

          <v-list-item v-for="(s, i) in splicedProfile" :key="i">
            <v-list-item-content class="d-flex">
              <v-list-item-title class="shrink"
                ><v-icon class="mr-5" left>{{ s.skill.icon }}</v-icon>
                <span class="ml-2">{{ s.skill.name }}</span>
              </v-list-item-title>
              <v-progress-linear
                :color="s.color"
                height="10"
                striped
                :value="s.score"
              ></v-progress-linear>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card class="print-card">
        <v-subheader class="overline"
          >About your {{ membership.baseline.title }} {{ "Report" | term }}</v-subheader
        >
        <v-card-text v-html="membership.baseline.description"> </v-card-text>
      </v-card>

      <v-card flat v-for="(item, i) in filteredInputs" :key="i">
        <v-card
          class="print-card"
          v-for="(v, i) in getVals(item.value)"
          :key="i"
          :class="{ breakafter: i == skills.length - 1 }"
        >
          <v-toolbar flat>
            <v-toolbar-title>
              <v-avatar
                size="30"
                class="mr-3 white--text"
                :color="app_info.baselineInputs[item.index]"
              >
                <h3>{{ item.label[0] | capitalize }}</h3>
              </v-avatar>
              {{ item.label }}
            </v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-title>
            <v-avatar tile size="40" class="mr-3 secondary--text" color="transparent">
              <v-icon v-if="v.icon">{{ v.icon }}</v-icon>
              <h3 v-else>{{ v.name | capitalize }}</h3>
            </v-avatar>
            {{ v.name }}
          </v-card-title>
          <v-card-text v-html="v.description"></v-card-text>
        </v-card>
      </v-card>
    </v-card>
  </div>
  <sr-not-found :icon="'fad fa-watch'" v-else>
    <template v-slot:title>In Progress</template>
    <template v-slot:body>This baseline report has not been completed yet. </template>
  </sr-not-found>
</template>

<script>
export default {
  name: "BaselineReportText",
  data() {
    return {
      panels: null,
      showHelp: false,
      imgUrl: require(`@/assets/images/${process.env.VUE_APP_ORG}/icon.png`),
      ready: false,
    };
  },
  created() {
    //this.imgUrl =  this.getImgUrl("icon");
  },
  mounted() {
    this.panels = this.filteredInputs.length + 1;
    this.ready = true;
  },

  methods: {
    print() {
      window.print();
    },
    getVals(items) {
      return this.$r.clone(this.skills).filter((x) => items.includes(x.name));
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    splicedProfile() {
      var result = [];

      this.filteredInputs.forEach((input, i) => {
        var skills = this.skillProfile.filter((x) => input.value.includes(x.skill.name));
        skills.forEach((s) => {
          var c = {};
          Object.assign(c, s);
          c.color = this.app_info.baselineInputs[i];
          result.push(c);
        });
      });
      return result;

      //return this.$r.clone(this.skillProfile).slice(0, 6);
    },
    filteredInputs() {
      return this.$r
        .clone(this.membership.baseline.inputs)
        .filter((x) => x.value && x.value.length);
    },
  },

  props: {
    skillProfile: {
      type: Array,
      default: () => [],
    },
    membership: {
      type: Object,
    },
    skills: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    printView: {
      type: Boolean,
      default: false,
    },
    showPrint: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
