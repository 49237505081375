<template>
  <div>
    <v-container v-if="!isDetailView">
      <sr-breadcrumbs :items="breadcrumbs"></sr-breadcrumbs>

      <v-row>
        <v-col>
          <v-alert dense text type="info" v-if="isAdmin">
            Select {{ "assessment" | pluralTerm }} to see which batch actions are
            available ({{ app_info.short_name }} {{ "Administrator" | pluralTerm }} only)
          </v-alert>
          <v-card outlined :tile="app_info.tile">
            <sr-open-assessments
              :hideHeaders="!isAdmin"
              :headers="headers"
              :query="query"
              :selectable="isAdmin"
              :archived="$route.name == 'ArchivedAssessments'"
              v-model="selectedAssessments"
              @input="updateAssessments"
              :rubricId="rubricId"
              :key="$route.name"
            >
            </sr-open-assessments>
            <v-divider v-if="isAdmin && selectedAssessments.length"></v-divider>
            <multiple-actions
              v-if="isAdmin && selectedAssessments.length"
              :items="selectedAssessments"
              :actions="actions"
              url="assessments/arr"
            ></multiple-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import MultipleActions from "./../newComponents/MultipleActions.vue";
import moment from "moment";
const Moment = moment;
export default {
  name: "Assessments",
  components: {
    MultipleActions,
  },
  data() {
    return {
      selectedAssessments: [],
    };
  },
  methods: {
    updateAssessments(e) {
      this.selectedAssessments = e;
    },
  },
  computed: {
    breadcrumbs() {
      if (this.$route.name == "ArchivedAssessments") {
        return [
          { text: "Home", to: { name: "Home" } },
          {
            text: this.$getTerm("Assessment", true),
            to: { name: "Assessments" },
            exact: true,
          },
          {
            text: this.$getTerm("Archived Assessment", true),
            to: { name: "ArchivedAssessments" },
            disabled: true,
          },
        ];
      } else {
        return [
          { text: "Home", to: { name: "Home" } },
          {
            text: this.$getTerm("Assessment", true),
            to: { name: "Assessments" },
            disabled: true,
          },
        ];
      }
    },
    rubricId() {
      return this.$route.query.rubric;
    },
    actions() {
      var assessments = this.selectedAssessments.length ? this.selectedAssessments : [];
      var result = [
        {
          name: "DuplicateAssessments",
          title: `Duplicate ${this.getTerm("assessment", true)}`,
          component: "sr-duplicate-assessments",
          props: {
            availableIds: [],
            unavailableIds: [],
            assessments: assessments,
          },
          data: {
            key: "Docs",
            write: true,
            ids: assessments.map((a) => a.id),
          },
        },
      ];
      if (this.$auth.isDataManager) {
        result.push({
          name: "ArchiveAssessments",
          title: `Archive ${this.getTerm("assessment", true)}`,
          component: "sr-archive-assessments",
          props: {
            availableIds: [],
            unavailableIds: [],
            assessments: assessments,
            archive: true,
          },
          data: {
            key: "Docs",
            write: true,
            ids: assessments.map((a) => a.id),
          },
        });
        result.push({
          name: "LockAssessments",
          title: `Lock ${this.getTerm("assessment", true)}`,
          component: "sr-lock-assessments",
          props: {
            availableIds: [],
            unavailableIds: [],
            assessments: assessments,
            lock: true,
          },
          data: {
            key: "Docs",
            write: true,
            ids: assessments.map((a) => a.id),
          },
        });
        result.push({
          name: "UnlockAssessments",
          title: `Unlock ${this.getTerm("assessment", true)}`,
          component: "sr-lock-assessments",
          props: {
            availableIds: [],
            unavailableIds: [],
            assessments: assessments,
            lock: false,
          },
          data: {
            key: "Docs",
            write: true,
            ids: assessments.map((a) => a.id),
          },
        });
        result.push({
          name: "BatchTagAssessment",
          title: `Add tags to ${this.getTerm("assessment", true)}`,
          component: "sr-batch-tag-assessment",
          props: {
            availableIds: [],
            unavailableIds: [],
            assessments: assessments,
          },
          data: {
            key: "Docs",
            write: true,
            ids: assessments.map((a) => a.id),
          },
        });
      }

      return result;
    },
    isDetailView() {
      if (this.$route.params.assessmentid) {
        return true;
      }
      return false;
    },
    isAdmin() {
      return this.$auth.isAdmin;
    },
  },
  props: {
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Date",
            value: "created",
            sortable: true,
            /*  sort: (a, b) => {
              debugger;
              var aDate = new Date(a);
              var bDate = new Date(b);
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
            },
            */
          },
          {
            text: "Title",
            value: "assessmentTitle",
            sortable: true,
          },
          {
            text: "Rubric",
            value: "rubricTitle",
            sortable: true,
          },
          {
            text: "Group",
            value: "groupId",
            sortable: false,
          },
          {
            text: "Scores",
            value: "scores",
            sortable: false,
          },
          {
            text: "Status",
            value: "complete",
            sortable: false,
          },

          {
            text: "",
            value: "actions",
          },
        ];
      },
    },
    query: {
      type: Object,
      default: () => {
        var end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        var start = Moment(new Date()).add(-18, "months").format("YYYY-MM-DD");
        return {
          groups: [],
          rubrics: [],
          tags: [],

          groupTypes: [],
          parents: [],
          showOnlyDirect: false,
          showOnlyNonDirect: false,
          showOnlyAdmin: false,
          showOnlyNonAdmin: false,

          individuals: [],
          assessments: [],
          matchAny: false,
          tagMatchAny: true,
          start: start,
          end: end,
          getNode: false,
          frameworks: [],
          onlyArchived: false,
          onlyLocked: false,
          onlyUnlocked: false,
          onlyUnarchived: true,
        };
      },
    },
  },
};
</script>
