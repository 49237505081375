<template>
  <v-card :tile="app_info.tile" flat>
    <v-card-text v-if="editableMembership">
      <v-form v-model="valid">
        <div v-for="(input, i) in editableMembership.baseline.inputs" :key="i">
          <v-select
            v-if="input.inputType == 'select'"
            :items="items"
            @change="changes++"
            v-model="input.value"
            :item-value="input.valueField"
            :disabled="saving || loading != false"
            :rules="getRules(input)"
            :multiple="input.multiselect"
            :label="input.multiselect ? pluralize(input.label) : input.label"
            :item-text="input.labelField"
          >
            <template v-slot:selection="{ item }">
              <v-chip color="secondary">
                <v-icon left>{{ item[input.iconField] }}</v-icon>
                {{ item[input.labelField] }}
              </v-chip>
            </template>
          </v-select>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="editableMembership">
      <v-spacer></v-spacer>
      <v-btn text @click="init" :disabled="changes == 0 || saving" color="warning"
        >reset</v-btn
      >
      <v-btn
        text
        @click="update"
        :disabled="changes == 0 || !valid || saving"
        color="success"
        >Save Changes</v-btn
      >
    </v-card-actions>

    <v-snackbar v-model="showSnackbar" top :timeout="500" :color="snackbar.type">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: "AssignBaseline",

  data() {
    return {
      loading: false,
      editableMembership: null,
      membership: null,
      error: null,
      valid: false,
      changes: 0,
      snackbar: { text: null, type: "info" },
      showSnackbar: false,
      saving: false,
    };
  },
  computed: {
    name() {
      var result = this.personId
        ? this.$store.getters["saved/get"](["people", this.personId])
        : null;
      return result ? result.displayText : null;
    },
    loaded() {
      return this.membership != null;
    },
  },
  created() {
    this.loading = "secondary";
    this.init();
  },
  watch: {
    loading: function (n) {
      this.$emit("loading", n);
    },
    personId: function () {
      this.loading = "secondary";

      this.init();
    },
  },
  methods: {
    update() {
      this.saving = true;
      this.loading = "secondary";
      debugger;
      this.$post(
        `groups/${this.groupId}/members/${this.personId}`,
        this.editableMembership.baseline
      ).then((response) => {
        debugger;
        if (response.error) {
          this.snackbar.text = response.text;
          this.snackbar.type = "error";
        } else {
          this.snackbar.text = `${this.baseline.title} updated.`;
          this.snackbar.type = "success";
        }
        this.saving = false;
        this.loading = false;
        this.showSnackbar = true;
        this.$emit("update");
        //this.init();
      });
    },
    getRules(i) {
      var rules = i.rules ? i.rules : [];
      if (i.required) {
        var label = `${i.label} ${
          i.inputType == "selection" ? "selection" : ""
        } is required`;
        var rule = (v) => !!v || label;
        if (i.inputType && i.multiselect) {
          rule = (v) => v.length > 0 || `At  least one ${label}`;
        }
        rules.push(rule);
      }
      return rules;
    },
    init() {
      this.changes = 0;
      this.$get(`groups/${this.groupId}/members/${this.personId}`).then((response) => {
        if (response.error) {
          this.error = response.text;
        } else {
          this.membership = response.data;
        }
        this.loading = false;
        this.mergeBaseline();
      });
    },
    mergeBaseline() {
      if (this.baseline != null) {
        var gtB = this.baseline;
        var mB = this.membership.baseline ? this.membership.baseline : {};
        var result = {
          description: gtB.description,
          title: gtB.title,
          inputs: [],
        };
        gtB.inputs.forEach((i) => {
          var mBI = mB.inputs ? mB.inputs.find((x) => x.name == i.name) : null;
          i.value =
            mBI && mBI.value
              ? mBI.value
              : i.inputType == "select" && i.multiselect
              ? []
              : null;
          result.inputs.push(i);
        });
        this.editableMembership = {};
        this.editableMembership = Object.assign(this.editableMembership, this.membership);
        this.editableMembership.baseline = result;
      }
    },
    getVals(item) {
      var options = this.$store.getters[item.getter](item.param).skills;
      var results = item.value.map((v) => {
        var value = options.find((x) => x[item.valueField] == v);
        if (value) {
          return {
            label: value[item.labelField] ?? null,
            description: value[item.descriptionField] ?? value.description,
            icon: value[item.iconField] ?? null,
            image: value[item.imageField] ?? null,
          };
        }
        return null;
      });
      return results.filter((x) => x);
    },
  },
  props: {
    personId: {
      type: String,
    },
    baseline: {
      type: Object,
    },
    groupId: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
