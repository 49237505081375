<template>
  <v-card :outlined="outlined" :flat="flat">
    <v-container fluid>
      <v-row align="center">
        <v-col class="text-center" cols="3">
          <i :class="color + '--text ' + icon" class="fa-5x ma-5"></i>
        </v-col>
        <v-col>
          <v-card-title class="text-center">
            <slot name="title">
              <span v-if="!unauthorized"> Not Found</span>
              <span v-else> No Access</span>
            </slot>
          </v-card-title>
          <v-card-subtitle>
            <slot name="description"> </slot>
          </v-card-subtitle>
          <v-card-text>
            <slot name="body">
              <span v-if="!unauthorized">
                {{ thing | capitalize }} does not exist or you do not have access.
              </span>
              <span v-else> You do not have access to {{ thing }}. </span>
            </slot>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="actions">
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="secondaryAction"></slot>
        <slot name="primaryAction"></slot>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    icon: {
      type: String,
      default: "fad fa-tools",
    },
    color: {
      type: String,
      default: "secondary",
    },
    actions: {
      type: Boolean,
      default: false,
    },
    thing: {
      type: String,
      default: "Page",
    },
    unauthorized: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
