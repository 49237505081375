<template>
  <div v-if="group && people && groupType">
    <v-card :flat="readonly" :outlined="readonly && !nav" :loading="loading">
      <v-toolbar flat v-if="!readonly">
        <v-toolbar-title>
          {{ "Member" | pluralTerm }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon color="secondary" @click="showAddNew = !showAddNew" v-if="!readonly">
          <v-icon>fad fa-user-plus</v-icon>
        </v-btn>
        <v-btn color="secondary" icon @click="showImport = !showImport" v-if="!readonly">
          <v-icon>fad fa-file-import</v-icon>
        </v-btn>
        <template v-slot:extension v-if="!readonly">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </template>
      </v-toolbar>
      <v-divider v-if="!readonly"></v-divider>

      <div v-if="people.length">
        <v-list :nav="nav">
          <v-subheader class="overline"
            >{{ groupType.adminName | pluralize }}
          </v-subheader>

          <v-list-item :dense="nav" v-for="(m, i) in admins" :key="'admin' + i">
            <v-list-item-avatar size="30">
              <v-img v-if="m.member && m.member.avatar" :src="m.member.avatar"></v-img>
              <v-icon v-else>fad fa-user</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="m.member">{{
                m.member.displayText
              }}</v-list-item-title>
            </v-list-item-content>
            <span v-if="group.manageMembers && !readonly">
              <v-menu offset-x v-if="!m.self">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" icon v-bind="attrs" v-on="on">
                    <v-icon>fal fa-cog</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-subheader v-if="m.member" class="overline text-center">{{
                    m.member.displayText
                  }}</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item @click="toggleSuspend(m.individualId)">
                    <i class="fal fa-ban error--text mr-3"></i>
                    <v-list-item-title
                      >Suspend from {{ group.metadata.name }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="toggleAdmin(m.individualId)">
                    <i class="fal fa-arrow-down warning--text mr-3"></i>
                    <v-list-item-title
                      >Demote to {{ groupType.memberName }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn icon disabled v-else>
                <v-icon>fad fa-user </v-icon>
              </v-btn>
            </span>

            <v-list-item-action v-if="permissions.administrators.read || m.self">
              <v-btn
                icon
                :to="{
                  name: 'PersonGroup',
                  params: { groupid: group.id, individualid: m.individualId },
                }"
                ><v-icon>fal fa-angle-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="!admins.length">
            <v-list-item-subtitle class="font-italic"
              >No {{ groupType.adminName | pluralize }}
              <span v-if="search && search.length">found</span>
              <span v-else>yet</span>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
        <v-list v-if="permissions && permissions.memberList.read">
          <v-divider></v-divider>
          <v-subheader class="overline"
            >{{ groupType.memberName ? groupType.memberName : "member" | pluralize }}
          </v-subheader>
          <v-list-item :dense="nav" v-for="(m, i) in members" :key="'member' + i">
            <v-list-item-avatar size="30">
              <v-img v-if="m.member && m.member.avatar" :src="m.member.avatar"></v-img>
              <v-icon v-else>fad fa-user</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="m.member"
                ><i class="fad fa-user mr-3" v-if="m.self"></i
                >{{ m.member.displayText }}</v-list-item-title
              >
            </v-list-item-content>
            <span v-if="group.manageMembers && !readonly">
              <v-menu offset-x v-if="!m.self">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" icon v-bind="attrs" v-on="on">
                    <v-icon>fal fa-cog</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-subheader v-if="m.member" class="overline text-center">{{
                    m.member.displayText
                  }}</v-subheader>
                  <v-divider></v-divider>
                  <v-list-item @click="toggleSuspend(m.individualId)">
                    <i class="fal fa-ban error--text mr-3"></i>
                    <v-list-item-title
                      >Suspend from {{ group.metadata.name }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="toggleAdmin(m.individualId)">
                    <i class="fal fa-arrow-up warning--text mr-3"></i>
                    <v-list-item-title
                      >Promote to {{ groupType.adminName }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn icon disabled v-else>
                <v-icon>fad fa-user </v-icon>
              </v-btn>
            </span>
            <v-list-item-action v-if="permissions.members.read || m.self">
              <v-btn
                icon
                :to="{
                  name: 'PersonGroup',
                  params: { groupid: group.id, individualid: m.individualId },
                }"
                ><v-icon>fal fa-angle-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="!members.length">
            <v-list-item-subtitle class="font-italic"
              >No {{ groupType.memberName ? groupType.memberName : "member" | pluralize }}
              <span v-if="search && search.length">found</span>
              <span v-else>yet</span>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
        <v-btn
          block
          small
          outlined
          color="light darken-2"
          v-if="permissions && permissions.memberList.write"
          @click="showInactive = !showInactive"
        >
          <span v-show="showInactive">Hide Suspended</span>
          <span v-show="!showInactive">Show Suspended</span>
        </v-btn>

        <v-list v-if="permissions && permissions.memberList.write && showInactive">
          <v-subheader class="overline">Suspended </v-subheader>
          <v-list-item :dense="nav" v-for="(m, i) in inactive" :key="'inactive' + i">
            <v-list-item-avatar size="30">
              <v-img v-if="m.member && m.member.avatar" :src="m.member.avatar"></v-img>
              <v-icon v-else>fad fa-user</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text--disabled" v-if="m.member"
                ><i class="fad fa-user mr-3" v-if="m.self"></i
                >{{ m.member.displayText }}</v-list-item-title
              >
              <v-list-item-subtitle class="text--disabled" v-if="m.admin">{{
                groupType.adminName
              }}</v-list-item-subtitle>
              <v-list-item-subtitle class="text--disabled" v-else>{{
                groupType.memberName
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <span v-if="group.manageMembers && !readonly">
              <v-menu offset-x v-if="!m.self">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" icon v-bind="attrs" v-on="on">
                    <v-icon>fal fa-cog</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-subheader v-if="m.member" class="overline text-center"
                    >{{ m.member.displayText }}
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item @click="toggleSuspend(m.individualId)">
                    <i class="fal fa-plus success--text mr-3"></i>
                    <v-list-item-title
                      >Reinstate to {{ group.metadata.name }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn icon disabled v-else>
                <v-icon>fad fa-user </v-icon>
              </v-btn>
            </span>

            <v-list-item-action v-if="m.self">
              <v-btn
                icon
                :to="{
                  name: 'PersonGroup',
                  params: { groupid: group.id, individualid: m.individualId },
                }"
                ><v-icon>fal fa-angle-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="!inactive.length">
            <v-list-item-subtitle class="font-italic"
              >No suspended members
              <span v-if="search && search.length">found</span>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
    <v-dialog
      max-width="75vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :hide-overlay="$vuetify.breakpoint.xsOnly"
      v-model="showAddNew"
    >
      <v-card :tile="$vuetify.breakpoint.smOnly">
        <sr-add-member
          @update="update"
          @addExisting="addExistingMember"
          @cancel="toggleShowAddNew"
          :group="group"
          :loading="loading"
          :groupType="groupType"
        >
        </sr-add-member>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showImport"
      max-width="75vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :hide-overlay="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <sr-csv-import
          :loading="loading"
          @import="importMembers"
          :group="group.id"
          :groupType="groupType"
        ></sr-csv-import>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackbar" :color="snackbarStatus" top>
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import SrCsvImport from "../../newComponents/GroupComponents/CsvImport.vue";
import SrAddMember from "../../newComponents/GroupComponents/AddMember.vue";
export default {
  name: "GroupMembers",
  data() {
    return {
      search: "",
      snackbarStatus: "error",
      showSnackbar: false,
      snackbarText: null,
      showAddNew: false,
      showImport: false,
      loading: false,
      showInactive: false,
    };
  },
  components: {
    SrCsvImport,
    SrAddMember,
  },
  methods: {
    resetGroupStuff() {
      this.$get("saved/sessiondata/delete").then(() => {
        this.$store.dispatch("saved/partialReset");
      });
    },
    toggleShowAddNew(e) {
      console.log(e);
      this.showAddNew = !this.showAddNew;
    },
    initNewMember() {
      this.newMember = {
        firstName: null,
        lastName: null,
        email: null,
        createAccount: false,
      };
    },
    update(data) {
      debugger;
      if (!data.error) {
        console.log("partial reset");
        this.resetGroupStuff();
        this.$emit("update", `${data.name} added to ${this.group.metadata.name}`);
      } else {
        this.showSnackbar = true;
        this.snackbarText = `Unable to add ${data.name} to ${this.group.metadata.name}. Please try again.`;
        this.snackbarStatus = "error";
      }
    },
    toggleSuspend(id) {
      this.$post("entities/groups/togglesuspend", {
        groupId: this.group.id,
        individualId: id,
      }).then((response) => {
        this.showSnackbar = response.error ? true : false;
        this.snackbarText = response.error ? response.text : null;
        this.snackbarStatus = response.error ? "error" : "dark";

        if (!response.error) {
          var i = this._people.find((x) => x.individualId == id);
          var name = i.active ? "suspended" : "reinstated";
          this.$emit("update", `${i.member.displayText} ${name}`);
        }
      });
    },
    toggleAdmin(id) {
      this.$post("entities/groups/toggleadmin", {
        groupId: this.group.id,
        individualId: id,
      }).then((response) => {
        this.showSnackbar = response.error ? true : false;
        this.snackbarText = response.error ? response.text : null;
        this.snackbarStatus = response.error ? "error" : "dark";
        if (!response.error) {
          var i = this._people.find((x) => x.individualId == id);
          var name = i.admin
            ? `demoted to ${this.groupType.memberName}`
            : `promoted to ${this.groupType.adminName}`;
          this.$emit("update", `${i.member.displayText} ${name}`);
        }
      });
    },
    importMembers(data) {
      this.loading = this.group.metadata.primaryColor ?? "secondary";
      this.$post(`entities/groups/${this.group.id}/members/upload`, data).then(
        (response) => {
          this.showSnackbar = response.error ? true : false;
          this.snackbarText = response.error ? response.text : null;
          this.snackbarStatus = response.error ? "error" : "dark";
          if (!response.error) {
            debugger;

            /* var items = response.map(x=>{
                    return {
                        Firstname: x.firstName ?? "",
                        Lastname: x.lastName ?? "",
                        Username: x.username ?? "",
                        Password: x.password ?? "",
                        AccountCreated: x.success ?? false,
                        Message: x.message ?? "Valid email is required to create account"
                    }
                });

                var headers = ['Firstname', 'Lastname', 'Username', 'Password', 'AccountCreated', 'Message'];
                var filename = `Member Import: ${this.group.metadata.name}`
                this.exportCSVFile(headers, items, filename);
                */
            this.showImport = false;
            console.log("partial reset");
            this.resetGroupStuff();
            this.$emit(
              "update",
              `New ${this.pluralize(this.groupType.memberName)} imported`
            );
          }
          this.loading = false;
        }
      );
    },
    addExistingMember(data) {
      console.log(data);
      this.$post("entities/groups/addmember", data.membership).then((response) => {
        this.showSnackbar = response.error ? true : false;
        this.snackbarText = response.error ? response.text : null;
        this.snackbarStatus = response.error ? "error" : "dark";
        if (!response.error) {
          console.log("partial reset");
          this.resetGroupStuff();
          this.$emit("update", `${data.name} added to ${this.group.metadata.name}`);
        }
      });
    },
  },
  computed: {
    groupType() {
      return this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupTypeId])
        : null;
    },
    self() {
      return this.$store.getters["saved/self"];
    },
    admins() {
      return this.filteredPeople.filter((x) => x.admin && x.active);
    },
    members() {
      return this.filteredPeople.filter((x) => !x.admin && x.active);
    },
    inactive() {
      return this.filteredPeople.filter((x) => !x.active);
    },
    filteredPeople() {
      if (this.search && this.search.length != 0) {
        return this._people.filter((x) =>
          x.member
            ? x.member.displayText.toLowerCase().includes(this.search.toLowerCase())
            : false
        );
      }
      return this._people;
    },
    _people() {
      return this.group && this.people && this.people.length
        ? this.group.members
            .map((x) => {
              var i = this.people.find((p) => p.id === x.individualId);
              if (i != null) {
                return {
                  ...x,
                  member: i,
                  self: this.self ? x.individualId == this.self.id : false,
                };
              }
            })
            .filter((x) => x)
            .sort((a) => {
              return a.admin ? -1 : 1;
            })
        : [];
    },
  },
  props: {
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Name",
            value: "name",
          },
        ];
      },
    },
    nav: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    people: {
      type: Array,
    },
    permissions: {
      type: Object,
    },
  },
};
</script>
