var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.notFound)?_c('v-card',{staticClass:"break"},[(_vm.individualAssessment)?_c('div',[_c('v-alert',{staticClass:"d-print-none mb-0",attrs:{"dense":"","tile":"","dark":"","color":"primary","type":['complete', 'ready'].includes(_vm.status)
          ? 'success'
          : _vm.status == 'partially Complete'
          ? 'info'
          : 'warning'},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',[(
              ['complete', 'ready', 'partially Complete'].includes(_vm.status) &&
              (!_vm.self || (_vm.self && ['ready', 'ready '].includes(_vm.status))) &&
              !_vm.locked
            )?_c('v-btn',{attrs:{"small":"","color":"primary darken-1"},on:{"click":_vm.toggleComplete}},[(['ready', 'partially Complete'].includes(_vm.status))?_c('span',[_vm._v("Finish & Submit")]):_c('span',[_vm._v("Mark as incomplete")])]):_vm._e(),(_vm.locked || _vm.$auth.isDataManager)?_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","icon":"","text":"","disabled":!_vm.$auth.isDataManager || _vm.locking},on:{"click":_vm.toggleLock}},[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.locked),expression:"locked"}]},[_vm._v("mdi-lock")]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.locked),expression:"!locked"}]},[_vm._v("mdi-lock-open")])],1):_vm._e()],1)]},proxy:true}],null,false,3505182034)},[_vm._v(" "+_vm._s(_vm._f("term")("Assessment"))+" "+_vm._s(_vm._f("capitalize")(_vm.status))+" ")]),(_vm.ready)?_c('v-progress-linear',{key:_vm.updates,attrs:{"color":"secondary","value":_vm.newCompleteUnits,"buffer-value":_vm.newStartedUnits}}):_vm._e(),(!_vm.editableAssessment)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('div',{staticClass:"mx-0 pt-0",attrs:{"fluid":""}},[(_vm.unitOptions.length > 1 && !_vm.editMode)?_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-list',[_c('v-list-item-group',{model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}},_vm._l((_vm.unitOptions),function(item,i){return _c('v-list-item',{key:item.value,class:{ 'border-bottom': i != _vm.unitOptions.length - 1 },attrs:{"value":item.value,"exact":""}},[_c('v-list-item-content',[_vm._v(_vm._s(item.text))]),_c('v-list-item-action',[(item.grade && item.complete)?_c('v-chip',{attrs:{"color":item.grade == 'No Marks' ? 'primary lighten-2' : 'primary',"small":""}},[_vm._v(_vm._s(item.grade[0]))]):_vm._e(),(item.score && !item.complete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.grade == 'No Marks' ? 'grey lighten-2' : 'grey',"dark":"","small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.grade[0]))])]}}],null,true)},[_c('span',[_vm._v("Preliminary "+_vm._s(_vm.$getTerm("grade"))+", "+_vm._s(_vm.$getTerm("unit"))+" incomplete")])]):_vm._e()],1)],1)}),1)],1)],1)],1):_vm._e(),(_vm.unitOptions.length > 1 && _vm.editMode)?_c('v-row',{staticClass:"my-0 px-5"},[_c('v-col',[(_vm.unitOptions.length > 1)?_c('v-select',{staticClass:"d-print-none ma-0",attrs:{"solo":"","items":_vm.unitOptions,"label":_vm.$getTerm('Unit')},on:{"change":_vm.goToRoute},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('i',{staticClass:"fal fa-bars mr-3"})]},proxy:true},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" "),_c('span',[_c('h4',{staticClass:"primary--text text--darken-1"},[_vm._v(" ("+_vm._s(_vm._f("ordinal")((_vm.unitOptions.indexOf(item) + 1)))+" of "+_vm._s(_vm.unitOptions.length)+" "+_vm._s(_vm.$getTerm("unit", true))+") ")]),(item.grade && item.complete)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(item.grade[0]))]):_vm._e(),(item.score && !item.complete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.grade == 'No Marks' ? 'grey lighten-1' : 'grey',"dark":"","small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.grade[0]))])]}}],null,true)},[_c('span',[_vm._v("Preliminary "+_vm._s(_vm.$getTerm("grade"))+", "+_vm._s(_vm.$getTerm("unit"))+" incomplete")])]):_vm._e()],1)])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.text)+" "),_c('span',[(item.grade && item.complete)?_c('v-chip',{attrs:{"color":item.grade == 'No Marks' ? 'primary lighten-2' : 'primary',"small":""}},[_vm._v(_vm._s(item.grade[0]))]):_vm._e(),(item.score && !item.complete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.grade == 'No Marks' ? 'grey lighten-1' : 'grey',"dark":"","small":""}},'v-chip',attrs,false),on),[_vm._v(_vm._s(item.grade[0]))])]}}],null,true)},[_c('span',[_vm._v("Preliminary "+_vm._s(_vm.$getTerm("grade"))+", "+_vm._s(_vm.$getTerm("unit"))+" incomplete")])]):_vm._e()],1)])]}}],null,false,3300506485),model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}}):_vm._e()],1)],1):_vm._e(),(_vm.editMode && _vm.editableAssessment)?_c('unit-mark',{key:_vm.unit,attrs:{"secret":_vm.secret,"value":_vm.editableAssessment.units.find(function (x) { return x.id == _vm.unit; }),"editMode":_vm.editMode,"disabled":_vm.editableAssessment.complete || _vm.locked,"iaId":_vm.editableAssessment.id,"individualId":_vm.individualId,"assessmentId":_vm.assessmentId},on:{"updateMark":_vm.update,"error":_vm.error}}):_vm._e(),(!_vm.editMode && _vm.editableAssessment)?_c('div',_vm._l((_vm.editableAssessment.units),function(u){return _c('unit-mark',{key:u.i,class:{ 'd-none d-print-block': u.id != _vm.unit },attrs:{"flat":_vm.editableAssessment.units.length == 1,"secret":_vm.secret,"value":u,"editMode":false,"iaId":_vm.editableAssessment.id,"individualId":_vm.individualId,"assessmentId":_vm.assessmentId},on:{"updateMark":_vm.update,"error":_vm.error}})}),1):_vm._e()],1),(!_vm.locked)?_c('v-alert',{attrs:{"dense":"","tile":"","dark":"","color":"primary","type":['complete', 'ready'].includes(_vm.status) ? 'success' : 'warning'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(
            ['complete', 'ready'].includes(_vm.status) &&
            (!_vm.self || (_vm.self && _vm.status == 'ready'))
          )?_c('v-btn',{attrs:{"small":"","color":"primary lighten-2"},on:{"click":_vm.toggleComplete}},[(_vm.status == 'ready')?_c('span',[_vm._v("Finish & Submit")]):_c('span',[_vm._v("Mark as incomplete")])]):_vm._e()]},proxy:true}],null,false,2540921803)},[_vm._v(" "+_vm._s(_vm._f("term")("Assessment"))+" "+_vm._s(_vm._f("capitalize")(_vm.status))+" ")]):_vm._e()],1):_c('sr-loading',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" Preparing "+_vm._s(_vm._f("term")("assessment"))+"... ")]},proxy:true}],null,false,1031460370)})],1):_c('sr-not-found',{attrs:{"unauthorized":"","thing":_vm.$getTerm('assessment')}})}
var staticRenderFns = []

export { render, staticRenderFns }