<template>
  <v-container fluid>
    <v-row v-if="permissions && permissions.settings">
      <v-col cols="3" md="2" v-if="$vuetify.breakpoint.mdAndUp">
        <v-list nav>
          <v-list-item-group v-model="tab" vertical dense>
            <v-list-item
              dense
              color="secondary"
              v-for="t in tabOptions"
              :key="t.value"
              @click="goToRoute(t.value)"
              :value="t.value"
            >
              <span class="text-body-2">
                {{ t.text }}
              </span>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <v-select
          solo
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="tab"
          :items="tabOptions"
          @change="goToRoute"
        >
        </v-select>

        <keep-alive>
          <component
            v-if="tab"
            :key="updateCount + tab"
            @update="update"
            @error="error"
            :is="component.component"
            v-bind="component.props"
          ></component>
        </keep-alive>
      </v-col>
    </v-row>
    <sr-not-found
      v-else
      color="error"
      icon="fad fa-lock"
      unauthorized
      :thing="`the settings page for ${assessment.title}`"
    >
    </sr-not-found>
  </v-container>
</template>

<script>
export default {
  name: "AssessmentSettings",
  data() {
    return {
      tab: null,
      updateCount: 0,
    };
  },
  created() {
    this.init();
  },

  watch: {
    routeTab: function () {
      this.init();
    },
  },
  methods: {
    init() {
      debugger;
      if (this.$route.query.tab != null) {
        var t = this.$route.query.tab.toLowerCase().replace("#", "");
        this.tab = this.tabOptions.map((x) => x.value).includes(t) ? t : "access";
        // this.goToRoute(this.tab);
      } else {
        this.tab = "access";
        this.goToRoute("access");
      }
    },
    update(snackbar) {
      console.log("assessment emit");
      console.log(snackbar);
      this.$emit("update", snackbar);
      setTimeout(() => this.$emit("reset"), 2000);
    },
    error(snackbar) {
      console.log(snackbar);
      this.$emit("error", snackbar);
    },
    goToRoute(e) {
      this.$router.push({ query: Object.assign({}, this.$route.query, { tab: e }) });
    },
  },
  computed: {
    routeTab() {
      return this.$route.query.tab ? this.$route.query.tab : "access";
    },
    tabOptions() {
      var tabs = [
        {
          text: this.$getTerm("Assessee", true),
          icon: "fa-users",
          value: "access",
        },
        {
          text: "Edit",
          icon: "fa-pencil",
          value: "edit",
        },
      ];

      return tabs;
    },

    component() {
      debugger;
      var component = "sr-edit-assessment";
      var props = {
        assessment: this.assessment,
        permissions: this.permissions,
        resetKey: this.resetKey,
      };
      switch (this.tab) {
        case "access":
          component = "sr-assessment-members";

          break;
      }
      return { component: component, props: props };
    },
  },
  props: {
    assessment: {
      type: Object,
    },

    permissions: {
      type: Object,
    },
    resetKey: {
      type: Number,
    },
  },
};
</script>
