var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.framework.write)?_c('v-card',{attrs:{"loading":_vm.saving}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Edit Metadata")])],1),_c('v-card-text',[(_vm.editableSettings)?_c('v-form',{key:_vm.resetCount,model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$getTerm('Title'),"disabled":_vm.saving,"rules":[
                               function (v) { return !!v || 'Name is required'; },
                                  function (v) { return v.length <= 50 || 'Name must be less than 50 characters'; } ]},on:{"input":_vm.change},model:{value:(_vm.editableSettings.title),callback:function ($$v) {_vm.$set(_vm.editableSettings, "title", $$v)},expression:"editableSettings.title"}}),_c('wysiwyg',{staticClass:"rounded mb-5",attrs:{"label":_vm.$getTerm('Title'),"disabled":_vm.saving},on:{"change":_vm.change},model:{value:(_vm.editableSettings.description),callback:function ($$v) {_vm.$set(_vm.editableSettings, "description", $$v)},expression:"editableSettings.description"}}),_c('v-combobox',{attrs:{"outlined":"","dense":"","hide-no-data":"","single-line":"","small-chips":"","chips":"","clearable":"","label":"Tags","disabled":_vm.saving,"multiple":"","append-icon":""},on:{"input":_vm.change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                              var attrs = ref.attrs;
                              var item = ref.item;
                              var select = ref.select;
                              var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"my-1",attrs:{"input-value":selected,"close":"","dark":""},on:{"click":select,"click:close":function($event){return _vm.removeTag(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" ")])]}}],null,false,2537883044),model:{value:(_vm.editableSettings.tags),callback:function ($$v) {_vm.$set(_vm.editableSettings, "tags", $$v)},expression:"editableSettings.tags"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{attrs:{"disabled":_vm.saving,"persistent-hint":"","hint":"'Public' means it is available to anyone on this platform.","label":"Public?"},on:{"change":_vm.change},model:{value:(_vm.editableSettings.public),callback:function ($$v) {_vm.$set(_vm.editableSettings, "public", $$v)},expression:"editableSettings.public"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{attrs:{"disabled":_vm.saving,"hint":"'Draft' means it is unavailable to group admins.","persistent-hint":"","label":"Draft?"},on:{"change":_vm.change},model:{value:(_vm.editableSettings.draft),callback:function ($$v) {_vm.$set(_vm.editableSettings, "draft", $$v)},expression:"editableSettings.draft"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{attrs:{"disabled":_vm.saving,"persistent-hint":"","hint":"'Static' means rubrics based on this framework can't be modified.","label":"Static?"},on:{"change":_vm.change},model:{value:(_vm.editableSettings.static),callback:function ($$v) {_vm.$set(_vm.editableSettings, "static", $$v)},expression:"editableSettings.static"}})],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || _vm.saving,"color":"warning"},on:{"click":_vm.reset}},[_vm._v("reset")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.changes == 0 || !_vm.valid || _vm.saving,"color":"success"},on:{"click":_vm.update}},[_vm._v("Save Changes")])],1)],1):_c('sr-not-found')}
var staticRenderFns = []

export { render, staticRenderFns }