<template>
  <div :style="`${app_info.loginBG}; height: 100VH;`" id="background">
    <v-row class="align-content-center justify-space-around" style="height: 100%">
      <v-col class="text-center" md="8" lg="6" cols="10">
        <v-card
          :tile="app_info.tile"
          color="transparent"
          :dark="app_info.loginBgDark"
          flat
        >
          <div class="my-5">
            <v-img style="margin: 0 auto" width="100" :src="getImgUrl('icon')"></v-img>
          </div>

          <span class="overline">Welcome</span>
          <div
            class="text-h4 text-md-h3 text-uppercase mt-2"
            v-html="app_info.htmlName ? app_info.htmlName : app_info.name"
          ></div>
          <div class="text-h5 mb-5 overline dark--text text--lighten-5">
            {{ app_info.description }}
          </div>
          <v-divider></v-divider>
          <div v-if="valid">
            <v-card-text v-if="person != null">
              <v-card-subtitle>Welcome {{ person.displayText }}!</v-card-subtitle>
              <p v-if="!this.$auth.isAuthenticated">
                <span v-if="!sentPW">
                  If you haven't logged into this application before, you will need to set
                  a password before you can continue.
                </span>
                <span v-else>
                  We've sent a password reset email to <strong>{{ person.email }}</strong
                  >. Please follow the instructions in the email and then return to this
                  page.
                </span>
              </p>
              <p v-if="!this.$auth.isAuthenticated">
                Your username is <strong>{{ person.email }}</strong>
              </p>
            </v-card-text>
            <sr-loading v-if="loading">
              <template v-slot:content>Validating...</template>
            </sr-loading>

            <v-card-actions v-if="this.$auth.isAuthenticated" class="justify-center ma-5">
              <v-btn
                :tile="app_info.tile"
                v-if="this.$auth.isAuthenticated"
                text
                color="light"
                outlined
                @click="logout"
              >
                <span>Log Out</span>
              </v-btn>
              <v-btn :tile="app_info.tile" color="secondary" @click="goToLoginPath()">
                <span v-if="!assessmentId">Go to Dashboard</span>
                <span v-else>Complete Evaluation</span>
              </v-btn>
            </v-card-actions>
            <div v-else-if="!loading">
              <v-card-actions class="justify-center ma-5">
                <v-btn
                  v-if="!sentPW"
                  :tile="app_info.tile"
                  color="secondary"
                  @click="triggerPasswordReset"
                >
                  Send Password Reset Email
                </v-btn>
                <v-btn
                  v-else
                  :tile="app_info.tile"
                  color="secondary"
                  @click="goToLoginPath()"
                >
                  I have reset my password
                </v-btn>
              </v-card-actions>
              <p v-if="!sentPW"><small>Not your first time logging in? </small></p>

              <v-btn
                v-if="!sentPW"
                :tile="app_info.tile"
                outlined
                color="secondary"
                @click="goToLoginPath()"
              >
                <span v-if="!assessmentId">Go to Dashboard</span>
                <span v-else>Complete Evaluation</span>
              </v-btn>
            </div>
          </div>
          <div v-else>
            <p v-if="!notFound">
              We were unable to find your invitation. Please contact the person who
              invited you or <a :href="app_info.supportUrl">support</a>.<small></small>
            </p>
            <v-card-actions class="justify-center ma-5">
              <v-btn
                :tile="app_info.tile"
                v-if="this.$auth.isAuthenticated"
                text
                color="light"
                outlined
                @click="logout"
              >
                <span>Log Out</span>
              </v-btn>
              <v-btn :tile="app_info.tile" color="secondary" @click="login">
                <span v-if="this.$auth.isAuthenticated">Go to Dashboard</span>
                <span v-else>Log In</span>
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Invitation",
  data() {
    return {
      person: null,
      loading: false,
      assessmentId: null,
      valid: true,
      sentPW: false,
      key: 0,
      notFound: false,
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    triggerPasswordReset() {
      this.$passwordReset(this.person.email);
      this.sentPW = true;
    },
    reload() {
      this.key++;
    },
    goToLoginPath() {
      debugger;
      var route = {
        name: "Home",
      };
      if (this.assessmentId != null) {
        route = {
          name: "AssessmentHome",
          params: {
            assessmentid: this.assessmentId,
          },
        };
      }
      this.$router.push(route);
    },
    init() {
      debugger;
      if (this.$route.query.hash) {
        this.loading = true;
        //var url = encodeURI(this.$route.query.hash);
        this.$publicGet(`public/invitation?query=${this.$route.query.hash}`).then(
          (response) => {
            // console.log(response.data);

            if (!response.error) {
              console.log(response.data);
              var data = response.data;
              this.userId = data.userId;
              this.assessmentId = data.assessmentId;
              this.person = data.person;
            } else {
              this.notFound = true;
              this.valid = false;
            }
            this.loading = false;
          }
        );
      } else {
        this.valid = false;
      }
    },
    createPassword() {
      this.$post("");
    },
    logout() {
      this.$logout();
    },
    login() {
      if (window.location.origin.includes("localhost")) {
        var route = { name: "Home" };
        this.$router.push(route);
      } else {
        var uri = `${window.location}app`;
        console.log(uri);
        this.$login(uri);
      }
    },
    accept() {
      debugger;
      console.log(this.window.location);
      this.$accept();
    },
  },
};
</script>
