<template>
  <v-card outlined class="mb-5" :loading="saving || !rubricReady">
    <v-toolbar dense flat>
      <v-toolbar-title>New {{ "Assessment" | term }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-if="rubricReady" @click="showCreate = !showCreate">
        <v-icon v-show="!showCreate">mdi-plus</v-icon>
        <v-icon v-show="showCreate">mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div v-if="rubricReady">
      <v-slide-y-transition>
        <div v-if="showCreate">
          <v-card-text>
            <v-form v-model="valid" v-if="newAssessment" :key="resetCount">
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    :label="`Title`"
                    v-model="newAssessment.name"
                    :disabled="saving != false"
                    @input="change"
                    :rules="[
                      (v) => !!v || 'Title is required',
                      (v) => v.length <= 150 || 'Title must be less than 150 characters',
                    ]"
                  >
                  </v-text-field>
                  <v-combobox
                    outlined
                    dense
                    hide-no-data
                    single-line
                    small-chips
                    v-model="newAssessment.tags"
                    chips
                    clearable
                    label="Tags"
                    :disabled="saving != false"
                    @input="change"
                    multiple
                    append-icon=""
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        dark
                        @click="select"
                        @click:close="removeTag(item)"
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="6" md="4" lg="3">
                  <v-text-field
                    outlined
                    dense
                    label="Date"
                    :disabled="saving != false"
                    type="date"
                    v-model="newAssessment.date"
                    @input="change"
                    :rules="[(v) => !!v || 'Date is required']"
                  >
                  </v-text-field>
                  <v-select
                    :items="filteredRubricItems"
                    outlined
                    dense
                    :disabled="saving != false"
                    :label="`${$getTerm('Rubric')}`"
                    item-value="id"
                    item-text="title"
                    v-model="newAssessment.rubricId"
                    :rules="[(v) => !!v || 'Rubric is required']"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field
                            dense
                            v-model.lazy="rubricSearch"
                            append-icon="mdi-magnify"
                            label="Search Rubrics"
                            clearable
                            :disabled="!rubricReady"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-if="peopleReady"
                    multiple
                    :items="members"
                    outlined
                    :disabled="saving != false"
                    dense
                    :label="`${pluralize(groupType.memberName)} to be ${toPastTense(
                      $getTerm('assess')
                    )}`"
                    item-value="id"
                    item-text="displayText"
                    searchable
                    v-model="newAssessment.individualIds"
                  >
                  </v-select>

                  <v-switch
                    v-model="newAssessment.selfAssessment"
                    @change="change"
                    :disabled="saving != false"
                    persistent-hint
                    :hint="`Will ${pluralize(
                      groupType.memberName.toLowerCase()
                    )} ${$getTerm('assess')} themselves?`"
                    :label="`Self-${$getTerm('Assessment')}`"
                  >
                  </v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="reset" :disabled="changes == 0 || saving" color="warning"
              >reset</v-btn
            >

            <v-btn text @click="update" :disabled="!valid || saving" color="success"
              >Save Changes</v-btn
            >
          </v-card-actions>
        </div>
      </v-slide-y-transition>
    </div>
  </v-card>
</template>

<script>
const Assessment = function (groupId, defaultSA, defaultRubric, name) {
  debugger;

  return {
    rubricId: defaultRubric,
    groupId: groupId,
    individualIds: [],
    assessors: [],
    selfAssessment: defaultSA,
    date: new Date().toISOString().split("T")[0],
    name: name,
    tags: [],
  };
};
export default {
  name: "CreateAssessment",
  data() {
    return {
      newAssessment: null,
      changes: 0,
      saving: false,
      rubricSearch: null,
      valid: false,
      resetCount: 0,
      showCreate: true,
      error: null,
      members: [],
      rubricItems: [],
      admins: [],
    };
  },
  created() {
    debugger;
    this.$store.dispatch("saved/stealthRubricUpdate").then(() => {
      this.rubricItems = this.$store.getters["saved/rubricsByGroup"](
        this.group.id
      ).filter((x) => !x.draft);
    });
  },
  mounted() {
    debugger;
    this.members = this.getMembers();
    this.admins = this.people
      ? this.people.filter(
          (x) => x.tags.includes("admin") && !x.tags.includes("inactive")
        )
      : [];
    this.reset();
  },

  computed: {
    filteredRubricItems() {
      var result = this.rubricItems;
      if (this.rubricSearch) {
        var rubricSearch = this.rubricSearch.toLowerCase();
        result = this.rubricItems.filter((f) => {
          var match = f.title.toLowerCase().indexOf(rubricSearch);
          return match > -1;
        });
      }
      return result;
    },
    peopleReady() {
      return this.$store.getters["saved/status"]("people") == "complete";
    },
    rubricReady() {
      return this.$store.getters["saved/status"]("rubrics") == "complete";
    },
    /*   rubrics(){
            return this.group ? this.$store.getters['saved/rubricsByGroup'](this.group.id) : [];
        },
        members(){
            return this.getMembers();
        },
        admins(){
            return this.people ? this.people.filter(x=>x.tags.includes('admin') && !x.tags.includes('inactive')) : [];
        }
    */
  },
  methods: {
    change() {
      this.changes++;
    },
    getMembers() {
      return this.people
        ? this.people.filter(
            (x) => !x.tags.includes("admin") && !x.tags.includes("inactive")
          )
        : [];
    },
    reset() {
      if (this.groupId) {
        this.newAssessment = null;
        var defaultSA = this.groupType ? this.groupType.defaultToSA : false;
        var rubric = this.rubrics && this.rubrics.length == 1 ? this.rubrics[0].id : null;
        var baseline = this.groupType ? this.groupType.baseline != null : false;

        var rubricName =
          this.rubrics && this.rubrics.length == 1 ? this.rubrics[0].title : "";
        var groupName =
          this.group && this.group.metadata ? this.group.metadata.name : null;
        var name = `${rubricName}${baseline ? " Profile" : ""}${
          rubricName ? ": " : ""
        }${groupName}`;
        if (!rubricName) {
          name = `${name} ${rubricName ? "" : this.$getTerm("Assessment")}`;
        }
        this.newAssessment = Assessment(this.groupId, defaultSA, rubric, name);
        this.newAssessment.individualIds = this.getMembers().map((x) => x.id);
        if (baseline) {
          this.newAssessment.tags.push("baseline");
        }
        this.newAssessment.assessors.push(this.$auth.userId);
        this.resetCount++;
      }
    },
    removeTag(item) {
      debugger;
      var i = this.newAssessment.tags.indexOf(item);
      this.newAssessment.tags.splice(i, 1);
      this.newAssessment.tags = [...this.newAssessment.tags];
      this.changes++;
    },
    update() {
      this.saving = "secondary";
      var t = this;
      this.$post("assessments/create", this.newAssessment).then((response) => {
        debugger;
        if (response.error) {
          t.error = response.text;
        } else {
          t.reset();
          //t.$store.dispatch('saved/get_assessments')
          var route = {
            name: "AssessmentHome",
            params: {
              assessmentid: response.id,
            },
          };
          console.log(route);

          //  this.$store.dispatch('assessments/init');
          t.$router.push(route);
        }
        t.saving = false;
      });
    },
  },
  props: {
    groupId: {
      type: String,
    },
    group: {
      type: Object,
    },
    groupType: {
      type: Object,
    },
    people: {
      type: Array,
    },
  },
};
</script>
