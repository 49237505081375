var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.comment)?_c('v-card',{staticClass:"border-left mt-4 mb-8 border-secondary",staticStyle:{"border-width":"5px !important"},attrs:{"flat":"","tile":_vm.app_info.tile}},[_c('v-toolbar',{attrs:{"height":"35","dense":"","flat":""}},[_c('v-toolbar-items',[_c('span',{staticClass:"d-print-inline d-none text-overline"},[_vm._v("Last updated by ")]),_c('sr-person-chip',{attrs:{"showAvatar":false,"selectOption":"","definite":"","id":_vm.comment.metadata.lastUpdatedBy
            ? _vm.comment.metadata.lastUpdatedBy
            : _vm.comment.metadata.createdBy
            ? _vm.comment.metadata.createdBy
            : _vm.$auth.userId},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.comment.metadata.lastUpdated,"DD MMM YYYY HH:mm")))]):_vm._e(),_c('v-menu',{staticClass:"d-print-none",attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-2 ml-5 mr-1 d-print-none",attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" History ")])]}}],null,false,4053238749)},[_c('v-list',{staticClass:"pt-0",attrs:{"dense":""}},[_c('v-subheader',{staticClass:"highlight"},[_vm._v("Authors")]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pl-2"},[_c('sr-person-chip',{attrs:{"selectOption":"","id":_vm.comment.metadata.createdBy},scopedSlots:_vm._u([{key:"avatar",fn:function(){return [_c('v-avatar',{attrs:{"left":"","tile":""}},[_vm._v(" + ")])]},proxy:true},{key:"default",fn:function(){return [_c('span',[_vm._v(" - "+_vm._s(_vm._f("moment")(_vm.comment.metadata.created,"DD MMMM YYYY HH:mm")))])]},proxy:true}],null,false,4186136161)})],1)],1),(
                  _vm.comment.metadata.lastUpdatedBy &&
                  _vm.comment.metadata.lastUpdatedBy != _vm.comment.metadata.createdBy
                )?_c('v-divider'):_vm._e(),(
                  _vm.comment.metadata.lastUpdatedBy &&
                  _vm.comment.metadata.lastUpdatedBy != _vm.comment.metadata.createdBy
                )?_c('v-list-item',[_c('v-list-item-content',{staticClass:"pl-2"},[_c('sr-person-chip',{attrs:{"selectOption":"","id":_vm.comment.metadata.lastUpdatedBy},scopedSlots:_vm._u([{key:"avatar",fn:function(){return [_c('v-avatar',{attrs:{"left":"","tile":""}},[_vm._v(" E ")])]},proxy:true},{key:"default",fn:function(){return [_c('span',[_vm._v(" - "+_vm._s(_vm._f("moment")(_vm.comment.metadata.lastUpdated,"DD MMMM YYYY HH:mm")))])]},proxy:true}],null,false,1389750512)})],1)],1):_vm._e()],1)],1)]},proxy:true}],null,false,1283485956)})],1),_c('v-spacer')],1),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutter":""}},[_c('v-col',{staticClass:"py-0",class:{ grow: _vm.$vuetify.breakpoint.smAndUp },attrs:{"id":"comment"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"id":"text","cols":"12","md":_vm.comment.file.length ? 8 : 12}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","id":"commentField"}},[_c('div',[_c('div',[(_vm.errorMsg)?_c('v-alert',{attrs:{"dense":"","outlined":"","text":"","color":"info"}},[_vm._v(" This "+_vm._s(_vm._f("term")("comment"))+" text contains images. Where possible, please replace them with file attachments or links to externally hosted images. ")]):_vm._e(),_c('v-card-text',{staticClass:"py-0",domProps:{"innerHTML":_vm._s(_vm.comment.text)}})],1)])]),_c('v-col',{attrs:{"cols":"12","id":"tagAndAttach"}},[_c('div',{staticClass:"ml-5"},_vm._l((_vm.comment.tags),function(t,ti){return _c('v-chip',{key:ti,staticClass:"mx-2",attrs:{"small":""}},[_vm._v(_vm._s(t))])}),1)])],1)],1),(_vm.comment.file.length)?_c('v-col',{staticClass:"no-padder",attrs:{"id":"attachments","md":_vm.comment.file.length ? 4 : 2}},[_c('sr-file-input',{attrs:{"stretch":"","xs":_vm.$vuetify.breakpoint.smAndDown,"flat":"","readonly":"","parent":_vm.comment}})],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }