<template>
  <v-card flat v-if="editableSettings && permissions">
    <div class="d-flex justify-space-between">
      <v-card-subtitle class="overline" v-if="!editMode">
        <span v-if="editableSettings.id">
          {{ editableSettings.title }}

          <em>({{ unit.maxMarks }} Marks)</em>
        </span>
        <span v-else> New Unit </span>
      </v-card-subtitle>
      <v-spacer v-else></v-spacer>
      <v-btn icon class="ma-3 mt-5" @click="editMode = !editMode">
        <v-icon v-show="editMode">mdi-chevron-up</v-icon>
        <v-icon v-show="!editMode">mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <v-form
      v-model="valid"
      v-if="editableSettings && editMode && permissions.changeRubricUnitMetadata"
      :key="resetCount"
    >
      <v-card-text v-if="editMode || !editableSettings.id">
        <v-row>
          <v-col>
            <v-text-field
              outlined
              dense
              :label="$getTerm('Title')"
              :disabled="saving"
              v-model="editableSettings.title"
              @input="change"
              :rules="[
                (v) => !!v || 'Name is required',
                (v) => v.length >= 1 || 'Name is required',
                (v) => v.length <= 150 || 'Name must be less than 50 characters',
              ]"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <wysiwyg
          class="rounded mb-5"
          :label="$getTerm('Title')"
          v-model="editableSettings.description"
          :disabled="saving"
          @change="change"
        />

        <v-combobox
          outlined
          dense
          hide-no-data
          single-line
          small-chips
          v-model="editableSettings.tags"
          chips
          clearable
          label="Tags"
          :disabled="saving"
          @input="change"
          multiple
          append-icon=""
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              class="my-1"
              dark
              @click="select"
              @click:close="removeTag(item)"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </v-card-text>

      <v-card-actions v-if="editMode || !editableSettings.id || changes != 0">
        <v-btn
          :disabled="saving"
          v-if="editableSettings.id"
          @click="removeUnit"
          color="error"
          >Delete {{ "unit" | term }}</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn
          text
          v-if="editableSettings.id"
          @click="reset"
          :disabled="changes == 0 || saving"
          color="warning"
          >reset</v-btn
        >
        <v-btn
          text
          @click="update"
          :disabled="changes == 0 || !valid || saving"
          color="success"
          >Save Changes</v-btn
        >
      </v-card-actions>
    </v-form>
    <div v-else-if="editMode && !permissions.changeRubricUnitMetaData">
      <v-card-title class="d-flex justify-space-between"
        >{{ unit.title }}
        <span>
          <em>({{ unit.maxMarks }} Marks)</em>
        </span>
      </v-card-title>

      <v-card-text v-html="unit.description"> </v-card-text>
      <v-card-text v-if="unit.tags && unit.tags.length">
        <v-chip small class="mr-2" v-for="(t, i) in unit.tags" :key="i"> {{ t }}</v-chip>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "UnitMeta",
  data() {
    return {
      editableSettings: null,
      changes: 0,
      valid: false,
      resetCount: 0,
      boundaryKey: 0,
      showBoundaries: false,
      editMode: false,
    };
  },
  created() {
    this.reset();
  },
  computed: {
    mode() {
      return this.unit.mode
        ? this.unit.mode
        : this.unit.ratingScale
        ? "ratingScale"
        : "matrix";
    },
  },
  methods: {
    change() {
      this.changes++;
    },
    changeBoundary() {
      this.editableSettings.boundaryEdited = true;
      this.change();
    },
    reset() {
      if (this.editableSettings != null) {
        this.editableSettings = null;
      }
      this.editMode = this.isEdit;

      this.editableSettings = {};
      var settings = {
        id: this.unit && this.unit.id ? this.unit.id : null,
        title: this.unit.title,
        tags: this.unit.tags,
        description: this.unit.description,
      };
      Object.assign(this.editableSettings, settings);
      this.changes = 0;
      this.resetCount++;
    },
    removeTag(item) {
      debugger;
      var i = this.editableSettings.tags.indexOf(item);
      this.editableSettings.tags.splice(i, 1);
      this.editableSettings.tags = [...this.editableSettings.tags];
      this.change();
    },

    removeUnit() {
      var ok = window.confirm(
        `Are you sure you want to remove this ${this.$getTerm(
          "unit"
        )}? This action cannot be undone.`
      );
      debugger;
      if (ok) {
        this.$emit("remove", this.editableSettings.id);
      }
    },
    update() {
      this.$emit("update", this.editableSettings);
      /*
            this.$post("frameworks/update", this.editableSettings).then((response)=>{
                debugger;
                if(response.error){

                     this.$emit('error', this.errorText(response.text, 'save',this.$getTerm('framework')))
                }
                else{
                    this.$store.dispatch('saved/get_frameworks')
                    this.changes = 0;
                    this.$emit('update', 'Changes Saved')
                }
                this.saving = false;
            });
            */
    },
  },
  props: {
    unit: {
      type: Object,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
