/* eslint-disable */
import config from '../../configs/template.js';
import staticData from "../../objects/staticData";

import terminology from '../../configs/terminology.js';
const org = process.env.VUE_APP_ORG;
//const markDownLinkExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)gi;
const expression = /\[([\w\s\d]+)\]\(((?:\/|https?:\/\/|[\w\d]+)[\w\d./?=#]+)\)/gi;
const labelExp = /\[([\w\s\d]+)\]/i;
const urlExp = /\(((?:\/|https?:\/\/|[\w\d]+)[\w\d./?=#]+)\)/i;
//const markdownRegex = new RegExp(markDownLinkExp);
const linkRegex = new RegExp(expression);
const labelRegex = new RegExp(labelExp);
const urlRegex = new RegExp(urlExp);
const globalMixins = {
  methods: {
    parseMarkdown(str) {
      if(str){
        debugger;
        console.log(str);
        var text = str; 
  
        var links = str.match(linkRegex);
        var formattedLinks = []; 
        if(links && links.length){
          links.forEach(link => {
            debugger;
            var label = link.match(labelRegex);
            var url = link.match(urlRegex);
            if(!url[1].includes('//') ){
              url[1] = `https://${url[1]}`
            }
            var formattedLink = `<a href="${url[1]}" target="_blank">${label[1]}</a>`;
            console.log(formattedLink);
            formattedLinks.push(formattedLink)
          });
          links.forEach((link,i) => {
            debugger;
            console.log('replace' + formattedLinks[i])
            text = text.replace(link, formattedLinks[i]);
            console.log(text)
          });
        }
        
        return text;
      }
      return null; 
    },
    sameDate(one, two){
      var a = new Date(one);
      var b = new Date(two);
      var aDate = a.getDate();
      var bDate = b.getDate();
      if(aDate == bDate){
        var aMonth = a.getMonth();
        var bMonth = b.getMonth();
        if(aMonth == bMonth){
          var aYear = a.getFullYear();
          var bYear = b.getFullYear();
          if(aYear == bYear){
            return true; 
          }
        }
      }
      return false; 
    },
   intersect(a, b) {
      var setA = new Set(a);
      var setB = new Set(b);
      var intersection = new Set([...setA].filter(x => setB.has(x)));
      return Array.from(intersection);
    },
    truncateString(str, num, ellipses){
      if(ellipses){
        num = num-3;
      }
      var len = str ? str.length : 0; 
      var result = str; 
      if(len > num){
        result = str.slice(0,num);
        result = ellipses ? result + '...' : result; 
      }
      return result; 
    },
   getTerm (value, plural) {
      //debugger
      if (!value) return "";
      
      var arr = value.split(' ');
      var result  = '';
      arr.forEach(v=>{
        //debugger;
        var upperCase = v[0] == v[0].toUpperCase(); 
        var lcValue = v.toLowerCase();
        var term = terminology[org] && terminology[org][lcValue] ?  terminology[org][lcValue] : (terminology.default[lcValue] != null ? terminology.default[lcValue] : v) ;
        if(upperCase){
          term = term[0].toUpperCase() + term.substring(1);
        }
        else{
          if(v.toUpperCase() != v){
            term = term.toLowerCase(); 
    
          }
        }
        result = `${result} ${term}`;
        })
      return plural ? this.pluralize(result.trim()) : result.trim();
    },
    getPronoun(pronouns, key){
      if(!pronouns){
        pronouns =  this.getPronounOptions().find(x=>x.name == 'neutral').value;

      }
      return pronouns[key] ? pronouns[key] : 'the';
     
    },
    expired(date) {
      var now = Date.now();
      var expiry = new Date(date);
      return now > expiry;
    },
    getPronounOptions() {
      debugger;
      var result = [];
      var p = staticData.gender;
      var customOption = {};
      Object.entries(p).forEach((form, index) => {
        customOption[form[0]] = null;
      });

      result.push({
        label: "Create Custom",
        name: "custom",
        value: customOption,
      });

      Object.entries(p.nominative).forEach((item, i) => {
        var pronounSet = {};
        Object.entries(p).forEach((form, index) => {
          pronounSet[form[0]] = p[form[0]][item[0]];
        });

        var option = {
          value: pronounSet,
          label: item[1] + "/" + staticData.gender.accusative[item[0]],
          name: item[0],
        };
        result.push(option);
      });
      return result;
    },
    getSkillProfile(reportData, skills){
      var result =[];

      if(reportData){
       var t = this;
       var data = t.$r.clone(reportData);
       if(data && data.length && skills.length){
           skills.forEach(s=>{
               //debugger;
               var matches = data.filter(x=>x.rubricObjectiveTags.includes(s.name));
               var score = matches.reduce(function (total, currentValue) {
                   return currentValue.percentage ? total + currentValue.percentage/100 : total;
               }, 0);
              if(score > 0){
                 result.push({skill: s, matches: matches, score: score*100/matches.filter(x=>x.value > 0).length});

                 //  result.push({skill: s, matches: matches, score: score*100/matches.length});
               }
           });
           if(skills.length != 0){
               result = result.sort(function(a,b) {return a.score > b.score ? -1 : 1});
           }

       }
       }
     
        return result;  
    },
    getMembership(group, personid, prioitizeAdmin){
      //debugger;
      var membership = group ? group.members.filter(x=> x.individualId == personid && x.active) : [];
      membership.sort((a,b)=> {
        if(prioitizeAdmin){
          return (a.admin === b.admin)? 0 : a.admin? -1 : 1;
        }
        return (a.admin === b.admin)? 0 : b.admin? -1 : 1;
      });
      return membership.length ? membership[0] : null; 
    },
    chunkArray(arr, size, out){
      //debugger;
    // if the output array hasn't been passed in
    // create it
      out = out || [];

      // if there are no elements in the input array
      // return the output array
      if (!arr.length) return out;

      // push the "head" of the input array to the
      // output array
      out.push(arr.slice(0, size));

      // call chunk again with the "tail" of the input array
      return this.chunkArray(arr.slice(size), size, out);
    },
    groupTypeRoute:function(name){
     return this.pluralize(name.toLowerCase().replace(/ /g, "-"));
    },
    upsert: function (array, item) {
      // (1)
      const i = array.findIndex((_item) => _item === item);
      if (i > -1) array[i] = item;
      // (2)
      else array.push(item);
      return array;
    },
    round: function(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    },
    getImgUrl(pet) {
      var images = require.context(
        "../images/" + process.env.VUE_APP_ORG + "/",
        false,
        /\.png$/
      );
      return images("./" + pet + ".png");
    },
    getInitials:function(name){
      var arr = name ? name.split(" ") : [];
      var initials = "";
      arr.forEach((element, i) => {
        if (i <= 1) {
          initials += element.substring(0, 1);
        }
      });
      return initials;
    },
   
    getPersonChip: function (person) {
      var name = this.getDisplayName(person);
      var arr = name ? name.split(" ") : [];
      var initials = "";
      arr.forEach((element, i) => {
        if (i <= 1) {
          initials += element.substring(0, 1);
        }
      });
      var gravatar;
      if (person.emails && person.emails.length) {
        var email = person.emails.find((x) => x.primary) ?? person.emails[0];
        gravatar = email.address != null ? this.getGravatar(email.address) : null;
      }
      var result = {
        name: name,
        gravatar: gravatar,
        initials: initials,
      };
      return result;
    },
    MD5: function (s) {
        function L(k, d) {
          return (k << d) | (k >>> (32 - d));
        }
        function K(G, k) {
          var I, d, F, H, x;
          F = G & 2147483648;
          H = k & 2147483648;
          I = G & 1073741824;
          d = k & 1073741824;
          x = (G & 1073741823) + (k & 1073741823);
          if (I & d) {
            return x ^ 2147483648 ^ F ^ H;
          }
          if (I | d) {
            if (x & 1073741824) {
              return x ^ 3221225472 ^ F ^ H;
            } else {
              return x ^ 1073741824 ^ F ^ H;
            }
          } else {
            return x ^ F ^ H;
          }
        }
        function r(d, F, k) {
          return (d & F) | (~d & k);
        }
        function q(d, F, k) {
          return (d & k) | (F & ~k);
        }
        function p(d, F, k) {
          return d ^ F ^ k;
        }
        function n(d, F, k) {
          return F ^ (d | ~k);
        }
        function u(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(r(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function f(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(q(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function D(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(p(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function t(G, F, aa, Z, k, H, I) {
          G = K(G, K(K(n(F, aa, Z), k), I));
          return K(L(G, H), F);
        }
        function e(G) {
          var Z;
          var F = G.length;
          var x = F + 8;
          var k = (x - (x % 64)) / 64;
          var I = (k + 1) * 16;
          var aa = Array(I - 1);
          var d = 0;
          var H = 0;
          while (H < F) {
            Z = (H - (H % 4)) / 4;
            d = (H % 4) * 8;
            aa[Z] = aa[Z] | (G.charCodeAt(H) << d);
            H++;
          }
          Z = (H - (H % 4)) / 4;
          d = (H % 4) * 8;
          aa[Z] = aa[Z] | (128 << d);
          aa[I - 2] = F << 3;
          aa[I - 1] = F >>> 29;
          return aa;
        }
        function B(x) {
          var k = "",
            F = "",
            G,
            d;
          for (d = 0; d <= 3; d++) {
            G = (x >>> (d * 8)) & 255;
            F = "0" + G.toString(16);
            k = k + F.substr(F.length - 2, 2);
          }
          return k;
        }
        function J(k) {
          k = k.replace(/rn/g, "n");
          var d = "";
          for (var F = 0; F < k.length; F++) {
            var x = k.charCodeAt(F);
            if (x < 128) {
              d += String.fromCharCode(x);
            } else {
              if (x > 127 && x < 2048) {
                d += String.fromCharCode((x >> 6) | 192);
                d += String.fromCharCode((x & 63) | 128);
              } else {
                d += String.fromCharCode((x >> 12) | 224);
                d += String.fromCharCode(((x >> 6) & 63) | 128);
                d += String.fromCharCode((x & 63) | 128);
              }
            }
          }
          return d;
        }
        var C = Array();
        var P, h, E, v, g, Y, X, W, V;
        var S = 7,
          Q = 12,
          N = 17,
          M = 22;
        var A = 5,
          z = 9,
          y = 14,
          w = 20;
        var o = 4,
          m = 11,
          l = 16,
          j = 23;
        var U = 6,
          T = 10,
          R = 15,
          O = 21;
        s = J(s);
        C = e(s);
        Y = 1732584193;
        X = 4023233417;
        W = 2562383102;
        V = 271733878;
        for (P = 0; P < C.length; P += 16) {
          h = Y;
          E = X;
          v = W;
          g = V;
          Y = u(Y, X, W, V, C[P + 0], S, 3614090360);
          V = u(V, Y, X, W, C[P + 1], Q, 3905402710);
          W = u(W, V, Y, X, C[P + 2], N, 606105819);
          X = u(X, W, V, Y, C[P + 3], M, 3250441966);
          Y = u(Y, X, W, V, C[P + 4], S, 4118548399);
          V = u(V, Y, X, W, C[P + 5], Q, 1200080426);
          W = u(W, V, Y, X, C[P + 6], N, 2821735955);
          X = u(X, W, V, Y, C[P + 7], M, 4249261313);
          Y = u(Y, X, W, V, C[P + 8], S, 1770035416);
          V = u(V, Y, X, W, C[P + 9], Q, 2336552879);
          W = u(W, V, Y, X, C[P + 10], N, 4294925233);
          X = u(X, W, V, Y, C[P + 11], M, 2304563134);
          Y = u(Y, X, W, V, C[P + 12], S, 1804603682);
          V = u(V, Y, X, W, C[P + 13], Q, 4254626195);
          W = u(W, V, Y, X, C[P + 14], N, 2792965006);
          X = u(X, W, V, Y, C[P + 15], M, 1236535329);
          Y = f(Y, X, W, V, C[P + 1], A, 4129170786);
          V = f(V, Y, X, W, C[P + 6], z, 3225465664);
          W = f(W, V, Y, X, C[P + 11], y, 643717713);
          X = f(X, W, V, Y, C[P + 0], w, 3921069994);
          Y = f(Y, X, W, V, C[P + 5], A, 3593408605);
          V = f(V, Y, X, W, C[P + 10], z, 38016083);
          W = f(W, V, Y, X, C[P + 15], y, 3634488961);
          X = f(X, W, V, Y, C[P + 4], w, 3889429448);
          Y = f(Y, X, W, V, C[P + 9], A, 568446438);
          V = f(V, Y, X, W, C[P + 14], z, 3275163606);
          W = f(W, V, Y, X, C[P + 3], y, 4107603335);
          X = f(X, W, V, Y, C[P + 8], w, 1163531501);
          Y = f(Y, X, W, V, C[P + 13], A, 2850285829);
          V = f(V, Y, X, W, C[P + 2], z, 4243563512);
          W = f(W, V, Y, X, C[P + 7], y, 1735328473);
          X = f(X, W, V, Y, C[P + 12], w, 2368359562);
          Y = D(Y, X, W, V, C[P + 5], o, 4294588738);
          V = D(V, Y, X, W, C[P + 8], m, 2272392833);
          W = D(W, V, Y, X, C[P + 11], l, 1839030562);
          X = D(X, W, V, Y, C[P + 14], j, 4259657740);
          Y = D(Y, X, W, V, C[P + 1], o, 2763975236);
          V = D(V, Y, X, W, C[P + 4], m, 1272893353);
          W = D(W, V, Y, X, C[P + 7], l, 4139469664);
          X = D(X, W, V, Y, C[P + 10], j, 3200236656);
          Y = D(Y, X, W, V, C[P + 13], o, 681279174);
          V = D(V, Y, X, W, C[P + 0], m, 3936430074);
          W = D(W, V, Y, X, C[P + 3], l, 3572445317);
          X = D(X, W, V, Y, C[P + 6], j, 76029189);
          Y = D(Y, X, W, V, C[P + 9], o, 3654602809);
          V = D(V, Y, X, W, C[P + 12], m, 3873151461);
          W = D(W, V, Y, X, C[P + 15], l, 530742520);
          X = D(X, W, V, Y, C[P + 2], j, 3299628645);
          Y = t(Y, X, W, V, C[P + 0], U, 4096336452);
          V = t(V, Y, X, W, C[P + 7], T, 1126891415);
          W = t(W, V, Y, X, C[P + 14], R, 2878612391);
          X = t(X, W, V, Y, C[P + 5], O, 4237533241);
          Y = t(Y, X, W, V, C[P + 12], U, 1700485571);
          V = t(V, Y, X, W, C[P + 3], T, 2399980690);
          W = t(W, V, Y, X, C[P + 10], R, 4293915773);
          X = t(X, W, V, Y, C[P + 1], O, 2240044497);
          Y = t(Y, X, W, V, C[P + 8], U, 1873313359);
          V = t(V, Y, X, W, C[P + 15], T, 4264355552);
          W = t(W, V, Y, X, C[P + 6], R, 2734768916);
          X = t(X, W, V, Y, C[P + 13], O, 1309151649);
          Y = t(Y, X, W, V, C[P + 4], U, 4149444226);
          V = t(V, Y, X, W, C[P + 11], T, 3174756917);
          W = t(W, V, Y, X, C[P + 2], R, 718787259);
          X = t(X, W, V, Y, C[P + 9], O, 3951481745);
          Y = K(Y, h);
          X = K(X, E);
          W = K(W, v);
          V = K(V, g);
        }
        var i = B(Y) + B(X) + B(W) + B(V);
        return i.toLowerCase();
    

     
    },
    getGravatar: function (email, size) {
      if(!email){
        return null;
      }
      var rsize = size || 80;
      var emailHash = email ? this.MD5(email) : '';
      return (
        "https://www.gravatar.com/avatar/" +
       emailHash +
        ".jpg?s=" +
        rsize +
        "&d=mp"
      );
    },
    getIndirectArticle(value){
      
      if (!value) return "a";
      value = value.toString();
      var vowels = ['a','e','i','o','u'];
      if (vowels.includes(value.toLowerCase().charAt(0))) {
        return 'an';
      }
      return 'a';
    },
    convertToString(input) {
  
      if(input) { 
        
          if(typeof input === "string") {

                return input;
            }
          
          return String(input);
      }
      return '';
    },
    // CSV actions
    convertToCSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';
  
      for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
              if (line != '') line += ','
  
              line += array[i][index];
          }
  
          str += line + '\r\n';
      }
  
      return str;
  },
  exportCSVFile(headers, items, fileTitle) {
    //debugger;
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  },
// convert string to words
    toWords(input) {							
      
      input = this.convertToString(input);
      
      var regex = /[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g;
      
      return input.match(regex);
      
    },
    toTitleCase(str) {
      return str.replace(
          /\w\S*/g,
          function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
      );
  },

// convert the input array to camel case
    toCamelCase(inputArray) {

        let result = "";

        for(let i = 0 , len = inputArray.length; i < len; i++) {

          let currentStr = inputArray[i];
      
          let tempStr = currentStr.toLowerCase();

          if(i != 0) {
      
            // convert first letter to upper case (the word is in lowercase) 
              tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);

          }
          
          result +=tempStr;
          
        }
      
        return result;
    },
  unCamel (value) {
  if (!value) return "";
  value = value.toString();
  var result = value.replace( /([A-Z])/g, " $1" );
 return result.charAt(0).toUpperCase() + result.slice(1);
},

    errorText(text, action, item){
      if(text.toLowerCase().includes('network error')){
        return `Network Unavailable: Could not ${action} ${item}.`
      }
      if(text.toLowerCase().includes('authoriz')){
        return `Unauthorized: Could not ${action} ${item}.`
      }
      return `Something went wrong: Could not ${action} ${item}.`
    },
    goToAssessment(e, name, props, tab){
      var path = {
        name: name, 
        params: {
          ...props,
          assessmentid: e.id
        },
        
      }
      console.log(path)
      this.resolveRoute(path, tab);
    },
    goToPerson(e, name, props, tab){
    //  //debugger;
      var path = {
        name: name, 
        params: {
          ...props,
          personid: e.id
        }
      }
      console.log(path)
      this.resolveRoute(path, tab);
    },
    goToGroup(e, tab){
      if(!e.readonly){
          var path = {
          name: 'GroupHome',
          params: {
              groupid: e.id
          }
      }
        this.resolveRoute(path, tab);
      }
    },
    resolveRoute(path, tab){
      //debugger;
      let routeData = this.$router.resolve(path);

      if(tab == true){
        window.open(routeData.href, '_blank');
      }
      else{
        window.location.href =routeData.href ;

      }
    },
    parseDate(date){
      var months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
      var day = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
     return date ? `${date.getFullYear()}-${months[date.getMonth()]}-${day[date.getDate()]}` : '2000-01-01';
    },
    getLastYear(){
      var endDate = new Date();
      var startDate = new Date();
      startDate.setDate(startDate.getDate() - 364);
      endDate.setDate(endDate.getDate() + 1);
      return [this.parseDate(startDate), this.parseDate(endDate)] 
    },
    goToPerson(e, tab){
      if(!e.readonly){
        //debugger;
          var path = {
          name: 'PersonHome',
          params: {
            individualid: e.id
          }
      }
      this.resolveRoute(path, tab);
      }
    },
    goToFramework(e, tab){
      if(!e.readonly){
        //debugger;
          var path = {
          name: 'FrameworkHome',
          params: {
            frameworkid: e.id
          }
      }
      this.resolveRoute(path, tab);
      }
    },
    goToRubric(e, tab){
      if(!e.readonly){
        //debugger;
          var path = {
          name: 'RubricHome',
          params: {
            rubricid: e.id
          }
      }
      this.resolveRoute(path, tab);
      }
    },
    toCamelCaseString(input) {						
      
      let words = this.toWords(input);
      
      return this.toCamelCase(words);
      
    },
    toPastTense(string){
      if(string != null){
        if(string[string.length -1] == 'e'){
          return string + 'd'
        }
        return string + 'ed'
      }
      return string; 
    },
    sameDay(a,b){
      var aDate = new Date(a);
      var bDate = new Date(b);
      var yearMatch = aDate.getFullYear() == bDate.getFullYear();
      var monthMatch = aDate.getMonth() == bDate.getMonth(); 
      var dayMatch = aDate.getDate() == bDate.getDate(); 
      return yearMatch && monthMatch && dayMatch;
    },
    getGravatarLogo: function (email, size) {
      var rsize = size || 80;
      var emailHash = this.MD5(email);
      //debugger;
      var urlencode = encodeURIComponent(window.location.origin + "/images/" + process.env.VUE_APP_ORG + "/icon.png");
      console.log(urlencode);
      return (
        "https://www.gravatar.com/avatar/" +
       emailHash +
        ".jpg?s=" +
        rsize +
        "&d=" + urlencode
      );
    },
    getDisplayName: function (person) {
      var name = null;
      if(person.nickname || person.firstName && person.lastName){
        name =  (person.nickname ?? person.firstName) + " " + person.lastName;
      }
      return name;
    },
    validateEmail: function (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    pluralize: function (value) {
      if (!value) return "";
      value = value.toString();
      var arr = [];
      if(value.includes(' of ')){
        arr = value.split(' ');
      }
      else{
        arr = [value];
      }
      var suffix = "s";
      if (arr[0].charAt(arr[0].length - 1) == "s") {
        suffix = ""//"es";
      }
      if (arr[0].charAt(arr[0].length - 1) == "y") {
        arr[0] = arr[0].substring(0, arr[0].length - 1);
        suffix = "ies";
      }
      if (arr[0].charAt(arr[0].length - 1) == "x") {
        suffix = "es";
      }
      if (arr[0].charAt(arr[0].length - 1) == ")") {
        suffix = "";
      }
      arr[0] = arr[0] + suffix;
      return arr.join(' ');
    },
    singularize: function (value) {
      if (
        value &&
        value.charAt(value.length - 1) == "s" &&
        value.charAt(value.length - 2) == "e" &&
        value.charAt(value.length - 3) == "i"
      ) {
        return value.substring(0, value.length - 3) + "y";
      }
      if (value && value.charAt(value.length - 1) == "s") {
        return value.substring(0, value.length - 1);
      }
      return value ?? "";
    },
    getPronouns: function (gender, form = "nominative") {
      var g = gender.toLowerCase();
      const masc = ["masc", "male", "man", "FTM"];
      const femme = ["femme", "female", "woman", "MTF"];

      var isMasc = masc.some((substring) => gender.includes(substring));
      var isFemme = femme.some((substring) => gender.includes(substring));
      //gender contains male but not female
      if (isMasc && !isFemme) {
        return gender[form].masculine;
      }
      //gender contains female but not male
      if (isFemme && !isMasc) {
        return gender[form].feminine;
      }
      //gender contains both male and female
      if (isFemme && isMasc) {
        var array = g.split(" ").reverse();
        array.forEach((x) => {
          if (masc.some((substring) => x.includes(substring))) {
            return gender[form].masculine;
          }
          if (femme.some((substring) => x.includes(substring))) {
            return gender[form].femme;
          }
        });
      }
      return gender[form].neutral;
    },
  },
};

export default globalMixins;
