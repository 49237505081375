var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"value":_vm.selectedAssessments,"disable-sort":"","server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50, 100] },"show-select":_vm.selectable && _vm.totalItems <= 100},on:{"update:options":function($event){_vm.options=$event},"input":_vm.update},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36"}},[_c('v-img',{attrs:{"src":item.picture}})],1)]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created,_vm.app_info.shortDate))+" ")])]}},{key:"item.groupId",fn:function(ref){
var item = ref.item;
return [_c('sr-group-chip',{attrs:{"flat":"","newWindow":"","path":"","id":item.groupId}})]}},{key:"item.frameworkId",fn:function(ref){
var item = ref.item;
return [_c('sr-framework-chip',{attrs:{"flat":"","newWindow":"","path":"","id":item.frameworkId}})]}},{key:"item.nodes",fn:function(ref){
var item = ref.item;
return _vm._l((item.nodes),function(n){return _c('sr-group-chip',{key:n,attrs:{"flat":"","newWindow":"","path":"","id":n}})})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goToAssessment(item, _vm.linkTarget, null, true)}}},[_c('v-icon',[_vm._v("fal fa-angle-right")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }