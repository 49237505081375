const layout ={
 
    default:{
        app: {
            sidebarMenu: {
                menuItems:[
                    {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'Home'},
                        icon: 'fad fa-home',
                        to: {name: 'Home'}
                    },
                  {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'People'},
                        icon: 'fad fa-user',
                        to: {name: 'People'}
                    },
                    {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'Assessments'},
                        icon: 'fad fa-edit',
                        to: {name: 'Assessments'}
                    },
                   
                    {
                        showFn: ()=>{return true},
                        title: (prop)=>{ return prop.isAdministrator ? 'All Groups' : 'Groups'},
                        icon: 'fad fa-building',
                        to: {name: 'AllGroups'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Reports' },
                        icon: 'fad fa-chart-bar',
                        to: {name: 'Report'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAuthAdmin},
                        title: ()=>{ return 'Organisation' },
                        icon: 'fad fa-layer-group',
                        to: {name: 'GroupType'}
                    },
                     /*
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Rubrics'},
                        icon: 'fad fa-th',
                        to: {name: 'Rubrics'}
                    },
                    */
                    {
                        showFn: (prop)=>{return prop.isAuthor },
                        title: ()=>{ return 'Frameworks'},
                        icon: 'fad fa-books',
                        to: {name: 'AllFrameworks'}
                    },
                    /*
                    {
                        title: ()=>{ return 'Help'},
                        icon: 'fad fa-question',
                        hrefFn: (prop)=>{return prop.help}
                    }
                    */
                ],
                showBookmarks: false,
                showNavHistory: false,
            },
            navBar: {
                color: 'primary',
            },
            footer:{

            }
        },
        home: 
            {
                type: 'v-container',
                attrs:{
                    id: 'Dashboard',
                },
                props: {fluid: true},
                showFn: ()=>true,
                children:[
                    {
                        type: 'sr-breadcrumbs',
                        showFn: ()=>false,
                        props:{
                            items: [{text: 'Home',to:{name: 'Home'}, disabled: true}]
                        },
                    },
                    {
                        type: 'v-row',
                        class: '',
                        props: null,
                        showFn: ()=>true,
                        children:[
                            {
                                type: 'v-col',
                                class: ' px-2',
                                attrs:{
                                    id: 'Center',
                                },
                                props: null,
                                showFn: ()=>true,
                                children:[
                                  
                                   
                                    
                                    {
                                        type: 'v-row',
                                        props: null,
                                        showFn: ()=>true,
                                        children:[
                                           
                                            {
                                                type: 'v-col',
                                                class: 'col-md-4 ',
                                                props: null,
                                                showFn:()=>true,
                                                children:[
                                                    {
                                                        showFn:()=>{return true},
                                                        type: 'sr-people-widget',
                                                        class: 'mb-4', 
                                                        props:{
                                                            
                                                        }
                                                    },
                                                    {
                                                        showFn:()=>{return true},
                                                        type: 'sr-group-widget',
                                                        class: 'mb-4', 
                                                        props: {
                                                            dispatch: false,
                                                            admin: false,
                                                            showAll: true,
                                                            asTable: true,
                                                            allowFilter: false,
                                                            filterValues:{
                                                                selectedGroupTypes: ["5f4e4d28d4298b4e9858e9a2"],
                                                                selectedParents: ["609bcb000128361c3806ce28"],
                                                                showOnlyDirect: false,
                                                                showOnlyAdmin: false,
                                                                showOnlyNonAdmin: false,
                
                                                            },
                                                            title: 'Departments',
                                                            small: true,
                                                            headers:[
                                                                {
                                                                    text: 'Name',
                                                                    align: 'start',
                                                                    sortable: true,
                                                                    value: 'displayName'
                                                                },
                                                        
                                                                {
                                                                    text: 'School',
                                                                    align: 'left',
                                                                    value: 'location'
                                                                },
                                                                {
                                                                    text: '',
                                                                    align: 'right',
                                                                    value: 'action'
                                                                }
                                                            ]
                                                        }
                                                    },
                                                ]

                                            },
                                             
                                            {
                                                type: 'v-col',
                                                class: 'col-md-8',
                                                props: null,
                                                showFn: ()=>true,
                                                children:[
                                                    {
                                                        showFn:()=>true,
                                                        type: 'sr-open-assessments',
                                                        props: {
                                                            headers:[
                                                                {
                                                                    text: 'Date',
                                                                    value: 'created',
                                                                    sortable: true
                                                                },
                                                                {
                                                                    text: 'Title',
                                                                    value: 'assessmentTitle',
                                                                },
                                                                {
                                                                    text: 'Group',
                                                                    value: 'groupId'
                                                                },
                                                                {
                                                                    text: '',
                                                                    value: 'actions'
                                                                },
                                                            ]
                                                        }
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                   
                                ]
                            },
                            {
                                type: 'v-col',
                                class: 'col-sm-12 col-md-4',
                                props: null,
                                showFn: ()=>false,
                                children:[
                                    {
                                        showFn:()=>true,
                                        type: 'sr-timeline',
                                        props: null
                                    },
                                ]
                            }
                        ]
                    }
                ]

            },
    
    },
    isp:{
        app: {
            sidebarMenu: {
                menuItems:[
                
                    {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'Home'},
                        icon: 'fad fa-home',
                        to: {name: 'Home'}
                    },
                  {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'People'},
                        icon: 'fad fa-users',
                        to: {name: 'People'}
                    },
                    {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'Evaluations'},
                        icon: 'fad fa-edit',
                        to: {name: 'Assessments'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Archived Evaluations'},
                        icon: 'fad fa-archive',
                        to: {name: 'ArchivedAssessments', params: 
                           { headers: [
                                {
                                  text: "Date",
                                  value: "created",
                                  sortable: true,
                                  sort: (a, b) => {
                                    var aDate = new Date(a);
                                    var bDate = new Date(b);
                                    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
                                  },
                                },
                                {
                                  text: "Title",
                                  value: "assessmentTitle",
                                },
                                {
                                  text: "Rubric",
                                  value: "rubricTitle",
                                },
                                {
                                  text: "Group",
                                  value: "groupId",
                                },
                                {
                                    text: "",
                                    value: "actions",
                                  },
                            ]}
                   }
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Add Group'},
                        icon: 'fad fa-plus',
                        to: {name: 'CreateGroup'}
                    },
                    {
                        showFn: ()=>{return true},
                        title: (prop)=>{ return prop.isAdministrator ? 'All Groups' : 'Groups'},
                        icon: 'fad fa-building',
                        to: {name: 'AllGroups'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Reports' },
                        icon: 'fad fa-chart-bar',
                        to: {name: 'Report'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAuthAdmin},
                        title: ()=>{ return 'Organisation' },
                        icon: 'fad fa-layer-group',
                        to: {name: 'GroupType'}
                    },
                     /*
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Rubrics'},
                        icon: 'fad fa-th',
                        to: {name: 'Rubrics'}
                    },
                    */
                    {
                        showFn: (prop)=>{return prop.isAuthor },
                        title: ()=>{ return 'Frameworks'},
                        icon: 'fad fa-books',
                        to: {name: 'AllFrameworks'}
                    },
                    /*
                    {
                        title: ()=>{ return 'Help'},
                        icon: 'fad fa-question',
                        hrefFn: (prop)=>{return prop.help}
                    }
                    */
                ],
                showBookmarks: false,
                showNavHistory: false,
            },
            navBar: {
                color: 'secondary',
                dark: false
            },
            footer:{

            }
        },
        home: 
            {
                type: 'v-container',
                attrs:{
                    id: 'Dashboard',
                },
                props: {fluid: true},
                showFn: ()=>true,
                children:[
                    {
                        type: 'sr-breadcrumbs',
                        showFn: ()=>false,
                        props:{
                            items: [{text: 'Home',to:{name: 'Home'}, disabled: true}]
                        },
                    },
                    {
                        type: 'v-row',
                        class: '',
                        props: null, 
                        showFn: ()=>true,
                        children:[
                            {
                                type: 'v-col',
                                class: 'col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 px-2',
                                attrs:{
                                    id: 'Center',
                                },
                                props: null,
                                showFn: ()=>false,
                                children:[
                                    {
                                        showFn:()=>{return true},
                                        type: 'sr-group-widget',
                                        class: 'mb-4', 
                                        props: {
                                            dispatch: false,
                                            admin: false,
                                            showAll: true,
                                            asTable: true,
                                            allowFilter: true,
                                            hideHeaders:false,
                                            
                                            query:{
                                                groupTypes: ["5f4e4d28d4298b4e9858e9a2"],
                                                parents: ["609bcb000128361c3806ce28"],
                                                showOnlyDirect: false,
                                                showOnlyAdmin: false,
                                                showOnlyNonAdmin: false,

                                            },
                                            title: 'Admin Departments',
                                            small: true,
                                            headers:[
                                                {
                                                    text: 'Name',
                                                    align: 'start',
                                                    sortable: true,
                                                    value: 'displayName'
                                                },
                                                {
                                                    text: 'School',
                                                    align: 'start',
                                                    sortable: true,
                                                    value: 'location'
                                                },
                                              
                                                {
                                                    text: '',
                                                    align: 'right',
                                                    value: 'action'
                                                }
                                            ]
                                        }
                                    },
                                ]
                            },
                            {
                                type: 'v-col',
                                class: ' px-2',
                                attrs:{
                                    id: 'Center',
                                },
                                props: null,
                                showFn: ()=>true,
                                children:[
                                    {
                                        showFn:()=> true,
                                        type: 'sr-open-assessments',
                                        props: {
                                            query:{
                                                groupTypes: [],
                                                showOnlyDirect: false,
                                                showOnlyNonDirect: false,
                                                showOnlyAdmin: false,
                                                showOnlyNonAdmin: false,
                                                onlyUnlocked: true,
                                                onlyUnarchived: true,
                                            },
                                            headers:[
                                                {
                                                    text: 'Date',
                                                    value: 'created',
                                                    sortable: true
                                                },
                                                {
                                                    text: 'Title',
                                                    value: 'assessmentTitle',
                                                    sortable: true

                                                },
                                                {
                                                    text: 'Group',
                                                    value: 'groupId',
                                                    sortable: false
                                                },
                                                {
                                                    text: 'Status',
                                                    value: 'complete',
                                                    sortable: false,
                                                   
                                                },
                                                {
                                                    text: '',
                                                    value: 'actions',
                                                    sortable: false
                                                },
                                            ]
                                        }
                                    },
                                    
                                ]
                            }
                        ]
                    },
                  
                ]

            },
    
    },
    designCEO:{
        app: {
            sidebarMenu: {
                menuItems:[
                    {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'Home'},
                        icon: 'fad fa-home',
                        to: {name: 'Home'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Add Company'},
                        icon: 'fad fa-plus',
                        to: {name: 'CreateGroup', query:{group:"5f577333a63ecf6e443a1d39", label: "Company", type: "5f57734ea63ecf6e443a1d3a" }}
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return  'All Companies' },
                        icon: 'fad fa-building',
                        to: {name: 'AllGroups', query: {type: "5f57734ea63ecf6e443a1d3a", hidepath: true, title: 'Clients'}}
                    },
                  {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'People'},
                        icon: 'fad fa-user',
                        to: {name: 'People'}
                    },
                 
                    {
                        showFn: ()=>{return true},
                        title: (prop)=>{ return prop.isAdministrator ? 'Evaluations': 'My Evaluations'},
                        icon: 'fad fa-edit',
                        to: {name: 'Assessments'}
                    },
                   
                 
                    {
                        showFn: (prop)=>{return !prop.isAdministrator},
                        title: ()=>{ return  'My Groups' },
                        icon: 'fad fa-building',
                        to: {name: 'ProfileGroups'}
                    },
                    {
                        showFn: (prop)=>{return !prop.isAdministrator},
                        title: ()=>{ return  'Profile' },
                        icon: 'fad fa-user',
                        to: {name: 'ProfileHome'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Reports' },
                        icon: 'fad fa-chart-bar',
                        to: {name: 'Report'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAuthAdmin},
                        title: ()=>{ return 'Organisation' },
                        icon: 'fad fa-layer-group',
                        to: {name: 'GroupType'}
                    },
                     /*
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Rubrics'},
                        icon: 'fad fa-th',
                        to: {name: 'Rubrics'}
                    },
                    */
                    {
                        showFn: (prop)=>{return prop.isAuthor },
                        title: ()=>{ return 'Frameworks'},
                        icon: 'fad fa-books',
                        to: {name: 'AllFrameworks'}
                    },
                    /*
                    {
                        title: ()=>{ return 'Help'},
                        icon: 'fad fa-question',
                        hrefFn: (prop)=>{return prop.help}
                    }
                    */
                ],
                showBookmarks: false,
                showNavHistory: false,
            },
            navBar: {
                color: 'primary',
            },
            footer:{

            }
        },
        home: 
            {
                type: 'v-container',
                attrs:{
                    id: 'Dashboard',
                },
                props: {fluid: true},
                showFn: ()=>true,
                children:[
                    {
                        type: 'sr-breadcrumbs',
                        showFn: ()=>false,
                        props:{
                            items: [{text: 'Home',to:{name: 'Home'}, disabled: true}]
                        },
                    },
                   
                    {
                        type: 'v-row',
                        class: '',
                        props: null,
                        showFn: ()=>true,
                        children:[
                            {
                                type: 'v-col',
                                class: 'px-4',
                                attrs:{
                                    id: 'Center',
                                },
                                props: null,
                                showFn: ()=>true,
                                children:[
                                  
                                    {
                                        type: 'v-row',
                                        class: '',
                                        props: null,
                                        showFn: ()=>true,
                                        children: [
                                            {
                                                type: 'v-col',
                                                class: '',
                                                props: null,
                                                showFn: ()=>true,
                                                children: [
                                                    {
                                                        showFn:()=>{return true},
                                                        type: 'sr-group-widget',
                                                        class: 'mb-4', 
                                                        props: {
                                                            dispatch: true,
                                                            admin: false,
                                                            showAll: true,
                                                            asTable: true,
                                                            allowFilter: false,
                                                            hideToolbar: true,
                                                            hideHeaders:true,
                                                            filterValues:{
                                                                selectedParents: [],
                                                                selectedGroupTypes: [],
                                                                showOnlyDirect: true,
                                                                showOnlyAdmin: false,
                                                                showOnlyNonAdmin: false,
                
                                                            },
                                                            small: true,
                                                           
                                                        }
                                                    },
                                                ]
                                            }
                                        ]
                                    },
                                    
                                    {
                                        type: 'v-row',
                                        props: null,
                                        showFn: ()=>true,
                                        children:[
                                             
                                            {
                                                type: 'v-col',
                                                props: null,
                                                showFn: ()=>true,
                                                children:[
                                                    {
                                                        showFn:()=>true,
                                                        type: 'sr-open-assessments',
                                                        props: {
                                                            headers:[
                                                                {
                                                                    text: 'Date',
                                                                    value: 'created',
                                                                    sortable: true
                                                                },
                                                                {
                                                                    text: 'Title',
                                                                    value: 'assessmentTitle',
                                                                },
                                                                {
                                                                    text: 'Group',
                                                                    value: 'groupId'
                                                                },
                                                                {
                                                                    text: '',
                                                                    value: 'actions'
                                                                },
                                                            ]
                                                        }
                                                    },
                                                ]
                                            },
                                        ]
                                    },
                                   
                                ]
                            },
                            {
                                type: 'v-col',
                                class: 'col-sm-12 col-md-4',
                                props: null,
                                showFn: ()=>false,
                                children:[
                                    {
                                        showFn:()=>true,
                                        type: 'sr-timeline',
                                        props: null
                                    },
                                ]
                            }
                        ]
                    }
                ]

            },
    
    },
    fluid:{
        app: {
            sidebarMenu: {
                menuItems:[
                    {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'Home'},
                        icon: 'fad fa-home',
                        to: {name: 'Home'}
                    },
                  {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'People'},
                        icon: 'fad fa-user',
                        to: {name: 'People'}
                    },
                    {
                        showFn: ()=>{return true},
                        title: ()=>{ return 'Assessments'},
                        icon: 'fad fa-edit',
                        to: {name: 'Assessments'}
                    },
                   
                    {
                        showFn: ()=>{return true},
                        title: (prop)=>{ return prop.isAdministrator ? 'All Groups' : 'Groups'},
                        icon: 'fad fa-building',
                        to: {name: 'AllGroups'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Reports' },
                        icon: 'fad fa-chart-bar',
                        to: {name: 'Report'}
                    },
                    {
                        showFn: (prop)=>{return prop.isAuthAdmin},
                        title: ()=>{ return 'Organisation' },
                        icon: 'fad fa-layer-group',
                        to: {name: 'GroupType'}
                    },
                     /*
                    {
                        showFn: (prop)=>{return prop.isAdministrator},
                        title: ()=>{ return 'Rubrics'},
                        icon: 'fad fa-th',
                        to: {name: 'Rubrics'}
                    },
                    */
                    {
                        showFn: (prop)=>{return prop.isAuthor },
                        title: ()=>{ return 'Frameworks'},
                        icon: 'fad fa-books',
                        to: {name: 'AllFrameworks'}
                    },
                    /*
                    {
                        title: ()=>{ return 'Help'},
                        icon: 'fad fa-question',
                        hrefFn: (prop)=>{return prop.help}
                    }
                    */
                ],
                showBookmarks: false,
                showNavHistory: false,
            },
            navBar: {
                color: 'primary',
            },
            footer:{

            }
        },
        home: 
            {
                type: 'v-container',
                attrs:{
                    id: 'Dashboard',
                },
                props: {fluid: true},
                showFn: ()=>true,
                children:[
                    {
                        type: 'sr-breadcrumbs',
                        showFn: ()=>false,
                        props:{
                            items: [{text: 'Home',to:{name: 'Home'}, disabled: true}]
                        },
                    },
                    {
                        type: 'v-row',
                        class: 'px-2',
                        props: null,
                        showFn: ()=>true,
                        children:[
                            {
                                type: 'v-col',
                                class: ' px-2',
                                attrs:{
                                    id: 'Center',
                                },
                                props: null,
                                showFn: ()=>true,
                                children:[
                                    {
                                        type: 'v-row',
                                        props: null,
                                        showFn: ()=>true,
                                        children:[
                                           
                                            {
                                                type: 'v-col',
                                                class: '',
                                                props: null,
                                                showFn:()=>true,
                                                children:[
                                               
                                                    {
                                                        showFn:()=>{return true},
                                                        type: 'sr-group-widget',
                                                        class: 'mb-4', 
                                                        props: {
                                                            dispatch: false,
                                                            admin: false,
                                                            showAll: true,
                                                            asTable: true,
                                                            allowFilter: false,
                                                            showOpaque: true,

                                                            filterValues:{
                                                                selectedGroupTypes: ["6319f24bc5d30d8a62cfd158"],
                                                                selectedParents:[],
                                                                showOnlyDirect: false,
                                                                showOnlyAdmin: false,
                                                                showOnlyNonAdmin: false,
                                                            },
                                                            title: 'Schools',
                                                            small: true,
                                                            headers:[
                                                                {
                                                                    text: 'Name',
                                                                    align: 'start',
                                                                    sortable: true,
                                                                    value: 'displayName'
                                                                },
                                                                {
                                                                    text: '',
                                                                    align: 'right',
                                                                    value: 'action'
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    
                                                ]

                                            },
                                            {
                                                type: 'v-col',
                                                class: '',
                                                props: null,
                                                showFn:()=>true,
                                                children:[
                                             
                                                     {
                                                        showFn:()=>{return true},
                                                        type: 'sr-group-widget',
                                                        class: 'mb-4', 
                                                        props: {
                                                            dispatch: false,
                                                            admin: false,
                                                            showAll: true,
                                                            asTable: true,
                                                            allowFilter: false,
                                                            showOpaque: true,

                                                            filterValues:{
                                                                selectedGroupTypes: ["6319fb9cc5d30d8a62cfd15e"],
                                                                selectedParents:[],
                                                                showOnlyDirect: false,
                                                                showOnlyAdmin: false,
                                                                showOnlyNonAdmin: false,
                
                                                            },
                                                            title: 'Internal Projects',
                                                            small: true,
                                                            headers:[
                                                                {
                                                                    text: 'Name',
                                                                    align: 'start',
                                                                    sortable: true,
                                                                    value: 'displayName'
                                                                },
                                                              
                                                                {
                                                                    text: '',
                                                                    align: 'right',
                                                                    value: 'action'
                                                                }
                                                            ]
                                                        }
                                                    },
                                                ]

                                            },
                                           
                                            
                                        ]
                                    },
                                   {
                                    type: 'v-row',
                                    props: null,
                                    showFn: ()=>true,
                                    children:[
                                        
                                        {
                                        type: 'v-col',
                                        class: '',
                                        props: null,
                                        showFn: ()=>true,
                                        children:[
                                            {
                                                showFn:()=>true,
                                                type: 'sr-open-assessments',
                                                props: {
                                                    headers:[
                                                        {
                                                            text: 'Date',
                                                            value: 'created',
                                                            sortable: true
                                                        },
                                                        {
                                                            text: 'Title',
                                                            value: 'assessmentTitle',
                                                        },
                                                        {
                                                            text: 'Group',
                                                            value: 'groupId'
                                                        },
                                                        {
                                                            text: '',
                                                            value: 'actions'
                                                        },
                                                    ]
                                                }
                                            },
                                        ]
                                    },]
                                   }
                                ]
                            },
                            {
                                type: 'v-col',
                                class: 'col-sm-12 col-md-4',
                                props: null,
                                showFn: ()=>false,
                                children:[
                                    {
                                        showFn:()=>true,
                                        type: 'sr-timeline',
                                        props: null
                                    },
                                    
                                ]
                            }
                        ]
                    }
                ]

            },
    
    },
}

export default layout;