<template>
  <v-tabs
    v-if="groupid && tabList.length > 1"
    :grow="grow"
    :vertical="vertical"
    :color="sliderColor"
    :right="right"
    :optional="optional"
  >
    <v-tabs-slider></v-tabs-slider>
    <v-tab
      v-for="(t, i) in tabList"
      :key="i"
      :exact="t.exact"
      :to="{ name: t.name, props: { groupid: groupid }, query: $route.query }"
    >
      <i :class="t.icon" class="fad mr-2"></i>
      <span v-if="t.filter == 'term'">{{ t.text | term }}</span>
      <span v-else>{{ t.text | pluralTerm }}</span>
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: "tabList",
  data() {
    return {
      tabList: [],
    };
  },
  created() {
    var result = this.tabs;

    if (this.admin) {
      if (this.hasBaseline) {
        result.push({
          text: "Baseline",
          filter: "term",
          exact: false,
          icon: "fa-poll-h",
          name: "GroupBaseline",
        });
      }
      result.push({
        text: "Assessment",
        filter: "pluralTerm",
        exact: true,
        icon: "fa-chart-bar",
        name: "GroupAssessments",
      });
      result.push({
        text: "Report",
        filter: "pluralTerm",
        exact: true,
        icon: "fa-chart-bar",
        name: "GroupReport",
      });

      result.push({
        text: "Settings",
        filter: "term",
        exact: false,
        icon: "fa-cog",
        name: "GroupSettings",
      });
    }

    this.tabList = result.filter((x) => !this.exclude.includes(x.name));
  },
  computed: {
    hasBaseline() {
      return this.groupType ? this.groupType.baseline != null : false;
    },
  },
  props: {
    groupType: {
      type: Object,
    },
    exclude: {
      type: Array,
      default: () => [],
    },
    optional: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => {
        return [
          {
            text: "Dashboard",
            filter: "term",
            exact: true,
            icon: "fa-home",
            name: "GroupHome",
          },
          /*    {
                        text: 'Assessment',
                        filter: 'pluralTerm',
                        exact: true,
                        icon: 'fa-edit',
                        name: 'GroupAssessments',
                    },
                    {
                        text: 'Rubric',
                        filter: 'pluralTerm',
                        exact: true,
                        icon: 'fa-th',
                        name: 'GroupRubrics',

                    },
                    */
        ];
      },
    },
    right: {
      type: Boolean,
      default: false,
    },
    iconWeight: {
      type: String,
      default: "fad",
    },
    grow: {
      type: Boolean,
      default: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    groupid: {
      type: String,
    },
    admin: {
      type: Boolean,
      default: false,
    },
    sliderColor: {
      type: String,
      default: "secondary",
    },
  },
};
</script>
