<template>
  <v-container fluid>
    <v-row v-if="rubric.write">
      <v-col cols="3" md="2" v-if="$vuetify.breakpoint.mdAndUp">
        <v-list nav>
          <v-list-item-group v-model="tab" vertical dense>
            <v-list-item
              dense
              color="secondary"
              v-for="t in tabOptions"
              :key="t.value"
              :value="t.value"
              :href="`#${t.value}`"
            >
              <span class="text-body-2">
                {{ t.text }}
              </span>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col>
        <v-select
          solo
          v-if="$vuetify.breakpoint.smAndDown"
          v-model="tab"
          :items="tabOptions"
        >
        </v-select>
        <keep-alive>
          <component
            @update="update"
            @error="error"
            :is="component.component"
            v-bind="component.props"
          ></component>
        </keep-alive>
      </v-col>
    </v-row>
    <sr-not-found
      v-else
      color="error"
      icon="fad fa-lock"
      unauthorized
      :thing="`the settings page for ${rubric.title}`"
    >
    </sr-not-found>
  </v-container>
</template>

<script>
export default {
  name: "RubricSettings",
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.hash != null) {
      var t = this.$route.hash.toLowerCase().replace("#", "");
      this.tab = this.tabOptions.map((x) => x.value).includes(t) ? t : "edit";
    } else {
      this.tab = "edit";
    }
  },
  methods: {
    update(snackbar) {
      console.log("settings emit");
      console.log(snackbar);
      this.$emit("update", snackbar);
    },
    error(snackbar) {
      console.log(snackbar);
      this.$emit("error", snackbar);
    },
  },
  computed: {
    tabOptions() {
      var tabs = [
        {
          text: "Metadata",
          icon: "fa-pencil",
          value: "edit",
        },

        {
          text: "Access",
          icon: "fa-shield-alt",
          value: "access",
        },
      ];

      return tabs;
    },

    component() {
      var component = "sr-edit-rubric";
      var props = { rubric: this.rubric, permissions: this.permissions };
      switch (this.tab) {
        /*  case "appearance":
          component = "sr-rubric-appearance";
          props.readonly = false;

          break;
        case "scoring":
          component = "sr-rubric-scoring";
          break;
          */
        case "access":
          component = "sr-rubric-access";

          break;
      }
      return { component: component, props: props };
    },
  },
  props: {
    rubric: {
      type: Object,
    },

    permissions: {
      type: Object,
    },
  },
};
</script>
