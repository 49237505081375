<template>
  <div>
    <v-system-bar v-if="groupType" dark :style="{ backgroundColor: color }">
      <span class="overline ml-5 pl-5"> {{ groupType.name }}</span>

      <v-spacer></v-spacer>
    </v-system-bar>
    <v-toolbar v-if="miniGroup" dense flat :key="loading">
      <v-toolbar-items class="no-padder">
        <v-btn
          v-if="paths.length != 0"
          @click="showBreadCrumbs = !showBreadCrumbs"
          text
          icon
          class="px-0"
        >
          <v-icon v-show="showBreadCrumbs">mdi-chevron-right</v-icon>
          <v-icon v-show="!showBreadCrumbs">mdi-chevron-left</v-icon>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items v-if="group && (showBreadCrumbs || paths.length == 0)">
        <v-btn icon :to="{ name: 'Home' }" :color="color">
          <i class="fal fa-home"></i>
        </v-btn>
        <v-menu offset-y v-if="groupType">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon :color="color">
              <i class="fal fa-users"></i>
            </v-btn>
          </template>

          <v-list v-if="groupType">
            <v-subheader hidden class="overline">Back To...</v-subheader>
            <v-list-item dense exact link :to="{ name: 'AllGroups' }">
              <v-list-item-title>
                <i color="primary" class="fad fa-users mr-3"></i>
                All groups
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              dense
              exact
              link
              :to="{ name: 'AllGroups', query: { type: groupType.id } }"
            >
              <v-list-item-title>
                <i color="primary" class="fad fa-users mr-3"></i>
                All {{ groupType.name | pluralize }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              dense
              v-if="paths.length"
              exact
              link
              :to="{
                name: 'AllGroups',
                query: { type: groupType.id, parent: paths[0].groups[0].id },
              }"
            >
              <v-list-item-title>
                <i color="primary" class="fad fa-users mr-3"></i>
                Sibling {{ groupType.name | pluralize }} ({{ paths[0].groups[0].name }})
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-toolbar-items v-for="(item, i) in paths" :key="i">
          <v-btn
            text
            large
            class="px-1"
            :color="color"
            :to="{ name: 'GroupHome', params: { groupid: item.groups[0].id } }"
          >
            {{ item.groups[0].name }}
          </v-btn>
          <v-icon class="text-button mt-1" v-if="i < paths.length"
            >mdi-chevron-right</v-icon
          >
        </v-toolbar-items>
      </v-toolbar-items>

      <v-toolbar-title :style="{ color: color }" class="text-button ml-2 mt-1">
        {{ miniGroup.displayText }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text :color="color" class="mx-3 hidden" v-if="admin">
          <i class="fal fa-plus mr-3"> </i>
          New {{ "Assessment" | term }}
        </v-btn>

        <sr-group-tabs
          :optional="true"
          right
          :sliderColor="color"
          :grow="false"
          v-if="group && $vuetify.breakpoint.lgAndUp && !showBreadCrumbs"
          :groupid="group.id"
          :groupType="groupType"
          :admin="admin"
        ></sr-group-tabs>
      </v-toolbar-items>

      <template
        v-slot:extension
        v-if="group && ($vuetify.breakpoint.mdAndDown || showBreadCrumbs)"
      >
        <sr-group-tabs
          v-if="group"
          class="border-top"
          :groupid="group.id"
          :groupType="groupType"
          :sliderColor="color"
          :admin="admin"
        ></sr-group-tabs>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container fluid v-if="group">
      <router-view
        name="header"
        v-bind="{
          group: group,
          people: people,
          rubrics: rubrics,
          permissions: permissions,
          groupType: groupType,
        }"
      ></router-view>
      <router-view
        @update="init"
        v-bind="{
          group: group,
          people: people,
          rubrics: rubrics,
          permissions: permissions,
          groupType: groupType,
        }"
      >
      </router-view>
    </v-container>
    <v-container fluid v-if="!group">
      <sr-loading v-if="loading"></sr-loading>
      <sr-not-found
        color="error"
        icon="fad fa-wifi-slash"
        v-if="!loading && error && error.toLowerCase().includes('network')"
      >
        <template v-slot:title> Network Error </template>
        <template v-slot:description>This resource is temporarily unavailable.</template>
        <template v-slot:body>
          Please try again later. If the problem persists, contact support.
        </template>
      </sr-not-found>
      <sr-not-found
        unauthorized
        v-if="!loading && error && !error.toLowerCase().includes('network')"
      ></sr-not-found>
    </v-container>
    <v-snackbar v-model="showSnackbar" top :timeout="app_info.snackbarTimeout">
      {{ snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import srGroupTabs from "../newComponents/GroupComponents/TabList";

export default {
  name: "GroupWrapper",
  components: {
    srGroupTabs,
  },
  watch: {
    groupid: function (newVal, oldVal) {
      if (oldVal != null) {
        this.init();
      }
    },
  },
  data() {
    return {
      group: null,
      loading: false,
      error: null,
      snackbar: null,
      showSnackbar: false,
      showBreadCrumbs: false,
      people: [],
      rubrics: [],
    };
  },
  computed: {
    color() {
      return this.group && this.group.metadata && this.group.metadata.primaryColor
        ? this.group.metadata.primaryColor
        : "secondary";
    },
    bookmarked() {
      return false;
    },
    bookmarks() {
      return this.$store.getters["saved/bookmarks"];
    },
    permissions() {
      return this.groupType
        ? this.admin
          ? this.groupType.objectPermissions.admin
          : this.groupType.objectPermissions.member
        : null;
    },
    admin() {
      return this.group != null ? this.group.isAdministrator : false;
    },
    miniGroup() {
      return this.$store.getters["saved/get"](["groups", this.groupid]);
    },

    status() {
      var role = !this.miniGroup.readonly
        ? this.groupType.adminName
        : this.groupType.memberName;
      var permissions = !this.miniGroup.readonly
        ? this.groupType.objectPermissions.admin
        : this.groupType.objectPermissions.member;
      return {
        role: role,
        direct: this.miniGroup.directMember || this.miniGroup.directAdmin,
        permissions: permissions,
      };
    },
    groupType() {
      return this.group
        ? this.$store.getters["saved/get"](["groupTypes", this.group.groupTypeId])
        : null;
    },
    paths() {
      var t = this;
      return this.miniGroup && this.miniGroup.path.length != 0
        ? this.miniGroup.path
            .map((x) => {
              return x
                ? {
                    level: x.level,
                    groups: x.groups
                      ? x.groups.map((g) => {
                          var group = t.$store.getters["saved/get"](["groups", g]);
                          var gt = t.$store.getters["saved/get"]([
                            "groupTypes",
                            group.groupType,
                          ]);
                          return {
                            id: g,
                            name: group != null ? group.displayText : null,
                            groupType: gt != null ? gt.name : null,
                            colour: group ? group.colour : "",
                          };
                        })
                      : [],
                  }
                : null;
            })
            .filter((y) => y)
            .sort(function (a, b) {
              return b.level - a.level;
            })
        : [];
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    bookmarkToggle() {
      if (this.bookmarked) {
        //cancel bookmark
      } else {
        var bookmark = {
          displayName: this.group.metadata.name,
          org: this.org_id,
          queries: this.$route.queries,
          params: this.$route.params,
          name: this.$route.name,
          index: this.bookmarks.length,
        };
        var self = this.$store.getters["saved/self"];
        console.log(bookmark);
        console.log(self);
        this.$post(`entities/individuals/${self.id}/bookmarks/add`, bookmark).then(
          (response) => {
            debugger;
            if (response.error) {
              this.error = response.text;
            } else {
              this.$store.dispatch("saved/get_self");
            }
          }
        );
      }
    },
    generation(level) {
      var result = "";
      var i;
      debugger;
      for (i = 0; i < level; i++) {
        if (i == 0) {
          result = result + "parent";
        } else if (i == 1) {
          result = "grand" + result;
        } else {
          result = "great " + result;
        }
      }
      return result;
    },
    init(snackbar) {
      this.$store.dispatch("saved/stealthRubricUpdate");
      console.log("getting Group");
      console.log("snackbar: " + snackbar);
      this.loading = true;
      this.$get(`entities/groups/${this.groupid}`).then((response) => {
        debugger;
        if (response.error) {
          this.error = response.text;
        } else {
          this.group = response.data;
          if (snackbar) {
            this.snackbar = snackbar;
            this.showSnackbar = true;
          }
        }
        this.loading = false;
      });
      this.$get(`entities/groups/${this.groupid}/members`).then((response) => {
        if (response.error) {
          this.error = response.text;
        } else {
          this.people = response.data;
        }
      });
      //this.$store.dispatch("saved/update_groups");
      this.$store.dispatch("saved/get_groups");

      this.$post("rubrics/groups", [this.groupid]).then((response) => {
        if (response.error) {
          this.error = response.text;
        } else {
          this.rubrics = response.items;
        }
      });
    },
  },
  props: {
    groupid: {
      type: String,
    },
  },
};
</script>
