var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","tile":_vm.app_info.tile,"loading":!_vm.frameworkReady || !_vm.rubricReady}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm._f("pluralTerm")(_vm.title))+" ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","disabled":!_vm.frameworkReady},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.frameworks,"single-expand":"","expanded":_vm.expanded,"item-key":"id","show-expand":"","headers":_vm.headers,"hide-default-header":_vm.hideHeaders,"search":_vm.search},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:( _vm.showExpand(item) && !isExpanded),expression:" showExpand(item) && !isExpanded"}],attrs:{"icon":"","text":""},on:{"click":function($event){$event.preventDefault();return expand(true)}}},[_c('v-icon',[_vm._v("fal fa-angle-down")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExpand(item) && isExpanded),expression:"showExpand(item) && isExpanded"}],attrs:{"icon":"","text":""},on:{"click":function($event){$event.preventDefault();return expand(false)}}},[_c('v-icon',[_vm._v("fal fa-angle-up")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(_vm.getRubrics(item.id).length)?_c('v-card',{staticClass:"pa-5 my-2",attrs:{"flat":"","color":"transparent"}},[_c('v-data-table',{attrs:{"dense":"","hide-default-header":true,"hide-default-footer":_vm.getRubrics(item.id).length <= 10,"disable-pagination":"","tile":"","color":"transparent","headers":_vm.rubricHeaders,"items":_vm.getRubrics(item.id)},scopedSlots:_vm._u([{key:"item.dateUpdated",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.dateUpdated,_vm.app_info.shortDate))+" ")])]}},{key:"item.assessments",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","pill":"","color":"primary"},on:{"click":function($event){return _vm.goToAssessmentView(item.frameworkId)}}},[_c('v-avatar',{attrs:{"left":""}},[_c('sr-icon',{staticClass:"fal",attrs:{"icon":"assessment"}})],1),_vm._v(" "+_vm._s(_vm._f("pluralTerm")('assessment'))+": "),_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.getAssessments(item.id).length))])],1)]}},{key:"item.nodes",fn:function(ref){
var item = ref.item;
return _vm._l((item.nodes),function(g,i){return _c('sr-group-chip',{key:g,class:{'mr-2': i < item.nodes.length-1},attrs:{"small":true,"id":g}})})}}],null,true)})],1):_c('v-card',{staticClass:"text--center",attrs:{"flat":"","color":"transparent"}},[_c('v-card-subtitle',{staticClass:"overline"},[_vm._v("No "+_vm._s(_vm._f("pluralTerm")('rubric'))+" in use")])],1)],1)]}},{key:"item.lastUpdated",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.lastUpdated,_vm.app_info.shortDate))+" ")])]}},{key:"item.authors",fn:function(ref){
var item = ref.item;
return _vm._l((item.authors),function(g,i){return _c('sr-person-chip',{key:g,class:{'mr-2': i < item.authors.length-1},attrs:{"newWindow":"","id":g}})})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.goToFramework(item, false)}}},[_c('v-icon',[_vm._v("fal fa-angle-right")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }