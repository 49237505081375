<template>
    <v-card :key="key" :tile="app_info.tile" :class="{'mt-5': $vuetify.breakpoint.smAndDown}" flat > 
       <div v-if="skillProfile.length">
            <v-list-item  v-for="(s,i) in splicedProfile" :key="i" >
                <v-list-item-content class="d-flex">
                  <v-list-item-title class="shrink">{{s.skill.name}}</v-list-item-title>
                  <v-progress-linear color="secondary" height="10" striped :value="s.score"></v-progress-linear>
                </v-list-item-content>
              

              </v-list-item>
              
       </div>
      <sr-not-found  :outlined="false" flat icon="fad fa-th" :actions="false"  v-if="!loading && !skillProfile.length">
          <template v-slot:title>Not enough data</template>
          <template v-slot:body>
              <p v-if="skillProfile.length > 0 && skills.length ==0">The {{'assessment' | pluralTerm}} associated with this {{groupTypeName}} may be against rubrics that do not have tags correctly configured for each objective. 

              </p>
              <p v-else>
                  {{personName}} hasn't completed enough {{'assessment' | pluralTerm}} to display an accurate {{'profile' |term}}.
              </p>
          </template>
      </sr-not-found>
     <sr-loading v-if="loading && !skillProfile.length"></sr-loading>
      
    </v-card>
</template>
 
<script>

export default { 
    name: 'BaselineReportSkills',
   
    data(){
        return {
            error: null,
            preview: false,
        }
    },
   
 
    computed:{
  
        key(){
            return this.skills.length; 
        },
        splicedProfile(){
            return !this.spliceProfile ? this.$r.clone(this.skillProfile) : this.$r.clone(this.skillProfile).splice(0,this.maxCount) 
        },
       
    },
    methods: {
      
    },
    props: {
        skillProfile:{
            type: Array
        },
        skills:{
            type: Array
        },
        spliceProfile: {
            type:Boolean,
            default: true
        },
        maxCount:{
            type: Number,
            default: 6
        },
        personName:{
            type: String
        },
        loading: {
            type: Boolean,
            default: false,
        },
         printView:{
           type:Boolean,
           default: false
       }
    }
}
</script>