<template>
  <div>
    <v-container v-if="!isDetailView">
      <sr-breadcrumbs
        :items="[
          { text: 'Home', to: { name: 'Home' } },
          { text: 'Rubrics', to: { name: 'AllRubrics' }, disabled: true },
        ]"
      ></sr-breadcrumbs>
      <v-row>
        <v-col> </v-col>
      </v-row>
      <v-row>
        <v-col>
          <rubric-widget></rubric-widget>
        </v-col>
      </v-row>
    </v-container>
    <router-view v-else></router-view>
  </div>
</template>

<script>
//import OrganizationList from "../newComponents/Settings/OrganizationList.vue";

import RubricWidget from "../newComponents/RubricWidget.vue";
//import CreateRubric from "../newComponents/RubricComponents/CreateRubric.vue";
export default {
  name: "Rubrics",
  components: {
    RubricWidget,
    // OrganizationList,
    //  CreateRubric,
  },
  computed: {
    isDetailView() {
      if (this.$route.params.rubricid) {
        return true;
      }
      return false;
    },
  },
  props: {
    rubricHeaders: {
      type: Array,
      default: () => {
        return [
          {
            text: "Date Created",
            value: "created",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Groups",
            value: "nodes",
          },
          {
            text: "Framework Source",
            value: "frameworkId",
          },
          {
            text: "",
            value: "actions",
          },
        ];
      },
    },
  },
};
</script>
