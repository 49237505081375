<template>
  <v-card>
    <v-toolbar flat extension-height="350" v-if="!hideToolbar">
      <div class="grey--text text-subtitle">
        {{ title | pluralTerm }}
      </div>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        :hide-details="true"
        label="Search Title or Description"
        @keyup.enter="getDataFromApi(true)"
      >
        <template v-slot:append>
          <v-icon v-show="!search || !search.length">mdi-magnify</v-icon>
          <v-icon v-show="search && search.length">mdi-keyboard-return</v-icon>
        </template>
      </v-text-field>

      <v-btn
        v-if="allowFilter && !hideFilter"
        icon
        @click="showExtension = !showExtension"
      >
        <v-icon v-show="!showExtension">mdi-chevron-down</v-icon>
        <v-icon v-show="showExtension">mdi-chevron-up</v-icon>
      </v-btn>

      <template v-slot:extension v-if="showExtension && allowFilter">
        <v-row class="mt-5">
          <v-col>
            <v-card fluid flat class="mb-3">
              <v-card-text>
                <v-row>
                  <v-col cols="2" class="text-right">
                    <span>Show only:</span>
                  </v-col>
                  <v-col v-if="!groupId">
                    <v-select
                      :disabled="!groupReady"
                      label="All groups..."
                      multiple
                      :items="groupItems"
                      clearable
                      deletable-chips
                      dense
                      item-value="value"
                      v-model="localQuery.groups"
                      :hint="!groupReady ? 'Loading options...' : ''"
                      :persistent-hint="!groupReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model="groupSearch"
                              append-icon="mdi-magnify"
                              label="Search Groups"
                              clearable
                              single-line
                              hide-details
                              :disabled="!groupReady"
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ active, item, attrs, on }">
                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <sr-group-chip
                              selectOption
                              :id="item.value"
                              small
                            ></sr-group-chip>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <sr-group-chip
                          selectOption
                          :id="item.value"
                          small
                        ></sr-group-chip>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-select
                      label="All Frameworks..."
                      multiple
                      :items="frameworkItems"
                      :disabled="!frameworkReady"
                      clearable
                      deletable-chips
                      dense
                      item-text="title"
                      item-value="id"
                      v-model="localQuery.frameworks"
                      :hint="!frameworkReady ? 'Loading options...' : ''"
                      :persistent-hint="!frameworkReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model.lazy="frameworkSearch"
                              append-icon="mdi-magnify"
                              label="Search Frameworks"
                              clearable
                              :disabled="!frameworkReady"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="false">
                    <v-select
                      label="All People..."
                      multiple
                      :items="peopleItems"
                      :disabled="!peopleReady"
                      clearable
                      deletable-chips
                      dense
                      item-text="displayText"
                      item-value="id"
                      v-model="localQuery.individuals"
                      :hint="!peopleReady ? 'Loading options...' : ''"
                      :persistent-hint="!peopleReady"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-text-field
                              dense
                              v-model.lazy="individualSearch"
                              append-icon="mdi-magnify"
                              label="Search People"
                              clearable
                              :disabled="!peopleReady"
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="2" class="text-right">
                    <span class="mt-3">Between dates</span>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="localQuery.start" type="date"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="localQuery.end" type="date"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2" class="text-right">
                    <span>Tags</span>
                  </v-col>
                  <v-col>
                    <v-combobox
                      multiple
                      clearable
                      deletable-chips
                      small-chips
                      v-model="localQuery.tags"
                      hint="tags are case sensitive and must be entered exactly as they appear in the rubric"
                      persistent-hint
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="4">
                    <v-checkbox
                      v-model="localQuery.tagMatchAny"
                      label="Match Any Tag"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="showDraft.checked"
                      @change="updateIndeterminate(showDraft)"
                      :indeterminate="showDraft.indeterminate"
                      :label="showDraft.name"
                      persistent-hint
                      :hint="
                        showDraft.indeterminate
                          ? `Both draft and published ${getTerm(
                              'rubric',
                              true
                            )} will be retrieved`
                          : `Only ${!showDraft.checked ? 'published' : 'draft'} ${getTerm(
                              'rubric',
                              true
                            )} will be retrieved`
                      "
                    >
                      <template v-slot:append>
                        <v-btn
                          v-if="!showDraft.indeterminate"
                          small
                          text
                          color="primary"
                          @click="showDraft.indeterminate = !showDraft.indeterminate"
                          >Show All</v-btn
                        >
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="resetFilters" color="error">Reset Filters</v-btn>
                <v-btn text @click="getDataFromApi(true)" color="primary">Search</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :value="selectedRubrics"
      calculate-widths
      disable-sort
      @input="update"
      :show-expand="false"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
      class="elevation-1"
      :show-select="selectable && totalItems <= 100"
    >
      <template v-slot:item.frameworkId="{ item }">
        <sr-framework-chip
          class="ma-0 transparent"
          :id="item.frameworkId"
        ></sr-framework-chip>
      </template>
      <template v-slot:item.created="{ item }">
        <div class="d-flex align-center">
          {{ item.created | moment(app_info.shortDate) }}
        </div>
      </template>
      <template v-slot:item.title="{ item }">
        <span>
          {{ item.title }}
          <v-tooltip left max-width="250" v-show="item.tags && item.tags.length > 0">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <i class="fal fa-tags"></i>
              </span>
            </template>
            <div class="comma-delimit">
              <span v-for="(t, i) in item.tags" :key="i">{{ t }}</span>
            </div>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.draft="{ item }">
        <div class="d-flex align-center">
          <v-chip small v-if="item.draft">Draft</v-chip>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length + 1">
          <div class="flex-wrap d-flex align-center ma-3">
            <v-chip small class="ml-2 my-1" v-for="(t, i) in item.tags" :key="i">{{
              t
            }}</v-chip>
          </div>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="goToRubric(item, 'RubricHome', null, true)">
          <v-icon>fal fa-angle-right</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";

const Moment = moment;
export default {
  data() {
    return {
      totalItems: 0,
      items: [],
      loading: true,
      options: {},
      search: "",
      changes: 0,
      showExtension: false,
      groupSearch: null,
      frameworkSearch: null,
      localQuery: {},
      individualSearch: null,
      showDraft: {
        name: "Show Draft?",
        checked: false,
        indeterminate: true,
      },
    };
  },
  watch: {
    options: {
      handler(nv, ov) {
        if (ov.page != nv.page || ov.itemsPerPage != nv.itemsPerPage) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    resetFilters() {
      this.init();
    },
    getHeader(items) {
      var item = items && items.length != 0 ? items[0] : null;
      if (item) {
        var framework = this.$store.getters["saved/get"]([
          "frameworks",
          item.frameworkId,
        ]);
        if (framework) {
          return framework.title;
        }
        return item.id;
      }
      return "Framework Unavailable";
    },
    init() {
      this.$store.dispatch("saved/stealthRubricUpdate");
      this.localQuery = {};
      Object.assign(this.localQuery, this.query);

      if (this.frameworkId) {
        this.localQuery.frameworks = [this.frameworkId];
      }
      if (this.groupId) {
        this.localQuery.groups = [this.groupId];
      }
      if (this.personId) {
        this.localQuery.individuals = [this.personId];
      }
      if (!this.localQuery.start) {
        // var end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        this.localQuery.start = Moment(new Date())
          .add(-18, "months")
          .format("YYYY-MM-DD");
      }
      if (!this.localQuery.end) {
        this.localQuery.end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
      }
      if (this.frameworkId) {
        this.localQuery.frameworks = [this.frameworkId];
      }
      if (this.groupId) {
        this.localQuery.groups = [this.groupId];
      }

      this.showDraft.checked =
        this.localQuery.onlyDraft && !this.localQuery.onlyPublished;
      this.showDraft.indeterminate =
        (!this.localQuery.onlyDraft && !this.localQuery.onlyPublished) ||
        (this.localQuery.onlyDraft && this.localQuery.onlyPublished);
    },
    updateIndeterminate(e) {
      e.indeterminate = false;
    },
    update(e) {
      this.$emit("input", e);
    },
    getDataFromApi(updatedQuery) {
      this.loading = true;
      var query = {};
      console.log(this.localQuery);
      Object.assign(query, this.localQuery);
      query.start = this.$moment(this.localQuery.start).toISOString();
      query.end = this.$moment(this.localQuery.end).toISOString();

      query.onlyDraft = this.showDraft.checked && !this.showDraft.indeterminate;
      query.onlyPublished = !this.showDraft.checked && !this.showDraft.indeterminate;
      if (updatedQuery) {
        this.options.page = 1;
      }
      console.log(query);
      debugger;
      var options = {
        pageNumber: this.options.page - 1,
        pageSize: this.options.itemsPerPage,
        search: this.search,
        query: query,
        sortField: "frameworkId",
      };
      console.log(options);
      this.$post(this.url, options).then((data) => {
        debugger;

        if (!data.error) {
          this.items = data.items;
          this.totalItems = data.totalItems > 0 ? data.totalItems : data.items.length;
        }
        this.loading = false;
      });
    },
  },
  computed: {
    groupReady() {
      return this.$store.getters["saved/status"]("groups") == "complete";
    },
    frameworkReady() {
      return this.$store.getters["saved/status"]("rubrics") == "complete";
    },
    peopleReady() {
      return this.$store.getters["saved/status"]("people") == "complete";
    },
    groupItems() {
      var groups = this.$store.getters["saved/getAll"]("groups");
      return groups
        .map((group) => {
          debugger;
          var paths = group
            ? group.path.map((g) => {
                var parents = g.groups
                  ? g.groups.map((gp) => {
                      var parent = this.$store.getters["saved/get"](["groups", gp]);
                      return parent ? parent.displayText : "";
                    })
                  : [];
                return parents;
              })
            : [];
          // var count = this.rawRubrics.filter(x=>x.groupId == group.id);
          return {
            text: group.displayText,
            value: group.id,
            path: paths.join(" "),
            member: group.directMember,
            admin: group.directAdmin,
            //    hasCount: count.length != 0
          };
        })
        .filter((x) => {
          if (this.groupSearch && this.groupSearch.length != 0) {
            return (x.text.toLowerCase() + x.path.toLowerCase()).includes(
              this.groupSearch.toLowerCase()
            );
          } else {
            return x;
          }
        });
    },
    frameworkProp() {
      if (this.routeProps) {
        return this.routeProps.frameworkId
          ? this.routeProps.frameworkId
          : this.frameworkId;
      }
      return this.frameworkId;
    },
    frameworkItems() {
      return this.$store.getters["saved/getAll"]("frameworks");
    },
    peopleProp() {
      if (this.routeProps) {
        return this.routeProps.personId ? this.routeProps.personId : this.personId;
      }
      return this.personId;
    },
    peopleItems() {
      return this.$store.getters["saved/getAll"]("people");
    },
  },
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: "RubricHome",
    },
    routeProps: {
      type: Object,
    },
    title: {
      type: String,
      default: "Rubric",
    },
    groupId: {
      type: String,
    },
    personId: {
      type: String,
    },
    rubricId: {
      type: String,
    },
    frameworkId: {
      type: String,
    },
    url: {
      type: String,
      default: "saved/rubrics/search",
    },
    selectedRubrics: {
      type: Array,
      default: () => [],
    },
    archived: {
      type: Boolean,
      default: false,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    allowFilter: {
      type: Boolean,
      default: true,
    },
    query: {
      type: Object,
      default: () => {
        var end = Moment(new Date()).add(1, "days").format("YYYY-MM-DD");
        var start = Moment(new Date()).add(-18, "months").format("YYYY-MM-DD");
        return {
          groups: [],
          parents: [],
          onlyPublished: false,
          onlyDraft: false,
          tags: [],
          individuals: [],
          assessments: [],
          matchAny: false,
          tagMatchAny: false,
          start: start,
          end: end,
          getNode: true,
          frameworks: [],
        };
      },
    },
    headers: {
      type: Array,
      default: () => {
        return [
          {
            text: "Created Date",
            value: "created",
            /*  sortable: true,
            sort: (a, b) => {
              debugger;
              var aDate = new Date(a);
              var bDate = new Date(b);
              return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
            },
            */
          },

          {
            text: "Framework",
            value: "frameworkId",
          },
          {
            text: "Rubric",
            value: "title",
          },
          {
            text: "Draft",
            value: "draft",
          },
          {
            text: "",
            value: "actions",
          },
        ];
      },
    },
  },
};
</script>
